import React, { Component } from 'react';

class FileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFileUploading: false,
      isFileDeleting: null,
      uploadedFiles: [],
      file: null
    };
  }

  addUploadedFile = (file) => {
    const { uploadedFiles } = this.state;
    if (!uploadedFiles.some((f) => f.filename === file.filename)) {
      this.setState((prevState) => ({
        uploadedFiles: [file, ...prevState.uploadedFiles]
      }));
    }
  };

  removeUploadedFile = (file) => {
    this.setState((prevState) => ({
      uploadedFiles: prevState.uploadedFiles.filter((f) => f.uuid !== file.uuid)
    }));
  };

  onDeleteUploadedFile = async (file) => {
    try {
      const { deleteService } = this.props;
      if (!deleteService) throw new Error('Pass delete report service');

      this.setState({ isFileDeleting: file.uuid });
      const res = await deleteService(file.uuid);
      if (res.status === 'success') this.removeUploadedFile(file);
      this.setState({ isFileDeleting: null });
    } catch (error) {
      this.setState({ isFileDeleting: null });
    }
  };

  onFileChange = async (e) => {
    try {
      const { uploadService } = this.props;
      if (!uploadService) throw new Error('Pass upload report service');

      const file = e.target.files[0];
      if (!file) return;
      this.setState({ file, isFileUploading: true });
      const res = await uploadService(file, this.props.student_uuid, this.props.clientDomain);
      this.setState({ file: null });
      if (res?.status === 200) {
        this.addUploadedFile(res.data);
        this.props.close_upload_medical_popup();
        this.props.load_data();
      }
      if (res?.message) this.addUploadedFile(res?.message?.[0]);
      this.setState({ isFileUploading: false });
    } catch (error) {
      this.setState({ isFileUploading: false });
    }
  };

  render() {
    const { isFileUploading, isFileDeleting, uploadedFiles, file } = this.state;

    return <input type="file" onChange={this.onFileChange} multiple={false} />;
  }
}

export default FileComponent;
