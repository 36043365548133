import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import CustomLoading from '../../../util_components/custom_loading';
import ReportTemplate from './report_template';
import { post_api } from '../../../../redux/api_funcs';
import { date_drop_for_report } from '../../../../constants';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import { get_api_url } from '../../../../utils/urls';
import './status.css';

class DailyStatus extends Component {
  state = {
    start_date: '',
    end_date: '',
    loading: true,
    report_details: {},
    selected_start_date: '',
    selected_end_date: '',
    date_filter_type: 'TODAY',
    timezone: 'America/Los_Angeles',
    first_load: true,
    submit_click: false
  };

  componentDidMount() {
    const start_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    const end_date = moment().format('YYYY-MM-DD');
    this.setState({ start_date, end_date, end_time }, () => {
      this.load_data();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.load_data();
    }
  }

  load_data = () => {
    const url = get_api_url('get_reports_data');
    let final_start_date = '';
    let final_end_date = '';
    const type = this.state.date_filter_type;
    if (type === 'TODAY') {
      const start_date = moment().format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_time = moment().format('HH:mm');
      final_end_date = `${start_date} ${end_time}`;
    } else if (type === 'YESTERDAY') {
      const start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_date = moment().format('YYYY-MM-DD');
      final_end_date = `${end_date} 00:00`;
    } else if (type === 'THIS WEEK') {
      const start_date = moment().weekday(1).format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_date = moment().format('YYYY-MM-DD');
      const end_time = moment().format('HH:mm');
      final_end_date = `${end_date} ${end_time}`;
    } else if (type === 'LAST WEEK') {
      const start_date = moment().weekday(1).subtract(1, 'weeks').format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_date = moment(start_date, 'YYYY-MM-DD').add(1, 'weeks').format('YYYY-MM-DD');
      final_end_date = `${end_date} 00:00`;
    } else if (type === 'THIS MONTH') {
      const start_date = moment().startOf('month').format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_date = moment().format('YYYY-MM-DD');
      const end_time = moment().format('HH:mm');
      final_end_date = `${end_date} ${end_time}`;
    } else if (type === 'LAST MONTH') {
      const today = moment().format('YYYY-MM-DD');
      const start_date = moment(today, 'YYYY-MM-DD')
        .startOf('month')
        .subtract(1, 'months')
        .format('YYYY-MM-DD');
      final_start_date = `${start_date} 00:00`;
      const end_date = moment(today, 'YYYY-MM-DD')
        .startOf('month')
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      final_end_date = `${end_date} 00:00`;
    } else {
      final_start_date = `${this.state.start_date} 00:00`;
      const end_date = moment(this.state.end_date, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD');
      final_end_date = `${end_date} 00:00`;
    }

    const payload = {
      start_date: final_start_date,
      end_date: final_end_date,
      iana_timezone: this.props.iana_timezone
    };
    if (!(type === 'CUSTOM' && !this.state.submit_click)) {
      this.setState(
        { selected_start_date: final_start_date, selected_end_date: final_end_date, loading: true },
        () => {
          post_api(url, payload, true).then((result) =>
            this.setState({
              report_details: { ...result.data.transaction },
              loading: false,
              first_load: false,
              submit_click: false
            })
          );
        }
      );
    }
  };

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  submit_click = () => this.setState({ submit_click: true }, () => this.load_data());

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => this.load_data());

  render() {
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Signup & Sessions</title>
        </Helmet>
        <div className="daily-report-header">
          <div style={{ marginRight: '10px' }}>
            <CustomSingleSelect
              label="Select a date filter"
              options={date_drop_for_report}
              onChange={this.change_date_filter}
              value={this.state.date_filter_type}
              style={{ width: '200px' }}
            />
          </div>
          {this.state.date_filter_type === 'CUSTOM' ? (
            <>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="Start Date"
                  value={this.state.start_date}
                  handleChange={(val) => this.handle_date_change('start_date', val)}
                  maxDate={this.state.end_date}
                />
              </div>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="End Date"
                  value={this.state.end_date}
                  handleChange={(val) => this.handle_date_change('end_date', val)}
                  minDate={this.state.start_date}
                  maxDate={moment().format('YYYY-MM-DD')}
                />
              </div>
              <button onClick={this.submit_click}>Submit</button>
            </>
          ) : null}
        </div>
        <ReactToPrint
          trigger={() => (
            <div className="print-link">
              <a href="#">Print</a>
            </div>
          )}
          content={() => this.componentRef}
        />
        {this.state.loading ? (
          <CustomLoading />
        ) : (
          <ReportTemplate
            report_details={this.state.report_details}
            ref={(el) => (this.componentRef = el)}
            start_date={this.state.selected_start_date}
            end_date={this.state.selected_end_date}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(DailyStatus);
