export const reset = 'RESET';

// action types for teacher
export const update_approved_teachers = 'UPDATE_APPROVED_TEACHERS';
export const set_teachers_variable = 'SET_TEACHERS_VARIABLE';
export const get_approved_teachers = 'GET_APPROVED_TEACHERS';
export const approve_teacher_details = 'APPROVE_TEACHER_DETAILS';
export const get_teacher_profile_details = 'GET_TEACHER_PROFILE_DETAILS';
export const update_teacher_files = 'UPDATE_TEACHER_FILES';
export const get_teachers_list = 'GET_TEACHERS_LIST';
export const filter_teachers = 'FILTER_TEACHERS';
export const avail_all_in_one = 'AVAIL_ALL_IN_ONE';

// action types for students
export const set_students_variable = 'SET_STUDENTS_VARIABLE';
export const get_all_students = 'GET_ALL_STUDENTS';
export const filter_students = 'FILTER_STUDENTS';
export const cud_membership_pause = 'PUD_MEMBERSHIP_PAUSE';
export const cud_membership = 'PUD_MEMBERSHIP';
export const search_students = 'SEARCH_STUDENTS';

// action types for home
export const login_to_app = 'LOGIN_TO_APP';
export const set_home_variable = 'SET_HOME_VARIABLE';
export const log_out = 'LOG_OUT';
export const impersonate_user = 'IMPERSONATE_USER';

export const get_config = 'GET_CONFIG';

// action types for dashboard
export const get_dashboard_data = 'GET_DASHBOARD_DATA';

// action types for loading
export const set_loading_variable = 'SET_LOADING_VARIABLE';

// action types for sessions
export const get_past_sessions = 'GET_PAST_SESSIONS';
export const get_future_sessions = 'GET_FUTURE_SESSIONS';
export const get_replacement_sessions = 'GET_REPLACEMENT_SESSIONS';
export const get_replacement_session_teachers = 'GET_REPLACEMENT_SESSION_TEACHERS';
export const get_past_group_sessions = 'GET_PAST_GROUP_SESSIONS';
export const get_future_group_sessions = 'GET_FUTURE_GROUP_SESSIONS';
export const set_sessions_variable = 'SET_SESSIONS_VARIABLE';
export const update_future_group_sessions = 'UPDATE_FUTURE_GROUP_SESSION';
export const get_class_types = 'GET_CLASS_TYPES';
export const get_future_friends_sessions = 'GET_FUTURE_FRIENDS_SESSIONS';
export const get_past_friends_sessions = 'GET_PAST_FRIENDS_SESSIONS';
export const get_proposal_info = 'GET_PROPOSAL_INFO';
export const promote_proposal = 'PROMOTE_PROPOSAL';
export const reject_proposal = 'REJECT_PROPOSAL';
export const accept_proposal = 'ACCEPT_PROPOSAL';
export const proposal_teacher_request = 'PROPOSAL_TEACHER_REQUEST';
export const promote_form_info = 'PROMOTE_FORM_INFO';
export const community_proposal_accept = 'COMMUNITY_PROPOSAL_ACCEPT';
export const community_proposal_ignore = 'COMMUNITY_PROPOSAL_IGNORE';
export const community_cancel_proposal = 'COMMUNITY_CANCEL_PROPOSAL';
export const community_update_proposal_class_status = 'COMMUNITY_UPDATE_PROPOSAL_CLASS_STATUS';
export const modify_proposal_data = 'MODIFY_PROPOSAL_DATA';

// action types for test

export const test_saga = 'TEST_SAGA';

// action types for reports

export const set_reports_variable = 'SET_REPORTS_VARIABLE';
export const get_email_templates = 'GET_EMAIL_TEMPLATES';

// action types for access
export const get_permissions = 'GET_PERMISSIONS';

// action types for notifications
export const set_notification_variable = 'SET_NOTIFICATION_VARIABLE';

// action types for workshops
export const set_workshops_variable = 'SET_WORKSHOPS_VARIABLE';
export const get_all_workshops = 'GET_ALL_WORKSHOPS';

// action types for content
export const set_content_variable = 'SET_CONTENT_VARIABLE';
export const get_category_data = 'GET_CATEGORY_DATA';
export const get_category_list = 'GET_CATEGORY_LIST';
export const add_new_tag = 'ADD_NEW_TAG';
export const delete_tag = 'DELETE_TAG';
export const update_tag = 'UPDATE_TAG';
export const get_all_blogs = 'GET_ALL_BLOGS';
export const get_all_associated_tags = 'GET_ALL_ASSOCIATED_TAGS';
export const update_tag_associations = 'UPDATE_TAG_ASSOCIATIONS';
export const add_new_category = 'ADD_NEW_CATEGORY';
export const update_category = 'UPDATE_CATEGORY';
