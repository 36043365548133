import React from 'react';

function Table(props) {
  return <div className="cus-table">{props.children}</div>;
}

function RowHeader(props) {
  return <div className="ct-row ct-h">{props.children}</div>;
}

function Row(props) {
  return <div className="ct-row">{props.children}</div>;
}

function Col(props) {
  return <div className="ct-col">{props.children}</div>;
}

export function PauseMetrics({ pause_metrics }) {
  return (
    <div style={{ marginTop: '50px' }}>
      Pause Metrics:
      <Table>
        <RowHeader>
          <Col>Limit</Col>
          <Col>Consumed</Col>
          <Col>Remaining</Col>
          <Col>Reset On</Col>
        </RowHeader>
        <Row>
          <Col>{pause_metrics.pause_days_limit}</Col>
          <Col>{pause_metrics.total_paused_days_till_now}</Col>
          <Col>{pause_metrics.total_remaining_days}</Col>
          <Col>1st January {pause_metrics.next_reset_cycle_year}</Col>
        </Row>
      </Table>
    </div>
  );
}
