import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import SplitTestList from './split_test_list';
import SplitTest from './split_test';
import TestChoice from './split_test_choice';

export default class AbTesting extends Component {
  componentDidUpdate() {
    console.log(this.props);
  }

  render() {
    return (
      <Switch>
        <Route exact path="/split_testing/test" component={SplitTest} />
        <Route exact path="/split_testing/test/choice" component={TestChoice} />
        <Route exact path="*" component={SplitTestList} />
      </Switch>
    );
  }
}
