import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import ScrollContainer from '../../../util_components/scroll_container';
import TransCharts from './trans_charts';
import { get_api_url } from '../../../../utils/urls';

class AllTransactions extends Component {
  state = {
    page_error: false,
    loading: true,
    single_trans_open: true,
    subscription_open: true,
    sort_val: true,
    active_open: true,
    paused_open: true,
    cancelled_open: true
  };

  componentDidMount() {
    this.load_transaction_details();
  }

  load_transaction_details = () => {
    const url = get_api_url('list_all_transactions');
    const payload = { recurring: 0 };
    post_api(url, payload, true)
      .then((response) => {
        this.setState({ single_transactions: [...response.data.transaction] }, () => {
          const new_payload = { recurring: 1 };
          post_api(url, new_payload, true)
            .then((response) => {
              const active_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'ACTIVE' && a.is_group === 0
              );
              const paused_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'PAUSED' && a.is_group === 0
              );
              const cancelled_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'CANCELLED' && a.is_group === 0
              );
              const future_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'FUTURE' && a.is_group === 1
              );
              const group_active_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'ACTIVE' && a.is_group === 1
              );
              const group_paused_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'PAUSED' && a.is_group === 1
              );
              const group_cancelled_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'CANCELLED' && a.is_group === 1
              );
              const group_future_transactions = response.data.transaction.filter(
                (a) => a.subscription_status === 'FUTURE' && a.is_group === 1
              );
              this.setState(
                {
                  subscription_transactions: [...response.data.transaction],
                  active_transactions,
                  paused_transactions,
                  cancelled_transactions,
                  future_transactions,
                  group_active_transactions,
                  group_paused_transactions,
                  group_cancelled_transactions,
                  group_future_transactions
                },
                () => this.setState({ loading: false })
              );
            })
            .catch(() => this.setState({ page_error: true }));
        });
      })
      .catch(() => this.setState({ page_error: true }));
  };

  go_back = () => {
    this.props.history.goBack();
  };

  toggle_single_trans = () => this.setState({ single_trans_open: !this.state.single_trans_open });

  render_single_transactions = () => {
    const transactions = this.state.single_transactions;
    return (
      <div>
        <div className="details-session-logs-header" onClick={this.toggle_single_trans}>
          Single transactions: {transactions.length}
          {transactions.length !== 0 ? (
            <img
              className={this.state.single_trans_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {transactions.length !== 0 && this.state.single_trans_open ? (
          this.props.application_width > 700 ? (
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div className="ct-col">Student</div>
                <div className="ct-col">Session Time</div>
                <div className="ct-col">Transaction Time</div>
                <div className="ct-col ct-hf">Price</div>
                <div className="ct-col">Invoice Id</div>
                <div className="ct-col">Teacher Name</div>
              </div>
              {transactions.map((transaction) => {
                const teacher_url = `/teacherprofile?id=${transaction.teacher_uuid}`;
                const student_url = `/studentprofile?id=${transaction.student_uuid}`;
                return (
                  <div className="ct-row  ct-ho" key={transaction.invoice_id}>
                    <div className="ct-col">
                      <a className="link-no-dec" href={student_url}>
                        {transaction.student_name}
                      </a>
                    </div>
                    <div className="ct-col  ct-sm-font">{transaction.session_student_time}</div>
                    <div className="ct-col  ct-sm-font">{transaction.transaction_date}</div>
                    <div className="ct-col ct-hf">{transaction.purchase_price}</div>
                    <div className="ct-col  ct-sm-font">{transaction.invoice_id}</div>
                    <div className="ct-col">
                      <a className="link-no-dec" href={teacher_url}>
                        {transaction.teacher_name}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            this.render_subscriptions_mobile(transactions, 'single')
          )
        ) : null}
      </div>
    );
  };

  toggle_subscription_open = () =>
    this.setState({ subscription_open: !this.state.subscription_open });

  toggle_flag_subscription = (e) => {
    const { flag } = e.currentTarget.dataset;
    this.setState({ [`${flag}_open`]: !this.state[`${flag}_open`] });
  };

  sort_subscriptions_trans = (e) => {
    const { key } = e.currentTarget.dataset;
    const { flag } = e.currentTarget.dataset;
    const sub_trans = [...this.state[`${flag}_transactions`]];
    sub_trans.sort((a, b) => {
      if (this.state.sort_val) {
        if (a[key] < b[key]) return 1;
        return -1;
      }
      if (a[key] > b[key]) return 1;
      return -1;
    });
    this.setState({ [`${flag}_transactions`]: [...sub_trans], sort_val: !this.state.sort_val });
  };

  render_subscriptions_mobile = (transactions, tr_type) => (
    <div>
      {transactions.map((tr, index) => {
        const student_url = `/studentprofile?id=${tr.student_uuid}`;
        const teacher_url = `/teacherprofile?id=${tr.teacher_uuid}`;
        let column_class = 'common-mobile-column';
        column_class +=
          index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
        return (
          <div className={column_class} key={index + tr.invoice_id}>
            <div className="common-mobile-details">
              <div className="common-mobile-single-detail common-mobile-key">Student</div>
              <div className="common-mobile-single-detail">
                <a className="link-no-dec" href={student_url}>
                  {tr.student_name}
                </a>
              </div>
            </div>
            {tr_type === 'subs' ? (
              <>
                <div className="common-mobile-details">
                  <div className="common-mobile-single-detail common-mobile-key">Package Name</div>
                  <div className="common-mobile-single-detail">{tr.package_name}</div>
                </div>
                <div className="common-mobile-details">
                  <div className="common-mobile-single-detail common-mobile-key">Purchase Date</div>
                  <div className="common-mobile-single-detail">
                    {moment(tr.purchase_date)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm z')}
                  </div>
                </div>
              </>
            ) : null}
            {tr_type === 'single' ? (
              <>
                <div className="common-mobile-details">
                  <div className="common-mobile-single-detail common-mobile-key">Session Time</div>
                  <div className="common-mobile-single-detail">{tr.session_student_time}</div>
                </div>
                <div className="common-mobile-details">
                  <div className="common-mobile-single-detail common-mobile-key">
                    Transaction Time
                  </div>
                  <div className="common-mobile-single-detail">{tr.transaction_date}</div>
                </div>
              </>
            ) : null}
            <div className="common-mobile-details">
              <div className="common-mobile-single-detail common-mobile-key">Price</div>
              <div className="common-mobile-single-detail">{tr.purchase_price}</div>
            </div>
            {tr_type === 'subs' ? (
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Next Billing</div>
                <div className="common-mobile-single-detail">
                  {moment(tr.next_billing_date)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm z')}
                </div>
              </div>
            ) : null}
            <div className="common-mobile-details">
              <div className="common-mobile-single-detail common-mobile-key">Invoice Id</div>
              <div className="common-mobile-single-detail">{tr.invoice_id}</div>
            </div>
            {tr_type === 'subs' ? (
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Sessions Left</div>
                <div className="common-mobile-single-detail">{tr.no_of_session_left}</div>
              </div>
            ) : null}
            {tr_type === 'single' ? (
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Teacher Name</div>
                <div className="common-mobile-single-detail">
                  <a className="link-no-dec" href={teacher_url}>
                    {tr.teacher_name}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  render_subscriptions = (flag) => {
    const transactions = this.state[`${flag}_transactions`];
    const flag_state = this.state[`${flag}_open`];
    const file_name = `${flag}_memebers_data_${moment().format('YYYY-MM-DD')}`;
    const flag_camel = flag.split('_').join(' ');
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <ExcelFile
            element={
              <button className="data-download-btn">{`Download ${flag_camel} members data`}</button>
            }
            filename={file_name}
          >
            <ExcelSheet data={transactions} name="Subscriptions">
              <ExcelColumn label="First Name" value="student_name" />
              <ExcelColumn label="Last Name" value="student_last_name" />
              <ExcelColumn label="Email" value="student_email" />
              <ExcelColumn label="Package" value="package_name" />
              <ExcelColumn label="Purchase Date" value="purchase_date" />
              <ExcelColumn label="Status" value="subscription_status" />
              <ExcelColumn label="Next billing Date" value="next_billing_date" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div
          className="details-session-logs-header"
          onClick={this.toggle_flag_subscription}
          data-flag={flag}
        >
          {flag_camel.charAt(0).toUpperCase() + flag_camel.slice(1)} members: {transactions.length}
          {transactions.length !== 0 ? (
            <img
              className={flag_state ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {transactions.length !== 0 && flag_state ? (
          this.props.application_width > 700 ? (
            <div className="cus-table">
              <div className="ct-row ct-h">
                <div
                  className="ct-col ct-cur"
                  data-key="student_name"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Student
                </div>
                <div
                  className="ct-col ct-cur"
                  data-key="package_name"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Package Name
                </div>
                <div
                  className="ct-col ct-cur"
                  data-key="purchase_date"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Purchase Date
                </div>
                <div
                  className="ct-col ct-cur ct-hf"
                  data-key="purchase_price"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Price
                </div>
                <div
                  className="ct-col ct-cur"
                  data-key="next_billing_date"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Next Billing
                </div>
                <div className="ct-col">Invoice Id</div>
                <div
                  className="ct-col ct-cur ct-hf"
                  data-key="no_of_session_left"
                  data-flag={flag}
                  onClick={this.sort_subscriptions_trans}
                >
                  Sessions Left
                </div>
              </div>
              {transactions.map((transaction) => {
                const student_url = `/studentprofile?id=${transaction.student_uuid}`;
                return (
                  <div className="ct-row ct-ho" key={transaction.invoice_id}>
                    <div className="ct-col">
                      <a className="link-no-dec" href={student_url}>
                        {transaction.student_name}
                      </a>
                    </div>
                    <div className="ct-col ct-sm-font">{transaction.package_name}</div>
                    <div className="ct-col ct-sm-font">
                      {moment(transaction.purchase_date)
                        .tz(this.props.iana_timezone)
                        .format('YYYY-MM-DD hh:mm z')}
                    </div>
                    <div className="ct-col ct-hf">{transaction.purchase_price}</div>
                    <div className="ct-col ct-sm-font">
                      {moment(transaction.next_billing_date)
                        .tz(this.props.iana_timezone)
                        .format('YYYY-MM-DD hh:mm z')}
                    </div>
                    <div className="ct-col ct-sm-font">{transaction.invoice_id}</div>
                    <div className="ct-col ct-hf">{transaction.no_of_session_left}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            this.render_subscriptions_mobile(transactions, 'subs')
          )
        ) : null}
      </div>
    );
  };

  render_content = () => {
    if (this.state.page_error) {
      return (
        <div className="common-page-container">
          <div className="common-page-header">
            <div className="common-page-title">All Transactions</div>
          </div>
          <span>Oops! something happened. Check your url or try again</span>
        </div>
      );
    }
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="common-page-title">All Transactions</div>
        </div>
        <TransCharts data={this.state.single_transactions} />

        {this.render_subscriptions('active')}
        {this.render_subscriptions('group_active')}
        {this.render_subscriptions('paused')}
        {this.render_subscriptions('group_paused')}
        {this.render_subscriptions('cancelled')}
        {this.render_subscriptions('group_cancelled')}
        {this.render_subscriptions('future')}
        {this.render_subscriptions('group_future')}
        {this.render_single_transactions()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Students Transactions</title>
        </Helmet>
        <ScrollContainer />
        {this.render_content()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  application_width: state.home.application_width
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(AllTransactions);
