import { all } from 'redux-saga/effects';
import TeachersSaga from './teachers_saga';
import homeSaga from './home_saga';
import StudentSaga from './students_saga';
import ReportsSaga from './reports_saga';

import SessionsSaga from './sessions_saga';
import WorkshopsSage from './workshops_saga';
import ContentSage from './content_saga';

export default function* rootSaga() {
  yield all([
    TeachersSaga(),
    homeSaga(),
    StudentSaga(),
    ReportsSaga(),
    SessionsSaga(),
    WorkshopsSage(),
    ContentSage()
  ]);
}
