import * as t from '../action_types';

export const reset = () => ({
  type: t.reset
});

export const set_home_variable = (key, payload) => ({
  type: t.set_home_variable,
  payload: {
    key,
    payload
  }
});

export const login_to_app = (payload) => ({
  type: t.login_to_app,
  payload
});

export const get_dashboard_data = () => ({
  type: t.get_dashboard_data
});

export const log_out = () => ({
  type: t.log_out
});

export const get_config = () => ({
  type: t.get_config
});

export const impersonate_user = (user_uuid, user_type, is_uk_user, client_domain) => ({
  type: t.impersonate_user,
  payload: {
    user_uuid,
    user_type,
    is_uk_user,
    client_domain
  }
});

export const set_loading_variable = (key, payload) => ({
  type: t.set_loading_variable,
  payload: {
    key,
    payload
  }
});

export const test_saga = (payload) => ({
  type: t.test_saga,
  payload
});

export const get_permissions = () => ({
  type: t.get_permissions
});

export const set_notification_variable = (
  show_notif,
  notif_type,
  notif_text,
  notif_dur = 20000
) => ({
  type: t.set_notification_variable,
  payload: { show_notif, notif_type, notif_text, notif_dur }
});

export const get_class_types = (payload) => ({
  type: t.get_class_types,
  payload
});

export const community_proposal_ignore = (payload) => ({
  type: t.community_proposal_ignore,
  payload
});
