import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import CustomLoading from '../../util_components/custom_loading';
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import CustomSingleSelect from '../../util_components/custom_single_select';
import Button from '../../util_components/button';
import { long_session_duration_list } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class WorkshopSession extends Component {
  state = {
    loading: true,
    session_details: {},
    create_err: false,
    workshop_session_name: '',
    workshop_session_description: '',
    start_time: '',
    duration: '',
    edit_details: false,
    open_change_teacher: false,
    reason: '',
    teacher_uuid: '',
    co_teachers: [],
    cancel_open: false,
    cancel_reason: ''
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('uuid');
    if (uuid) {
      this.setState({ session_uuid: uuid }, () => this.load_data());
    } else {
      this.props.history.push('/workshops');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('workshop_session_details');
      const payload = { session_uuid: this.state.session_uuid };
      post_api(url, payload, true).then((res) => {
        this.setState({
          session_details: res.data.details,
          co_teachers: res.data.co_teachers,
          loading: false
        });
      });
    });
  };

  go_back = () => this.props.history.goBack();

  set_create_time = (date) => this.setState({ start_time: date });

  open_edit_session = () => {
    const { session_details } = this.state;
    const start_time = moment(
      moment(session_details.start_time)
        .tz(this.props.iana_timezone)
        .format('YYYY-MM-DD hh:mm:ss A')
    ).toDate();
    this.setState({
      start_time,
      edit_details: true,
      workshop_session_name: session_details.workshop_session_name,
      workshop_session_description: session_details.workshop_session_description,
      duration: session_details.duration
    });
  };

  close_edit = () =>
    this.setState({
      edit_details: false,
      workshop_session_name: '',
      workshop_session_description: '',
      open_change_teacher: false,
      teacher_uuid: '',
      reason: '',
      start_time: '',
      cancel_open: false,
      duration: ''
    });

  cancel_session = () => this.setState({ cancel_open: true, cancel_reason: '' });

  render_details = () => {
    const { session_details } = this.state;
    console.log(session_details);
    const session_duration =
      session_details.duration > 120
        ? `${session_details.duration / 60} hrs`
        : `${session_details.duration} min`;
    return (
      <>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Name: </td>
              <td>{session_details.workshop_session_name}</td>
              <td className="teacher-table-detail">Workshop session uuid:</td>
              <td>{session_details.uuid}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Start time: </td>
              <td>
                {moment(session_details.start_time)
                  .tz('America/Los_Angeles')
                  .format('YYYY-MM-DD hh:mm A z')}
              </td>
              <td className="teacher-table-detail">Duration:</td>
              <td>{session_duration}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Teacher: </td>
              <td>{session_details.teacher_name}</td>
              <td className="teacher-table-detail">Status</td>
              <td>{session_details.status}</td>
            </tr>
          </tbody>
        </table>
        <button onClick={this.open_edit_session}>Edit details</button>
        <button onClick={this.change_teacher}>Change teacher</button>
        {session_details.status === 'SCHEDULED' || session_details.status === 'ACTIVE' ? (
          <button onClick={this.cancel_session}>Cancel session</button>
        ) : null}
        <div style={{ margin: '20px 0' }}>
          Session Description:
          <div style={{ margin: '5px 0', fontSize: '14px', letterSpacing: '0.3px' }}>
            {session_details.workshop_session_description}
          </div>
        </div>
      </>
    );
  };

  change_teacher = () => this.setState({ open_change_teacher: true });

  edit_workshop_session = () => {
    const {
      workshop_session_name,
      workshop_session_description,
      duration,
      session_uuid,
      start_time
    } = this.state;
    if (!!workshop_session_name && !!workshop_session_name) {
      const sel_time = moment(start_time).format('YYYY-MM-DD HH:mm:ss');
      const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
      const payload = {
        session_uuid,
        workshop_session_name,
        workshop_session_description,
        duration,
        start_time: epoch_time
      };
      const url = get_api_url('update_workshop_session');
      this.setState({ loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Workshop session updated');
            this.close_edit();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ create_err: true });
    }
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, create_err: false });
  };

  render_edit_workshop = () => {
    const {
      create_err,
      workshop_session_name,
      workshop_session_description,
      duration,
      start_time
    } = this.state;
    const err_sty = create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    return (
      <div>
        <h2>Edit Workshop Session</h2>
        <div className="display-flex-between">
          <CustomInput
            label="Enter session name *"
            onChange={this.onCommonChange}
            name="workshop_session_name"
            value={workshop_session_name}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session duration"
            options={long_session_duration_list}
            onChange={this.onCommonChange}
            name="duration"
            value={duration}
            style={{ width: '250px' }}
          />
        </div>
        <div>
          <div>{`Select time (${curr_timezone})`}</div>
          <DatePicker
            selected={start_time}
            onChange={(date) => this.set_create_time(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
        <div>Enter session description:</div>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={workshop_session_description}
          name="workshop_session_description"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <p style={err_sty}>All fields are compulsory</p>
        <button onClick={this.edit_workshop_session}>Submit</button>
      </div>
    );
  };

  submit_cancel = () =>
    this.setState({ loading: true }, () => {
      const { session_uuid, cancel_reason } = this.state;
      const url = get_api_url('cancel_group_session');
      const payload = { session_uuid, reason: cancel_reason, is_workshop: 1 };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Workshop session updated');
          this.close_edit();
          this.load_data();
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_cancel = () => {
    const { cancel_reason } = this.state;
    return (
      <div>
        <h4>Cancel session?</h4>
        <CustomInput
          label="Enter cancel reason *"
          onChange={this.onCommonChange}
          name="cancel_reason"
          value={cancel_reason}
          style={{ width: '250px' }}
        />
        <Button disabled={!cancel_reason} onClick={this.submit_cancel}>
          Submit
        </Button>
      </div>
    );
  };

  submit_change_teacher = () =>
    this.setState({ loading: true }, () => {
      const { teacher_uuid, session_details, reason } = this.state;
      const url = get_api_url('change_teacher_for_group_session');
      const payload = { teacher_uuid, reason, session_uuid: session_details.uuid, is_workshop: 1 };
      post_api(url, payload, true)
        .then(() => {
          this.close_edit();
          this.props.set_notification_variable(true, 'success', 'Teacher successfully changed');
          this.load_data();
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_change_teacher = () => {
    const { teacher_uuid, co_teachers, reason } = this.state;
    return (
      <div>
        <h4>Change teacher</h4>
        <CustomSingleSelect
          label="Select teacher"
          options={co_teachers}
          onChange={this.onCommonChange}
          name="teacher_uuid"
          value={teacher_uuid}
          style={{ width: '250px' }}
        />
        <CustomInput
          label="Enter reason *"
          onChange={this.onCommonChange}
          name="reason"
          value={reason}
          style={{ width: '250px' }}
        />
        <Button disabled={!teacher_uuid || !reason} onClick={this.submit_change_teacher}>
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const { loading, session_details, edit_details, open_change_teacher, cancel_open } = this.state;
    return (
      <div className="common-page-container">
        {loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Workshop Session</div>
        </div>
        <CustomModal show={edit_details} close={this.close_edit}>
          {this.render_edit_workshop()}
        </CustomModal>
        <CustomModal show={open_change_teacher} close={this.close_edit}>
          {this.render_change_teacher()}
        </CustomModal>
        <CustomModal show={cancel_open} close={this.close_edit}>
          {this.render_cancel()}
        </CustomModal>
        {Object.keys(session_details).length !== 0 ? this.render_details() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopSession);
