import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../util_components/custom_loading';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomInput from '../../util_components/custom_input';
import CategoryTemplate from './category_template';
import CustomModal from '../../util_components/custom_modal';
import Button from '../../util_components/button';
import './content.css';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_filter_type: 'all',
      loading: true,
      category_details: [],
      open_create_tag: false,
      open_edit_tag: false,
      tag_name: '',
      category_list: [],
      selected_category_id: '',
      open_create_category: false,
      open_edit_category: false,
      category_name: ''
    };
  }

  componentDidMount() {
    if (this.props.get_category_list_status === 'none') {
      this.props.getCategoryList();
    }
    if (this.props.get_content_category_status === 'none') {
      this.props.getCategoryData();
    }
    if (this.props.get_content_category_status === 'success') {
      this.setState({
        category_list: this.props.get_category_list_response,
        loading: false
      });
    }
    if (this.props.get_content_category_status === 'success') {
      this.setState({
        category_details: this.props.get_content_category_response,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_category_list_status === 'loading' &&
      this.props.get_category_list_status === 'success'
    ) {
      this.setState({
        category_list: this.props.get_category_list_response,
        loading: false
      });
    }
    if (
      prevProps.get_content_category_status === 'loading' &&
      this.props.get_content_category_status === 'success'
    ) {
      this.setState({
        category_details: this.props.get_content_category_response,
        loading: false
      });
    }
    if (prevProps.add_new_tag_status === 'loading' && this.props.add_new_tag_status === 'success') {
      this.props.getCategoryData();
      this.setState({
        loading: false,
        open_create_tag: false,
        open_edit_tag: false,
        selected_category_id: '',
        tag_name: ''
      });
    }
    if (prevProps.delete_tag_status === 'loading' && this.props.delete_tag_status === 'success') {
      this.props.getCategoryData();
      this.setState({
        loading: false
      });
    }
    if (prevProps.update_tag_status === 'loading' && this.props.update_tag_status === 'success') {
      this.props.getCategoryData();
      this.setState({
        loading: false,
        open_create_tag: false,
        open_edit_tag: false,
        selected_category_id: '',
        tag_name: ''
      });
    }
    if (
      prevProps.add_new_category_status === 'loading' &&
      this.props.add_new_category_status === 'success'
    ) {
      this.props.getCategoryData();
      this.setState({
        loading: false,
        open_create_category: false,
        open_edit_category: false,
        selected_category_id: '',
        category_name: ''
      });
    }
    if (
      prevProps.update_category_status === 'loading' &&
      this.props.update_category_status === 'success'
    ) {
      this.props.getCategoryData();
      this.setState({
        loading: false,
        open_create_category: false,
        open_edit_category: false,
        selected_category_id: '',
        category_name: ''
      });
    }
  }

  change_category_filter = (e) => {
    this.setState({
      category_filter_type: e.target.value
    });
    if (e.target.value.toLowerCase() === 'all') {
      this.setState({
        loading: true
      });
      this.props.getCategoryData();
    } else {
      this.filter_category(e.target.value);
    }
  };

  filter_category = (category_name) => {
    const filtered_arr = this.props.get_content_category_response?.data.filter(
      (item) => item.category_name.toLowerCase() === category_name.toLowerCase()
    );
    this.setState({
      category_details: filtered_arr
    });
  };

  on_add_new_category = () => {
    this.setState({
      open_create_category: true,
      open_edit_category: false
    });
  };

  on_edit_category = (category_uuid, category_name) => {
    this.setState({
      open_edit_category: true,
      open_create_category: false,
      selected_category_id: category_uuid,
      category_name
    });
  };

  close_category_popup = () => {
    this.setState({
      open_edit_category: false,
      open_create_category: false,
      selected_category_id: '',
      category_name: ''
    });
  };

  on_add_new_tag = (category_id) => {
    this.setState({
      open_create_tag: true,
      open_edit_tag: false,
      selected_category_id: category_id
    });
  };

  on_edit_tag = (category_id, tag_name, slugname) => {
    this.setState({
      // open_edit_tag : true,
      open_create_tag: false,
      selected_category_id: category_id,
      tag_name
    });
    const slug_name = slugname;
    const path_name = `/content/tags/${slug_name}`;
    // let path_name = '/sessions'
    const payload = {
      pathname: path_name,
      slug_name
    };
    if (this.props.history) {
      this.props.history.push(payload);
    }
  };

  close_create_tag = () =>
    this.setState({
      open_create_tag: false,
      open_edit_tag: false,
      selected_category_id: '',
      tag_name: ''
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  render_create_tag = () => {
    const { open_edit_tag, tag_name } = this.state;
    const title = open_edit_tag ? 'Edit tag' : 'Add new tag';
    return (
      <div className="create-tag-name">
        <h4>{title}</h4>
        <CustomInput
          label="Enter Tag*"
          onChange={this.onCommonChange}
          name="tag_name"
          value={tag_name}
          style={{ width: '100%' }}
          label_style={{ color: '#aaa', fontSize: '12px', fontWeight: '600' }}
          input_style={{ margin: '2', marginBottom: '20px', fontSize: '14px', height: 'auto' }}
        />
        <div className="create-tag-name-button">
          <Button
            disabled={!tag_name}
            onClick={open_edit_tag ? this.on_update_tag : this.on_submit_new_tag}
          >
            {open_edit_tag ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    );
  };

  render_create_category = () => {
    const { open_edit_category, category_name } = this.state;
    const title = open_edit_category ? 'Edit category' : 'Add new category';
    return (
      <div className="create-tag-name">
        <h4>{title}</h4>
        <CustomInput
          label="Enter Category*"
          onChange={this.onCommonChange}
          name="category_name"
          value={category_name}
          style={{ width: '100%' }}
          label_style={{ color: '#aaa', fontSize: '12px', fontWeight: '600' }}
          input_style={{ margin: '2', marginBottom: '20px', fontSize: '14px', height: 'auto' }}
        />
        <div className="create-tag-name-button">
          <Button
            disabled={!category_name}
            onClick={open_edit_category ? this.on_update_category : this.on_submit_category}
          >
            {open_edit_category ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    );
  };

  on_update_tag = () => {
    this.setState({
      loading: true
    });

    const payload = {
      UUID: this.state.selected_category_id,
      Name: this.state.tag_name
    };
    this.props.updateTag(payload);
  };

  on_submit_new_tag = () => {
    this.setState({
      loading: true
    });
    const payload = {
      tag_category_uuid: this.state.selected_category_id,
      name: this.state.tag_name
    };
    this.props.addNewTag(payload);
  };

  on_delete_tag = (tag_uuid) => {
    this.setState({
      loading: true
    });
    const payload = {
      UUID: tag_uuid
    };
    this.props.deleteTag(payload);
  };

  on_submit_category = () => {
    this.setState({
      loading: true
    });
    const payload = {
      Name: this.state.category_name
    };
    this.props.addNewCategory(payload);
  };

  on_update_category = () => {
    this.setState({
      loading: true
    });
    const payload = {
      UUID: this.state.selected_category_id,
      Name: this.state.category_name
    };
    this.props.updateCategory(payload);
  };

  render() {
    const { loading, open_create_tag, open_edit_tag, open_create_category, open_edit_category } =
      this.state;
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tags</title>
        </Helmet>
        {loading ? (
          <CustomLoading />
        ) : (
          <>
            <div className="content-tag-header">
              <div style={{ marginRight: '10px' }}>
                <CustomSingleSelect
                  label="Select category"
                  options={this.state.category_list}
                  onChange={this.change_category_filter}
                  value={this.state.category_filter_type}
                  style={{ width: '200px' }}
                />
              </div>
            </div>
            <CategoryTemplate
              category_details={this.state.category_details}
              on_add_new_tag={this.on_add_new_tag}
              on_edit_tag={this.on_edit_tag}
              on_delete_tag={this.on_delete_tag}
              on_add_new_category={this.on_add_new_category}
              on_edit_category={this.on_edit_category}
            />
          </>
        )}
        <CustomModal show={open_create_tag || open_edit_tag} close={this.close_create_tag}>
          {this.render_create_tag()}
        </CustomModal>

        <CustomModal
          show={open_create_category || open_edit_category}
          close={this.close_category_popup}
        >
          {this.render_create_category()}
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  get_content_category_status: state.content.get_content_category_status,
  get_content_category_response: state.content.get_content_category_response,
  get_category_list_status: state.content.get_category_list_status,
  get_category_list_response: state.content.get_category_list_response,
  add_new_tag_status: state.content.add_new_tag_status,
  delete_tag_status: state.content.delete_tag_status,
  update_tag_status: state.content.update_tag_status,
  add_new_category_status: state.content.add_new_category_status,
  update_category_status: state.content.update_category_status
});

const mapDispatchToProps = (dispatch) => ({
  getCategoryData: () => dispatch(actions.get_category_data()),
  getCategoryList: () => dispatch(actions.get_category_list()),
  addNewTag: (payload) => dispatch(actions.add_new_tag(payload)),
  deleteTag: (payload) => dispatch(actions.delete_tag(payload)),
  updateTag: (payload) => dispatch(actions.update_tag(payload)),
  addNewCategory: (payload) => dispatch(actions.add_new_category(payload)),
  updateCategory: (payload) => dispatch(actions.update_category(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
