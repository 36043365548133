import React from 'react';
import './custom_loading.css';

function CustomLoading(props) {
  const loading_class = props.full_loader ? 'custom-loading-full' : '';
  return (
    <div className={`custom-loading ${loading_class}`}>
      <img src="https://images.myyogateacher.com/myt-logo.png" alt="logo" />
    </div>
  );
}

export default CustomLoading;
