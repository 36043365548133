import React, { Component } from 'react';
import { connect } from 'react-redux';
import HighlightSearch from '../../util_components/highlight_search_text';
import CommonHeader from '../../util_components/common_header';
import * as actions from '../../../redux/action_creators';
import { searchBlogPosts } from '../../blog/graph-client';
import './content.css';

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_value: '',
      loading: true,
      all_blogs: {}
    };
  }

  componentDidMount() {
    if (this.props.get_all_blogs_status === 'none') {
      this.props.getAllBlogs();
    }
    if (this.props.get_all_blogs_status === 'success') {
      this.setState({
        all_blogs: this.props.get_all_blogs_response,
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_all_blogs_status === 'loading' &&
      this.props.get_all_blogs_status === 'success'
    ) {
      this.setState({
        all_blogs: this.props.get_all_blogs_response,
        loading: false
      });
    }
  }

  handle_update = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, create_err: false });
  };

  render_type_header = () => {
    const { search_value } = this.state;
    return (
      <div className="content-tag-header">
        <input
          value={search_value}
          onChange={this.handle_update}
          name="search_value"
          placeholder="Search by name"
          className="content-search-input"
          style={{ marginBottom: 30 }}
        />
        <button onClick={this.search_blog}>Submit</button>
      </div>
    );
  };

  search_blog = () => {
    const { search_value } = this.state;
    if (search_value) {
      this.setState({ loading: true }, () => {
        searchBlogPosts(search_value)
          .then((posts) => {
            console.log(posts);
            this.setState({ loading: false, all_blogs: posts });
          })
          .catch((e) => {
            console.log(e);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ all_blogs: this.props.get_all_blogs_response });
    }
  };

  open_details = (association_id, title, slug) => {
    const url = `/tag_associations/blogs/${association_id}?title=${title}&slug=${slug}`;
    this.props.history.push(url);
  };

  render_blog_details = () => {
    const { search_value, all_blogs } = this.state;
    // const blogs_list = Object.values(all_blogs)
    // const searched_blogs = blogs_list.filter(g => typeof g ==='object' && g.title.toLowerCase().indexOf(search_value.toLowerCase()) !== -1)

    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-la ct-pl-10">Name</div>
          <div className="ct-col ct-qf">Details</div>
          <div className="ct-col ct-qf">Preview</div>
        </div>
        {Object.values(all_blogs).length !== 0 &&
          Object.values(all_blogs).map((blog) => {
            if (typeof blog === 'object') {
              return (
                <div className="ct-row" key={blog?.id}>
                  <div className="ct-col ct-sm-font ct-bw ct-la ct-pl-10">
                    <HighlightSearch full_text={blog?.title} search_text={search_value} />
                  </div>

                  <div className="ct-col ct-qf blog-details-link">
                    <a
                      className="link-btn"
                      href={`/tag_associations/blogs/${blog.id}?title=${blog.title}&slug=${blog.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Details
                    </a>
                  </div>
                  <div className="ct-col ct-qf blog-details-link">
                    <a
                      className="link-btn"
                      href={`https://myyogateacher.com/articles/${blog.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Blogs">
        {this.render_type_header()}
        {this.render_blog_details()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  get_all_blogs_status: state.content.get_all_blogs_status,
  get_all_blogs_response: state.content.get_all_blogs_response
});

const mapDispatchToProps = (dispatch) => ({
  getAllBlogs: () => dispatch(actions.get_all_blogs())
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
