import React, { PureComponent } from 'react';
import HtmlReactParser from 'html-react-parser';
import './template.css';
import './common_template.css';

export default class ReportTemplate extends PureComponent {
  render() {
    const details = this.props.report_details;
    return (
      <table
        className="report-width-full report-template-table-2 outer-table"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        height="100%"
        id="outer-table"
        align="center"
      >
        <tbody>
          <tr>
            <td width="100%" valign="top" className="report-width-full">
              <h2 className="report-h2-1">Signup & sessions report</h2>
              <h3 style={{ opacity: '0.5' }}>
                {this.props.start_date}
                <span>{` - `}</span>
                {this.props.end_date}
              </h3>
              <p style={{ textAlign: 'center' }}>
                Signups & sessions scheduled in the give date range
              </p>
            </td>
          </tr>
          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>1. Signup</h2>
              <table className="data-table" style={{ textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <th>Unverified</th>
                    <th>Verified</th>
                    <th>Web</th>
                    <th>Mobile</th>
                  </tr>
                  <tr>
                    <td>{details.unverified_users_count}</td>
                    <td>{details.verified_users_count}</td>
                    <td>{details.web_users_count}</td>
                    <td>{details.mobile_users_count}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>2.) From Facebook</h2>
              <table className="data-table">
                <thead>
                  <tr>{HtmlReactParser(details.facebook_utm_content_headers)}</tr>
                  <tr>{HtmlReactParser(details.facebook_utm_content_sub_headers)}</tr>
                </thead>
                <tbody>
                  <tr>{HtmlReactParser(details.facebook_utm_content_data)}</tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>3. Users registered</h2>

              <h5>Verified Users</h5>
              <table
                className="data-table verified-user-table"
                style={{ backgroundColor: '#f9fff9' }}
              >
                <tbody>
                  <tr>
                    <th rowSpan="2">Email</th>
                    <th rowSpan="2">Timezone</th>
                    <th rowSpan="2">Source</th>
                    <th colSpan="4">UTM</th>
                  </tr>
                  <tr>
                    <th>Source</th>
                    <th>Campaign</th>
                    {/* <th>Medium</th> */}
                    <th>Content</th>
                  </tr>
                  {HtmlReactParser(details.verified_users_data)}
                </tbody>
              </table>

              <h5>Unverified Users</h5>
              <table
                className="data-table unverfied-users-table"
                style={{ backgroundColor: '#f9fff9' }}
              >
                <tbody>
                  <tr>
                    <th rowSpan="2">Email</th>
                    <th rowSpan="2">Timezone</th>
                    <th rowSpan="2">Source</th>
                    <th colSpan="4">UTM</th>
                  </tr>
                  <tr>
                    <th>Source</th>
                    <th>Campaign</th>
                    {/* <th>Medium</th> */}
                    <th>Content</th>
                  </tr>
                  {HtmlReactParser(details.unverified_users_data)}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>4.) Sessions scheduled</h2>
              <table className="data-table">
                <tbody>
                  <tr>
                    <th colSpan="2">Scheduled by</th>
                    <th rowSpan="2">1st free session</th>
                    <th rowSpan="2">1st paid session</th>
                    <th rowSpan="2">Total free sessions</th>
                    <th rowSpan="2">Total paid sessions</th>
                  </tr>
                  <tr>
                    <th>New users</th>
                    <th>Existing users</th>
                  </tr>
                  <tr>
                    <td>{details.session_scheduled_new_students_count}</td>
                    <td>{details.session_scheduled_existing_students_count}</td>
                    <td>{details.is_first_free_session_count}</td>
                    <td>{details.is_first_paid_session_count}</td>
                    <td>{details.free_session_count}</td>
                    <td>{details.paid_session_count}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-width-full">
              <h2 style={{ textAlign: 'center', margin: '50px 0px 5px' }}>
                Sessions list and quality parameters
              </h2>
              <p style={{ textAlign: 'center', margin: '5px 0px 10px' }}>
                (within last 24 hours, irrespective of when they were scheduled by students)
              </p>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>5.) Sessions completeness</h2>
              <table className="data-table">
                <tbody>
                  <tr>
                    <th rowSpan="2">Finished</th>
                    <th colSpan="2">Cancelled</th>
                    <th colSpan="3">No show</th>
                  </tr>
                  <tr>
                    <th>by student</th>
                    <th>by teacher</th>
                    <th>student</th>
                    <th>teacher</th>
                    <th>both</th>
                  </tr>
                  <tr>
                    <td>{details.session_finish_count}</td>
                    <td>{details.session_student_cancelled_count}</td>
                    <td>{details.session_teacher_cancelled_count}</td>
                    <td>{details.session_student_no_show_count}</td>
                    <td>{details.session_teacher_no_show_count}</td>
                    <td>{details.session_student_teacher_no_show_count}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>6.) Sessions quality</h2>
              <table className="data-table">
                <tbody>
                  <tr>
                    <th rowSpan="2">Finished</th>
                    <th colSpan="6">Rating</th>
                  </tr>
                  <tr>
                    <th>0</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                  </tr>
                  <tr>
                    <td>{details.session_finish_count}</td>
                    <td>{details.session_rating_counts['0']}</td>
                    <td>{details.session_rating_counts['1']}</td>
                    <td>{details.session_rating_counts['2']}</td>
                    <td>{details.session_rating_counts['3']}</td>
                    <td>{details.session_rating_counts['4']}</td>
                    <td>{details.session_rating_counts['5']}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>7.) Sessions feedback</h2>
              <table className="data-table">
                <tbody>
                  <tr>
                    <th>Setup</th>
                    <th>Video</th>
                    <th>Audio</th>
                    <th>Teacher</th>
                    <th>Internet</th>
                    <th>Language</th>
                    <th>Communication</th>
                  </tr>
                  <tr>
                    <td>{details.session_feedback_counts.setup}</td>
                    <td>{details.session_feedback_counts.video}</td>
                    <td>{details.session_feedback_counts.audio}</td>
                    <td>{details.session_feedback_counts.teacher}</td>
                    <td>{details.session_feedback_counts.internet}</td>
                    <td>{details.session_feedback_counts.language}</td>
                    <td>{details.session_feedback_counts.communication}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>8.) Sessions list</h2>
              <h5>Completed</h5>
              {/* <table className="data-table session-list" style={{ width: '1000px', overflow: 'scroll' }}></table> */}
              <table className="data-table session-list" style={{ overflow: 'scroll' }}>
                <tbody>
                  <tr>
                    <th>Student</th>
                    <th>Teacher</th>
                    <th style={{ width: '150px', padding: '5px' }}>Start time</th>
                    <th style={{ backgroundColor: '#f3fff3' }}>Rating</th>
                    <th>Review</th>
                    <th style={{ width: '200px', padding: '5px' }}>Feedback</th>
                    <th>Student Device</th>
                  </tr>
                  {HtmlReactParser(details.session_completed_data)}
                </tbody>
              </table>

              <h5 style={{ marginTop: '80px' }}>Cancelled</h5>
              <table className="data-table cancel-table" style={{ backgroundColor: '#fff9f9' }}>
                <tbody>
                  <tr>
                    <th>Student</th>
                    <th>Teacher</th>
                    <th style={{ width: '100px' }}>Cancelled by</th>
                    <th>Reason</th>
                  </tr>
                  {HtmlReactParser(details.session_cancelled_data)}
                </tbody>
              </table>

              <h5 style={{ marginTop: '80px' }}>No Show</h5>

              <table className="data-table no-show-table" style={{ backgroundColor: '#fff8f5' }}>
                <tbody>
                  <tr>
                    <th>Student</th>
                    <th>Teacher</th>
                    <th>No show</th>
                  </tr>
                  {HtmlReactParser(details.session_no_show_data)}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td width="100%" valign="top" className="report-td-3">
              <h2>9.) Session Payment</h2>

              <h5 style={{ marginTop: '80px' }}>Transactions</h5>

              <table className="data-table no-show-table" style={{ backgroundColor: '#fff8f5' }}>
                <tbody>
                  <tr>
                    <th>Student</th>
                    <th>Teacher</th>
                    <th>Session Time</th>
                    <th>Purchase Time</th>
                    <th>Invoice Id</th>
                    <th>Amount charged</th>
                  </tr>
                  {HtmlReactParser(details.transaction_charged_data)}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
