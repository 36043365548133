import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import App from './App';
import './index.css';
import './App.css';
import './common.css';

const shouldEnableSentry = () => {
  if (
    import.meta.env.VITE_APP_STAGE !== 'production' ||
    import.meta.env.SSR ||
    window.location.hostname.includes('local')
  ) {
    return false;
  }

  return true;
};

Sentry.init({
  dsn: 'https://e341f8dd761adfe882abb53a5a6d1df9@o4507713531936768.ingest.us.sentry.io/4507854393507840',
  integrations: [
    // Sentry.captureConsoleIntegration(),
  ],
  environment: import.meta.env.VITE_APP_STAGE,
  autoSessionTracking: true,
  enabled: shouldEnableSentry()
});

const store = configureStore();

export default class NewApp extends React.Component {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={<div>Loading</div>}>
        <Provider store={store}>
          <App />
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}
ReactDOM.render(<NewApp />, document.getElementById('root'));
