import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import CustomPlayer from '../../../util_components/custom_video_react';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import { date_drop_for_report } from '../../../../constants';
import { get_date_filter_data } from '../../../../util_functions';
import { get_api_url } from '../../../../utils/urls';

export default class StudentSessionStats extends Component {
  state = {
    start_date: '',
    end_date: '',
    loading: true,
    date_filter_type: 'THIS WEEK',
    show_video: false,
    video_url: ''
  };

  componentDidMount() {
    const start_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    const end_date = moment().format('YYYY-MM-DD');
    this.setState({ start_date, end_date, end_time }, () => {
      this.load_data();
    });
  }

  load_data = () => {
    const url = get_api_url('get_student_session_stats');
    const type = this.state.date_filter_type;
    const date_data = get_date_filter_data(type, this.state.start_date, this.state.end_date);
    const payload = {
      start_date: date_data.final_start_date,
      end_date: date_data.final_end_date,
      iana_timezone: this.props.iana_timezone
    };
    if (!(type === 'CUSTOM' && !this.state.submit_click)) {
      this.setState(
        {
          selected_start_date: date_data.final_start_date,
          selected_end_date: date_data.final_end_date,
          loading: true
        },
        () => {
          post_api(url, payload, true).then((result) =>
            this.setState({
              report_details: [...result.data.results],
              loading: false,
              first_load: false,
              submit_click: false
            })
          );
        }
      );
    }
  };

  render_data = () => {
    const details = this.state.report_details;
    return (
      <>
        {/* <Charts data={this.state.report_details} /> */}
        <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.3)' }}>
          The data is from <span style={{ color: 'black' }}>{this.state.selected_start_date}</span>{' '}
          to <span style={{ color: 'black' }}>{this.state.selected_end_date}</span>
        </div>
        <table>
          <tbody>
            <tr className="tr-border">
              <th style={{ width: '30%' }}>Session Details</th>
              <th style={{ width: '40%' }}>Session Quality</th>
              <th style={{ width: '30%' }}>Payment Info</th>
            </tr>
            {details.map((item) => {
              const teacher_url = `/teacherprofile?id=${item.teacher_uuid}`;
              const student_url = `/studentprofile?id=${item.student_uuid}`;
              const session_url = `/sessiondetails?id=${item.session_uuid}`;
              const teacher_classname =
                item.teacher_first_join_time > item.student_first_join_time &&
                item.teacher_first_join_time > item.session_start_time
                  ? { color: 'red' }
                  : null;
              return (
                <tr key={item.session_uuid} className="tr-border-light">
                  <td style={{ textAlign: 'left' }} className="low-text">
                    Student:{' '}
                    <a href={student_url} target="_blank" rel="noopener noreferrer">
                      <span className="high-text">{item.student_name}</span>
                    </a>
                    <br />
                    Teacher:{' '}
                    <a href={teacher_url} target="_blank" rel="noopener noreferrer">
                      <span className="high-text">{item.teacher_name}</span>
                    </a>
                    <br />
                    <a href={session_url} target="_blank" rel="noopener noreferrer">
                      <span className="high-text">Session</span>
                    </a>
                    <br />
                    Student Device: {item.device_student}
                    <br />
                    Teacher Device: {item.device_teacher}
                    <br />
                    Start time:
                    {` ${moment(item.session_start_time)
                      .tz(item.student_iana_timezone)
                      .format('MMM DD hh:mm A z')}`}
                    <br />
                    Scheduled at:
                    {` ${moment(item.session_created_date)
                      .tz(item.student_iana_timezone)
                      .format('MMM DD hh:mm A z')}`}
                    <br />
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }} className="low-text">
                    {item.replay_url ? (
                      <a
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => this.open_video(item.replay_url)}
                      >
                        Replay
                      </a>
                    ) : null}
                    <br />
                    <span>
                      Student Join Time :{' '}
                      {` ${moment(item.student_first_join_time)
                        .tz(item.student_iana_timezone)
                        .format('hh:mm A z')}`}
                    </span>
                    <br />
                    <span style={teacher_classname}>
                      Teacher Join Time :{' '}
                      {` ${moment(item.teacher_first_join_time)
                        .tz(item.student_iana_timezone)
                        .format('hh:mm A z')}`}
                    </span>
                    <br />
                    {item.student_rating !== 0 ? (
                      <>
                        <span>
                          Rating: <span className="high-text">{item.student_rating}</span>
                        </span>
                        <br />
                        <span>Reason: {item.student_rating_reason}</span>
                        <br />
                        <span>Feedback: {item.student_review}</span>
                        <br />
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'top' }} className="low-text">
                    {item.is_first_free_session === 1 ? (
                      'First free session: Yes'
                    ) : item.is_trial === 1 ? (
                      <>
                        <span>First Free Session: No</span>
                        <br />
                        <span>Free Session: Yes</span>
                        <br />
                        <span>Credits used so far: {item.credits_used_so_far}</span>
                        <br />
                      </>
                    ) : item.is_recurring === 1 ? (
                      <>
                        <span>Free Session: No</span>
                        <br />
                        <span>Package Name: {item.package_name}</span>
                        <br />
                        <span>
                          Purchase Date:{' '}
                          {` ${moment(item.purchase_date)
                            .tz(item.student_iana_timezone)
                            .format('MMM DD')}`}
                        </span>
                        <br />
                        <span>
                          Next Billing Date:{' '}
                          {` ${moment(item.next_billing_date)
                            .tz(item.student_iana_timezone)
                            .format('MMM DD')}`}
                        </span>
                        <br />
                      </>
                    ) : (
                      <>
                        <span>Free Session: No</span>
                        <br />
                        <span>Charged Amount: {item.charge_amount}</span>
                        <br />
                        <span>
                          Purchase Date:{' '}
                          {` ${moment(item.purchase_date)
                            .tz(item.student_iana_timezone)
                            .format('MMM DD')}`}
                        </span>
                        <br />
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => this.load_data());

  submit_click = () => this.setState({ submit_click: true, loading: true }, () => this.load_data());

  render_video = () => <CustomPlayer video_url={this.state.video_url} />;

  close_video = () => this.setState({ show_video: false });

  open_video = (url) =>
    this.setState({ video_url: url }, () => this.setState({ show_video: true }));

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Student Sessions Stats</title>
        </Helmet>
        {this.state.loading ? (
          <CustomLoading />
        ) : (
          <div className="common-page-container">
            <CustomModal show={this.state.show_video} close={this.close_video}>
              {this.render_video()}
            </CustomModal>
            <div className="common-page-header">
              <div className="common-page-title">Student Sessions Stats</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <div style={{ marginRight: '10px' }}>
                <CustomSingleSelect
                  label="Select a date filter"
                  options={date_drop_for_report}
                  onChange={this.change_date_filter}
                  value={this.state.date_filter_type}
                  style={{ width: '200px' }}
                />
              </div>
              {this.state.date_filter_type === 'CUSTOM' ? (
                <>
                  <div className="daily-report-item">
                    <CustomDatePicker
                      label="Start Date"
                      value={this.state.start_date}
                      handleChange={(val) => this.handle_date_change('start_date', val)}
                      maxDate={this.state.end_date}
                    />
                  </div>
                  <div className="daily-report-item">
                    <CustomDatePicker
                      label="End Date"
                      value={this.state.end_date}
                      handleChange={(val) => this.handle_date_change('end_date', val)}
                      minDate={this.state.start_date}
                      maxDate={moment().format('YYYY-MM-DD')}
                    />
                  </div>
                  <button onClick={this.submit_click}>Submit</button>
                </>
              ) : null}
            </div>
            {this.render_data()}
          </div>
        )}
      </div>
    );
  }
}
