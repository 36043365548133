import React, { Component } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { post_api } from '../../../../redux/api_funcs';
import * as actions from '../../../../redux/action_creators';
import CustomCheckbox from '../../../util_components/custom_checkbox';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CommonHeader from '../../../util_components/common_header';
import CustomRadio from '../../../util_components/custom_radio_button';
import Button from '../../../util_components/button';
import CustomInput from '../../../util_components/custom_input';
import CustomModal from '../../../util_components/custom_modal';
import 'react-datepicker/dist/react-datepicker.css';
import './availability.css';
import { get_api_url } from '../../../../utils/urls';
import { time_day_format } from '../../../../constants';
import { isEmpty } from '../../../../util_functions';

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
class AllTeacherAvailability extends Component {
  state = {
    time_array: [...Array(48).keys()],
    all_sum_arr: Array(48).fill(0),
    all_availability_array: [],
    final_availability_array: [],
    platform_sum_arr: Array(48).fill(0),
    platform_availability_array: [],
    final_platform_availability_array: [],
    session_sum_arr: Array(48).fill(0),
    session_availability_array: [],
    final_session_availability_array: [],
    loading: true,
    start_date: moment().toDate(),
    end_date: moment().add(7, 'd').toDate(),
    redirectLogin: false,
    zero_availability_teachers: [],
    is_consultant_therapist: 1,
    is_back_pain_therapist: 0,
    is_preferred_consultant: 0,
    is_book_session_tab_open: false,
    book_session_teachers_slot: {},
    selected_teacher_uuid: '',
    student_search_text: '',
    add_student_uuid: '',
    full_loading: false,
    slots_list: [],
    slot_selected: '',
    book_success: false,
    err_msg: '',
    search_students_list: [],
    duration: 30,
    avi_teacher: [],
    filter_teacher_uuid: [],
    searchValue: '',
    multi_ava_teacher: [],
    is_teacher_with_lead_time: 0,
    all_sessions: 1,
    backpain_sessions: 0,
    lifestyle_sessions: 0
  };

  componentDidMount() {
    this.load_teacher_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.setState({ loading: true }, () => {
        this.load_teacher_data();
      });
    }
  }

  parse_availability_data = (data) => {
    const {
      is_back_pain_therapist,
      is_consultant_therapist,
      filter_teacher_uuid,
      is_preferred_consultant,
      is_teacher_with_lead_time
    } = this.state;
    const avail_array = [...data];
    const sum_for_each = Array(48).fill(0);
    const teachers_list = [];
    const all_avail_teacher = [];
    const { length } = avail_array;
    const final_teacher_availability = [];
    for (let i = 0; i < length; i++) {
      const available_teachers = avail_array[i];
      const final_available_teachers = {
        date: available_teachers.date,
        time_slots: []
      };
      if (!!available_teachers.time_slots && available_teachers.time_slots.length > 0) {
        for (const avail_teacher of available_teachers.time_slots) {
          const avail_teachers = avail_teacher.teachers.filter((t) => {
            const _is_back_pain_therapist =
              !!is_back_pain_therapist && t.is_back_pain_therapist === is_back_pain_therapist;
            const _is_consultant_therapist =
              !!is_consultant_therapist && t.is_consultant_therapist === is_consultant_therapist;
            const _is_preferred_consultant =
              !!is_preferred_consultant && t.is_preferred_consultant === is_preferred_consultant;
            const _is_teacher_with_lead_time =
              !!is_teacher_with_lead_time &&
              t.is_teacher_with_lead_time === is_teacher_with_lead_time;
            const _filter_teacher_uuid = !!(
              filter_teacher_uuid && filter_teacher_uuid.includes(t.uuid)
            );
            if (filter_teacher_uuid.length) {
              if (is_teacher_with_lead_time === 1) {
                return (
                  (_is_back_pain_therapist ||
                    _is_consultant_therapist ||
                    _is_preferred_consultant) &&
                  _filter_teacher_uuid &&
                  _is_teacher_with_lead_time
                );
              }
              return (
                (_is_back_pain_therapist || _is_consultant_therapist || _is_preferred_consultant) &&
                _filter_teacher_uuid
              );
            }
            if (is_teacher_with_lead_time === 1) {
              return (
                (_is_back_pain_therapist ||
                  _is_consultant_therapist ||
                  _filter_teacher_uuid ||
                  _is_preferred_consultant) &&
                _is_teacher_with_lead_time
              );
            }
            return (
              _is_back_pain_therapist ||
              _is_consultant_therapist ||
              _filter_teacher_uuid ||
              _is_preferred_consultant
            );
          });
          const avail_obj = {
            time: avail_teacher.time,
            timestamp: avail_teacher.timestamp,
            available: avail_teachers.length,
            teachers: avail_teachers
          };
          final_available_teachers.time_slots.push(avail_obj);
        }
      }
      final_available_teachers.time_slots.forEach((list) => {
        list.teachers.forEach((teacher) => {
          const index = teachers_list.findIndex((x) => x.uuid === teacher.uuid);
          if (index === -1) {
            teachers_list.push({ uuid: teacher.uuid, name: teacher.name, count: 1 });
          } else {
            teachers_list[index] = {
              ...teachers_list[index],
              count: teachers_list[index].count + 1
            };
          }

          const finalIndex = all_avail_teacher.findIndex((x) => x.uuid === teacher.uuid);
          if (finalIndex === -1) {
            all_avail_teacher.push({
              uuid: teacher.uuid,
              name: teacher.name,
              count: 1,
              is_teacher_with_lead_time: teacher.is_teacher_with_lead_time,
              lead_time_backpain_consult: teacher.lead_time_backpain_consult
            });
          }
        });
      });
      let arr = final_available_teachers.time_slots.map((item) => item.available);
      if (i === 0) {
        arr = Array(48 - arr.length)
          .fill(0)
          .concat(arr);
      }
      if (i === length - 1) {
        arr = arr.concat(Array(48 - arr.length).fill(0));
      }
      for (let j = 0; j < sum_for_each.length; ++j) sum_for_each[j] = sum_for_each[j] + arr[j];
      final_teacher_availability.push(final_available_teachers);
    }
    return [
      [...sum_for_each],
      [...teachers_list],
      [...final_teacher_availability],
      [...all_avail_teacher]
    ];
  };

  load_teacher_data = () => {
    const url = get_api_url('teacher_availability');
    const zero_url = get_api_url('get_zero_availability_teachers');
    const start_date = `${moment(this.state.start_date).format('YYYY-MM-DD')} 00:00:00`;
    const end_date = `${moment(this.state.end_date).format('YYYY-MM-DD')} 23:30:00`;
    const data = {
      start_date,
      end_date,
      iana_timezone: this.props.iana_timezone
    };
    const all_paylaod = {
      start_date: moment(this.state.start_date).format('YYYY-MM-DD'),
      end_date: moment(this.state.end_date).add(1, 'days').format('YYYY-MM-DD')
    };
    this.setState({ loading: true });
    try {
      post_api(url, data, true).then((response) => {
        const [all_sum_arr, all_sum_count, final_availability_array, all_avail_teacher] =
          this.parse_availability_data(response.data.availability);
        post_api(url, { ...data, available_slots: 1 }, true).then((response1) => {
          const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
            this.parse_availability_data(response1.data.availability);
          post_api(url, { ...data, available_slots: 0 }, true).then((response2) => {
            const [session_sum_arr, session_sum_count, final_session_availability_array] =
              this.parse_availability_data(response2.data.availability);
            this.setState({
              session_availability_array: [...response2.data.availability],
              final_session_availability_array,
              session_sum_arr,
              session_sum_count,
              platform_availability_array: [...response1.data.availability],
              final_platform_availability_array,
              platform_sum_arr,
              platform_sum_count,
              all_availability_array: [...response.data.availability],
              all_sum_arr,
              all_sum_count,
              final_availability_array,
              loading: false,
              avi_teacher: all_avail_teacher
            });
            post_api(zero_url, all_paylaod, true).then((zero_response) => {
              this.setState({ zero_availability_teachers: [...zero_response.data.teachers] });
            });
          });
        });
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        this.setState({ redirectLogin: true });
      }
    }
  };

  render_table_header = () => (
    <tr>
      <th className="all-table-header">Day</th>
      <th className="all-table-header">Date</th>
      {this.state.time_array.map((item, index) => (
        <th className="all-table-header" key={item + index}>
          {item % 2 === 0 ? item / 2 : ''}
        </th>
      ))}
      <th className="all-table-header">Total</th>
    </tr>
  );

  render_blank_td = (availabality) => {
    const temp_length = 48 - availabality.time_slots.length;
    const temp_array = [...Array(temp_length).keys()];
    return (
      <>
        {temp_array.map((item, index) => (
          <td style={{ border: 'none' }} key={item + index} />
        ))}
      </>
    );
  };

  open_book_session = (slot) =>
    this.setState({ book_session_teachers_slot: slot, is_book_session_tab_open: true });

  render_table_rows = () => {
    const { final_availability_array } = this.state;
    const { is_teacher_with_lead_time } = this.state;
    return (
      <>
        {final_availability_array.map((availabality, parent_index) => {
          const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
          let total = 0;
          return (
            <tr key={parent_index + availabality.date}>
              <td className="all-table-value day-format">
                {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
              </td>
              <td className="all-table-value day-format">{availabality.date}</td>
              {parent_index === 0 ? this.render_blank_td(availabality) : null}
              {availabality.time_slots.map((slot, index) => {
                total += slot.available;
                const color = max_value === 0 ? 0 : slot.available / max_value;
                const td_style =
                  slot.available === 0 || max_value === 0
                    ? { background: 'rgb(255,255,255)' }
                    : { background: `rgba(0,0,255,${color})`, cursor: 'pointer' };
                return (
                  <td
                    style={td_style}
                    key={index}
                    className="all-table-value hover-parent"
                    onClick={() => this.open_book_session(slot)}
                  >
                    <div className="tooltiptext">
                      No of teachers {slot.available}
                      {slot.teachers.map((teacher, index) => (
                        <span key={teacher.uuid}>
                          <br />
                          {teacher.name}{' '}
                          {is_teacher_with_lead_time === 1 ? (
                            `( ${`${teacher.lead_time_backpain_consult} hours`} )`
                          ) : (
                            <></>
                          )}
                        </span>
                      ))}
                    </div>
                    {slot.available}
                  </td>
                );
              })}
              {parent_index === final_availability_array.length - 1
                ? this.render_blank_td(availabality)
                : null}
              <td className="all-table-value total-table-column">
                <span className="color-blue">{total}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td />
          <td className="all-table-value total-table-column total-heading">Total</td>
          {this.state.all_sum_arr.map((val, index) => (
            <td className="all-table-value total-table-column" key={index}>
              <span className="color-blue">{val}</span>
            </td>
          ))}
          <td className="all-table-value total-table-column">
            <span className="color-blue">{this.state.all_sum_arr.reduce((a, b) => a + b)}</span>
          </td>
        </tr>
      </>
    );
  };

  render_platform_table_rows = () => {
    const availability_array = this.state.final_platform_availability_array;
    const { is_teacher_with_lead_time } = this.state;
    return (
      <>
        {availability_array.map((availabality, parent_index) => {
          const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
          let total = 0;
          return (
            <tr key={parent_index + availabality.date}>
              <td className="all-table-value day-format">
                {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
              </td>
              <td className="all-table-value day-format">{availabality.date}</td>
              {parent_index === 0 ? this.render_blank_td(availabality) : null}
              {availabality.time_slots.map((slot, index) => {
                total += slot.available;
                const color = max_value === 0 ? 0 : slot.available / max_value;
                const td_style =
                  slot.available === 0 || max_value === 0
                    ? { background: 'rgb(255,255,255)' }
                    : { background: `rgba(0,0,255,${color})`, cursor: 'pointer' };
                return (
                  <td
                    style={td_style}
                    key={index}
                    className="all-table-value hover-parent"
                    onClick={() => this.open_book_session(slot)}
                  >
                    <div className="tooltiptext">
                      No of teachers {slot.available}
                      {slot.teachers.map((teacher, index) => (
                        <span key={teacher.uuid}>
                          <br />
                          {teacher.name}{' '}
                          {is_teacher_with_lead_time === 1 ? (
                            `( ${`${teacher.lead_time_backpain_consult} hours`} )`
                          ) : (
                            <></>
                          )}
                        </span>
                      ))}
                    </div>
                    {slot.available}
                  </td>
                );
              })}
              {parent_index === availability_array.length - 1
                ? this.render_blank_td(availabality)
                : null}
              <td className="all-table-value total-table-column">
                <span className="color-blue">{total}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td />
          <td className="all-table-value total-table-column total-heading">Total</td>
          {this.state.platform_sum_arr.map((val, index) => (
            <td className="all-table-value total-table-column" key={index}>
              <span className="color-blue">{val}</span>
            </td>
          ))}
          <td className="all-table-value total-table-column">
            <span className="color-blue">
              {this.state.platform_sum_arr.reduce((a, b) => a + b)}
            </span>
          </td>
        </tr>
      </>
    );
  };

  render_session_table_rows = () => {
    const availability_array = this.state.final_session_availability_array;
    const { is_teacher_with_lead_time, all_sessions, backpain_sessions, lifestyle_sessions } =
      this.state;
    let max_value_for_all_session = 0;
    let total_slots = 0;
    const ava_session = availability_array.map((item) => {
      const max_v = Math.max(...item.time_slots.map((i) => i.available));
      max_value_for_all_session += max_v;

      item.time_slots.map((i) => {
        const tmp_slot = i.available;
        total_slots += tmp_slot;
      });
    });

    if (all_sessions === 1) {
      return (
        <>
          {availability_array.map((availabality, parent_index) => {
            const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
            let total = 0;
            return (
              <tr key={parent_index + availabality.date}>
                <td className="all-table-value day-format">
                  {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
                </td>
                <td className="all-table-value day-format">{availabality.date}</td>
                {parent_index === 0 ? this.render_blank_td(availabality) : null}
                {availabality.time_slots.map((slot, index) => {
                  total += slot.available;
                  const color =
                    max_value_for_all_session === 0 ? 0 : total_slots / max_value_for_all_session;
                  const td_style =
                    slot.available === 0 || max_value_for_all_session === 0
                      ? { background: 'rgb(255,255,255)' }
                      : { background: `rgba(0,0,255,${color})` };
                  return (
                    <td style={td_style} key={index} className="all-table-value hover-parent">
                      <div className="tooltiptext">
                        No of teachers {slot.available}
                        {slot.teachers.map((teacher, index) => (
                          <span key={teacher.uuid}>
                            <br />
                            {teacher.name}{' '}
                            {is_teacher_with_lead_time === 1 ? (
                              `( ${`${teacher.lead_time_backpain_consult} hours`} )`
                            ) : (
                              <></>
                            )}
                          </span>
                        ))}
                      </div>
                      {slot.available}
                    </td>
                  );
                })}
                {parent_index === availability_array.length - 1
                  ? this.render_blank_td(availabality)
                  : null}
                <td className="all-table-value total-table-column">
                  <span className="color-blue">{total / 2}</span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td />
            <td className="all-table-value total-table-column total-heading">Total</td>
            {this.state.session_sum_arr.map((val, index) => (
              <td className="all-table-value total-table-column" key={index}>
                <span className="color-blue">{val / 2}</span>
              </td>
            ))}
            <td className="all-table-value total-table-column">
              <span className="color-blue">
                {this.state.session_sum_arr.reduce((a, b) => a + b) / 2}
              </span>
            </td>
          </tr>
        </>
      );
    }
    if (backpain_sessions === 1) {
      return (
        <>
          {availability_array.map((availabality, parent_index) => {
            const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
            const new_max_value = Math.max(
              ...availabality.time_slots.map((item) => {
                const filtered_teacher_list = item?.teachers?.filter(
                  (i) => i.is_backpain_session === 1
                );
                return filtered_teacher_list.length;
              })
            );
            let total = 0;
            return (
              <tr key={parent_index + availabality.date}>
                <td className="all-table-value day-format">
                  {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
                </td>
                <td className="all-table-value day-format">{availabality.date}</td>
                {parent_index === 0 ? this.render_blank_td(availabality) : null}
                {availabality.time_slots.map((slot, index) => {
                  const ava_slot = slot?.teachers?.filter((item) => item.is_backpain_session === 1);
                  total = ava_slot.length;
                  const color = new_max_value === 0 ? 0 : ava_slot.length / new_max_value;
                  const td_style =
                    total === 0 || new_max_value === 0
                      ? { background: 'rgb(255,255,255)' }
                      : { background: `rgba(0,0,255,${color})` };
                  return (
                    <td
                      style={ava_slot.length === 0 ? {} : td_style}
                      key={index}
                      className="all-table-value hover-parent"
                    >
                      <div className="tooltiptext">
                        No of teachers {ava_slot.length}
                        {slot.teachers.map((teacher, index) => {
                          if (teacher.is_backpain_session === 1) {
                            return (
                              <span key={teacher.uuid}>
                                <br />
                                {teacher.name}{' '}
                                {is_teacher_with_lead_time === 1 ? (
                                  `( ${`${teacher.lead_time_backpain_consult} hours`} )`
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          }
                        })}
                      </div>
                      {ava_slot.length === 0 ? '' : ava_slot.length}
                    </td>
                  );
                })}
                {parent_index === availability_array.length - 1
                  ? this.render_blank_td(availabality)
                  : null}
                <td className="all-table-value total-table-column">
                  <span className="color-blue">{total / 2}</span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td />
            <td className="all-table-value total-table-column total-heading">Total</td>
            {this.state.session_sum_arr.map((val, index) => (
              <td className="all-table-value total-table-column" key={index}>
                <span className="color-blue">{val / 2}</span>
              </td>
            ))}
            <td className="all-table-value total-table-column">
              <span className="color-blue">
                {this.state.session_sum_arr.reduce((a, b) => a + b) / 2}
              </span>
            </td>
          </tr>
        </>
      );
    }
    if (lifestyle_sessions === 1) {
      return (
        <>
          {availability_array.map((availabality, parent_index) => {
            const max_value = Math.max(...availabality.time_slots.map((a) => a.available));
            const new_max_value = Math.max(
              ...availabality.time_slots.map((item) => {
                const filtered_teacher_list = item?.teachers?.filter(
                  (i) => i.is_backpain_session === 0
                );
                return filtered_teacher_list.length;
              })
            );
            let total = 0;
            return (
              <tr key={parent_index + availabality.date}>
                <td className="all-table-value day-format">
                  {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
                </td>
                <td className="all-table-value day-format">{availabality.date}</td>
                {parent_index === 0 ? this.render_blank_td(availabality) : null}
                {availabality.time_slots.map((slot, index) => {
                  const ava_slot = slot?.teachers?.filter((item) => item.is_backpain_session === 0);
                  total = ava_slot.length;
                  const color = new_max_value === 0 ? 0 : ava_slot.length / new_max_value;
                  const td_style =
                    total === 0 || new_max_value === 0
                      ? { background: 'rgb(255,255,255)' }
                      : { background: `rgba(0,0,255,${color})` };
                  return (
                    <td
                      style={ava_slot.length === 0 ? {} : td_style}
                      key={index}
                      className="all-table-value hover-parent"
                    >
                      <div className="tooltiptext">
                        No of teachers {ava_slot.length}
                        {slot.teachers.map((teacher, index) => {
                          if (teacher.is_backpain_session === 0) {
                            return (
                              <span key={teacher.uuid}>
                                <br />
                                {teacher.name}{' '}
                                {is_teacher_with_lead_time === 1 ? (
                                  `( ${`${teacher.lead_time_backpain_consult} hours`} )`
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          }
                        })}
                      </div>
                      {ava_slot.length === 0 ? '' : ava_slot.length}
                    </td>
                  );
                })}
                {parent_index === availability_array.length - 1
                  ? this.render_blank_td(availabality)
                  : null}
                <td className="all-table-value total-table-column">
                  <span className="color-blue">{total / 2}</span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td />
            <td className="all-table-value total-table-column total-heading">Total</td>
            {this.state.session_sum_arr.map((val, index) => (
              <td className="all-table-value total-table-column" key={index}>
                <span className="color-blue">{val / 2}</span>
              </td>
            ))}
            <td className="all-table-value total-table-column">
              <span className="color-blue">
                {this.state.session_sum_arr.reduce((a, b) => a + b) / 2}
              </span>
            </td>
          </tr>
        </>
      );
    }
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  on_submit = () => {
    if (this.state.uuid !== '') {
      this.load_teacher_data();
    }
  };

  render_data = () => {
    if (this.state.loading) {
      return <div style={{ padding: '20px' }}>Loading...</div>;
    }
    if (this.state.all_availability_array.length === 0) {
      return (
        <div className="teacher-avilability">
          <h4>Teachers Availability</h4>
          <p>NO DATA AVAILABLE</p>
        </div>
      );
    }
    return (
      <>
        <div className="availability-table">
          <h4>Current availability on platform:</h4>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_platform_table_rows()}
            </tbody>
          </table>
        </div>
        <div className="availability-table">
          <h4>Sessions booked:</h4>
          <div className="mt-2">{this.rander_booking_session_filter()}</div>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_session_table_rows()}
            </tbody>
          </table>
        </div>
        <div className="availability-table">
          <h4>Availability given by teachers:</h4>
          <table>
            <tbody>
              {this.render_table_header()}
              {this.render_table_rows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  render_filters = () => (
    <div>
      <hr />
      <h4>Date range:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.on_submit}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  render_all_data = () => {
    const { avail_all } = this.props;
    return (
      <>
        <h4>Overall availability:</h4>
        <table>
          <tbody>
            <tr>
              <td rowSpan="2">Date</td>
              <td colSpan="3">Availability</td>
              <td colSpan="3">Booking</td>
              <td colSpan="3">Percentage</td>
            </tr>
            <tr>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
              <td>CST</td>
              <td>PST</td>
              <td>EST</td>
            </tr>
            {avail_all.map((av, index) => (
              <tr>
                <td>{av.date}</td>
                <td>{parseFloat(av.cst_av).toFixed(1)}</td>
                <td>{parseFloat(av.pst_av).toFixed(1)}</td>
                <td>{parseFloat(av.est_av).toFixed(1)}</td>
                <td>{av.cst_bk}</td>
                <td>{av.pst_bk}</td>
                <td>{av.est_bk}</td>
                <td>{parseFloat(av.cst_per).toFixed(2)}</td>
                <td>{parseFloat(av.pst_per).toFixed(2)}</td>
                <td>{parseFloat(av.est_per).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  render_zero_avail_data = () => {
    const teachers = this.state.zero_availability_teachers;
    teachers.sort((a, b) => a.name.localeCompare(b.name));
    if (teachers.length > 0) {
      return (
        <>
          <h4>Teachers with zero availability:</h4>
          <table>
            <tbody>
              <tr>
                <td>Teacher</td>
                <td>Status</td>
              </tr>
              {teachers.map((teacher) => {
                const teacher_url = `/teacherprofile?id=${teacher.uuid}`;
                return (
                  <tr key={teacher.uuid}>
                    <td>
                      <a className="link-no-dec" href={teacher_url}>
                        {teacher.name}
                      </a>
                    </td>
                    <td>{teacher.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
    return null;
  };

  render_table_data = () => {
    const booked_sessions = this.state.session_sum_count;
    const available_hours = this.state.platform_sum_count;
    const total_hours = this.state.all_sum_count;
    total_hours.sort((a, b) => b.count - a.count);
    return (
      <>
        <br />
        {this.render_zero_avail_data()}
        <h4>Teacher specific:</h4>
        <table>
          <tbody>
            <tr>
              <td>Teacher</td>
              <td>Total Hours</td>
              <td>Booked Hours</td>
              <td>Available Hours</td>
            </tr>
            {total_hours.map((teacher) => {
              const booked_sessions_index = booked_sessions.findIndex(
                (x) => x.uuid === teacher.uuid
              );
              const available_hours_index = available_hours.findIndex(
                (x) => x.uuid === teacher.uuid
              );
              const teacher_url = `/teacherprofile?id=${teacher.uuid}`;
              return (
                <tr key={teacher.uuid}>
                  <td>
                    <a className="link-no-dec" href={teacher_url}>
                      {teacher.name}
                    </a>
                  </td>
                  <td>{teacher.count / 2}</td>
                  <td>
                    {(booked_sessions[booked_sessions_index]
                      ? booked_sessions[booked_sessions_index].count
                      : 0) / 2}
                  </td>
                  <td>
                    {(available_hours[available_hours_index]
                      ? available_hours[available_hours_index].count
                      : 0) / 2}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  on_box_click = (name, val) => {
    const { all_availability_array, platform_availability_array, session_availability_array } =
      this.state;
    let { is_consultant_therapist } = this.state;
    let { is_back_pain_therapist } = this.state;
    let { is_preferred_consultant } = this.state;
    const new_val = val === 1 ? 0 : 1;
    if (new_val === 1) {
      if (name === 'is_consultant_therapist') {
        is_back_pain_therapist = 0;
        is_consultant_therapist = 1;
        is_preferred_consultant = 0;
      } else if (name === 'is_preferred_consultant') {
        is_preferred_consultant = 1;
        is_back_pain_therapist = 0;
        is_consultant_therapist = 0;
      } else {
        is_consultant_therapist = 0;
        is_back_pain_therapist = 1;
        is_preferred_consultant = 0;
      }
    } else if (name === 'is_consultant_therapist') is_consultant_therapist = 0;
    else if (name === 'is_preferred_consultant') is_preferred_consultant = 0;
    else {
      is_back_pain_therapist = 0;
      this.setState({ is_teacher_with_lead_time: 0 });
    }

    this.setState(
      {
        is_consultant_therapist,
        is_back_pain_therapist,
        filter_teacher_uuid: '',
        is_preferred_consultant,
        is_teacher_with_lead_time: 0
      },
      () => {
        const [all_sum_arr, all_sum_count, final_availability_array, all_avail_teacher] =
          this.parse_availability_data(all_availability_array);
        const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
          this.parse_availability_data(platform_availability_array);
        const [session_sum_arr, session_sum_count, final_session_availability_array] =
          this.parse_availability_data(session_availability_array);
        this.setState({
          all_sum_arr,
          all_sum_count,
          final_availability_array,
          platform_sum_arr,
          platform_sum_count,
          final_platform_availability_array,
          session_sum_arr,
          session_sum_count,
          final_session_availability_array,
          avi_teacher: all_avail_teacher
        });
      }
    );
  };

  on_booking_session_radio_btn_click = (name, val) => {
    const { session_availability_array } = this.state;
    let { all_sessions } = this.state;
    let { backpain_sessions } = this.state;
    let { lifestyle_sessions } = this.state;
    const new_val = val === 1 ? 0 : 1;
    if (new_val === 1) {
      if (name === 'all_sessions') {
        all_sessions = 1;
        backpain_sessions = 0;
        lifestyle_sessions = 0;
      } else if (name === 'backpain_sessions') {
        all_sessions = 0;
        backpain_sessions = 1;
        lifestyle_sessions = 0;
      } else {
        all_sessions = 0;
        backpain_sessions = 0;
        lifestyle_sessions = 1;
      }
    } else if (name === 'backpain_sessions') backpain_sessions = 0;
    else if (name === 'all_sessions') all_sessions = 0;
    else lifestyle_sessions = 0;
    this.setState({ all_sessions, backpain_sessions, lifestyle_sessions });
  };

  toggle_check_box = () =>
    this.setState(
      { is_teacher_with_lead_time: this.state.is_teacher_with_lead_time === 1 ? 0 : 1 },
      () => {
        const { all_availability_array, platform_availability_array, session_availability_array } =
          this.state;
        const [all_sum_arr, all_sum_count, final_availability_array, all_avail_teacher] =
          this.parse_availability_data(all_availability_array);
        const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
          this.parse_availability_data(platform_availability_array);
        const [session_sum_arr, session_sum_count, final_session_availability_array] =
          this.parse_availability_data(session_availability_array);
        this.setState({
          all_sum_arr,
          all_sum_count,
          final_availability_array,
          platform_sum_arr,
          platform_sum_count,
          final_platform_availability_array,
          session_sum_arr,
          session_sum_count,
          final_session_availability_array,
          avi_teacher: all_avail_teacher
        });
      }
    );

  changeTeacher = (e) => {
    const {
      all_availability_array,
      platform_availability_array,
      session_availability_array,
      filter_teacher_uuid
    } = this.state;
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value }, () => {
      const [all_sum_arr, all_sum_count, final_availability_array] =
        this.parse_availability_data(all_availability_array);
      const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
        this.parse_availability_data(platform_availability_array);
      const [session_sum_arr, session_sum_count, final_session_availability_array] =
        this.parse_availability_data(session_availability_array);
      this.setState({
        all_sum_arr,
        all_sum_count,
        final_availability_array,
        platform_sum_arr,
        platform_sum_count,
        final_platform_availability_array,
        session_sum_arr,
        session_sum_count,
        final_session_availability_array
      });
    });
  };

  clear_teacher = () => {
    const {
      all_availability_array,
      platform_availability_array,
      session_availability_array,
      filter_teacher_uuid
    } = this.state;
    this.setState({ filter_teacher_uuid: [] }, () => {
      const [all_sum_arr, all_sum_count, final_availability_array] =
        this.parse_availability_data(all_availability_array);
      const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
        this.parse_availability_data(platform_availability_array);
      const [session_sum_arr, session_sum_count, final_session_availability_array] =
        this.parse_availability_data(session_availability_array);
      this.setState({
        all_sum_arr,
        all_sum_count,
        final_availability_array,
        platform_sum_arr,
        platform_sum_count,
        final_platform_availability_array,
        session_sum_arr,
        session_sum_count,
        final_session_availability_array
      });
    });
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  clearSearch = () => {
    this.setState({ filter_teacher_uuid: [], searchValue: '' }, () => {
      this.clear_teacher();
    });
  };

  handleOptionClick = (optionValue) => {
    const {
      filter_teacher_uuid,
      all_availability_array,
      platform_availability_array,
      session_availability_array
    } = this.state;

    const newSelectedOptions = filter_teacher_uuid?.includes(optionValue)
      ? filter_teacher_uuid?.filter((value) => value !== optionValue)
      : [...filter_teacher_uuid, optionValue];

    this.setState(
      {
        filter_teacher_uuid: newSelectedOptions
      },
      () => {
        const [all_sum_arr, all_sum_count, final_availability_array] =
          this.parse_availability_data(all_availability_array);
        const [platform_sum_arr, platform_sum_count, final_platform_availability_array] =
          this.parse_availability_data(platform_availability_array);
        const [session_sum_arr, session_sum_count, final_session_availability_array] =
          this.parse_availability_data(session_availability_array);
        this.setState({
          all_sum_arr,
          all_sum_count,
          final_availability_array,
          platform_sum_arr,
          platform_sum_count,
          final_platform_availability_array,
          session_sum_arr,
          session_sum_count,
          final_session_availability_array
        });
      }
    );
  };

  rander_booking_session_filter = () => {
    const { all_sessions, backpain_sessions, lifestyle_sessions } = this.state;
    return (
      <div className="avl-tea-filters mt-2 flex gap-4">
        <CustomRadio
          label="All sessions"
          onRadioClick={() => this.on_booking_session_radio_btn_click('all_sessions', all_sessions)}
          selected={all_sessions === 1}
        />
        <CustomRadio
          label="Backpain sessions"
          onRadioClick={() =>
            this.on_booking_session_radio_btn_click('backpain_sessions', backpain_sessions)
          }
          selected={backpain_sessions === 1}
        />
        <CustomRadio
          label="Lifestyle sessions"
          onRadioClick={() =>
            this.on_booking_session_radio_btn_click('lifestyle_sessions', lifestyle_sessions)
          }
          selected={lifestyle_sessions === 1}
        />
      </div>
    );
  };

  render_checks = () => {
    const {
      is_consultant_therapist,
      is_back_pain_therapist,
      filter_teacher_uuid,
      searchValue,
      is_preferred_consultant,
      is_teacher_with_lead_time
    } = this.state;

    const av_teach = this.state?.avi_teacher?.map((item) => ({
      value: item.uuid,
      label: item.name,
      is_teacher_with_lead_time: item.is_teacher_with_lead_time,
      lead_time_backpain_consult: item.lead_time_backpain_consult
    }));

    const filteredOptions = av_teach.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    return (
      <>
        <div className="avl-tea-filters mt-2 flex gap-4">
          <CustomRadio
            label="All Consultant"
            onRadioClick={() =>
              this.on_box_click('is_consultant_therapist', is_consultant_therapist)
            }
            selected={is_consultant_therapist === 1}
          />
          <CustomRadio
            label="Preferred Consultants"
            onRadioClick={() =>
              this.on_box_click('is_preferred_consultant', is_preferred_consultant)
            }
            selected={is_preferred_consultant === 1}
          />
          <CustomRadio
            label="Coaches"
            onRadioClick={() => this.on_box_click('is_back_pain_therapist', is_back_pain_therapist)}
            selected={is_back_pain_therapist === 1}
          />
          {is_back_pain_therapist === 1 ? (
            <></>
          ) : (
            <CustomCheckbox
              label="Consider Lead Times"
              checked={is_teacher_with_lead_time}
              onClick={() => this.toggle_check_box('is_teacher_with_lead_time')}
            />
          )}
        </div>

        <div className="multi-select-container mt-4 flex items-center">
          <div className="multi-select-w">
            <div>
              Select{' '}
              {is_consultant_therapist === 1
                ? 'Consultant'
                : is_preferred_consultant === 1
                  ? 'Preferred Consultants'
                  : 'Coaches'}
            </div>
            <input
              type="text"
              value={searchValue}
              onChange={this.handleSearchChange}
              placeholder="Search options..."
            />
            {searchValue !== '' && (
              <div className="options-container">
                {filteredOptions.length === 0 ? (
                  <div className="option">No Teacher Found </div>
                ) : (
                  filteredOptions.map((option) => (
                    <div
                      key={option.value}
                      className={`option ${filter_teacher_uuid.includes(option?.value) ? 'selected' : ''}`}
                      onClick={() => this.handleOptionClick(option.value)}
                    >
                      {option.label}{' '}
                      {is_teacher_with_lead_time === 1 ? (
                        `( ${`${option.lead_time_backpain_consult} hours`} )`
                      ) : (
                        <></>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
            <div className="selected-options">
              {filter_teacher_uuid &&
                filter_teacher_uuid?.map((value) => (
                  <div
                    key={value}
                    className="selected-option cursor-pointer"
                    onClick={() => this.handleOptionClick(value)}
                  >
                    {av_teach.find((option) => option.value === value)?.label}{' '}
                    {is_teacher_with_lead_time === 1 ? (
                      `${
                        av_teach.find((option) => option.value === value)
                          ?.lead_time_backpain_consult
                      } hours`
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div>
            <img
              src="https://images.myyogateacher.com/icons/ic-delete-48.png"
              className="cursor-pointer"
              width={40}
              alt="nav"
              onClick={this.clearSearch}
            />
          </div>
        </div>
      </>
    );
  };

  on_teacher_change = (e) => {
    this.setState(
      { selected_teacher_uuid: e.target.value, slots_list: [], slot_selected: '' },
      () => {
        // this.get_slots();
      }
    );
  };

  get_slots = () => {
    const { selected_teacher_uuid, add_student_uuid } = this.state;
    const url = get_api_url('teacher_slots_v1');
    const data = {
      student_uuid: add_student_uuid,
      teacher_uuid: selected_teacher_uuid
    };
    post_api(url, data, true)
      .then((response) => {
        const available_slots = [...response.data.available_slots];
        const all_slots = [];
        for (const day_slot of available_slots) {
          const keys = Object.keys(day_slot.slots);
          if (keys.length > 0) {
            for (const key of keys) {
              all_slots.push(...day_slot.slots[key]);
            }
          }
        }
        this.setState({
          slots_list: all_slots,
          slot_selected: '',
          full_loading: false
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  on_search_click = () => {
    const { student_search_text } = this.state;
    if (student_search_text.trim().length < 3) {
      console.log('search clicked if');
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.setState({ full_loading: true }, () => {
        const url = get_api_url('search_students_including_queue');
        const paylaod = { search_param: student_search_text };
        post_api(url, paylaod, true)
          .then((result) => {
            // [...result.data.students]
            this.setState({ full_loading: false, search_students_list: [...result.data.students] });
          })
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.reason || e.response.data.reason.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ full_loading: false });
          });
      });
    }
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  render_add_student = () => {
    const { student_search_text, add_student_uuid, selected_teacher_uuid, search_students_list } =
      this.state;
    const student_select_values = [];
    search_students_list.map((st) => {
      const { student_uuid } = st;
      if (student_uuid) {
        const fname = st.sq_fname ? st.sq_fname : '';
        const mname = st.sq_mname ? st.sq_mname : '';
        const lname = st.sq_lname ? st.sq_lname : '';
        student_select_values.push({
          label: `${fname} ${mname} ${lname} (${st.email})`,
          value: student_uuid
        });
      }
      return null;
    });
    if (selected_teacher_uuid) {
      return (
        <div>
          <h3>Add a participant to the session</h3>
          <div className="display-flex-between-center">
            <CustomInput
              label="Enter student name or email"
              onChange={this.onCommonChange}
              name="student_search_text"
              value={student_search_text}
              style={{ width: '300px' }}
              onKeyPress={this.on_key_press}
            />
            <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
              Search student
            </Button>
          </div>
          {!!student_search_text && student_select_values.length > 0 ? (
            <div>
              <CustomSingleSelect
                label="Select student"
                options={student_select_values}
                onChange={this.onCommonChange}
                name="add_student_uuid"
                value={add_student_uuid}
                style={{ width: '250px' }}
              />
            </div>
          ) : null}
        </div>
      );
    }
  };

  book_session = () => {
    const {
      selected_teacher_uuid,
      add_student_uuid,
      book_session_teachers_slot,
      is_back_pain_therapist,
      duration,
      is_consultant_therapist,
      is_preferred_consultant
    } = this.state;
    this.setState({ full_loading: true });
    const calling_url =
      is_back_pain_therapist === 1 ? 'schedule_therapy_consultation' : 'schedule_yoga_consultation';
    const url = get_api_url(calling_url);
    const data = {
      student_uuid: add_student_uuid,
      teacher_uuid: selected_teacher_uuid,
      start_time: parseInt(book_session_teachers_slot.timestamp * 1000, 10),
      is_repeat: 0,
      duration: parseInt(duration),
      is_back_pain_therapy_session: is_back_pain_therapist,
      is_therapy_consultation: is_consultant_therapist
    };
    post_api(url, data, true)
      .then(() => {
        const url = get_api_url('/v2/zoom_services/create_zoom_meeting', false, true);
        post_api(url, data, true)
          .then(() => {
            this.setState({ book_success: true, full_loading: false });
          })
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.reason || e.response.data.reason.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ book_success: true, full_loading: false });
          });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.reason || e.response.data.reason.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        this.props.set_notification_variable(true, 'error', err_message);
        this.setState({ full_loading: false, book_success: true, err_msg: err_message });
      });
  };

  render_teacher_slots = () => {
    const { book_session_teachers_slot, selected_teacher_uuid, add_student_uuid, duration } =
      this.state;
    const selected_teachers = book_session_teachers_slot.teachers.map((t) => ({
      value: t.uuid,
      label: t.name
    }));
    const durations = [
      { value: 30, label: '30 Mins' },
      { value: 60, label: '60 Mins' }
    ];
    return (
      <div>
        <p>
          The time selected is{' '}
          {`${book_session_teachers_slot.time} ${moment().tz(this.props.iana_timezone).format('z')}`}
        </p>
        <CustomSingleSelect
          label="Duration"
          options={durations}
          onChange={this.onCommonChange}
          name="duration"
          value={duration}
          style={{ width: '300px' }}
        />
        <CustomSingleSelect
          label="Select a teacher"
          options={selected_teachers}
          onChange={this.on_teacher_change}
          value={selected_teacher_uuid}
          style={{ width: '300px' }}
        />
        {this.render_add_student()}
        <Button disabled={!selected_teacher_uuid || !add_student_uuid} onClick={this.book_session}>
          Book Session
        </Button>
      </div>
    );
  };

  render_modal_open = () => {
    const {
      is_book_session_tab_open,
      book_success,
      err_msg,
      is_consultant_therapist,
      is_back_pain_therapist
    } = this.state;
    const text_str =
      is_consultant_therapist == 1
        ? 'Back Pain Consultant'
        : is_back_pain_therapist == 1
          ? 'Back Pain Coach'
          : 'teacher';
    if (book_success) {
      return <div>{err_msg || 'Session Booked'}</div>;
    }
    if (is_book_session_tab_open) {
      return (
        <div>
          <h4>Book Session with {text_str}</h4>
          {this.render_teacher_slots()}
        </div>
      );
    }
    return null;
  };

  close_modal = () =>
    this.setState({
      is_book_session_tab_open: false,
      err_msg: '',
      search_students_list: [],
      book_success: false,
      selected_teacher_uuid: '',
      add_student_uuid: '',
      slot_selected: '',
      slots_list: [],
      duration: 30,
      student_search_text: ''
    });

  render() {
    const { is_book_session_tab_open, full_loading } = this.state;
    const { student_search_status } = this.props;
    const url = new URL(window.location.href);
    const redirect = `/login?redirect_url=${url.pathname}${url.search}`;
    const show_loading = full_loading || student_search_status === 'loading';
    if (this.state.redirectLogin) {
      return <Redirect to={redirect} />;
    }
    return (
      <CommonHeader loading={show_loading} title="All availability of Consult teachers">
        <CustomModal show={is_book_session_tab_open} close={this.close_modal}>
          {this.render_modal_open()}
        </CustomModal>
        {this.render_filters()}
        {this.render_checks()}
        {this.render_data()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  avail_all_status: state.loading.avail_all_status,
  avail_all: state.reports.avail_all,
  student_search_status: state.loading.student_search_status
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTeacherAvailability);
