import * as t from '../action_types';

export const set_content_variable = (key, payload) => ({
  type: t.set_content_variable,
  payload: {
    key,
    payload
  }
});

export const get_category_data = () => ({
  type: t.get_category_data
});

export const get_category_list = () => ({
  type: t.get_category_list
});

export const add_new_tag = (payload) => ({
  type: t.add_new_tag,
  payload
});

export const delete_tag = (payload) => ({
  type: t.delete_tag,
  payload
});

export const update_tag = (payload) => ({
  type: t.update_tag,
  payload
});

export const get_all_blogs = (payload) => ({
  type: t.get_all_blogs,
  payload
});

export const get_all_associated_tags = (payload) => ({
  type: t.get_all_associated_tags,
  payload
});

export const update_tag_associations = (payload) => ({
  type: t.update_tag_associations,
  payload
});

export const add_new_category = (payload) => ({
  type: t.add_new_category,
  payload
});

export const update_category = (payload) => ({
  type: t.update_category,
  payload
});
