import * as t from '../action_types';

export const update_approved_teachers = (uid, payload) => ({
  type: t.update_approved_teachers,
  payload: {
    uid,
    payload
  }
});

export const approve_teacher_details = (key, payload) => ({
  type: t.approve_teacher_details,
  payload: {
    key,
    payload
  }
});

export const set_teachers_variable = (key, payload) => ({
  type: t.set_teachers_variable,
  payload: {
    key,
    payload
  }
});

export const get_approved_teachers = (payload) => ({
  type: t.get_approved_teachers,
  payload
});

export const get_teacher_profile_details = (payload) => ({
  type: t.get_teacher_profile_details,
  payload
});

export const get_teachers_list = (payload) => ({
  type: t.get_teachers_list,
  payload
});

export const update_teacher_files = (uuid, key, payload) => ({
  type: t.update_teacher_files,
  payload: {
    uuid,
    key,
    payload
  }
});

export const filter_teachers = (key, payload) => ({
  type: t.filter_teachers,
  payload: {
    key,
    payload
  }
});
