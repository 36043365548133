import React from 'react';
import PropTypes from 'prop-types';

function HighligntSearchText(props) {
  const { search_text } = props;
  const s_l = search_text.length;
  const { full_text } = props;
  const index = props.full_text.toLowerCase().indexOf(search_text);
  if (index === -1) {
    return <>{full_text}</>;
  }
  const pre_text = full_text.slice(0, index);
  const match_text = full_text.slice(index, index + s_l);
  const post_text = full_text.slice(index + s_l);
  return (
    <>
      {pre_text}
      <span className="search-hightlight">{match_text}</span>
      {post_text}
    </>
  );
}

HighligntSearchText.propTypes = {
  full_text: PropTypes.string,
  search_text: PropTypes.string
};

HighligntSearchText.defaultProps = {
  full_text: '',
  search_text: ''
};

export default HighligntSearchText;
