import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import Button from '../../../util_components/button';
import { get_api_url } from '../../../../utils/urls';

class EmailDeliveryReport extends Component {
  state = {
    start_date: moment().subtract(7, 'd').toDate(),
    end_date: moment().toDate(),
    loading: true,
    email_data: [],
    bounces: [],
    complaints: [],
    sort_val: true,
    bounces_open: false,
    complaints_open: false,
    show_per: false
  };

  componentDidMount() {
    this.load_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.load_data();
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { start_date, end_date } = this.state;
      const url = get_api_url('email_report_data');
      const payload = {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).add(1, 'days').format('YYYY-MM-DD'),
        iana_timezone: this.props.iana_timezone
      };
      post_api(url, payload, true)
        .then((response) => {
          this.setState({
            loading: false,
            email_data: [...response.data.email_data],
            bounces: [...response.data.bounces],
            complaints: [...response.data.complaints]
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  on_details_click = (date) => {
    const search = `?date=${date}`;
    this.props.history.push({
      pathname: '/emaildeliveryreport/day',
      search
    });
  };

  toggle_per = () => this.setState({ show_per: !this.state.show_per });

  render_emails = () => {
    const { email_data, show_per } = this.state;
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Email Data:
          <Button onClick={this.toggle_per}>{show_per ? 'Show values' : 'Show rates'}</Button>
        </div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Date</div>
            <div className="ct-col">Total</div>
            <div className="ct-col">Delivered</div>
            <div className="ct-col">Bounces</div>
            <div className="ct-col">Complaints</div>
            <div className="ct-col">Opened</div>
            <div className="ct-col">Details</div>
          </div>
          {email_data.map((email) => (
            <div className="ct-row" key={email.date}>
              <div className="ct-col">{email.date}</div>
              <div className="ct-col">{email.total_emails}</div>
              <div className="ct-col">
                {!show_per ? email.delivered_emails : email.delivered_perc}
              </div>
              <div className="ct-col">{!show_per ? email.bounce_emails : email.bounce_perc}</div>
              <div className="ct-col">
                {!show_per ? email.complaint_emails : email.complaint_perc}
              </div>
              <div className="ct-col">{!show_per ? email.opened_emails : email.opened_perc}</div>
              <div className="ct-col">
                <button onClick={() => this.on_details_click(email.date)}>Details</button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  sort_data = (type, flag) => {
    const data = this.state[type];
    const sort_val = this.state.sort_val ? 1 : -1;
    if (flag === 'student') {
      data.sort((a, b) => {
        const a_name = a.student_name ? a.student_name : '';
        const b_name = b.student_name ? b.student_name : '';
        return a_name.localeCompare(b_name) * sort_val;
      });
      this.setState({ [type]: [...data], sort_val: !this.state.sort_val });
    } else if (flag === 'email_type') {
      data.sort((a, b) => a.email_type.localeCompare(b.email_type) * sort_val);
      this.setState({ [type]: [...data], sort_val: !this.state.sort_val });
    } else if (flag === 'teacher') {
      data.sort((a, b) => {
        const a_name = a.teacher_name ? a.teacher_name : '';
        const b_name = b.teacher_name ? b.teacher_name : '';
        return a_name.localeCompare(b_name) * sort_val;
      });
      this.setState({ [type]: [...data], sort_val: !this.state.sort_val });
    }
  };

  render_complaints = () => {
    const { complaints, complaints_open } = this.state;
    if (complaints.length > 0 && complaints_open) {
      return (
        <div style={{ marginTop: '20px' }}>
          <div className="cus-table">
            <div className="ct-row ct-h">
              {/* <div className="ct-col">Email uuid</div> */}
              <div
                className="ct-col ct-cur"
                onClick={() => this.sort_data('complaints', 'email_type')}
              >
                Email type
              </div>
              <div
                className="ct-col ct-cur"
                onClick={() => this.sort_data('complaints', 'com_sub_type')}
              >
                Complaint type
              </div>
              <div className="ct-col">To</div>
              {/* <div className="ct-col ct-cur" onClick={() => this.sort_data('complaints', 'student')}>Student</div>
                            <div className="ct-col ct-cur" onClick={() => this.sort_data('complaints', 'teacher')}>Teacher</div> */}
            </div>
            {complaints.map((comp) => {
              const teacher_url = `/teacherprofile?id=${comp.teacher_uuid}`;
              const student_url = `/studentprofile?id=${comp.student_uuid}`;
              return (
                <div className="ct-row" key={comp.id}>
                  <div className="ct-col ct-bw">{comp.email_type}</div>
                  <div className="ct-col">{comp.complaint_subtype}</div>
                  <div className="ct-col">{comp.to_address}</div>
                  {/* <div className="ct-col ct-bw">
                                        {!!comp.student_uuid ? <a className="link-no-dec" href={student_url}>
                                            <div className="ct-pr-na-em">
                                                {comp.student_name}
                                                <div style={{ fontSize: '12px' }}>{`(${comp.student_email})`}</div>
                                            </div>
                                        </a> : '--'}
                                    </div>
                                    <div className="ct-col ct-bw">
                                        {!!comp.teacher_uuid ? <a className="link-no-dec" href={teacher_url}>
                                            <div className="ct-pr-na-em">
                                                {comp.teacher_name}
                                                <div style={{ fontSize: '12px' }}>{`(${comp.teacher_email})`}</div>
                                            </div>
                                        </a> : '--'}
                                    </div> */}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  render_bounces = () => {
    const { bounces, bounces_open } = this.state;
    if (bounces.length > 0 && bounces_open) {
      return (
        <div style={{ marginTop: '20px' }}>
          <div className="cus-table">
            <div className="ct-row ct-h">
              {/* <div className="ct-col">Email uuid</div> */}
              <div
                className="ct-col ct-cur"
                onClick={() => this.sort_data('bounces', 'email_type')}
              >
                Email type
              </div>
              <div className="ct-col">Bounce type</div>
              <div className="ct-col">Bounce subtype</div>
              <div className="ct-col">To</div>
              {/* <div className="ct-col ct-cur" onClick={() => this.sort_data('bounces','student')}>Student</div>
                            <div className="ct-col ct-cur" onClick={() => this.sort_data('bounces', 'teacher')}>Teacher</div> */}
            </div>
            {bounces.map((bounce) => {
              const teacher_url = `/teacherprofile?id=${bounce.teacher_uuid}`;
              const student_url = `/studentprofile?id=${bounce.student_uuid}`;
              return (
                <div className="ct-row" key={bounce.id}>
                  {/* <div className="ct-col">{bounce.aws_email_uuid}</div> */}
                  <div className="ct-col ct-bw">{bounce.email_type}</div>
                  <div className="ct-col">{bounce.bounce_type}</div>
                  <div className="ct-col">{bounce.bounce_subtype}</div>
                  <div className="ct-col">{bounce.to_address}</div>
                  {/* <div className="ct-col ct-bw">
                                        {!!bounce.student_uuid ? <a className="link-no-dec" href={student_url}>
                                            <div className="ct-pr-na-em">
                                                {bounce.student_name}
                                                <div style={{fontSize:'12px'}}>{`(${bounce.student_email})`}</div>
                                            </div>
                                        </a> : '--'}
                                    </div>
                                    <div className="ct-col ct-bw">
                                        {!!bounce.teacher_uuid ? <a className="link-no-dec" href={teacher_url}>
                                            <div className="ct-pr-na-em">
                                                {bounce.teacher_name}
                                                <div style={{ fontSize: '12px' }}>{`(${bounce.teacher_email})`}</div>
                                            </div>
                                        </a> : '--'}
                                    </div> */}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  render_filters = () => (
    <div>
      <hr />
      <h4>Date range:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.load_data}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  toggle_bounces = () => this.setState({ bounces_open: !this.state.bounces_open });

  toggle_complaints = () => this.setState({ complaints_open: !this.state.complaints_open });

  render() {
    const { bounces, complaints } = this.state;
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Email Delivery Report</div>
        </div>
        {this.render_filters()}
        {this.render_emails()}
        <div className="details-session-logs-header" onClick={this.toggle_bounces}>
          Bounces: {bounces.length}
          {bounces.length !== 0 ? (
            <img
              className={this.state.bounces_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {this.render_bounces()}
        <div className="details-session-logs-header" onClick={this.toggle_complaints}>
          Complaints: {complaints.length}
          {bounces.length !== 0 ? (
            <img
              className={this.state.complaints_open ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {this.render_complaints()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(EmailDeliveryReport);
