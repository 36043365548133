import React, { Component } from 'react';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

class CreateEditFaq extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.set_faq_details();
  }

  set_faq_details = () => {
    const { faq_details } = this.props;
    this.setState({
      faq_question: faq_details.Question,
      faq_answer: faq_details.Answer,
      faq_uuid: faq_details.UUID,
      faq_position: faq_details.Position
    });
  };

  submit_faq = () => {
    const { faq_question, faq_answer, faq_uuid, faq_position } = this.state;
    const payload = {
      Question: faq_question,
      Answer: faq_answer,
      Position: faq_position
    };
    if (faq_uuid) {
      payload.UUID = faq_uuid;
      this.props.submit_faq(payload);
    } else {
      this.props.add_faq(payload);
    }
  };

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCustomChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  render_react_quill = (label, key, faq_content) => {
    const faq_key = `faq_${key}`;
    return (
      <div>
        <br />
        <div>
          Enter Faq {label}:
          <ReactQuill
            ref={(el) => {
              this.quill = el;
            }}
            theme="snow"
            value={faq_content || ''}
            onChange={(faq_content) => this.onCommonChange(faq_key, faq_content)}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                  ['link', 'image', 'video'],
                  ['clean']
                ],
                handlers: {
                  image: this.newImageHandler
                }
              }
            }}
            formats={formats}
          />
        </div>
        <br />
      </div>
    );
  };

  onCustomChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  onCommonChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { faq_question, faq_answer, faq_uuid } = this.state;
    return (
      <div>
        {this.state.loading ? <CustomLoading /> : null}
        {/* {this.render_react_quill('Question', 'question', faq_question)}     */}
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <div>Enter Question:</div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={faq_question}
              name="faq_question"
              onChange={this.onCustomChange}
              maxLength="2000"
            />
          </div>
        </div>
        <br />
        {this.render_react_quill('Answer', 'answer', faq_answer)}
        {/* <div className="display-flex-between">
                    <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
                        <div>Enter Answer:</div>
                        <textarea style={{ width: '100%', height: '200px' }}
                            value={faq_answer} name="faq_answer"
                            onChange={this.onCommonChange} maxLength="2000" />
                    </div>
                </div> */}
        <Button onClick={this.submit_faq}>Submit</Button>
      </div>
    );
  }
}

export default CreateEditFaq;
