import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import SplitTestListV2 from './split_test_list_v2';
import SplitTestV2 from './split_test_v2';
import SplitTestChoiceV2 from './split_test_choice_v2';

function SplitTestDetailV2() {
  const { path } = useRouteMatch();
  const { tag, uuid } = useParams();

  return (
    <Switch>
      <Route exact path={`${path}/:tag/:uuid`} component={SplitTestChoiceV2} />
      <Route exact path={`${path}/:tag`} component={SplitTestV2} />
      <Route exact path={path} component={SplitTestListV2} />
    </Switch>
  );
}

export default SplitTestDetailV2;
