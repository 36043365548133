import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import CustomCheckBox from '../../util_components/custom_checkbox';

class CreateEditCampaignSchedule extends Component {
  state = {
    loading: false,
    show_time_error: false
  };

  componentDidMount() {
    this.set_campaign_schedule_details();
  }

  set_campaign_schedule_details = () => {
    const { campaign_schedule_details } = this.props;
    let current_schedule;
    let current_campaign;
    if (this.props.current_campaign) {
      current_campaign = this.props.current_campaign;
    }
    if (this.props.current_schedule) {
      current_schedule = this.props.current_schedule;
    }
    this.setState({
      page_level_campaign_id: current_campaign
        ? current_campaign.id
        : current_schedule
          ? current_schedule.page_level_campaign_id
          : null,
      page_level_campaign_uuid: current_campaign
        ? current_campaign.uuid
        : current_schedule
          ? current_schedule.page_level_campaign_uuid
          : null,
      start_time: current_schedule ? moment(current_schedule.start_time).toDate() : new Date(),
      end_time: current_schedule ? moment(current_schedule.end_time).toDate() : new Date(),
      is_active: current_schedule ? current_schedule.is_active : 0,
      uuid: current_schedule ? current_schedule.uuid : null,
      current_campaign,
      current_schedule
    });
  };

  submit_campaign_schedule = () => {
    const {
      page_level_campaign_id,
      page_level_campaign_uuid,
      start_time,
      end_time,
      is_active,
      uuid
    } = this.state;
    if (start_time > end_time) {
      this.setState({
        show_time_error: true
      });
      return;
    }
    const payload = {
      page_level_campaign_id,
      page_level_campaign_uuid,
      start_time,
      end_time,
      is_active
    };
    if (uuid) {
      payload.uuid = uuid;
      this.props.edit_campaign_schedule(payload);
    } else {
      this.props.add_campaign_schedule(payload);
    }
  };

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCustomChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  onCustomChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  onCommonChange = (key, value) => {
    this.setState({ [key]: value });
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  set_start_time = (date) => this.setState({ start_time: date });

  set_end_time = (date) => this.setState({ end_time: date });

  render() {
    const {
      page_level_campaign_id,
      page_level_campaign_uuid,
      start_time,
      end_time,
      is_active,
      show_time_error
    } = this.state;
    return (
      <div>
        {this.state.loading ? <CustomLoading /> : null}
        {!!this.state.current_campaign || !!this.state.current_schedule ? (
          <div>
            <div className="display-flex-between">
              <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
                <table className="campaign-schedule">
                  <tbody>
                    <tr className="table-row-left-align">
                      <td className="teacher-table-detail">Page Level campaign ID: </td>
                      <td>{page_level_campaign_id}</td>
                    </tr>
                    <tr className="table-row-left-align">
                      <td className="teacher-table-detail">Page Level campaign UUID: </td>
                      <td>{page_level_campaign_uuid}</td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ margin: '10px 0' }}>
                  <CustomCheckBox
                    label="Is Active"
                    checked={is_active}
                    onClick={() => this.toggle_check_box('is_active')}
                  />
                </div>
                <div>
                  <h3>Select Start & End Time (in local)</h3>
                  <DatePicker
                    selected={start_time}
                    onChange={(date) => this.set_start_time(date)}
                    minDate={new Date()}
                    maxDate={moment(new Date()).add(30, 'days').toDate()}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <DatePicker
                    selected={end_time}
                    onChange={(date) => this.set_end_time(date)}
                    minDate={start_time}
                    maxDate={moment(new Date()).add(30, 'days').toDate()}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                {show_time_error ? <h3>Error: "Start time is greater than End time"</h3> : null}
              </div>
            </div>
            <br />
            <Button onClick={this.submit_campaign_schedule}>Submit</Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CreateEditCampaignSchedule;
