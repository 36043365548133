import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import CustomSingleTable from '../../util_components/custom_single_table';
import { image_error_replace } from '../../../util_functions';
import { announcement_minor_version_format } from '../../../utils/formats';
import { get_api_url } from '../../../utils/urls';
import { time_format, day_format_year } from '../../../constants';

class MinorVersionDetails extends Component {
  state = {
    loading: false,
    details: {},
    teachers_list: []
  };

  componentDidMount() {
    try {
      const { uuid } = this.props.match.params;
      if (uuid) {
        this.setState({ announcement_uuid: uuid }, () => this.load_data());
      }
    } catch (e) {
      this.go_to_path('/announcements');
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { announcement_uuid } = this.state;
      const url = get_api_url('get_announcement_teachers_list');
      const payload = { announcement_uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            details: { ...res.data.details },
            teachers_list: [...res.data.teachers_list],
            loading: false
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  go_to_path = (path) => this.props.history.push(path);

  render_teacher_list = (item) => {
    const teacher_url = `/teacherprofile?id=${item.teacher_uuid}`;
    return (
      <div className="ct-row" key={item.uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={item.profile_photo} onError={image_error_replace} alt="x" />
              {item.teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-hf ct-si">
          {item.times_opened > 0 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-hf">
          {item.last_read_time ? (
            <>
              <div>
                {moment(item.last_read_time).tz(this.props.iana_timezone).format(day_format_year)}
              </div>
              <div>
                {moment(item.last_read_time).tz(this.props.iana_timezone).format(time_format)}
              </div>
            </>
          ) : (
            '--'
          )}
        </div>
        <div className="ct-col ct-hf ct-si">
          {item.acknowledgement_given === 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-hf">
          {item.acknowledgement_time ? (
            <>
              <div>
                {moment(item.acknowledgement_time)
                  .tz(this.props.iana_timezone)
                  .format(day_format_year)}
              </div>
              <div>
                {moment(item.acknowledgement_time).tz(this.props.iana_timezone).format(time_format)}
              </div>
            </>
          ) : (
            '--'
          )}
        </div>
      </div>
    );
  };

  render() {
    const { loading, details, teachers_list } = this.state;
    return (
      <CommonHeader loading={loading} title="Announcement Details" show_back>
        <CustomSingleTable data={details} data_format={announcement_minor_version_format} />
        <div style={{ marginTop: '20px' }}>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Name</div>
              <div className="ct-col ct-hf">Read</div>
              <div className="ct-col ct-hf">Last read time</div>
              <div className="ct-col ct-hf">Acknowledged</div>
              <div className="ct-col ct-hf">Acknowledged time</div>
            </div>
            {teachers_list.map(this.render_teacher_list)}
          </div>
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MinorVersionDetails);
