import axios from 'axios';
import { getCookie } from '../util_functions';

export const post_api = (url, payload, token = false, type = 'none') => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${getCookie('cms_token_3')}`;
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }
  return new Promise((resolve, reject) =>
    axios({
      method: 'post',
      url,
      headers,
      data: payload
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  );
};

export const put_api = (url, payload, token = false, type = 'none') => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${getCookie('cms_token_3')}`;
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }
  return new Promise((resolve, reject) =>
    axios({
      method: 'put',
      url,
      headers,
      data: payload
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  );
};

export const api_with_method = (api_method, url, payload, token = false, type = 'none') => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${getCookie('cms_token_3')}`;
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }
  return new Promise((resolve, reject) =>
    axios({
      method: api_method,
      url,
      headers,
      data: payload
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  );
};

export const get_api = (url, token = false) => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${getCookie('cms_token_3')}`;
  }
  return axios({
    method: 'get',
    url,
    headers
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export const delete_api = (url, token = false) => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${getCookie('cms_token_3')}`;
  }
  return axios({
    method: 'delete',
    url,
    headers
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};
