import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import { inventory_types, day_format, time_format } from '../../../constants';
import * as actions from '../../../redux/action_creators';
import { post_api } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';

class Inventory extends Component {
  state = {
    loading: false,
    inventory_type: 'MOBILE',
    inventory_type_header: 'MOBILE',
    inv_open: false,
    edit_open: false,
    inv_uuid: '',
    name: '',
    assignee_uuid: '',
    inventory_name: '',
    cms_users: [],
    inventory: [],
    office_user: {}
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_inventory');
      post_api(url, null, true)
        .then((res) => {
          const { cms_users } = res.data;
          const { inventory } = res.data;
          const { office_user } = res.data;
          this.setState({
            cms_users,
            inventory,
            office_user,
            assignee_uuid: office_user.uuid,
            loading: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  toggle_create = () =>
    this.setState({
      inv_open: !this.state.inv_open,
      assignee_uuid: this.state.office_user.uuid,
      inventory_type: 'MOBILE'
    });

  change_categry = (category) => {
    const { inventory_type_header } = this.state;
    if (inventory_type_header !== category) {
      this.setState({ inventory_type_header: category });
    }
  };

  render_inventory_header = () => {
    const { inventory_type_header, inventory } = this.state;
    const inv_list = inventory.filter((t) => t.inventory_type === inventory_type_header);
    return (
      <div>
        <button onClick={this.toggle_create}>Create Inventory</button>
        <div className="stc-container">
          <div className="stc-header">
            {inventory_types.map((c) => (
              <div
                className={`stc-label ${c.value === inventory_type_header ? 'stc-label-sel' : ''}`}
                onClick={() => this.change_categry(c.value)}
              >
                {c.label}
              </div>
            ))}
          </div>
          <div>{this.render_inventory(inv_list)}</div>
        </div>
      </div>
    );
  };

  create_device = () =>
    this.setState({ loading: true }, () => {
      const { inventory_name, inventory_type, assignee_uuid, inv_uuid, edit_inv } = this.state;
      let url = get_api_url('create_inventory');
      if (edit_inv) url = get_api_url('update_inventory');
      const payload = { inventory_name, inventory_type, assignee_uuid, inventory_uuid: inv_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(
            true,
            'success',
            `Inventory ${edit_inv ? 'updated' : 'created'}`
          );
          this.close_edit();
          this.load_data();
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, modal_error: false });
  };

  render_create_inventory = () => {
    const { inventory_name, inventory_type, assignee_uuid, cms_users, edit_open } = this.state;
    return (
      <div>
        <h2 style={{ marginBottom: '20px' }}>{`${edit_open ? 'Edit' : 'Create'} Item`}</h2>
        <CustomInput
          label="*Enter Name"
          onChange={this.onCommonChange}
          name="inventory_name"
          value={inventory_name}
          style={{ width: '260px' }}
        />
        <CustomSingleSelect
          label="*Select User"
          options={cms_users}
          name="assignee_uuid"
          onChange={this.onCommonChange}
          value={assignee_uuid}
          style={{ width: '250px' }}
        />
        <CustomSingleSelect
          label="*Select Type"
          options={inventory_types}
          name="inventory_type"
          onChange={this.onCommonChange}
          value={inventory_type}
          style={{ width: '250px' }}
        />
        <button onClick={this.create_device}>Create</button>
      </div>
    );
  };

  edit_inv = (inv) => {
    this.setState({
      inv_uuid: inv.uuid,
      assignee_uuid: inv.assignee_uuid,
      inventory_type: inv.inventory_type,
      inventory_name: inv.inventory_name,
      edit_inv: true,
      inv_open: true
    });
  };

  close_edit = () =>
    this.setState({
      inv_uuid: '',
      assignee_uuid: this.state.office_user.uuid,
      inventory_type: 'MOBILE',
      inventory_name: '',
      edit_inv: false,
      inv_open: false
    });

  render_single_inventory = (inv) => (
    <div className="ct-row">
      <div className="ct-col">{inv.inventory_name}</div>
      <div className="ct-col">{inv.assignee_name}</div>
      <div className="ct-col">
        <div>{moment(inv.assigned_date).tz(this.props.iana_timezone).format(day_format)}</div>
        <div>{moment(inv.assigned_date).tz(this.props.iana_timezone).format(time_format)}</div>
      </div>
      <div className="ct-col ct-bw ct-hf">
        <button onClick={() => this.edit_inv(inv)}>change</button>
      </div>
    </div>
  );

  render_inventory = (inv_list) => {
    if (inv_list.length > 0) {
      return (
        <div style={{ marginTop: '20px' }}>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Name</div>
              <div className="ct-col">Assignee</div>
              <div className="ct-col">Assigned Date</div>
              <div className="ct-col ct-bw ct-hf">Change</div>
            </div>
            {inv_list.map(this.render_single_inventory)}
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { loading, inv_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Inventory">
        <CustomModal show={inv_open} close={this.close_edit}>
          {this.render_create_inventory()}
        </CustomModal>
        {this.render_inventory_header()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
