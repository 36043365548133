import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import { api_with_method } from '../../../redux/api_funcs';
import CustomModal from '../../util_components/custom_modal';
import CustomCheckBox from '../../util_components/custom_checkbox';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import { get_api_url } from '../../../utils/urls';
import * as actions from '../../../redux/action_creators';

class PressPages extends Component {
  state = {
    loading: false,
    press_pages: [],
    open_add: false,
    open_edit: false,
    page_url: '',
    title: '',
    description: '',
    image_url: '',
    page_id: 0,
    is_active: 0,
    is_pinned: 0
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('/v2/cms_user/press_pages', false, true);
      api_with_method('get', url, null, true)
        .then((response) => {
          const press_pages = [...response.data.data.press_pages];
          this.setState({ press_pages, loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  edit_page = (item) => {
    this.setState({
      open_add: true,
      open_edit: true,
      page_url: item.page_url,
      title: item.title,
      description: item.desc,
      image_url: item.image_url,
      page_id: item.page_id,
      is_active: item.is_active,
      is_pinned: item.is_pinned
    });
  };

  show_pages = () => {
    const { press_pages } = this.state;

    return (
      <div className="pages-list">
        {press_pages.map((page) => {
          const active_class = page.is_active === 1 ? 'single-page-active' : 'single-page-inactive';
          const pinned_class = page.is_pinned === 1 ? 'single-page-active' : 'single-page-inactive';

          return (
            <div className="single-page">
              <a href={page.page_url} target="_blank" rel="noopener noreferrer">
                <img src={page.image_url} />
              </a>
              <div className="single-page-buttons">
                <div className={active_class} style={{ marginRight: '5px' }}>
                  {page.is_active === 1 ? 'Active' : 'Inactive'}
                </div>
                <div className={pinned_class}>{page.is_pinned === 1 ? 'Pinned' : 'Unpinned'}</div>
                <button className="ct-small-btn" onClick={() => this.edit_page(page)}>
                  Edit
                </button>
              </div>
              <p className="single-page-title">{page.title}</p>
              <p>{page.desc}</p>
            </div>
          );
        })}
      </div>
    );
  };

  create_press_page = () => {
    this.setState({ loading: true }, () => {
      const { page_url, title, description, image_url, is_active, page_id, open_edit, is_pinned } =
        this.state;
      const url = get_api_url('/v2/cms_user/press_pages', false, true);
      const api_method = open_edit ? `post` : 'put';

      const payload = {
        page_url,
        title,
        desc: description,
        image_url
      };

      if (open_edit) {
        payload.is_active = is_active;
        payload.page_id = page_id;
        payload.is_pinned = is_pinned;
      }

      api_with_method(api_method, url, payload, true)
        .then((res) => {
          this.close_toggle();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
    if (key === 'page_url') {
      this.setState({ confirm_url: false });
    }
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  render_add = () => {
    const { is_active, page_url, title, description, image_url, open_edit, is_pinned } = this.state;

    return (
      <div>
        <h4>{open_edit ? `Edit` : `Add`} Page Url:</h4>
        <div style={{ marginTop: '20px' }}>
          <CustomInput
            label="Enter Page Url"
            onChange={this.onCommonChange}
            name="page_url"
            value={page_url}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <CustomInput
            label="Enter title"
            onChange={this.onCommonChange}
            name="title"
            value={title}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <CustomInput
            label="Enter Image Url"
            onChange={this.onCommonChange}
            name="image_url"
            value={image_url}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <div>Enter Description:</div>
          <textarea
            label="Enter Description"
            onChange={this.onCommonChange}
            name="description"
            value={description}
            style={{ height: '200px' }}
          />
        </div>
        {open_edit ? (
          <div style={{ margin: '10px 0' }}>
            <CustomCheckBox
              label="Active"
              checked={is_active}
              onClick={() => this.toggle_check_box('is_active')}
            />
          </div>
        ) : null}
        {open_edit ? (
          <div style={{ margin: '10px 0' }}>
            <CustomCheckBox
              label="Pinned"
              checked={is_pinned}
              onClick={() => this.toggle_check_box('is_pinned')}
            />
          </div>
        ) : null}
        <Button
          disabled={!page_url || !title || !image_url || !description}
          onClick={this.create_press_page}
        >
          Submit
        </Button>
      </div>
    );
  };

  open_add = () => this.setState({ open_add: true });

  close_toggle = () => {
    this.setState({
      open_add: false,
      page_url: '',
      title: '',
      description: '',
      image_url: '',
      page_id: 0,
      is_active: 0,
      is_pinned: 0,
      open_edit: false
    });
  };

  render() {
    const { loading, open_add } = this.state;
    return (
      <CommonHeader loading={loading} title="Press Pages">
        <CustomModal show={open_add} close={this.close_toggle}>
          {this.render_add()}
        </CustomModal>
        <button onClick={this.open_add}>Add Page</button>
        {this.show_pages()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PressPages);
