import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import moment from 'moment';
import CommonHeader from '../../../util_components/common_header';
import { image_error_replace, check_null_text } from '../../../../util_functions';
import { day_format, time_format } from '../../../../constants';
import { get_api_url } from '../../../../utils/urls';
import { post_api } from '../../../../redux/api_funcs';

class Replacement extends Component {
  state = {
    loading: false,
    start_date: moment().subtract(7, 'd').toDate(),
    // start_date: moment('2020-09-27').toDate(),
    end_date: moment().toDate(),
    sessions: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { start_date, end_date } = this.state;
      const url = get_api_url('replacement_sessions');
      const payload = {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD')
      };
      post_api(url, payload, true)
        .then((response) => {
          this.setState({ loading: false, sessions: [...response.data.sessions] });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  get_group_name = (type) => {
    let final_text = null;
    switch (type) {
      case 'GROUP_SESSION':
        final_text = '(Group)';
        break;
      case 'YOGA_WITH_FRIENDS_SESSION':
        final_text = '(Friends)';
        break;
      case 'PRIVATE_GROUP_SESSION':
        final_text = '(Private)';
        break;
      case 'WORKSHOP_SESSION':
        final_text = '(Workshop)';
        break;
      default:
        final_text = null;
        break;
    }
    if (final_text) {
      return <div style={{ fontSize: '10px' }}>{final_text}</div>;
    }
    return null;
  };

  get_href = (uuid, type) => {
    let final_href = null;
    if (type === 'YOGA') {
      final_href = `/sessiondetails?id=${uuid}`;
    } else {
      final_href = `/groupsessiondetails?session_uuid=${uuid}`;
    }
    return final_href;
  };

  render_sessions = () => {
    const { sessions } = this.state;
    if (sessions.length > 0) {
      return (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-vsm-font ct-bw ct-la">Original Teacher</div>
            <div className="ct-col ct-vsm-font ct-bw ct-la">New Teacher</div>
            <div className="ct-col cr-hf ct-vsm-font">Session Time</div>
            {/* <div className="ct-col ct-vsm-font">Action</div> */}
            <div className="ct-col cr-hf ct-vsm-font">Rep Start time</div>
            <div className="ct-col cr-hf ct-vsm-font">Accepted time</div>
            <div className="ct-col ct-vsm-font">Reason</div>
            <div className="ct-col ct-vsm-font ct-hf">Group Session</div>
            <div className="ct-col ct-vsm-font ct-hf">Details</div>
          </div>
          {sessions.map((s) => {
            const orig_teacher_url = `/teacherprofile?id=${s.original_teacher_uuid}`;
            const new_teacher_url = `/teacherprofile?id=${s.new_teacher_uuid}`;
            const action_style =
              s.action === 'ACCEPTED'
                ? { color: '#016601', fontSize: '12px' }
                : { color: '#ED4D4D', fontSize: '10px' };
            return (
              <div className="ct-row" key={s.uuid}>
                <div className="ct-col">
                  <a className="link-no-dec" href={orig_teacher_url}>
                    <div className="profile-img-name ct-sm-font">
                      <img src={s.original_teacher_photo} onError={image_error_replace} alt="x" />
                      {s.original_teacher_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col">
                  <a className="link-no-dec" href={new_teacher_url}>
                    <div className="profile-img-name ct-sm-font">
                      <img src={s.new_teacher_photo} onError={image_error_replace} alt="x" />
                      {s.new_teacher_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col cr-hf ct-vsm-font">
                  <div>
                    {moment(s.session_start_time).tz(this.props.iana_timezone).format(day_format)}
                  </div>
                  <div>
                    {moment(s.session_start_time).tz(this.props.iana_timezone).format(time_format)}
                  </div>
                </div>
                {/* <div className="ct-col" style={action_style}>{s.action}</div> */}
                <div className="ct-col cr-hf ct-vsm-font">
                  {s.waiting_start_time ? (
                    <>
                      <div>
                        {moment(s.waiting_start_time)
                          .tz(this.props.iana_timezone)
                          .format(day_format)}
                      </div>
                      <div>
                        {moment(s.waiting_start_time)
                          .tz(this.props.iana_timezone)
                          .format(time_format)}
                      </div>
                    </>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col cr-hf ct-vsm-font">
                  {s.accepted_time ? (
                    <>
                      <div>
                        {moment(s.accepted_time).tz(this.props.iana_timezone).format(day_format)}
                      </div>
                      <div>
                        {moment(s.accepted_time).tz(this.props.iana_timezone).format(time_format)}
                      </div>
                    </>
                  ) : (
                    '--'
                  )}
                </div>
                <div className="ct-col ct-vsm-font ct-bw">{s.reason_for_replacement}</div>
                <div className="ct-col ct-si ct-hf">
                  {s.type === 'GROUP_SESSION' ||
                  s.type === 'YOGA_WITH_FRIENDS_SESSION' ||
                  s.type === 'PRIVATE_GROUP_SESSION' ||
                  s.type === 'WORKSHOP_SESSION' ? (
                    <>
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                      {this.get_group_name(s.type)}
                    </>
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-hf">
                  <a
                    className="btn-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.get_href(s.session_uuid, s.type)}
                  >
                    Details
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <div style={{ minHeight: '500px' }} />;
  };

  handle_date_chage = (date, component) => this.setState({ [component]: date });

  render_filters = () => (
    <div>
      <hr />
      <h4>Date range:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.load_data}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Past Replacements">
        {this.render_filters()}
        {this.render_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Replacement);
