import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import CustomModal from '../../util_components/custom_modal';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomDatePicker from '../../util_components/custom_date_picker';

import {
  image_error_replace,
  getCookie,
  get_date_filter_data_timezone_utc
} from '../../../util_functions';
import { day_format, time_format, date_drop_for_report } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import SmsWindow from './sms_window';

import 'react-datepicker/dist/react-datepicker.css';
import { openChatWithUserUUID } from '../../chat/chat';
import UserRole from '../../../../myt_chat/src/chat/enums/UserRole';

class NewUsers extends Component {
  state = {
    loading: true,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    students: [],
    history: '',
    history_open: '',
    open_info: false,
    student_info: {},
    concierge_teachers: [],
    concierge_teacher_uuid: '',
    is_header_sticky: false,
    sms_window_open: false,
    sms_student: '',
    sms_student_name: ''
  };

  headerRef = React.createRef();

  async componentDidMount() {
    this.load_data();
    // let jwt_token = getCookie('care_chat_token');

    // MytChatService.shared.connect({
    //     serverHost: get_api_url('mytChatUrl'),
    //     authToken: jwt_token,
    //     nickname: 'Care',
    //     avatar: 'https://images.myyogateacher.com/banyan-logo-200.png',
    // });

    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }

    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  handle_scroll = () => {};

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.opt_in_concierge === 1);
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ concierge_teachers: [{ value: 'all', label: 'ALL' }, ...teachers_list] });
  };

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { date_filter_type, start_date, end_date } = this.state;
      const url = get_api_url('get_latest_students');
      const date_data = get_date_filter_data_timezone_utc(date_filter_type, start_date, end_date);

      const payload = {
        start_date: date_data.utc_start_date,
        end_date: date_data.utc_end_date,
        iana_timezone: this.props.iana_timezone
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ loading: false, students: [...res.data.student_data] });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => {
      if (this.state.date_filter_type !== 'CUSTOM') {
        this.load_data();
      }
    });

  handle_date_change = (name, val) => this.setState({ [name]: val });

  change_concierge = (e) => this.setState({ concierge_teacher_uuid: e.target.value });

  render_filters = () => {
    const { date_filter_type, start_date, end_date, concierge_teachers, concierge_teacher_uuid } =
      this.state;
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
      >
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a date filter"
            options={date_drop_for_report}
            onChange={this.change_date_filter}
            value={date_filter_type}
            style={{ width: '200px' }}
          />
        </div>
        {this.state.date_filter_type === 'CUSTOM' ? (
          <>
            <div className="daily-report-item">
              <CustomDatePicker
                label="Start Date"
                value={start_date}
                handleChange={(val) => this.handle_date_change('start_date', val)}
                maxDate={end_date}
              />
            </div>
            <div className="daily-report-item">
              <CustomDatePicker
                label="End Date"
                value={end_date}
                handleChange={(val) => this.handle_date_change('end_date', val)}
                minDate={start_date}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <button onClick={this.load_data}>Submit</button>
          </>
        ) : null}
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a concierge"
            options={concierge_teachers}
            onChange={this.change_concierge}
            value={concierge_teacher_uuid}
            style={{ width: '200px' }}
            showClear
            clear_fn={this.clear_concierge}
          />
        </div>
      </div>
    );
  };

  clear_concierge = () => this.setState({ concierge_teacher_uuid: '' });

  open_history = (e, history) => {
    e.stopPropagation();
    this.setState({ history, history_open: true });
  };

  open_chat = async (student) => {
    openChatWithUserUUID(student.uuid, UserRole.STUDENT);
  };

  isTextPresent = (inputString) => {
    const trimmedString = inputString.trim();
    if (trimmedString.length > 0) {
      return inputString;
    }
    return 'Name not given';
  };

  render_single_student = (student) => {
    const student_url = `/studentprofile?id=${student.uuid}`;
    const teacher_url = `/teacherprofile?id=${student.teacher_uuid}`;
    const concierge_url = `/teacherprofile?id=${student.concierge_uuid}`;
    const client_domain = student.client_domain
      ? student.client_domain === 'lifestyle'
        ? ''
        : 'Lifetree Health'
      : '';

    return (
      <div className="ct-row" key={student.uuid}>
        <div className="ct-col ct-hf ct-la ct-bw">
          <a className="link-no-dec" href={student_url} target="_blank" rel="noopener noreferrer">
            <div className="profile-img-name">
              <img src={student.profile_photo} onError={image_error_replace} alt="x" />
              {this.isTextPresent(student?.name)}
            </div>
            {student.yoga_level ? (
              <div
                style={{ fontSize: '10px', marginLeft: '42px' }}
              >{`(${student.yoga_level})`}</div>
            ) : null}
          </a>
          {student.health_history ? (
            <div
              className="div-link"
              style={{ marginLeft: '42px' }}
              onClick={(e) => this.open_history(e, student.health_history)}
            >
              History
            </div>
          ) : null}
          {client_domain === '' ? null : (
            <div className="bg-mytPurple px-2.5 py-1 w-fit text-white text-xs font-bold leading-3 rounded-lg mx-auto flex justify-center items-center">
              {client_domain}
            </div>
          )}
        </div>
        <div className="ct-col ct-qf ct-vsm-font">
          {client_domain === '' ? (
            <a className="btn-link" onClick={() => this.open_chat(student)}>
              Chat
            </a>
          ) : null}
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div>{moment(student.created_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(student.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col ct-qf ct-sm-font  ct-si">
          {student.is_member === 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-hf ct-vsm-font ct-bw">
          <div>{student.funnel_url}</div>
          <div>{student.funnel_type}</div>
          <div>{student.offer_type}</div>
        </div>
        <div className="ct-col ct-qf ct-la ct-vsm-font">
          {student.concierge_uuid ? (
            <a className="link-no-dec" href={concierge_url}>
              <div className="profile-img-name">
                <img src={student.concierge_photo} onError={image_error_replace} alt="x" />
                {student.concierge_name}
              </div>
            </a>
          ) : (
            student.concierge_name
          )}
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.yoga_sessions_finshed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.yoga_sessions_finshed}
                </a>
              ) : (
                student.yoga_sessions_finshed
              )}
            </div>
            <div>
              {student.group_sessions_finshed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_finshed}
                </a>
              ) : (
                student.group_sessions_finshed
              )}
            </div>
          </div>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.yoga_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.yoga_sessions_scheduled}
                </a>
              ) : (
                student.yoga_sessions_scheduled
              )}
            </div>
            <div>
              {student.group_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_scheduled}
                </a>
              ) : (
                student.group_sessions_scheduled
              )}
            </div>
          </div>
        </div>
        <div className="ct-col ct-qf ct-sm-font">{student.credits}</div>
        <div
          className="ct-col ct-qf ct-sm-font ct-si"
          style={{ cursor: 'pointer' }}
          onClick={() => this.setState({ open_info: true, student_info: student })}
        >
          <img src="https://images.myyogateacher.com/icons/ic_info_blue.png" />
        </div>
        <div className="ct-col ct-qf ct-sm-font  ct-si">
          {student.to_student == 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : student.to_student == 0 ? (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          ) : student.to_student === null ? (
            <span>--</span>
          ) : null}
        </div>
        <div className="ct-col ct-qf">
          {(!!student.phone_personal && student.phone_personal != '') ||
          (!!student.phone_no && student.phone_no != '') ? (
            <button className="ct-small-btn" onClick={() => this.open_sms_window(student)}>
              Send SMS
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  get_href = (uuid, type) => {
    let final_href = null;
    if (type === 'YOGA') {
      final_href = `/sessiondetails?id=${uuid}`;
    } else {
      final_href = `/groupsessiondetails?session_uuid=${uuid}`;
    }
    return final_href;
  };

  get_excel_data = () => {
    const { students } = this.state;
    const teacherDataArr = [];
    students.forEach((item) => {
      const csvData = {
        name: item?.name,
        uuid: item?.uuid,
        created_date: moment(item?.created_date)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD HH:mm:ss z'),
        funnel_url: item?.funnel_url,
        offer_type: item?.offer_type,
        funnel_type: item?.funnel_type,
        device_type: item?.device_type,
        yoga_level: item?.yoga_level,
        trial_end_date: moment(item?.trial_end_date)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD HH:mm:ss z'),
        affiliate: item?.affiliate,
        queue_affiliate: item?.queue_affiliate,
        credits: item?.credits,
        concierge_name: item?.concierge_name,
        is_member: item?.is_member,
        phone_personal: item?.phone_personal,
        yoga_sessions_finshed: item?.yoga_sessions_finshed,
        group_sessions_finshed: item?.group_sessions_finshed,
        yoga_sessions_scheduled: item?.yoga_sessions_scheduled,
        group_sessions_scheduled: item?.group_sessions_scheduled,
        client_domain: item?.client_domain
      };
      teacherDataArr.push('csvData', csvData);
    });
    return teacherDataArr;
  };

  render_data = () => {
    const { students, concierge_teacher_uuid, is_header_sticky } = this.state;
    let filter_students = [...students];
    if (concierge_teacher_uuid) {
      filter_students = students.filter((s) => {
        if (concierge_teacher_uuid === 'all') {
          return !!s.concierge_uuid;
        }
        return s.concierge_uuid === concierge_teacher_uuid;
      });
    }
    return (
      <div className="cus-table">
        <div className={`ct-row ct-h ${is_header_sticky ? 'cus-sticky' : ''}`} ref={this.headerRef}>
          <div className="ct-col ct-hf ct-la">Name</div>
          <div className="ct-col ct-qf ct-sm-font">Chat</div>
          <div className="ct-col ct-hf ct-sm-font">Created Date</div>
          <div className="ct-col ct-qf ct-sm-font">Member</div>
          <div className="ct-col ct-hf ct-sm-font">
            <div>Funnel Url</div>
            <div>Source</div>
            <div>Offer</div>
          </div>
          <div className="ct-col ct-qf ct-sm-font ct-la">Concierge</div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Session - Fin</div>
            <div>1on1 | GROUP</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Session - SCH</div>
            <div>1on1 | GROUP</div>
          </div>
          <div className="ct-col ct-qf ct-sm-font">Credits</div>
          <div className="ct-col ct-qf ct-sm-font">Info</div>
          <div className="ct-col ct-qf ct-sm-font">Reply Sent</div>
          <div className="ct-col ct-qf ct-sm-font" />
        </div>
        {filter_students.map(this.render_single_student)}
      </div>
    );
  };

  close_history = () => this.setState({ history_open: false, open_info: false, student_info: {} });

  open_sms_window = (student) => {
    this.setState({
      sms_window_open: true,
      sms_student: student.uuid,
      sms_student_name: student.name
    });
  };

  on_reply_sent = () => {
    const { students, send_sms_uuid, sms_student } = this.state;
    const new_students_data = [...students];
    const student = new_students_data.find((s) => s.uuid === sms_student);
    if (student) {
      student.to_student = 1;
      this.setState({ students: new_students_data });
    }
  };

  close_sms_window = () => this.setState({ sms_window_open: false, sms_student: '' });

  render_info = () => {
    const { student_info } = this.state;
    return (
      <div>
        <p>Name - {student_info.name}</p>
        <p>Affiliate - {student_info.affiliate}</p>
        <p>Queue Table Affiliate - {student_info.queue_affiliate}</p>
        <p>
          Trial End -{' '}
          {moment(student_info.trial_end_date).tz(this.props.iana_timezone).format(day_format)}{' '}
          {moment(student_info.trial_end_date).tz(this.props.iana_timezone).format(time_format)}
        </p>
      </div>
    );
  };

  render() {
    const {
      loading,
      history_open,
      history,
      open_info,
      sms_window_open,
      sms_student,
      sms_student_name
    } = this.state;
    return (
      <CommonHeader loading={loading} title="New Users">
        <CustomModal show={history_open} close={this.close_history}>
          <div>{history}</div>
        </CustomModal>
        <CustomModal show={open_info} close={this.close_history}>
          {this.render_info()}
        </CustomModal>
        <div style={{ minHeight: '400px' }}>
          {this.render_filters()}
          {this.render_data()}
        </div>
        {sms_window_open ? (
          <SmsWindow
            student_uuid={sms_student}
            student_name={sms_student_name}
            close_fn={this.close_sms_window}
            send_success_notification={this.on_reply_sent}
          />
        ) : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUsers);
