import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import parse from 'html-react-parser';
import { post_api } from '../../../redux/api_funcs';
import { BackIcon, DownloadIcon, EditBlackIcon, Ratingstar } from '../../../utils/svg';
import { get_api_url } from '../../../utils/urls';
import CustomLoading from '../../util_components/custom_loading';
import { time_format } from '../../../constants';
import 'react-quill/dist/quill.snow.css';

function SessionSummary() {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sessionUUID, setSessionUUID] = useState('');
  const [generatedData, setGeneratedData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState('');
  const [originalNotes, setOriginalNotes] = useState('');
  const [rating, setRating] = useState(0);
  const textareaRef = useRef(null);
  const iana_timezone = useSelector((state) => state.home.iana_timezone);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const session_uuid = params.get('session_uuid');
    setSessionUUID(session_uuid);
  }, [location]);

  useEffect(() => {
    if (sessionUUID) {
      fetchSessionSummary();
    }
  }, [sessionUUID]);

  const handleBackClick = () => {
    history.goBack();
  };

  const fetchSessionSummary = async () => {
    const url = get_api_url('get_session_summary');
    setLoading(true);
    const payload = {
      session_uuid: sessionUUID
    };
    try {
      const response = await post_api(url, payload, true);
      if (response.data.status === 'success') {
        setGeneratedData(response.data);
        setNotes(response.data.manual_session_data || '');
        setOriginalNotes(response.data.manual_session_data || '');
        setRating(response.data.llm_generated_rating || 0);
      }
    } catch (error) {
      console.error('Error fetching session summaries:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const cleanHtmlContent = (html) => html.replace(/(<p><br><\/p>\s*)+$/, '');

  const handleSaveClick = async () => {
    setIsEditing(false);
    const getNotes = cleanHtmlContent(notes);
    const url = get_api_url('edit_manual_notes');
    setLoading(true);
    const payload = {
      session_uuid: sessionUUID,
      updated_notes: getNotes
    };
    try {
      const response = await post_api(url, payload, true);
      if (response.data.status === 'success') {
        fetchSessionSummary();
      }
    } catch (error) {
      console.error('Error updating manual notes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNotes(originalNotes);
  };

  const handleRatingClick = async (selectedRating) => {
    const url = get_api_url('rate_session_summary');
    setLoading(true);
    const payload = {
      session_uuid: sessionUUID,
      rating: selectedRating
    };
    try {
      const response = await post_api(url, payload, true);
      if (response.data.status === 'success') {
        setRating(selectedRating);
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = () => {
    const content = generatedData?.session_transcript;

    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'session_summary.txt';
    a.click();

    URL.revokeObjectURL(url);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'header',
    'font',
    'list',
    'bullet',
    'bold',
    'italic',
    'underline',
    'align',
    'link',
    'image'
  ];

  return (
    <>
      {loading && <CustomLoading />}
      <div className="common-page-container h-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2.5 cursor-pointer" onClick={handleBackClick}>
              <BackIcon />
              Back
            </div>
            <div className="font-extrabold text-[30px]">
              Session Summary: {generatedData?.teacher_name || ''}
            </div>
          </div>
          <div
            onClick={handleDownloadClick}
            className="border border-black border-dashed rounded-lg flex items-center gap-2.5 p-2.5 cursor-pointer"
          >
            <DownloadIcon />
            <div className="text-black font-medium text-lg"> Download Session Transcript</div>
          </div>
        </div>
        <div className="text-sm text-black font-medium mt-2.5">
          Date of Session:{' '}
          <b>
            {moment(generatedData?.session_start_time)?.tz(iana_timezone)?.format('DD MMM YYYY') ||
              ''}{' '}
            at{' '}
            {moment(generatedData?.session_start_time)?.tz(iana_timezone)?.format(time_format) ||
              ''}
          </b>
        </div>
        <div className="flex items-start gap-4 mt-10">
          <div className="max-w-full flex-1">
            <div className="font-bold text-lg mb-2.5">AI Generated Summarization</div>
            <div className="border border-solid border-black rounded-lg p-4 text-base font-medium flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-base">summary of the Session</div>
                <div className="text-sm leading-normal">
                  {generatedData?.summary?.length > 0 ? generatedData.summary : 'No Data'}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-base">Goals of the Session</div>
                <div className="text-sm leading-normal">
                  <ul className="list-disc mx-6">
                    {generatedData?.student_goals?.length > 0
                      ? generatedData?.student_goals?.map((item, index) => (
                          <li key={index} className="text-sm leading-normal">
                            {item}
                          </li>
                        ))
                      : 'No Data'}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-base">Health History</div>
                <div className="text-sm leading-normal">
                  <ul className="list-disc mx-6">
                    {generatedData?.health_history?.length > 0
                      ? generatedData?.health_history?.map((item, index) => (
                          <li key={index} className="text-sm leading-normal">
                            {item}
                          </li>
                        ))
                      : 'No Data'}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-base">Teacher Recommendations</div>
                <ul className="list-disc mx-6">
                  {generatedData?.teacher_recommendations?.length > 0
                    ? generatedData?.teacher_recommendations?.map((item, index) => (
                        <li key={index} className="text-sm leading-normal">
                          {item}
                        </li>
                      ))
                    : 'No Data'}
                </ul>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-base">Yoga Poses Practised</div>
                <ul className="list-disc mx-6 flex flex-col gap-3">
                  {generatedData?.yoga_poses?.length > 0
                    ? generatedData?.yoga_poses?.map((item, index) => (
                        <div key={index}>
                          <li className="text-sm leading-normal">
                            <b>Pose Name:</b> {item.pose_name}
                          </li>
                          <li className="text-sm leading-normal">
                            <b>Start Time Code:</b> {item.start_pose_timecode}
                          </li>
                          <li className="text-sm leading-normal">
                            <b>End Time Code:</b> {item.end_pose_timecode}
                          </li>
                        </div>
                      ))
                    : 'No Data'}
                </ul>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div className="font-bold text-lg mb-2.5">Manual Notes</div>
            <div className="border border-solid border-black rounded-lg p-4 text-base font-medium max-w-full">
              <div
                className="flex justify-end w-fit ml-auto cursor-pointer"
                onClick={handleEditClick}
              >
                <EditBlackIcon />
              </div>
              <div className="mt-2 manual-cnotes-container">
                {isEditing ? (
                  <ReactQuill
                    value={notes}
                    onChange={(value) => {
                      setNotes(value);
                    }}
                    modules={modules}
                    formats={formats}
                    ref={textareaRef}
                  />
                ) : (
                  <div
                    className={`leading-[22px] manual-notes ${notes ? 'font-medium text-sm' : 'font-bold text-base'}`}
                  >
                    {parse(notes || 'Add Manual Notes')}
                  </div>
                )}
              </div>
              {isEditing && (
                <div className="flex items-center gap-2 justify-end mt-5">
                  <button onClick={handleCancelClick}>Cancel</button>
                  <button onClick={handleSaveClick}>Save</button>
                </div>
              )}
            </div>
          </div>
        </div>
        {generatedData?.faq_details?.length > 0 ? (
          <div className="mt-10">
            <div className="font-bold text-lg mb-5">Key details from the Summary</div>
            <div className="border border-solid border-black rounded-lg p-4 text-base font-medium max-w-full flex flex-col gap-5">
              {generatedData?.faq_details?.map((item, index) => (
                <div key={index} className="flex flex-col gap-1">
                  <div className="text-sm font-medium">
                    <b>Question:</b> {item.question}
                  </div>
                  <div className="text-sm font-medium">
                    <b>Answer:</b> {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="my-10">
          <div className="font-bold text-base leading-22px">Rate this LLM-Gererated Summary</div>
          <div className="flex">
            {[1, 2, 3, 4, 5].map((star) => (
              <Ratingstar
                key={star}
                filled={star <= rating}
                onClick={() => handleRatingClick(star)}
                className="cursor-pointer"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SessionSummary;
