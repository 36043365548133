import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import { image_error_replace } from '../../../util_functions';
import { time_format, day_format_year } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class MembershipCancellations extends Component {
  state = {
    loading: false,
    cancellations: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_cancelled_memberships');
      post_api(url, null, true)
        .then((res) => {
          console.log(res.data);
          this.setState({ cancellations: [...res.data.cancellations], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_cancellation = (cancel) => {
    const student_url = `/studentprofile?id=${cancel.student_uuid}`;
    const cancellation_name =
      cancel.cancelled_by === 'care'
        ? `Care - ${cancel.care_name}`
        : cancel.cancelled_by === 'cron'
          ? 'cron'
          : null;
    return (
      <div className="ct-row" key={cancel.uuid}>
        <div className="ct-col ct-hf">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={cancel.student_photo} onError={image_error_replace} alt="x" />
              {cancel.student_name}
            </div>
            <div className="ct-sm-font">{cancellation_name}</div>
          </a>
        </div>
        <div className="ct-col ct-la">
          <ul className="ct-sm-font">
            {cancel.primary_reasons.map((r) => (
              <li>{r.cancellation_primary_reason}</li>
            ))}
          </ul>
        </div>
        <div className="ct-col ct-la">
          <ul className="ct-sm-font">
            {cancel.secondary_reasons.map((r) => (
              <li>{r.cancellation_secondary_reason}</li>
            ))}
          </ul>
        </div>
        <div className="ct-col ct-hf ct-sm-font">{cancel.cancellation_other_reason}</div>
        <div className="ct-col ct-hf ct-sm-font">{cancel.cancellation_feedback}</div>
        <div className="ct-col ct-qf ct-sm-font">
          <div>
            {moment(cancel.created_date).tz(this.props.iana_timezone).format(day_format_year)}
          </div>
          <div>{moment(cancel.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, cancellations } = this.state;
    return (
      <CommonHeader loading={loading} title="Membership Cancellations" show_back>
        <div style={{ marginTop: '30px' }}>
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf">
                Student
                <div className="ct-sm-font">(By)</div>
              </div>
              <div className="ct-col ct-la">Primary reasons</div>
              <div className="ct-col ct-la">Secondary reasons</div>
              <div className="ct-col ct-hf">Other reason</div>
              <div className="ct-col ct-hf">Feedback</div>
              <div className="ct-col ct-qf">Date</div>
            </div>
            {cancellations.map(this.render_single_cancellation)}
          </div>
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCancellations);
