import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CustomLoading from '../util_components/custom_loading';
import Spinner from '../util_components/spinner';
import * as actions from '../../redux/action_creators';
import './login.css';

class Login extends Component {
  state = {
    email: '',
    password: '',
    input_error: false
  };

  componentDidMount() {
    if (this.props.cms_token !== '' && this.props.permissions_status === 'none') {
      this.props.get_permissions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login_status === 'loading' && this.props.login_status === 'success') {
      this.props.get_permissions();
    }
    if (prevProps.login_status === 'loading' && this.props.login_status === 'fail') {
      // this.setState({ input_error: true });
      this.props.set_notification_variable(true, 'error', this.props.login_error_message);
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      input_error: false
    });
  };

  login = () => {
    const payload = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.login_to_app(payload);
  };

  on_key_press = (e) => {
    if (e.key === 'Enter') {
      this.login();
    }
  };

  render() {
    if (this.props.cms_token !== '') {
      if (this.props.permissions_status !== 'success') {
        return <CustomLoading full_loader />;
      }
      this.props.set_home_variable('login_status', 'none');
      const url = new URL(window.location.href);
      const redirect_url = url.searchParams.get('redirect_url');
      const final_url = redirect_url || this.props.access_pages[0];
      console.log('final_url', final_url);
      return <Redirect to={final_url} />;
    }
    return (
      <div className="login-container">
        <img
          src="https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png"
          alt="logo"
        />
        <div className="login">
          <h1>Login to Hridaya</h1>
          <input
            name="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.onChange}
          />
          <input
            name="password"
            type="password"
            placeholder="Enter password"
            value={this.state.password}
            onChange={this.onChange}
            onKeyPress={this.on_key_press}
          />
          <button onClick={this.login}>Login</button>
          {this.state.input_error ? (
            <div className="login-error">*{this.props.login_error_message}</div>
          ) : null}
          <div className="login-loading">
            {this.props.login_status === 'loading' ? <Spinner circleRadius={50} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login_status: state.home.login_status,
  cms_token: state.home.cms_token,
  login_error_message: state.home.login_error_message,
  permissions_status: state.loading.permissions_status,
  access_pages: state.home.access_pages
});

const mapDispatchToProps = (dispatch) => ({
  login_to_app: (payload) => dispatch(actions.login_to_app(payload)),
  set_home_variable: (key, payload) => dispatch(actions.set_home_variable(key, payload)),
  get_permissions: () => dispatch(actions.get_permissions()),
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
