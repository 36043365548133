import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import CustomLoading from '../../util_components/custom_loading';
import { modules, formats, email_template_tags } from '../../../constants';
import { convert_to_pascal_with_spaces } from '../../../util_functions';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import Button from '../../util_components/button';
import * as actions from '../../../redux/action_creators';
import { post_api } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';

const select_days = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 }
];
class EmailTemplates extends Component {
  state = {
    loading: true,
    email_body: '',
    templates: [],
    template_selected: '',
    email: '',
    test_days: 0,
    edit_as_html: false,
    test_email_error: false,
    modal_open: false
  };

  quillRef = null; // Quill instance

  reactQuillRef = null; // ReactQuill component

  componentDidMount() {
    this.attachQuillRefs();
    this.props.get_email_templates();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.edit_as_html) {
      this.attachQuillRefs();
    }
    if (
      prevProps.get_templates_status === 'loading' &&
      this.props.get_templates_status === 'success'
    ) {
      const templates = [...this.props.report_templates];
      const final_templates = templates.map((t, index) => ({
        ...t,
        index,
        label: t.email_type,
        value: convert_to_pascal_with_spaces(t.email_type)
      }));
      this.setState({
        templates: [...final_templates],
        loading: false,
        report_index: 0,
        email_body: final_templates[0].email_body,
        template_selected: final_templates[0].email_type,
        email_subject: final_templates[0].email_subject
      });
    }
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  send_test_email = () => {
    this.setState({ loding: true }, () => {
      const payload = {
        email: this.state.email,
        subject: `Test email - ${this.state.email_subject}`,
        body: this.state.email_body,
        days: this.state.test_days
      };
      const url = get_api_url('send_test_email');
      post_api(url, payload, true)
        .then(() => this.setState({ loading: false, modal_open: true }))
        .catch((e) => this.setState({ loading: false, modal_open: true, test_email_error: true }));
    });
  };

  handleEmailBody = (val) => this.setState({ email_body: val });

  onBodyTagSelect = (e) => {
    const range = this.quillRef.getSelection();
    if (range) {
      const position = range ? range.index : 0;
      this.quillRef.insertText(position, e.target.value);
    }
  };

  onEmailTemplateChange = () => {};

  onSubjectTagSelect = (e) =>
    this.setState({ email_subject: `${this.state.email_subject} ${e.target.value}` });

  onSubjectChange = (e) => this.setState({ email_subject: e.target.value });

  onCommonChange = (e) => this.setState({ [e.target.name]: e.target.value });

  toggle_edit_html = () => this.setState({ edit_as_html: !this.state.edit_as_html });

  toggle_modal = () => this.setState({ modal_open: !this.state.modal_open });

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <CustomModal show={this.state.modal_open} close={this.toggle_modal}>
          {!this.state.test_email_error ? <p>Email Sent</p> : <p>Some error occured</p>}
        </CustomModal>
        <div className="common-page-header">
          <div className="common-page-title">Email Templates</div>
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CustomSingleSelect
            label="Select a Emial template"
            defaultOption="Select a template"
            options={this.state.templates}
            onChange={this.onEmailTemplateChange}
            value={this.state.template_selected}
            style={{ width: '200px' }}
          />
        </div>
        <div>
          <CustomSingleSelect
            label="Tags for subject"
            defaultOption="Select a tag"
            options={email_template_tags}
            onChange={this.onSubjectTagSelect}
            value=""
            style={{ width: '200px' }}
          />
        </div>
        <div className="custom-quill">
          <p>Email Subject:</p>
          <textarea value={this.state.email_subject} onChange={this.onSubjectChange} />
        </div>
        <div>
          <CustomSingleSelect
            label="Tags for body"
            defaultOption="Select a tag"
            options={email_template_tags}
            onChange={this.onBodyTagSelect}
            value=""
            style={{ width: '200px' }}
          />
        </div>
        <div className="custom-quill">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Email Body:</p>
            {/* <button onClick={this.toggle_edit_html}>{this.state.edit_as_html ? 'Edit as text' : 'Edit as Html'}</button> */}
          </div>
          {this.state.edit_as_html ? (
            <textarea
              style={{ height: '200px' }}
              value={this.state.email_body}
              name="email_body"
              onChange={this.onCommonChange}
            />
          ) : (
            <>
              <ReactQuill
                value={this.state.email_body || ''}
                onChange={this.handleEmailBody}
                modules={modules}
                formats={formats}
                ref={(el) => {
                  this.reactQuillRef = el;
                }}
              />
              <button>Save</button>
            </>
          )}
        </div>
        <div style={{ border: '1px solid', padding: '20px' }}>
          <p>Send a test email:</p>
          <CustomInput
            label="Enter email"
            style={{ width: '300px' }}
            value={this.state.value}
            name="email"
            onChange={this.onCommonChange}
          />
          <CustomSingleSelect
            label="Select no of days "
            options={select_days}
            onChange={this.onCommonChange}
            value={this.state.test_days}
            name="test_days"
          />
          <Button disabled={!this.state.email} onClick={this.send_test_email}>
            Send
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  report_templates: state.reports.report_templates,
  get_templates_status: state.loading.get_templates_status
});

const mapDispatchToProps = (dispatch) => ({
  get_email_templates: () => {
    dispatch(actions.get_email_templates());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
