import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import Spinner from '../../../util_components/spinner';

import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomModal from '../../../util_components/custom_modal';
import Button from '../../../util_components/button';
import { image_error_replace } from '../../../../util_functions';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';

import * as actions from '../../../../redux/action_creators';

class ReplacementSessions extends Component {
  state = {
    loading: true,
    change_teacher_id: '',
    change_reason: '',
    input_error: false,
    change_teacher: false,
    change_session_uuid: '',
    teachers_loaded: false,
    teachers_list: [],
    is_replace_loading: false,
    cancel_replacement: false,
    cancel_replacement_loading: false,
    cancel_replacement_id: ''
  };

  componentDidMount() {
    this.props.get_replacement_sessions();
  }

  submit_change_teacher = () => {
    this.setState({ is_replace_loading: true }, () => {
      const session_uuid = this.state.change_session_uuid;
      const teacher_uuid = this.state.change_teacher_id;
      const reason = this.state.change_reason;
      if (!!teacher_uuid && !!reason) {
        const url = get_api_url('change_teacher_for_session');
        const payload = { session_uuid, teacher_uuid, reason };
        post_api(url, payload, true)
          .then(() => {
            this.setState(
              {
                change_session_uuid: '',
                change_teacher_id: '',
                change_reason: '',
                change_teacher: false,
                loading: true,
                is_replace_loading: false
              },
              () => {
                this.props.get_replacement_sessions();
              }
            );
          })
          .catch(() => {
            this.setState({ is_replace_loading: false });
          });
      } else {
        this.setState({ input_error: true, is_replace_loading: false });
      }
    });
  };

  get_teachers_list = (student_uuid) => {
    const url = get_api_url('teachers_list_for_new_session');
    const data = {
      student_uuid
    };
    post_api(url, data, true)
      .then((response) => {
        this.setState({ teachers_list: response.data.teachers, teachers_loaded: true });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({ redirectLogin: true });
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.r_s_s === 'loading' && this.props.r_s_s === 'success') {
      this.setState({ loading: false });
    }
  }

  handle_teacher_change = (e) => this.setState({ change_teacher_id: e.target.value });

  handle_change_reasons = (e) => this.setState({ change_reason: e.target.value });

  render_change_teacher = () => {
    const teachers_list = this.state.teachers_list.map((teacher) => ({
      value: teacher.teacher_uuid,
      label: teacher.name
    }));
    return (
      <CustomModal show={this.state.change_teacher} close={this.close_replace_teacher}>
        {this.state.is_replace_loading ? (
          <Spinner />
        ) : (
          <>
            <h5>Change Teacher</h5>
            {!this.state.teachers_loaded ? (
              <Spinner type="dots" />
            ) : (
              <div style={{ margin: '10px 0' }}>
                <CustomSingleSelect
                  label="Select a teacher"
                  options={teachers_list}
                  onChange={this.handle_teacher_change}
                  value={this.state.change_teacher_id || ''}
                  style={{ width: '300px' }}
                  data-session_id={this.state.change_session_uuid}
                />
              </div>
            )}
            <div style={{ margin: '10px 0' }}>
              <textarea
                placeholder="enter a reason"
                value={this.state.change_reason || ''}
                data-session_id={this.state.change_session_uuid}
                onChange={this.handle_change_reasons}
                style={{ width: '300px', fontSize: '14px', lineHeight: '20px', height: '60px' }}
              />
            </div>
            <Button
              data-session_id={this.state.change_session_uuid}
              disabled={!this.state.teachers_loaded}
              onClick={this.submit_change_teacher}
            >
              Submit
            </Button>
          </>
        )}
      </CustomModal>
    );
  };

  replace_teacher = (uuid, student_uuid) => {
    this.get_teachers_list(student_uuid);
    this.setState({ change_session_uuid: uuid, change_teacher: true });
  };

  close_replace_teacher = () =>
    this.setState({
      change_session_uuid: '',
      change_teacher_id: '',
      change_reason: '',
      change_teacher: false,
      teachers_loaded: false,
      teachers_list: []
    });

  cancel_replacement = (session_uuid) =>
    this.setState({ cancel_replacement_id: session_uuid, cancel_replacement: true });

  close_cancel_replacement = () =>
    this.setState({ cancel_replacement_id: '', cancel_replacement: false });

  render_sessions = (sessions, type) => {
    if (sessions.length === 0) {
      return null;
    }
    return (
      <>
        <p>{type === 'care' ? 'Reqested sessions to care:' : 'Requested sessions to teachers:'}</p>
        <table>
          <thead>
            <tr>
              <th className="th-name-header">
                <span>Session Details</span>
              </th>
              <th className="th-name-header">
                <span>Replacement Details</span>
              </th>
              <th className="th-name-header">
                <span>Session Type</span>
              </th>
              <th className="th-name-header">
                <span>Session Duration</span>
              </th>
              <th className="th-name-header">
                <span>Teacher</span>
              </th>
              <th>TIMINGS</th>
              <th className="th-name-header">
                <span>STUDENT</span>
              </th>
              {/* <th>Meeting Id</th> */}
              <th>Replacement Request Status</th>
              <th>Replacement Request Action</th>
              <th>Requested Time</th>
              <th>Reason for replacement</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((session, index) => {
              const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
              const student_url = `/studentprofile?id=${session.student_uuid}`;
              const sessionUrl = `/sessiondetails?id=${session.session_uuid}`;
              const replacement_teachers = `/replacementsessions/${session.replacement_request_id}`;
              return (
                <tr key={session.session_uuid + index}>
                  <td>
                    <Button
                      onClick={() => {
                        this.props.history.push(sessionUrl);
                      }}
                    >
                      Session Details
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        this.props.history.push(replacement_teachers);
                      }}
                    >
                      Replacement Details
                    </Button>
                  </td>
                  <td>{session.session_type}</td>
                  <td>{session.session_duration}</td>

                  <td>
                    <a className="link-no-dec" href={teacher_url}>
                      <div className="profile-img-name">
                        <img
                          src={session.teacher_profile_photo}
                          alt="i"
                          onError={image_error_replace}
                        />
                        {session.teacher_name}
                      </div>
                    </a>
                  </td>
                  <td className="low-text">{session.start_start_time_local}</td>
                  <td>
                    <a className="link-no-dec" href={student_url}>
                      <div className="profile-img-name">
                        <img
                          src={session.student_profile_photo}
                          alt="i"
                          onError={image_error_replace}
                        />
                        {session.student_name}
                      </div>
                    </a>
                  </td>
                  {/* <td>
											<span className="low-text">{session.zoom_id}</span>
										</td> */}
                  <td>{session.replacement_request_status}</td>
                  <td>{session.replacement_request_action}</td>
                  <td>{session.replacement_requested_time_local}</td>
                  <td>{session.reason_for_replacement}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  submit_cancel_replacement = () => {
    this.setState({ cancel_replacement_loading: true }, () => {
      const url = get_api_url('update_replacement_status');
      const payload = {
        session_uuid: this.state.cancel_replacement_id
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(
            true,
            'success',
            'Session has been successfully sent back to teacher'
          );
          this.setState({
            cancel_replacement_loading: false,
            cancel_replacement: false,
            cancel_replacement_id: ''
          });
          this.props.get_replacement_sessions();
        })
        .catch((e) => {
          console.log(e);
          this.props.set_notification_variable(
            true,
            'error',
            'Something happened. Please contact dev team'
          );
          this.setState({
            cancel_replacement_loading: false,
            cancel_replacement: false,
            cancel_replacement_id: ''
          });
        });
    });
  };

  render_func = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const { sessions } = this.props;
    const care_sessions = sessions.filter((s) => s.request_replacement_status === 'SENT_TO_CARE');
    const teacher_sessions = sessions.filter(
      (s) => s.request_replacement_status !== 'SENT_TO_CARE'
    );
    return (
      <div className="common-page-container">
        <CustomModal show={this.state.cancel_replacement} close={this.close_cancel_replacement}>
          <>
            <div style={{ marginBottom: '10px' }}>Are you sure you want to cancel replacement?</div>
            <Button
              onClick={this.submit_cancel_replacement}
              disabled={this.state.cancel_replacement_loading}
            >
              Submit
            </Button>{' '}
            <Button onClick={this.close_cancel_replacement}>Close</Button>
          </>
        </CustomModal>
        {this.render_change_teacher()}
        <div className="common-page-header">
          <div className="common-page-title">Pending Replacements</div>
        </div>
        {sessions.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '10px' }}>No replacement requests</div>
        ) : (
          <>
            {this.render_sessions(care_sessions, 'care')}
            {this.render_sessions(teacher_sessions, 'teach')}
          </>
        )}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Replacement Sessions</title>
        </Helmet>
        {this.render_func()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sessions: state.home.replacement_sessions,
  r_s_s: state.loading.replacement_sessions_status,
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_replacement_sessions: (status) => {
    dispatch(actions.get_replacement_sessions(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementSessions);
