import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import { modules, formats } from '../../../../constants';
import { get_api_url } from '../../../../utils/urls';
import 'react-quill/dist/quill.snow.css';
import './roadmap.css';

export default class AddRoadmap extends Component {
  state = {
    pretext: '',
    objectives: '',
    diagnosis: '',
    plan: '',
    results: '',
    loading: true,
    update_success: false
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    this.setState({ student_uuid: uuid }, () => {
      this.getStudentsDetail();
    });
  }

  submitStudentsDetail = () => {
    const { pretext, objectives, plan, diagnosis, results, student_uuid } = { ...this.state };
    const url = get_api_url('insert_or_update_student_roadmap');
    const payload = { pretext, objectives, plan, diagnosis, results, student_uuid };
    post_api(url, payload, true).then((result) => {
      this.setState({ update_success: true });
    });
  };

  getStudentsDetail = () => {
    const url = get_api_url('get_student_roadmap');
    const payload = { student_uuid: this.state.student_uuid };
    post_api(url, payload, true).then((result) => {
      this.setState({
        pretext: result.data.roadmap ? result.data.roadmap.pretext : '',
        objectives: result.data.roadmap ? result.data.roadmap.objectives : '',
        diagnosis: result.data.roadmap ? result.data.roadmap.diagnosis : '',
        plan: result.data.roadmap ? result.data.roadmap.plan : '',
        results: result.data.roadmap ? result.data.roadmap.results : '',
        loading: false
      });
    });
  };

  handleObjectives = (value) => {
    this.setState({ objectives: value });
  };

  handleDiagnosis = (value) => {
    this.setState({ diagnosis: value });
  };

  handlePlan = (value) => {
    this.setState({ plan: value });
  };

  handleResults = (value) => {
    this.setState({ results: value });
  };

  handlePretext = (value) => {
    this.setState({ pretext: value });
  };

  go_back = () => {
    this.props.history.goBack();
  };

  toggleSuccess = () => this.setState({ update_success: !this.state.update_success });

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <CustomModal show={this.state.update_success} close={this.toggleSuccess}>
          Roadmap Updated Successfully
        </CustomModal>
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Add Roadmap</div>
        </div>
        <div className="custom-quill">
          <h5>Pretext:</h5>
          <ReactQuill
            value={this.state.pretext || ''}
            onChange={this.handlePretext}
            modules={modules}
            formats={formats}
          />
        </div>
        <div className="custom-quill">
          <h5>Student Objectives:</h5>
          <ReactQuill
            value={this.state.objectives || ''}
            onChange={this.handleObjectives}
            modules={modules}
            formats={formats}
          />
        </div>
        <div className="custom-quill">
          <h5>Teacher Diagnosis:</h5>
          <ReactQuill
            value={this.state.diagnosis || ''}
            onChange={this.handleDiagnosis}
            modules={modules}
            formats={formats}
          />
        </div>
        <div className="custom-quill">
          <h5>Plan:</h5>
          <ReactQuill
            value={this.state.plan || ''}
            onChange={this.handlePlan}
            modules={modules}
            formats={formats}
          />
        </div>
        <div className="custom-quill">
          <h5>Results:</h5>
          <ReactQuill
            value={this.state.results || ''}
            onChange={this.handleResults}
            modules={modules}
            formats={formats}
          />
        </div>
        <button onClick={this.submitStudentsDetail}>Submit</button>
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Add Roadmap</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}
