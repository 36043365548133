import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CommonHeader from '../../util_components/common_header';
import CustomModal from '../../util_components/custom_modal';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomDatePicker from '../../util_components/custom_date_picker';

import {
  image_error_replace,
  getCookie,
  get_date_filter_data_timezone_utc
} from '../../../util_functions';
import {
  day_format,
  time_format,
  date_drop_for_report,
  iana_values,
  trial_end_date_drop_for_report,
  newUsersDownloadList
} from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import SmsWindow from './sms_window';

import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../util_components/button';
import Single_teacher_availability from '../teachers/single_teacher_availability';

import UserRole from '../../../../myt_chat/src/chat/enums/UserRole';
import { openChatWithUserUUID } from '../../chat/chat';

class NewUsers extends Component {
  state = {
    loading: true,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    start_date_for_trial: moment().format('YYYY-MM-DD'),
    end_date_for_trial: moment().format('YYYY-MM-DD'),
    students: [],
    history: '',
    history_open: '',
    open_info: false,
    student_info: {},
    concierge_teachers: [],
    concierge_teacher_uuid: 'all',
    selected_iana_timezone: 'ALL',
    is_header_sticky: false,
    sms_window_open: false,
    sms_student: '',
    sms_student_name: '',
    is_sorted: false,
    show_concierge_detail: false,
    s_uuid: '',
    concierge_detail: '',
    on_boarding_sort: -1,
    active_score_sort: -1,
    show_arrow: false,
    show_activity_score_arrow: false,
    onboarding_score_for_popup: '',
    iana_abbr_list: [],
    is_back_pain_client: 0,
    original_message_uuid: '',
    show_teacher_popup: false,
    teachers_list: [],
    teachers_loaded: false,
    change_teacher_id: '',
    consult_session_uuid: '',
    filtered_consultant_list: [],
    selected_consultant_uuid: 'all',
    date_filter_type: 'TODAY',
    trial_end_date_filter: ''
  };

  headerRef = React.createRef();

  async componentDidMount() {
    const url = window.location.href;
    const isBackPain = url.split('/').includes('backpain');
    if (isBackPain) {
      this.setState({ is_back_pain_client: 1 });
    } else {
      this.setState({ is_back_pain_client: 0 });
    }

    this.load_data();
    // let jwt_token = getCookie('care_chat_token');

    // MytChatService.shared.connect({
    //     serverHost: get_api_url('mytChatUrl'),
    //     authToken: jwt_token,
    //     nickname: 'Care',
    //     avatar: 'https://images.myyogateacher.com/banyan-logo-200.png',
    // });

    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }

    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    if (prevProps.location.pathname.toString() !== this.props.location.pathname.toString()) {
      this.setState({ date_filter_type: 'TODAY' }, () => {
        this.clear_concierge();
        this.clear_timezone();
        const url = window.location.href;
        const isBackPain = url.split('/').includes('backpain');
        if (isBackPain) {
          this.setState({ is_back_pain_client: 1 }, () => {
            this.load_data();
          });
        } else {
          this.setState({ is_back_pain_client: 0 }, () => {
            this.load_data();
          });
        }
      });
    }
  }

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.opt_in_concierge === 1);
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));

    const all_consultant = this.props.teachers_list.filter(
      (teacher) =>
        (teacher.test_user === 0 && teacher.is_consultant_therapist === 1) ||
        teacher.uuid === 'aa73fd2e-273d-4d6f-a8f7-ee90cdcf0a6b'
    );
    const consultant_list = all_consultant.map((consultant) => {
      const is_consultant =
        consultant.is_consultant_therapist_in_preferred_group === 1
          ? ' (preferred consultant)'
          : '';
      return {
        value: consultant.uuid,
        label: `${consultant.first_name} ${consultant.last_name} ${is_consultant}`
      };
    });
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ concierge_teachers: [], filtered_consultant_list: [] }, () => {
      this.setState({
        concierge_teachers: [{ value: 'all', label: 'ALL' }, ...teachers_list],
        filtered_consultant_list: [{ value: 'all', label: 'ALL' }, ...consultant_list],
        show_arrow: false,
        show_activity_score_arrow: false,
        on_boarding_sort: -1,
        active_score_sort: -1
      });
    });
  };

  get_teachers_list_for_consult_change = (student_uuid) => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('teachers_list_for_new_session');
      const data = {
        student_uuid,
        session_type: 'BACK_PAIN_CONSULT'
      };
      post_api(url, data, true)
        .then((response) => {
          const { teachers } = response.data;
          teachers.sort((a, b) => a.name.localeCompare(b.name));
          this.setState({ teachers_list: teachers, loading: false });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  load_data = () =>
    this.setState({ loading: true, students: [] }, () => {
      const {
        date_filter_type,
        start_date,
        end_date,
        is_back_pain_client,
        trial_end_date_filter,
        end_date_for_trial,
        start_date_for_trial
      } = this.state;
      const url = get_api_url('get_concierge_students');
      let date_data;
      if (trial_end_date_filter !== '') {
        date_data = get_date_filter_data_timezone_utc(
          trial_end_date_filter,
          start_date_for_trial,
          end_date_for_trial
        );
      } else if (date_filter_type !== '') {
        date_data = get_date_filter_data_timezone_utc(date_filter_type, start_date, end_date);
      }

      const payload = {
        start_date: date_data.utc_start_date,
        end_date: date_data.utc_end_date,
        iana_timezone: this.props.iana_timezone,
        is_back_pain_client,
        get_by_trial_end_date: trial_end_date_filter !== '' ? 1 : 0
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState(
            {
              students: [...res.data.student_data],
              iana_abbr_list: [...res.data.iana_abbr_list],
              show_arrow: false,
              show_activity_score_arrow: false,
              on_boarding_sort: -1,
              active_score_sort: -1
            },
            () => {
              this.setState({ loading: false });
            }
          );
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  change_date_filter = (e) =>
    this.setState(
      { date_filter_type: e.target.value, students: [], trial_end_date_filter: '' },
      () => {
        this.load_data();
      }
    );

  change_trial_end_date_filter = (e) => {
    this.setState(
      { trial_end_date_filter: e.target.value, students: [], date_filter_type: '' },
      () => {
        this.load_data();
      }
    );
  };

  handle_date_change = (name, val) => this.setState({ [name]: val });

  change_concierge = (e) => {
    this.setState({ concierge_teacher_uuid: e.target.value });
  };

  change_consultant = (e) => this.setState({ selected_consultant_uuid: e.target.value });

  clear_consultant = (e) => this.setState({ selected_consultant_uuid: 'all' });

  change_iana_timezone = (e) => {
    this.setState({ selected_iana_timezone: e.target.value });
  };

  load_concierge_data = () => {
    this.setState({ loading: true }, () => {
      const { s_uuid } = this.state;
      const url = get_api_url('get_concierge_onboarding_score_details');
      const payload = {
        student_uuid: s_uuid
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ loading: false, concierge_detail: res.data });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  open_concierge_detail = (student_uuid, onboarding_score) => {
    this.setState({
      show_concierge_detail: true,
      s_uuid: student_uuid,
      onboarding_score_for_popup: onboarding_score
    });
    this.load_concierge_data();
  };

  concierge_profile = () => {
    const { concierge_detail, onboarding_score_for_popup } = this.state;
    return (
      <div>
        <table className="table-auto">
          <thead>
            <th>Onboarding Score</th>
            <th>{onboarding_score_for_popup}</th>
          </thead>
          <tbody style={{ fontSize: '14px', color: '#5f5f5f' }}>
            <tr>
              <th className="text-justify">Ethnicity Score (Western 1 Indian 2):</th>
              <td>{concierge_detail.ethnicity_score}</td>
            </tr>
            <tr>
              <th className="text-justify">
                Yoga level score (Newbie / Beginner 1 Intermediate / Advanced 2):
              </th>
              <td>{concierge_detail.yoga_level_score}</td>
            </tr>
            <tr>
              <th className="text-justify">Learner type score (Group 1 One-on-One 1.5):</th>
              <td>{concierge_detail.learner_type_score}</td>
            </tr>
            <tr>
              <th className="text-justify">Funnel score (Referral 4 Organic 3 Paid 1):</th>
              <td>{concierge_detail.funnel_score}</td>
            </tr>
            <tr>
              <th className="text-justify">Gender score (Male 1.25 Female 1):</th>
              <td>{concierge_detail.gender_score}</td>
            </tr>
            <tr>
              <th className="text-justify">
                Signup time score (7 am - 7 pm in user timezone 1 Else 0.5):
              </th>
              <td>{concierge_detail.signup_time_score}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  close_concierge_detail = () => {
    this.setState({ show_concierge_detail: false });
  };

  clear_trial_end_date = () => {
    this.setState({ trial_end_date_filter: '' });
  };

  clear_onboarding_date = () => {
    this.setState({ date_filter_type: '' });
  };

  render_new_filters = () => {
    const {
      date_filter_type,
      start_date,
      end_date,
      concierge_teachers,
      concierge_teacher_uuid,
      selected_iana_timezone,
      iana_abbr_list,
      filtered_consultant_list,
      selected_consultant_uuid,
      trial_end_date_filter,
      start_date_for_trial,
      end_date_for_trial
    } = this.state;
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-row items-center gap-4">
          <div>
            <CustomSingleSelect
              label="Select trial start date"
              options={date_drop_for_report}
              onChange={this.change_date_filter}
              value={date_filter_type}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_onboarding_date}
              defaultOptionDisabled={trial_end_date_filter !== ''}
            />
          </div>
          <div>
            <CustomSingleSelect
              label="Select trial end date"
              options={trial_end_date_drop_for_report}
              onChange={this.change_trial_end_date_filter}
              value={trial_end_date_filter}
              showClear
              style={{ width: '200px' }}
              clear_fn={this.clear_trial_end_date}
              defaultOptionDisabled={date_filter_type !== ''}
            />
          </div>

          <div className="flex items-center">
            {date_filter_type === 'CUSTOM' ? (
              <>
                <div style={{ borderLeft: '6px solid green', height: '50px' }} />
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="Start Date"
                    value={start_date}
                    handleChange={(val) => this.handle_date_change('start_date', val)}
                    maxDate={end_date}
                  />
                </div>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="End Date"
                    value={end_date}
                    handleChange={(val) => this.handle_date_change('end_date', val)}
                    minDate={start_date}
                    maxDate={moment().format('YYYY-MM-DD')}
                  />
                </div>
                <button style={{ height: '46px' }} onClick={this.load_data}>
                  Submit
                </button>
              </>
            ) : null}
            {trial_end_date_filter === 'CUSTOM' ? (
              <>
                <div style={{ borderLeft: '6px solid green', height: '50px' }} />
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="Start Date"
                    value={start_date_for_trial}
                    handleChange={(val) => this.handle_date_change('start_date_for_trial', val)}
                    minDate={start_date_for_trial}
                  />
                </div>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="End Date"
                    value={end_date_for_trial}
                    handleChange={(val) => this.handle_date_change('end_date_for_trial', val)}
                    minDate={start_date_for_trial}
                    // maxDate={moment().format('YYYY-MM-DD')}
                  />
                </div>
                <button style={{ height: '46px' }} onClick={this.load_data}>
                  Submit
                </button>
              </>
            ) : null}
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div>
            <CustomSingleSelect
              label="Select a concierge"
              options={concierge_teachers}
              onChange={this.change_concierge}
              value={concierge_teacher_uuid}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_concierge}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <CustomSingleSelect
              label="Select a timezone"
              options={iana_abbr_list}
              onChange={this.change_iana_timezone}
              value={selected_iana_timezone}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_timezone}
            />
          </div>
          <div>
            <CustomSingleSelect
              label="Select a consultant"
              options={filtered_consultant_list}
              onChange={this.change_consultant}
              value={selected_consultant_uuid}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_consultant}
            />
          </div>
        </div>
      </div>
    );
  };

  render_filters = () => {
    const {
      date_filter_type,
      start_date,
      end_date,
      concierge_teachers,
      concierge_teacher_uuid,
      selected_iana_timezone,
      iana_abbr_list,
      filtered_consultant_list,
      selected_consultant_uuid,
      trial_end_date_filter,
      start_date_for_trial,
      end_date_for_trial
    } = this.state;
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-row items-center gap-4">
          <div>
            <CustomSingleSelect
              label="Select trial start date"
              options={date_drop_for_report}
              onChange={this.change_date_filter}
              value={date_filter_type}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_onboarding_date}
              defaultOptionDisabled={trial_end_date_filter !== ''}
            />
          </div>
          <div>
            <CustomSingleSelect
              label="Select trial end date"
              options={trial_end_date_drop_for_report}
              onChange={this.change_trial_end_date_filter}
              value={trial_end_date_filter}
              showClear
              style={{ width: '200px' }}
              clear_fn={this.clear_trial_end_date}
              defaultOptionDisabled={date_filter_type !== ''}
            />
          </div>

          <div className="flex items-center">
            {date_filter_type === 'CUSTOM' ? (
              <>
                <div style={{ borderLeft: '6px solid green', height: '50px' }} />
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="Start Date"
                    value={start_date}
                    handleChange={(val) => this.handle_date_change('start_date', val)}
                    maxDate={end_date}
                  />
                </div>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="End Date"
                    value={end_date}
                    handleChange={(val) => this.handle_date_change('end_date', val)}
                    minDate={start_date}
                    maxDate={moment().format('YYYY-MM-DD')}
                  />
                </div>
                <button style={{ height: '46px' }} onClick={this.load_data}>
                  Submit
                </button>
              </>
            ) : null}
            {trial_end_date_filter === 'CUSTOM' ? (
              <>
                <div style={{ borderLeft: '6px solid green', height: '50px' }} />
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="Start Date"
                    value={start_date_for_trial}
                    handleChange={(val) => this.handle_date_change('start_date_for_trial', val)}
                  />
                </div>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="End Date"
                    value={end_date_for_trial}
                    handleChange={(val) => this.handle_date_change('end_date_for_trial', val)}
                  />
                </div>
                <button style={{ height: '46px' }} onClick={this.load_data}>
                  Submit
                </button>
              </>
            ) : null}
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div>
            <CustomSingleSelect
              label="Select a concierge"
              options={concierge_teachers}
              onChange={this.change_concierge}
              value={concierge_teacher_uuid}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_concierge}
            />
          </div>
          <div style={{ marginRight: '10px' }}>
            <CustomSingleSelect
              label="Select a timezone"
              options={iana_abbr_list}
              onChange={this.change_iana_timezone}
              value={selected_iana_timezone}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_timezone}
            />
          </div>
          <div>
            <CustomSingleSelect
              label="Select a consultant"
              options={filtered_consultant_list}
              onChange={this.change_consultant}
              value={selected_consultant_uuid}
              style={{ width: '200px' }}
              showClear
              clear_fn={this.clear_consultant}
            />
          </div>
        </div>
      </div>
    );
  };

  clear_concierge = () => this.setState({ concierge_teacher_uuid: 'all' });

  clear_timezone = () => this.setState({ selected_iana_timezone: 'ALL' });

  open_history = (e, history) => {
    e.stopPropagation();
    this.setState({ history, history_open: true });
  };

  open_chat = async (student) => {
    openChatWithUserUUID(student.uuid, UserRole.STUDENT);
  };

  get_href = (uuid, type) => {
    let final_href = null;
    if (type === 'YOGA') {
      final_href = `/sessiondetails?id=${uuid}`;
    } else {
      final_href = `/groupsessiondetails?session_uuid=${uuid}`;
    }
    return final_href;
  };

  sort_student_data = () => {
    const { students, on_boarding_sort } = this.state;
    const state_students = [...students];
    this.setState({ loading: true, students: [] }, () => {
      const final_student = state_students.sort(
        (a, b) =>
          (parseFloat(a.onboarding_score) - parseFloat(b.onboarding_score)) * on_boarding_sort
      );
      this.setState(
        {
          students: [...final_student],
          on_boarding_sort: on_boarding_sort * -1,
          show_activity_score_arrow: false,
          show_arrow: true
        },
        () => this.setState({ loading: false })
      );
    });
  };

  sort_activity_score = () => {
    const { students, active_score_sort } = this.state;
    const state_students = [...students];
    this.setState({ loading: true, students: [] }, () => {
      const final_students = state_students.sort(
        (a, b) => (parseFloat(a.activity_score) - parseFloat(b.activity_score)) * active_score_sort
      );
      this.setState(
        {
          students: [...final_students],
          active_score_sort: active_score_sort * -1,
          show_activity_score_arrow: true,
          show_arrow: false
        },
        () => this.setState({ loading: false })
      );
    });
  };

  render_single_student = (student, index) => {
    const student_url = `/studentprofile?id=${student.uuid}`;
    const teacher_url = `/teacherprofile?id=${student.teacher_uuid}`;
    const concierge_url = `/teacherprofile?id=${student.concierge_uuid}`;
    return (
      <div className="ct-row" key={index + student.uuid}>
        <div className="ct-col ct-hf ct-la ct-bw ct-sm-font">
          <a className="link-no-dec" href={student_url} target="_blank" rel="noopener noreferrer">
            <div className="profile-img-name">
              <img src={student.profile_photo} onError={image_error_replace} alt="x" />
              {student.name}
            </div>
            {student.yoga_level ? (
              <div
                style={{ fontSize: '10px', marginLeft: '42px' }}
              >{`(${student.yoga_level})`}</div>
            ) : null}
            <div style={{ fontSize: '8px', marginLeft: '42px' }}>{student.iana_timezone}</div>
          </a>
          {student.health_history ? (
            <div
              className="div-link"
              style={{ marginLeft: '42px' }}
              onClick={(e) => this.open_history(e, student.health_history)}
            >
              History
            </div>
          ) : null}
        </div>
        <div className="ct-col ct-qf ct-vsm-font">
          <a className="btn-link" onClick={() => this.open_chat(student)}>
            Chat
          </a>
        </div>
        <div className="ct-col ct-hf ct-vsm-font flex flex-col items-center">
          <div className="border-b border-solid border-black w-[90px]">
            <div>
              {moment(student.created_date).tz(this.props.iana_timezone).format(day_format)}
            </div>
            <div>
              {moment(student.created_date).tz(this.props.iana_timezone).format(time_format)}
            </div>
          </div>
          <div className="text-mytLightBlack">
            <div>
              {moment(student.trial_end_date).tz(this.props.iana_timezone).format(day_format)}
            </div>
            <div>
              {moment(student.trial_end_date).tz(this.props.iana_timezone).format(time_format)}
            </div>
          </div>
        </div>
        <div className="ct-col ct-qf ct-sm-font  ct-si">
          {student.is_member === 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-qf ct-sm-font flex justify-center item-center">
          <div>{student.onboarding_score}</div>
          <div
            className="cursor-pointer"
            onClick={() => this.open_concierge_detail(student.uuid, student.onboarding_score)}
          >
            <img
              src="https://images.myyogateacher.com/icons/ic_info_blue.png"
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        </div>
        <div className="ct-col ct-1f ct-sm-font">{student.activity_score}</div>
        <div className="ct-col ct-hf ct-vsm-font ct-bw">
          <div>{student.funnel_url}</div>
          <div>{student.funnel_type}</div>
          <div>{student.offer_type}</div>
        </div>
        <div className="ct-col ct-qf ct-la ct-vsm-font">
          {student.concierge_uuid ? (
            <a className="link-no-dec" href={concierge_url}>
              <div className="profile-img-name">
                {/* <img src={student.concierge_photo} onError={image_error_replace} alt='x' /> */}
                {student.concierge_name}
              </div>
            </a>
          ) : (
            student.concierge_name
          )}
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.yoga_sessions_finshed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.yoga_sessions_finshed}
                </a>
              ) : (
                student.yoga_sessions_finshed
              )}
            </div>
            <div>
              {student.group_sessions_finshed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_finshed}
                </a>
              ) : (
                student.group_sessions_finshed
              )}
            </div>
          </div>
        </div>

        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.yoga_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.yoga_sessions_scheduled}
                </a>
              ) : (
                student.yoga_sessions_scheduled
              )}
            </div>
            <div>
              {student.group_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_scheduled}
                </a>
              ) : (
                student.group_sessions_scheduled
              )}
            </div>
          </div>
        </div>
        <div className="ct-col ct-qf ct-sm-font">{student.credits}</div>
        <div
          className="ct-col ct-qf ct-sm-font ct-si"
          style={{ cursor: 'pointer' }}
          onClick={() => this.setState({ open_info: true, student_info: student })}
        >
          <img src="https://images.myyogateacher.com/icons/ic_info_blue.png" />
        </div>
        <div className="ct-col ct-qf ct-sm-font  ct-si">
          {student.to_student == 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : student.to_student == 0 ? (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          ) : student.to_student === null ? (
            <span>--</span>
          ) : null}
        </div>
        <div className="ct-col ct-qf">
          {(!!student.phone_personal && student.phone_personal != '') ||
          (!!student.phone_no && student.phone_no != '') ? (
            <button className="ct-small-btn" onClick={() => this.open_sms_window(student)}>
              Send SMS
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  // New Changes for change coach

  toggel_teacher = (student_uuid, consult_session_uuid) =>
    this.setState({ show_teacher_popup: true, consult_session_uuid }, () => {
      this.get_teachers_list_for_consult_change(student_uuid);
    });

  close_teacher_popup = () =>
    this.setState({
      show_teacher_popup: false,
      change_teacher_id: '',
      show_teacher_availability: false
    });

  handle_teacher_change = (e) => {
    this.setState({
      change_teacher_id: e.target.value,
      teacher_name: e.target.label,
      show_teacher_availability: true
    });
  };

  submit_change_teacher = () => {
    const session_uuid = this.state.consult_session_uuid;
    const teacher_uuid = this.state.change_teacher_id;
    const reason = 'Changing Virtual consult teacher';

    if (teacher_uuid) {
      const url = get_api_url('change_teacher_for_session');
      const payload = { session_uuid, teacher_uuid, reason };
      post_api(url, payload, true)
        .then(() => {
          this.setState(
            {
              change_session_uuid: '',
              change_teacher_id: '',
              show_teacher_popup: false,
              loading: true
            },
            () => {
              this.load_data();
            }
          );
        })
        .catch((e) => {
          let err_message;
          try {
            console.log(e.response);
            err_message = e.response.data.message || e.response.data.reason.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
    } else {
      this.setState({ input_error: true });
    }
  };

  change_teacher = () => {
    const { show_teacher_availability, change_teacher_id, teacher_name } = this.state;
    const teachers_list = this.state.teachers_list.map((teacher) => {
      const is_consultant =
        teacher.is_consultant_therapist_in_preferred_group == 1 ? ' (preferred consultant)' : '';
      return {
        value: teacher.teacher_uuid,
        label: `${teacher.name} ${is_consultant}`
      };
    });
    const name = teachers_list.filter((teacher) => teacher.value === change_teacher_id);
    return (
      <>
        <div className="flex flex-col items-center">
          <h5>Change Teacher</h5>
          <div className="flex items-center flex-row justify-center gap-10 ">
            <div style={{ margin: '10px 0' }}>
              <CustomSingleSelect
                label="Select a teacher"
                options={teachers_list}
                onChange={this.handle_teacher_change}
                value={this.state.change_teacher_id || ''}
                style={{ width: '300px' }}
                data-session_id={this.state.change_session_uuid}
              />
            </div>
            {change_teacher_id === '' ? null : (
              <Button
                data-session_id={this.state.change_session_uuid}
                onClick={this.submit_change_teacher}
              >
                Confirm change of teacher
              </Button>
            )}
          </div>
        </div>
        {change_teacher_id === '' ? null : <hr style={{ border: '3px solid blue' }} />}
        {show_teacher_availability ? (
          <Single_teacher_availability
            uuid={change_teacher_id}
            name={name[0]?.label}
            hide_back_button
          />
        ) : null}
      </>
    );
  };

  render_single_backpain_student = (student, index) => {
    const student_url = `/studentprofile?id=${student.uuid}`;
    const teacher_url = `/teacherprofile?id=${student.teacher_uuid}`;
    const concierge_url = `/teacherprofile?id=${student.concierge_uuid}`;
    return (
      <div className="ct-row" key={index + student.uuid}>
        <div className="ct-col ct-hf ct-la ct-bw ct-sm-font">
          <a className="link-no-dec" href={student_url} target="_blank" rel="noopener noreferrer">
            <div className="profile-img-name">
              <img src={student.profile_photo} onError={image_error_replace} alt="x" />
              {student.name}
            </div>
            {student.yoga_level ? (
              <div
                style={{ fontSize: '10px', marginLeft: '42px' }}
              >{`(${student.yoga_level})`}</div>
            ) : null}
          </a>
          {student.health_history ? (
            <div
              className="div-link"
              style={{ marginLeft: '42px' }}
              onClick={(e) => this.open_history(e, student.health_history)}
            >
              History
            </div>
          ) : null}
          {student?.client_email}
        </div>
        <div className="ct-col ct-hf ct-vsm-font">
          <div>{moment(student.created_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(student.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
          <div>{student.iana_timezone}</div>
        </div>
        <div className="ct-col ct-hf ct-vsm-font">{student?.phone_personal}</div>
        <div className="ct-col ct-hf ct-vsm-font">
          <div>{student?.consult_time}</div>
          {student?.is_virtual_backpain_consultant === 1 ? (
            <>
              <div>{student?.consultant_name}</div>
              <a
                className="cursor-pointer text-blue-600"
                onClick={() => this.toggel_teacher(student.uuid, student.consult_session_uuid)}
              >
                Change Teacher
              </a>
            </>
          ) : (
            <div>{student?.consultant_name}</div>
          )}
        </div>
        <div className="ct-col ct-hf ct-vsm-font">
          <div>{student?.onboarding_complete}</div>
        </div>
        <div className="ct-col ct-hf ct-vsm-font">
          <div>{student?.back_pain_coaching?.coach_name}</div>
          <div>{student?.back_pain_coaching?.coach_date}</div>
          <div>{student?.back_pain_coaching?.coach_time}</div>
        </div>
        <div className="ct-col ct-hf ct-vsm-font ct-si">
          {student?.purchased_pack === 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-qf ct-la ct-vsm-font">
          {student.concierge_uuid ? (
            <a className="link-no-dec" href={concierge_url}>
              <div className="profile-img-name">
                {/* <img src={student.concierge_photo} onError={image_error_replace} alt='x' /> */}
                {student.concierge_name}
              </div>
            </a>
          ) : (
            student.concierge_name
          )}
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.backpain_1on1_sessions_completed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.backpain_1on1_sessions_completed}
                </a>
              ) : (
                student.backpain_1on1_sessions_completed
              )}
            </div>
            <div>
              {student.group_sessions_finshed > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/past?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_finshed}
                </a>
              ) : (
                student.group_sessions_finshed
              )}
            </div>
          </div>
        </div>

        <div className="ct-col ct-hf ct-sm-font">
          <div className="new-users-sess-count">
            <div>
              {student.yoga_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.yoga_sessions_scheduled}
                </a>
              ) : (
                student.yoga_sessions_scheduled
              )}
            </div>
            <div>
              {student.group_sessions_scheduled > 0 ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/usersessions/future?id=${student.uuid}&user_type=STUDENT&name=${student.name}`}
                >
                  {student.group_sessions_scheduled}
                </a>
              ) : (
                student.group_sessions_scheduled
              )}
            </div>
          </div>
        </div>

        <div className="ct-col ct-qf ct-sm-font  ct-si">
          {student?.reply_sent == 1 ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : student?.reply_sent == 0 ? (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          ) : student?.reply_sent === null ? (
            <span>--</span>
          ) : null}
        </div>
        <div className="ct-col ct-qf">
          {(!!student.phone_personal && student.phone_personal != '') ||
          (!!student.phone_no && student.phone_no != '') ? (
            <button className="ct-small-btn" onClick={() => this.open_sms_window(student)}>
              Send SMS
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  render_backpain_data = () => {
    const {
      students,
      concierge_teacher_uuid,
      filtered_consultant_list,
      is_header_sticky,
      on_boarding_sort,
      show_arrow,
      show_activity_score_arrow,
      active_score_sort,
      selected_iana_timezone,
      iana_abbr_list,
      selected_consultant_uuid
    } = this.state;
    let filter_students = [];
    if (concierge_teacher_uuid) {
      filter_students = students.filter((s) => {
        if (concierge_teacher_uuid === 'all') {
          return !!s.concierge_uuid;
        }
        return s.concierge_uuid === concierge_teacher_uuid;
      });
    }
    if (!!selected_iana_timezone && selected_iana_timezone !== 'ALL') {
      const timezone = iana_abbr_list.find((i) => i.label === selected_iana_timezone);
      if (timezone) {
        filter_students = filter_students.filter((s) => s.iana_timezone_abbr === timezone.value);
      }
    }

    if (!!selected_consultant_uuid && selected_consultant_uuid !== 'all') {
      const consultant = filtered_consultant_list.find((i) => i.value === selected_consultant_uuid);
      if (consultant) {
        filter_students = filter_students.filter(
          (s) => s.consult_teacher_uuid === consultant.value
        );
      }
    }

    return (
      <div className="cus-table">
        <div className={`ct-row ct-h ${is_header_sticky ? 'cus-sticky' : ''}`} ref={this.headerRef}>
          <div className="ct-col ct-hf ct-la">Name</div>
          <div className="ct-col ct-hf ct-sm-font">
            <div>Created</div>
            <div>Date</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Contact</div>
            <div>Number</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Consult Time</div>
            <div>Consult Name</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Onboarding</div>
            <div>Complete</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Back Pain</div>
            <div>Coaching</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Purchased</div>
            <div>Pack</div>
          </div>
          <div className="ct-col ct-qf ct-sm-font ct-la">Concierge</div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Session - Fin</div>
            <div>1on1 | GROUP</div>
          </div>
          <div className="ct-col ct-hf ct-vsm-font">
            <div>Session - SCH</div>
            <div>1on1 | GROUP</div>
          </div>
          <div className="ct-col ct-qf ct-sm-font">Reply Sent</div>
          <div className="ct-col ct-qf ct-sm-font" />
        </div>

        {filter_students.map(this.render_single_backpain_student)}
      </div>
    );
  };

  get_excel_data = (students) => {
    const teacherDataArr = [];
    students.forEach((item) => {
      const csvData = {
        name: item?.name,
        uuid: item?.uuid,
        email: item?.client_email,
        created_date: moment(item?.created_date)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD HH:mm:ss z'),
        funnel_url: item?.funnel_url,
        offer_type: item?.offer_type,
        funnel_type: item?.funnel_type,
        device_type: item?.device_type,
        yoga_level: item?.yoga_level,
        trial_end_date: moment(item?.trial_end_date)
          .tz(this.props.iana_timezone)
          .format('YYYY-MM-DD HH:mm:ss z'),
        affiliate: item?.affiliate,
        queue_affiliate: item?.queue_affiliate,
        credits: item?.credits,
        concierge_name: item?.concierge_name,
        is_member: item?.is_member,
        phone_personal: item?.phone_personal,
        yoga_sessions_finshed: item?.yoga_sessions_finshed,
        group_sessions_finshed: item?.group_sessions_finshed,
        yoga_sessions_scheduled: item?.yoga_sessions_scheduled,
        group_sessions_scheduled: item?.group_sessions_scheduled,
        activity_score: item?.activity_score,
        onboarding_score: item?.onboarding_score,
        iana_timezone: item?.iana_timezone,
        onboarding_complete: item?.onboarding_complete,
        client_age: item?.client_age
      };
      teacherDataArr.push('csvData', csvData);
    });
    return teacherDataArr;
  };

  render_data = () => {
    const {
      students,
      concierge_teacher_uuid,
      is_header_sticky,
      on_boarding_sort,
      show_arrow,
      show_activity_score_arrow,
      active_score_sort,
      selected_iana_timezone,
      iana_abbr_list,
      is_back_pain_client
    } = this.state;
    let filter_students = [];
    if (concierge_teacher_uuid) {
      filter_students = students.filter((s) => {
        if (concierge_teacher_uuid === 'all') {
          return !!s.concierge_uuid;
        }
        return s.concierge_uuid === concierge_teacher_uuid;
      });
    }
    if (!!selected_iana_timezone && selected_iana_timezone !== 'ALL') {
      const timezone = iana_abbr_list.find((i) => i.label === selected_iana_timezone);
      if (timezone) {
        filter_students = filter_students.filter((s) => s.iana_timezone_abbr === timezone.value);
      }
    }
    const client_domain = is_back_pain_client === 1 ? 'backpain' : 'lifestyle';
    const file_name = `concierge_users_${client_domain}_{${moment().format('YYYY-MM-DD')}_${uuid()}`;

    return (
      <>
        <div style={{ textAlign: 'right' }}>
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={this.get_excel_data(filter_students)} name="Latest Students">
              {newUsersDownloadList.map((d) => (
                <ExcelColumn label={d.label} value={d.value} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="cus-table">
          <div
            className={`ct-row ct-h ${is_header_sticky ? 'cus-sticky' : ''}`}
            ref={this.headerRef}
          >
            <div className="ct-col ct-hf ct-la">Name</div>
            <div className="ct-col ct-qf ct-sm-font">Chat</div>
            <div className="ct-col ct-hf ct-sm-font flex flex-col items-center">
              <div className="border-b border-solid border-black w-[90px]">Trial start date</div>
              <div>Trial end date</div>
            </div>
            <div className="ct-col ct-qf ct-sm-font">Member</div>
            <div
              className="ct-col ct-qf ct-sm-font flex justify-evenly cursor-pointer"
              onClick={this.sort_student_data}
            >
              <div>
                <div>Onboarding</div>
                <div>Score</div>
              </div>
              {show_arrow && (
                <div>
                  {on_boarding_sort === 1 ? (
                    <img
                      src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1669961469/website/images/sort-a.png"
                      alt=""
                      style={{ width: '12px', height: 'auto', marginTop: '100%' }}
                    />
                  ) : (
                    <img
                      src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1669961603/website/images/sort-b.png"
                      alt=""
                      style={{ width: '12px', height: 'auto', marginTop: '100%' }}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              className="ct-col ct-1f ct-vsm-font flex justify-evenly cursor-pointer"
              onClick={this.sort_activity_score}
            >
              <div>
                <div>Activity</div>
                <div>Score</div>
              </div>
              {show_activity_score_arrow && (
                <div>
                  {active_score_sort === 1 ? (
                    <img
                      src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1669961469/website/images/sort-a.png"
                      alt=""
                      style={{ width: '12px', height: 'auto', marginTop: '100%' }}
                    />
                  ) : (
                    <img
                      src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1669961603/website/images/sort-b.png"
                      alt=""
                      style={{ width: '12px', height: 'auto', marginTop: '100%' }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="ct-col ct-hf ct-sm-font">
              <div>Funnel Url</div>
              <div>Source</div>
              <div>Offer</div>
            </div>
            <div className="ct-col ct-qf ct-sm-font ct-la">Concierge</div>
            <div className="ct-col ct-hf ct-vsm-font">
              <div>Session - Fin</div>
              <div>1on1 | GROUP</div>
            </div>
            <div className="ct-col ct-hf ct-vsm-font">
              <div>Session - SCH</div>
              <div>1on1 | GROUP</div>
            </div>
            <div className="ct-col ct-qf ct-sm-font">Credits</div>
            <div className="ct-col ct-qf ct-sm-font">Info</div>
            <div className="ct-col ct-qf ct-sm-font">Reply Sent</div>
            <div className="ct-col ct-qf ct-sm-font" />
          </div>

          {filter_students.map(this.render_single_student)}
        </div>
      </>
    );
  };

  close_history = () => this.setState({ history_open: false, open_info: false, student_info: {} });

  open_sms_window = (student) => {
    this.setState({
      sms_window_open: true,
      sms_student: student.uuid,
      sms_student_name: student.name,
      original_message_uuid: student.sms_uuid
    });
  };

  on_reply_sent = () => {
    const { students, send_sms_uuid, sms_student } = this.state;
    const new_students_data = [...students];
    const student = new_students_data.find((s) => s.uuid === sms_student);
    if (student) {
      student.to_student = 1;
      this.setState({ students: new_students_data });
    }
  };

  close_sms_window = () => this.setState({ sms_window_open: false, sms_student: '' });

  render_info = () => {
    const { student_info } = this.state;
    return (
      <div>
        <p>Name - {student_info.name}</p>
        <p>Affiliate - {student_info.affiliate}</p>
        <p>Queue Table Affiliate - {student_info.queue_affiliate}</p>
        <p>
          Trial End -{' '}
          {moment(student_info.trial_end_date).tz(this.props.iana_timezone).format(day_format)}{' '}
          {moment(student_info.trial_end_date).tz(this.props.iana_timezone).format(time_format)}
        </p>
      </div>
    );
  };

  render() {
    const {
      loading,
      history_open,
      history,
      open_info,
      sms_window_open,
      sms_student,
      sms_student_name,
      show_concierge_detail,
      is_back_pain_client,
      original_message_uuid,
      show_teacher_popup
    } = this.state;
    return (
      <CommonHeader
        loading={loading}
        title={`Concierge Users: ${is_back_pain_client === 0 ? 'MyYogaTeacher' : 'Lifetree Health'}`}
      >
        <CustomModal show={history_open} close={this.close_history}>
          <div>{history}</div>
        </CustomModal>
        <CustomModal show={open_info} close={this.close_history}>
          {this.render_info()}
        </CustomModal>
        <div style={{ minHeight: '400px' }}>
          {this.render_filters()}
          {is_back_pain_client === 0 ? this.render_data() : this.render_backpain_data()}
        </div>
        {sms_window_open ? (
          <SmsWindow
            student_uuid={sms_student}
            student_name={sms_student_name}
            original_message_uuid={original_message_uuid}
            close_fn={this.close_sms_window}
            send_success_notification={this.on_reply_sent}
          />
        ) : null}
        <CustomModal show={show_concierge_detail} close={this.close_concierge_detail}>
          {this.concierge_profile()}
        </CustomModal>
        <CustomModal show={show_teacher_popup} isFullWidth close={this.close_teacher_popup}>
          {this.change_teacher()}
        </CustomModal>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUsers);
