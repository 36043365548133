import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import CustomInput from '../../util_components/custom_input';
import CustomModal from '../../util_components/custom_modal';
import HighlightSearch from '../../util_components/highlight_search_text';
import CustomSingleSelect from '../../util_components/custom_single_select';
import { api_with_method } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

const profanity_options = [
  { label: 'positive', value: 'positive' },
  { label: 'negative', value: 'negative' },
  { label: 'profane', value: 'profane' }
];

class ProfanityList extends Component {
  state = {
    loading: false,
    profanity_list: [],
    word_search: '',
    add_word_open: false,
    delete_word_open: false,
    delete_id: null,
    new_word: '',
    new_word_type: 'profane'
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('/cms/api/v2/innocence/words', false, true);
      api_with_method('get', url, null, true)
        .then((response) => {
          this.setState({ profanity_list: [...response.data.items], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_profanity = (p) => {
    const { word_search } = this.state;
    return (
      <div className="ct-row" key={p.id}>
        <div className="ct-col">
          <HighlightSearch full_text={p.word} search_text={word_search} />
        </div>
        <div className="ct-col">{p.type}</div>
        <div
          className="ct-col"
          onClick={() => this.setState({ delete_id: p.id, delete_word_open: true })}
        >
          <button>Delete</button>
        </div>
      </div>
    );
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  open_add_word = () => this.setState({ add_word_open: true });

  close_modal = () =>
    this.setState({
      add_word_open: false,
      new_word: '',
      new_word_type: 'profane',
      delete_word_open: false,
      delete_id: null
    });

  add_new_word = () =>
    this.setState({ loading: true }, () => {
      const { new_word, new_word_type } = this.state;
      const url = get_api_url('/cms/api/v2/innocence/words', null, true);
      const payload = {
        word_type: new_word_type,
        word: new_word
      };
      api_with_method('post', url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
          this.props.set_notification_variable(true, 'success', 'Word added');
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_add_word = () => {
    const { new_word, new_word_type } = this.state;
    return (
      <div>
        <h4>Add a word to profanity list</h4>
        <CustomInput
          label="Enter Word"
          style={{ width: '300px' }}
          value={new_word}
          name="new_word"
          onChange={this.onCommonChange}
        />
        <CustomSingleSelect
          label="Select type"
          options={profanity_options}
          onChange={this.onCommonChange}
          name="new_word_type"
          value={new_word_type}
          style={{ width: '250px' }}
        />
        <button onClick={this.add_new_word}>Submit</button>
        <button onClick={this.close_modal}>Cancel</button>
      </div>
    );
  };

  sort_list = (sort_type) => {
    const { profanity_list } = this.state;
    const sam = profanity_list[0];
    console.log(sam[sort_type]);
    const final_list = profanity_list.sort((a, b) => a[sort_type].localeCompare(b[sort_type]));
    console.log('inside sort list');
    this.setState({ profanity_list: [...final_list] });
  };

  render_words = () => {
    const { profanity_list, word_search } = this.state;
    const filtered_list = profanity_list.filter(
      (k) => k.word.toLowerCase().indexOf(word_search.toLowerCase()) !== -1
    );
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomInput
            label="Search Word"
            style={{ width: '500px' }}
            value={word_search}
            name="word_search"
            onChange={this.onCommonChange}
          />
          <button onClick={this.open_add_word}>Add Word</button>
        </div>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div
              className="ct-col"
              style={{ cursor: 'pointer' }}
              onClick={() => this.sort_list('word')}
            >
              Word
            </div>
            <div
              className="ct-col"
              style={{ cursor: 'pointer' }}
              onClick={() => this.sort_list('type')}
            >
              Type
            </div>
            <div className="ct-col" />
          </div>
          {filtered_list.map(this.render_single_profanity)}
        </div>
      </div>
    );
  };

  delete_word = () =>
    this.setState({ loading: true }, () => {
      const { delete_id } = this.state;
      const url = get_api_url('/cms/api/v2/innocence/words', null, true);
      const payload = {
        id: delete_id
      };
      api_with_method('delete', url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
          this.props.set_notification_variable(true, 'success', 'Word deleted');
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_delete_word = () => {
    const { delete_id, profanity_list } = this.state;
    if (delete_id) {
      const selected_word = profanity_list.find((p) => p.id === delete_id);
      console.log(selected_word);
      return (
        <div>
          <h4>Are you sure you want to delete {selected_word.word}</h4>
          <button onClick={this.delete_word}>Delete</button>
          <button onClick={this.close_modal}>Cancel</button>
        </div>
      );
    }
    return null;
  };

  render() {
    const { loading, add_word_open, delete_word_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Profanity List">
        <CustomModal show={add_word_open} close={this.close_modal}>
          {this.render_add_word()}
        </CustomModal>
        <CustomModal show={delete_word_open} close={this.close_modal}>
          {this.render_delete_word()}
        </CustomModal>
        {this.render_words()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfanityList);
