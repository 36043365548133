import React, { Component } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../../util_components/custom_loading';
import { weekdays } from '../../../../constants';
import { getCookie } from '../../../../util_functions';
import './avail.css';
import 'react-datepicker/dist/react-datepicker.css';
import { get_api_url } from '../../../../utils/urls';

class Availability extends Component {
  state = {
    uuid: '',
    time_array: [...Array(48).keys()],
    sum_arr: Array(48).fill(0),
    availability_array: [],
    loading: true,
    start_date: moment().toDate(),
    end_date: moment().add(7, 'd').toDate()
  };

  componentDidMount() {
    this.load_teacher_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.setState({ loading: true }, () => {
        this.load_teacher_data();
      });
    }

    if (prevProps.uuid !== this.props.uuid) {
      this.setState({ loading: true }, () => {
        this.load_teacher_data();
      });
    }
  }

  load_teacher_data = () => {
    const { uuid } = this.props;
    const url = get_api_url('teacher_availability');
    const start_date = `${moment(this.state.start_date).format('YYYY-MM-DD')} 00:00:00`;
    const end_date = `${moment(this.state.end_date).format('YYYY-MM-DD')} 23:30:00`;
    const data = {
      teacher_uuid: uuid,
      start_date,
      end_date,
      iana_timezone: this.props.iana_timezone
    };
    this.setState({ loading: true });
    const headers = {
      Authorization: `Bearer ${getCookie('cms_token_3')}`
    };
    axios({
      method: 'post',
      url,
      headers,
      data
    })
      .then((response) => {
        const avail_array = response.data.availability;
        const sum_for_each = Array(48).fill(0);
        const { length } = avail_array;
        for (let i = 0; i < length; i++) {
          let arr = avail_array[i].time_slots.map((item) => item.available);
          if (i === 0) {
            arr = Array(48 - arr.length)
              .fill(0)
              .concat(arr);
          }
          if (i === length - 1) {
            arr = arr.concat(Array(48 - arr.length).fill(0));
          }
          for (let j = 0; j < sum_for_each.length; ++j) sum_for_each[j] = sum_for_each[j] + arr[j];
        }
        this.setState({
          availability_array: [...response.data.availability],
          loading: false,
          sum_arr: [...sum_for_each]
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({ redirectLogin: true });
        }
      });
  };

  render_table_header = () => (
    <tr>
      <th className="single-table-header">Day</th>
      <th className="single-table-header">Date</th>
      {this.state.time_array.map((item, index) => (
        <th className="single-table-header" key={item + index}>
          {item % 2 === 0 ? item / 2 : ''}
        </th>
      ))}
      <th className="single-table-header">Total hours</th>
    </tr>
  );

  render_blank_td = (availabality) => {
    const temp_length = 48 - availabality.time_slots.length;
    const temp_array = [...Array(temp_length).keys()];
    return (
      <>
        {temp_array.map((item, index) => (
          <td style={{ border: 'none' }} key={item + index} />
        ))}
      </>
    );
  };

  open_in_new_tab = (session) => {
    if (session.session_url) {
      document.getElementById('dummy-link').href = session.session_url;
      document.getElementById('dummy-link').click();
    }
  };

  get_slot_classname = (slot) => {
    let class_name = 'single-table-column ';
    if (slot.is_booked && slot.available)
      class_name = 'single-table-column single-available-booked-color ';
    else if (slot.is_booked && slot.session.is_repeat_session)
      class_name = 'single-table-column single-non-available-repeat-booked-color ';
    else if (slot.is_booked) class_name = 'single-table-column single-non-available-booked-color ';
    else if (slot.available) class_name = 'single-table-column single-available-bg-color';
    return class_name;
  };

  render_table_rows = () => (
    <>
      {this.state.availability_array.map((availabality, index) => {
        let total = 0;
        return (
          <tr key={index + availabality.date}>
            <td className="single-table-column day-format">
              {weekdays[moment(availabality.date, 'YYYY-MM-DD').day()]}
            </td>
            <td className="single-table-column day-format">{availabality.date}</td>
            {index === 0 ? this.render_blank_td(availabality) : null}
            {availabality.time_slots.map((slot, index) => {
              total += slot.available;
              return (
                <td
                  className={this.get_slot_classname(slot)}
                  key={index}
                  onClick={() => this.open_in_new_tab(slot.session)}
                >
                  {slot.is_booked === 1 ? (
                    <div className="tooltiptexts">Session with - {slot.student.name}</div>
                  ) : null}
                  {slot.trial_only_availability === 1 ? (
                    <span className="single-table-trial-availability" />
                  ) : null}
                </td>
              );
            })}
            {index === this.state.availability_array.length - 1
              ? this.render_blank_td(availabality)
              : null}
            <td className="single-table-column total-table-column">
              <span className="color-blue">{total / 2}</span>
            </td>
          </tr>
        );
      })}
      <tr>
        <td className="single-table-column" />
        <td className="single-table-column total-heading">Total hours</td>
        {this.state.sum_arr.map((val, index) => (
          <td className="single-table-column total-table-column" key={`${index}-${val}`}>
            {' '}
            <span className="color-blue">{val !== 0 ? val / 2 : null}</span>
          </td>
        ))}
        <td className="single-table-column total-table-column">
          <span className="color-blue">{this.state.sum_arr.reduce((a, b) => a + b) / 2}</span>
        </td>
      </tr>
    </>
  );

  render_data = () => {
    if (this.state.loading) {
      return (
        <div style={{ padding: '20px' }}>
          <CustomLoading />
        </div>
      );
    }
    if (this.state.availability_array.length === 0) {
      return (
        <div className="teacher-avilability">
          <h4>Teachers Availability</h4>
          <p>NO DATA AVAILABLE</p>
        </div>
      );
    }
    return (
      <div className="availability-table">
        <a id="dummy-link" target="_blank" rel="noopener noreferrer" style={{ display: 'none' }} />
        <table>
          <tbody>
            {this.render_table_header()}
            {this.render_table_rows()}
          </tbody>
        </table>
      </div>
    );
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  on_submit = () => {
    this.load_teacher_data();
  };

  render_filters = () => (
    <div>
      <h4>Filters:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button
            className="teacher-availability-submit items-center flex"
            onClick={this.on_submit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );

  render_legend = () => (
    <div>
      <h4>Legend:</h4>
      <div className="teacher-availability-legend-box">
        <div className="legend">
          <div className="legend-color" style={{ backgroundColor: 'rgba(0, 0, 255, 0.75)' }} />
          <div className="legend-text">Available</div>
        </div>
        <div className="legend">
          <div className="legend-color" style={{ backgroundColor: 'rgba(255, 0, 0, 0.75)' }} />
          <div className="legend-text">Booked</div>
        </div>
        <div className="legend">
          <div className="legend-color" style={{ backgroundColor: '#F4D03F' }} />
          <div className="legend-text">Booked outside availabality</div>
        </div>
        <div className="legend">
          <div className="legend-color" style={{ backgroundColor: '#7F8C8D' }} />
          <div className="legend-text">Repeat Booked outside availabality</div>
        </div>
        <div className="legend">
          <div className="legend-color" style={{ backgroundColor: '#0a9aad' }} />
          <div className="legend-text">Trial Only Availability</div>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Single Teacher Availability</title>
        </Helmet>
        <div className="common-page-header">
          {this.props?.hide_back_button ? null : (
            <div className="profile-go-back" onClick={this.props.close_availability_graph}>
              <img
                src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
                alt="back"
              />
              <span>Back</span>
            </div>
          )}
          <div className="common-page-title">
            Teachers Availability - {this.props.name}
            <p style={{ margin: 0, marginLeft: '10px' }}>({this.props.uuid})</p>
          </div>
        </div>
        {this.render_filters()}
        {this.render_legend()}
        {this.render_data()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		get_all_students: () => {
// 			dispatch(actions.get_all_students());
// 		},
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Availability);
