import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Login from './components/login';
import Home from './components/home';
import Test from './components/test';

import Notifications from './components/views/notifications';
import StudentChat from './components/chat/home/student';
import TeacherChat from './components/chat/home/teacher';
import Blog from './components/blog';
import BlogPost from './components/blog/post';
import './common.css';

import 'react-tooltip/dist/react-tooltip.css';

export default class App extends Component {
  render() {
    return (
      <>
        <ReactTooltip id="my-tooltip" style={{ overflow: 'visible', zIndex: '100' }} />
        <Notifications />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/test" component={Test} />

            <Route exact path="/student_chat" component={StudentChat} role="student" />
            <Route exact path="/teacher_chat" component={TeacherChat} role="teacher" />

            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:slug" component={BlogPost} />

            <Redirect from="/login" to="/" />
            <Redirect from="/home" to="/" />
            <Route exact path="*" component={Home} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
