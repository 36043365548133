import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import 'react-quill/dist/quill.snow.css';

function GettingToKnowStudentBetter({ data }) {
  const { student_additional_info, student_uuid } = data;
  const [additionalInfo, setAdditionalInfo] = useState(student_additional_info);

  const handleChange = (value) => {
    setAdditionalInfo(value);
  };

  const updateAdditionalInfo = (info) => {
    const payload = {
      student_uuid,
      additional_info: info
    };
    const url = get_api_url('update_student_additional_info');
    post_api(url, payload, true).then((response) => {
      if (response?.data?.status === 'success') {
        window.location.reload();
      }
    });
  };

  const handleSave = () => {
    updateAdditionalInfo(additionalInfo);
  };

  const handleClear = () => {
    setAdditionalInfo('');
    updateAdditionalInfo('');
  };

  return (
    <div>
      <ReactQuill theme="snow" value={additionalInfo} onChange={handleChange} />
      <div className="w-full flex align-center justify-end mt-4">
        <button onClick={handleSave}>Save</button>
        <button onClick={handleClear}>Clear</button>
      </div>
    </div>
  );
}

export default GettingToKnowStudentBetter;
