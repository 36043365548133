import { all, put, takeLatest, call } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api, get_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* getAllWorkshops(action) {
  try {
    yield put(actions.set_loading_variable('worshops_status', 'loading'));
    const url = get_api_url('list_all_workshops');
    const response = yield call(post_api, url, null, true);
    const workshops = [...response.data.workshops];
    for (const workshop of workshops) {
      workshop.is_past_workshop = 0;
      if (moment(workshop.end_date) < moment()) {
        workshop.is_past_workshop = 1;
      }
    }
    yield put(actions.set_workshops_variable('workshops', workshops));
    yield put(actions.set_loading_variable('worshops_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('worshops_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export default function* chatSaga() {
  yield all([takeLatest(t.get_all_workshops, getAllWorkshops)]);
}
