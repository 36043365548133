import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { isMobile, isTablet } from 'react-device-detect';
import CommonHeader from '../../../util_components/common_header';
import Button from '../../../util_components/button';
import {
  time_day_format,
  session_duration_list,
  group_session_private_values,
  target_audience_names,
  group_types,
  boolean_values
} from '../../../../constants';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomInput from '../../../util_components/custom_input';
import MultiCheckBox from '../../../util_components/multi_check_box';
import { post_api } from '../../../../redux/api_funcs';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

import './cgs.css';
import CustomCheckbox from '../../../util_components/custom_checkbox';

class CreateGroupSessions extends Component {
  state = {
    loading: true,
    teachers: [],
    page_teachers: [],
    teacher_selected: '',
    start_date_list: [],
    goals: [],
    start_date: '',
    session_title: '',
    session_desc: '',
    session_other_title: '',
    session_duration: '60',
    target_audience: 'Beginner',
    is_premium_group_session: '0',
    is_private_group_session: '0',
    session_max_non_member_participants: 0,
    time_error: false,
    enable_add_button: false,
    epoch_selected: 0,
    featured_in_recommended: 0,
    from_page: false,
    page_title: '',
    page_details: {},
    all_goals: [],
    bootcamp_workshop_names: [],
    bootcamp_workshop_uuid: '',
    is_repeat_session: 0,
    is_new_session: 0
  };

  componentDidMount() {
    try {
      const loc_state = this.props.location.state;
      this.setState({
        from_page: loc_state.from_page,
        page_title: loc_state.slug,
        page_details: loc_state.page_details,
        teachers: loc_state.teachers,
        // session_title: !!loc_state.name ? loc_state.name : '',
        // session_desc: !!loc_state.description ? loc_state.description : '',
        goals: loc_state.page_details.goals,
        target_audience: loc_state.page_details.yoga_level,
        session_title: loc_state.page_details.session_title
      });
    } catch (e) {
      if (this.props.teachers_list_status === 'success') {
        this.set_teachers_list();
      }
    }
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (this.props.class_types_status === 'none') {
      this.props.get_class_types();
    }
    if (this.props.worshops_status === 'none') this.props.get_all_workshops();
    if (this.props.worshops_status === 'success') this.set_bootcamp_workshops();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      if (!this.state.from_page) {
        this.set_teachers_list();
      }
    }
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (prevProps.worshops_status === 'loading' && this.props.worshops_status === 'success') {
      this.set_bootcamp_workshops();
    }
  }

  set_bootcamp_workshops = () => {
    const { workshops } = this.props;
    const bootcamp_workshops = workshops.filter((w) => w.audience_type == 4);
    const bootcamp_workshop_names = bootcamp_workshops.map((w) => ({
      value: w.uuid,
      label: `${w.name}(${w.start_date})`
    }));
    bootcamp_workshop_names.push({ value: '', label: 'NONE' });
    this.setState({ bootcamp_workshop_names });
  };

  set_session_names = () => {
    const session_max_non_member_participants =
      this.props.config_values.max_non_member_participants_for_group_classes;
    const group_session_values = this.props.class_types;
    const group_session_names = [];
    const group_session_descriptions = [];
    for (const sess of group_session_values) {
      group_session_names.push({ value: sess.uuid, label: sess.name });
      group_session_descriptions.push({ value: sess.uuid, description: sess.description });
    }
    const grp_sess_name_filters = [...group_session_names];
    group_session_names.sort((a, b) => a.label.localeCompare(b.label));
    grp_sess_name_filters.splice(0, 0, { value: 'all', label: 'All' });
    const all_goals = [...this.props.config_values.yoga_goals];
    let from_page;
    let page_details;
    let session_title = '';
    let session_desc = '';
    try {
      const loc_state = this.props.location.state;
      from_page = loc_state.from_page;
      page_details = loc_state.page_details;
      const class_type_index = group_session_values.findIndex(
        (t) => t.uuid === page_details.class_type_uuid
      );
      if (class_type_index !== -1) {
        const class_type = group_session_values[class_type_index];
        session_title = class_type.uuid;
        session_desc = class_type.description;
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({
      all_goals,
      group_session_names,
      group_session_descriptions,
      grp_sess_name_filters,
      session_max_non_member_participants,
      session_title,
      session_desc,
      loading: false
    });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter(
      (t) => t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY'
    );
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    const grp_sess_teach_filters = [...teachers_list];
    grp_sess_teach_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ teachers: [...teachers_list], loading: false, grp_sess_teach_filters });
  };

  set_start_date = (date) => {
    const { teacher_selected, session_duration, is_repeat_session } = this.state;
    if (teacher_selected) {
      this.setState({ loading: true }, () => {
        const sel_time = moment(date).format('YYYY-MM-DD HH:mm:ss');
        const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
        const payload = {
          teacher_uuid: teacher_selected,
          start_time: epoch_time,
          duration: parseInt(session_duration),
          is_repeat: is_repeat_session
        };
        const url = get_api_url('check_session_present_for_this_time_v1');
        this.setState({ start_date: date, session_create_err: false, time_error: false });
        post_api(url, payload, true)
          .then(() => {
            // let times_list = [...this.state.start_date_list];
            // times_list.push(epoch_time)
            this.setState({ loading: false, enable_add_button: true, epoch_selected: epoch_time });
          })
          .catch(() => {
            this.props.set_notification_variable(
              true,
              'error',
              'Teacher has a session at this time slot',
              2000
            );
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ time_error: true });
    }
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    const { class_types } = this.props;
    this.setState({ [key]: value, session_create_err: false, time_error: false }, () => {
      if (key === 'session_title') {
        const index = class_types.findIndex((gs) => value === gs.uuid);
        const new_class = class_types[index];
        if (index !== -1) {
          this.setState({ session_desc: new_class.description, goals: new_class.goals });
        } else {
          this.setState({ session_desc: '', goals: [] });
        }
      } else if (key === 'teacher_selected') {
        this.setState({ start_date_list: [] });
      }
    });
  };

  create_sessions = () => {
    const {
      teacher_selected,
      session_title,
      session_desc,
      session_duration,
      session_other_title,
      is_private_group_session,
      target_audience,
      is_premium_group_session,
      start_date_list,
      session_max_non_member_participants,
      from_page,
      page_details,
      goals,
      featured_in_recommended,
      bootcamp_workshop_uuid
    } = this.state;
    if (
      !!teacher_selected &&
      start_date_list.length > 0 &&
      !!session_title &&
      !!session_desc &&
      (session_title !== 'other' || !!session_other_title)
    ) {
      const class_type = this.props.class_types.find((c) => c.uuid === session_title);
      const paylaod = {
        teacher_uuid: teacher_selected,
        start_date_list,
        description: session_desc,
        duration: session_duration,
        is_private_group_session: parseInt(is_private_group_session),
        target_audience,
        featured_in_recommended,
        is_premium_group_session: parseInt(is_premium_group_session),
        goals,
        class_type_uuid: class_type.uuid,
        name: class_type.name,
        bootcamp_workshop_uuid,
        is_exclusive_for_backpain_client:
          this.props?.location?.state?.page_details?.is_exclusive_for_backpain_client,
        device_type: isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop',
        app_platform: 'web_app',
        app_major_version: 'v1',
        app_minor_version: 'v2'
      };
      if (session_max_non_member_participants) {
        paylaod.max_non_member_participants = session_max_non_member_participants;
      }
      if (from_page) {
        paylaod.class_page_uuid = page_details.uuid;
        paylaod.class_type_uuid = page_details.class_type_uuid;
        paylaod.is_repeat = this.state.is_repeat_session;
        paylaod.is_new = this.state.is_new_session;
      }
      const url = get_api_url('create_bulk_group_sessions_v1');
      this.setState({ session_create_loading: true }, () => {
        post_api(url, paylaod, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Group sessions created');
            this.setState(
              {
                session_create_loading: false,
                teacher_selected: '',
                start_date: '',
                start_date_list: [],
                create_session_open: false
              },
              () => {
                this.props.history.goBack();
              }
            );
            this.props.get_future_group_sessions();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ session_create_loading: false });
          });
      });
    } else {
      this.setState({ session_create_err: true });
    }
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  save_goals = (goals) => this.setState({ goals });

  render_create_session = () => {
    const {
      group_session_names,
      session_create_err,
      teachers,
      from_page,
      all_goals,
      goals,
      bootcamp_workshop_names,
      bootcamp_workshop_uuid,
      is_repeat_session,
      is_new_session
    } = this.state;
    const err_sty = session_create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const { state } = this.props?.location;
    return (
      <div>
        <h2>Create group session</h2>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select a teacher"
            options={teachers}
            name="teacher_selected"
            onChange={this.onCommonChange}
            value={this.state.teacher_selected}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session duration"
            options={session_duration_list}
            onChange={this.onCommonChange}
            name="session_duration"
            value={this.state.session_duration}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          {!state?.from_page && (
            <CustomSingleSelect
              label="Select session private of public"
              options={group_session_private_values}
              onChange={this.onCommonChange}
              name="is_private_group_session"
              value={this.state.is_private_group_session}
              style={{ width: '250px' }}
            />
          )}
          {!state?.from_page && (
            <CustomSingleSelect
              label="Target audience"
              options={target_audience_names}
              onChange={this.onCommonChange}
              name="target_audience"
              value={this.state.target_audience}
              style={{ width: '250px' }}
            />
          )}
        </div>
        <div className="display-flex-between">
          {!state?.from_page && (
            <CustomSingleSelect
              label="Select session type"
              options={group_types}
              onChange={this.onCommonChange}
              name="is_premium_group_session"
              value={this.state.is_premium_group_session}
              style={{ width: '250px' }}
            />
          )}
          {/* {from_page ? 
                        <CustomInput
                            label="Select session title"
                            onChange={this.onCommonChange}
                            name="session_title"
                            value={this.state.session_title}
                            disabled={true}
                            style={{ width: '250px' }}
                        /> : 
                        <CustomSingleSelect
                            label="Select session title"
                            options={group_session_names}
                            onChange={this.onCommonChange}
                            name="session_title"
                            value={this.state.session_title}
                            style={{ width: '250px' }}
                        />
                    } */}
          {!state?.from_page && (
            <CustomSingleSelect
              label="Select session title"
              options={group_session_names}
              onChange={this.onCommonChange}
              name="session_title"
              value={this.state.session_title}
              style={{ width: '250px' }}
            />
          )}
        </div>
        <div className="display-flex-between">
          {!state?.from_page && (
            <CustomInput
              label="Enter Max Non Member Participants"
              onChange={this.on_number_change}
              name="session_max_non_member_participants"
              type="number"
              value={this.state.session_max_non_member_participants}
              style={{ width: '200px' }}
            />
          )}
          {!state?.from_page && (
            <CustomSingleSelect
              label="Feature in recommended"
              options={boolean_values}
              onChange={this.onCommonChange}
              name="featured_in_recommended"
              value={this.state.featured_in_recommended}
              style={{ width: '250px' }}
            />
          )}
        </div>
        {this.state.session_title === 'other' ? (
          <CustomInput
            label="Enter session title"
            onChange={this.onCommonChange}
            name="session_other_title"
            value={this.state.session_other_title}
            style={{ width: '300px' }}
          />
        ) : null}
        {!state?.from_page && (
          <CustomSingleSelect
            label="Select Bootcamp Workshop"
            options={bootcamp_workshop_names}
            onChange={this.onCommonChange}
            name="bootcamp_workshop_uuid"
            value={bootcamp_workshop_uuid}
            style={{ width: '250px' }}
          />
        )}
        {!state?.from_page && all_goals.length > 0 ? (
          <MultiCheckBox
            title="Select goals: "
            all_values={all_goals}
            curr_values={goals}
            save_values={this.save_goals}
          />
        ) : null}
        {!state?.from_page && (
          <>
            <div>Enter session description:</div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={this.state.session_desc}
              name="session_desc"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
            <p style={{ margin: '0', fontSize: '12px' }}>
              you have {2000 - this.state.session_desc.length} characters left
            </p>
          </>
        )}
        <p style={err_sty}>All fields are compulsory</p>
        {!this.props?.location?.state?.is_from_bulk_session && (
          <CustomCheckbox
            label="Repeat Session"
            checked={is_repeat_session}
            onClick={() => this.toggle_check_box('is_repeat_session')}
          />
        )}
        <CustomCheckbox
          label="New Session"
          checked={is_new_session}
          onClick={() => this.toggle_check_box('is_new_session')}
        />

        <Button onClick={this.create_sessions} disabled={this.state.session_create_loading}>
          Submit
        </Button>
      </div>
    );
  };

  toggle_check_box = (key) => {
    this.setState({ [key]: this.state[key] === 0 ? 1 : 0 });
  };

  delete_row = (index) => {
    const times_list = this.state.start_date_list;
    const updated_list = [...times_list.slice(0, index), ...times_list.slice(index + 1)];
    this.setState({ start_date_list: updated_list });
  };

  render_single_date = (date, index) => (
    <div key={date} className="cgs-single-date">
      {moment(date).tz(this.props.iana_timezone).format(time_day_format)}
      <img
        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
        alt="nav"
        onClick={() => this.delete_row(index)}
      />
    </div>
  );

  add_time = () => {
    const { epoch_selected } = this.state;
    const times_list = [...this.state.start_date_list];
    times_list.push(epoch_selected);
    this.setState({
      epoch_selected: 0,
      start_date_list: times_list,
      enable_add_button: false,
      start_date: ''
    });
  };

  render_add_times = () => {
    const { time_error, start_date, start_date_list, enable_add_button } = this.state;
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    const error_class = time_error ? 'cgs-time-error' : 'cgs-time-error cgs-time-error-none';
    return (
      <div className="cgs-time-container">
        <div>{`Select time (${curr_timezone})`}</div>
        <div>
          <DatePicker
            selected={start_date}
            onChange={(date) => this.set_start_date(date)}
            minDate={new Date()}
            maxDate={moment(new Date()).add(90, 'days').toDate()}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
        <p className={error_class}>*Please select teacher first</p>
        <Button disabled={!enable_add_button} onClick={this.add_time}>
          Add
        </Button>
        <div>Selected times list</div>
        <div>{start_date_list.map(this.render_single_date)}</div>
      </div>
    );
  };

  render_group_sessions = () => (
    <div className="cgs-container">
      <div className="cgs-create">{this.render_create_session()}</div>
      <div className="cgs-add-time">{this.render_add_times()}</div>
    </div>
  );

  render() {
    const { from_page, page_title, loading, session_create_loading } = this.state;
    const sub_title = from_page ? `(For page - ${page_title})` : '';
    return (
      <CommonHeader
        loading={loading || session_create_loading}
        title="Create Group Sessions"
        sub_title={sub_title}
        show_back
      >
        {this.render_group_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values,
  future_group_sessions_status: state.loading.future_group_sessions_status,
  future_group_sessions: state.sessions.future_group_sessions,
  future_group_sess_curr_page: state.sessions.future_group_sess_curr_page,
  future_group_sess_page_limit: state.sessions.future_group_sess_page_limit,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  worshops_status: state.loading.worshops_status,
  workshops: state.workshops.workshops
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes, dur) => {
    dispatch(actions.set_notification_variable(show, type, mes, dur));
  },
  get_future_group_sessions: (payload) => {
    dispatch(actions.get_future_group_sessions(payload));
  },
  update_future_group_sessions: (uuid, key, value) => {
    dispatch(actions.update_future_group_sessions(uuid, key, value));
  },
  get_class_types: () => dispatch(actions.get_class_types()),
  get_all_workshops: () => dispatch(actions.get_all_workshops())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupSessions);
