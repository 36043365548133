export const get_api_url = (link, ignoredemail, isGo, absoluteUrl) => {
  const dev_domain = 'https://gstg1.myyogateacher.com';
  const stage_domain = 'https://gstg1.myyogateacher.com';
  const prod_domain = 'https://gapi.myyogateacher.com';

  let url_domain = prod_domain;
  let mytChatUrl = '';
  let go_domain = '';

  switch (import.meta.env.VITE_APP_STAGE) {
    case 'development':
      url_domain = dev_domain;
      mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
      go_domain = stage_domain;
      break;
    case 'staging':
      url_domain = stage_domain;
      mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
      go_domain = stage_domain;
      break;
    case 'production':
      url_domain = prod_domain;
      mytChatUrl = 'samvaad.myyogateacher.com';
      go_domain = prod_domain;
      break;
    default:
      url_domain = dev_domain;
      mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
      go_domain = dev_domain;
      break;
  }

  if (link === 'mytChatUrl') {
    return mytChatUrl;
  }

  if (isGo) {
    return `${go_domain}${link}`;
  }

  if (absoluteUrl) {
    return `${url_domain}/${link}`;
  }

  return `${url_domain}/cms/api/${link}`;
};

export const get_domain = () => {
  const { host } = window.location;
  const subdomain = host.split('.')[0];
  const url_domain =
    subdomain === 'hridaya'
      ? 'https://myyogateacher.com'
      : 'https://student-testenv4.myyogateacher.com';

  return url_domain;
};
