import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';
import DatePicker from 'react-datepicker';
import CommonHeader from '../../util_components/common_header';
import CustomSingleTable from '../../util_components/custom_single_table';
import {
  image_error_replace,
  check_null_text,
  check_user_is_from_back_pain,
  getDayAndTime,
  isViewOnly
} from '../../../util_functions';
import CreateEditClassPage from './create_edit_class_page';
import CustomSingleSelect from '../../util_components/custom_single_select';
import {
  day_format,
  time_day_format,
  time_format,
  day_format_year,
  default_timezone
} from '../../../constants';
import CustomModal from '../../util_components/custom_modal';
import Button from '../../util_components/button';
import { class_page_format } from '../../../utils/formats';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import RenderAllTags from '../content/components/render_tags';
import CustomInput from '../../util_components/custom_input';

class ClassPageDetails extends Component {
  state = {
    initial_loading: true,
    loading: true,
    initial_slug: '',
    page_details: {},
    edit_open: false,
    sessions: [],
    add_classes_open: false,
    add_classes_future: false,
    remove_classes_open: false,
    remove_classes_future: false,
    null_classes: [],
    current_classes: [],
    search_text: '',
    add_teacher_to_group: false,
    confirm_add_teacher: false,
    add_teacher: 0,
    teacher_options: [],
    chat_teacher_uuid: '',
    association_type: 'GROUP_CLASS_PAGE',
    is_repeat_session: this.props?.location?.state?.is_repeat_session,
    repeat_session_list: [],
    show_add_gc_videos: false,
    group_video_data: [{ teacher_uuid: '', video_url: '' }],
    selected_teacher_for_gc: '',
    show_group_teacher_replacement: false,
    group_session_id: '',
    error_message: '',
    show_error: false,
    is_save_disable: true,
    selected_reason: '',
    show_repeat_modal: false,
    group_class_pages: [],
    selected_group_class_pages: [],
    additional_reason: '',
    show_time_change_modal: false,
    change_time: '',
    error_message_for_change_time: '',
    show_error_message_for_change_time: false,
    selected_day: 0,
    duration: 30,
    selected_teacher_name: '',
    selected_teacher_uuid: '',
    group_chat_channel_id: ''
  };

  componentDidMount() {
    try {
      const initial_slug = this.props.match.params.page_slug;
      if (initial_slug) {
        this.setState({ initial_slug }, () => {
          if (this.props.class_types_status === 'none') {
            this.props.get_class_types();
          }
          if (
            this.props.class_types_status === 'success' &&
            this.props.config_status === 'success'
          ) {
            this.setState({ initial_loading: false });
          }
          if (this.props.teachers_list_status === 'none') {
            this.props.get_teachers_list();
          }
          this.load_data();
        });
      } else this.go_to_path('/group_class_pages');
    } catch (e) {
      this.go_to_path('/group_class_pages');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.initial_loading &&
      this.props.class_types_status === 'success' &&
      this.props.config_status === 'success' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.setState({ initial_loading: false });
      this.set_teachers();
    }
    if (prevProps.match.params.page_slug !== this.props.match.params.page_slug) {
      this.setState({ loading: true, initial_slug: this.props.match.params.page_slug }, () =>
        this.load_data()
      );
    }
  }

  set_teachers = () => {
    const teachers = this.props.teachers_list.filter((t) => t.onboarding_status === 'APPROVED');
    const teacher_options = teachers.map((t) => ({
      label: `${t.first_name} ${t.last_name}`,
      value: t.uuid
    }));
    this.setState({ teacher_options });
  };

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_class_page_details_v1');
      const payload = { slug: this.state.initial_slug };
      post_api(url, payload, true)
        .then((resp) => {
          const data = resp?.data?.page?.teachers_videos?.map((item) => ({
            teacher_uuid: item.teacher_uuid,
            video_url: item.video_url,
            uuid: item.uuid
          }));
          const selected_easy_filters = resp?.data?.easy_filters;
          this.setState({
            easy_filters: selected_easy_filters,
            group_video_data: data,
            page_details: resp.data.page,
            loading: false,
            sessions: resp.data.sessions_list,
            repeat_session_list: resp.data.repeat_session_list
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.go_to_path('/group_class_pages');
        });
    });

  go_to_path = (path) => this.props.history.push(path);

  toggle_edit = () => this.setState({ edit_open: !this.state.edit_open });

  update_class_page = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_class_page');
      post_api(url, payload, true)
        .then(() => {
          if (this.state.initial_slug !== payload.slug) {
            this.toggle_edit();
            this.setState({ loading: false });
            const path = `/group_class_page/${payload.slug}`;
            this.go_to_path(path);
          } else {
            this.toggle_edit();
            this.load_data();
          }
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  edit_page = () => {
    const { edit_open, page_details, easy_filters } = this.state;
    if (edit_open) {
      return (
        <CreateEditClassPage
          page_details={page_details}
          submit_change={this.update_class_page}
          edit_class
          selected_easy_filters={easy_filters}
        />
      );
    }
    return null;
  };

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  toggle_group_class_details_video = () => {
    this.setState({ show_add_gc_videos: true });
  };

  close_group_class_details_video = () => {
    this.setState({
      show_add_gc_videos: false
    });
  };

  create_classes = () => {
    const { page_details } = this.state;
    const teachers = page_details.teachers_data ? page_details.teachers_data : [];
    const final_teachers = teachers.map((t) => ({ label: t.name, value: t.uuid }));
    this.props.history.push({
      pathname: '/creategroupsessions',
      state: {
        from_page: true,
        teachers: final_teachers,
        slug: page_details.slug,
        name: page_details.name,
        description: page_details.description,
        page_details
      }
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col ct-pl-10">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
              {session.teacher_name.split(' ')[0]}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format_year)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-sm-font">{session.group_session_name}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">{session.participants}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.members_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_members_participants_after_first_10_days)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_members_participants_within_first_10_days)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_member_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">{session.max_non_member_participants}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {session.is_live === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">
          <a
            className="link-btn"
            href={`/groupsessiondetails?session_uuid=${session.session_uuid}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Details
          </a>
          {/* <Button onClick={() => this.open_session_viewer(session.session_uuid)}>Details</Button> */}
        </div>
        {/* <div className="ct-col">
                    {session.is_premium_group_session === 1 ? 'Members only' : <Button onClick={() => this.open_confirm_premium(session.session_uuid)}>Promote</Button>}
                </div> */}
      </div>
    );
  };

  render_sessions_list = (sessions) => (
    <div className="cus-table" style={{ marginBottom: '20px' }}>
      <div className="ct-row ct-h">
        <div className="ct-col">Teacher</div>
        <div className="ct-col">
          <div>Start Time</div>
          <div className="ct-sm-font">(Dur)</div>
        </div>
        <div className="ct-col">Name</div>
        <div className="ct-col ct-3rdf ct-sm-font">
          Total <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          Members <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          NM <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          Trials <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          NM <span className="ct-vsm-font">Total Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">NM Limit</div>
        <div className="ct-col ct-3rdf ct-sm-font">Live</div>
        <div className="ct-col">Details</div>
      </div>
      {sessions.map(this.render_single_session)}
    </div>
  );

  render_sessions = () => {
    const { sessions } = this.state;
    const finished_sessions = sessions.filter((s) => s.session_status === 'FINISHED');
    const other_sessions = sessions.filter((s) => s.session_status !== 'FINISHED');
    if (sessions.length > 0) {
      return (
        <>
          {other_sessions.length > 0 ? (
            <>
              <p>Sessions:</p>
              {this.render_sessions_list(other_sessions)}
            </>
          ) : null}
          {finished_sessions.length > 0 ? (
            <>
              <p>Finished Sessions:</p>
              {this.render_sessions_list(finished_sessions)}
            </>
          ) : null}
        </>
      );
    }
    return null;
  };

  render_repeat_sesisons = () => {
    const { repeat_session_list } = this.state;
    return (
      <>
        <p>Repeat Sessions:</p>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h ">
            <div className="ct-col">Teacher</div>
            <div className="ct-col">
              <div>Start Time</div>
              <div className="ct-sm-font">(Dur)</div>
            </div>
            <div className="ct-col">Is Active</div>
            {!isViewOnly(this.props.access_type) ? (
              <>
                <div className="ct-col">
                  <div>Stop Scheduling and</div>
                  <div>Notify User</div>
                </div>
                <div className="ct-col">Replace Teacher all Future Classes</div>
                <div className="ct-col">Reschedule Repeat Classes</div>
              </>
            ) : null}
          </div>
          {repeat_session_list.map(this.render_repeat_single_session)}
        </div>
      </>
    );
  };

  render_repeat_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    const { pst_time_of_the_day, pst_day_of_the_week, time_of_the_day, day_of_the_week } = session;
    let final_day_of_the_week = session.day_of_the_week;
    let final_time_of_the_day = session.time_of_the_day;
    if (pst_time_of_the_day) {
      final_day_of_the_week = pst_day_of_the_week;
      final_time_of_the_day = pst_time_of_the_day;
    }
    const session_date = moment()
      .tz(default_timezone)
      .day(final_day_of_the_week)
      .format('YYYY-MM-DD');
    const session_time = moment
      .tz(`${session_date} ${final_time_of_the_day}`, default_timezone)
      .utc();

    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col ct-pl-10">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_profile_photo} onError={image_error_replace} alt="x" />
              {session.teacher_first_name?.split(' ')[0]}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <div>Every {session_time.tz(this.props.iana_timezone).format('dddd')}</div>
          <div>{session_time.tz(this.props.iana_timezone).format('hh:mm A z')}</div>
          <div>{`${session.duration || ''} Mins`}</div>
        </div>

        <div className="ct-col ct-sm-font">
          {session.is_active === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        {!isViewOnly(this.props.access_type) ? (
          <>
            <div className="ct-col">
              {session.is_active === 1 ? (
                <Button
                  onClick={() => this.notify_repeat_users_modal(session.original_group_session_id)}
                >
                  Stop
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div className="ct-col">
              <Button
                onClick={() => this.open_teacher_replacement(session.original_group_session_id)}
              >
                Replace
              </Button>
            </div>
            <div className="ct-col">
              <Button
                onClick={() =>
                  this.open_time_change(
                    session.original_group_session_id,
                    session?.epoch_start_time
                  )
                }
              >
                Reschedule
              </Button>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  notify_repeat_users_modal = (original_group_session_id) => {
    this.setState({ show_repeat_modal: true, original_group_session_id }, () => {
      this.load_all_class_pages();
    });
  };

  load_all_class_pages = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_class_pages');
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ group_class_pages: [...res.data.pages], loading: false });
        })
        .catch((e) => {
          console.log('error while getting group class pages', e);
          this.setState({ loading: false });
        });
    });

  close_notify_repeat_user_modal = () => {
    this.setState({ show_repeat_modal: false, original_group_session_id: '' });
  };

  onMultiSelect = (name) => {
    if (name.length > 5) return;
    this.setState(() => ({
      selected_group_class_pages: name
    }));
  };

  submit_stop_repeat = () => {
    const {
      selected_reason,
      selected_group_class_pages,
      original_group_session_id,
      additional_reason
    } = this.state;
    const class_page_uuids = selected_group_class_pages.map((item) => item.value);

    const payload = {
      original_session_id: original_group_session_id,
      reason: selected_reason,
      class_page_uuids,
      additional_reason
    };
    const url = get_api_url('notify_repeat_user_no_session');
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false, show_repeat_modal: false }, () => {
              this.load_data();
            });
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  };

  render_notify_repeat_user_modal = () => {
    const reasons = [
      { label: 'Low attendance', value: 'Low attendance' },
      { label: 'Teacher unavailability', value: 'Teacher unavailability' }
    ];

    const { selected_reason, additional_reason, group_class_pages, selected_group_class_pages } =
      this.state;
    const gc_page_list = group_class_pages.map((item) => ({
      label: item.name,
      value: item.uuid
    }));

    const btn_disabled = selected_reason === '' || selected_group_class_pages.length < 2;

    return (
      <>
        <div className="flex flex-col">
          <CustomSingleSelect
            label="Select Reason to stop repeat *"
            options={reasons}
            name="selected_reason"
            value={selected_reason}
            onChange={(e) => this.onCommonChange(e)}
          />
        </div>
        <div className="text-sm text-black">Comment (Not visible to user)</div>
        <div>
          <textarea
            value={additional_reason}
            name="additional_reason"
            onChange={this.onCommonChange}
            maxLength="2000"
          />
        </div>
        <div className="h-96 mt-4">
          <div className="text-sm text-black">Select 2-5 classes to recommend to the users *</div>
          <pre className="mt-4 flex flex-wrap  whitespace-normal">
            {selected_group_class_pages.map((option) => (
              <div className="cre-class-teac" key={option.value}>
                {option.label}
                <img
                  src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                  onClick={() => this.remove_gc_page(option.value)}
                  alt="x"
                />
              </div>
            ))}
          </pre>
          <MultiSelect
            options={gc_page_list}
            value={selected_group_class_pages}
            onChange={this.onMultiSelect}
            hasSelectAll={false}
          />
        </div>
        <div>
          <Button disabled={btn_disabled} onClick={this.submit_stop_repeat}>
            Stop Repeat
          </Button>
        </div>
      </>
    );
  };

  remove_gc_page = (removed_option) => {
    const { selected_group_class_pages } = this.state;
    const updated_option = selected_group_class_pages.filter(
      (option) => option.value !== removed_option
    );
    this.setState({ selected_group_class_pages: updated_option });
  };

  update_chat = () => {
    this.create_chat(1);
  };

  create_chat = (edit_chat = 0) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_chat_group_class_page');
      let success_msg = 'Chat created';
      const details = this.state.page_details;
      const payload = { class_page_uuid: details.uuid };
      if (edit_chat === 1) {
        success_msg = 'Chat updated';
        payload.update_chat = 1;
      }
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', success_msg);
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_details = () => {
    const { page_details, sessions, easy_filters } = this.state;
    const selected_easy_filter = easy_filters?.map((item) => item.name);
    const teachers = page_details.teachers_data ? page_details.teachers_data : [];
    const finished_sessions = sessions.filter((s) => s.session_status === 'FINISHED');
    const upcoming_sessions = sessions.filter((s) => s.session_status !== 'FINISHED');
    const new_data = this.state.page_details;
    new_data.easy_filters_list = selected_easy_filter;

    return (
      <>
        <CustomSingleTable data={new_data} data_format={class_page_format} />
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h ">
            <div className="ct-col">Who it’s for</div>
            <div className="ct-col">Who it’s not for</div>
            <div className="ct-col">How it will help you</div>
            <div className="ct-col">How it will not help you</div>
          </div>
          <div className="ct-row">
            <div className="ct-col" dangerouslySetInnerHTML={{ __html: page_details?.whos_for }} />
            <div
              className="ct-col"
              dangerouslySetInnerHTML={{ __html: page_details?.whos_not_for }}
            />
            <div
              className="ct-col"
              dangerouslySetInnerHTML={{ __html: page_details?.how_will_help }}
            />
            <div
              className="ct-col"
              dangerouslySetInnerHTML={{ __html: page_details?.how_will_not_help }}
            />
          </div>
        </div>
        {!isViewOnly(this.props.access_type) ? (
          <>
            <button onClick={this.toggle_edit}>Edit Details</button>
            <button onClick={this.create_classes}>Create Classes</button>
            <button onClick={this.open_future_classes}>Add Future Classes</button>
            <button onClick={this.open_past_classes}>Add Past Classes</button>
            <button onClick={this.toggle_group_class_details_video}>
              Add Group Class Details Video
            </button>
            {upcoming_sessions.length > 0 ? (
              <button onClick={this.remove_future_classes}>Remove Future Classes</button>
            ) : null}
            {finished_sessions.length > 0 ? (
              <button onClick={this.remove_past_classes}>Remove Past Classes</button>
            ) : null}
          </>
        ) : null}
        {/* {!page_details.group_chat_channel_id ?
                    <button onClick={this.create_chat} style={{ float: 'right' }}>Create group chat channel</button> :
                    <button onClick={this.update_chat} style={{ float: 'right' }}>Update group chat channel</button>} */}
        <p>Teachers:</p>
        <div className="mb-4">
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h ">
              <div className="ct-col">Teacher Name</div>
              <div className="ct-col">Add Teacher to chat</div>
              <div className="ct-col">Remove Teacher from chat</div>
            </div>
            {teachers.map((teac) => {
              const teacher_url = `/teacherprofile?id=${teac.uuid}`;
              return (
                <div className="ct-row" key={teac.uuid}>
                  <div className="ct-col">
                    <a className="link-no-dec" href={teacher_url}>
                      <div className="profile-img-name">
                        <img src={teac.profile_photo} alt="i" onError={image_error_replace} />
                        {teac.name}
                      </div>
                    </a>
                  </div>
                  {!isViewOnly(this.props.access_type) ? (
                    <>
                      <div className="ct-col">
                        <Button
                          onClick={() =>
                            this.setState({
                              add_teacher_to_group: true,
                              add_teacher: 1,
                              selected_teacher_name: teac.name,
                              selected_teacher_uuid: teac.uuid,
                              group_chat_channel_id: teac.group_chat_channel_id
                            })
                          }
                        >
                          Add Teacher
                        </Button>
                      </div>
                      <div className="ct-col">
                        <Button
                          onClick={() =>
                            this.setState({
                              add_teacher_to_group: true,
                              add_teacher: 0,
                              selected_teacher_name: teac.name,
                              selected_teacher_uuid: teac.uuid,
                              group_chat_channel_id: teac.group_chat_channel_id
                            })
                          }
                        >
                          Remove Teacher
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  render_add_remove_teacher_modal = () => {
    const {
      chat_teacher_uuid,
      teacher_options,
      add_teacher,
      confirm_add_teacher,
      selected_teacher_name,
      selected_teacher_uuid
    } = this.state;

    const remove_text =
      add_teacher === 1
        ? `add ${selected_teacher_name} to`
        : `remove ${selected_teacher_name} from`;
    return (
      <div>
        <h4>{`Are you sure you want to ${remove_text} to group?`}</h4>
        <button onClick={this.add_remove_teacher}>Submit</button>
        <button onClick={() => this.close_add_remove_group()}>Cancel</button>
      </div>
    );
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  search_similar_classes = () =>
    this.setState({ loading: true }, () => {
      const { add_classes_future } = this.state;
      const payload = {
        search_text: this.state.search_text,
        teachers: this.state.page_details.teachers,
        is_future: add_classes_future ? 1 : 0
      };
      const url = get_api_url('get_similar_classes');
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ null_classes: [...res.data.sessions], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.setState({ loading: false });
        });
    });

  stop_propagation = (e) => e.stopPropagation();

  change_to_current = (uuid) => {
    const { null_classes, current_classes } = this.state;
    const class_index = null_classes.findIndex((c) => c.uuid === uuid);
    const new_class = null_classes[class_index];
    const new_null_classes = [
      ...null_classes.slice(0, class_index),
      ...null_classes.slice(class_index + 1)
    ];
    const new_current_classes = [...current_classes, new_class];
    this.setState({ null_classes: new_null_classes, current_classes: new_current_classes });
  };

  change_to_null = (uuid) => {
    const { null_classes, current_classes } = this.state;
    const class_index = current_classes.findIndex((c) => c.uuid === uuid);
    const new_class = current_classes[class_index];
    const new_current_classes = [
      ...current_classes.slice(0, class_index),
      ...current_classes.slice(class_index + 1)
    ];
    const new_null_classes = [...null_classes, new_class];
    this.setState({ null_classes: new_null_classes, current_classes: new_current_classes });
  };

  submit_remove_classes = () =>
    this.setState({ loading: true }, () => {
      const { null_classes, page_details } = this.state;
      const payload = {
        session_uuids: null_classes.map((c) => c.uuid),
        class_page_uuid: page_details.uuid,
        remove_classes: 1
      };
      const url = get_api_url('update_similar_classes');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Classes removed');
          this.close_add_classes();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  submit_add_classes = () =>
    this.setState({ loading: true }, () => {
      const { current_classes, page_details } = this.state;
      const payload = {
        session_uuids: current_classes.map((c) => c.uuid),
        class_page_uuid: page_details.uuid
      };
      const url = get_api_url('update_similar_classes');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Classes added to page');
          this.close_add_classes();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  remove_classes = () => {
    const { null_classes, current_classes, remove_classes_future } = this.state;
    const title_text = remove_classes_future ? `Future Classes` : `Past Classes`;
    return (
      <div>
        <div style={{ textAlign: 'center' }}>Remove Classes</div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <p>{title_text}</p>
          <p>Current Page</p>
        </div>
        <div className="add-classes-open">
          <div className="add-classes-left">
            <div className="cus-table">
              {null_classes.map((cl) => {
                const teacher_url = `/teacherprofile?id=${cl.teacher_uuid}`;
                return (
                  <div
                    className="ct-row add-class-click"
                    key={cl.uuid}
                    onClick={() => this.change_to_current(cl.uuid)}
                  >
                    <div className="ct-col">{cl.teacher_name}</div>
                    <div className="ct-col ct-sm-font">
                      <div>
                        {moment(cl.start_time).tz(this.props.iana_timezone).format(day_format)}
                      </div>
                      <div>
                        {moment(cl.start_time).tz(this.props.iana_timezone).format(time_format)}
                      </div>
                    </div>
                    <div className="ct-col ct-sm-font">{cl.group_session_name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="add-classes-right">
            <div className="cus-table">
              {current_classes.map((cl) => (
                <div
                  className="ct-row add-class-click"
                  key={cl.uuid}
                  onClick={() => this.change_to_null(cl.uuid)}
                >
                  <div className="ct-col">{cl.teacher_name}</div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      {moment(cl.start_time).tz(this.props.iana_timezone).format(day_format)}
                    </div>
                    <div>
                      {moment(cl.start_time).tz(this.props.iana_timezone).format(time_format)}
                    </div>
                  </div>
                  <div className="ct-col ct-sm-font">{cl.group_session_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Button onClick={this.submit_remove_classes} disabled={null_classes.length === 0}>
          Submit
        </Button>
      </div>
    );
  };

  add_classes = () => {
    const { search_text, null_classes, current_classes, add_classes_future } = this.state;
    const title_text = add_classes_future ? `Future Classes` : `Past Classes`;
    return (
      <div>
        <div className="add-classes-search">
          <input
            name="search_text"
            value={search_text}
            onChange={this.onCommonChange}
            placeholder="Enter search text"
          />
          <Button onClick={this.search_similar_classes} disabled={!search_text}>
            Search
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <p>{title_text}</p>
          <p>Current Page</p>
        </div>
        <div className="add-classes-open">
          <div className="add-classes-left">
            <div className="cus-table">
              {null_classes.map((cl) => {
                const teacher_url = `/teacherprofile?id=${cl.teacher_uuid}`;
                return (
                  <div
                    className="ct-row add-class-click"
                    key={cl.uuid}
                    onClick={() => this.change_to_current(cl.uuid)}
                  >
                    <div className="ct-col">{cl.teacher_name}</div>
                    <div className="ct-col ct-sm-font">
                      <div>
                        {moment(cl.start_time).tz(this.props.iana_timezone).format(day_format)}
                      </div>
                      <div>
                        {moment(cl.start_time).tz(this.props.iana_timezone).format(time_format)}
                      </div>
                    </div>
                    <div className="ct-col ct-sm-font">{cl.group_session_name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="add-classes-right">
            <div className="cus-table">
              {current_classes.map((cl) => (
                <div
                  className="ct-row add-class-click"
                  key={cl.uuid}
                  onClick={() => this.change_to_null(cl.uuid)}
                >
                  <div className="ct-col">{cl.teacher_name}</div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      {moment(cl.start_time).tz(this.props.iana_timezone).format(day_format)}
                    </div>
                    <div>
                      {moment(cl.start_time).tz(this.props.iana_timezone).format(time_format)}
                    </div>
                  </div>
                  <div className="ct-col ct-sm-font">{cl.group_session_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Button onClick={this.submit_add_classes} disabled={current_classes.length === 0}>
          Submit
        </Button>
      </div>
    );
  };

  close_add_classes = () =>
    this.setState({
      add_classes_open: false,
      add_classes_future: false,
      remove_classes_open: false,
      remove_classes_future: false,
      null_classes: [],
      search_text: '',
      current_classes: []
    });

  open_future_classes = () => this.setState({ add_classes_open: true, add_classes_future: true });

  open_past_classes = () => this.setState({ add_classes_open: true });

  remove_future_classes = () => {
    const { sessions } = this.state;
    const upcoming_sessions = sessions.filter((s) => s.session_status !== 'FINISHED');
    const current_classes = upcoming_sessions.map((s) => ({
      uuid: s.session_uuid,
      teacher_uuid: s.teacher_uuid,
      teacher_name: s.teacher_name,
      group_session_name: s.group_session_name,
      start_time: s.epoch_start_time
    }));
    this.setState({ current_classes, remove_classes_open: true });
  };

  remove_past_classes = () => {
    const { sessions } = this.state;
    const finished_sessions = sessions.filter((s) => s.session_status === 'FINISHED');
    const current_classes = finished_sessions.map((s) => ({
      uuid: s.session_uuid,
      teacher_uuid: s.teacher_uuid,
      teacher_name: s.teacher_name,
      group_session_name: s.group_session_name,
      start_time: s.epoch_start_time
    }));
    this.setState({ current_classes, remove_classes_open: true });
  };

  close_add_remove_group = () =>
    this.setState({
      add_teacher_to_group: false,
      confirm_add_teacher: false,
      chat_teacher_uuid: ''
    });

  add_remove_teacher = () =>
    this.setState({ loading: true }, () => {
      const { selected_teacher_uuid, add_teacher, group_chat_channel_id } = this.state;
      const payload = {
        user_id: selected_teacher_uuid,
        group_id: group_chat_channel_id
      };
      let url = get_api_url('add_member_to_group');
      if (add_teacher === 0) {
        url = get_api_url('remove_member_from_group');
      }
      post_api(url, payload, true)
        .then(() => {
          const success_text =
            add_teacher === 1 ? 'Participant added to group' : 'Participant removed from group';
          this.props.set_notification_variable(true, 'success', success_text);
          this.setState({ loading: false });
          this.close_add_remove_group();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const new_group_video_data = [...this.state.group_video_data];
    new_group_video_data[index] = { ...new_group_video_data[index], [name]: value };
    this.setState({ group_video_data: new_group_video_data });
  };

  handleAddField = () => {
    this.setState((prevState) => ({
      group_video_data: [...prevState.group_video_data, { video_url: '', teacher_uuid: '' }]
    }));
  };

  handleRemoveField = (index) => {
    const group_video_data = [...this.state.group_video_data];
    group_video_data.splice(index, 1);
    this.setState({ group_video_data });
  };

  save_videos = () => {
    const { group_video_data, page_details } = this.state;
    const payload = {
      class_page_uuid: page_details.uuid,
      video_data: group_video_data.map((item) => ({
        video_url: item.video_url,
        teacher_uuid: item.teacher_uuid,
        uuid: item?.uuid || ''
      }))
    };
    const url = get_api_url('update_class_page_teacher_videos');
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ show_add_gc_videos: false, loading: false }, () => {
              this.load_data();
            });
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    });
  };

  submit_replace_teacher = () => {
    const { group_session_id, selected_teacher_for_gc } = this.state;
    const payload = {
      original_group_session_id: group_session_id,
      teacher_uuid: selected_teacher_for_gc
    };
    const url = get_api_url('replace_repeat_group_class_teacher');
    this.setState({ loading: true });
    post_api(url, payload, true)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false, show_group_teacher_replacement: false }, () => {
            this.load_data();
          });
        }
      })
      .catch((e) => {
        console.log('message', e.response.data.message);
        this.setState({ loading: false, error_message: e.response.data.message, show_error: true });
      });
  };

  render_replace_group_class_teacher = () => {
    const { page_details, teacher_options, selected_teacher_for_gc, show_error, error_message } =
      this.state;
    const tagged_teachers = page_details.teachers_data?.map((t) => ({
      label: t.name,
      value: t.uuid
    }));
    return (
      <>
        <div className="flex flex-row items-center justify-between">
          <CustomSingleSelect
            label="Select Teacher"
            options={tagged_teachers}
            name="selected_teacher_for_gc"
            onChange={this.onCommonChange}
            value={selected_teacher_for_gc}
          />
          <Button disabled={!selected_teacher_for_gc} onClick={this.submit_replace_teacher}>
            Submit
          </Button>
        </div>
        <div className="text-mytRed">{show_error ? error_message : ''}</div>
      </>
    );
  };

  set_change_time = (date) => this.setState({ change_time: date });

  change_sessions_time = () => {
    const { change_time, group_session_id, selected_day, duration } = this.state;
    const res = getDayAndTime(change_time);
    const url = get_api_url('update_repeat_group_session_time');
    const payload = {
      time_of_the_day: res.formattedTime,
      day_of_the_week: Number(selected_day),
      original_group_session_id: group_session_id,
      iana_timezone: this.props.iana_timezone,
      duration: Number(duration)
    };
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then((res) => {
          if (res.status === 200)
            this.setState({ loading: false, show_time_change_modal: false }, () =>
              this.load_data()
            );
        })
        .catch((e) => {
          this.setState({
            loading: false,
            show_error_message_for_change_time: true,
            error_message_for_change_time: e?.response?.data?.message
          });
        });
    });
  };

  render_time_change = () => {
    const {
      change_time,
      show_error_message_for_change_time,
      error_message_for_change_time,
      selected_day,
      duration
    } = this.state;
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    const days = [
      { label: 'Sunday', value: 0 },
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 }
    ];
    const duration_options = [
      { label: '30 Minutes', value: 30 },
      { label: '45 Minutes', value: 45 },
      { label: '60 Minutes', value: 60 },
      { label: '90 Minutes', value: 90 }
    ];
    return (
      <div className="h-96">
        <div className="flex items-center justify-center">{`Select time (${curr_timezone})`}</div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <div>Select Time</div>
            <DatePicker
              selected={change_time}
              onChange={(date) => this.set_change_time(date)}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              minDate={new Date()}
              maxDate={moment(new Date()).add(90, 'days').toDate()}
              title="Select Time"
            />
          </div>
          <CustomSingleSelect
            label="Select day"
            options={days}
            name="selected_day"
            value={selected_day}
            onChange={this.onCommonChange}
          />
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <CustomSingleSelect
              label="Select duration"
              options={duration_options}
              name="duration"
              value={duration}
              onChange={this.onCommonChange}
            />
          </div>
        </div>
        <div>
          <Button onClick={this.change_sessions_time}>Change Time</Button>
        </div>
        <div className="text-mytRed">
          {show_error_message_for_change_time && error_message_for_change_time}
        </div>
      </div>
    );
  };

  render_group_class_video = () => {
    const { group_video_data, page_details } = this.state;
    const tagged_teachers = page_details.teachers_data?.map((t) => ({
      label: t.name,
      value: t.uuid
    }));
    return (
      <>
        <div className="w-full flex items-center justify-center mb-4">
          <h2 className="">Add videos for group class</h2>
        </div>
        <div className="w-full flex flex-col">
          {group_video_data.map((field, index) => (
            <div className="flex flex-row items-center justify-between">
              <CustomInput
                label="Video Url"
                onChange={(e) => this.handleInputChange(index, e)}
                name="video_url"
                value={field.video_url}
              />
              <CustomSingleSelect
                label="Select Teacher"
                options={tagged_teachers}
                name="teacher_uuid"
                onChange={(e) => this.handleInputChange(index, e)}
                value={field.teacher_uuid}
              />
              <Button onClick={() => this.handleRemoveField(index)}>Remove</Button>
            </div>
          ))}
          <div className="flex flex-row items-center space-between">
            <Button onClick={this.save_videos}>Save</Button>
            <Button type="button" onClick={this.handleAddField}>
              Add Field
            </Button>
          </div>
        </div>
      </>
    );
  };

  render_student_waitlist = () => {
    const { page_details } = this.state;
    return (
      <>
        <div className="mt-5">Student Wait list</div>
        <table className="border">
          <thead>
            <tr>
              <th className="text-white bg-mytBlue">Name</th>
              <th className="text-white bg-mytBlue">Email</th>
              <th className="text-white bg-mytBlue">Date Opted for</th>
            </tr>
          </thead>
          <tbody>
            {page_details?.waitlist_students?.map((item) => (
              <tr key={item.created_date}>
                <td>{item.first_name}</td>
                <td>{item.email}</td>
                <td>
                  {moment(item.created_date).tz(this.props.iana_timezone).format(time_day_format)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className='mt-5'>
                    <ImageCropComponent />
            </div> */}
      </>
    );
  };

  open_teacher_replacement = (original_group_session_id) =>
    this.setState({
      show_group_teacher_replacement: true,
      group_session_id: original_group_session_id
    });

  open_time_change = (original_group_session_id, session_start_time) => {
    const {
      day_of_the_week = '',
      pst_day_of_the_week = '',
      duration
    } = this.state.repeat_session_list.filter(
      (sess) => sess.original_group_session_id === original_group_session_id
    )?.[0];
    let final_day_of_the_week = day_of_the_week;
    if (pst_day_of_the_week) {
      final_day_of_the_week = pst_day_of_the_week;
    }
    const selected_date = moment
      .tz(session_start_time, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const selected_time = moment(selected_date).toDate();
    this.setState({
      show_time_change_modal: true,
      group_session_id: original_group_session_id,
      change_time: selected_time,
      selected_day: final_day_of_the_week,
      duration
    });
  };

  close_time_change = () =>
    this.setState({
      show_time_change_modal: false,
      show_error_message_for_change_time: false,
      error_message_for_change_time: ''
    });

  close_teacher_replacement = () =>
    this.setState({
      show_group_teacher_replacement: false,
      show_error: false,
      error_message: '',
      selected_teacher_for_gc: ''
    });

  render() {
    const {
      loading,
      edit_open,
      initial_loading,
      add_classes_open,
      remove_classes_open,
      add_teacher_to_group,
      page_details,
      show_add_gc_videos,
      show_group_teacher_replacement,
      show_repeat_modal,
      show_time_change_modal
    } = this.state;

    return (
      <CommonHeader show_back loading={loading || initial_loading} title="Class page details">
        <CustomModal show={edit_open} close={this.toggle_edit}>
          {this.edit_page()}
        </CustomModal>
        <CustomModal
          show={add_classes_open}
          close={this.close_add_classes}
          inner_style={{ maxWidth: '80%', boxSizing: 'border-box' }}
        >
          {this.add_classes()}
        </CustomModal>
        <CustomModal
          show={remove_classes_open}
          close={this.close_add_classes}
          inner_style={{ maxWidth: '80%', boxSizing: 'border-box' }}
        >
          {this.remove_classes()}
        </CustomModal>
        <CustomModal show={add_teacher_to_group} close={this.close_add_remove_group}>
          {this.render_add_remove_teacher_modal()}
        </CustomModal>
        <CustomModal show={this.state.edit_tags} close={this.close_edit_tags} />
        <CustomModal show={show_add_gc_videos} close={this.close_group_class_details_video}>
          {this.render_group_class_video()}
        </CustomModal>
        <CustomModal show={show_group_teacher_replacement} close={this.close_teacher_replacement}>
          {this.render_replace_group_class_teacher()}
        </CustomModal>
        <CustomModal show={show_repeat_modal} close={this.close_notify_repeat_user_modal}>
          {this.render_notify_repeat_user_modal()}
        </CustomModal>
        <CustomModal show={show_time_change_modal} close={this.close_time_change}>
          {this.render_time_change()}
        </CustomModal>
        {this.render_details()}
        {this.render_repeat_sesisons()}
        {this.render_sessions()}
        {page_details.uuid ? (
          <RenderAllTags
            all_tags={this.state.all_tags}
            association_type={this.state.association_type}
            association_id={page_details.uuid}
            entity_name={page_details.name}
          />
        ) : null}
        {this.render_student_waitlist()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  config_status: state.loading.config_status,
  teachers_list_status: state.teachers.teachers_list_status,
  teachers_list: state.teachers.teachers_list,
  get_all_associated_tags: state.content.get_all_associated_tags,
  get_all_associated_tags_status: state.content.get_all_associated_tags_status,
  update_tag_associations_status: state.content.update_tag_associations_status,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_class_types: () => dispatch(actions.get_class_types()),
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  getAllAssociatedTags: (payload) => {
    dispatch(actions.get_all_associated_tags(payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassPageDetails);
