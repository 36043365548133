import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import './date_picker.css';

function CustomDatePicker(props) {
  const handle_date = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const da = date.getDate();
    const final_date = `${year}-${month < 10 ? `0${month}` : month}-${da < 10 ? `0${da}` : da}`;
    props.handleChange(final_date);
  };
  const value =
    !!props.value && props.value !== 'Invalid date' ? new Date(props.value.replace(/-/g, '/')) : '';
  return (
    <div className="custom-date-picker">
      {props.label ? <div className="custom-date-picker-label">{props.label}:</div> : null}
      <DatePicker
        utcOffset={0}
        // selected={!!props.value && props.value !== 'Invalid date' ? new Date(props.value) : ''}
        selected={value}
        onChange={(date) => handle_date(date)}
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement={props.popper_placement}
        minDate={props.minDate ? new Date(props.minDate) : ''}
        maxDate={props.maxDate ? new Date(props.maxDate) : ''}
      />
    </div>
  );
}

CustomDatePicker.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  popper_placement: PropTypes.string
};

CustomDatePicker.defaultProps = {
  minDate: '',
  maxDate: '',
  popper_placement: 'auto'
};

export default CustomDatePicker;
