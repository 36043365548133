import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import BlogUpload from './upload';
import NoPermission from '../../../util_components/no_permission';

class UploadImages extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/upload_images/blog" component={BlogUpload} />
          <Route exact path="/upload_images/workshop" component={BlogUpload} />
          <Route path="*" component={NoPermission} />
        </Switch>
      </div>
    );
  }
}

export default UploadImages;
