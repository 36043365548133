import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import ScrollContainer from '../../../util_components/scroll_container';
import { get_api_url } from '../../../../utils/urls';

class CareMessages extends Component {
  state = { loading: true, students_messages: [] };

  componentDidMount() {
    this.load_data();
  }

  load_data = () => {
    const url = get_api_url('get_care_messages_last_7_days');
    post_api(url, null, true)
      .then((result) => this.setState({ students_messages: result.data.messages, loading: false }))

      .catch(() => this.setState({ loading: false, page_error: true }));
  };

  render_messages_mobile = () => {
    const messages = this.state.students_messages;
    return (
      <div>
        {messages.map((message, index) => {
          const student_url = `/studentprofile?id=${message.student_uuid}`;
          let column_class = 'common-mobile-column';
          column_class +=
            index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
          return (
            <div className={column_class} key={message.id}>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Student Name</div>
                <div className="common-mobile-single-detail">
                  <a
                    className="care-msg-name"
                    target="_blank"
                    href={student_url}
                    rel="noopener noreferrer"
                  >
                    {message.student_first_name +
                      message.student_middle_name +
                      message.student_last_name}
                  </a>
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Message</div>
                <div className="common-mobile-single-detail">{message.body}</div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Received at</div>
                <div className="common-mobile-single-detail">
                  {moment(message.created_date)
                    .tz(this.props.iana_timezone)
                    .format('DD MMM, hh:mm z')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render_students_care_messages_new = () => (
    <div className="cus-table" style={{ marginBottom: '20px' }}>
      <div className="ct-row ct-h">
        <div className="ct-col ct-hf ct-la" style={{ paddingLeft: '10px' }}>
          Student
        </div>
        <div className="ct-col">Message</div>
        <div className="ct-col ct-hf">Received on</div>
      </div>
      {this.state.students_messages.map((student, index) => {
        const student_url = `/studentprofile?id=${student.student_uuid}`;
        return (
          <div className="ct-row" key={index + student.student_uuid}>
            <div className="ct-col ct-hf ct-la" style={{ paddingLeft: '10px' }}>
              <a
                className="care-msg-name"
                target="_blank"
                href={student_url}
                rel="noopener noreferrer"
              >
                {student.student_first_name +
                  student.student_middle_name +
                  student.student_last_name}
              </a>
            </div>
            <div className="ct-col ct-la">{student.body}</div>
            <div className="ct-col ct-hf">
              {moment(student.created_date).tz(this.props.iana_timezone).format('DD MMM, hh:mm z')}
            </div>
          </div>
        );
      })}
    </div>
  );

  render_students_care_messages = () => (
    <table className="care-msg-table">
      <thead>
        <tr>
          <th width="15%">Student Name</th>
          <th width="70%">Message</th>
          <th width="15%">Received at</th>
        </tr>
      </thead>
      <tbody>
        {this.state.students_messages.map((student, index) => {
          const student_url = `/studentprofile?id=${student.student_uuid}`;
          return (
            <tr key={index + student.student_uuid}>
              <td>
                <a
                  className="care-msg-name"
                  target="_blank"
                  href={student_url}
                  rel="noopener noreferrer"
                >
                  {student.student_first_name +
                    student.student_middle_name +
                    student.student_last_name}
                </a>
              </td>
              <td className="care-msg">{student.body}</td>
              <td className="care-msg-time">
                {moment(student.created_date)
                  .tz(this.props.iana_timezone)
                  .format('DD MMM, hh:mm z')}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="common-page-title">Care Messages</div>
        </div>
        {this.props.application_width > 700
          ? this.render_students_care_messages_new()
          : this.render_messages_mobile()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Care Messages</title>
        </Helmet>
        <ScrollContainer />
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  application_width: state.home.application_width
});

export default connect(mapStateToProps)(CareMessages);
