import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { post_api } from '../../redux/api_funcs';
import * as actions from '../../redux/action_creators';
import { get_api_url } from '../../utils/urls';
import './uploadImage.css';

class UploadImage extends Component {
  state = {
    show_upload: false,
    image: null,
    image_url: ''
  };

  handleFileChange = (e) => {
    const reader = new FileReader();
    const image_file = e.target.files[0];
    reader.onloadend = () => {
      console.log(reader.result);
      this.setState({
        image: image_file,
        image_url: reader.result,
        show_upload: true
      });
    };
    reader.readAsDataURL(image_file);
  };

  update_pic = () => {
    const realInput = document.getElementById('pic-input');
    realInput.click();
  };

  clear_image = () => this.setState({ image: null, image_url: '', show_upload: false });

  upload_image = () => {
    const { image } = this.state;
    const url = get_api_url('upload_single_cloudinary_image');
    const data = new FormData();
    data.append('photo', image);
    data.append('folder_name', 'uploads');
    post_api(url, data, true, 'formdata')
      .then((response) => {
        console.log('the resp is ', response);
        const img_response = response.data.result;
        const regex = `/[\\w-]+\.${img_response.format}/`;
        console.log(regex);
        const match = img_response.secure_url.match(regex);
        console.log(match);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { image_url, show_upload } = this.state;
    return (
      <div className="custom-image-upload">
        {image_url ? (
          <div className="custom-image-image">
            <img src={image_url} alt="upload-image" />
          </div>
        ) : null}
        <button onClick={this.update_pic}>Select Image</button>
        {show_upload ? <button onClick={this.upload_image}>Upload Image</button> : null}
        {image_url ? <button onClick={this.clear_image}>Clear Image</button> : null}
        <input
          type="file"
          id="pic-input"
          accept="image/*"
          onChange={this.handleFileChange}
          name="photo"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

UploadImage.propTypes = {
  folder_name: PropTypes.string
};

UploadImage.defaultProps = {
  folder_name: 'upload_images'
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
