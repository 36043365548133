import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { post_api } from '../../../../redux/api_funcs';
import CustomLoading from '../../../util_components/custom_loading';
import Spinner from '../../../util_components/spinner';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomCheckbox from '../../../util_components/custom_checkbox';
import CustomModal from '../../../util_components/custom_modal';
import Button from '../../../util_components/button';
import { get_api_url } from '../../../../utils/urls';
import { trackUserDevice } from '../../../../util_functions';

const session_duration_list = [
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '60',
    label: '60 min'
  },
  {
    value: '90',
    label: '90 min'
  }
];

export default class BookSession extends Component {
  state = {
    teachers_loading: false,
    teachers_loaded: false,
    slots_loaded: false,
    slots_loading: false,
    teacher_selected: '',
    slot_selected: '',
    session_error: false,
    book_loading: false,
    book_success: false,
    book_error: false,
    is_repeat: false,
    session_duration: '60',
    err_message: '',
    no_credits: false,
    final_durations: []
  };

  componentDidMount() {
    const { session_price } = this.props.details;
    const session_price_30 = Math.floor(session_price / 2);
    const session_price_90 = session_price + Math.floor(session_price / 2);
    const credits = this.props.details.credits_available;
    const mem_details = this.props.details.subscription_info;
    const is_member = Object.keys(mem_details).length !== 0;
    const fu_details = this.props.details.subscription_queue;
    const is_fu_member = Object.keys(fu_details).length !== 0;
    let final_durations;
    if (credits >= session_price_90 || is_member || is_fu_member) {
      final_durations = session_duration_list;
    } else if (credits >= session_price) {
      final_durations = session_duration_list.slice(0, 2);
    } else if (credits >= session_price_30) {
      final_durations = session_duration_list.slice(0, 1);
      this.setState({ session_duration: '30' });
    } else this.setState({ no_credits: true });
    this.setState({ final_durations });
    this.get_cards_list();
  }

  get_cards_list = () => {
    const url = get_api_url('get_all_cards_s');
    const data = {
      student_uuid: this.props.student_uuid
    };
    post_api(url, data, true)
      .then((response) => {
        const cards_count = response.data.cards.length;
        const credits = this.props.details.credits_available - this.props.details.session_price;
        // if (credits >= 0 || cards_count > 0) {
        // } else {
        // 	this.setState({ session_error: true });
        // }
        this.get_teachers_list();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          this.setState({ redirectLogin: true });
        }
      });
  };

  get_teachers_list = () => {
    this.setState({ teachers_loading: true });
    const url = get_api_url('teachers_list_for_new_session');
    const data = {
      student_uuid: this.props.details.uuid
    };
    post_api(url, data, true)
      .then((response) => {
        const teachers_list = [...response.data.teachers];
        teachers_list.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
          teachers_list,
          teachers_loaded: true,
          teachers_loading: false
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          this.setState({ redirectLogin: true });
        }
      });
  };

  get_slots = () => {
    this.setState({ slots_loading: true, slots_loaded: false });
    // const data = {
    // 	student_uuid: this.props.details.uuid,
    // 	teacher_uuid: this.state.teacher_selected,
    // 	timezone: this.props.details.timezone * -1,
    // 	iana_timezone: this.props.details.iana_timezone
    // };
    const url = get_api_url('teacher_slots_v1');
    const data = {
      student_uuid: this.props.details.uuid,
      teacher_uuid: this.state.teacher_selected,
      timezone: this.props.details.timezone * -1
    };
    post_api(url, data, true)
      .then((response) => {
        const available_slots = [...response.data.available_slots];
        const all_slots = [];
        for (const day_slot of available_slots) {
          const keys = Object.keys(day_slot.slots);
          if (keys.length > 0) {
            for (const key of keys) {
              all_slots.push(...day_slot.slots[key]);
            }
          }
        }
        // this.setState({
        // 	slots_list: response.data.available_slots,
        // 	slots_loaded: true,
        // 	slots_loading: false,
        // 	slot_selected: ''
        // });
        this.setState({
          slots_list: all_slots,
          slots_loaded: true,
          slots_loading: false,
          slot_selected: ''
        });
      })
      .catch((error) => {
        console.log(error);
        // if (error.response.status === 401) {
        // 	this.setState({ redirectLogin: true });
        // }
      });
  };

  on_teacher_change = (e) => {
    this.setState({ teacher_selected: e.target.value }, () => {
      this.get_slots();
    });
    //
  };

  on_slot_change = (e) => {
    this.setState({ slot_selected: e.target.value });
  };

  change_duration = (e) => {
    this.setState({ session_duration: e.target.value });
  };

  book_session = () => {
    this.setState({ book_loading: true });
    const url = get_api_url('schedule_session');
    const data = {
      student_uuid: this.props.details.uuid,
      teacher_uuid: this.state.teacher_selected,
      start_time: parseInt(this.state.slot_selected, 10),
      is_repeat: this.state.is_repeat ? 1 : 0,
      duration: parseInt(this.state.session_duration, 10),
      booking_source: 'care_team'
    };
    post_api(url, data, true)
      .then((res) => {
        const session_uuid = res?.data?.data[0]?.session_uuid;
        trackUserDevice(session_uuid, 'SCHEDULE');
        const url = get_api_url('/v2/zoom_services/create_zoom_meeting', false, true);
        post_api(url, data, true)
          .then(() => {
            this.setState({ book_success: true, book_loading: false });
          })
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.reason || e.response.data.reason.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ book_success: true, book_loading: false });
          });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.reason || e.response.data.reason.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        this.props.set_notification_variable(true, 'error', err_message);
        this.setState({ book_loading: false, err_message });
      });
  };

  render_teachers_list = () => {
    if (this.state.no_credits) {
      return <div>No credits available</div>;
    }
    if (this.state.teachers_loaded) {
      const teachers_list = this.state.teachers_list.map((teacher) => ({
        value: teacher.teacher_uuid,
        label: teacher.name
      }));
      return (
        <>
          <p>
            All slots are in timezone{' '}
            {this.props.details.iana_timezone
              ? moment().tz(this.props.details.iana_timezone).format('z')
              : `${this.props.details.timezone * -1} ( timezone offset)`}
          </p>

          <CustomSingleSelect
            label="Select a teacher"
            options={teachers_list}
            onChange={this.on_teacher_change}
            value={this.state.teacher_selected}
            style={{ width: '300px' }}
          />
        </>
      );
    }

    return null;
  };

  toggle_repeat = () => this.setState({ is_repeat: !this.state.is_repeat, slot_selected: '' });

  render_slots = () => {
    if (this.state.slots_loaded) {
      const slots_array = [];
      // this.state.slots_list.map(day =>
      // 	day.slots.map(slot => slots_array.push({ value: slot.epoch_time, label: day.date + ' ' + slot.time }))
      // );
      const final_slots = this.state.slots_list.filter((f) => {
        const duration = this.state.session_duration;
        if (duration === '30' && this.state.is_repeat) {
          return f.slot_30 === 1 && f.is_repeat_30 === 1;
        }
        if (duration === '30') {
          return f.slot_30 === 1;
        }
        if (duration === '60' && this.state.is_repeat) {
          return f.slot_60 === 1 && f.is_repeat === 1;
        }
        if (duration === '60') {
          return f.slot_60 === 1;
        }
        if (duration === '90' && this.state.is_repeat) {
          return f.slot_90 === 1 && f.is_repeat_90 === 1;
        }
        if (duration === '90') {
          // return f.slot_30 === 1 || f.slot_60 === 1 || f.slot_90 === 1
          return f.slot_90 === 1;
        }
        // let available_key = `slot_${duration}`
        // let repeat_key = 'is_repeat';
        // if (duration !== '60'){
        // 	repeat_key = `is_repeat_${duration}`
        // }
        // if(this.state.is_repeat){
        // 	return f[available_key] === 1 && f[repeat_key] === 1
        // }
        // else return f[available_key] === 1
      });
      final_slots.map((slot) =>
        slots_array.push({ value: slot.epoch_time, label: slot.local_session_time })
      );
      if (final_slots.length > 0) {
        return (
          <>
            <div style={{ marginBottom: '20px' }}>
              <CustomCheckbox
                label="Is Repeat"
                checked={this.state.is_repeat}
                onClick={this.toggle_repeat}
              />
            </div>
            <CustomSingleSelect
              label="Select a slot"
              options={slots_array}
              onChange={this.on_slot_change}
              value={this.state.slot_selected}
              style={{ width: '300px' }}
            />
          </>
        );
      }
      return <div>No slots available</div>;
    }
    return null;
  };

  render() {
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Book Session</title>
        </Helmet>
        {this.state.book_loading ? <CustomLoading /> : ''}
        <CustomModal show={this.state.book_success} close={this.props.close_all_popups}>
          Session Booked
        </CustomModal>
        <CustomModal show={this.state.book_error} close={this.props.close_all_popups}>
          {this.state.err_message}
        </CustomModal>
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.props.close_all_popups}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Book a Session</div>
        </div>
        <div>
          {this.state.session_error ? (
            <>
              <h6>Insufficent credits or no credit cards available</h6>
              <p>Please add credits or credit card to continue</p>
            </>
          ) : null}
          {this.state.teachers_loading ? <Spinner type="dots" /> : null}
          {this.render_teachers_list()}
          {this.state.slots_loading ? <Spinner type="dots" /> : null}
          {this.state.slots_loaded && !!this.state.teacher_selected ? (
            <CustomSingleSelect
              label="Select Duration"
              options={this.state.final_durations}
              onChange={this.change_duration}
              value={this.state.session_duration}
              style={{ width: '300px' }}
            />
          ) : null}
          {this.render_slots()}
          {this.state.teacher_selected.trim() !== '' && this.state.slot_selected.trim() !== '' ? (
            <Button disabled={this.state.book_loading} onClick={this.book_session}>
              Book
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}
