import React, { Component } from 'react';
import moment from 'moment-timezone';
import CustomInput from '../../util_components/custom_input';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomRadioButton from '../../util_components/custom_radio_button';
import Button from '../../util_components/button';

const offer_types = [
  { value: 'CREDIT', label: 'CREDIT' },
  { value: 'WORKSHOP', label: 'WORKSHOP' },
  { value: 'PACKAGE', label: 'PACKAGE' },
  { value: 'PREPAY', label: 'PREPAY' },
  { value: 'GIFT', label: 'GIFT' },
  { value: 'RECURRING', label: 'RECURRING' }
];

const value_types = [
  { value: 'PERC', label: 'PERC' },
  { value: 'VALUE', label: 'VALUE' }
];

const used_by_values = [
  { value: 'MULTI_USER', label: 'Multi User' },
  { value: 'SINGLE_USER', label: 'Single User' }
];

const weeks = [
  { value: '4', label: '4 Weeks' },
  { value: '12', label: '12 Weeks' },
  { value: '24', label: '24 Weeks' },
  { value: '52', label: '52 Weeks' }
];

const currencies = ['USD', 'INR', 'GBP'];

class CreateEditOffer extends Component {
  state = {
    offer_name: '',
    type: '',
    start_date: '',
    end_date: '',
    value_type: '',
    value: 0,
    currency: '',
    activation_limit: 0,
    coupon_used_by: '',
    description: '',
    offer_type_mapped: 0,
    no_of_weeks: '4'
  };

  handle_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val
    });
  };

  handle_number_change = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10)
    });
  };

  toggle_currency = (curr) => {
    const { currency } = this.state;
    const curr_vals = currency ? currency.split(',') : [];
    const curr_index = curr_vals.indexOf(curr);
    if (curr_index === -1) curr_vals.push(curr);
    else curr_vals.splice(curr_index, 1);
    this.setState({ currency: curr_vals.join(',') });
  };

  toggle_state = (key) => {
    this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });
  };

  submit_fn = () => {
    const {
      offer_name,
      type,
      start_date,
      end_date,
      value_type,
      value,
      currency,
      activation_limit,
      coupon_used_by,
      description,
      offer_type_mapped,
      no_of_weeks
    } = this.state;
    const payload = {
      offer_name,
      type,
      start_date,
      end_date,
      value_type,
      value,
      currency,
      activation_limit,
      coupon_used_by,
      description,
      offer_type_mapped,
      no_of_weeks
    };
    this.props.submit_fn(payload);
  };

  render() {
    const {
      offer_name,
      type,
      start_date,
      end_date,
      value_type,
      value,
      currency,
      activation_limit,
      coupon_used_by,
      description,
      offer_type_mapped,
      no_of_weeks
    } = this.state;
    const is_disabled =
      !!offer_name &&
      !!type &&
      !!start_date &&
      !!end_date &&
      !!value_type &&
      value > 0 &&
      !!currency &&
      !!coupon_used_by &&
      !!description;
    return (
      <div>
        <h2 className="common-padding">Create Coupon Offer</h2>
        <div className="display-flex-between-center">
          <CustomInput
            label="Offer Name"
            value={offer_name}
            name="offer_name"
            onChange={this.handle_change}
          />
          <CustomSingleSelect
            label="Type"
            options={offer_types}
            onChange={this.handle_change}
            value={type}
            name="type"
          />
        </div>
        <div className="display-flex-between-center">
          <CustomDatePicker
            label="Start Date"
            value={start_date}
            handleChange={(val) => this.handle_date_change('start_date', val)}
            minDate={moment().format('YYYY-MM-DD')}
          />
          <CustomDatePicker
            label="End Date"
            value={end_date}
            handleChange={(val) => this.handle_date_change('end_date', val)}
            minDate={moment().format('YYYY-MM-DD')}
          />
        </div>
        <div className="display-flex-between-center">
          <CustomSingleSelect
            label="Value Type"
            options={value_types}
            onChange={this.handle_change}
            value={value_type}
            name="value_type"
          />
          <CustomInput
            label="Value"
            value={value}
            name="value"
            onChange={this.handle_number_change}
            type="number"
          />
        </div>
        <div>
          <h4>Currency:</h4>
          <div className="pgn-con">
            {currencies.map((c) => {
              const is_selected = currency.indexOf(c) !== -1;
              return (
                <div className="ct-mr25">
                  <CustomRadioButton
                    label={c}
                    onRadioClick={() => this.toggle_currency(c)}
                    selected={is_selected}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="display-flex-between-center" style={{ marginTop: '20px' }}>
          <CustomInput
            label="Activation Limit"
            value={activation_limit}
            name="activation_limit"
            onChange={this.handle_number_change}
            type="number"
          />
          <CustomSingleSelect
            label="Used By"
            options={used_by_values}
            onChange={this.handle_change}
            value={coupon_used_by}
            name="coupon_used_by"
          />
        </div>
        <div className="display-flex-between-center">
          <CustomRadioButton
            label="Is Mapping Present"
            onRadioClick={() => this.toggle_state('offer_type_mapped')}
            selected={offer_type_mapped === 1}
          />
          <CustomSingleSelect
            label="No of Weeks"
            options={weeks}
            onChange={this.handle_change}
            value={no_of_weeks}
            name="no_of_weeks"
          />
        </div>
        <div className="edit-profile-yoga">
          <div>Description:</div>
          <textarea onChange={this.handle_change} value={description} name="description" />
        </div>
        <Button disabled={!is_disabled} onClick={this.submit_fn}>
          Submit
        </Button>
      </div>
    );
  }
}

export default CreateEditOffer;
