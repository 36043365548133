import * as t from '../action_types';

const initialState = {
  approved_teachers_list: [],
  approved_teachers_status: 'none',
  teachers_list_status: 'none',
  teachers_list: [],
  filtered_teachers: [],
  teacher_profile_uuid: '',
  teacher_profile_details: '',
  teacher_profile_status: '',
  search_text: '',
  goal_search_text: '',
  onboarding_status: 'APPROVED'
};

const update_approved_teachers = (state, payload) => {
  const channels = [...state.channels];
  channels.find((o) => o.sid === payload.sid).other_member = payload.payload.member;
  return {
    ...state,
    channels
  };
};

const set_teachers_variable = (state, payload) => ({
  ...state,
  [payload.key]: payload.payload
});

const filter_teachers = (state, payload) => {
  let all_teachers = [];
  if (payload.key === 'teachers_list') {
    all_teachers = [...payload.payload];
  } else {
    all_teachers = [...state.teachers_list];
  }
  let status_list = [];
  const sort_item = payload.key === 'onboarding_status' ? payload.payload : state.onboarding_status;
  const name_search_text =
    payload.key === 'search_text' ? payload.payload.toLowerCase() : state.search_text.toLowerCase();
  const goal_search_text =
    payload.key === 'goal_search_text'
      ? payload.payload.toLowerCase()
      : state.goal_search_text.toLowerCase();
  if (sort_item === 'ALL') {
    status_list = [...all_teachers];
  } else {
    status_list = all_teachers.filter((teacher) => teacher.onboarding_status === sort_item);
  }
  const search_list = status_list.filter(
    (teacher) =>
      teacher.first_name.toLowerCase().indexOf(name_search_text) !== -1 ||
      teacher.last_name.toLowerCase().indexOf(name_search_text) !== -1 ||
      teacher.email.toLowerCase().indexOf(name_search_text) !== -1
  );

  const goal_list = search_list.filter((tea) => {
    const goals = tea.goals ? tea.goals : [];
    return goals.join(',').toLowerCase().indexOf(goal_search_text) !== -1;
  });
  goal_list
    .sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 1))
    .sort((a, b) => b.new_profile_updates - a.new_profile_updates);
  return {
    ...state,
    [payload.key]: payload.payload,
    filtered_teachers: [...goal_list]
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_teachers_variable:
      return set_teachers_variable(state, action.payload);
    case t.update_approved_teachers:
      return update_approved_teachers(state, action.payload);
    case t.filter_teachers:
      return filter_teachers(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
