import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { get_api_url, get_domain } from '../../../utils/urls';
import { get_api, post_api } from '../../../redux/api_funcs';
import { HridayaLinkType, HridayaMedium } from '../../../constants';
import CustomInput from '../../util_components/custom_input';
import NoData from '../../../../public/static/images/no-data.jpg';
import { CloseIcon, InfoIcon, CopyClick } from '../../../utils/svg';

const ShortUrlLinks = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shortUrlLinksData, setShortUrlLinksData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formData, setFormData] = useState({
    shortUrlTag: '',
    filterShortUrlTag: '',
    prefix: '',
    description: '',
    utmCampaign: '',
    utmMedium: '',
    utmSource: '',
    utmContent: '',
    utmTerm: '',
    path: ''
  });
  const [infoDatum, setInfoDatum] = useState({});
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    const { utmCampaign, utmSource, utmMedium, utmContent, utmTerm, shortUrlTag } = formData;
    const isFormComplete = [
      utmCampaign,
      utmSource,
      utmMedium,
      utmContent,
      utmTerm,
      shortUrlTag
    ].every((field) => field !== '');
    setIsButtonDisabled(!isFormComplete);
  }, [formData]);
  // set state for model open and close
  const toggleVisibility = () => {
    setShowModal((prevShowModal) => !prevShowModal);
    setFormData({
      shortUrlTag: '',
      filterShortUrlTag: '',
      prefix: '',
      description: '',
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      utmContent: '',
      utmTerm: '',
      path: ''
    });
    setShowMoreInfo(false);
  };

  const closeModel = () => {
    setIsButtonDisabled(true);
    setShowModal(false);
    setFormData({
      shortUrlTag: '',
      filterShortUrlTag: '',
      prefix: '',
      description: '',
      utmCampaign: '',
      utmMedium: '',
      utmSource: '',
      utmContent: '',
      utmTerm: '',
      path: ''
    });
    setShowMoreInfo(false);
    setInfoDatum({});
  };

  const generateUrl = () => {
    const domain = get_domain();
    return `${domain}/${formData.path}?utm_source=${formData.utmSource}&utm_medium=${formData.utmMedium}&utm_campaign=${formData.utmCampaign}&utm_content=${formData.utmContent}&utm_term=${formData.utmTerm}`;
  };

  const onChangeFormHelper = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle the form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      url: generateUrl(),
      prefix: formData.prefix,
      link_type: HridayaLinkType,
      medium: HridayaMedium,
      description: formData.description,
      short_url_tag: formData.shortUrlTag
    };
    const url = get_api_url('/v2/url_shortner', null, true);

    setLoading(true);

    post_api(url, payload, true)
      .then(() => {
        closeModel();
        loadData();
      })
      .catch((e) => {
        console.error(e);
        const err_message =
          e?.response?.data?.message || 'Some error occurred. Please contact care team.';
        setLoading(false);
      });
  };

  // loading the all short_urls based on filters
  const loadData = () => {
    setLoading(true);
    const apiUrl = `/v2/url_shortner/short_urls?short_url_tag=${formData.filterShortUrlTag}`;
    const url = get_api_url(apiUrl, null, true);

    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        setShortUrlLinksData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const handleShowMoreInfo = (datum) => {
    setInfoDatum(datum);
    setShowMoreInfo((prevShowModal) => !prevShowModal);
  };

  // Render Template
  const renderMoreInfo = () => (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        id="default-modal"
      >
        <div className="relative w-auto my-6 mx-auto max-w-7xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 rounded-t">
              <h4 className="px-3 py-1 text-2xl font-semibold text-center">More Info</h4>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-1xl font-semibold"
                onClick={handleShowMoreInfo}
              >
                <CloseIcon />
              </button>
            </div>
            {/* body */}
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    Token
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.token}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    URL
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.url}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    Expiry At
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.expire_at}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    Short Url
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.short_url}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    Short Url Tag
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.short_url_tag}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    Description
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum.description}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    UTM Source
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum?.query_string?.utm_source}
                  </td>
                </tr>

                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    UTM Medium
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum?.query_string?.utm_medium}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    UTM Campaign
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum?.query_string?.utm_campaign}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    UTM Content
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum?.query_string?.utm_content}
                  </td>
                </tr>
                <tr>
                  <td scope="col" className="px-4 py-2 font-medium">
                    UTM Term
                  </td>
                  <td scope="col" className="px-4 py-2 font-medium">
                    {infoDatum?.query_string?.utm_term}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );

  // render the modal
  const renderShortUrlLinkModel = () => (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h4 className="px-3 py-1 text-2xl font-semibold">Create New Link</h4>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-1xl font-semibold"
                onClick={toggleVisibility}
              >
                <svg
                  className="w-4 h-4 text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
            {/* body */}
            <form onSubmit={handleFormSubmit}>
              <div className="relative p-6 flex-auto">
                <div className="grid gap-4 mb-4 grid-cols-2">
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="UTM Source"
                      onChange={onChangeFormHelper}
                      name="utmSource"
                      value={formData.utmSource}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="UTM Medium"
                      onChange={onChangeFormHelper}
                      name="utmMedium"
                      value={formData.utmMedium}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="UTM Campaign"
                      onChange={onChangeFormHelper}
                      name="utmCampaign"
                      value={formData.utmCampaign}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="UTM Content"
                      onChange={onChangeFormHelper}
                      name="utmContent"
                      value={formData.utmContent}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="UTM Term"
                      onChange={onChangeFormHelper}
                      name="utmTerm"
                      value={formData.utmTerm}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <CustomInput
                      label="Path"
                      onChange={onChangeFormHelper}
                      name="path"
                      value={formData.path}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="prefix"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Prefix
                    </label>
                    <input
                      type="text"
                      name="prefix"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full"
                      maxLength={4}
                      onChange={onChangeFormHelper}
                      value={formData.prefix}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="prefix"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Short Url Tag
                    </label>
                    <input
                      type="text"
                      name="shortUrlTag"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full"
                      maxLength={50}
                      onChange={onChangeFormHelper}
                      value={formData.shortUrlTag}
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="prefix"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                    <textarea
                      style={{ width: '500px', height: '200px' }}
                      value={formData.description}
                      name="description"
                      onChange={onChangeFormHelper}
                      maxLength="2000"
                    />
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-red-500 hover:text-white hover:bg-red-500"
                  type="button"
                  onClick={toggleVisibility}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className={`text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-transparent hover:text-blue-700 ${
                    isButtonDisabled ? 'pointer-events-none opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  <svg
                    className="me-1 -ms-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );

  // render the filter section
  const renderFilter = () => (
    <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
      <div className="flex items-center flex-1 space-x-4">
        <h5>
          <span className="text-gray-500">Filters</span>
        </h5>
      </div>
      <form onSubmit={loadData}>
        <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
          <CustomInput
            label="Short Url Tags"
            value={formData.filterShortUrlTag}
            name="filterShortUrlTag"
            onChange={onChangeFormHelper}
            onSubmit={loadData}
          />
          <button className="hidden" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );

  // render the single short_url
  const renderSingleShortUrlLink = (shortUrl) => (
    <tr className="border-b">
      <td scope="col" className="px-5 py-2 font-medium" id="copy-short_url">
        {shortUrl.short_url_tag}
      </td>
      <td scope="col" className="px-4 py-2">
        <span
          className={`text-primary-800 text-xs font-medium px-2 py-0.5 rounded ${
            moment(shortUrl.expire_at).endOf('day').isBefore(moment()) ? 'text-red-500' : ''
          }`}
        >
          {shortUrl.expire_at}
        </span>
      </td>
      <td className="px-4 py-2 font-medium">{shortUrl.short_url}</td>
      <td scope="col" className="px-4 py-2 font-medium flex items-center space-x-2">
        <div className="flex items-center space-x-2">
          <button
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Green Icon Tooltip"
            onClick={() => handleShowMoreInfo(shortUrl)}
            className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
          >
            <InfoIcon />
          </button>
        </div>

        <button
          data-tooltip-id="copy-tooltip"
          data-tooltip-content="Copy URL"
          onClick={() => navigator.clipboard.writeText(shortUrl.short_url)}
          className="w-6 h-6 text-gray-800 dark:text-white bg-transparent border-0"
        >
          <CopyClick />
        </button>
      </td>
    </tr>
  );

  // render the short table
  const renderShortUrlLinksTable = () => (
    <div>
      {shortUrlLinksData.length > 0 ? (
        <table className="w-full text-sm text-left">
          <thead className="text-xs uppercase">
            <tr>
              <th scope="col" className="px-4 py-3">
                Short Url Tag
              </th>
              <th scope="col" className="px-4 py-3">
                Expiry Date
              </th>
              <th scope="col" className="px-4 py-3">
                Short url
              </th>
            </tr>
          </thead>
          <tbody>{shortUrlLinksData.map(renderSingleShortUrlLink)}</tbody>
        </table>
      ) : (
        <div className="flex items-center justify-center">
          <img src={NoData} className="w-1/2 h-1/2" />
        </div>
      )}
    </div>
  );

  // render the table header
  const renderTableHeader = () => (
    <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
      <div className="flex items-center flex-1 space-x-4">
        <h5>
          <span className="text-black-500">All Short URL Links</span>
        </h5>
      </div>
      <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
        <button
          className="block text-white bg-blue-700 hover:bg-transparent hover:text-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          type="button"
          onClick={toggleVisibility}
        >
          Create New Link
        </button>
      </div>
    </div>
  );

  // render list of short_urls
  const renderListOfShortUrlLink = () => (
    <div>
      <div>
        <section>
          <div>
            <div className="relative overflow-hidden sm:rounded-lg">
              {renderTableHeader()}
              {renderFilter()}
              <div className="overflow-x-auto">{renderShortUrlLinksTable()}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
  const renderShortUrls = () => {
    const title = 'Hridaya Short URL Links';
    return (
      <CommonHeader loading={loading} title={title} meta_title="Hridaya Short URL">
        {showModal ? renderShortUrlLinkModel() : null}
        {showMoreInfo ? renderMoreInfo() : null}
        {!loading ? renderListOfShortUrlLink() : null}
      </CommonHeader>
    );
  };
  return renderShortUrls();
};

export default ShortUrlLinks;
