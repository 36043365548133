import React, { Component } from 'react';
import { gql } from 'graphql-request';
import { Helmet } from 'react-helmet';
import CustomLoading from '../util_components/custom_loading';
import { graphBlogPostApi } from './graph-client';
import './post.css';

export default class Post extends Component {
  state = {
    loading: false,
    metadata: {},
    post: {}
  };

  async componentDidMount() {
    const { match } = this.props;
    const { slug } = match.params;
    if (slug) {
      try {
        const resp = await graphBlogPostApi(slug);
        console.log(resp);
        this.setState({ post: resp, loading: false });
      } catch (e) {
        console.log(e);
        this.go_to_blog();
      }
    } else this.go_to_blog();
  }

  go_to_blog = () => this.props.history.push('/blog');

  render_intro = () => {
    const img_url =
      'https://i1.wp.com/blog.myyogateacher.com/wp-content/uploads/2020/06/IMG_20200319_001624_146.jpg?w=627&ssl=1';
    const user_img =
      'https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png';
    return (
      <div className="blog-post-top-section">
        <img src={img_url} />
        <div className="blog-post-intro">
          <div className="blog-post-intro-in">
            <h3>Practice And All Is Coming</h3>
            <p className="blog-post-sub-title">5 min read</p>
            <div className="blog-post-author">
              <div>
                <img src={user_img} />
              </div>
              <p>Shadiya . Date</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render_content = () => (
    <div className="blog-post-con">
      My yoga journey started in the year 2010 when I was introduced to few yoga stretches post a
      dance rehearsal in college. I didn’t think much of it back then and little did I know, it is
      going to change my life ten years down the line. After finishing college, I joined a MNC with
      big aspirations and certain goals in my mind. I was not prepared for all the challenges I had
      to endure in order to achieve those goals I had set for myself . Corporate life became
      increasingly difficult emotionally and physically. I found myself walking in to a yoga class
      to combat the stress and anxiety. After the first few sessions, I started to connect with
      practice and thoroughly enjoying that one hour of practice. I would look forward to learning
      something new every session and I would be excited about my small achievements in each class.
      I found myself dreaming of postures amidst busy work calls and meetings. Later that year,
      against all the odds I quit my job to pursue Teacher’s training course in Hatha yoga.
      Practicing and learning various aspects of yoga with like minded people from all over the
      world was a memorable experience. I consider teachers training as my first real learning
      experience in yoga and I haven’t stopped learning or practicing since then. I believe in
      consistent self practice or sadhana as the key to being a better teacher. I am constantly
      learning from teachers who have more experience than me. I help my students to understand
      their body , breath and alignment. I thoroughly enjoy the process of sharing my knowledge to
      students who are eager to learn and practice. I am thrilled to be joining this platform to
      share my learnings and looking forward to this new chapter in my yoga journey.
    </div>
  );

  render() {
    const { loading } = this.state;
    if (loading) {
      return <CustomLoading />;
    }
    return (
      <div className="blog-post-container">
        {this.render_intro()}
        {this.render_content()}
      </div>
    );
  }
}
