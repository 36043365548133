import * as t from '../action_types';

const initialState = {
  get_all_students_status: 'none',
  future_sessions_status: 'none',
  past_sessions_status: 'none',
  zoom_signature_status: 'none',
  get_templates_status: 'none',
  replacement_sessions_status: 'none',
  permissions_status: 'none',
  membership_status: 'none',
  student_search_status: 'none',
  config_status: 'none',
  past_group_sessions_status: 'none',
  future_group_sessions_status: 'none',
  future_friends_sessions_status: 'none',
  past_friends_sessions_status: 'none',
  avail_all_status: 'none',
  class_types_status: 'none',
  worshops_status: 'none',
  show_member_error_popup: false,
  membership_error_response: '',
  pause_error_response: '',
  show_pause_error: false
};

const set_loading_variable = (state, payload) => ({
  ...state,
  [payload.key]: payload.payload
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_loading_variable:
      return set_loading_variable(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
