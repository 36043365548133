import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../../util_components/common_header';
import { moment_format_tz, check_null_text, check_bool_flag } from '../../../../util_functions';
import {
  day_format,
  time_format,
  custom_data_configs,
  split_test_categories
} from '../../../../constants';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import CustomModal from '../../../util_components/custom_modal';
import CustomInput from '../../../util_components/custom_input';
import Button from '../../../util_components/button';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import * as actions from '../../../../redux/action_creators';

import '../st.css';

const choice_active = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 }
];

class SplitTest extends Component {
  state = {
    loading: true,
    loaded: false,
    test_details: {},
    choices: [],
    edit_open: false,
    modal_open: false,
    modal_error: false,
    is_active: '',
    weight: 0,
    tag: '',
    name: '',
    description: '',
    test_next_page_url: '',
    start_date: '',
    end_date: '',
    test_starting_page: '',
    first_success_metric_name: '',
    second_success_metric_name: '',
    third_success_metric_name: '',
    fourth_success_metric_name: '',
    fifth_success_metric_name: '',
    image_url: '',
    optimize_test_id: '',
    add_config_key: '',
    add_config_type: 'string',
    data_config: [],
    data_conf_uuid: '',
    add_config_open: false,
    edit_config_open: false,
    custom_data_key: '',
    custom_data_value: '',
    custom_data: [],
    choice_edit_index: -1,
    choice_edit_value: '',
    choice_edit_key: '',
    optimize_test_variant: '',
    category: ''
  };

  go_back = () => this.props.history.goBack();

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('uuid');
    if (uuid) {
      this.setState({ uuid }, () => this.load_data());
    } else {
      this.props.history.push('/split_testing');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { uuid } = this.state;
      const url = get_api_url('get_split_test_details');
      const payload = { split_test_uuid: uuid };
      post_api(url, payload, true)
        .then((res) => {
          const test_details = res.data.details;
          this.setState(
            {
              test_details,
              distribution_metric: test_details.distribution_metric,
              choices: res.data.choices,
              loaded: true,
              custom_data: test_details.custom_data ? test_details.custom_data : {}
            },
            () => this.load_data_config()
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  load_data_config = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_split_test_config');
      const payload = { split_test_uuid: this.state.uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ data_config: [...res.data.data_config], loading: false });
        })
        .catch((e) => {
          console.log(e);
        });
    });

  render_single_choice = (choice) => {
    const { distribution_metric } = this.state;
    return (
      <div className="ct-row" key={choice.uuid}>
        <div className="ct-col">{choice.name}</div>
        <div className="ct-col">{choice.tag}</div>
        {distribution_metric === 'DAY_OF_THE_MONTH' ? (
          <div className="ct-col">{choice.distribution_pattern}</div>
        ) : (
          <div className="ct-col">{choice.weight}</div>
        )}
        <div className="ct-col">
          {choice.is_active === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">
          <div>{moment(choice.created_date).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(choice.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col">
          <button onClick={() => this.open_choice_details(choice.uuid)}>Details</button>
        </div>
      </div>
    );
  };

  open_choice_details = (uuid) => {
    const search = `?uuid=${uuid}`;
    this.props.history.push({
      pathname: '/split_testing/test/choice',
      search
    });
  };

  render_choices = () => {
    const { choices, distribution_metric } = this.state;
    if (choices.length > 0) {
      return (
        <>
          <h4>Choices</h4>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Name</div>
              <div className="ct-col">Tag</div>
              {distribution_metric === 'DAY_OF_THE_MONTH' ? (
                <div className="ct-col">Metric</div>
              ) : (
                <div className="ct-col">weight</div>
              )}
              <div className="ct-col">Active</div>
              <div className="ct-col">Created</div>
              <div className="ct-col">Details</div>
            </div>
            {choices.map(this.render_single_choice)}
          </div>
        </>
      );
    }
    return null;
  };

  render_custom_data = () => {
    const { test_details } = this.state;
    const custom_data = test_details.custom_data ? test_details.custom_data : {};
    const cus_keys = Object.keys(custom_data);
    if (cus_keys.length > 0) {
      return (
        <div>
          <h3>Custom Data:</h3>
          <table>
            <tbody>
              {cus_keys.map((key, index) => (
                <tr className="table-row-left-align" key={key + index}>
                  <td className="teacher-table-detail">{key}:</td>
                  <td>{custom_data[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  render_data_config = () => {
    const { data_config } = this.state;
    if (data_config.length > 0) {
      return (
        <div>
          <h4>Data config:</h4>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Key</div>
              <div className="ct-col">Type</div>
              <div className="ct-col">Details</div>
            </div>
            {data_config.map((conf) => (
              <div className="ct-row" key={conf.uuid}>
                <div className="ct-col">{conf.data_key}</div>
                <div className="ct-col">{conf.data_type}</div>
                <div className="ct-col">
                  <button
                    onClick={() => this.edit_data_config(conf.data_key, conf.data_type, conf.uuid)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  render_test_details = () => {
    const details = this.state.test_details;
    const { choices } = this.state;
    let show_create_choice = true;
    if (details.distribution_metric === 'DAY_OF_THE_MONTH' && choices.length >= 2) {
      show_create_choice = false;
    }
    return (
      <div>
        <h3>About test:</h3>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Name:</td>
              <td>{details.name}</td>
              <td className="teacher-table-detail">Tag :</td>
              <td>{details.tag}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Start date :</td>
              <td>{moment_format_tz(details.start_date)}</td>
              <td className="teacher-table-detail">End date:</td>
              <td>{moment_format_tz(details.end_date)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Uuid:</td>
              <td>{details.uuid}</td>
              <td className="teacher-table-detail">Start page :</td>
              <td>{details.test_starting_page}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Distribution:</td>
              <td>{details.distribution_metric}</td>
              <td className="teacher-table-detail">Active:</td>
              <td>{check_bool_flag(details.is_active)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">First metric:</td>
              <td>{details.first_success_metric_name}</td>
              <td className="teacher-table-detail">Second metric:</td>
              <td>{check_null_text(details.second_success_metric_name)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Third metric:</td>
              <td>{check_null_text(details.third_success_metric_name)}</td>
              <td className="teacher-table-detail">Fourth metric:</td>
              <td>{check_null_text(details.fourth_success_metric_name)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Fifth metric:</td>
              <td>{check_null_text(details.fifth_success_metric_name)}</td>
              <td className="teacher-table-detail">Optimize test id:</td>
              <td>{check_null_text(details.optimize_test_id)}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Category:</td>
              <td>{check_null_text(details.category)}</td>
              <td className="teacher-table-detail" />
              <td />
            </tr>
          </tbody>
        </table>
        {this.render_custom_data()}
        {this.render_data_config()}
        {show_create_choice ? <button onClick={this.open_modal}>Create choice</button> : null}
        <button onClick={this.open_edit}>Edit test</button>
        <button onClick={this.add_data_config}>Add data config</button>
        <div style={{ margin: '20px 0' }}>
          Test Description:
          <div style={{ margin: '5px 0', fontSize: '14px', letterSpacing: '0.3px' }}>
            {check_null_text(details.description)}
          </div>
          {this.render_choices()}
        </div>
      </div>
    );
  };

  add_data_config = () =>
    this.setState({
      add_config_open: true,
      add_config_key: '',
      add_config_type: 'string'
    });

  edit_data_config = (key, type, uuid) =>
    this.setState({
      edit_config_open: true,
      add_config_key: key,
      add_config_type: type,
      data_conf_uuid: uuid
    });

  cancel_edit_choice = () => {
    this.setState({ custom_edit_value: '', choice_edit_index: -1, custom_edit_key: '' });
  };

  save_edit_choice = () => {
    const { custom_edit_value, custom_edit_key, custom_data } = this.state;
    const new_custom_data = { ...custom_data, [custom_edit_key]: custom_edit_value };
    this.setState({
      custom_edit_value: '',
      choice_edit_index: -1,
      custom_edit_key: '',
      custom_data: new_custom_data
    });
  };

  add_key_value = () => {
    const { custom_data_key, custom_data_value } = this.state;
    const custom_data = { ...this.state.custom_data, [custom_data_key]: custom_data_value };
    this.setState({ custom_data, custom_data_key: '', custom_data_value: '' });
  };

  handle_date_change = (name, val) => this.setState({ [name]: val, modal_error: false });

  render_create_test = () => {
    const {
      tag,
      name,
      description,
      test_starting_page,
      first_success_metric_name,
      image_url,
      custom_data,
      choice_edit_index,
      second_success_metric_name,
      third_success_metric_name,
      modal_error,
      is_active,
      custom_data_key,
      custom_data_value,
      optimize_test_id,
      fourth_success_metric_name,
      fifth_success_metric_name,
      start_date,
      end_date,
      custom_edit_value,
      category
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    const show_edit = choice_edit_index === -1;
    return (
      <div>
        <h2>{`Edit split test (in ${curr_timezone})`}</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CustomDatePicker
            label="*Start Date"
            value={start_date}
            handleChange={(val) => this.handle_date_change('start_date', val)}
          />
          <CustomDatePicker
            label="*End Date"
            value={end_date}
            handleChange={(val) => this.handle_date_change('end_date', val)}
            minDate={start_date}
          />
          <CustomInput
            label="*Enter tag"
            onChange={this.onCommonChange}
            name="tag"
            value={tag}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Start page"
            onChange={this.onCommonChange}
            name="test_starting_page"
            value={test_starting_page}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Optimize test id"
            onChange={this.onCommonChange}
            name="optimize_test_id"
            value={optimize_test_id}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*First success metric"
            onChange={this.onCommonChange}
            name="first_success_metric_name"
            value={first_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomSingleSelect
            label="Change active"
            options={choice_active}
            onChange={this.onCommonChange}
            value={is_active}
            style={{ width: '260px' }}
            name="is_active"
          />
          <CustomInput
            label="Second success metric"
            onChange={this.onCommonChange}
            name="second_success_metric_name"
            value={second_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Third success metric"
            onChange={this.onCommonChange}
            name="third_success_metric_name"
            value={third_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Fourth success metric"
            onChange={this.onCommonChange}
            name="fourth_success_metric_name"
            value={fourth_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Fifth success metric"
            onChange={this.onCommonChange}
            name="fifth_success_metric_name"
            value={fifth_success_metric_name}
            style={{ width: '260px' }}
          />
          <CustomSingleSelect
            label="Category"
            options={split_test_categories}
            onChange={this.onCommonChange}
            value={category}
            style={{ width: '260px' }}
            name="category"
          />
          <div style={{ width: '100%', margin: '10px 0' }}>
            Custom Data:
            {Object.keys(custom_data).map((key_val, index) => {
              if (choice_edit_index === index) {
                return (
                  <div
                    key={key_val + index}
                    style={{ display: 'flex', width: '100%' }}
                    className="sp-test-cus-edit"
                  >
                    <div className="sp-test-edit-key">{key_val}</div>
                    <div className="sp-test-edit-val">
                      <CustomInput
                        show_label={false}
                        onChange={this.onCommonChange}
                        name="custom_edit_value"
                        value={custom_edit_value}
                      />
                    </div>
                    <div className="sp-test-edit-btns">
                      <button onClick={this.save_edit_choice}>save</button>
                      <button onClick={this.cancel_edit_choice}>cancel</button>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={key_val + index}
                  style={{ display: 'flex', width: '100%' }}
                  className="sp-test-cus-edit"
                >
                  <div className="sp-test-edit-key">{key_val}</div>
                  <div className="sp-test-edit-val">{custom_data[key_val]}</div>
                  <div className="sp-test-edit-btns">
                    {show_edit ? (
                      <button
                        onClick={() =>
                          this.setState({
                            choice_edit_index: index,
                            custom_edit_value: custom_data[key_val],
                            custom_edit_key: key_val
                          })
                        }
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })}
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <CustomInput
                label="Data key"
                onChange={this.onCommonChange}
                name="custom_data_key"
                value={custom_data_key}
                style={{ width: '220px' }}
              />
              <CustomInput
                label="Data Value"
                onChange={this.onCommonChange}
                name="custom_data_value"
                value={custom_data_value}
                style={{ width: '220px' }}
              />
            </div>
            <Button disabled={!custom_data_key || !custom_data_value} onClick={this.add_key_value}>
              Add data
            </Button>
          </div>
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <button onClick={this.submit_edit}>Submit</button>
        <p style={err_sty}>* fields are compulsory</p>
      </div>
    );
  };

  open_edit = () => {
    const { test_details } = this.state;
    const start_date = moment(test_details.start_date).format('YYYY-MM-DD');
    const end_date = moment(test_details.end_date).format('YYYY-MM-DD');
    this.setState({
      tag: test_details.tag,
      name: test_details.name,
      description: test_details.description,
      start_date,
      end_date,
      edit_open: true,
      is_active: test_details.is_active,
      test_starting_page: test_details.test_starting_page,
      first_success_metric_name: test_details.first_success_metric_name,
      second_success_metric_name: test_details.second_success_metric_name,
      third_success_metric_name: test_details.third_success_metric_name,
      fourth_success_metric_name: test_details.fourth_success_metric_name,
      fifth_success_metric_name: test_details.fifth_success_metric_name,
      optimize_test_id: test_details.optimize_test_id,
      category: test_details.category
    });
  };

  open_modal = () => this.setState({ modal_open: true });

  close_modal = () =>
    this.setState({
      modal_open: false,
      tag: '',
      name: '',
      weight: 0,
      edit_config_open: false,
      description: '',
      test_next_page_url: '',
      start_date: '',
      end_date: '',
      category: '',
      test_starting_page: '',
      first_success_metric_name: '',
      is_active: '',
      optimize_test_variant: '',
      second_success_metric_name: '',
      third_success_metric_name: '',
      optimize_test_id: '',
      fourth_success_metric_name: '',
      fifth_success_metric_name: '',
      add_config_open: false,
      edit_open: false
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    let { value } = e.target;
    if (key === 'weight') {
      value = Math.min(value, 100);
    }
    this.setState({ [key]: value, modal_error: false });
  };

  submit_edit = () => {
    const {
      uuid,
      tag,
      name,
      description,
      test_starting_page,
      first_success_metric_name,
      category,
      second_success_metric_name,
      third_success_metric_name,
      is_active,
      custom_data,
      optimize_test_id,
      fourth_success_metric_name,
      fifth_success_metric_name,
      start_date,
      end_date,
      distribution_metric
    } = this.state;
    if (
      !!tag &&
      !!name &&
      !!start_date &&
      !!end_date &&
      !!first_success_metric_name &&
      !!description
    ) {
      const sel_start_date = moment(start_date).format('YYYY-MM-DD');
      const sel_end_date = moment(end_date).format('YYYY-MM-DD');
      const final_start_date = moment
        .tz(`${sel_start_date} 00:00:01`, this.props.iana_timezone)
        .valueOf();
      const final_end_date = moment
        .tz(`${sel_end_date} 23:59:59`, this.props.iana_timezone)
        .valueOf();
      const payload = {
        uuid,
        tag,
        name,
        description,
        test_starting_page,
        first_success_metric_name,
        second_success_metric_name,
        third_success_metric_name,
        distribution_metric,
        category,
        fourth_success_metric_name,
        fifth_success_metric_name,
        start_date: final_start_date,
        end_date: final_end_date,
        is_active,
        is_edit: 1,
        custom_data,
        optimize_test_id
      };
      const url = get_api_url('create_split_test');
      this.setState({ loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Split test edited');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  submit_test = () => {
    const {
      uuid,
      tag,
      name,
      description,
      weight,
      test_next_page_url,
      test_details,
      image_url,
      choices,
      optimize_test_variant
    } = this.state;
    if (!!tag && !!name && !!description) {
      this.setState({ loading: true }, () => {
        const url = get_api_url('create_split_test_choice');
        const payload = {
          split_test_uuid: uuid,
          tag,
          name,
          description,
          test_next_page_url,
          weight,
          image_url,
          optimize_test_variant
        };
        if (test_details.distribution_metric === 'DAY_OF_THE_MONTH') {
          if (choices.length === 0) {
            payload.distribution_pattern = 'EVEN_DAY';
          } else {
            payload.distribution_pattern = 'ODD_DAY';
          }
        }
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Choice created');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  render_create_choice = () => {
    const {
      tag,
      name,
      description,
      test_next_page_url,
      modal_error,
      is_active,
      image_url,
      weight,
      test_details,
      optimize_test_variant
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    return (
      <div>
        <h2>Create split test choice</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <CustomInput
            label="*Enter tag"
            onChange={this.onCommonChange}
            name="tag"
            value={tag}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
            style={{ width: '260px' }}
          />
          {test_details.distribution_metric === 'USERS' ? (
            <CustomInput
              label="*Enter weight"
              onChange={this.onCommonChange}
              name="weight"
              type="number"
              value={weight}
              style={{ width: '260px' }}
            />
          ) : null}
          <CustomInput
            label="Next page url"
            onChange={this.onCommonChange}
            name="test_next_page_url"
            value={test_next_page_url}
            style={{ width: '260px' }}
          />
          <CustomInput
            label="Optimize test variant"
            onChange={this.onCommonChange}
            name="optimize_test_variant"
            value={optimize_test_variant}
            style={{ width: '260px' }}
          />
          {test_details.distribution_metric === 'DAY_OF_THE_MONTH' ? (
            <CustomInput
              label="Image url"
              onChange={this.onCommonChange}
              name="image_url"
              value={image_url}
              style={{ width: '260px' }}
            />
          ) : null}
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <button onClick={this.submit_test}>Submit</button>
        <p style={err_sty}>* fields are compulsory</p>
      </div>
    );
  };

  save_new_data_config = () => {
    const { uuid, add_config_key, add_config_type, edit_config_open, data_conf_uuid } = this.state;
    this.setState({ loading: true }, () => {
      let url = get_api_url('add_split_test_config');
      const payload = {
        split_test_uuid: uuid,
        data_key: add_config_key,
        data_type: add_config_type
      };
      let success_message = 'config created';
      if (edit_config_open) {
        payload.config_uuid = data_conf_uuid;
        url = get_api_url('edit_split_test_config');
        success_message = 'config updated';
      }
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', success_message);
          this.setState({ add_config_open: false, edit_config_open: false });
          this.load_data_config();
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_add_config = () => {
    const { add_config_key, add_config_type, edit_config_open } = this.state;
    const title = edit_config_open ? `Edit custom data config` : `Add custom data config`;
    return (
      <div>
        <h4>{title}</h4>
        {!edit_config_open ? (
          <CustomInput
            label="*Enter Data Key"
            onChange={this.onCommonChange}
            name="add_config_key"
            value={add_config_key}
            style={{ width: '260px' }}
          />
        ) : (
          <p>Key: {add_config_key}</p>
        )}
        <CustomSingleSelect
          label="Select type"
          options={custom_data_configs}
          onChange={this.onCommonChange}
          value={add_config_type}
          style={{ width: '260px' }}
          name="add_config_type"
        />
        <Button disabled={!add_config_key} onClick={this.save_new_data_config}>
          Save
        </Button>
      </div>
    );
  };

  render() {
    const { loaded, loading, modal_open, edit_open, add_config_open, edit_config_open } =
      this.state;
    return (
      <CommonHeader loading={loading} title="Split Test Details" show_back>
        <CustomModal show={modal_open} close={this.close_modal}>
          {this.render_create_choice()}
        </CustomModal>
        <CustomModal show={edit_open} close={this.close_modal}>
          {this.render_create_test()}
        </CustomModal>
        <CustomModal show={add_config_open} close={this.close_modal}>
          {this.render_add_config()}
        </CustomModal>
        <CustomModal show={edit_config_open} close={this.close_modal}>
          {this.render_add_config()}
        </CustomModal>
        {loaded ? this.render_test_details() : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitTest);
