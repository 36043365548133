import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import { post_api, get_api } from '../../../redux/api_funcs';
import CustomLoading from '../../util_components/custom_loading';
import { get_api_url } from '../../../utils/urls';
import CustomCheckBox from '../../util_components/custom_checkbox';

class SessionQA extends Component {
  state = {
    loading: false,
    session_qa_issue_types: [],
    session_qa_open: false,
    session_qa_issues: []
  };

  open_qa_popup = () =>
    this.setState({ session_qa_open: true, loading: true }, () => {
      let url = get_api_url('/v2/cms_user/get_session_qa_issues', false, true);
      url += `?session_uuid=${this.props.session_uuid}`;
      get_api(url, true)
        .then((data) => {
          this.setState({
            session_qa_issue_types: data.data.data.issue_types,
            session_qa_issues: data.data.data.session_issues,
            loading: false
          });
        })
        .catch((e) => console.log(e));
    });

  save_qa_issues = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('/v2/cms_user/update_session_qa_issues', false, true);
      const payload = {
        session_uuid: this.props.session_uuid,
        updates: this.state.session_qa_issues
      };
      post_api(url, payload, true)
        .then(() => this.open_qa_popup())
        .catch((e) => console.log(e));
    });

  close_poup = () => {
    this.setState({ session_qa_open: false });
  };

  update_qa_issue = (issue) => {
    const { session_qa_issues } = this.state;
    const current_issue_index = session_qa_issues.findIndex((i) => i.type_id === issue.id);
    if (current_issue_index !== -1) {
      const current_issue = session_qa_issues[current_issue_index];
      current_issue.is_active = current_issue.is_active === 1 ? 0 : 1;
      this.setState({ session_qa_issues });
    } else {
      session_qa_issues.push({
        type_id: issue.id,
        is_active: 1,
        issue_details: ''
      });
      this.setState({ session_qa_issues });
    }
  };

  update_qa_issue_details = (type_id, issue_details) => {
    const { session_qa_issues } = this.state;
    const current_issue_index = session_qa_issues.findIndex((i) => i.type_id === type_id);
    if (current_issue_index !== -1) {
      const current_issue = session_qa_issues[current_issue_index];
      current_issue.issue_details = issue_details;
      this.setState({ session_qa_issues });
    }
  };

  render_single_issue = (issue) => {
    const { session_qa_issues } = this.state;
    const current_issue_index = session_qa_issues.findIndex((i) => i.type_id === issue.id);
    const current_issue = current_issue_index !== -1 ? session_qa_issues[current_issue_index] : {};
    return (
      <div className="ct-row" key={issue.id}>
        <div className="ct-col ct-qf ct-pl-10">
          <CustomCheckBox
            show_label={false}
            checked={current_issue.is_active === 1}
            onClick={() => this.update_qa_issue(issue)}
          />
        </div>
        <div className="ct-col ct-la">{issue.label}</div>
        <div className="ct-col">
          {current_issue.is_active === 1 ? (
            <textarea
              style={{ width: '100%', height: '100px' }}
              value={current_issue.issue_details}
              onChange={(e) => this.update_qa_issue_details(issue.id, e.target.value)}
            />
          ) : null}
        </div>
        <div className="ct-col">{current_issue.name}</div>
      </div>
    );
  };

  render_qa = () => {
    const { session_qa_issue_types } = this.state;
    const student_issues = session_qa_issue_types.filter((s) => s.category === 'student');
    const teacher_issues = session_qa_issue_types.filter((s) => s.category === 'teacher');
    const zoom_issues = session_qa_issue_types.filter((s) => s.category === 'zoom');
    return (
      <div>
        <h3>Session QA</h3>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h ct-sb">
            <div className="ct-col ct-qf" />
            <div className="ct-col ct-la">Label</div>
            <div className="ct-col">Details</div>
            <div className="ct-col">Last Updated BY</div>
          </div>
          <h4>Student Issues</h4>
          {student_issues.map(this.render_single_issue)}
          <h4>Teacher Issues</h4>
          {teacher_issues.map(this.render_single_issue)}
          <h4>Zoom Issues</h4>
          {zoom_issues.map(this.render_single_issue)}
        </div>
        <div>
          <Button onClick={this.save_qa_issues}>SAVE</Button>
        </div>
      </div>
    );
  };

  render() {
    const { loading, session_qa_open, session_qa_issues } = this.state;
    return (
      <>
        {loading ? <CustomLoading /> : null}
        <Button onClick={this.open_qa_popup}>QA</Button>
        <CustomModal
          show={session_qa_open}
          close={this.close_poup}
          inner_style={{ minWidth: '800px', minHeight: '600px' }}
        >
          {this.render_qa()}
        </CustomModal>
      </>
    );
  }
}

SessionQA.propTypes = {
  session_uuid: PropTypes.string.isRequired
};

export default SessionQA;
