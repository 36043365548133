import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { image_error_replace, check_null_text } from '../../../util_functions';
import { day_format, time_format } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class RecommendedClasses extends Component {
  state = {
    loading: false,
    sessions: [],
    page_status: ''
  };

  componentDidMount() {
    this.validate_url();
  }

  validate_url = () => {
    const { page_status } = this.props.match.params;
    console.log();
    if (!!page_status && (page_status === 'future' || page_status === 'past')) {
      this.setState({ page_status }, () => this.load_data());
    } else this.go_to_path('/groupsessions');
  };

  go_to_path = (path) => this.props.history.push(path);

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('list_group_sessions');
      const { page_status } = this.state;
      const payload = {
        is_recommended: 1,
        page: 0,
        sessions_type: page_status
      };
      post_api(url, payload, true)
        .then((resp) => this.setState({ loading: false, sessions: resp.data.sessions }))
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
              {session.teacher_name.split(' ')[0]}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-sm-font">{session.group_session_name}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">{session.participants}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.members_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_member_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {session.is_live === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        <div className="ct-col">
          <a
            className="link-btn"
            href={`/groupsessiondetails?session_uuid=${session.session_uuid}`}
          >
            Details
          </a>
        </div>
        {/* <div className="ct-col">
                    {session.is_premium_group_session === 1 ? 'Members only' : <Button onClick={() => this.open_confirm_premium(session.session_uuid)}>Promote</Button>}
                </div> */}
      </div>
    );
  };

  render_sessions = () => {
    const { sessions } = this.state;
    return (
      <div>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col">Teacher</div>
            <div className="ct-col">
              <div>Start Time</div>
              <div className="ct-sm-font">(Dur)</div>
            </div>
            <div className="ct-col">Name</div>
            <div className="ct-col ct-3rdf ct-sm-font">
              Total <span className="ct-vsm-font">Booked</span>
            </div>
            <div className="ct-col ct-3rdf ct-sm-font">
              Members <span className="ct-vsm-font">Booked</span>
            </div>
            <div className="ct-col ct-3rdf ct-sm-font">
              NM <span className="ct-vsm-font">Booked</span>
            </div>
            <div className="ct-col ct-3rdf ct-sm-font">Live</div>
            <div className="ct-col">Details</div>
          </div>
          {sessions.map(this.render_single_session)}
        </div>
      </div>
    );
  };

  render_buttons = () => {
    const { page_status } = this.state;
    return (
      <div>
        {page_status === 'future' ? (
          <a className="link-btn" href="/groupsessions/recommended/past">
            Past Recommended Classes
          </a>
        ) : (
          <a className="link-btn" href="/groupsessions/recommended/future">
            Future Recommended Classes
          </a>
        )}
      </div>
    );
  };

  render() {
    const { loading, page_status } = this.state;
    const title = `${page_status === 'future' ? 'Future' : 'Past'} Recommended Classes`;
    return (
      <CommonHeader loading={loading} title={title} show_back>
        {this.render_buttons()}
        {this.render_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedClasses);
