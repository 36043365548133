import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import CustomModal from '../../util_components/custom_modal';
import { image_error_replace, check_null_text } from '../../../util_functions';
import { day_format, time_format } from '../../../constants';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class BannedStudents extends Component {
  state = {
    loading: false,
    unban_open: false,
    banned_logs: [],
    unban_student_uuid: '',
    unban_student_name: '',
    ban_logs_open: false,
    ban_logs: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_banned_users_logs');
      post_api(url, null, true)
        .then((res) => {
          this.setState({ banned_logs: res.data.logs, loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_ban = (log) => {
    const { ban_logs_open } = this.state;
    const student_url = `/studentprofile?id=${log.uuid}`;
    const teacher_url = `/teacherprofile?id=${log.teacher_uuid}`;
    return (
      <div>
        <div className="ct-row">
          <div className="ct-col ct-la">
            <a className="link-no-dec" href={student_url}>
              <div className="profile-img-name">
                <img src={log.student_photo} onError={image_error_replace} alt="x" />
                {log.student_name}
              </div>
            </a>
          </div>
          <div className="ct-col ct-si ct-qf">
            {log.is_banned === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </div>
          <div className="ct-col ct-sm-font">
            {log.banned_by === 'TEACHER' ? (
              <a className="link-no-dec" href={teacher_url}>
                <div>{log.teacher_name}</div>
                {log.banned_by ? <div>({check_null_text(log.banned_by)})</div> : null}
              </a>
            ) : (
              <>
                <div>{check_null_text(log.care_name)}</div>
                {log.banned_by ? <div>({check_null_text(log.banned_by)})</div> : null}
              </>
            )}
          </div>
          <div className="ct-col ct-sm-font">{check_null_text(log.reason)}</div>
          <div className="ct-col ct-sm-font">
            {log.log_created_date ? (
              <>
                <div>
                  {moment(log.log_created_date).tz(this.props.iana_timezone).format(day_format)}
                </div>
                <div>
                  {moment(log.log_created_date).tz(this.props.iana_timezone).format(time_format)}
                </div>
              </>
            ) : (
              '--'
            )}
          </div>
          <div className="ct-col">
            {log.is_banned === 1 ? (
              <button
                onClick={() =>
                  this.setState({
                    unban_open: true,
                    unban_student_uuid: log.uuid,
                    unban_student_name: log.student_name
                  })
                }
                className="ct-small-btn"
              >
                Remove Ban
              </button>
            ) : (
              '--'
            )}
          </div>
          <div className="ct-col ct-si ct-colimg ct-qf">
            {log.logs.length > 0 ? (
              <img
                className={ban_logs_open ? 'reverse-expand' : 'normal-expand'}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
                onClick={() => this.setState({ ban_logs: log.logs, ban_logs_open: true })}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render_ban_logs = () => {
    const { ban_logs } = this.state;
    return (
      <div className="cus-table" style={{ marginBottom: '20px' }}>
        <div className="ct-row ct-h">
          <div className="ct-col ct-qf">Banned</div>
          <div className="ct-col">Updated By</div>
          <div className="ct-col">Reason</div>
          <div className="ct-col">Date</div>
        </div>
        {ban_logs.map((log1) => {
          const ban_teacher_url = `/teacherprofile?id=${log1.teacher_uuid}`;
          return (
            <div className="ct-row" key={log1.log_id}>
              <div className="ct-col ct-si ct-qf">
                {log1.log_banned == 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>
              <div className="ct-col ct-sm-font">
                {log1.banned_by === 'TEACHER' ? (
                  <a className="link-no-dec" href={ban_teacher_url}>
                    <div>{log1.teacher_name}</div>
                    {log1.banned_by ? <div>({check_null_text(log1.banned_by)})</div> : null}
                  </a>
                ) : (
                  <>
                    <div>{check_null_text(log1.care_name)}</div>
                    {log1.banned_by ? <div>({check_null_text(log1.banned_by)})</div> : null}
                  </>
                )}
              </div>
              <div className="ct-col ct-sm-font">{check_null_text(log1.reason)}</div>
              <div className="ct-col ct-sm-font">
                {log1.log_created_date ? (
                  <>
                    <div>
                      {moment(log1.log_created_date)
                        .tz(this.props.iana_timezone)
                        .format(day_format)}
                    </div>
                    <div>
                      {moment(log1.log_created_date)
                        .tz(this.props.iana_timezone)
                        .format(time_format)}
                    </div>
                  </>
                ) : (
                  '--'
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  close_modal = () =>
    this.setState({
      unban_open: false,
      unban_student_uuid: '',
      unban_student_name: '',
      ban_logs_open: false,
      ban_logs: []
    });

  unban_student = () =>
    this.setState({ loading: true }, () => {
      const { unban_student_uuid } = this.state;
      const url = get_api_url('unban_student');
      const payload = { student_uuid: unban_student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_unban = () => {
    const { unban_student_name } = this.state;
    return (
      <div>
        <h4>Are you sure you want to remove ban for student {unban_student_name}</h4>
        <button onClick={this.unban_student}>Submit</button>
        <button onClick={this.close_modal}>Cancel</button>
      </div>
    );
  };

  render() {
    const { loading, banned_logs, unban_open, ban_logs_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Banned Students">
        <CustomModal show={unban_open} close={this.close_modal}>
          {this.render_unban()}
        </CustomModal>
        <CustomModal show={ban_logs_open} close={this.close_modal}>
          {this.render_ban_logs()}
        </CustomModal>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-la">Student</div>
            <div className="ct-col ct-qf">Banned</div>
            <div className="ct-col">Updated By</div>
            <div className="ct-col">Reason</div>
            <div className="ct-col">Date</div>
            <div className="ct-col">Remove Ban</div>
            <div className="ct-col ct-qf">Logs</div>
          </div>
          {banned_logs.map(this.render_single_ban)}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BannedStudents);
