import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import { date_drop_for_report } from '../../../../constants';
import { get_date_filter_data } from '../../../../util_functions';
import { get_api_url } from '../../../../utils/urls';

class ConsolidatedReport extends Component {
  state = {
    start_date: '',
    end_date: '',
    loading: true,
    date_filter_type: 'THIS WEEK'
  };

  componentDidMount() {
    const start_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    const end_date = moment().format('YYYY-MM-DD');
    this.setState({ start_date, end_date, end_time }, () => {
      this.load_data();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.load_data();
    }
  }

  load_data = () => {
    const url = get_api_url('get_teacher_session_stats');
    const type = this.state.date_filter_type;
    const date_data = get_date_filter_data(type, this.state.start_date, this.state.end_date);
    const payload = {
      start_date: date_data.final_start_date,
      end_date: date_data.final_end_date,
      iana_timezone: this.props.iana_timezone
    };
    if (!(type === 'CUSTOM' && !this.state.submit_click)) {
      this.setState(
        {
          selected_start_date: date_data.final_start_date,
          selected_end_date: date_data.final_end_date,
          loading: true
        },
        () => {
          post_api(url, payload, true).then((result) =>
            this.setState({
              report_details: [...result.data.results],
              loading: false,
              first_load: false,
              submit_click: false
            })
          );
        }
      );
    }
  };

  render_data = () => {
    const details = this.state.report_details;
    return (
      <>
        {/* <Charts data={this.state.report_details} /> */}
        <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.3)' }}>
          The data is from <span style={{ color: 'black' }}>{this.state.selected_start_date}</span>{' '}
          to <span style={{ color: 'black' }}>{this.state.selected_end_date}</span>
        </div>
        <table>
          <tbody>
            <tr className="tr-border">
              <td rowSpan="2">Name</td>
              <td colSpan="3">Sessions</td>
              <td colSpan="3">Cancelled - Student</td>
              <td colSpan="3">Cancelled - Teacher</td>
              <td colSpan="3">No Show</td>
            </tr>
            <tr className="tr-border">
              <td>Total</td>
              <td>Scheduled</td>
              <td>Finished</td>
              <td>Total</td>
              <td>{'< 12Hr'}</td>
              <td>{'< 1Hr'}</td>
              <td>Total</td>
              <td>{'< 12Hr'}</td>
              <td>{'< 1Hr'}</td>
              <td>Both</td>
              <td>Student</td>
              <td>Teacher</td>
            </tr>
            {details.map((item) => (
              <tr key={item.uuid} className="tr-border-light">
                <td>{item.first_name}</td>
                <td>{item.total_sessions_scheduled}</td>
                <td>{item.scheduled_sessions}</td>
                <td>{item.finished_sessions}</td>
                <td>{item.student_cancelled}</td>
                <td>{item.student_cancelled_in_last_12_hour}</td>
                <td>{item.student_cancelled_in_last_one_hour}</td>
                <td>{item.teacher_cancelled}</td>
                <td>{item.teacher_cancelled_in_last_12_hour}</td>
                <td>{item.teacher_cancelled_in_last_one_hour}</td>
                <td>{item.student_and_teacher_no_show}</td>
                <td>{item.student_no_show}</td>
                <td>{item.teacher_no_show}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => this.load_data());

  submit_click = () => this.setState({ submit_click: true, loading: true }, () => this.load_data());

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="common-page-title">Teacher Session Stats</div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <div style={{ marginRight: '10px' }}>
            <CustomSingleSelect
              label="Select a date filter"
              options={date_drop_for_report}
              onChange={this.change_date_filter}
              value={this.state.date_filter_type}
              style={{ width: '200px' }}
            />
          </div>
          {this.state.date_filter_type === 'CUSTOM' ? (
            <>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="Start Date"
                  value={this.state.start_date}
                  handleChange={(val) => this.handle_date_change('start_date', val)}
                  maxDate={this.state.end_date}
                />
              </div>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="End Date"
                  value={this.state.end_date}
                  handleChange={(val) => this.handle_date_change('end_date', val)}
                  minDate={this.state.start_date}
                  maxDate={moment().format('YYYY-MM-DD')}
                />
              </div>
              <button onClick={this.submit_click}>Submit</button>
            </>
          ) : null}
        </div>
        {this.render_data()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Teacher Session Stats</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(ConsolidatedReport);
