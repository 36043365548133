import React, { Component } from 'react';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import CustomCheckBox from '../../util_components/custom_checkbox';

class CreateEditCampaign extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.set_campaign_details();
  }

  set_campaign_details = () => {
    const { campaign_details } = this.props;
    let current_campaign;
    if (this.props.current_campaign) {
      current_campaign = this.props.current_campaign;
    }
    this.setState({
      campaign_page_url: current_campaign ? current_campaign.page_url : '',
      campaign_page_tag: current_campaign ? current_campaign.page_tag : '',
      campaign_is_active: current_campaign ? current_campaign.is_active : 0,
      campaign_coupon_offer_id: campaign_details.id,
      campaign_coupon_offer_uuid: campaign_details.uuid,
      campaign_uuid: current_campaign ? current_campaign.uuid : null
    });
  };

  submit_campaign = () => {
    const {
      campaign_page_url,
      campaign_page_tag,
      campaign_is_active,
      campaign_coupon_offer_id,
      campaign_coupon_offer_uuid,
      campaign_uuid
    } = this.state;
    const payload = {
      page_url: campaign_page_url,
      page_tag: campaign_page_tag,
      is_active: campaign_is_active,
      coupon_offer_id: campaign_coupon_offer_id,
      coupon_offer_uuid: campaign_coupon_offer_uuid
    };
    if (campaign_uuid) {
      payload.campaign_uuid = campaign_uuid;
      this.props.edit_campaign(payload);
    } else {
      this.props.add_campaign(payload);
    }
  };

  onCustomChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  onCommonChange = (key, value) => {
    this.setState({ [key]: value });
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCustomChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  render() {
    const {
      campaign_page_url,
      campaign_page_tag,
      campaign_is_active,
      campaign_coupon_offer_id,
      campaign_coupon_offer_uuid
    } = this.state;
    return (
      <div>
        {this.state.loading ? <CustomLoading /> : null}
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <table className="campaign-page">
              <tbody>
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">Coupon Offer ID:</td>
                  <td>{campaign_coupon_offer_id}</td>
                </tr>
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">Coupon Offer UUID:</td>
                  <td>{campaign_coupon_offer_uuid}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <div>
              {this.render_custom_input('Enter Page URL:', 'campaign_page_url', campaign_page_url, {
                width: '550px'
              })}
              {this.render_custom_input('Enter Page Tag:', 'campaign_page_tag', campaign_page_tag, {
                width: '550px'
              })}
            </div>
            <div style={{ margin: '10px 0' }}>
              <CustomCheckBox
                label="Is Active"
                checked={campaign_is_active}
                onClick={() => this.toggle_check_box('campaign_is_active')}
              />
            </div>
          </div>
        </div>
        <br />
        <Button onClick={this.submit_campaign}>Submit</Button>
      </div>
    );
  }
}

export default CreateEditCampaign;
