import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import CustomLoading from '../../util_components/custom_loading';
import CustomModal from '../../util_components/custom_modal';
import CustomCheckbox from '../../util_components/custom_checkbox';
import CustomSingleSelect from '../../util_components/custom_single_select';
import Button from '../../util_components/button';
import { post_api } from '../../../redux/api_funcs';
import { image_error_replace } from '../../../util_functions';
import {
  session_duration_list,
  tickImageUrl,
  closeImageUrl,
  time_format,
  day_format_year
} from '../../../constants';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class RepeatSessions extends Component {
  state = {
    loading: false,
    student_uuid: '',
    sessions: [],
    group_sessions: [],
    is_group_cancel: false,
    cancel_session: false,
    cancel_all_future: false,
    session_uuid: '',
    old_start_date: null,
    start_date: null,
    duration: 0,
    edit_session: false,
    change_duration: false,
    new_duration: 60,
    repeat_group_session_participant_id: 0
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('uuid');
    if (uuid) {
      this.setState({ student_uuid: uuid }, () => this.load_data());
    } else {
      this.props.history.push('/users');
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_repeat_sessions');
      const payload = { student_uuid: this.state.student_uuid };
      post_api(url, payload, true).then((res) => {
        this.setState({
          sessions: res.data.yoga_sessions,
          group_sessions: res.data.group_sessions,
          loading: false
        });
      });
    });

  toggle_cancel = () => this.setState({ cancel_all_future: !this.state.cancel_all_future });

  cancel_repeat_session = () =>
    this.setState({ loading: true }, () => {
      const {
        session_uuid,
        cancel_all_future,
        is_group_cancel,
        repeat_group_session_participant_id
      } = this.state;
      let url = get_api_url('cancel_repeat_sessions');
      const payload = {
        repeat_session_uuid: session_uuid,
        cancel_all_future_sessions: cancel_all_future ? 1 : 0
      };
      if (is_group_cancel) {
        url = get_api_url('cancel_student_repeat_group_session');
        payload.repeat_group_session_participant_id = repeat_group_session_participant_id;
      }
      post_api(url, payload, true)
        .then(() => {
          this.close_cancel_session();
          this.props.set_notification_variable(true, 'success', 'Session cancelled');
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_cancel_sessions = () => {
    const label_style = { margin: '10px', fontSize: '17px' };
    return (
      <div>
        <h4>Are you sure you want to cancel?</h4>
        <CustomCheckbox
          label="Cancel all future sessions"
          checked={this.state.cancel_all_future}
          onClick={this.toggle_cancel}
          label_style={label_style}
        />
        <button onClick={this.cancel_repeat_session}>Confirm</button>
      </div>
    );
  };

  set_start_date = (date) => this.setState({ start_date: date });

  change_time = () => {
    const sel_time = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
    const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_repeat_session_time');
      const payload = {
        session_uuid: this.state.session_uuid,
        start_time: epoch_time,
        duration: this.state.duration
      };
      post_api(url, payload, true)
        .then(() => {
          this.load_data();
          this.close_cancel_session();
          this.props.set_notification_variable(
            true,
            'success',
            'Session time updated successfully'
          );
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  submit_change_duration = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_repeat_session_duration');
      const payload = {
        session_uuid: this.state.session_uuid,
        duration: this.state.new_duration
      };
      post_api(url, payload, true)
        .then(() => {
          this.load_data();
          this.close_cancel_session();
          this.props.set_notification_variable(true, 'success', 'Duration updated successfully');
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  render_change_duration = () => {
    const { duration, new_duration } = this.state;
    return (
      <div>
        <h4>Change Duration</h4>
        <CustomSingleSelect
          label="Select session duration"
          options={session_duration_list}
          onChange={this.onCommonChange}
          name="new_duration"
          value={new_duration}
          style={{ width: '250px' }}
        />
        <Button disabled={duration == new_duration} onClick={this.submit_change_duration}>
          Submit
        </Button>
      </div>
    );
  };

  render_edit_sessions = () => {
    const { start_date, old_start_date } = this.state;
    const old_time = moment(old_start_date)
      .tz(this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const new_time = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
    return (
      <div style={{ minHeight: '400px' }}>
        <h3>Change session time</h3>
        <DatePicker
          selected={start_date}
          onChange={(date) => this.set_start_date(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
        <div>
          <Button disabled={old_time === new_time} onClick={this.change_time}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  cancel_session = (uuid, is_group_cancel, repeat_group_session_participant_id) => {
    this.setState({
      cancel_session: true,
      session_uuid: uuid,
      is_group_cancel,
      repeat_group_session_participant_id
    });
  };

  edit_session = (uuid, repeat_time, duration) => {
    const curr_date = moment
      .tz(repeat_time, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const curr_time = moment(curr_date).toDate();
    this.setState({
      edit_session: true,
      session_uuid: uuid,
      start_date: curr_time,
      old_start_date: curr_time,
      duration
    });
  };

  change_duration = (uuid, repeat_time, duration) => {
    this.setState({ change_duration: true, session_uuid: uuid, duration, new_duration: duration });
  };

  close_cancel_session = () =>
    this.setState({
      cancel_session: false,
      session_uuid: '',
      cancel_all_future: false,
      edit_session: false,
      old_start_date: null,
      start_date: null,
      duration: 0,
      new_duration: 60,
      change_duration: false,
      repeat_group_session_participant_id: 0,
      is_group_cancel: false
    });

  render_group_sessions = () => {
    const { group_sessions } = this.state;
    return (
      <div>
        <h3>Group Repeat Sessions</h3>
        {group_sessions.length > 0 ? (
          <div>
            <div className="ct-row ct-h">
              <div className="ct-col">Class Page</div>
              <div className="ct-col">Teacher</div>
              <div className="ct-col">Start Time</div>
              <div className="ct-col ct-hf">Is Active</div>
              <div className="ct-col ct-hf">Cancel</div>
              <div className="ct-col ct-hf">Cancellation Time</div>
            </div>
            {group_sessions.map((session) => {
              const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
              const class_page_url = `/group_class_page/${session.group_class_page_slug}`;
              return (
                <div className="ct-row" key={session.id}>
                  <div className="ct-col ct-sm-font">
                    <a className="link-no-dec" href={class_page_url}>
                      <div className="profile-img-name">{session.group_class_page_name}</div>
                    </a>
                  </div>
                  <div className="ct-col ct-sm-font">
                    <a className="link-no-dec" href={teacher_url}>
                      <div className="profile-img-name">
                        <img src="" onError={image_error_replace} alt="x" />
                        {session.teacher_name}
                      </div>
                    </a>
                  </div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      Every{' '}
                      {moment(session.repeat_time)
                        .tz(this.props.iana_timezone)
                        .format('dddd, hh:mm A z')}
                    </div>
                  </div>
                  <div className="ct-col ct-si ct-hf">
                    {session.is_active === 1 ? (
                      <img src={tickImageUrl} alt="tick" />
                    ) : (
                      <img src={closeImageUrl} alt="close" />
                    )}
                  </div>
                  <div className="ct-col ct-hf">
                    {session.is_active === 1 ? (
                      <button
                        className="ct-small-btn"
                        onClick={() => this.cancel_session(null, true, session.id)}
                      >
                        Cancel
                      </button>
                    ) : (
                      '--'
                    )}
                  </div>
                  <div className="ct-col ct-hf ct-sm-font">
                    {session.is_active === 0 ? (
                      <>
                        <div>
                          {moment(session.cancellation_time)
                            .tz(this.props.iana_timezone)
                            .format(day_format_year)}
                        </div>
                        <div>
                          {moment(session.cancellation_time)
                            .tz(this.props.iana_timezone)
                            .format(time_format)}
                        </div>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  render_sessions = () => {
    const { sessions } = this.state;
    return (
      <div>
        <h3>1-on-1 Repeat Sessions</h3>
        {sessions.length > 0 ? (
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Teacher</div>
              <div className="ct-col">
                <div>Start Time</div>
                <div className="ct-sm-font">(Dur)</div>
              </div>
              <div className="ct-col ct-hf">Is Active</div>
              <div className="ct-col ct-hf">Cancel</div>
              <div className="ct-col ct-hf">Edit</div>
              <div className="ct-col ct-hf">Change Duration</div>
            </div>
            {sessions.map((session) => {
              const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
              return (
                <div className="ct-row" key={session.uuid}>
                  <div className="ct-col ct-sm-font">
                    <a className="link-no-dec" href={teacher_url}>
                      <div className="profile-img-name">
                        <img src="" onError={image_error_replace} alt="x" />
                        {session.teacher_name}
                      </div>
                    </a>
                  </div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      Every{' '}
                      {moment(session.repeat_time)
                        .tz(this.props.iana_timezone)
                        .format('dddd, hh:mm A z')}
                    </div>
                    <div>({session.duration} mins)</div>
                  </div>
                  <div className="ct-col ct-si ct-hf">
                    {session.is_active === 1 ? (
                      <img src={tickImageUrl} alt="tick" />
                    ) : (
                      <img src={closeImageUrl} alt="close" />
                    )}
                  </div>
                  <div className="ct-col ct-hf">
                    {session.is_active === 1 ? (
                      <button
                        className="ct-small-btn"
                        onClick={() => this.cancel_session(session.uuid, false)}
                      >
                        Cancel
                      </button>
                    ) : (
                      '--'
                    )}
                  </div>
                  <div className="ct-col ct-hf">
                    {session.is_active === 1 ? (
                      <button
                        className="ct-small-btn"
                        onClick={() =>
                          this.edit_session(
                            session.original_session_uuid,
                            session.repeat_epoch_time,
                            session.duration
                          )
                        }
                      >
                        Edit
                      </button>
                    ) : (
                      '--'
                    )}
                  </div>
                  <div className="ct-col ct-hf">
                    {session.is_active === 1 ? (
                      <button
                        className="ct-small-btn"
                        onClick={() =>
                          this.change_duration(
                            session.original_session_uuid,
                            session.repeat_epoch_time,
                            session.duration
                          )
                        }
                      >
                        Change
                      </button>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { change_duration } = this.state;
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="profile-go-back" onClick={() => this.props.history.goBack()}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Repeat sessions</div>
        </div>
        <CustomModal show={this.state.cancel_session} close={this.close_cancel_session}>
          {this.render_cancel_sessions()}
        </CustomModal>
        <CustomModal show={this.state.edit_session} close={this.close_cancel_session}>
          {this.render_edit_sessions()}
        </CustomModal>
        <CustomModal show={change_duration} close={this.close_cancel_session}>
          {this.render_change_duration()}
        </CustomModal>
        {this.render_sessions()}
        {this.render_group_sessions()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RepeatSessions);
