import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import { isMobile, isTablet } from 'react-device-detect';
import CustomModal from '../../util_components/custom_modal';
import CommonHeader from '../../util_components/common_header';
import CustomInput from '../../util_components/custom_input';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CreateEditWorkshop from './create_edit_workshop';
import { image_error_replace, check_bool_flag } from '../../../util_functions';
import { time_day_format, long_session_duration_list, audience_types } from '../../../constants';
import { post_api, api_with_method } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import Button from '../../util_components/button';
import RenderAllTags from '../content/components/render_tags';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link'
];
class WorkshopDetails extends Component {
  state = {
    workshop_uuid: '',
    loading: true,
    workshop_details: {},
    workshop_sessions: [],
    edit_workshop: false,
    create_session: false,
    create_session_error: false,
    is_active: '',
    price: '',
    create_err: false,
    session_name: '',
    session_description: '',
    session_start_time: '',
    session_duration: '60',
    workshop_participants: [],
    teachers_data: [],
    teacher_uuid: '',
    confirm_sync: false,
    download_attendance_open: false,
    add_chat_open: false,
    attendance_data: [],
    attendance_dates: [],
    add_chat_student_uuid: '',
    confirm_add_chat_participant: false,
    edit_video_description: false,
    video_description: '',
    workshop_comments: [],
    add_comment_open: false,
    del_comment_open: false,
    open_duplicate_workshop: false,
    duplicate_teacher_uuid: '',
    duplicate_workshop_sessions: [],
    comment_uuid: '',
    all_teachers: [],
    session_id: '',
    duplicate_start_date: null,
    duplicate_end_date: null,
    open_alias_edit: false,
    workshop_alias: '',
    association_type: 'WORKSHOP',
    show_add_free_participant: false,
    student_search_text: '',
    add_free_participant_uuid: '',
    booking_allow: false,
    is_booking_allow_msg: true,
    group_chat_channel_id: '',
    show_add_teacher_to_chat: false,
    teacher_uuid_to_add_in_chat: '',
    confirm_add_chat_teacher: false,
    error_message: '',
    show_error_msg: true
  };

  componentDidMount() {
    const { uuid } = this.props.match.params;
    if (uuid) {
      this.setState({ workshop_uuid: uuid }, () => {
        this.load_data();
      });
      if (this.props.teachers_list_status === 'success') {
        this.set_teachers_list();
      }
      if (this.props.teachers_list_status === 'none') {
        this.props.get_teachers_list();
      }
    } else {
      this.props.history.push('/workshops');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    try {
      if (this.props.match.params.uuid !== prevProps.match.params.uuid) {
        this.setState({ workshop_uuid: this.props.match.params.uuid }, () => {
          this.load_data();
        });
      }
    } catch (e) {}
  }

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list;
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ all_teachers: [...teachers_list] });
  };

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { workshop_uuid } = this.state;
      const url = get_api_url('get_workshop_details');
      const payload = { workshop_uuid };
      post_api(url, payload, true)
        .then((res) => {
          const workshop_details = { ...res.data.workshop };
          const teachers_data = [...res.data.teachers_data];
          const teacher_options = teachers_data.map((t) => ({
            label: t.teacher_name,
            value: t.uuid
          }));
          const workshop_participants = [...res.data.participants];
          const participant_options = workshop_participants.map((p) => ({
            label: `${p.first_name} ${p.last_name}`,
            value: p.uuid
          }));
          teacher_options.push({
            label: `${workshop_details.teacher.first_name} ${workshop_details.teacher.middle_name} ${workshop_details.teacher.last_name}`,
            value: workshop_details.teacher_uuid
          });
          this.setState({
            workshop_details,
            teacher_uuid: workshop_details.teacher_uuid,
            workshop_sessions: [...res.data.workshop_sessions],
            workshop_participants,
            teachers_data,
            teacher_options,
            participant_options,
            audience_type: workshop_details.audience_type,
            group_chat_channel_id: workshop_details.group_chat_channel_id,
            loading: false
          });
          this.load_workshop_comments();
        })
        .catch((e) => console.log(e));
    });
  };

  load_workshop_comments = () => {
    const url = get_api_url('workshop_comments');
    const payload = { workshop_uuid: this.state.workshop_uuid };
    api_with_method('post', url, payload, true)
      .then((res) => {
        this.setState({
          workshop_comments: [...res.data.workshop_comments],
          loading: false
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e);
      });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, create_err: false });
  };

  set_start_date = (key, date) => {
    this.setState({ [key]: date, create_err: false });
  };

  edit_workshop = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_workshop');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Workshop edited');
          this.close_workshop();
          this.load_data();
          this.close_edit_description();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  update_chat = () => {
    this.create_chat(1);
  };

  create_chat = (edit_chat = 0) =>
    this.setState({ loading: true }, () => {
      let url = get_api_url('create_chat_group');
      let success_msg = 'Chat created';
      if (edit_chat === 1) {
        url = get_api_url('update_chat_group');
        success_msg = 'Chat updated';
      }
      const details = this.state.workshop_details;
      const payload = { workshop_uuid: details.uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', success_msg);
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  sync_workshop = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('sync_workshop');
      const details = this.state.workshop_details;
      const payload = { workshop_uuid: details.uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Workshop synced');
          this.setState({ loading: false });
          this.close_sync();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_edit_workshop = () => this.setState({ edit_workshop: true });

  close_workshop = () => this.setState({ edit_workshop: false });

  open_create_session = () => this.setState({ create_session: true });

  close_create_session = () => this.setState({ create_session: false, session_start_time: '' });

  open_add_participant = () => this.setState({ add_chat_open: true });

  close_add_particpant = () =>
    this.setState({
      add_chat_open: false,
      add_chat_student_uuid: '',
      confirm_add_chat_participant: false
    });

  add_participant_to_group = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('add_member_to_group');
      const { add_chat_student_uuid, workshop_details } = this.state;
      const payload = {
        user_id: add_chat_student_uuid,
        group_id: workshop_details.group_chat_channel_id
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Participant added to group');
          this.setState({ loading: false });
          this.close_add_particpant();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_add_chat_participant = () => {
    const { participant_options, add_chat_student_uuid, confirm_add_chat_participant } = this.state;
    if (confirm_add_chat_participant) {
      const selected_participant = participant_options.find(
        (p) => p.value === add_chat_student_uuid
      );
      return (
        <div>
          <h4>{`Are you sure you want to add ${selected_participant.label} to group chat?`}</h4>
          <button onClick={this.add_participant_to_group}>Submit</button>
          <button onClick={() => this.setState({ confirm_add_chat_participant: false })}>
            Cancel
          </button>
        </div>
      );
    }
    return (
      <div>
        <h4>Add participant to chat</h4>
        <CustomSingleSelect
          label="Select participant"
          options={participant_options}
          onChange={this.onCommonChange}
          name="add_chat_student_uuid"
          value={add_chat_student_uuid}
          style={{ width: '250px' }}
        />
        <Button
          disabled={!add_chat_student_uuid}
          onClick={() => this.setState({ confirm_add_chat_participant: true })}
        >
          Confirm
        </Button>
        <button onClick={this.close_add_particpant}>Cancel</button>
      </div>
    );
  };

  open_duplicate_workshop = () => {
    const { workshop_sessions } = this.state;
    const duplicate_workshop_sessions = [];
    for (const session of workshop_sessions) {
      duplicate_workshop_sessions.push({
        uuid: session.uuid,
        workshop_session_name: session.workshop_session_name,
        workshop_session_description: session.workshop_session_description,
        duration: session.duration,
        start_time: null
      });
    }
    this.setState({
      duplicate_teacher_uuid: '',
      duplicate_workshop_sessions,
      open_duplicate_workshop: true,
      duplicate_start_date: null,
      duplicate_end_date: null
    });
  };

  toggle_alias = () =>
    this.setState({
      workshop_alias: this.state.workshop_details.alias,
      open_alias_edit: !this.state.open_alias_edit
    });

  open_add_teacher_to_chat = () => {
    this.setState({ show_add_teacher_to_chat: true });
  };

  close_add_teacher_to_chat = () => {
    this.setState({
      show_add_teacher_to_chat: false,
      teacher_uuid_to_add_in_chat: '',
      confirm_add_chat_teacher: false
    });
  };

  add_teacher_to_group_chat = () => {
    this.setState({ loading: true }, () => {
      const { teacher_uuid_to_add_in_chat, workshop_details } = this.state;
      const url = get_api_url('add_member_to_group');
      const payload = {
        user_id: teacher_uuid_to_add_in_chat,
        group_id: workshop_details.group_chat_channel_id
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Participant added to group');
          this.setState({ loading: false });
          this.close_add_teacher_to_chat();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_add_teacher_to_chat = () => {
    const { teacher_options, teacher_uuid_to_add_in_chat, confirm_add_chat_teacher } = this.state;
    if (confirm_add_chat_teacher) {
      const selected_teacher = teacher_options.find(
        (item) => item.value === teacher_uuid_to_add_in_chat
      );
      return (
        <div>
          <h4>{`Are you sure you want to add ${selected_teacher?.label} to group chat?`}</h4>
          <button onClick={this.add_teacher_to_group_chat}>Submit</button>
          <button onClick={() => this.setState({ confirm_add_chat_teacher: false })}>Cancel</button>
        </div>
      );
    }
    return (
      <>
        <h4>Add Teacher to chat</h4>
        <CustomSingleSelect
          label="Select Teacher"
          options={teacher_options}
          onChange={this.onCommonChange}
          name="teacher_uuid_to_add_in_chat"
          value={teacher_uuid_to_add_in_chat}
          style={{ width: '250px' }}
        />
        <Button
          disabled={!teacher_uuid_to_add_in_chat}
          onClick={() => this.setState({ confirm_add_chat_teacher: true })}
        >
          Confirm
        </Button>
        <button onClick={this.close_add_teacher_to_chat}>Cancel</button>
      </>
    );
  };

  open_add_free_participant = () => {
    this.setState({ show_add_free_participant: true });
  };

  close_add_free_participant = () => {
    this.setState(
      {
        show_add_free_participant: false,
        booking_allow: false,
        add_free_participant_uuid: '',
        student_search_text: '',
        show_error_msg: false
      },
      () => this.props.set_loading_variable('student_search_status', 'none')
    );
  };

  on_search_click = () => {
    const { student_search_text } = this.state;
    if (student_search_text.trim().length < 3) {
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.props.search_students({
        search_param: student_search_text
      });
    }
  };

  check_student_time_zone_and_booking_status = async (student_uuid, workshop_uuid) => {
    this.setState({ loading: true }, () => {
      if (this.state.booking_allow) {
        const url = get_api_url('subscribe_to_workshop');
        const payload = {
          student_uuid,
          workshop_uuid
        };
        api_with_method('post', url, payload, true)
          .then((res) => {
            this.close_add_free_participant();
            this.load_data();
          })
          .catch((e) => {
            this.setState({ error_message: e.response.data.message, show_error_msg: true });
            console.log(e.response.data.message);
          });
      }

      this.setState({ loading: false });
    });
  };

  onNameChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, create_err: false, loading: true }, () => {
      const url = get_api_url('get_student_data');
      const payload = {
        student_uuid: value
      };
      let time_zone = '';
      let booking_out_side_us = 0;
      post_api(url, payload, true)
        .then((result) => {
          time_zone = result.data?.iana_timezone;
          booking_out_side_us = result.data?.allow_booking_outside_us;

          if (time_zone.startsWith('Asia') && booking_out_side_us === 0) {
            this.setState({ booking_allow: false, loading: false, is_booking_allow_msg: false });
          } else {
            this.setState({ booking_allow: true, loading: false, is_booking_allow_msg: true });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  add_particpant = () => {
    this.setState({ loading: true }, () => {
      const { add_free_participant_uuid, workshop_uuid } = this.state;
      this.check_student_time_zone_and_booking_status(add_free_participant_uuid, workshop_uuid);
    });
  };

  render_add_free_participant = () => {
    const {
      student_search_text,
      add_free_participant_uuid,
      booking_allow,
      is_booking_allow_msg,
      show_error_msg,
      error_message
    } = this.state;
    const search_student_list = [];
    this.props.search_students_list.map((item) => {
      const { student_uuid } = item;
      if (student_uuid) {
        const fname = item.first_name ? item.first_name : '';
        const mname = item.middle_name ? item.middle_name : '';
        const lname = item.last_name ? item.last_name : '';
        search_student_list.push({
          label: `${fname} ${mname} ${lname} (${item.email})`,
          value: student_uuid
        });
      }
    });
    return (
      <>
        <h3>Add a participant</h3>
        <div className="display-flex-between-center">
          <CustomInput
            label="Enter student name or email"
            onChange={this.onCommonChange}
            name="student_search_text"
            value={student_search_text}
            style={{ width: '300px' }}
            onKeyPress={this.on_key_press}
          />
          <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
            Search student
          </Button>
        </div>
        {!!student_search_text && this.props.student_search_status === 'success' ? (
          <div>
            <CustomSingleSelect
              label="Select student"
              options={search_student_list}
              onChange={this.onNameChange}
              name="add_free_participant_uuid"
              value={add_free_participant_uuid}
              style={{ width: '250px' }}
            />
            <Button
              disabled={!add_free_participant_uuid || !booking_allow}
              onClick={() => this.add_particpant()}
            >
              Add participant
            </Button>
            {is_booking_allow_msg ? (
              ''
            ) : (
              <div className="text-red-600">
                Users from India are not allowed to book a class, please whitelist the account to
                book outside US from their hridaya profile and try again
              </div>
            )}
            {error_message ? <div className="text-red-600">{error_message}</div> : ''}
          </div>
        ) : null}
        <div />
      </>
    );
  };

  render_workshop_details = () => {
    const details = this.state.workshop_details;
    const { teachers_data, workshop_participants } = this.state;
    const { host } = window.location;
    const subdomain = host.split('.')[0];
    const url_domain =
      subdomain === 'hridaya'
        ? 'https://myyogateacher.com'
        : 'https://student-testenv4.myyogateacher.com';
    let audience_type = '';
    const aud_index = audience_types.findIndex((a) => a.value === details.audience_type);
    if (aud_index !== -1) audience_type = audience_types[aud_index].label;
    if (Object.keys(details).length > 0) {
      const teacher_url = `/teacherprofile?id=${details.teacher.uuid}`;
      return (
        <>
          <table>
            <tbody>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Title: </td>
                <td>{details.name}</td>
                <td className="teacher-table-detail">Workshop uuid:</td>
                <td>{details.uuid}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Teacher Name:</td>
                <td>
                  <a className="link-no-dec" href={teacher_url}>
                    {details.teacher.first_name}
                  </a>
                </td>
                <td className="teacher-table-detail">Active: </td>
                <td>{check_bool_flag(details.is_active)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Slug: </td>
                <td>{details.slug}</td>
                <td className="teacher-table-detail">Url Alias:</td>
                <td>{details.alias}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Start date: </td>
                <td>
                  {moment(details.start_date)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')}
                </td>
                <td className="teacher-table-detail">End date:</td>
                <td>
                  {moment(details.end_date)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')}
                </td>
              </tr>
              {details.audience_type != 4 ? (
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">Price: </td>
                  <td>{details.price}</td>
                  <td className="teacher-table-detail">Original Price:</td>
                  <td>{details.original_price}</td>
                </tr>
              ) : null}
              <tr className="table-row-left-align">
                {details.audience_type != 4 ? (
                  <>
                    <td className="teacher-table-detail">Member discount: </td>
                    <td>{details.discount_for_members}</td>
                  </>
                ) : (
                  <>
                    <td className="teacher-table-detail"> </td>
                    <td />
                  </>
                )}
                <td className="teacher-table-detail">Referral benefit:</td>
                <td>{details.referral_benefit}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Seats Limit: </td>
                <td>{details.seats_limit}</td>
                <td className="teacher-table-detail">Seats booked:</td>
                <td>{details.seats_enrolled}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Instalment amount: </td>
                <td>{details.instalment_price}</td>
                <td className="teacher-table-detail">No of instalments:</td>
                <td>{details.number_of_instalments}</td>
              </tr>
              {/* <tr className="table-row-left-align">
                                <td className="teacher-table-detail">Visibility on the list: </td>
                                <td>{details.is_unlisted === 1 ? 'Hide from the list' : 'Visible to everyone'}</td>
                                <td className="teacher-table-detail"></td>
                                <td></td>
                            </tr> */}
              {/* <tr className="table-row-left-align">
                                <td className="teacher-table-detail">Referral benefit:</td>
                                <td>{details.referral_benefit}</td>
                                <td className="teacher-table-detail"></td>
                                <td></td>
                            </tr> */}
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Audience type: </td>
                <td>{audience_type}</td>
                <td className="teacher-table-detail">Is credit card required?:</td>
                <td>{check_bool_flag(details.cc_required_for_bootcamps)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Visibile to Newbie: </td>
                <td>{check_bool_flag(details.visible_to_newbie)}</td>
                <td className="teacher-table-detail">Visibile to Beginner:</td>
                <td>{check_bool_flag(details.visible_to_beginner)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Visibile to Intermediate: </td>
                <td>{check_bool_flag(details.visible_to_intermediate)}</td>
                <td className="teacher-table-detail">Visibile to Advanced:</td>
                <td>{check_bool_flag(details.visible_to_advanced)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Visibile to Members: </td>
                <td>{check_bool_flag(details.visible_to_members)}</td>
                <td className="teacher-table-detail" />
                <td />
              </tr>
              {details.visible_to_members === 1 && details.audience_type == 4 ? (
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">Member crossed price: </td>
                  <td>{details.member_original_price}</td>
                  <td className="teacher-table-detail">Member discounted price</td>
                  <td>{details.member_discounted_price}</td>
                </tr>
              ) : null}
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Visibile to Non Members: </td>
                <td>{check_bool_flag(details.visible_to_non_members)}</td>
                <td className="teacher-table-detail" />
                <td />
              </tr>
              {details.visible_to_non_members === 1 && details.audience_type == 4 ? (
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">NonMember crossed price: </td>
                  <td>{details.non_member_original_price}</td>
                  <td className="teacher-table-detail">NonMember discounted price</td>
                  <td>{details.non_member_discounted_price}</td>
                </tr>
              ) : null}
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Visibile to trial users: </td>
                <td>{check_bool_flag(details.visible_to_trial_users)}</td>
                <td className="teacher-table-detail" />
                <td />
              </tr>
              {details.visible_to_trial_users === 1 && details.audience_type == 4 ? (
                <tr className="table-row-left-align">
                  <td className="teacher-table-detail">Trial user crossed price: </td>
                  <td>{details.trial_user_original_price}</td>
                  <td className="teacher-table-detail">Trial user discounted price</td>
                  <td>{details.trial_user_discounted_price}</td>
                </tr>
              ) : null}
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Show course schedule: </td>
                <td>{check_bool_flag(details.show_course_schedule)}</td>
                <td className="teacher-table-detail">Show about teacher: </td>
                <td>{check_bool_flag(details.show_about_teacher)}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">List thumbnail:</td>
                <td colSpan="3">
                  {details.list_thumbnail ? (
                    <a href={details.list_thumbnail} target="_blank" rel="noopener noreferrer">
                      {details.list_thumbnail}
                    </a>
                  ) : null}
                </td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Banner image:</td>
                <td colSpan="3">
                  {details.banner_image ? (
                    <a href={details.banner_image} target="_blank" rel="noopener noreferrer">
                      {details.banner_image}
                    </a>
                  ) : null}
                </td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Video title:</td>
                <td colSpan="3">{details.video_title}</td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Video url:</td>
                <td colSpan="3">
                  {details.video_url ? (
                    <a href={details.video_url} target="_blank" rel="noopener noreferrer">
                      {details.video_url}
                    </a>
                  ) : null}
                </td>
              </tr>
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Video thumbnail url:</td>
                <td colSpan="3">
                  {details.video_thumbnail_url ? (
                    <a href={details.video_thumbnail_url} target="_blank" rel="noopener noreferrer">
                      {details.video_thumbnail_url}
                    </a>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
          <>
            <button onClick={this.open_edit_workshop}>Edit</button>
            <button onClick={this.open_edit_description}>Edit Workshop Details</button>
            <button onClick={this.open_create_session}>Create session</button>
            <button onClick={this.open_sync}>Sync Workshop</button>
            <a
              className="link-btn"
              href={`${url_domain}/workshops/${details.slug}?preview=true`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
            {!details.group_chat_channel_id ? (
              <button onClick={this.create_chat} style={{ float: 'right' }}>
                Create group chat channel
              </button>
            ) : (
              <button onClick={this.update_chat} style={{ float: 'right' }}>
                Update group chat channel
              </button>
            )}
            <button onClick={this.open_download_attendance}>Attendance</button>
            <button onClick={this.toggle_alias}>{`${details.alias ? 'Edit' : 'Add'} Alias`}</button>
            <button onClick={this.open_duplicate_workshop}>Duplicate Workshop</button>
            {workshop_participants.length > 0 ? (
              <button onClick={this.open_add_participant}>Add particpant to chat</button>
            ) : null}
            {this.props.config_values.user_access === 'SUPER_USER' ? (
              <button onClick={this.open_add_free_participant}>Add a free participant</button>
            ) : null}
            <button onClick={this.open_add_teacher_to_chat}>Add teacher to chat</button>
          </>

          <div style={{ margin: '20px 0' }}>
            Workshop Description:
            <div
              style={{
                margin: '5px 0',
                fontSize: '14px',
                letterSpacing: '0.3px'
              }}
            >
              {details.description}
            </div>
          </div>

          <div style={{ margin: '20px 0' }}>
            Workshop Details:
            <div
              style={{
                margin: '5px 0',
                fontSize: '14px',
                letterSpacing: '0.3px'
              }}
              dangerouslySetInnerHTML={{ __html: details.video_description }}
            />
          </div>
          <p>Co - Teachers:</p>
          <div className="page-teachers">
            {teachers_data.map((teac) => {
              const teacher_url = `/teacherprofile?id=${teac.uuid}`;
              return (
                <div className="page-teacher" key={teac.uuid}>
                  <a className="link-no-dec" href={teacher_url} key={teac.uuid}>
                    <div className="profile-img-name">
                      <img src={teac.profile_photo} alt="i" onError={image_error_replace} />
                      {teac.teacher_name}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </>
      );
    }
    return null;
  };

  open_sync = () => this.setState({ confirm_sync: true });

  set_create_time = (date) => {
    this.setState({ session_start_time: date });
  };

  set_start_time = (uuid, date) => {
    const { duplicate_workshop_sessions } = this.state;
    const session_index = duplicate_workshop_sessions.findIndex((s) => s.uuid === uuid);
    if (session_index !== -1) {
      const session = duplicate_workshop_sessions[session_index];
      session.start_time = date;
      this.setState({ duplicate_workshop_sessions });
    }
  };

  create_workshop_session = () => {
    const {
      session_name,
      session_description,
      session_start_time,
      teacher_uuid,
      workshop_uuid,
      session_duration
    } = this.state;
    if (!!session_name && !!session_start_time) {
      const sel_time = moment(session_start_time).format('YYYY-MM-DD HH:mm:ss');
      const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
      const paylaod = {
        teacher_uuid,
        workshop_uuid,
        start_time: epoch_time,
        name: session_name,
        description: session_description,
        duration: session_duration,
        device_type: isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop',
        app_platform: 'web_app',
        app_major_version: 'v1',
        app_minor_version: 'v2'
      };
      const url = get_api_url('create_workshop_session');
      this.setState({ loading: true }, () => {
        post_api(url, paylaod, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Workshop session created');
            this.setState({
              session_name: '',
              session_description: '',
              session_start_time: '',
              create_session: false
            });
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ create_session_error: true });
    }
  };

  render_create_session = () => {
    const {
      session_name,
      session_description,
      teacher_options,
      teacher_uuid,
      session_start_time,
      create_session_error,
      session_duration,
      audience_type
    } = this.state;

    const err_sty = create_session_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    return (
      <div>
        <h2>Create Workshop Session</h2>
        <div className="display-flex-between">
          <CustomInput
            label="Enter session name *"
            onChange={this.onCommonChange}
            name="session_name"
            value={session_name}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select teacher"
            options={teacher_options}
            onChange={this.onCommonChange}
            name="teacher_uuid"
            value={teacher_uuid}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <div>
            <div>{`Select time (${curr_timezone})`}</div>
            <DatePicker
              selected={session_start_time}
              onChange={(date) => this.set_create_time(date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
          <CustomSingleSelect
            label="Select session duration"
            options={long_session_duration_list}
            onChange={this.onCommonChange}
            name="session_duration"
            value={session_duration}
            style={{ width: '250px' }}
          />
        </div>
        <div>Enter session description:</div>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={session_description}
          name="session_description"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <p style={err_sty}>All fields are compulsory</p>
        <button onClick={this.create_workshop_session}>Submit</button>
      </div>
    );
  };

  open_session_viewer = (session_uuid) => {
    // const search = '?uuid=' + session_uuid;
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      // pathname: '/workshop/session',
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_single_workshop_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session['teacher.uuid']}`;
    const session_duration =
      session.duration > 120 ? `${session.duration / 60} hrs` : `${session.duration} min`;
    return (
      <div className="ct-row" key={session.uuid}>
        <div className="ct-col">
          {moment(session.start_time).tz(this.props.iana_timezone).format(time_day_format)}
        </div>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session['teacher.profile_photo']} onError={image_error_replace} alt="x" />
              {session['teacher.first_name']}
            </div>
          </a>
        </div>
        <div className="ct-col">{session.workshop_session_name}</div>
        <div className="ct-col ct-hf">{session_duration}</div>
        <div className="ct-col ct-hf">{session.status}</div>
        <div className="ct-col ct-hf">{session.workshop_session_number}</div>
        <div className="ct-col ct-hf">
          <button onClick={() => this.open_session_viewer(session.uuid)}>Details</button>
        </div>
      </div>
    );
  };

  render_workshop_sessions = () => {
    const { workshop_sessions } = this.state;
    if (workshop_sessions.length > 0) {
      return (
        <div style={{ marginTop: '30px' }}>
          Workshop Sessions:
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Start Date</div>
              <div className="ct-col">Teacher</div>
              <div className="ct-col">Name</div>
              <div className="ct-col ct-hf">Duration</div>
              <div className="ct-col ct-hf">Status</div>
              <div className="ct-col ct-hf">Number</div>
              <div className="ct-col ct-hf">Details</div>
            </div>
            {workshop_sessions.map(this.render_single_workshop_session)}
          </div>
        </div>
      );
    }
    return null;
  };

  render_single_workshop_participant = (par) => {
    const name = `${par.first_name} ${par.middle_name} ${par.last_name}`;
    const profile_url = `/studentprofile?id=${par.uuid}`;
    return (
      <div className="ct-row" key={par.uuid}>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={profile_url}>
            <div className="profile-img-name">
              <img src={par.profile_photo} alt="i" onError={image_error_replace} />
              {name}
            </div>
          </a>
        </div>
        <div className="ct-col">
          {moment(par.purchase_date).tz(this.props.iana_timezone).format(time_day_format)}
        </div>
        <div className="ct-col">{par.subscription_status}</div>
      </div>
    );
  };

  render_workshop_participants = () => {
    const { workshop_participants } = this.state;
    if (workshop_participants.length > 0) {
      return (
        <div style={{ marginTop: '30px' }}>
          Workshop Participants:
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Student</div>
              <div className="ct-col">Purchase Date</div>
              <div className="ct-col">Status</div>
            </div>
            {workshop_participants.map(this.render_single_workshop_participant)}
          </div>
        </div>
      );
    }
    return null;
  };

  close_sync = () => this.setState({ confirm_sync: false });

  render_sync_confirm = () => (
    <div>
      <h4>Are you sure you want to sync?</h4>
      <button onClick={this.sync_workshop}>Confirm</button>
      <button onClick={this.close_sync}>Cancel</button>
    </div>
  );

  open_download_attendance = () =>
    this.setState({ download_attendance_open: true, loading: true }, () => {
      const { workshop_uuid } = this.state;
      const payload = { workshop_uuid };
      const url = get_api_url('get_workshop_attendance');
      post_api(url, payload, true)
        .then((result) => {
          this.setState({
            loading: false,
            attendance_data: result.data.attendance,
            attendance_dates: result.data.attendance_dates
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_download_attendance = () => {
    const { workshop_details, attendance_data, attendance_dates } = this.state;
    const file_name = `${workshop_details.slug}_attendance`;
    return (
      <div>
        <h4>Download Attendance</h4>
        {!!attendance_data.length > 0 ? (
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={attendance_data} name="Attendance">
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Is Member" value="is_member" />
              {attendance_dates.map((a, index) => (
                <ExcelColumn label={a} value={a} key={index + a} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        ) : null}
      </div>
    );
  };

  open_edit_description = () =>
    this.setState({
      edit_video_description: true,
      video_description: this.state.workshop_details.video_description || ''
    });

  close_edit_description = () =>
    this.setState({
      edit_video_description: false,
      add_comment_open: false,
      session_id: '',
      del_comment_open: false,
      comment_uuid: ''
    });

  setValue = (video_description) => this.setState({ video_description });

  save_description = () => {
    const { workshop_details, video_description } = this.state;
    const url = get_api_url('update_workshop_description');
    this.setState({ loading: true }, () => {
      const payload = {
        workshop_uuid: workshop_details.uuid,
        video_description
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Workshop edited');
          this.close_workshop();
          this.load_data();
          this.close_edit_description();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_edit_video_description = () => {
    const { video_description } = this.state;
    return (
      <div>
        <h4>Edit Workshop Details:</h4>
        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          theme="snow"
          value={video_description}
          onChange={this.setValue}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link'],
                ['clean']
              ]
            }
          }}
          formats={formats}
        />
        <button onClick={this.save_description}>Save</button>
      </div>
    );
  };

  add_workshop_comment = () =>
    this.setState({ loading: true }, () => {
      const { session_id, workshop_uuid } = this.state;
      const url = get_api_url('workshop_comments');
      const payload = { workshop_uuid, session_id };
      api_with_method('patch', url, payload, true)
        .then((res) => {
          this.setState({
            workshop_comments: [...res.data.workshop_comments],
            loading: false
          });
          this.close_edit_description();
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
        });
    });

  delete_comment = () =>
    this.setState({ loading: true }, () => {
      const { workshop_uuid, comment_uuid } = this.state;
      const url = get_api_url('workshop_comments');
      const payload = { workshop_uuid, workshop_comment_uuid: comment_uuid };
      api_with_method('delete', url, payload, true)
        .then((res) => {
          this.setState({
            workshop_comments: [...res.data.workshop_comments],
            loading: false
          });
          this.close_edit_description();
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
        });
    });

  render_workshop_comments = () => {
    const { workshop_comments } = this.state;
    return (
      <div>
        Workshop Comments:
        <button onClick={() => this.setState({ add_comment_open: true })}>Add Comment</button>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-hf">Student</div>
            <div className="ct-col ct-qf">Rating</div>
            <div className="ct-col">Review</div>
            <div className="ct-col ct-hf">Date</div>
            <div className="ct-col ct-qf">Delete</div>
          </div>
          {workshop_comments.map((c) => {
            const student_url = `/studentprofile?id=${c.student_uuid}`;
            return (
              <div className="ct-row" key={c.id}>
                <div className="ct-col ct-hf">
                  <a className="link-no-dec" href={student_url}>
                    <div className="profile-img-name">
                      <img src={c.student_picture} onError={image_error_replace} alt="x" />
                      {c.first_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col ct-qf">{c.rating}</div>
                <div className="ct-col">{c.review}</div>
                <div className="ct-col ct-hf">{c.review_formatted_date}</div>
                <div className="ct-col ct-qf">
                  <button
                    onClick={() =>
                      this.setState({
                        del_comment_open: true,
                        comment_uuid: c.uuid
                      })
                    }
                  >
                    Del
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  close_download_attendance = () => this.setState({ download_attendance_open: false });

  render_add_comment = () => {
    const { session_id } = this.state;
    return (
      <div>
        <h4>Add Session Id</h4>
        <CustomInput
          label="Enter session id *"
          onChange={this.onCommonChange}
          name="session_id"
          value={session_id}
          style={{ width: '250px' }}
        />
        <Button disabled={!session_id} onClick={this.add_workshop_comment}>
          Submit
        </Button>
      </div>
    );
  };

  render_del_comment = () => {
    const { comment_uuid, workshop_comments } = this.state;
    const comment = workshop_comments.find((w) => w.uuid === comment_uuid);
    return (
      <div>
        <h4>Are you sure you want to comment by {comment ? comment.first_name : ''}</h4>
        <button onClick={this.delete_comment}>Confirm</button>
        <button onClick={this.close_edit_description}>Cancel</button>
      </div>
    );
  };

  duplicate_workshop = () =>
    this.setState({ loading: true }, () => {
      const {
        duplicate_workshop_sessions,
        workshop_uuid,
        duplicate_teacher_uuid,
        duplicate_start_date,
        duplicate_end_date
      } = this.state;
      const sessions = [];
      const sel_du_start_time = moment(duplicate_start_date).format('YYYY-MM-DD HH:mm:ss');
      const fin_start_date = moment.tz(sel_du_start_time, this.props.iana_timezone).valueOf();
      const sel_du_end_time = moment(duplicate_end_date).format('YYYY-MM-DD HH:mm:ss');
      const fin_end_date = moment.tz(sel_du_end_time, this.props.iana_timezone).valueOf();
      for (const session of duplicate_workshop_sessions) {
        const sel_start_time = moment(session.start_time).format('YYYY-MM-DD HH:mm:ss');
        const fin_start_time = moment.tz(sel_start_time, this.props.iana_timezone).valueOf();
        sessions.push({
          ...session,
          start_time: fin_start_time
        });
      }
      const payload = {
        teacher_uuid: duplicate_teacher_uuid,
        sessions,
        workshop_uuid,
        start_date: fin_start_date,
        end_date: fin_end_date
      };
      const url = get_api_url('duplicate_workshop');
      post_api(url, payload, true)
        .then((res) => {
          this.props.set_notification_variable(true, 'success', 'Workshop duplicated');
          console.log('workshop succes ', res.data.workshop_uuid);
          this.setState({ loading: false, open_duplicate_workshop: false });
          this.props.history.push(`/workshop/${res.data.workshop_uuid}`);
        })
        .catch((e) => {
          let err_message;
          console.log(e.response);
          try {
            err_message = e.response.data.reason.message || e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_duplicate_workshop = () => {
    const {
      duplicate_workshop_sessions,
      duplicate_teacher_uuid,
      all_teachers,
      duplicate_start_date,
      duplicate_end_date
    } = this.state;
    const null_session_times = duplicate_workshop_sessions.filter((s) => !s.start_time);
    return (
      <div style={{ minHeight: '600px' }}>
        <h4 style={{ marginBottom: '10px' }}>Duplicate Workshop</h4>
        <CustomSingleSelect
          label="Select teacher"
          options={all_teachers}
          onChange={this.onCommonChange}
          name="duplicate_teacher_uuid"
          value={duplicate_teacher_uuid}
          style={{ width: '250px' }}
        />
        <div className="display-flex-between">
          <div>
            <div>Select start date *</div>
            <DatePicker
              selected={duplicate_start_date}
              onChange={(date) => this.set_start_date('duplicate_start_date', date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="yyyy-MM-dd  h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
          <div>
            <div>Select end date *</div>
            <DatePicker
              selected={duplicate_end_date}
              onChange={(date) => this.set_start_date('duplicate_end_date', date)}
              minDate={duplicate_start_date}
              showTimeSelect
              popperPlacement="left"
              dateFormat="yyyy-MM-dd  h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
        </div>
        {duplicate_workshop_sessions.map((session) => (
          <div>
            <div>{`Select time for session ${session.workshop_session_name}`}</div>
            <DatePicker
              selected={session.start_time}
              onChange={(date) => this.set_start_time(session.uuid, date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
        ))}
        <Button
          disabled={
            !duplicate_teacher_uuid ||
            null_session_times.length !== 0 ||
            !duplicate_start_date ||
            !duplicate_end_date
          }
          onClick={this.duplicate_workshop}
        >
          Duplicate
        </Button>
      </div>
    );
  };

  submit_alias = () =>
    this.setState({ loading: true }, () => {
      const { workshop_details, workshop_alias } = this.state;
      const url = get_api_url('update_alias');
      const payload = {
        workshop_uuid: workshop_details.uuid,
        workshop_alias
      };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Alias edited');
          this.toggle_alias();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_alias = () => {
    const { workshop_details, workshop_alias } = this.state;
    return (
      <div>
        <h4>{workshop_details.alias ? 'Edit' : 'Add'} Alias</h4>
        <CustomInput
          label="Enter Alias"
          onChange={this.onCommonChange}
          name="workshop_alias"
          value={workshop_alias}
          style={{ width: '350px' }}
        />
        <Button disabled={!workshop_alias} onClick={this.submit_alias}>
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const {
      loading,
      workshop_details,
      confirm_sync,
      download_attendance_open,
      add_chat_open,
      edit_video_description,
      add_comment_open,
      open_alias_edit,
      del_comment_open,
      open_duplicate_workshop,
      show_add_free_participant,
      show_add_teacher_to_chat
    } = this.state;
    const show_loading = this.props.student_search_status === 'loading' || loading;
    return (
      <CommonHeader show_back loading={show_loading} title="Workshop Details">
        <CustomModal show={this.state.edit_workshop} close={this.close_workshop}>
          <CreateEditWorkshop
            workshop_details={workshop_details}
            is_edit
            submit_workshop={this.edit_workshop}
            close_create_workshop={this.close_workshop}
            get_all_workshops={this.props.get_all_workshops}
          />
        </CustomModal>
        <CustomModal show={this.state.create_session} close={this.close_create_session}>
          {this.render_create_session()}
        </CustomModal>
        <CustomModal
          show={open_duplicate_workshop}
          close={() => this.setState({ open_duplicate_workshop: false })}
        >
          {this.render_duplicate_workshop()}
        </CustomModal>
        <CustomModal show={open_alias_edit} close={this.toggle_alias}>
          {this.render_alias()}
        </CustomModal>
        <CustomModal show={download_attendance_open} close={this.close_download_attendance}>
          {this.render_download_attendance()}
        </CustomModal>
        <CustomModal show={confirm_sync} close={this.close_sync}>
          {this.render_sync_confirm()}
        </CustomModal>
        <CustomModal show={add_chat_open} close={this.close_add_particpant}>
          {this.render_add_chat_participant()}
        </CustomModal>
        <CustomModal show={edit_video_description} close={this.close_edit_description}>
          {this.render_edit_video_description()}
        </CustomModal>
        <CustomModal show={add_comment_open} close={this.close_edit_description}>
          {this.render_add_comment()}
        </CustomModal>
        <CustomModal show={del_comment_open} close={this.close_edit_description}>
          {this.render_del_comment()}
        </CustomModal>
        {this.render_workshop_details()}
        {this.render_workshop_sessions()}
        {this.render_workshop_participants()}
        {this.render_workshop_comments()}
        {workshop_details.uuid ? (
          <RenderAllTags
            association_type={this.state.association_type}
            association_id={workshop_details.uuid}
            entity_name={workshop_details.name}
          />
        ) : null}
        <CustomModal show={show_add_free_participant} close={this.close_add_free_participant}>
          {this.render_add_free_participant()}
        </CustomModal>
        <CustomModal show={show_add_teacher_to_chat} close={this.close_add_teacher_to_chat}>
          {this.render_add_teacher_to_chat()}
        </CustomModal>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  search_students_list: state.students.search_students_list,
  student_search_status: state.loading.student_search_status
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_all_workshops: () => {
    dispatch(actions.get_all_workshops());
  },
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  search_students: (payload) => {
    dispatch(actions.search_students(payload));
  },
  set_loading_variable: (key, payload) => {
    dispatch(actions.set_loading_variable(key, payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopDetails);
