import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { image_error_replace } from '../../../util_functions';
import { time_format, day_format_year } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class FailedRecurringTransactions extends Component {
  state = {
    loading: true,
    transactions: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_failed_recurring_transactions');
      post_api(url, null, true)
        .then((res) => {
          this.setState({ transactions: [...res.data.transactions], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_transaction = (transaction) => {
    const student_url = `/studentprofile?id=${transaction.student_uuid}`;
    return (
      <div className="ct-row" key={transaction.uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={transaction.profile_photo} onError={image_error_replace} alt="x" />
              {transaction.first_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-si">
          {transaction.price}
          <span style={{ marginLeft: '5px' }}>
            {transaction.is_prepaid === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </span>
        </div>
        <div className="ct-col  ct-sm-font">{transaction.description}</div>
        <div className="ct-col  ct-sm-font">{transaction.transaction_failure_reason}</div>
        <div className="ct-col ct-hf  ct-sm-font">{transaction.retry_attempts}</div>
        <div className="ct-col ct-hf  ct-sm-font">
          <div>
            {moment(transaction.next_retry_date)
              .tz(this.props.iana_timezone)
              .format(day_format_year)}
          </div>
          <div>
            {moment(transaction.next_retry_date).tz(this.props.iana_timezone).format(time_format)}
          </div>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          <div>
            {moment(transaction.created_date).tz(this.props.iana_timezone).format(day_format_year)}
          </div>
          <div>
            {moment(transaction.created_date).tz(this.props.iana_timezone).format(time_format)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, transactions } = this.state;
    return (
      <CommonHeader loading={loading} title="Failed Recurring Transactions">
        <div style={{ marginTop: '30px' }}>
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Student</div>
              <div className="ct-col">Price (Prepaid)</div>
              <div className="ct-col">Package</div>
              <div className="ct-col">Reason</div>
              <div className="ct-col ct-hf  ct-sm-font">Retry attemps</div>
              <div className="ct-col  ct-hf  ct-sm-font">Next Retry</div>
              <div className="ct-col  ct-hf  ct-sm-font">Created Date</div>
            </div>
            {transactions.map(this.render_single_transaction)}
          </div>
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_status: state.loading.config_status,
  config_values: state.home.config_values
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FailedRecurringTransactions);
