import React from 'react';
import PropTypes from 'prop-types';

function LinkButton(props) {
  return (
    <a href={props.href} className="link-btn" target="_blank" rel="noopener noreferrer">
      {props.title}
    </a>
  );
}

LinkButton.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string
};

LinkButton.defaultProps = {
  href: '/',
  title: 'Link'
};

export default LinkButton;
