import React, { ChangeEvent, KeyboardEvent } from 'react';
import { image_error_replace } from '../../../util_functions';
import moment from 'moment-timezone';
import './chat_window.css';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import videojs from 'video.js';
import '../../../../node_modules/video.js/dist/video-js.css';
import './chat_pop.css';

import imageCompression from 'browser-image-compression';

import { Subscription, liveQuery } from 'dexie';
import { Player } from '@lottiefiles/react-lottie-player';

import { ChatOpenState, ChatWindowManagerGlobalState, chatWindowManagerState } from '../chat';

import Channel from '../../../../myt_chat/src/chat/models/Channel';
import Message, { Media } from '../../../../myt_chat/src/chat/models/Message';
import User from '../../../../myt_chat/src/chat/models/User';
import Config from '../../../../myt_chat/src/chat/models/Config';
import UserDBOps from '../../../../myt_chat/src/chat/database/UserDBOps';

import { db } from '../../../../myt_chat/src/chat/database/MytChatDB';
import S3Handler from '../../../../myt_chat/src/chat/network/S3Handler';
import ChannelDBOps from '../../../../myt_chat/src/chat/database/ChannelDBOps';
import MessageHandler from '../../../../myt_chat/src/chat/network/MessageHandler';
import ChatClient from '../../../../myt_chat/src/chat/network/ChatClient';
import { CallbackHandler } from '../../../../myt_chat/src/chat/callbacks/handler';
import Member from '../../../../myt_chat/src/chat/models/Member';
import PresenceHandler from '../../../../myt_chat/src/chat/network/PresenceHandler';
import MessageState from '../../../../myt_chat/src/chat/enums/MessageState';
import PinHandler from '../../../../myt_chat/src/chat/network/PinHandler';
import ReferenceType from '../../../../myt_chat/src/chat/enums/ReferenceType';
import Linkify from 'linkify-react';
import MessageUtils from '../../../../myt_chat/src/chat/utils/MessageUtils';
import UserRole from '../../../../myt_chat/src/chat/enums/UserRole';
import { AutomatedMessageIcon } from '../../../../myt_chat/src/assets/svgs';
/*

Assumptions

* Only Private pinned messages in p2p

*/

type ChatWindowProps = {
  channel: Channel;
};

type ChatWindowState = {
  onlineStatus: boolean;
  channel: Channel;
  channelUUID: string;
  messages: Array<Message>;
  pinnedMessages: Array<Message>;
  unread: number;
  msgCount: number;
  openState: ChatOpenState;
  typing: boolean;
  myUUID: string;
  input_focus: boolean;
  openAllPinnedChats: boolean;
  new_message: string;
  pickEmoji: boolean;
  current_chat_focus: boolean;
  showScrollBottom: boolean;
  is_loading: boolean;
  replyTo: boolean;
  replyReference?: Message;
  show_img_value: string;
  show_chat_pop_img: boolean;
  showVideo: boolean;
  uploadProgress: number;
  showOptionsOnMessageId: string;
  otherUser: User | undefined;
};

class ChatWindow extends React.Component<ChatWindowProps, ChatWindowState> {
  player: any;
  videoNode: any;

  userSubscription?: Subscription;
  messageSubscription?: Subscription;
  channelSubscription?: Subscription;
  chatWindowManagerSubscription?: () => void = undefined;

  typingSignalTimer?: number = undefined;
  messageEnd = React.createRef<HTMLDivElement>();

  constructor(props: ChatWindowProps) {
    super(props);

    this.state = {
      onlineStatus: false,
      channel: props.channel,
      channelUUID: props.channel.uuid,
      messages: [],
      pinnedMessages: [],
      unread: 0,
      msgCount: 0,
      openState: ChatOpenState.OPEN,
      typing: false,
      myUUID: Config.shared.cached().authInfo.uuid!,
      input_focus: false,
      openAllPinnedChats: false,
      new_message: '',
      pickEmoji: false,
      current_chat_focus: false,
      showScrollBottom: false,
      is_loading: false,
      replyTo: false,
      replyReference: undefined,
      show_img_value: '',
      show_chat_pop_img: false,
      showVideo: false,
      uploadProgress: 1,
      showOptionsOnMessageId: '',
      otherUser: undefined
    };

    this.userSubscription = liveQuery(() => {
      return UserDBOps.shared.getUser(props.channel.otherUserId);
    }).subscribe(
      (user) => {
        this.setState({
          otherUser: user
        });
      },
      (error) => {
        console.log(error);
      }
    );

    this.messageSubscription = liveQuery(() => {
      return db.message.where({ channel: props.channel.uuid }).sortBy('timeHandle');
    }).subscribe(
      (result) => {
        const shouldScroll = result.length > this.state.msgCount;

        const pinnedMessages = result.filter((item) => item.isPrivatePinned || item.isPublicPinned);

        this.setState(
          {
            messages: result,
            pinnedMessages: pinnedMessages,
            msgCount: result.length,
            openAllPinnedChats: pinnedMessages.length === 0 ? false : this.state.openAllPinnedChats
          },
          () => {
            if (shouldScroll) {
              this.scrollToBottom();
            }
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );

    this.channelSubscription = liveQuery(() => {
      return ChannelDBOps.shared.getChannel(this.state.channelUUID);
    }).subscribe(
      (result: Channel) => {
        if (result === undefined) {
          return;
        }

        this.setState({
          channel: result,
          unread: result.unread
        });
      },
      (error) => {
        console.log(error);
      }
    );

    this.chatWindowManagerSubscription = chatWindowManagerState.subscribe(
      (global: ChatWindowManagerGlobalState) => {
        global.windows.forEach((item) => {
          if (
            item.channel.uuid === this.state.channelUUID &&
            this.state.openState !== item.openState
          ) {
            this.setState({
              openState: item.openState
            });
          }
        });
      }
    );

    ChatClient.shared.loadChannel(props.channel);
  }

  on_close_click = () => {
    this.setState(
      {
        openState: ChatOpenState.CLOSED
      },
      () => {
        chatWindowManagerState.updateValue((state: ChatWindowManagerGlobalState) => {
          let itemIndex = state.windows.findIndex(
            (item) => item.channel.uuid === this.state.channelUUID
          );
          if (itemIndex !== -1) {
            state.windows.splice(itemIndex, 1);
          }

          return state;
        });
      }
    );
  };

  on_minimize_toggle_click = () => {
    let nextState: ChatOpenState;

    if (this.state.openState === ChatOpenState.OPEN) {
      nextState = ChatOpenState.MINIMIZED;
    } else {
      nextState = ChatOpenState.OPEN;
    }

    this.setState(
      {
        openState: nextState
      },
      () => {
        chatWindowManagerState.updateValue((state: ChatWindowManagerGlobalState) => {
          let itemIndex = state.windows.findIndex((item) => {
            return item.channel.uuid === this.state.channelUUID;
          });

          if (itemIndex !== -1) {
            state.windows[itemIndex].openState = nextState;
          }

          return state;
        });
      }
    );
  };

  presenceListener = (user: string, status: string) => {
    this.updateOnlineStatus(user, status === 'Online');
  };

  typingListener = (channelId: string, from: User | Member, typing: boolean) => {
    if (this.state.channelUUID.includes(channelId) && from.uuid !== this.state.myUUID) {
      this.setState({
        typing: typing
      });
    }
  };

  uploadProgressListener = (message: Message, mediaIdx: number, progress: number) => {
    this.setState({
      uploadProgress: progress
    });
  };

  messageListener = (message: Message) => {
    if (this.state.channel.uuid !== message.channel) {
      return;
    }

    if (message.state >= MessageState.RECEIVED && message.state < MessageState.READ_SENT) {
      MessageHandler.shared.sendReadReceipt(message);
    }
  };

  componentDidMount(): void {
    CallbackHandler.shared.registerOnMessageAddedListener(this.messageListener);
    CallbackHandler.shared.registerPresenceListener(this.presenceListener);
    CallbackHandler.shared.registerTypingListener(this.typingListener);
    CallbackHandler.shared.registerUploadProgressListener(this.uploadProgressListener);

    PresenceHandler.shared.getLastSeen(this.state.channel.otherUserId);
  }

  componentWillUnmount(): void {
    CallbackHandler.shared.unregisterTypingListener(this.typingListener);
    CallbackHandler.shared.unregisterPresenceListener(this.presenceListener);
    CallbackHandler.shared.unregisterUploadProgressListener(this.uploadProgressListener);
    CallbackHandler.shared.unregisterOnMessageAddedListener(this.messageListener);

    this.userSubscription?.unsubscribe();
    this.messageSubscription?.unsubscribe();
    this.channelSubscription?.unsubscribe();
    this.chatWindowManagerSubscription?.();

    this.userSubscription = undefined;
    this.messageSubscription = undefined;
    this.channelSubscription = undefined;
    this.chatWindowManagerSubscription = undefined;
  }

  updateOnlineStatus = (user: string, online: boolean) => {
    if (this.state.channelUUID.includes(user)) {
      this.setState({
        onlineStatus: online
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.typing !== this.state.typing) {
      this.scrollToBottom();
    }
  }

  onInputBlur = () => this.setState({ input_focus: false });
  onInputFocus = () => this.setState({ input_focus: true });

  scrollToBottom = () => {
    if (this.messageEnd.current && this.state.openState === ChatOpenState.OPEN) {
      const height = this.messageEnd.current.scrollHeight;
      this.messageEnd.current.scrollTo(0, height);
    }
  };

  pin_chat = () => {
    return (
      <>
        {this.state.pinnedMessages.length > 0 && (
          <>
            <div className={`pinned-mess-tab ${this.state.openAllPinnedChats ? 'open' : ''}`}>
              <div
                className="pin-chat-head"
                onClick={() => {
                  this.setState({
                    openAllPinnedChats: !this.state.openAllPinnedChats
                  });
                }}
              >
                <span>{!!this.state.pinnedMessages && this.state.pinnedMessages.length}</span>{' '}
                Pinned chats{' '}
                {this.state.openAllPinnedChats === false ? (
                  <button
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Open pin messages"
                    className="pin-club"
                    onClick={(event) => {
                      this.setState({
                        openAllPinnedChats: !this.state.openAllPinnedChats
                      });
                    }}
                  >
                    <img
                      className="club"
                      alt="icon"
                      src="https://images.myyogateacher.com/icons/ic_club_chat.svg"
                    />
                  </button>
                ) : (
                  <button
                    className="pin-close"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Close tab"
                    onClick={(event) => {
                      this.setState({
                        openAllPinnedChats: !this.state.openAllPinnedChats
                      });
                    }}
                  >
                    <img
                      className="close-pin"
                      alt="icon"
                      src="https://images.myyogateacher.com/ic_cross_ch1.svg"
                    />
                  </button>
                )}
              </div>

              {this.state.openAllPinnedChats && (
                <div className="open-all-pins">
                  {this.state.pinnedMessages.map((message, index) => {
                    const mediaList = message.media;
                    const message_item = message.text;

                    const emojiCount = this.getEmojisCount(message_item);
                    const textWithoutEmojis = this.removeEmojisFromString(message_item);
                    const emojiStyle = message.isDeleted
                      ? { fontStyle: 'italic', opacity: 0.6 }
                      : this.isEmoji(message_item) &&
                          textWithoutEmojis.length === 0 &&
                          emojiCount < 3
                        ? { fontSize: 30 }
                        : this.isEmoji(message_item) &&
                            textWithoutEmojis.length === 0 &&
                            emojiCount >= 3
                          ? { fontSize: 18 }
                          : {};

                    const timeStamp = message.timestamp;
                    if (message.state >= MessageState.RECEIVED) {
                      return (
                        <div className="friend-chat" key={message.id}>
                          <div className="chat-frnd-div">
                            <button
                              className="pin-remove-btn"
                              onClick={async () => {
                                await PinHandler.shared.unpinMessageByCare(message);
                                this.setState({
                                  showOptionsOnMessageId: ''
                                });
                              }}
                            >
                              Remove
                            </button>

                            <img
                              className="chat-user-pic"
                              src={this.state.channel?.avatar}
                              onError={image_error_replace}
                              alt="i"
                            />
                            <div className="chat-frnd-mess">
                              <p className="chat-user-name">{this.state.channel?.name || 'User'}</p>
                              <div className="pin-f-wid">
                                {message_item && (
                                  <div className="chat-cmt-box">
                                    <span style={emojiStyle}>
                                      <Linkify
                                        key="start"
                                        className="whitespace-pre-line"
                                        as={'pre'}
                                        options={{
                                          defaultProtocol: 'https',
                                          target: '_blank',
                                          truncate: 50,
                                          rel: 'noopener',
                                          ignoreTags: ['script', 'style'],
                                          className: 'underline'
                                        }}
                                      >
                                        {message.text}
                                      </Linkify>
                                    </span>
                                  </div>
                                )}

                                {mediaList.map((media) => {
                                  return this.createAttachments(message, media);
                                })}

                                <div className="chat-time">
                                  {moment().format('l') === moment(timeStamp).format('l')
                                    ? moment(timeStamp).format('h:mm a')
                                    : moment(timeStamp).calendar()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="friend-chat" key={index + message.id}>
                          <div className="chat-frnd-div">
                            <img
                              className="chat-user-pic"
                              src={Config.shared.cached().avatar}
                              onError={image_error_replace}
                              alt="user pic"
                            />
                            <button
                              className="pin-remove-btn"
                              onClick={async () => {
                                await PinHandler.shared.unpinMessageByCare(message);
                                this.setState({
                                  showOptionsOnMessageId: ''
                                });
                              }}
                            >
                              Remove
                            </button>

                            <div className="chat-frnd-mess">
                              <div className="pin-f-wid">
                                {message_item && (
                                  <div className="chat-cmt-box">
                                    <span style={emojiStyle}>
                                      <Linkify
                                        key="start"
                                        className="whitespace-pre-line"
                                        as={'pre'}
                                        options={{
                                          defaultProtocol: 'https',
                                          target: '_blank',
                                          truncate: 50,
                                          rel: 'noopener',
                                          ignoreTags: ['script', 'style'],
                                          className: 'underline'
                                        }}
                                      >
                                        {message.text}
                                      </Linkify>
                                    </span>
                                  </div>
                                )}

                                {mediaList.map((media) => {
                                  return this.createAttachments(message, media);
                                })}

                                <div className="chat-time">
                                  {moment().format('l') === moment(timeStamp).format('l')
                                    ? moment(timeStamp).format('h:mm a')
                                    : moment(timeStamp).calendar()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  isSameDay(currentMessage, diffMessage) {
    if (!diffMessage || !diffMessage.timestamp) {
      return false;
    }
    const currentCreatedAt = moment(currentMessage.timestamp);
    const diffCreatedAt = moment(diffMessage.timestamp);
    if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
      return false;
    }
    return currentCreatedAt.isSame(diffCreatedAt, 'day');
  }

  isSameTime = (currentMsg, nextMsg) => {
    const isSameDate = this.isSameDay(currentMsg, nextMsg);
    if (isSameDate) {
      const currentTime = moment(currentMsg.timestamp).format('LT');
      const prevTime = moment(nextMsg.timestamp).format('LT');
      return currentTime === prevTime;
    }
    return false;
  };

  getComputedMessageTime = (message) => {
    const date = moment(message.timestamp).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: 'Do MMM YYYY',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'Do MMM YYYY',
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return 'Do MMM YYYY';
      }
    });
    return date;
  };

  renderMessagesDate = (messages, currentIndex) => {
    let currentMessage = messages[currentIndex];
    if (currentIndex === 0) {
      return this.getComputedMessageTime(currentMessage);
    }
    let prevMessage = messages[currentIndex - 1];
    if (!this.isSameDay(currentMessage, prevMessage)) {
      return this.getComputedMessageTime(currentMessage);
    }
    return '';
  };

  renderMessageTiming = (messages: Array<Message>, index: number) => {
    let currentMessage = messages[index];
    if (this.isMyMessage(currentMessage)) {
      return moment(currentMessage.timestamp).format('LT');
    }
    if (index === messages.length - 1) {
      return moment(currentMessage.timestamp).format('LT');
    }
    let nextMessage = messages[index + 1];
    if (nextMessage.from !== currentMessage.from) {
      return moment(currentMessage.timestamp).format('LT');
    }

    if (!this.isSameTime(currentMessage, nextMessage)) {
      return moment(currentMessage.timestamp).format('LT');
    }
  };

  isMyMessage = (message: Message) => {
    return message.state <= MessageState.READ;
  };

  uploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || e.target.files.length === 0) {
      return;
    }

    let file = e.target.files[0];

    if (file.type.includes('image')) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 400,
        useWebWorker: true,
        fileType: 'image/jpeg'
      };

      file = await imageCompression(file, options);
    }

    this.send_end_typing_notification();
    MessageHandler.shared.sendMessage(this.state.channel, '', file);
    this.setState({
      uploadProgress: 1
    });
  };

  send_message = () => {
    let new_message = this.state.new_message.trim();

    this.send_end_typing_notification();

    if (new_message.length === 0) {
      return;
    }

    MessageHandler.shared.sendMessage(
      this.state.channel,
      new_message,
      undefined,
      this.state.replyReference
    );

    this.setState({
      new_message: '',
      replyTo: false,
      replyReference: undefined
    });
  };

  render_progress_bar = () => {
    return (
      <>
        {this.state.uploadProgress < 1 && (
          <div className="upload-bar-div">
            <div className="upload-bar">
              <b
                className="upload-bar-in"
                style={{
                  width: `${(this.state.uploadProgress * 100).toFixed(2)}%`
                }}
              ></b>
            </div>

            <div className="upload-bar-percent">
              {(this.state.uploadProgress * 100).toFixed(2)}%
            </div>
          </div>
        )}
      </>
    );
  };

  render_chat_content = () => {
    const { messages } = this.state;

    return (
      <>
        <div
          className={`c-c-messages ${
            this.state.openAllPinnedChats && this.state.pinnedMessages.length > 0
              ? 'cc-stop-back-scroll'
              : ''
          }`}
          id="chat-window"
          ref={this.messageEnd}
        >
          {messages.map((message, index) => {
            try {
              let show_indicator = false;
              let img_src = '';
              const isAutomated = message?.flags?.isAutomated;

              if (message.isDeleted) {
                img_src = '';
                show_indicator = false;
              } else if (message.state === MessageState.READ) {
                img_src = 'https://images.myyogateacher.com/icons/green-read-tick.svg';
                show_indicator = true;
              } else if (message.state === MessageState.DELIVERED) {
                img_src = 'https://images.myyogateacher.com/icons/grey-read-tick.svg';
                show_indicator = true;
              } else if (message.state === MessageState.SENT) {
                img_src = 'https://images.myyogateacher.com/icons/single-sent-tick.svg';
                show_indicator = true;
              }

              let message_item = message.text;
              let mediaList = message.media;
              const emojiCount = this.getEmojisCount(message_item);
              const textWithoutEmojis = this.removeEmojisFromString(message_item);
              const emojiStyle = message.isDeleted
                ? { fontStyle: 'italic', opacity: 0.6 }
                : this.isEmoji(message_item) && textWithoutEmojis.length === 0 && emojiCount < 3
                  ? { fontSize: 50 }
                  : this.isEmoji(message_item) && textWithoutEmojis.length === 0 && emojiCount >= 3
                    ? { fontSize: 18 }
                    : {};

              const isMessagePinned = message.isPrivatePinned || message.isPublicPinned;

              if (!this.isMyMessage(message)) {
                return (
                  <div key={index + message.id}>
                    <p className="parting-date">{this.renderMessagesDate(messages, index)}</p>

                    {this.state.showOptionsOnMessageId === message.id && (
                      <div
                        onClick={() => {
                          this.setState({
                            showOptionsOnMessageId: ''
                          });
                        }}
                        className="common-overlay-close"
                      />
                    )}

                    <div
                      className={`friend-chat 
                                            ${this.state.showOptionsOnMessageId === message.id ? 'sel' : ''}
                                            ${isMessagePinned ? 'sel-pin' : ''}`}
                      id={`${message.id}-chat-height`}
                    >
                      {isMessagePinned && (
                        <img
                          className="pin-it"
                          alt="pin"
                          src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                        />
                      )}

                      <div className="chat-frnd-div">
                        {isAutomated ? (
                          <AutomatedMessageIcon />
                        ) : (
                          <img
                            className="chat-user-pic"
                            src={this.state.channel.avatar}
                            onError={image_error_replace}
                            alt="i"
                          />
                        )}
                        <div className="chat-frnd-mess">
                          <p className="chat-user-name">
                            {isAutomated ? 'Automated Message' : this.state.channel.name}
                          </p>
                          <div className={`chat-frnd-cond ${message_item ? '' : 'row-it'}`}>
                            <div className="frnd-cmt-outer">
                              {message_item && (
                                <div
                                  className="chat-cmt-box"
                                  style={{ backgroundColor: isAutomated ? '#FCD757' : '' }}
                                >
                                  {message.reference.type === ReferenceType.REPLY && (
                                    <div className="rep-rep-frnd">
                                      <p className="rep_from_name">
                                        {message.reference.sender.name}
                                      </p>

                                      <Linkify
                                        className="whitespace-pre-line"
                                        as={'pre'}
                                        options={{
                                          defaultProtocol: 'https',
                                          target: '_blank',
                                          truncate: 50,
                                          rel: 'noopener',
                                          ignoreTags: ['script', 'style'],
                                          className: ''
                                        }}
                                      >
                                        {message.reference.text}
                                      </Linkify>
                                    </div>
                                  )}

                                  <span style={emojiStyle}>
                                    <Linkify
                                      className="whitespace-pre-line"
                                      as={'pre'}
                                      options={{
                                        defaultProtocol: 'https',
                                        target: '_blank',
                                        truncate: 50,
                                        rel: 'noopener',
                                        ignoreTags: ['script', 'style'],
                                        className: ''
                                      }}
                                    >
                                      {message.text}
                                    </Linkify>
                                  </span>
                                </div>
                              )}

                              {!message.isDeleted && (
                                <div className="chat-bt-div">
                                  <button
                                    className="chat-dot-btn"
                                    onClick={() => {
                                      if (this.state.showOptionsOnMessageId === message.id) {
                                        return this.setState({
                                          showOptionsOnMessageId: ''
                                        });
                                      }

                                      this.setState(
                                        {
                                          showOptionsOnMessageId: message.id
                                        },
                                        () => {
                                          document
                                            .getElementById(
                                              `${this.state.showOptionsOnMessageId}-options`
                                            )
                                            ?.scrollIntoView({
                                              behavior: 'smooth',
                                              block: 'nearest',
                                              inline: 'start'
                                            });
                                        }
                                      );
                                    }}
                                  >
                                    <img
                                      src="https://images.myyogateacher.com/icons/ic_more_chat.svg"
                                      alt="more"
                                    />
                                  </button>

                                  {this.state.showOptionsOnMessageId === message.id && (
                                    <div
                                      className="chat-act-popup frnd-it"
                                      id={`${message.id}-options`}
                                    >
                                      {isMessagePinned ? (
                                        <button
                                          onClick={async () => {
                                            await PinHandler.shared.unpinMessageByCare(message);
                                            this.setState({
                                              showOptionsOnMessageId: ''
                                            });
                                          }}
                                        >
                                          <img
                                            alt="pin"
                                            src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                                          />{' '}
                                          <span>Unpin chat</span>
                                        </button>
                                      ) : (
                                        <button
                                          onClick={async () => {
                                            await PinHandler.shared.pinMessageByCare(message);
                                            this.setState({
                                              showOptionsOnMessageId: ''
                                            });
                                          }}
                                        >
                                          <img
                                            alt="pin"
                                            src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                                          />{' '}
                                          <span>Pin chat</span>
                                        </button>
                                      )}

                                      {message_item && (
                                        <button
                                          onClick={(event: React.UIEvent<HTMLElement>) => {
                                            if (this.state.messages.length > 0) {
                                              this.scrollToBottom();
                                            }

                                            this.setState({
                                              replyTo: true,
                                              replyReference: message,
                                              showOptionsOnMessageId: ''
                                            });
                                          }}
                                        >
                                          <img
                                            alt="reply"
                                            src="https://images.myyogateacher.com/icons/ic_win_open.svg"
                                          />{' '}
                                          <span>Reply</span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {mediaList.map((media) => {
                              return this.createAttachments(message, media);
                            })}
                            <div className="chat-time">
                              {this.renderMessageTiming(messages, index)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index + message.id}>
                    <p className="parting-date">{this.renderMessagesDate(messages, index)}</p>

                    {this.state.showOptionsOnMessageId === message.id && (
                      <div
                        onClick={() => {
                          this.setState({
                            showOptionsOnMessageId: ''
                          });
                        }}
                        className="common-overlay-close"
                      />
                    )}

                    <div
                      className={`my-chat 
                                            ${this.state.showOptionsOnMessageId === message.id ? 'sel' : ''} 
                                            ${isMessagePinned ? 'sel-pin' : ''}`}
                      id={`${message.id}-chat-height`}
                    >
                      {isMessagePinned && (
                        <img
                          className="pin-it"
                          alt="pin"
                          src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                        />
                      )}
                      <div className="chat-my-div">
                        <div className={`chat-my-mess ${message_item ? '' : 'row-it'}`}>
                          <div className="my-cmt-outer items-baseline">
                            {!message.isDeleted && (
                              <div className="chat-bt-div">
                                <button
                                  className="chat-dot-btn chat-me"
                                  onClick={() => {
                                    if (this.state.showOptionsOnMessageId === message.id) {
                                      return this.setState({
                                        showOptionsOnMessageId: ''
                                      });
                                    }

                                    this.setState(
                                      {
                                        showOptionsOnMessageId: message.id
                                      },
                                      () => {
                                        document
                                          .getElementById(
                                            `${this.state.showOptionsOnMessageId}-options`
                                          )
                                          ?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'nearest',
                                            inline: 'start'
                                          });
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    src="https://images.myyogateacher.com/icons/ic_more_chat.svg"
                                    alt="more"
                                  />
                                </button>

                                {this.state.showOptionsOnMessageId === message.id && (
                                  <div
                                    className="chat-act-popup chat-me"
                                    id={`${message.id}-options`}
                                  >
                                    {isMessagePinned ? (
                                      <button
                                        onClick={async () => {
                                          await PinHandler.shared.unpinMessageByCare(message);
                                          this.setState({
                                            showOptionsOnMessageId: ''
                                          });
                                        }}
                                      >
                                        <img
                                          alt="reply"
                                          src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                                        />
                                        <span>Unpin chat</span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={async () => {
                                          await PinHandler.shared.pinMessageByCare(message);
                                          this.setState({
                                            showOptionsOnMessageId: ''
                                          });
                                        }}
                                      >
                                        <img
                                          alt="reply"
                                          src="https://images.myyogateacher.com/icons/ic_pin_chat.svg"
                                        />
                                        <span>Pin chat</span>
                                      </button>
                                    )}

                                    {message_item && (
                                      <button
                                        onClick={(event: React.UIEvent<HTMLElement>) => {
                                          document
                                            .getElementById(`text-area-${this.state.channelUUID}`)
                                            ?.focus();
                                          if (this.state.messages.length > 0) {
                                            this.scrollToBottom();
                                          }

                                          this.setState({
                                            replyTo: true,
                                            replyReference: message,
                                            showOptionsOnMessageId: ''
                                          });
                                        }}
                                      >
                                        <img
                                          alt="reply"
                                          src="https://images.myyogateacher.com/icons/ic_win_open.svg"
                                        />
                                        <span>Reply</span>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                            {message_item && (
                              <div>
                                <p
                                  className="text-[#151536] opacity-50 mt-5px mb-2.5 font-semibold"
                                  style={{ fontSize: '12px' }}
                                >
                                  {message.sender.name}
                                </p>
                                <div className="chat-cmt-box chat-me">
                                  {message.reference.type === ReferenceType.REPLY && (
                                    <div className="rep-rep-frnd">
                                      <p className="rep_from_name">{message.sender.name}</p>
                                      <Linkify
                                        className="whitespace-pre-line"
                                        as={'pre'}
                                        options={{
                                          defaultProtocol: 'https',
                                          target: '_blank',
                                          truncate: 50,
                                          rel: 'noopener',
                                          ignoreTags: ['script', 'style'],
                                          className: 'underline'
                                        }}
                                      >
                                        {message.reference.text}
                                      </Linkify>
                                    </div>
                                  )}
                                  <span style={emojiStyle}>
                                    <Linkify
                                      className="whitespace-pre-line"
                                      as={'pre'}
                                      options={{
                                        defaultProtocol: 'https',
                                        target: '_blank',
                                        truncate: 50,
                                        rel: 'noopener',
                                        ignoreTags: ['script', 'style'],
                                        className: 'underline'
                                      }}
                                    >
                                      {message.text}
                                    </Linkify>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          {!message_item && (
                            <div className="flex flex-col">
                              <p className="text-[#151536] opacity-50 mt-5px mb-2.5 text-end font-semibold">
                                {message.sender.name}
                              </p>
                              {mediaList.map((media) => {
                                return this.createAttachments(message, media);
                              })}
                            </div>
                          )}

                          <div className="chat-time chat-me">
                            {this.renderMessageTiming(messages, index)}
                            {show_indicator ? <img alt="indicator" src={img_src} /> : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            } catch (e) {
              console.log(e);
            }
          })}

          {this.state.typing ? (
            <div className="c-c-m-l">
              <img
                className="c-c-m-l-p"
                src={this.state.channel.avatar}
                onError={image_error_replace}
                alt="i"
              />

              <Player
                autoplay={true}
                loop={true}
                speed={1}
                controls={false}
                src="https://assets8.lottiefiles.com/packages/lf20_SCdC0F.json"
                style={{ width: '64px', height: '20px' }}
                background={'transparent'}
              ></Player>
            </div>
          ) : null}
          {this.state.showScrollBottom && (
            <div
              onClick={(event) => {
                this.scrollToBottom();
              }}
              className="chat-scroll-to"
            >
              <img alt="send" src="https://images.myyogateacher.com/icons/ic_ch_right.svg" />
            </div>
          )}
        </div>
        {this.state.replyTo && (
          <div className="friend-chat reply_f_chat">
            <button
              className="close_rep_chat"
              onClick={() => {
                this.setState({
                  replyTo: false
                });
              }}
            >
              <img
                alt="close"
                src="https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg"
              />
            </button>
            <div className="chat-frnd-div">
              <div className="chat-frnd-mess">
                <p className="chat-user-name">{this.state.replyReference?.sender.name}</p>

                <div className="chat-cmt-box">
                  <Linkify
                    className="whitespace-pre-line"
                    as={'pre'}
                    options={{
                      defaultProtocol: 'https',
                      target: '_blank',
                      truncate: 50,
                      rel: 'noopener',
                      ignoreTags: ['script', 'style'],
                      className: 'underline'
                    }}
                  >
                    {this.state.replyReference?.text}
                  </Linkify>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  checkMimeType = (mime: string) => {
    let allowed = [
      'video/3gpp',
      'video/mp4',
      'video/x-mp4',
      'video/quicktime',
      'video/mpeg2',
      'video/m4v',
      'video/mpeg'
    ];

    if (allowed.includes(mime)) {
      return mime;
    }

    return 'video/mp4';
  };

  playChatVideo = (src: string, mime: string) => {
    this.setState(
      {
        showVideo: true
      },
      () => {
        let options = {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: src,
              type: this.checkMimeType(mime)
            }
          ]
        };
        this.player = videojs(this.videoNode, options, () => {
          console.log('onPlayerReady', this);
          this.player.play();
        });
      }
    );
  };

  closeChatPopVideo = () => {
    this.setState({
      showVideo: false
    });
  };

  openChatAttachImage = (src: string) => {
    this.setState({
      show_chat_pop_img: true,
      show_img_value: src
    });
  };

  closeChatAttachImage = () => {
    this.setState(
      {
        show_img_value: ''
      },
      () => {
        this.setState({
          show_chat_pop_img: false
        });
      }
    );
  };

  createAttachments = (message: Message, media: Media) => {
    // if (!this.awsFileUrls.has(media.dataKey)) {
    //     return null;
    // }

    // const url = this.awsFileUrls.get(media.dataKey);

    S3Handler.shared.loadMediaFileUrl(message, media);

    if (MessageUtils.isMediaPDF(media)) {
      return (
        <a href={media.url} target="_blank" className="pdf-style" key={media.dataKey}>
          <span className="pdf-wh">
            <img
              alt="pdf icon"
              src="https://s3.amazonaws.com/images.myyogateacher.com/chat/ic_PDF1.svg"
            />
            <b className="pdf-title">PDF</b>
          </span>
          <span className="pdf-t">PDF</span>
        </a>
      );
    } else if (MessageUtils.isMediaDoc(media)) {
      return (
        <a href={media.url} target="_blank" className="pdf-style" key={media.dataKey}>
          <span className="pdf-wh">
            <img
              alt="pdf icon"
              src="https://s3.amazonaws.com/images.myyogateacher.com/chat/ic_DOC.svg"
            />
            <b className="pdf-title">DOC</b>
          </span>
          <span className="pdf-t">DOC</span>
        </a>
      );
    } else if (MessageUtils.isMediaImage(media)) {
      return (
        <div
          className="chat-atch"
          key={media.dataKey}
          onClick={(e) => {
            e.preventDefault();
            this.openChatAttachImage(media.url);
          }}
        >
          <img alt="img" src={media.url} />
        </div>
      );
    } else if (MessageUtils.isMediaVideo(media)) {
      return (
        <div className="chat-video-cont" key={media.dataKey}>
          <div
            className="chat-v-overlay"
            onClick={async (e) => {
              e.preventDefault();

              const result = await S3Handler.shared.loadFileUrl(media);

              this.playChatVideo(result.url, media.mimeType);
            }}
          />
        </div>
      );
    }
  };

  render_chat_header = () => {
    const header_class = this.state.input_focus ? 'c-c-header' : 'c-c-header c-c-header-o';

    const img_class = this.state.input_focus ? '' : 'c-i-o';

    return (
      <div className={header_class}>
        <div
          className="c-c-header-c"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="View Profile"
        >
          <div
            className={`ch-sender-name desk-chat-p ${
              this.state.channelUUID.includes('care') ? 'pt_no' : ''
            }`}
          >
            <div
              className="flex items-center"
              onClick={() => {
                const profileHref =
                  this.state.otherUser?.role === UserRole.STUDENT
                    ? `/studentprofile?id=${this.state.otherUser.uuid}`
                    : this.state.otherUser?.role === UserRole.TEACHER
                      ? `/teacherprofile?id=${this.state.otherUser.uuid}`
                      : '#';

                window.open(profileHref, '_blank');
              }}
            >
              <div className="h-7 w-7 relative mr-1 ml-2">
                <img
                  className=""
                  src={this.state.channel.avatar}
                  style={{ borderRadius: '50%' }}
                  onError={image_error_replace}
                />

                <p
                  className={`absolute top-0 right-0 p-1 rounded-full 
                                ${this.state.onlineStatus ? 'bg-mytgreen' : ''} `}
                ></p>
              </div>
              <div className="ml-1">
                <b className="ch_name_desk">
                  <div className="inline">
                    {this.state.channel.name.substring(0, 18)}
                    {this.state.channelUUID.includes('care') ? (
                      ''
                    ) : (
                      <img
                        alt="send"
                        src="https://images.myyogateacher.com/icons/ic_ch_right.svg"
                        style={{ display: 'inline' }}
                      />
                    )}
                  </div>
                </b>
                <span className="ch_status_text">
                  {this.state.onlineStatus ? 'Online' : 'Offline'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="c-c-header-i">
          <button
            className="chat-c-btn"
            onClick={this.on_minimize_toggle_click}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              this.state.openState === ChatOpenState.OPEN ? 'Minimize tab' : 'Expand tab'
            }
          >
            {this.state.openState === ChatOpenState.OPEN ? (
              <img
                className={img_class + ' c-c-min'}
                src="https://images.myyogateacher.com/ic_minimize_ch1.svg"
                alt="min"
              />
            ) : (
              <img
                className={img_class + ' c-c-expand'}
                alt="expand"
                src="https://images.myyogateacher.com/ic_expand.svg"
              />
            )}
          </button>
          <button
            className="chat-c-btn last-btn"
            onClick={this.on_close_click}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Close tab"
          >
            <img
              className="close_min_chat"
              src="https://images.myyogateacher.com/ic_cross_ch1.svg"
              alt="c"
            />
          </button>
        </div>
      </div>
    );
  };
  render_chat_loading = () => (
    <div className="c-c-loading">
      <div />
    </div>
  );

  initDoc = () => {
    let observe: (element: any, event: string, handler: () => void) => void;

    if (window['attachEvent']) {
      observe = function (element, event, handler) {
        element.attachEvent('on' + event, handler);
      };
    } else {
      observe = function (element, event, handler) {
        element.addEventListener(event, handler, false);
      };
    }

    const text = document.getElementById(`text-area-${this.state.channelUUID}`);

    const resize = () => {
      text!.style.height = 'auto';
      text!.style.height = text!.scrollHeight + 'px';
    };
    /* 0-timeout to get the already changed text */
    const delayedResize = () => {
      window.setTimeout(resize, 0);
    };

    observe(text, 'change', resize);
    observe(text, 'cut', delayedResize);
    observe(text, 'paste', delayedResize);
    observe(text, 'drop', delayedResize);
    observe(text, 'keydown', delayedResize);

    text?.focus();
    // text.select();
    resize();
  };

  handle_message = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.initDoc();

    if (this.typingSignalTimer === undefined) {
      MessageHandler.shared.sendChatState(this.state.channel, true);
    }

    clearTimeout(this.typingSignalTimer);

    this.setState({ new_message: e.target.value });

    this.typingSignalTimer = window.setTimeout(() => {
      this.send_end_typing_notification();
    }, 1000);
  };

  on_enter_press = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (this.state.input_focus && event.key === 'Enter' && event.shiftKey) {
      this.setState({
        new_message: event.currentTarget.value + '\n'
      });
      return;
    }

    if (this.state.input_focus && event.key === 'Enter') {
      event.preventDefault();
      this.send_message();
    }
  };

  replaceNewLines = (message: string) => {
    let newMessage = '';
    let ms = message.trim().split('\n');
    for (let i = 0; i < ms.length; i++) {
      newMessage += ms[i];
      if (i < ms.length - 1) newMessage += '<br/>';
    }
    return newMessage;
  };

  send_end_typing_notification = () => {
    MessageHandler.shared.sendChatState(this.state.channel, false);
    clearTimeout(this.typingSignalTimer);
    this.typingSignalTimer = undefined;
  };

  onEmojiClick = (emoji, e) => {
    this.setState({
      new_message: this.state.new_message + emoji.native,
      pickEmoji: false
    });
    document.getElementById(`text-area-${this.state.channelUUID}`)?.focus();
  };

  closeEmoji = () => {
    this.setState({
      pickEmoji: false
    });
  };

  isEmoji = (str) => {
    var ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
    ];
    if (str.match(ranges.join('|'))) {
      return true;
    } else {
      return false;
    }
  };
  removeEmojisFromString = (str) => {
    return str.replace(
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
      ''
    );
  };

  getEmojisCount(str) {
    const joiner = '\u{200D}';
    const split = str.split(joiner);
    let count = 0;
    for (const s of split) {
      //removing the variation selectors
      const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join('')).length;
      count += num;
    }
    //assuming the joiners are used appropriately
    return count / split.length;
  }

  render() {
    if (this.state.openState === ChatOpenState.CLOSED) {
      return <></>;
    }

    if (this.state.openState === ChatOpenState.MINIMIZED) {
      return <div className="c-c-window-min ch_new">{this.render_chat_header()}</div>;
    }
    return (
      <>
        <div
          className={`c-c-window ch_new ${this.state.current_chat_focus ? 'cur_chat_focus' : ''}`}
          onFocus={() => {
            this.setState({
              current_chat_focus: true
            });
          }}
          onBlur={() => {
            this.setState({
              current_chat_focus: false
            });
          }}
          onScroll={(event: any) => {
            if (
              event.target.scrollTop + event.target.offsetHeight <
              event.target.scrollHeight - event.target.offsetHeight
            ) {
              this.setState({
                showScrollBottom: true
              });
            } else {
              this.setState({
                showScrollBottom: false
              });
            }

            // if (
            //     event.target.scrollTop === 0 &&
            //     this.state.messages.length > 0
            // ) {
            //     ChatClient.shared.fetchArchiveMessages(this.state.channel);
            // }
          }}
        >
          {this.render_chat_header()}
          {this.state.pickEmoji && (
            <div className="emoji-cont">
              <div className="emoji-head"></div>
              <Picker data={data} onEmojiSelect={this.onEmojiClick} theme={'light'} />
            </div>
          )}
          {this.state.is_loading ? (
            this.render_chat_loading()
          ) : (
            <div className="pt-1.5 chat-content flex-col flex relative font-normal w-full">
              {this.pin_chat()}
              {this.render_chat_content()}
              {this.render_progress_bar()}

              <div className="c-c-i-c">
                <div
                  className="textarea-cont"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Hit ↵ Enter to send message"
                >
                  <textarea
                    id={`text-area-${this.state.channelUUID}`}
                    className="textarea"
                    value={this.state.new_message}
                    onChange={this.handle_message}
                    onKeyPress={this.on_enter_press}
                    autoFocus
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    placeholder={this.state.replyTo ? 'Please reply here...' : undefined}
                    style={{ boxShadow: 'none' }}
                  />
                  {this.state.new_message.length > 0 && (
                    <span onClick={() => this.send_message()} className="send-btn-c">
                      Send
                    </span>
                  )}
                </div>

                <div
                  onClick={() => {
                    this.setState({
                      pickEmoji: !this.state.pickEmoji
                    });
                  }}
                  className={`ch-btn ch_emoji ${this.state.pickEmoji ? 'ch_emoji-clicked' : ''}`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Click here to add emoji"
                >
                  <img alt="emoji" src="https://images.myyogateacher.com/icons/ic_emoji_ch.png" />
                </div>
                <div
                  className={`ch-btn ch_attahment`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Click to add attachments"
                >
                  <img alt="emoji" src="https://images.myyogateacher.com/chat/ic_attachement.svg" />
                  <input
                    type="file"
                    onChange={this.uploadFiles}
                    id="myfile"
                    name="myfile"
                    accept="image/png, image/jpeg, image/jpg, application/pdf, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    aria-label="Choose File"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.showVideo && (
          <div className="chat-popup">
            <div className="chat-pop-body chat-v-pop">
              <button className="cpop-close-btn" onClick={this.closeChatPopVideo}>
                <img
                  alt="close"
                  src="https://images.myyogateacher.com/icons/ic_white_cross.png"
                  style={{ maxWidth: '15px' }}
                />
              </button>
              <div className="chat-pop-video">
                <div data-vjs-player>
                  <video ref={(node) => (this.videoNode = node)} className="video-js"></video>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.show_chat_pop_img && (
          <div className="chat-popup">
            <div className="chat-pop-body chat-img-pop">
              <button className="cpop-close-btn" onClick={this.closeChatAttachImage}>
                <img
                  alt="close"
                  src="https://images.myyogateacher.com/icons/ic_white_cross.png"
                  style={{ maxWidth: '15px' }}
                />
              </button>
              <div className="chat-pop-single-img-cont">
                <img src={this.state.show_img_value} alt="pic" />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default ChatWindow;
