import React, { useState, useEffect } from 'react';
import Common_header from '../../../util_components/common_header';
import { get_api, put_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import CustomModal from '../../../util_components/custom_modal';

function TeacherNotificationPreferences(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const parseQueryString = (query) => {
    if (!query) return {};

    const formattedQuery = decodeURI(query).replace(/&/g, '","').replace(/=/g, '":"');

    return JSON.parse(`{"${formattedQuery}"}`);
  };

  useEffect(() => {
    const query = window.location.search.substring(1);
    const params = parseQueryString(query);
    const { name, id } = params;
    if (name) {
      setName(name);
    }
    getNotificationPrefrences(id);
  }, []);

  const getNotificationPrefrences = async (id) => {
    setLoading(true);
    const path = `/v2/teacher/notification_preferences/${id}`;
    const url = get_api_url(path, false, true);
    await get_api(url, true)
      .then((res) => {
        setLoading(false);
        setData(res?.data?.data || []);
      })
      .catch((e) => {
        setLoading(false);
        console.log('e: ', e);
      });
  };

  const updatedNotificationPrefrences = async () => {
    const path = `/v2/teacher/notification_preferences`;
    const url = get_api_url(path, false, true);
    const payload = { teacher_uuid: data.teacher_uuid };
    Object.entries(updatedData).forEach(([key, value]) => {
      payload[key] = value;
    });
    try {
      const res = await put_api(url, payload, true);
      if (res?.data?.status === 'success') {
        setShowConfirmationPopup(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateNotification = (key) => {
    setData((prevData) => {
      const newData = {
        ...prevData,
        [key]: !prevData[key]
      };
      setUpdatedData({ ...updatedData, [key]: newData[key] });
      return newData;
    });
  };

  const openNotificationHistory = () => {
    const url = '/teacher/notification_preferences_history';
    const search = `?id=${data.teacher_uuid}&name=${name}`;
    props.history.push({
      pathname: url,
      search
    });
  };

  return (
    <div>
      <Common_header title="Notification Preferences" show_back subtitle={name} loading={loading}>
        <button style={{ marginBottom: '20px' }} onClick={openNotificationHistory}>
          History
        </button>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <div
              className="cst-single-row cst-single-row-full"
              style={{
                borderTop: '1px solid rgb(207, 207, 207)',
                borderLeft: '1px solid rgb(207, 207, 207)'
              }}
            >
              {typeof value === 'boolean' && (
                <>
                  <div className="cst-single-td cst-first-td">{snakeToNormalCase(key)}:</div>
                  <div className="cst-single-td cst-second-td">
                    <input
                      className="cursor-pointer"
                      type="checkbox"
                      checked={value}
                      onChange={() => handleUpdateNotification(key)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        <button style={{ marginTop: '20px' }} onClick={updatedNotificationPrefrences}>
          Save
        </button>
      </Common_header>
      <CustomModal show={showConfirmationPopup} close={() => setShowConfirmationPopup(false)}>
        Notification updated
      </CustomModal>
    </div>
  );
}

export const snakeToNormalCase = (str) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export default TeacherNotificationPreferences;
