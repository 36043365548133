import React from 'react';
import PropTypes from 'prop-types';
import './custom_sigle_select.css';

function CustomSelect(props) {
  return (
    <div
      className={`custom-single-select ${props.defaultOptionDisabled ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {props.noLabel ? null : (
        <div
          className={`custom-single-select-label ${props.labelClass ? props.labelClass : ''}`}
          style={props.labelStyle}
        >
          {props.label}:
        </div>
      )}
      <div className="custom-single-select-inner">
        <select
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          style={props.style}
          disabled={props.defaultOptionDisabled}
        >
          <option value="">{props.defaultOption}</option>
          {props.options.map((option) => (
            <option value={option.value} key={option.value + option.label}>
              {option.label}
            </option>
          ))}
        </select>
        {props.showClear && !!props.value ? (
          <img
            src="https://images.myyogateacher.com/icons/ic-delete-48.png"
            alt="nav"
            onClick={props.clear_fn}
          />
        ) : null}
      </div>
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultOption: PropTypes.string,
  defaultOptionDisabled: PropTypes.bool,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showClear: PropTypes.bool,
  clear_fn: PropTypes.func
};

CustomSelect.defaultProps = {
  label: 'Enter Label',
  name: 'custom_select',
  defaultOption: 'Select an option',
  style: {
    width: '200px'
  },
  placeholder: 'Enter text',
  options: [
    { value: 1, label: 'one' },
    { value: 2, label: 'two' }
  ],
  noLabel: false,
  showClear: false,
  defaultOptionDisabled: false,
  clear_fn: () => console.log('clear clicked')
};

export default CustomSelect;
