import React, { Component } from 'react';
import CustomLoading from '../../util_components/custom_loading';
import CustomInput from '../../util_components/custom_input';
import { post_api } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';
import CustomSingleSelect from '../../util_components/custom_single_select';

class CreateEditAssociation extends Component {
  state = {
    loading: true,
    teacher_details: null,
    all_teachers: [],
    teachers: [],
    all_workshops: [],
    workshops: [],
    all_group_class_page: [],
    group_class_page: [],
    all_blogs: null,
    blogs: [],
    is_workshop_data_loaded: false,
    is_teachers_data_loaded: false,
    is_group_class_data_loaded: false,
    is_blogs_data_loaded: false
  };

  componentDidMount() {
    const blogs = Object.values(this.props.blog_data);
    const blogs_list = blogs
      .filter((item) => item !== 'success')
      .map((t) => ({ value: t.id, label: t.slug }));
    blogs_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({
      all_blogs: blogs_list,
      is_blogs_data_loaded: true
    });

    this.set_association_details();
    this.get_association_data();
  }

  set_association_details = () => {
    const { association_details } = this.props;
  };

  get_association_data = () => {
    const teacher_url = get_api_url('get_teachers_list');
    post_api(teacher_url, null, true)
      .then((res) => {
        const teachers_list = res.data.teachers;
        const teachers = teachers_list
          .filter((t) => t.onboarding_status === 'APPROVED')
          .map((t) => ({ value: t.uuid, label: `${t.first_name} ${t.last_name}` }));
        teachers.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          all_teachers: teachers,
          is_teachers_data_loaded: true
        });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
      });
    const workshop_url = get_api_url('list_all_workshops');
    post_api(workshop_url, null, true)
      .then((res) => {
        const workshops_list = res.data.workshops;
        const workshops = workshops_list.map((t) => ({ value: t.uuid, label: t.slug }));
        workshops.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          all_workshops: workshops,
          is_workshop_data_loaded: true
        });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
      });
    const group_class_pages_url = get_api_url('get_all_class_pages');
    post_api(group_class_pages_url, null, true)
      .then((res) => {
        const group_class_list = res.data.pages;
        const group_class = group_class_list.map((t) => ({ value: t.uuid, label: t.name }));
        group_class.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({
          all_group_class_page: group_class,
          is_group_class_data_loaded: true,
          loading: false
        });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
      });
  };

  onCommonChange = (key, value) => {
    this.setState({ [key]: value });
  };

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCommonChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  on_entity_click = (e, entity_label) => {
    const all_items = this.state[`all_${entity_label}`];
    const items = this.state[`${entity_label}`];
    const item_index = all_items.findIndex((t) => t.value === e.target.value);
    const final_all_items = [...all_items.slice(0, item_index), ...all_items.slice(item_index + 1)];
    const final_items = [...items, all_items[item_index]];
    this.setState({
      [`all_${entity_label}`]: final_all_items,
      [`${entity_label}`]: final_items
    });
  };

  render_selected_entity = (entity_label) => {
    const items = this.state[`${entity_label}`];
    if (items.length > 0) {
      return (
        <div className="display-flex-between">
          <div>
            <div className="">
              <div className="cre-class-teachers-title">{entity_label} selected:</div>
              <div className="cre-class-teachers">
                {items.map((item) => (
                  <div className="cre-class-teac" key={item.value}>
                    {item.label}
                    <img
                      src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                      onClick={() => this.remove_entity(entity_label, item)}
                      alt="x"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  remove_entity = (entity_label, entity_item) => {
    const all_items = this.state[`all_${entity_label}`];
    const items = this.state[`${entity_label}`];
    const item_index = items.findIndex((t) => t.value === entity_item.value);
    const final_entity = [...items.slice(0, item_index), ...items.slice(item_index + 1)];
    const final_all_entity = [...all_items, entity_item].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    this.setState({
      [`all_${entity_label}`]: final_all_entity,
      [`${entity_label}`]: final_entity
    });
  };

  add_entity = (entity_label) => {
    const { teachers } = this.state;
    const { workshops } = this.state;
    const { group_class_page } = this.state;
    const { blogs } = this.state;
    const payload = {
      TEACHER: teachers,
      WORKSHOP: workshops,
      GROUP_CLASS_PAGE: group_class_page,
      BLOG: blogs
    };
    this.props.submit_association(payload);
  };

  close_editor = () => {
    this.props.close_editor();
  };

  render() {
    const {
      all_teachers,
      all_workshops,
      all_group_class_page,
      all_blogs,
      is_workshop_data_loaded,
      is_teachers_data_loaded,
      is_group_class_data_loaded,
      is_blogs_data_loaded
    } = this.state;
    const label_style = { color: '#aaa', fontSize: '12px', fontWeight: '600' };
    const entity_name = {
      teachers: 'teachers',
      workshops: 'workshops',
      group_class_page: 'group_class_page',
      blogs: 'blogs'
    };
    return (
      <div>
        <div>
          <div>
            {this.state.loading ? <CustomLoading /> : null}
            {is_workshop_data_loaded &&
            is_teachers_data_loaded &&
            is_group_class_data_loaded &&
            is_blogs_data_loaded ? (
              <div>
                <CustomSingleSelect
                  label="Add Teachers"
                  options={all_teachers}
                  name="teacher_selected"
                  onChange={(e) => this.on_entity_click(e, entity_name.teachers)}
                  value=""
                  style={{ width: '250px' }}
                  labelStyle={label_style}
                />
                {this.render_selected_entity(entity_name.teachers)}
                <CustomSingleSelect
                  label="Add Workshops"
                  options={all_workshops}
                  name="workshop_selected"
                  onChange={(e) => this.on_entity_click(e, entity_name.workshops)}
                  value=""
                  style={{ width: '250px' }}
                  labelStyle={label_style}
                />
                {this.render_selected_entity(entity_name.workshops)}
                <CustomSingleSelect
                  label="Add Group Class"
                  options={all_group_class_page}
                  name="group_class_selected"
                  onChange={(e) => this.on_entity_click(e, entity_name.group_class_page)}
                  value=""
                  style={{ width: '250px' }}
                  labelStyle={label_style}
                />
                {this.render_selected_entity(entity_name.group_class_page)}
                <CustomSingleSelect
                  label="Add Blogs"
                  options={all_blogs}
                  name="blog_selected"
                  onChange={(e) => this.on_entity_click(e, entity_name.blogs)}
                  value=""
                  style={{ width: '250px' }}
                  labelStyle={label_style}
                />
                {this.render_selected_entity(entity_name.blogs)}
              </div>
            ) : null}
            <button onClick={this.add_entity}>Submit</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditAssociation;
