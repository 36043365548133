import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { get_api_url } from '../../../../../utils/urls';
import { post_api } from '../../../../../redux/api_funcs';
import CustomSingleSelect from '../../../../util_components/custom_single_select';
import CustomInput from '../../../../util_components/custom_input';
import CustomModal from '../../../../util_components/custom_modal';
import CustomRadioButton from '../../../../util_components/custom_radio_button';
import CustomLoading from '../../../../util_components/custom_loading';

function VacationPauseMembershipDetails(props) {
  const [openCancelOption, setOpenCancelOption] = useState(false);
  const [pauseDetails, setPauseDetails] = useState([]);
  const [pauseDetailsData, setPauseDetailsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelState, setCancelState] = useState({
    status: '',
    cancelled_by_student: false,
    cancelled_by_teacher: false,
    teacher_penalty: '',
    teacher_penalty_reason: '',
    modal_error: false,
    loading: false
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCancelIndex, setCurrentCancelIndex] = useState(null);
  const [cancelType, setCancelType] = useState(null);

  useEffect(() => {
    if (props.from_date && props.to_date && props.shouldFetchData) {
      fetchMembershipDetails();
    }
  }, [props.student_uuid, props.from_date, props.to_date, props.shouldFetchData]);

  const { iana_timezone } = useSelector((state) => ({
    iana_timezone: state.home.iana_timezone
  }));

  const fetchMembershipDetails = async () => {
    const { student_uuid, from_date, to_date } = props;
    setLoading(true);
    const payload = {
      student_uuid,
      from_date,
      to_date
    };
    const url = get_api_url('get_pause_conflicts');
    try {
      const response = await post_api(url, payload, true);
      if (response?.data) {
        const { status, sessions } = response?.data;
        if (status === 'success' && sessions) {
          setPauseDetails(sessions);
          setPauseDetailsData(response.data);
          setOpenCancelOption(new Array(sessions.length).fill(false));

          if (sessions.length === 0) {
            props.onSessionListEmpty(true);
          } else {
            props.onSessionListEmpty(false);
          }
        }
      } else {
        console.warn('No data found in the response.');
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelScheduledClass = (index) => {
    const updatedCancelOptions = openCancelOption.map((value, i) => (i === index ? !value : false));
    setOpenCancelOption(updatedCancelOptions);
  };

  const openCancelModal = (index, type) => {
    setCurrentCancelIndex(index);
    setCancelType(type);
    setCancelState({
      status: '',
      cancelled_by_student: false,
      cancelled_by_teacher: false,
      teacher_penalty: '',
      teacher_penalty_reason: '',
      modal_error: false,
      loading: false
    });
    setIsModalOpen(true);
  };

  const closeCancelModal = () => {
    setIsModalOpen(false);
    setCurrentCancelIndex(null);
    setCancelType(null);
  };

  const handleCancelAllRepeatClasses = async (index = 0) => {
    const { student_uuid, from_date, to_date } = props;
    setLoading(true);
    try {
      const sessionToCancel = pauseDetails[index];
      const payload = {
        student_uuid,
        repeat_session_id: sessionToCancel.repeat_session_id,
        session_type: sessionToCancel.session_type,
        from_date,
        to_date,
        cancel_reason: cancelState.teacher_penalty_reason
      };

      if (cancelState.status === 'CANCELLED') {
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_student) {
        payload.cancelled_by_student = 1;
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_teacher) {
        payload.cancelled_by_teacher = 1;
        payload.cancelled_by_admin = 1;
      }

      const url = get_api_url('cancel_repeat_instances_during_pause');
      const response = await post_api(url, payload, true);
      if (response?.data?.status === 'success') {
        const updatedPauseDetails = pauseDetails.filter(
          (session) => session.repeat_session_id !== sessionToCancel.repeat_session_id
        );
        setPauseDetails(updatedPauseDetails);
        const totalClassesCancelled = pauseDetails.filter(
          (session) => session.repeat_session_id === sessionToCancel.repeat_session_id
        ).length;
        setPauseDetailsData({
          ...pauseDetailsData,
          total_classes: pauseDetailsData.total_classes - totalClassesCancelled
        });
        const updatedCancelOptions = [...openCancelOption];
        updatedCancelOptions[index] = false;
        setOpenCancelOption(updatedCancelOptions);
      } else {
        console.error('Error canceling class: ', response.data.message);
      }
    } catch (error) {
      console.error('Error canceling all repeat classes: ', error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancelClass = async (index = 0) => {
    setLoading(true);
    try {
      const singleSessionCancel = pauseDetails[index];
      const payload = {
        session_uuid: singleSessionCancel.session_uuid,
        cancel_reason: cancelState.teacher_penalty_reason
      };
      if (cancelState.status === 'CANCELLED') {
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_student) {
        payload.cancelled_by_student = 1;
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_teacher) {
        payload.cancelled_by_teacher = 1;
        payload.cancelled_by_admin = 1;
      }

      let url;
      if (singleSessionCancel.session_type === 'GROUP_SESSION_STUDENT') {
        url = get_api_url('cancel_group_session');
      } else {
        url = get_api_url('cancel_session');
      }

      const response = await post_api(url, payload, true);

      if (response?.data?.status === 'success') {
        const updatedPauseDetails = pauseDetails.filter((_, i) => i !== index);
        setPauseDetails(updatedPauseDetails);
        setPauseDetailsData({
          ...pauseDetailsData,
          total_classes: pauseDetailsData.total_classes - 1
        });
        const updatedCancelOptions = [...openCancelOption];
        updatedCancelOptions[index] = false;
        setOpenCancelOption(updatedCancelOptions);
      } else {
        console.error('Error canceling class: ', response.data.message);
      }
    } catch (error) {
      console.error('Error canceling class: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelAllFutureClasses = async (index = 0) => {
    setLoading(true);
    try {
      const futureSessionCancel = pauseDetails[index];
      const payload = {
        cancel_all_future_sessions: 1,
        repeat_session_uuid: futureSessionCancel.repeat_session_uuid,
        cancel_reason: cancelState.teacher_penalty_reason
      };
      if (cancelState.status === 'CANCELLED') {
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_student) {
        payload.cancelled_by_student = 1;
        payload.cancelled_by_admin = 1;
      }
      if (cancelState.cancelled_by_teacher) {
        payload.cancelled_by_teacher = 1;
        payload.cancelled_by_admin = 1;
      }

      let url;
      if (futureSessionCancel.session_type === 'GROUP_SESSION_STUDENT') {
        url = get_api_url('cancel_student_repeat_group_session');
        payload.repeat_group_session_participant_id =
          futureSessionCancel.repeat_group_session_participant_id;
      } else {
        url = get_api_url('cancel_repeat_sessions');
      }

      const response = await post_api(url, payload, true);
      if (response?.data?.status === 'success') {
        const updatedPauseDetails = pauseDetails.filter(
          (session) => session.repeat_session_id !== futureSessionCancel.repeat_session_id
        );
        setPauseDetails(updatedPauseDetails);
        const totalClassesCancelled = pauseDetails.filter(
          (session) => session.repeat_session_id === futureSessionCancel.repeat_session_id
        ).length;
        setPauseDetailsData({
          ...pauseDetailsData,
          total_classes: pauseDetailsData.total_classes - totalClassesCancelled
        });
        const updatedCancelOptions = [...openCancelOption];
        updatedCancelOptions[index] = false;
        setOpenCancelOption(updatedCancelOptions);
      } else {
        console.error('Error canceling class: ', response.data.message);
      }
    } catch (error) {
      console.error('Error canceling class:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditModalChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status' && value !== 'CANCELLED') {
      setCancelState((prevState) => ({
        ...prevState,
        cancelled_by_student: false,
        cancelled_by_teacher: false
      }));
    }
    setCancelState((prevState) => ({
      ...prevState,
      [name]: value,
      modal_error: false
    }));
  };
  const cancelOptions = ['cancelled_by_student', 'cancelled_by_teacher'];

  const saveModal = () => {
    switch (cancelType) {
      case 'single':
        handleCancelClass(currentCancelIndex);
        break;
      case 'future':
        handleCancelAllFutureClasses(currentCancelIndex);
        break;
      case 'repeat':
        handleCancelAllRepeatClasses(currentCancelIndex);
        break;
      default:
        break;
    }
    closeCancelModal();
  };
  const toggleCancel = (option) => {
    let { status } = cancelState;
    if (option === 'cancelled_by_student') {
      if (!cancelState.cancelled_by_student) {
        status = 'CANCELLED';
      }
      setCancelState((prevState) => ({
        ...prevState,
        cancelled_by_student: !cancelState.cancelled_by_student,
        cancelled_by_teacher: cancelState.cancelled_by_student,
        status
      }));
    } else if (option === 'cancelled_by_teacher') {
      if (!cancelState.cancelled_by_teacher) {
        status = 'CANCELLED';
      }
      setCancelState((prevState) => ({
        ...prevState,
        cancelled_by_teacher: !cancelState.cancelled_by_teacher,
        cancelled_by_student: cancelState.cancelled_by_teacher,
        status
      }));
    }
  };

  function CancelledReason() {
    const session_status_values = [{ label: 'CANCELLED', value: 'CANCELLED' }];
    if (cancelState.loading) {
      return null;
    }
    return (
      <CustomModal show={isModalOpen} close={closeCancelModal}>
        <h5>Edit Session Details</h5>
        <div style={{ margin: '10px 0' }}>
          <CustomSingleSelect
            label="Change status"
            options={session_status_values}
            onChange={handleEditModalChange}
            value={cancelState.status || ''}
            style={{ width: '300px' }}
            name="status"
          />
        </div>
        {cancelOptions.map((option, index) => (
          <td key={index}>
            <CustomRadioButton
              label={
                option === 'cancelled_by_student' ? 'Cancelled by Student' : 'Cancelled by Teacher'
              }
              onRadioClick={() => toggleCancel(option)}
              selected={cancelState[`${option}`]}
            />
          </td>
        ))}
        <CustomInput
          label="Enter Penalty"
          style={{ width: '300px' }}
          value={cancelState.teacher_penalty}
          name="teacher_penalty"
          onChange={handleEditModalChange}
          type="number"
        />
        <div style={{ margin: '10px 0' }}>
          <textarea
            value={cancelState.teacher_penalty_reason}
            name="teacher_penalty_reason"
            onChange={handleEditModalChange}
            style={{ width: '300px', height: '100px' }}
            maxLength="200"
            placeholder="Enter penalty reason (max 200 char)"
          />
        </div>
        {cancelState.modal_error ? (
          <p style={{ color: 'red' }}>* Teacher penalty should be greater or equal to 0</p>
        ) : null}
        <button onClick={saveModal}>Save</button>
      </CustomModal>
    );
  }
  return (
    <>
      {loading && <CustomLoading />}
      {pauseDetailsData.status === 'success' && pauseDetailsData.total_classes > 0 ? (
        <div className="font-medium text-sm text-black border-b border-black pb-3 mb-3">
          Unable to pause: <b> {pauseDetailsData.total_classes} </b>{' '}
          {pauseDetailsData.total_classes > 1 ? 'classes' : 'class'} scheduled during this period.
          Reschedule or cancel to proceed.
        </div>
      ) : null}
      <div className="flex flex-col gap-2.5">
        {pauseDetails.map((item, index) => (
          <div className="flex gap-2.5">
            <div className="flex flex-col gap-2.5 w-full">
              <div className="flex items-center justify-between border border-black rounded-lg p-2 max-w-[618px] w-full h-[78px]">
                <div className="flex items-start gap-3">
                  <div className="h-8 w-8 rounded-full ">
                    <img
                      src={item.teacher_photo}
                      className="h-8 w-8 rounded-full"
                      style={{ position: 'static', width: '32px', cursor: '' }}
                    />
                  </div>

                  <div className="">
                    <div className="font-bold text-black text-[15px] leading-normal">
                      {item.session_type === 'GROUP_SESSION_STUDENT' ? item.gc_name : null}
                      {item.session_type === 'YOGA'
                        ? `1:1 Session with ${item.teacher_name}`
                        : null}
                      {item.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT'
                        ? `Yoga with Friends with ${item.teacher_name}`
                        : null}
                      {item.session_type === 'WORKSHOP_SESSION_STUDENT' ? item.workshop_name : null}
                    </div>
                    <div className="flex items-center font-medium text-xs gap-2.5 leading-[22px]">
                      <div>
                        <b>Date: </b>
                        {moment(item.start_time).tz(iana_timezone).format('DD MMMM (dddd)')}
                      </div>
                      {item.session_type === 'YOGA' ? null : (
                        <div>
                          <b>Teacher: </b> {item.teacher_name}
                        </div>
                      )}
                      <div>
                        <b>Duration: </b> {item.duration}
                      </div>
                    </div>
                  </div>
                </div>
                <button onClick={() => handleCancelScheduledClass(index)}>Cancel</button>
              </div>
            </div>
            {openCancelOption[index] ? (
              <div className="border border-black rounded-lg p-2 flex flex-col gap-1.5 max-w-[228px] w-full justify-center h-fit">
                <div
                  // onClick={() => handleCancelClass(index)}
                  onClick={() => openCancelModal(index, 'single')}
                  className="font-semibold text-[10.5px] text-black cursor-pointer"
                >
                  Cancel this class
                </div>
                {item.repeat_group_session_participant_id === null &&
                item.repeat_session_uuid === null ? null : (
                  <>
                    <div
                      onClick={() => openCancelModal(index, 'future')}
                      className="font-semibold text-[10.5px] text-black cursor-pointer"
                    >
                      Cancel all future classes
                    </div>
                    <div
                      onClick={() => openCancelModal(index, 'repeat')}
                      className="font-semibold text-[10px] text-black cursor-pointer"
                    >
                      Cancel all repeat classes under this period
                    </div>
                  </>
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {CancelledReason()}
    </>
  );
}

export default VacationPauseMembershipDetails;
