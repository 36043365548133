import React from 'react';
import PropTypes from 'prop-types';

const keyStrokeListenerFunction = (e, onClick) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    onClick();
  }
};

function Button(props) {
  let class_name = '';
  if (props.type === 'secondary') {
    class_name += ' button-secondary';
  }
  if (props.disabled) {
    class_name += ' button-disabled';
  }
  return (
    <button
      style={props.custom_style}
      className={class_name}
      onClick={() => (props.disabled ? null : props.onClick())}
      tabIndex="0"
      onKeyPress={(e) => keyStrokeListenerFunction(e, props.onClick)}
    >
      {props.children}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  custom_style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  custom_style: {},
  onClick: () => console.log('button clicked'),
  type: 'primary'
};

export default Button;
