import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  image_error_replace,
  getCookie,
  combine_name,
  check_null_text
} from '../../../util_functions';

import * as actions from '../../../redux/action_creators';

import HighlightSearch from '../../util_components/highlight_search_text';
import './home.css';
import './list.css';
import { CareTicket } from '../../../../myt_chat/src/chat/packets/CareTicket';
import ChatClient from '../../../../myt_chat/src/chat/network/ChatClient';
import { CallbackHandler } from '../../../../myt_chat/src/chat/callbacks/handler';
import ConnectionState from '../../../../myt_chat/src/chat/enums/ConnectionState';
import CareHandler from '../../../../myt_chat/src/chat/network/CareHandler';
import UserRole from '../../../../myt_chat/src/chat/enums/UserRole';
import Message from '../../../../myt_chat/src/chat/models/Message';
import { ChatWindowManager, openChatWithUserUUID } from '../chat';

type StudentList = Array<{
  profile_photo: string;
  student_uuid: string;
  sq_fname: string;
  sq_mname: string;
  sq_lname: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email_verified: number;
  phone_personal: string;
  yoga_level: string;
  credits: string;
}>;

type ChatHomeProps = {
  cms_token: string;
  search_students_list: StudentList;
  student_search_status: string;
  search_students: (item: { search_param: string }) => void;
};

type ChatHomeState = {
  chat_count: number;
  search_text: string;
  search_network: string;
  student_unread: boolean;
  chatLoading: boolean;
  tickets: Array<CareTicket>;
  allTickets: Array<CareTicket>;
  isTerminated: boolean;
  closedTickets: Map<string, boolean>;
};

class ChatHome extends React.Component<ChatHomeProps, ChatHomeState> {
  state: ChatHomeState = {
    chat_count: 0,
    search_text: '',
    search_network: '',
    student_unread: false,
    chatLoading: true,
    isTerminated: false,
    tickets: [],
    allTickets: [],
    closedTickets: new Map<string, boolean>()
  };

  messageListener = (message: Message) => {
    if (message.sender.role !== UserRole.STUDENT) {
      //only showing student tickets
      return;
    }

    const items = new Map<string, CareTicket>();

    this.state.allTickets.forEach((ticket) => {
      items.set(ticket.user, ticket);
    });

    const newTicket: CareTicket = {
      user: message.sender.uuid,
      name: message.sender.name,
      avatar: message.sender.avatar,
      role: message.sender.role,
      message: message,
      unread: 0
    };

    if (items.has(message.sender.uuid)) {
      newTicket.unread = Math.max(items.get(message.sender.uuid)!.unread, 0) + 1;
    } else if (!items.has(message.sender.uuid) || this.state.closedTickets.has(newTicket.user)) {
      newTicket.unread = Math.max(newTicket.unread, 1);
    }

    items.set(newTicket.user, newTicket);

    this.state.closedTickets.delete(newTicket.user);
    const tickets = CareHandler.shared.sortTickets(Array.from(items.values()));

    this.setState(
      {
        allTickets: tickets,
        closedTickets: this.state.closedTickets
      },
      () => {
        this.updateTickets(tickets, this.state.search_text);
      }
    );
  };

  connectionListener = (state: ConnectionState) => {
    if (state === ConnectionState.AUTHENTICATED) {
      CareHandler.shared.fetchP2PCareTickets(UserRole.STUDENT).then(this.onCareTicketsFetched);
    }
  };

  componentDidMount() {
    this.setState({ chat_count: Math.floor(window.innerWidth / 390) });

    CallbackHandler.shared.registerOnMessageAddedListener(this.messageListener);
    CallbackHandler.shared.registerConnectionListener(this.connectionListener);

    ChatClient.shared.initialize();

    this.connectionListener(ChatClient.shared.connectionState());
  }

  componentWillUnmount() {
    CallbackHandler.shared.unregisterOnMessageAddedListener(this.messageListener);
    CallbackHandler.shared.unregisterConnectionListener(this.connectionListener);
  }

  onCareTicketsFetched = (result: { tickets: Array<CareTicket> }) => {
    this.setState(
      {
        allTickets: result.tickets
      },
      () => {
        this.updateTickets(result.tickets, '');
      }
    );
  };

  updateTickets = (tickets: Array<CareTicket>, searchQuery: string) => {
    let filtered = tickets.filter((ticket) => {
      return ticket.name.toLowerCase().includes(searchQuery);
    });

    this.setState({
      tickets: filtered,
      chatLoading: false,
      search_text: searchQuery
    });
  };

  filterTickets = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value.toLowerCase().trim();
    this.updateTickets(this.state.allTickets, searchQuery);
  };

  on_chat_click = (ticketItem: CareTicket) => {
    const allTickets = this.state.allTickets.map((x) => {
      if (x.user === ticketItem.user) {
        x.unread = 0;
      }

      return x;
    });

    const tickets = this.state.tickets.map((x) => {
      if (x.user === ticketItem.user) {
        x.unread = 0;
      }

      return x;
    });

    this.setState({
      allTickets: allTickets,
      tickets: tickets
    });

    openChatWithUserUUID(ticketItem.user, UserRole.STUDENT);
  };

  render_single_student = (item: CareTicket, index: number) => {
    return (
      <div className="ch-sc-cb" key={index + item.user} onClick={() => this.on_chat_click(item)}>
        <div className="ch-sc-cb-i">
          <img src={item.avatar} alt="pic" onError={image_error_replace} />
        </div>
        <p className="ch-p">{item.name}</p>
        {item.unread > 0 ? <div className="ch-sc-bd">{item.unread}</div> : null}

        {item.unread === 0 ? (
          <>
            {!this.state.closedTickets.has(item.user) ? (
              <div className="close-tckt">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    let res = window.confirm(
                      `Are you sure you want to close the ticket with ${item.name}?`
                    );
                    if (res) {
                      CareHandler.shared
                        .closeP2PCareTicket(item.user, Date.now() * 1000)
                        .then(() => {
                          this.setState({
                            closedTickets: this.state.closedTickets.set(item.user, true)
                          });
                        });
                    }
                  }}
                >
                  Close Ticket
                </button>
              </div>
            ) : (
              <div className="close-tckt">Ticket Closed</div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  ignoreCatch = (error) => {
    console.log(error);
  };

  render_student_chats = () => {
    if (this.state.chatLoading) {
      return <h2>Chat Initializing. Please wait...</h2>;
    }
    return (
      <>
        <div className="ch-sc-h">
          <p>Student chats - {this.state.allTickets.length}</p>
          <input
            value={this.state.search_text}
            name="student_search"
            placeholder="Enter name to search"
            onChange={this.filterTickets}
          />
        </div>
        {this.state.search_text !== '' ? (
          <div
            style={{
              textAlign: 'center',
              fontSize: '18px',
              padding: '20px',
              border: '1px solid',
              borderRight: 'none'
            }}
          >
            Search whole database for{' '}
            <span
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                this.setState(
                  {
                    search_network: this.state.search_text
                  },
                  () => {
                    if (this.state.search_network.length > 2)
                      this.props.search_students({ search_param: this.state.search_network });
                  }
                );
              }}
            >
              {' '}
              '{this.state.search_text}' {'-->'}
            </span>{' '}
          </div>
        ) : null}
        <div className="ch-sc-ch">{this.state.tickets.map(this.render_single_student)}</div>
      </>
    );
  };

  render_chat_container = () => {
    return (
      <div className="ch-c">
        <div className="ch-sc">{this.render_student_chats()}</div>

        {this.state.search_network !== '' ? (
          <div className="ch-sc">
            <div style={{ textAlign: 'center', fontSize: '18px', padding: '20px' }}>
              Database search results for '
              <span style={{ cursor: 'pointer', color: 'blue' }}>{this.state.search_network}</span>'
            </div>
            {this.render_display_text()}
          </div>
        ) : null}
        {this.state.isTerminated ? (
          <div style={{ textAlign: 'center', fontSize: '18px', padding: '20px' }}>
            Hridaya Chat is opened on a new browser tab, hence the service is disconnected here.
            Please refresh this window if you want to resume chat service on this browser tab.
          </div>
        ) : null}
      </div>
    );
  };

  render_display_text = () => {
    const search_value = this.state.search_network.trim();

    if (search_value.length >= 3 && this.props.student_search_status === 'success') {
      return this.render_students();
    }

    let display_text = '';

    if (search_value.length === 0) {
      display_text = 'Enter name, email or phone number to search';
    } else if (search_value.length > 0 && search_value.length < 3) {
      display_text = 'Please type atleast 3 letters to search';
    } else if (this.props.student_search_status === 'loading') {
      display_text = 'Loading...';
    }

    return <div className="sl-display-cont">{display_text}</div>;
  };

  render() {
    if (!!!this.props.cms_token) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="ch">
          <Helmet>
            <meta charSet="utf-8" />
            <title>All Chats</title>
          </Helmet>
          <div className="ch-h" style={{ justifyContent: 'start' }}>
            <a href="/">
              <img src="https://images.myyogateacher.com/banyan-logo-200.png" alt="logo-1" />
            </a>
            <p style={{ marginLeft: '20px' }}>All chats</p>
          </div>
          {this.render_chat_container()}
        </div>
        <ChatWindowManager />
      </>
    );
  }

  render_students = () => {
    let students = [...this.props.search_students_list].filter(
      (x) => 'student_uuid' in x && x.student_uuid !== ''
    );

    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-qf">
            <img src="" alt="i" onError={image_error_replace} />
          </div>
          <div className="ct-col">Student</div>

          {/* <div className="ct-col">USER TYPE</div> */}
          <div className="ct-col">PHONE</div>
        </div>
        {students.map((student, index) => {
          const profile_photo = !!student.profile_photo ? student.profile_photo : '';
          const tr_class = !!student.student_uuid
            ? 'ct-row ct-cur tr-cursor-row'
            : 'ct-row tr-no-uuid';
          const sq_name = combine_name(student.sq_fname, student.sq_mname, student.sq_lname).trim();
          const name = combine_name(
            student.first_name,
            student.middle_name,
            student.last_name
          ).trim();
          return (
            <div
              className={tr_class}
              key={student.student_uuid}
              onClick={() => {
                openChatWithUserUUID(student.student_uuid, UserRole.STUDENT);
              }}
              data-id={student.student_uuid}
            >
              <div className="ct-col ct-qf">
                <img src={profile_photo} alt="i" onError={image_error_replace} />
              </div>
              <div className="ct-col ct-bw">
                <div style={{ fontSize: '17px' }}>
                  <HighlightSearch
                    full_text={name ?? sq_name}
                    search_text={this.state.search_network}
                  />
                </div>
                {!!sq_name && sq_name !== 'User' && sq_name !== name ? (
                  <div style={{ fontSize: '14px', opacity: '0.7' }}>
                    <HighlightSearch full_text={sq_name} search_text={this.state.search_network} />
                  </div>
                ) : null}
              </div>
              <div className="ct-col ct-si">
                {student.email_verified === 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>

              <div className="ct-col ct-ho">
                <HighlightSearch
                  full_text={check_null_text(student.phone_personal)}
                  search_text={this.state.search_network}
                />
              </div>
              <div className="ct-col ct-ho">{student.yoga_level}</div>
              <div className="ct-col ct-ho ct-hf">{student.credits}</div>
            </div>
          );
        })}
      </div>
    );
  };
}

type GlobalState = {
  home: {
    cms_token: string;
  };
  students: {
    search_students_list: StudentList;
  };
  loading: {
    student_search_status: string;
  };
};

const mapStateToProps = (state: GlobalState) => {
  return {
    cms_token: state.home.cms_token,
    search_students_list: state.students.search_students_list,
    student_search_status: state.loading.student_search_status
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search_students: (payload: { search_param: string }) => {
      dispatch(actions.search_students(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHome);
