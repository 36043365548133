import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment-timezone';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import CustomLoading from '../../util_components/custom_loading';
import * as actions from '../../../redux/action_creators';
import './community.css';
import CustomModal from '../../util_components/custom_modal';
import CommonHeader from '../../util_components/common_header';
import CustomSingleSelect from '../../util_components/custom_single_select';
import { time_day_format, session_duration_list } from '../../../constants';
import CustomInput from '../../util_components/custom_input';

class CommunityClassesRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestList: false,
      showAssignTeacherModal: false,
      teachers: [],
      teacher_selected: '',
      selectedAssignTeacherItem: null,
      loading: true,
      classDetails: {},
      cls_details_start_date: '',
      cls_details_start_time: '',
      showDetails: false
    };

    this.proposalUUid = new URLSearchParams(props.location.search).get('proposal');
    this.action = new URLSearchParams(props.location.search).get('action');
  }

  componentDidMount() {
    this.props.get_proposal_info();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.setState({ loading: false });
      this.set_teachers_list();
    }

    if (
      prevProps.get_proposal_info_status === 'loading' &&
      this.props.get_proposal_info_status === 'success'
    ) {
      this.setState({ loading: false });
      if (this.proposalUUid) {
        this.props.get_proposal.items.map((req, index) => {
          if (req.proposal.uuid === this.proposalUUid) {
            if (this.action === 'promote') {
              this.props.history.push('promote_class', { item: req });
              this.proposalUUid = null;
              this.action = null;
            } else if (this.action === 'reject') {
              this.props.reject_proposal({ uuid: this.proposalUUid });
              this.proposalUUid = null;
              this.action = null;
            }
          }
        });
      }
    }

    if (
      prevProps.get_proposal_reject_status === 'loading' &&
      this.props.get_proposal_reject_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Request rejected');
      this.props.get_proposal_info();
    }

    if (
      prevProps.proposal_teacher_request_status === 'loading' &&
      this.props.proposal_teacher_request_status === 'success'
    ) {
      this.setState({ loading: false });
      this.setState({ showRequestList: true });
    }

    if (
      prevProps.get_proposal_accept_status === 'loading' &&
      this.props.get_proposal_accept_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Request accepted');
      this.props.get_proposal_info();
    }

    if (
      prevProps.community_proposal_accept_status === 'loading' &&
      this.props.community_proposal_accept_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Request accepted');
      this.props.get_proposal_info();
      this.setState({
        teacher_selected: '',
        showAssignTeacherModal: false,
        selectedAssignTeacherItem: null
      });
    }

    if (
      prevProps.community_proposal_ignore_status === 'loading' &&
      this.props.community_proposal_ignore_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Request rejected');
      this.props.get_proposal_info();
    }

    if (
      prevProps.community_cancel_proposal_status === 'loading' &&
      this.props.community_cancel_proposal_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Request deleted');
      this.props.get_proposal_info();
    }

    if (
      prevProps.community_cancel_proposal_status === 'loading' &&
      this.props.community_cancel_proposal_status === 'fail'
    ) {
      this.setState({ loading: false });
    }

    if (
      prevProps.community_update_proposal_class_status_status === 'loading' &&
      this.props.community_update_proposal_class_status_status === 'success'
    ) {
      this.setState({ loading: false });
      alert('Class status updated to trial end');
      this.props.get_proposal_info();
    }

    if (
      prevProps.community_update_proposal_class_status_status === 'loading' &&
      this.props.community_update_proposal_class_status_status === 'fail'
    ) {
      this.setState({ loading: false });
    }

    if (
      prevProps.modify_proposals_data_status === 'loading' &&
      this.props.modify_proposals_data_status === 'success'
    ) {
      alert('Success');
      this.setState({ loading: false });
      this.props.get_proposal_info();
    }
    if (
      prevProps.modify_proposals_data_status === 'loading' &&
      this.props.modify_proposals_data_status === 'fail'
    ) {
      this.setState({ loading: false });
    }
  }

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;

    this.setState({
      [key]: value
    });
  };

  handle_change = (e) => {
    const classDetailsObj = { ...this.state.classDetails };
    classDetailsObj.proposal[e.target.name] = e.target.value;

    console.log('classDetailsObj', classDetailsObj);
    this.setState({
      classDetails: classDetailsObj
    });
  };

  set_date = (date) => {
    // const start_date = date.join("\n");
    // let dateArray = [];
    // start_date.split("\n").map((d) => {
    //   dateArray.push(d);
    // });

    this.setState({ cls_details_start_date: date });
  };

  set_time = (time) => {
    this.setState({ cls_details_start_time: time });
  };

  update_class_details = (proposal) => {
    this.close_modal();
    this.setState({ loading: true });

    const { uuid, description, duration, name } = proposal;

    // let weekdays = new Set();
    // let startDate = [...this.state.cls_details_start_date];
    // startDate
    //   .sort(function (left, right) {
    //     return moment.utc(left).diff(moment.utc(right));
    //   })
    //   .map((t) => {
    //     weekdays.add(moment(t).utc().format("dddd"));
    //   });

    const dt = `${this.state.cls_details_start_date.toString()} ${this.state.cls_details_start_time.toString()}:00`;

    const datetime = moment.tz(dt, this.props.iana_timezone).utc().format('YYYY-MM-DD HH:mm:ss');

    console.log('datetime', datetime);

    this.props.modify_proposal_data({
      uuid,
      name,
      description,
      duration: parseInt(duration),
      datetime
      // weekdays: Array.from(weekdays),
    });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.onboarding_status === 'APPROVED');

    // let teacher_selected = "";

    // if (all_teachers.length > 0) {
    //   teacher_selected = all_teachers[0].uuid;
    // }

    this.setState({ teachers: all_teachers });
  };

  renderHeader = () => {
    const item = this.props.get_proposal ? this.props.get_proposal.items : [];

    if (item.length === 0) {
      return null;
    }

    const data = [
      'Request No.',
      'Requestor',
      'Requested by',
      'Request type',
      'Class Name',
      'Class Description',
      'Class Level',
      'Day & Time',
      'Teacher Name',
      'Teacher Request List',
      'Status',
      'Attendance',
      'ACTION - OPS '
    ];

    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-la ct-pl-10">Name</div>
          <div className="ct-col ">Requestor</div>
          <div className="ct-col">Teacher</div>
          <div className="ct-col ct-hf">Day & Time</div>
          <div className="ct-col ct-hf">By</div>
          {/* <div className="ct-col ct-hf">Type</div> */}
          {/* <div className="ct-col">Class Description</div> */}
          {/* <div className="ct-col ct-hf">Level</div> */}
          <div className="ct-col ct-qf ct-sm-font">Request List</div>
          <div className="ct-col ct-hf">Status</div>
          {/* <div className="ct-col">Attendance</div> */}
          <div className="ct-col">ACTION</div>
          <div className="ct-col ct-hf">Details</div>
          <div className="ct-col ct-hf">Request Action</div>
        </div>

        {item.map((i, index) => this.render_single_item(i, index))}
      </div>
    );
  };

  render_single_item = (i, index) => {
    const { uuid, level, name, description, status, type, datetime, day, time } = i.proposal;

    const requestorObj = i.requester;
    const acceptorObj = i.acceptor;
    const isRequested = status === 'Requested';
    const isTrialEnd = status === 'Trial End';
    const isTrialStart = status === 'Trial Start';
    const isRejected = status === 'Rejected';
    const isPromoted = status === 'Promoted';
    const isIgnored = status === 'Ignored';
    const isCancelled = status === 'Cancelled';
    const isByTeacher = acceptorObj.role == 'teacher';

    let opsActionText = '';
    let opsAction;
    if (isRequested && isByTeacher) {
      opsActionText = 'Accept';
      opsAction = () => {
        this.setState({ loading: true });
        this.props.accept_proposal({
          uuid,
          teacher_id: requestorObj.uuid
        });
      };
    } else if (isRequested) {
      opsActionText = 'Assign Teacher';
      opsAction = () => {
        if (this.props.teachers_list_status === 'none') {
          this.setState({ loading: true });
          this.props.get_teachers_list();
        }

        this.setState({
          showAssignTeacherModal: true,
          selectedAssignTeacherItem: i
        });
      };
    } else if (isTrialEnd) {
      opsActionText = 'Promote to regular class';
      opsAction = () => {
        this.props.history.push('promote_class', { item: i });
        // this.props.promote_proposal();
      };
    }

    const showRejectBtn =
      !isPromoted &&
      !isTrialStart &&
      !isRejected &&
      !isIgnored &&
      !isCancelled &&
      (isTrialEnd || isByTeacher);

    const requester_url = `/${
      requestorObj.role.toLowerCase() === 'teacher' ? 'teacherprofile' : 'studentprofile'
    }?id=${requestorObj.uuid}`;
    const acceptor_url = `/teacherprofile?id=${acceptorObj.uuid}`;

    return (
      <div key={i} className="ct-row">
        <div className="ct-col ct-sm-font ct-la ct-pl-10">{name}</div>
        <div className="ct-col ct-sm-font ct-la ct-pl-10">
          <a className="link-no-dec" href={requester_url}>
            {requestorObj.name}
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={acceptor_url}>
            {acceptorObj.name}
          </a>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          {/* <div>{day}</div>
          <div>{time}</div> */}
          <div>{moment(datetime).tz(this.props.iana_timezone).format(time_day_format)}</div>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          {requestorObj.role.toLowerCase() == 'teacher' ? 'Teacher' : 'Student'}
        </div>
        {/* <div className="ct-col ct-hf ct-sm-font">{type}</div> */}
        {/* <div className="ct-col">{}</div> */}
        {/* <div className="ct-col ct-hf ct-sm-font">{level}</div> */}
        <div className="ct-col ct-qf">
          <button
            onClick={() => {
              this.setState({ loading: true });
              this.props.proposal_teacher_request({ uuid });
            }}
          >
            i
          </button>
        </div>
        <div className="ct-col ct-hf ct-sm-font">{status}</div>
        {/* <div className="ct-col ct-la">
          {i.attendances
            .map((a) => {
              return a.count;
            })
            .join(", ")}
        </div> */}
        <div className="ct-col ct-sm-font">
          {!!opsActionText && (
            <button onClick={opsAction} props>
              {opsActionText}
            </button>
          )}
          {showRejectBtn && (
            <button
              onClick={() => {
                if (isTrialEnd) {
                  this.props.reject_proposal({ uuid });
                } else {
                  this.props.community_proposal_ignore({ uuid });
                }
              }}
            >
              Reject
            </button>
          )}
        </div>
        {!isCancelled && (
          <div className="ct-col ct-hf">
            <button
              onClick={() => {
                // For multi date selection

                // const cls_details_start_date = [
                //   moment(i.proposal.datetime)
                //     .tz(this.props.iana_timezone)
                //     .format("YYYY/MM/DD"),
                // ];
                // const cls_details_start_time = new DateObject().set({
                //   hour: moment(i.proposal.datetime)
                //     .tz(this.props.iana_timezone)
                //     .format("HH"),
                //   minute: moment(i.proposal.datetime)
                //     .tz(this.props.iana_timezone)
                //     .format("mm"),
                // });

                // For single date selection
                const cls_details_start_date = moment
                  .tz(i.proposal.datetime, this.props.iana_timezone)
                  .format('YYYY-MM-DD');
                const cls_details_start_time = new DateObject().set({
                  hour: moment.tz(i.proposal.datetime, this.props.iana_timezone).format('HH'),
                  minute: moment.tz(i.proposal.datetime, this.props.iana_timezone).format('mm')
                });
                this.setState({
                  cls_details_start_date,
                  cls_details_start_time,
                  classDetails: i,
                  showDetails: true
                });
              }}
            >
              View
            </button>
          </div>
        )}
        <div className="ct-col ct-hf">
          {!isRejected && !isIgnored && !isCancelled && (
            <button
              onClick={() => {
                this.setState({ loading: true });
                this.props.community_cancel_proposal({ uuid });
              }}
            >
              Delete Request
            </button>
          )}
          {isTrialStart && (
            <button
              onClick={() => {
                this.props.community_update_proposal_class_status({
                  uuid
                });
              }}
            >
              Update status to Trial End
            </button>
          )}
        </div>
      </div>
    );
  };

  renderRequestList = () => {
    if (this.props.proposal_teacher_request_response == undefined) {
      return null;
    }

    if (this.props.proposal_teacher_request_response.requests.length === 0) {
      return <div>No teacher received any request</div>;
    }
    return this.props.proposal_teacher_request_response.requests.map((data, index) => {
      const { avatar_large, avatar_round, name, uuid } = data.teacher;

      const status = this.props.proposal_teacher_request_response.status_types[data.request.status];

      return (
        <div key={index} className="ct-row">
          <div className="ct-col ct-la">{index + 1}</div>
          <div className="ct-col ct-la">{uuid}</div>
          <div className="ch-sc-cb-i">
            <img src={avatar_round} alt="pic" />
          </div>
          <div className="ct-col ct-la">{name}</div>

          <div className="ct-col ct-la">{status}</div>
        </div>
      );
    });
  };

  renderAssignTeacher = () => {
    const teachers_list = this.state.teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    return (
      <CustomModal
        show={this.state.showAssignTeacherModal}
        close={() => {
          this.setState({
            teacher_selected: '',
            showAssignTeacherModal: false,
            selectedAssignTeacherItem: null
          });
        }}
      >
        <CustomSingleSelect
          label="Assign a teacher"
          options={teachers_list}
          name="teacher_selected"
          onChange={this.onCommonChange}
          value={this.state.teacher_selected}
          style={{ width: '300px' }}
        />

        <button
          onClick={() => {
            this.props.community_proposal_accept({
              uuid: this.state.selectedAssignTeacherItem.proposal.uuid,
              teacher_id: this.state.teacher_selected
            });
          }}
        >
          Submit
        </button>
      </CustomModal>
    );
  };

  renderDetails = () => {
    const { classDetails } = this.state;
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');

    try {
      return (
        <div>
          <h2>Details:</h2>
          <CustomInput
            label="Enter session title"
            onChange={this.handle_change}
            name="name"
            value={classDetails.proposal.name}
            style={{ width: '300px' }}
          />
          <h5>Type:</h5>
          <p>{classDetails.proposal.type}</p>
          <h5>Level:</h5>
          <p>{classDetails.proposal.level}</p>
          <h5>Description:</h5>
          {/* <p>{classDetails.proposal.description}</p> */}
          <textarea
            className="community_class_desc"
            onChange={this.handle_change}
            value={classDetails.proposal.description}
            name="description"
          />

          <div>
            {/* <div>{`Select time (${moment()
              .tz(this.props.iana_timezone)
              .format("z")})`}</div> */}
            {/* <DatePicker
              selected={datetime}
              onChange={(date) => {
                let classDetailsObj = { ...this.state.classDetails };
                classDetailsObj.proposal["datetime"] = date;

                this.setState({
                  classDetails: classDetailsObj,
                });
              }}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            /> */}

            <div>
              <div>{`Select date and time (${curr_timezone})`}</div>
              <DatePicker
                value={this.state.cls_details_start_date}
                // multiple
                format="YYYY-MM-DD"
                onChange={this.set_date}
              />
              <DatePicker
                value={this.state.cls_details_start_time}
                placeholder="Select Time"
                disableDayPicker
                onChange={this.set_time}
                format="HH:mm"
                plugins={[<TimePicker hideSeconds />]}
              />
            </div>
          </div>

          <CustomSingleSelect
            label="Select session duration"
            options={session_duration_list}
            onChange={this.handle_change}
            name="duration"
            value={classDetails.proposal.duration}
            style={{ width: '250px' }}
          />

          <h4>Attendances:</h4>
          <p>{classDetails.attendances.map((a) => a.count).join(', ')}</p>
          <button
            onClick={() => {
              this.update_class_details(classDetails.proposal);
            }}
          >
            Update
          </button>
        </div>
      );
    } catch (e) {
      return null;
    }
  };

  close_modal = () =>
    this.setState({
      showRequestList: false,
      classDetails: {},
      showDetails: false
    });

  render() {
    const { loading, showRequestList, showDetails } = this.state;
    return (
      <CommonHeader loading={loading} title="Community Classes">
        {this.renderHeader()}
        <CustomModal show={showRequestList} close={this.close_modal}>
          {this.renderRequestList()}
        </CustomModal>
        {Object.keys(this.state.classDetails).length > 0 && (
          <CustomModal show={showDetails} close={this.close_modal}>
            {this.renderDetails()}
          </CustomModal>
        )}
        {this.renderAssignTeacher()}
        {this.state.loading && <CustomLoading />}
      </CommonHeader>
    );
  }
}

function mapStateToProps(state) {
  return {
    iana_timezone: state.home.iana_timezone,
    proposal_teacher_request_response: state.sessions.proposal_teacher_request_response,
    get_proposal: state.sessions.get_proposal,
    get_proposal_info_status: state.loading.get_proposal_info_status,
    teachers_list: state.teachers.teachers_list,
    teachers_list_status: state.teachers.teachers_list_status,
    community_proposal_accept_status: state.loading.community_proposal_accept_status,
    get_proposal_reject_status: state.loading.get_proposal_reject_status,
    get_proposal_accept_status: state.loading.get_proposal_accept_status,
    proposal_teacher_request_status: state.loading.proposal_teacher_request_status,
    community_proposal_ignore_status: state.loading.community_proposal_ignore_status,
    community_proposal_accept_response: state.sessions.community_proposal_accept_response,
    community_cancel_proposal_status: state.loading.community_cancel_proposal_status,
    community_update_proposal_class_status_status:
      state.loading.community_update_proposal_class_status_status,
    modify_proposals_data_status: state.loading.modify_proposals_data_status
  };
}

function mapDispatchToProps(dispatch) {
  return {
    proposal_teacher_request: (params) => dispatch(actions.proposal_teacher_request(params)),
    get_proposal_info: (params) => dispatch(actions.get_proposal_info(params)),
    promote_proposal: (params) => dispatch(actions.promote_proposal(params)),
    reject_proposal: (params) => dispatch(actions.reject_proposal(params)),
    community_proposal_ignore: (params) => dispatch(actions.community_proposal_ignore(params)),

    accept_proposal: (params) => dispatch(actions.accept_proposal(params)),
    get_teachers_list: (status) => {
      dispatch(actions.get_teachers_list(status));
    },
    community_proposal_accept: (status) => {
      dispatch(actions.community_proposal_accept(status));
    },
    community_cancel_proposal: (status) => {
      dispatch(actions.community_cancel_proposal(status));
    },
    community_update_proposal_class_status: (status) => {
      dispatch(actions.community_update_proposal_class_status(status));
    },
    modify_proposal_data: (status) => {
      dispatch(actions.modify_proposal_data(status));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityClassesRequest);
