import React from 'react';
import './chat.css';

import ChatWindow from '../p2p_chat_window';

import { createState } from 'state-pool';
import SecurityCheckPopup from './security_check_popup';
import Channel from '../../../../myt_chat/src/chat/models/Channel';
import ChannelType from '../../../../myt_chat/src/chat/enums/ChannelType';
import ChannelDBOps from '../../../../myt_chat/src/chat/database/ChannelDBOps';
import ChannelUtils from '../../../../myt_chat/src/chat/utils/ChannelUtils';
import UserRole from '../../../../myt_chat/src/chat/enums/UserRole';
import AccountHandler from '../../../../myt_chat/src/chat/network/AccountHandler';

type ChatWindowItem = {
  channel: Channel;
  openState: ChatOpenState;
};

export type ChatWindowManagerGlobalState = {
  windows: Array<ChatWindowItem>;
};

type ChatState = {
  chat_count: number;
  windows: Array<ChatWindowItem>;
  contentViolationPopup: boolean;
};

type ChatProps = {};

export enum ChatOpenState {
  CLOSED = 0,
  MINIMIZED = 1,
  OPEN = 2
}

function createDefaultState(): ChatWindowManagerGlobalState {
  return {
    windows: new Array<ChatWindowItem>()
  };
}

export const chatWindowManagerState = createState(createDefaultState()); // Create store for storing our global state

export const openChatWithUserUUID = async (userUUID: string, userRole: UserRole) => {
  console.log(`openChatWithChannelUUID: ${userUUID}, fetching...`);

  await AccountHandler.shared.fetchUserProfile(userUUID);

  const channelId = ChannelUtils.makeChannel(userUUID, userRole);
  const channel = await ChannelDBOps.shared.getChannel(channelId);

  if (channel === undefined) {
    console.log(`openChatWithChannelUUID: ${userUUID} failed, channel not found`);
    return;
  }

  openChatWindow(channel);
};

const openChatWindow = (channelItem: Channel) => {
  chatWindowManagerState.updateValue(
    (oldState: ChatWindowManagerGlobalState): ChatWindowManagerGlobalState => {
      let windowItemIndex = oldState.windows.findIndex(
        (item) => item.channel.uuid === channelItem.uuid
      );

      if (windowItemIndex === -1) {
        let item = {
          channel: channelItem,
          openState: ChatOpenState.OPEN
        };

        oldState.windows.unshift(item);
        return oldState;
      } else {
        let item = oldState.windows[windowItemIndex];
        item.openState = ChatOpenState.OPEN;

        oldState.windows.splice(windowItemIndex, 1);
        oldState.windows.unshift(item);

        return oldState;
      }
    }
  );
};

export class ChatWindowManager extends React.Component<ChatProps, ChatState> {
  constructor(props: ChatProps) {
    super(props);
    this.state = {
      chat_count: Math.floor(window.innerWidth / 390),
      windows: [],
      contentViolationPopup: false
    };
  }

  subscription: undefined | (() => void);

  componentDidMount(): void {
    this.subscription = chatWindowManagerState.subscribe(
      (newValue: ChatWindowManagerGlobalState) => {
        this.setState({
          windows: newValue.windows
        });
      }
    );
  }

  renderContentViolation = () => {
    this.setState({ contentViolationPopup: true });
  };

  closeContentViolation = () => {
    this.setState({ contentViolationPopup: false });
  };

  componentWillUnmount() {
    this.subscription?.();
  }

  render_chat_windows = () => {
    const displayable = Math.max(this.state.windows.length - this.state.chat_count, 0);

    const slice = this.state.windows.slice(displayable);
    return slice.map((item) => {
      if (item.channel.type === ChannelType.P2P) {
        return <ChatWindow channel={item.channel} key={item.channel.uuid} />;
      }

      return null;
    });
  };

  render() {
    return (
      <>
        <div className="ch-cws">{this.render_chat_windows()}</div>
        {this.state.contentViolationPopup ? (
          <SecurityCheckPopup close={this.closeContentViolation} />
        ) : null}
      </>
    );
  }
}
