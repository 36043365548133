import React, { Component } from 'react';
import moment from 'moment-timezone';
import CustomCheckbox from '../../../util_components/custom_checkbox';
import Button from '../../../util_components/button';

const package_titles = {
  group_class_packages: 'Group packages',
  '1_on_1_session_packages': '1-on-1 packages',
  gifting_pricing: 'Gift packages'
};
export default class CreateSubs extends Component {
  state = {
    package_key_selected: 'group_class_packages',
    package_uuid: '',
    gift_duration: '',
    gift_package_index: -1,
    show_summary: false,
    package_details: {},
    // show_gift: import.meta.env.VITE_APP_STAGE !== 'production'
    show_gift: true
  };

  render_single_gift_configuration = () => {
    const { package_key_selected, gift_duration, gift_package_index, package_uuid } = this.state;
    const packages = this.props.config_values.pricing_packages;
    const package_selected = packages[package_key_selected];
    const index = package_selected.findIndex((ps) => ps.duration === gift_duration);
    if (gift_package_index !== -1) {
      const config = package_selected[index].packages_configuration;
      const packages = config[gift_package_index].configuration;
      return (
        <div>
          {packages.map((pack) => (
            <div
              className="cre-member-options"
              onClick={() => this.setState({ package_uuid: pack.uuid, package_details: pack })}
            >
              <CustomCheckbox show_label={false} checked={pack.uuid === package_uuid} />
              <div className="cre-member-option">
                <div className="cre-member-heading">{pack.package}</div>
                <div className="cre-member-sub-heading">{pack.payment}</div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  render_gift_configuration = () => {
    const { package_key_selected, gift_duration, gift_package_index } = this.state;
    const packages = this.props.config_values.pricing_packages;
    const package_selected = packages[package_key_selected];
    const index = package_selected.findIndex((ps) => ps.duration === gift_duration);
    if (!!gift_duration && index !== -1) {
      const config = package_selected[index].packages_configuration;
      return (
        <div>
          <div className="cre-member-options-row">
            {config.map((con_pack, index) => (
              <div
                className="cre-member-options cre-mem-gift-options"
                onClick={() =>
                  this.setState({
                    gift_package_index: index,
                    package_uuid: '',
                    package_details: {}
                  })
                }
              >
                <CustomCheckbox show_label={false} checked={index === gift_package_index} />
                <div className="cre-member-option">
                  <div className="cre-member-sub-heading">{con_pack.heading}</div>
                </div>
              </div>
            ))}
          </div>
          {this.render_single_gift_configuration()}
        </div>
      );
    }
    return null;
  };

  render_gifting_packages = () => {
    const { package_key_selected, gift_duration } = this.state;
    const packages = this.props.config_values.pricing_packages;
    const package_selected = packages[package_key_selected];
    return (
      <div>
        <div className="cre-member-options-row">
          {package_selected.map((duration_pack) => (
            <div
              className="cre-member-options cre-mem-gift"
              onClick={() =>
                this.setState({
                  gift_duration: duration_pack.duration,
                  gift_package_index: -1,
                  package_uuid: '',
                  package_details: {}
                })
              }
            >
              <CustomCheckbox
                show_label={false}
                checked={duration_pack.duration === gift_duration}
              />
              <div className="cre-member-option">
                <div className="cre-member-heading">{duration_pack.display_duration}</div>
                <div className="cre-member-sub-heading">{duration_pack.discount}</div>
              </div>
            </div>
          ))}
        </div>
        {this.render_gift_configuration()}
      </div>
    );
  };

  render_other_packages = () => {
    const { package_key_selected, package_uuid } = this.state;
    const packages = this.props.config_values.pricing_packages;
    const package_selected = packages[package_key_selected];
    return (
      <div>
        {package_selected.packages.map((pack) => (
          <div
            className="cre-member-options"
            onClick={() => this.setState({ package_uuid: pack.uuid, package_details: pack })}
          >
            <CustomCheckbox show_label={false} checked={pack.uuid === package_uuid} />
            <div className="cre-member-option">
              <div className="cre-member-heading">{pack.heading}</div>
              <div className="cre-member-sub-heading">{pack.sub_heading}</div>
            </div>
            <div className="cre-member-pricing">{pack.pricing}</div>
          </div>
        ))}
      </div>
    );
  };

  confirm_purchase = () => {
    const { package_details, package_key_selected, package_uuid } = this.state;
    if (package_key_selected === 'gifting_pricing') {
      const start_date = moment().format('YYYY-MM-DD');
      const payload = {
        package_uuid,
        membership_start_date: start_date,
        message_date: start_date,
        self_purchase: 1,
        student_uuid: this.props.student_uuid,
        number_of_week: package_details.no_of_weeks
      };
      this.props.submit_gift(payload);
    } else {
      const payload = {
        package_uuid,
        student_uuid: this.props.student_uuid
      };
      this.props.submit_subscription(payload);
    }
  };

  render_summary = () => {
    const { package_details, package_key_selected, package_uuid } = this.state;
    const title =
      package_details.package || package_details.heading || package_details.package_name;
    const pricing = package_details.pricing
      ? `${package_details.pricing} ${package_details.duration}`
      : package_details.payment;
    return (
      <div>
        <h4>Confirm subscription</h4>
        <div className="subs-summ">
          <span>Subscription type:</span> {package_titles[package_key_selected]}
        </div>
        <div className="subs-summ">
          <span>Package:</span> {title}
        </div>
        {!!package_details.heading && !!package_details.sub_heading ? (
          <div className="subs-summ">
            <span>Details:</span> {package_details.sub_heading}
          </div>
        ) : (
          ''
        )}
        <div className="subs-summ">
          <span>Price:</span> {pricing}
        </div>
        <div className="subs-summ">
          <span>Uuid:</span> {package_uuid}
        </div>
        <div>
          <button onClick={this.confirm_purchase}>Confirm</button>
          <button onClick={this.toggle_summary}>Back</button>
        </div>
      </div>
    );
  };

  render_package_details = () => {
    const { package_key_selected } = this.state;
    if (package_key_selected === 'gifting_pricing') {
      return this.render_gifting_packages();
    }
    return this.render_other_packages();
  };

  render_single_package = (package_key) => {
    const label_style = { marginRight: '20px', fontSize: '17px' };
    const { package_key_selected } = this.state;
    if (
      package_key !== 'gifting_pricing' ||
      (package_key === 'gifting_pricing' && this.state.show_gift)
    ) {
      return (
        <CustomCheckbox
          label={package_titles[package_key]}
          checked={package_key === package_key_selected}
          onClick={() =>
            this.setState({
              package_key_selected: package_key,
              package_uuid: '',
              gift_duration: '',
              package_details: {}
            })
          }
          label_style={label_style}
        />
      );
    }
    return null;
  };

  toggle_summary = () => this.setState({ show_summary: !this.state.show_summary });

  render() {
    const packages = this.props.config_values.pricing_packages;
    const package_keys = Object.keys(packages);
    const { package_uuid, show_summary } = this.state;
    if (show_summary) {
      return this.render_summary();
    }
    return (
      <div>
        <h4>Create Subscription</h4>
        <div style={{ display: 'flex', margin: '10px 0', marginBottom: '25px' }}>
          {package_keys.map(this.render_single_package)}
        </div>
        {this.render_package_details()}
        <Button disabled={!package_uuid} onClick={this.toggle_summary}>
          Continue
        </Button>
      </div>
    );
  }
}
