import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import CustomModal from '../../util_components/custom_modal';
import Button from '../../util_components/button';
import { get_api_url } from '../../../utils/urls';
import CustomInput from '../../util_components/custom_input';
import Paginator from '../../util_components/paginator';
import * as actions from '../../../redux/action_creators';
import { post_api } from '../../../redux/api_funcs';
import { time_format, day_format_year } from '../../../constants';

export default function Sample() {
  const iana_timezone = useSelector((state) => state.home.iana_timezone);
  const [loading, setLoading] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailTemplateContent, setEmailTemplateContent] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [emailPreview, setEmailPreview] = useState('');
  const [emailPath, setEmailPath] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [emailEditTemplate, setEmailEditTemplate] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [pageSearch, setPageSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadPage, setReloadPage] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reloadPage) {
      loadData();
    }
    setReloadPage(false);
  }, [reloadPage]);

  const submitEdit = () => {
    setLoading(true);
    const url = get_api_url('update_email_template');
    const data = new FormData();
    data.append('image_data', image);
    data.append('file_name', imageUrl);
    data.append('template', emailEditTemplate);
    data.append('email_path', emailPath);
    data.append('is_create', isCreate ? 1 : 0);
    post_api(url, data, true, 'formdata')
      .then(() => {
        close_edit_modal();
        loadData();
      })
      .catch((e) => {
        setLoading(false);
        let err_message;
        try {
          err_message = e.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        dispatch(actions.set_notification_variable(true, 'error', err_message));
      });
  };

  const loadData = () => {
    console.log('load pageSearch, pageNumber', pageSearch, pageNumber);
    setLoading(true);
    const url = get_api_url('email_templates');
    const payload = { page_number: pageNumber, page_search: pageSearch };
    post_api(url, payload, true)
      .then((resp) => {
        const { email_templates, total_pages } = res.data;
        setEmailTemplates(email_templates);
        setLoading(false);
        setTotalPages(total_pages);
      })
      .catch((e) => {
        setLoading(false);
        let err_message;
        try {
          err_message = e.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        dispatch(actions.set_notification_variable(true, 'error', err_message));
      });
  };

  const loadEmailContentApi = (template) => {
    setLoading(true);
    const url = get_api_url('email_template_content');
    post_api(url, { template }, true)
      .then((resp) => {
        const { email_content } = resp.data;
        setEmailTemplateContent([...emailTemplateContent, { template, content: email_content }]);
        setShowPreview(true);
        setEmailPreview(email_content);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        let err_message;
        try {
          err_message = e.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        dispatch(actions.set_notification_variable(true, 'error', err_message));
      });
  };

  const openEmailEdit = (template, isCreate) => {
    setShowEdit(true);
    setEmailEditTemplate(template);
    setIsCreate(isCreate);
  };

  const renderEmailTemplates = () => (
    <div className="cus-table" style={{ marginBottom: '20px' }}>
      <div className="ct-row ct-h">
        <div className="ct-col">Template</div>
        <div className="ct-col ct-qf">Version</div>
        <div className="ct-col">Subject Format</div>
        <div className="ct-col">Created</div>
        <div className="ct-col">File Path</div>
        <div className="ct-col ct-hf">Preview</div>
        <div className="ct-col ct-hf">Update File</div>
      </div>
      {emailTemplates.map((em) => (
        <div className="ct-row" key={em.id}>
          <div className="ct-col ct-bw">{em.template}</div>
          <div className="ct-col ct-qf">{em.template_version}</div>
          <div className="ct-col">{em.subject_format}</div>
          <div className="ct-col">
            <div>{moment(em.created_date).tz(iana_timezone).format(day_format_year)}</div>
            <div>{moment(em.created_date).tz(iana_timezone).format(time_format)}</div>
          </div>
          <div className="ct-col ct-bw">{em.s3_file_path}</div>
          <div className="ct-col ct-hf">
            <button onClick={() => loadEmailContent(em.template)}>View</button>
          </div>
          <div className="ct-col ct-hf">
            <button onClick={() => openEmailEdit(em.template, false)}>Update</button>
          </div>
        </div>
      ))}
      <Paginator
        curr_page={pageNumber}
        page_limit={totalPages}
        pageChange={(page) => changePage(page)}
      />
    </div>
  );

  const loadEmailContent = (template) => {
    const index = emailTemplateContent.findIndex((c) => c.template === template);
    if (index !== -1) {
      const emailContent = emailTemplateContent[index].content;
      setShowPreview(true);
      setEmailPreview(emailContent);
    } else loadEmailContentApi(template);
  };

  const render_preview_modal = () => (
    <iframe
      style={{ width: '100%', minHeight: '800px' }}
      id="lkansdnfklsd"
      name="laskmdflksdf"
      sandbox="allow-same-origin"
      srcDoc={emailPreview}
    />
  );

  const handleFileChange = (e) => {
    const reader = new FileReader();
    const image_file = e.target.files[0];
    reader.onloadend = () => {
      setImage(image_file);
      setImageUrl(image_file.name);
    };
    reader.readAsDataURL(image_file);
  };

  const changePage = (page) => {
    setPageNumber(page);
    setReloadPage(true);
  };

  const searchPage = () => {
    setPageNumber(0);
    setPageSearch(pageSearch);
    setReloadPage(true);
  };

  const clearPage = () => {
    setPageNumber(1);
    setPageSearch('');
    setReloadPage(true);
  };
  const render_edit = () => {
    const title = isCreate ? `Create template` : `Update template for ${emailEditTemplate}`;
    const isDisabled = isCreate
      ? !emailEditTemplate || !emailPath || !imageUrl
      : !emailPath || !imageUrl;
    return (
      <div>
        <h4 style={{ marginBottom: '20px' }}>{title}</h4>
        {isCreate ? (
          <CustomInput
            label="Template*"
            onChange={(e) => setEmailEditTemplate(e.target.value)}
            value={emailEditTemplate}
            style={{ width: '300px' }}
          />
        ) : null}
        <CustomInput
          label="Email path*"
          onChange={(e) => setEmailPath(e.target.value)}
          value={emailPath}
          style={{ width: '300px' }}
        />
        <input type="file" id="pic-input" accept=".html" onChange={handleFileChange} name="photo" />
        <Button disabled={isDisabled} onClick={submitEdit}>
          Submit
        </Button>
      </div>
    );
  };

  const renderSearchBar = () => (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <div>
        <CustomInput
          label="Search name"
          onChange={(e) => setPageSearch(e.target.value)}
          value={pageSearch}
          style={{ width: '500px' }}
        />
      </div>
      <Button disabled={!pageSearch} onClick={() => searchPage()}>
        Search
      </Button>
      <Button disabled={!pageSearch} onClick={() => clearPage()}>
        Clear
      </Button>
    </div>
  );

  const close_edit_modal = () => {
    setEmailPath('');
    setShowEdit(false);
    setImage(null);
    setImageUrl('');
    setIsCreate(false);
  };

  return (
    <CommonHeader loading={loading} title="Email Templates" show_back>
      <button onClick={() => openEmailEdit('', true)}>Create</button>
      {renderSearchBar()}
      {renderEmailTemplates()}
      <CustomModal
        show={showPreview}
        inner_style={{ maxWidth: '80%', boxSizing: 'border-box' }}
        close={() => setShowPreview(false)}
      >
        {render_preview_modal()}
      </CustomModal>
      <CustomModal show={showEdit} close={close_edit_modal}>
        {render_edit()}
      </CustomModal>
    </CommonHeader>
  );
}
