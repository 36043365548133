import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { image_error_replace, check_bool_flag } from '../../../util_functions';

class TeacherRepeatSessions extends Component {
  state = {
    loading: false,
    group_repeat_sessions: [],
    yoga_repeat_sessions: []
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('uuid');
    if (uuid) {
      this.setState({ teacher_uuid: uuid }, () => this.load_data());
    } else {
      this.props.history.push('/teacherslist');
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('teacher_repeat_sessions');
      const payload = {
        teacher_uuid: this.state.teacher_uuid
      };
      console.log('url', url);
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            group_repeat_sessions: [...res.data.group_repeat_sessions],
            yoga_repeat_sessions: [...res.data.yoga_repeat_sessions],
            loading: false
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_repeat_group_sessions = () => {
    const { group_repeat_sessions } = this.state;
    if (group_repeat_sessions.length > 0) {
      return (
        <div>
          <h4>Repeat Group Sessions:</h4>
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-la">Session Name</div>
              <div className="ct-col ct-la">Class Page</div>
              <div className="ct-col">Time</div>
              <div className="ct-col ct-qf">Active</div>
            </div>
            {group_repeat_sessions.map((s) => {
              const session_time = moment.utc().day(s.day_of_the_week).format('YYYY-MM-DD');
              const final_session_time = moment.utc(`${session_time} ${s.time_of_the_day}`);
              return (
                <div className="ct-row">
                  <div className="ct-col ct-la ct-sm-font">{s.group_session_name}</div>
                  <div className="ct-col ct-la ct-sm-font">
                    <a
                      href={`/group_class_page/${s.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {s.slug}
                    </a>
                  </div>
                  <div className="ct-col ct-sm-font">
                    <div>
                      Every{' '}
                      {final_session_time.tz(this.props.iana_timezone).format('dddd, hh:mm A z')}
                    </div>
                  </div>
                  <div className="ct-col ct-qf ct-si">
                    {s.is_active === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  render_repeat_yoga_sessions = () => {
    const { yoga_repeat_sessions } = this.state;
    if (yoga_repeat_sessions.length > 0) {
      return (
        <div>
          <h4>Repeat 1on1 Sessions</h4>
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">Teacher</div>
              <div className="ct-col">
                <div>Start Time</div>
                <div className="ct-sm-font">(Dur)</div>
              </div>
              <div className="ct-col ct-qf">Active</div>
            </div>
            {yoga_repeat_sessions.map((s) => {
              const teacher_url = `/studentprofile?id=${s.student_uuid}`;
              return (
                <div className="ct-row" key={s.uuid}>
                  <div className="ct-col">
                    <a
                      className="link-no-dec"
                      href={teacher_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="profile-img-name">
                        <img src={s.profile_photo} onError={image_error_replace} alt="x" />
                        {s.student_name}
                      </div>
                    </a>
                  </div>
                  <div className="ct-col">
                    <div>
                      Every{' '}
                      {moment(s.repeat_time).tz(this.props.iana_timezone).format('dddd, hh:mm A z')}
                    </div>
                    <div>({s.duration} mins)</div>
                  </div>
                  <div className="ct-col ct-qf ct-si">
                    {s.is_active === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Teacher Repeat Sessions" show_back>
        {this.render_repeat_group_sessions()}
        {this.render_repeat_yoga_sessions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherRepeatSessions);
