import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import CustomModal from '../../util_components/custom_modal';
import CreateEditAnnouncement from './create_edit_announcement';
import { time_day_format, short_time_day_format } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class Announcements extends Component {
  state = {
    loading: false,
    announcements: [],
    create_announcement_open: false
  };

  componentDidMount() {
    this.load_data();
  }

  go_to_path = (path) => this.props.history.push(path);

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_announcements');
      post_api(url, null, true)
        .then((res) => {
          const announcements = [...res.data.announcements];
          const ann_flags = {};
          for (const ann of announcements) {
            ann_flags[`${ann.type}_open`] = true;
          }
          this.setState({ announcements, loading: false, ...ann_flags });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  submit_announcement = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_announcement');
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ create_announcement_open: false });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  toggle_announcement_open = () =>
    this.setState({ create_announcement_open: !this.state.create_announcement_open });

  render_single_announcement = (ann) => (
    <div className="ct-row" key={ann.uuid}>
      <div className="ct-col ct-qf">{ann.version}</div>
      <div className="ct-col ct-sm-font">{ann.name}</div>
      <div className="ct-col ct-qf">
        {ann.is_published === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        {ann.is_scheduled === 1 ? (
          <div>
            <div>Scheduled at </div>
            <div>
              {moment(ann.scheduled_date)
                .tz(this.props.iana_timezone)
                .format(short_time_day_format)}
            </div>
          </div>
        ) : null}
      </div>
      <div className="ct-col ct-hf ct-sm-font">{ann.published_by}</div>
      <div className="ct-col ct-hf ct-sm-font">
        <div>{moment(ann.created_date).tz(this.props.iana_timezone).format(time_day_format)}</div>
      </div>
      <div className="ct-col ct-hf">
        <button onClick={() => this.go_to_path(`/announcements/${ann.uuid}`)}>Details</button>
      </div>
    </div>
  );

  toggle_announcement_block = (flag) => this.setState({ [flag]: !this.state[flag] });

  render_single_block = (announcement_block) => {
    const open_flag = `${announcement_block.type}_open`;
    return (
      <div style={{ marginTop: '20px' }} key={announcement_block.type_uuid}>
        <div
          className="details-session-logs-header"
          onClick={() => this.toggle_announcement_block(open_flag)}
        >
          {announcement_block.name}: {announcement_block.announcements.length}
          {announcement_block.announcements.length !== 0 ? (
            <img
              className={this.state[open_flag] ? 'reverse-expand' : 'normal-expand'}
              src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
              alt="exp"
            />
          ) : null}
        </div>
        {announcement_block.announcements.length !== 0 && this.state[open_flag] ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col ct-qf">Version</div>
              <div className="ct-col">Name</div>
              <div className="ct-col ct-qf">Published</div>
              <div className="ct-col ct-hf">Created By</div>
              <div className="ct-col ct-hf">Created Date</div>
              <div className="ct-col ct-hf">Details</div>
            </div>
            {announcement_block.announcements.map(this.render_single_announcement)}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { loading, announcements, create_announcement_open } = this.state;
    return (
      <CommonHeader loading={loading} title="Announcements">
        <CustomModal show={create_announcement_open} close={this.toggle_announcement_open}>
          <CreateEditAnnouncement submit_announcement={this.submit_announcement} />
        </CustomModal>
        <button onClick={this.toggle_announcement_open}>Create Announcement</button>
        <div>{announcements.map(this.render_single_block)}</div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
