import React, { useEffect, useState } from 'react';
import { get_api_url } from '../../../utils/urls';
import { get_api } from '../../../redux/api_funcs';
import CommonHeader from '../../util_components/common_header';

export default function SessionFeedbackFormDetails() {
  const [loading, setLoading] = useState(true);
  const [feedbackData, setFeedbackData] = useState([]);
  const [response, setResponse] = useState({});
  const [sessionUUID, setSessionUUID] = useState('');
  const [errorData, setErrorData] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setLoading(true);
    const currentUrl = new URL(window.location.href);
    const UUID = currentUrl.searchParams.get('session_uuid');
    setSessionUUID(UUID);
    const apiUrl = `/v2/cms_user/sessions/feedbacks/${UUID}`;
    const url = get_api_url(apiUrl, null, true);
    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        setResponse(data);
        if (data?.feedback) {
          setFeedbackData(data?.feedback);
          setLoading(false);
        } else {
          setErrorData(true);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        let err_message;
        try {
          err_message = e?.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occurred. Please contact care team.';
        }
        setErrorData(true);
        setLoading(false);
      });
  };

  const renderTableRows = (feedback) => (
    <tr className="table-row-left-align">
      <td className="teacher-table-detail">{feedback.question}</td>
      <td>{feedback.answer}</td>
    </tr>
  );
  const renderFeedbackDetails = () => (
    <>
      <div>
        <h3>Details</h3>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">UUID</td>
              <td>{response?.uuid}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session uuid :</td>
              <td>{response.session_uuid}</td>
            </tr>
            {feedbackData.map(renderTableRows)}
          </tbody>
        </table>
      </div>
      <div className="my-5">
        <a href={`/session_score_card/edit?session_uuid=${sessionUUID}`} className="link-btn">
          Edit QA Score Card
        </a>
      </div>
    </>
  );

  const renderErrorData = () => (
    <div className="">
      <div className="text-center">
        <div>No QA Score</div>
      </div>
      <div className="my-5">
        <a href={`/session_score_card/create?session_uuid=${sessionUUID}`} className="link-btn">
          Add QA Score Card
        </a>
      </div>
    </div>
  );

  const renderSessionFeedbackDetails = () => {
    const title = 'QA Score Card';
    return (
      <CommonHeader loading={loading} title={title} meta_title="QA Score Card">
        {!loading && !errorData ? renderFeedbackDetails() : null}
        {errorData ? renderErrorData() : null}
      </CommonHeader>
    );
  };

  return renderSessionFeedbackDetails();
}
