import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomLoading from '../../../util_components/custom_loading';
import { image_error_replace } from '../../../../util_functions';
import CustomPlayer from '../../../util_components/custom_video_react';
import CustomModal from '../../../util_components/custom_modal';
import Button from '../../../util_components/button';
import { time_day_format, iana_values } from '../../../../constants';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import Paginator from '../../../util_components/paginator';
import * as actions from '../../../../redux/action_creators';
import * as selectors from '../../../../redux/selectors';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import '../future_sessions/future_sessions.css';

class PastSessions extends Component {
  state = {
    loading: true,
    session_details: {},
    show_video: false,
    video_url: '',
    sessions: [],
    teachers: [],
    download_sessions_open: false,
    download_start_time: null,
    download_end_time: null,
    download_data: [],
    page_limit: 0,
    past_sess_teacher_uuid: '',
    page: 1
  };

  componentDidMount() {
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
    // if (this.props.past_sessions_status === 'none') {
    // 	this.props.get_past_sessions();
    // } else if (this.props.past_sessions_status === 'success') {
    // 	this.set_sessions()
    // }
    this.load_sessions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.load_sessions();
    }
    if (
      prevProps.past_sessions_status === 'loading' &&
      this.props.past_sessions_status === 'success'
    ) {
      // this.set_sessions()
    }
    if (prevProps.past_sess_teacher_uuid !== this.props.past_sess_teacher_uuid) {
      // this.set_sessions()
    }
  }

  load_sessions = () =>
    this.setState({ loading: true }, () => {
      const urlString = window.location.href;
      const urlStr = new URL(urlString);
      let page = urlStr.searchParams.get('page');
      page = page > 0 ? page : 1;
      const url = get_api_url('get_all_past_sessions');
      const is_backpain = this.props.location.pathname === '/pastsessions/backpain' ? 1 : 0;
      const payload = {
        timezone: new Date().getTimezoneOffset(),
        page: parseInt(page),
        item_limit: 300,
        is_backpain
      };
      post_api(url, payload, true)
        .then((resp) => {
          this.setState(
            { loading: false, sessions: resp.data.sessions, page_limit: resp.data.page_limit },
            () => this.set_teachers_list()
          );
        })
        .catch((e) => {
          console.log(e);
        });
    });

  set_sessions = () => {
    this.setState({ loading: true }, () => {
      let sessions = this.props.past_sessions;
      if (this.props.past_sess_teacher_uuid) {
        sessions = sessions.filter((s) => s.teacher_uuid === this.props.past_sess_teacher_uuid);
      }
      this.setState({ sessions: [...sessions] }, () => {
        this.setState({ loading: false });
      });
    });
  };

  set_teachers_list = () => {
    const { sessions } = this.state;
    const teacher_uuids = [...new Set(sessions.map((s) => s.teacher_uuid))];
    const all_teachers = this.props.teachers_list.filter(
      (t) =>
        t.uuid === 'aa73fd2e-273d-4d6f-a8f7-ee90cdcf0a6b' ||
        ((t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY') &&
          teacher_uuids.indexOf(t.uuid) !== -1)
    );
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ teachers: [...teachers_list] });
  };

  open_session_viewer = (session_details) => {
    const search = `?session_uuid=${session_details.session_uuid}`;
    this.props.history.push({
      pathname: '/session_replay',
      search
    });
  };

  go_to_sessions = (id, type) => {
    let search = `?id=${id}`;
    let pathname = '/sessiondetails';
    if (type === 'GROUP_SESSION' || type === 'GROUP_SESSION_STUDENT') {
      search = `?session_uuid=${id}`;
      pathname = 'groupsessiondetails';
    }
    this.props.history.push({ pathname, search });
  };

  render_video = () => <CustomPlayer video_url={this.state.video_url} />;

  close_video = () => this.setState({ show_video: false });

  open_video = (url) =>
    this.setState({ video_url: url }, () => this.setState({ show_video: true }));

  // changePage = (page) => this.props.get_past_sessions(page)
  changePage = (page) => {
    window.location.replace(`/pastsessions/backpain?page=${page}`);
  };

  render_single_session = (session, index) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    const student_url = `/studentprofile?id=${session.student_uuid}`;
    const rating = session.student_rating === 0 ? '--' : session.student_rating;
    const rating_color =
      session.student_rating > 3 ? 'green' : session.student_rating > 0 ? 'red' : 'auto';
    const cancelled_status = [
      'CANCELLED',
      'CANCELLED_BY_TEACHER',
      'CANCELLED_BY_STUDENT',
      'NO_SHOW_CANCELLATION_1HOUR',
      'TEACHER_NO_SHOW',
      'STUDENT_NO_SHOW',
      'STUDENT_AND_TEACHER_NO_SHOW',
      'JOINED_BUT_CANCELLED',
      'NO_SHOW_WITH_RECORDING'
    ];
    const status_style =
      cancelled_status.indexOf(session.session_status) === -1
        ? { color: '#016601' }
        : { color: '#ED4D4D' };
    const is_backpain = this.props.location.pathname === '/pastsessions/backpain' ? 1 : 0;
    return (
      <div className="ct-row" key={index + session.session_uuid}>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_avatar_medium} alt="i" onError={image_error_replace} />
              {session.teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_day_format)}
        </div>
        {is_backpain == 1 ? (
          <div className="ct-col ct-hf ct-sm-font">
            {session.type === 'BACK_PAIN_CONSULT' ? 'Consult' : 'Therapy'}
          </div>
        ) : null}
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={session.student_avatar_medium} alt="i" onError={image_error_replace} />
              {session.student_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font ct-hf" style={status_style}>
          {session.session_status.substr(0, 3)}
          {session.session_status === 'CANCELLED' &&
          !!session.can_diff_hrs &&
          session.can_diff_hrs < 12 ? (
            <span style={{ display: 'block' }}>{`(< 12)`}</span>
          ) : null}
        </div>
        {is_backpain == 0 ? (
          <div className="ct-col ct-si ct-hf">
            {session.is_recurring === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic_repeat@2x.png" alt="ic_rep" />
            ) : null}
          </div>
        ) : null}
        <div className="ct-col ct-si ct-hf">
          {session.replay_url ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col ct-sm-font ct-hf" style={{ color: rating_color }}>
          {rating}
        </div>
        <div className="ct-col">
          <button onClick={() => this.go_to_sessions(session.session_uuid, session.type)}>
            Details
          </button>
        </div>
      </div>
    );
  };

  on_teacher_change = (e) => this.setState({ past_sess_teacher_uuid: e.target.value });

  clear_teacher = () => this.setState({ past_sess_teacher_uuid: '' });

  render_filters = () => {
    const { teachers } = this.state;
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomSingleSelect
          label="Select a teacher"
          options={teachers}
          name="teacher_selected"
          onChange={this.on_teacher_change}
          value={this.state.past_sess_teacher_uuid}
          style={{ width: '250px' }}
        />
        <Button onClick={this.clear_teacher} disabled={!this.state.past_sess_teacher_uuid}>
          clear
        </Button>
      </div>
    );
  };

  open_download = () => this.setState({ download_sessions_open: true });

  close_download = () =>
    this.setState({
      download_start_time: null,
      download_end_time: null,
      download_sessions_open: false
    });

  set_date = (key, value) => this.setState({ [key]: value });

  generate_data = () => {
    const { download_start_time, download_end_time } = this.state;
    // let sel_start_time = moment(download_start).format('YYYY-MM-DD HH:mm:ss')
    const sel_start_time = `${moment(download_start_time).format('YYYY-MM-DD')} 00:00:00`;
    const epoch_start_time = moment
      .tz(sel_start_time, this.props.iana_timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    // const sel_end_time = moment(download_end).format('YYYY-MM-DD HH:mm:ss')
    const sel_end_time = `${moment(download_end_time).format('YYYY-MM-DD')} 23:59:59`;
    const epoch_end_time = moment
      .tz(sel_end_time, this.props.iana_timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const payload = { start_time: epoch_start_time, end_time: epoch_end_time, type: 'YOGA' };
    const url = get_api_url('download_past_session_data');
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then((response) => {
          this.setState({ download_data: [...response.data.sessions], loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_download_session = () => {
    const { download_start_time, download_end_time, download_data } = this.state;
    const iana_index = iana_values.findIndex((a) => a.value === this.props.iana_timezone);
    const iana_value = iana_values[iana_index].label;
    const max_date = moment(download_start_time).add(10, 'days').toDate();
    const start_date = moment(download_start_time).format('MMMDD');
    const end_date = moment(download_end_time).format('MMMDD');
    const file_name = `Yoga_sessions_${start_date}_${end_date}_${iana_value}`;
    const final_download_data = download_data.map((s) => {
      const new_s = {
        ...s,
        formatted_start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('MMM DD hh:mm A z'),
        start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('M/D/YYYY hh:mm A z')
      };
      return new_s;
    });
    return (
      <div style={{ minHeight: '500px', minWidth: '500px' }}>
        <h4>Download session data</h4>
        <p>Select start date and end date to generate data in {iana_value}</p>
        <div style={{ display: 'flex' }}>
          <CustomDatePicker
            label="Start Date"
            value={download_start_time}
            handleChange={(val) => this.set_date('download_start_time', val)}
          />
          <CustomDatePicker
            label="End Date"
            value={download_end_time}
            handleChange={(val) => this.set_date('download_end_time', val)}
            minDate={download_start_time}
            maxDate={max_date}
          />
        </div>
        <div>
          <Button
            onClick={this.generate_data}
            disabled={!download_start_time || !download_end_time}
          >
            Generate Data
          </Button>
        </div>
        {download_data.length > 0 ? (
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={final_download_data} name="Sessions">
              <ExcelColumn label="Session UUID" value="uuid" />
              <ExcelColumn label="Teacher" value="teacher_name" />
              <ExcelColumn label="Student" value="student_name" />
              <ExcelColumn label="Start Time" value="start_time" />
              <ExcelColumn label="Formatted Start Time" value="formatted_start_time" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="Duration(min)" value="duration" />
              <ExcelColumn label="Trial User" value="is_trial_value" />
              <ExcelColumn label="Current Trial User" value="current_trial_value" />
            </ExcelSheet>
          </ExcelFile>
        ) : null}
      </div>
    );
  };

  render_function = () => {
    const { loading, past_sess_teacher_uuid, page, page_limit } = this.state;
    const is_backpain = this.props.location.pathname === '/pastsessions/backpain' ? 1 : 0;
    let { sessions } = this.state;
    if (past_sess_teacher_uuid) {
      sessions = sessions.filter((s) => s.teacher_uuid === past_sess_teacher_uuid);
    }
    return (
      <div className="common-page-container">
        {this.props.past_sessions_status === 'loading' || loading ? <CustomLoading /> : null}
        <CustomModal show={this.state.show_video} close={this.close_video}>
          {this.render_video()}
        </CustomModal>
        <CustomModal show={this.state.download_sessions_open} close={this.close_download}>
          {this.render_download_session()}
        </CustomModal>
        {is_backpain == 0 ? <button onClick={this.open_download}>Download past data</button> : null}
        <div className="common-page-header">
          <div className="common-page-title">{`Past Sessions ${this.state.sessions.length > 0 ? `(${this.state.sessions.length})` : ``}`}</div>
        </div>
        {this.render_filters()}
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Teacher</div>
            <div className="ct-col">Time</div>
            {is_backpain == 1 ? <div className="ct-col ct-hf ct-sm-font">Type</div> : null}
            <div className="ct-col">Student</div>
            <div className="ct-col ct-hf">Status</div>
            {is_backpain == 0 ? <div className="ct-col ct-hf">Repeat</div> : null}
            <div className="ct-col ct-hf">Replay</div>
            <div className="ct-col ct-hf">Rating</div>
            <div className="ct-col">Details</div>
          </div>
          {sessions.map(this.render_single_session)}
        </div>
        <Paginator curr_page={page} page_limit={page_limit} pageChange={this.changePage} />
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Past Sessions</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  past_sessions: selectors.past_sessions(state),
  past_sessions_status: selectors.past_sessions_status(state),
  iana_timezone: state.home.iana_timezone,
  past_sess_curr_page: state.sessions.past_sess_curr_page,
  past_sess_page_limit: state.sessions.past_sess_page_limit
});

const mapDispatchToProps = (dispatch) => ({
  get_past_sessions: (status) => {
    dispatch(actions.get_past_sessions(status));
  },
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_sessions_variable: (key, payload) => {
    dispatch(actions.set_sessions_variable(key, payload));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PastSessions);
