import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../../util_components/common_header';
import { post_api } from '../../../../redux/api_funcs';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

import './upload.css';

class Upload extends Component {
  state = {
    loading: false,
    show_upload: false,
    image: null,
    image_url: '',
    upload_succes: false,
    image_name: '',
    upload_image_url: '',
    current_component: '',
    title: '',
    folder_name: '',
    copy_done: false
  };

  inputRef = React.createRef();

  componentDidMount() {
    this.load_initial_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.path !== this.props.match.path) {
      this.load_initial_data();
    }
  }

  load_initial_data = () => {
    const pathname = this.props.match.path;
    let current_component = '';
    let title = '';
    let folder_name = 'uploads';
    if (pathname === '/upload_images/blog') {
      current_component = 'blog';
      title = 'Blog';
      folder_name = 'blog/posts';
    } else if (pathname === '/upload_images/workshop') {
      current_component = 'workshop';
      title = 'Workshop';
      folder_name = 'workshops';
    }
    this.setState({
      current_component,
      title,
      folder_name,
      loading: false,
      show_upload: false,
      image: null,
      image_url: '',
      upload_succes: false,
      image_name: '',
      upload_image_url: ''
    });
  };

  update_pic = () => {
    this.setState({ upload_succes: false, image_name: '', image_url: '', copy_done: false });
    const realInput = document.getElementById('pic-input');
    realInput.click();
  };

  clear_image = () =>
    this.setState({
      image: null,
      show_upload: false,
      upload_succes: false,
      image_name: '',
      image_url: '',
      copy_done: false
    });

  upload_image = () =>
    this.setState({ loading: true }, () => {
      const { image, folder_name } = this.state;
      const url = get_api_url('upload_single_cloudinary_image');
      const data = new FormData();
      data.append('photo', image);
      data.append('folder_name', folder_name);
      post_api(url, data, true, 'formdata')
        .then((response) => {
          this.props.set_notification_variable(true, 'success', 'Image uploaded');
          const img_response = response.data.result;
          const regex = `[\\w-]+\.${img_response.format}`;
          const image_name = img_response.secure_url.match(new RegExp(regex));
          this.setState({
            image_name,
            upload_image_url: img_response.secure_url,
            show_upload: false,
            upload_succes: true,
            loading: false
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  handleFileChange = (e) => {
    const reader = new FileReader();
    const image_file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: image_file,
        image_url: reader.result,
        show_upload: true
      });
    };
    reader.readAsDataURL(image_file);
  };

  copy_clipboard = () => {
    const { image_name } = this.state;
    navigator.clipboard.writeText(image_name);
    this.setState({ copy_done: true });
  };

  render_success = () => {
    const { upload_succes, image_name, upload_image_url } = this.state;
    if (upload_succes) {
      const pathname = this.props.match.path;
      return (
        <div style={{ margin: '20px 0' }}>
          Upload success
          <div style={{ margin: '20px 0' }}>
            {pathname === '/upload_images/blog' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                The filename is
                <div style={{ margin: '0 10px' }}>{image_name}</div>
                <button onClick={this.copy_clipboard}>Copy</button>
                {this.state.copy_done ? <div style={{ color: 'green' }}>Success!</div> : null}
              </div>
            ) : null}
          </div>
          <div style={{ margin: '20px 0' }}>
            {pathname === '/upload_images/workshop' ? (
              <div>
                The image url is{' '}
                <a href={upload_image_url} target="_blank" rel="noopener noreferrer">
                  {upload_image_url}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    console.log(this.state);
    const { loading, image_url, show_upload, title } = this.state;
    return (
      <CommonHeader loading={loading} title={`Upload ${title} Image`} full_loader>
        <div className="custom-image-upload">
          {image_url ? (
            <div className="custom-image-image">
              <img src={image_url} alt="upload-image" />
            </div>
          ) : null}
          {this.render_success()}
          <button onClick={this.update_pic}>Select Image</button>
          <input
            type="file"
            id="pic-input"
            accept="image/*"
            maxFileSize={100000}
            onChange={this.handleFileChange}
            name="photo"
          />
          {show_upload ? <button onClick={this.upload_image}>Upload Image</button> : null}
          {image_url ? <button onClick={this.clear_image}>Clear Image</button> : null}
        </div>
        <p>Max 5 MB</p>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
