import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import CustomInput from '../../util_components/custom_input';
import HighlightSearch from '../../util_components/highlight_search_text';
import { image_error_replace } from '../../../util_functions';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

import './consults.css';

class ConsultDetails extends Component {
  state = {
    loading: true,
    loaded: false,
    sessions: [],
    session_details: {},
    student_details: {},
    show_raw_data: false,
    raw_data_search: '',
    rec_teachers: {},
    rec_sessions: {},
    notes: '',
    notes_temp: '',
    notes_open: false,
    attachments: [],
    add_attachment: false,
    selected_file: null,
    delete_open: false,
    delete_details: {}
  };

  componentDidMount() {
    // this.load_data()
    try {
      const { uuid } = this.props.match.params;
      if (uuid) {
        this.setState({ session_uuid: uuid }, this.load_data());
      }
    } catch (e) {
      this.go_to_path('/consults/future');
    }
  }

  go_to_path = (path) => this.props.history.push(path);

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { session_uuid } = this.state;
      const url = get_api_url('get_consult_details');
      const payload = { session_uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            loading: false,
            session_details: { ...res.data.session_details },
            student_details: { ...res.data.student_details },
            rec_teachers: { ...res.data.rec_teachers },
            rec_sessions: { ...res.data.rec_sessions },
            notes: res.data.notes,
            attachments: [...res.data.attachments],
            loaded: true
          });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_recommended_sessions = () => {
    const { rec_sessions } = this.state;
    try {
      return (
        <div className="rec-block">
          {rec_sessions.recommend_sessions.map((session) => {
            const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
            return (
              <div className="rec-session">
                <a className="link-no-dec" href={teacher_url}>
                  <div className="profile-img-name rec-teacher-image">
                    <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
                    {session.teacher_name}
                  </div>
                </a>
                {/* <div className="rec-teacher-image">
                                    <img src={session.teacher_photo} onError={image_error_replace} />
                                </div>
                                <div className="rec-teacher-detail">{session.teacher_name}</div> */}
                <div className="rec-teacher-detail ct-sm-font ct-bw">
                  {session.group_session_name}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Target - {session.target_audience}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Participants - {session.no_of_participants}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Time -{' '}
                  {moment(session.epoch_time)
                    .tz(this.props.iana_timezone)
                    .format('MMM DD hh:mm A z')}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Score - {session.teacher_recommendation_score}
                </div>
              </div>
            );
          })}
        </div>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  render_recommended_teachers = () => {
    const { rec_teachers } = this.state;
    try {
      return (
        <div className="rec-block">
          {rec_teachers.teachers.map((teacher) => {
            const teacher_url = `/teacherprofile?id=${teacher.teacher_uuid}`;
            const score = this.get_num_padding(teacher.teacher_recommendation_score, 7, 0);
            const display_score = `${score.toString().slice(0, 3)}  ${score.toString().slice(3)}`;
            return (
              <div className="rec-teacher">
                <a className="link-no-dec" href={teacher_url}>
                  <div className="profile-img-name rec-teacher-image">
                    <img src={teacher.avatar_round} onError={image_error_replace} alt="x" />
                    {teacher.name}
                  </div>
                </a>
                {/* <div className="rec-teacher-image">
                                    <img src={teacher.avatar_round} onError={image_error_replace} />
                                </div> */}
                {/* <div className="rec-teacher-detail">{teacher.name}</div> */}
                <div className="rec-teacher-detail" style={{ fontSize: '14px' }}>
                  Score - {display_score}
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Practise - {teacher.years_of_yoga_practise} yrs
                </div>
                <div className="rec-teacher-detail ct-sm-font">
                  Teaching - {teacher.years_of_yoga_teaching_experience} yrs
                </div>
                <div className="rec-teacher-types ct-sm-font">
                  Types:
                  {teacher.yoga_types_teaching.map((type) => (
                    <div className="rec-teacher-type">
                      {type.name} - {type.level}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  toggle_raw_data = () => this.setState({ show_raw_data: !this.state.show_raw_data });

  toggle_add_attachment = () =>
    this.setState({ add_attachment: !this.state.add_attachment, selected_file: null });

  delete_attachment = (delete_details) =>
    this.setState({ delete_attachment: true, delete_details });

  get_num_padding = (n, width, z) => {
    z = z || '0';
    n += '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  render_session_details = () => {
    const { notes, attachments, notes_open, notes_temp } = this.state;
    const details = this.state.session_details;
    const teacher_url = `/teacherprofile?id=${details.teacher_uuid}`;
    const student_url = `/studentprofile?id=${details.student_uuid}`;
    const { access_type, cms_teacher_uuid } = this.props;
    const show_edit =
      access_type !== 'CONSULT_CONSULTANT' || cms_teacher_uuid === details.teacher_uuid;
    return (
      <div>
        <h3>About session:</h3>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Teacher Name:</td>
              <td>
                <a className="link-no-dec" href={teacher_url}>
                  {details.teacher_name}
                </a>
              </td>
              <td className="teacher-table-detail">Student Name :</td>
              <td>
                <a className="link-no-dec" href={student_url}>
                  {details.student_name}
                </a>
              </td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session uuid :</td>
              <td>{details.uuid}</td>
              <td className="teacher-table-detail">Student phone :</td>
              <td>{details.student_phone_number}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Status:</td>
              <td>{details.status}</td>
              <td className="teacher-table-detail">Start time: </td>
              <td>
                {moment(details.epoch_start_time)
                  .tz(this.props.iana_timezone)
                  .format('YYYY-MM-DD hh:mm A z')}
              </td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Scheduled from web: </td>
              <td>{details.scheduled_from_web === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Scheduled from mobile: </td>
              <td>{details.scheduled_from_web === 1 ? 'False' : 'True'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Duration</td>
              <td>{details.duration}</td>
              <td className="teacher-table-detail">
                <a onClick={this.toggle_raw_data} className="link-sec-btn">
                  Student properties
                </a>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        {notes_open ? (
          <div className="consult-notes">
            <h4>Edit notes</h4>
            <textarea value={notes_temp} onChange={this.change_notes} />
            <button onClick={this.save_notes}>Save</button>
            <button onClick={this.toggle_notes}>Close</button>
          </div>
        ) : (
          <>
            <h3>Notes:</h3>
            <div>{notes}</div>
            {show_edit ? <button onClick={this.toggle_notes}>Edit Notes</button> : null}
          </>
        )}
        {show_edit ? <button onClick={this.toggle_add_attachment}>Add attachment</button> : null}
        {attachments.length > 0 ? (
          <>
            <h3>Attachments</h3>
            {attachments.map((at) => (
              <div key={at.uuid} className="consult-attch">
                <a href={at.url} target="_blank" rel="noopener noreferrer">
                  {at.name}
                </a>
                <img
                  src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                  alt="nav"
                  onClick={() => this.delete_attachment(at)}
                />
              </div>
            ))}
          </>
        ) : null}
        <hr style={{ margin: '10px 0' }} />
        <h3>Recommended Teachers</h3>
        {this.render_recommended_teachers()}
        <h3>Recommended Sessions</h3>
        {this.render_recommended_sessions()}
        <hr style={{ margin: '10px 0' }} />
      </div>
    );
  };

  toggle_notes = () =>
    this.setState({ notes_open: !this.state.notes_open, notes_temp: this.state.notes });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  change_notes = (e) => this.setState({ notes_temp: e.target.value });

  render_raw_data = () => {
    const { student_details, show_raw_data, raw_data_search } = this.state;
    if (show_raw_data) {
      const raw_data = student_details;
      const object_keys = Object.keys(raw_data).sort();
      const filtered_keys = object_keys.filter(
        (k) => k.toLowerCase().indexOf(raw_data_search.toLowerCase()) !== -1
      );
      return (
        <div style={{ height: '80vh' }}>
          <CustomInput
            label="Search key value"
            style={{ width: '500px' }}
            value={raw_data_search}
            name="raw_data_search"
            onChange={this.onCommonChange}
          />
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
              <div className="ct-col ct-la ct-pl-10">Value</div>
            </div>
            {filtered_keys.map((key_val, index) => {
              const val = raw_data[key_val];
              if (typeof val !== 'object') {
                return (
                  <div className="ct-row" key={index + key_val}>
                    <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                      <HighlightSearch full_text={key_val} search_text={raw_data_search} />
                    </div>
                    <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">{val}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  save_notes = () =>
    this.setState({ loading: true }, () => {
      const { session_uuid, notes_temp } = this.state;
      const payload = { session_uuid, notes: notes_temp };
      const url = get_api_url('save_consult_notes');
      post_api(url, payload, true)
        .then((res) => {
          this.toggle_notes();
          this.setState({ loading: false, notes: notes_temp, notes_temp: '' });
          this.props.set_notification_variable(true, 'success', 'Notes updated');
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_notes = () => {
    const { notes_temp } = this.state;
    return (
      <div className="consult-notes">
        <h4>Edit notes</h4>
        <textarea value={notes_temp} onChange={this.change_notes} />
        <button onClick={this.save_notes}>Save</button>
      </div>
    );
  };

  submit_file = () =>
    this.setState({ loading: true }, () => {
      const { selected_file, session_uuid, attachments } = this.state;
      const filename = selected_file.name;
      const data = new FormData();
      data.append('attachment', selected_file);
      data.append('filename', filename);
      data.append('session_uuid', session_uuid);
      const url = get_api_url('upload_consult_attachment');
      post_api(url, data, true, 'formdata')
        .then((res) => {
          const new_attachments = [...attachments, res.data.attachment];
          this.setState({ loading: false, attachments: new_attachments });
          this.toggle_add_attachment();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  onFileChange = (event) => this.setState({ selected_file: event.target.files[0] });

  render_add_attachment = () => {
    const { selected_file } = this.state;
    return (
      <div>
        <h4>Select file to add</h4>
        <input type="file" onChange={this.onFileChange} />
        <div style={{ marginTop: '15px' }}>
          <Button disabled={!selected_file} onClick={this.submit_file}>
            Submit
          </Button>
        </div>
      </div>
    );
  };

  submit_delete = () =>
    this.setState({ loading: false }, () => {
      const { delete_details, session_uuid, attachments } = this.state;
      const payload = {
        attachment_uuid: delete_details.uuid,
        session_uuid
      };
      const url = get_api_url('delete_attachment');
      post_api(url, payload, true)
        .then((res) => {
          const new_attachments = [...attachments];
          const index = new_attachments.findIndex((a) => a.uuid === delete_details.uuid);
          const final_attachments = [
            ...new_attachments.slice(0, index),
            ...new_attachments.slice(index + 1)
          ];
          this.setState({ loading: false, attachments: final_attachments });
          this.close_delete();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_delete_attachment = () => {
    const { delete_details } = this.state;
    return (
      <div>
        <h4>Are you sure you want to delete {delete_details.name}</h4>
        <button onClick={this.submit_delete}>Submit</button>
      </div>
    );
  };

  close_delete = () => this.setState({ delete_attachment: false, delete_details: {} });

  render() {
    const { loading, loaded, show_raw_data, notes_open, add_attachment, delete_attachment } =
      this.state;
    return (
      <CommonHeader loading={loading} show_back title="Consult Details">
        <CustomModal show={show_raw_data} close={this.toggle_raw_data}>
          {this.render_raw_data()}
        </CustomModal>
        {/* <CustomModal show={notes_open} close={this.toggle_notes}>
                    {this.render_notes()}
                </CustomModal> */}
        <CustomModal show={add_attachment} close={this.toggle_add_attachment}>
          {this.render_add_attachment()}
        </CustomModal>
        <CustomModal show={delete_attachment} close={this.close_delete}>
          {this.render_delete_attachment()}
        </CustomModal>
        {loaded ? this.render_session_details() : null}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  access_type: state.home.access_type,
  cms_teacher_uuid: state.home.cms_teacher_uuid
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultDetails);
