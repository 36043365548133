import React from 'react';
import PropTypes from 'prop-types';

import './custom_checkbox.css';

function CustomCheckbox(props) {
  return (
    <div className="custom-checkbox" onClick={props.onClick} style={props.custom_style}>
      <input type="checkbox" readOnly checked={props.checked} style={props.input_style} />
      {props.show_label ? (
        <div className="custom-checkbox-label" style={props.label_style}>
          {props.label}
        </div>
      ) : null}
    </div>
  );
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  show_label: PropTypes.bool,
  custom_style: PropTypes.object,
  label_style: PropTypes.object,
  input_style: PropTypes.object
};

CustomCheckbox.defaultProps = {
  label: 'label',
  checked: false,
  onClick: () => console.log('check clicked'),
  show_label: true,
  custom_style: {},
  label_style: {},
  input_style: {}
};

export default CustomCheckbox;
