import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '../custom_checkbox';

export default class index extends Component {
  state = {
    loading: true,
    title: '',
    all_values: [],
    curr_values: [],
    state_text: ''
  };

  componentDidMount() {
    this.setState({
      title: this.props.title,
      all_values: this.props.all_values,
      curr_values: this.props.curr_values,
      state_text: this.props.state_text,
      loading: false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.curr_values !== this.props.curr_values) {
      this.setState({ curr_values: this.props.curr_values });
    }
  }

  onCheckClick = (value) => {
    const val_arr = [...this.state.curr_values];
    const index = val_arr.indexOf(value);
    if (index === -1) val_arr.push(value);
    else val_arr.splice(index, 1);
    this.setState({ curr_values: [...val_arr] });
    this.props.save_values(val_arr);
  };

  render() {
    const { title, curr_values, all_values, loading } = this.state;
    if (!loading) {
      return (
        <>
          <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
            {title}
          </div>
          <div className="group-class-goals" style={{ marginBottom: '10px' }}>
            {all_values.map((value) => {
              const selected = curr_values.indexOf(value) !== -1;
              const custom_style = { marginRight: '10px', flexShrink: '0' };
              return (
                <CustomCheckbox
                  key={value}
                  label={value}
                  checked={selected}
                  onClick={() => this.onCheckClick(value)}
                  custom_style={custom_style}
                />
              );
            })}
          </div>
        </>
      );
    }
    return null;
  }
}
