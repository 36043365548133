import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import CustomLoading from '../../util_components/custom_loading';
import * as actions from '../../../redux/action_creators';
import './dashboard.css';

class Dashboard extends Component {
  state = {
    isLoading: false,
    yogaTypePie: {},
    yogaExpSourcedoughnut: {},
    yogaActiveSourcedoughnut: {},
    yogaOverAllExpSourcedoughnut: {},
    yogaOverAllVerifiedExpSourcedoughnut: {},
    yogaOverAllNotVerifiedExpSourcedoughnut: {},
    yogaGoalsPie: {},

    optionss: {},

    yogaGoalsColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FFBD33', '#DBFF33', '#f441a6'],
    yogaTypesColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#FFBD33',
      '#DBFF33',
      '#f441a6',
      '#52685d',
      '#33FFBD',
      '#6EFF33',
      '#f48342',
      '#33FFC4'
    ],
    verifiedUsers: 0,
    unVerifiedUsers: 0
  };

  componentDidMount() {
    this.props.get_dashboard_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dashboard_status === 'loading' && this.props.dashboard_status === 'success') {
      this.load_data_into_state();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  load_data_into_state = () => {
    const data = this.props.dashboard_data;
    const yogaTypesBreakup = data.users_yoga_types_practiced_breakup;
    const usersYogaGoalsBreakup = data.users_yoga_goals_breakup;
    const usersYogaExp = data.users_yoga_experience_source_breakup;
    const usersOverAllYogaExp = data.overall_users_source_breakup;
    const usersOverAllVerifiedYogaExp = data.overall_verified_users_source_breakup;
    const usersOverAllNotVerifiedYogaExp = data.overall_unverified_users_source_breakup;

    this.setState({
      total_users_signup: data.total_users_signup,
      total_members_signup: data.total_members_signup,
      users_given_yoga_experience: data.users_given_yoga_experience,
      verifiedUsers: data.signups_breakup_verification_status[1].count,
      unVerifiedUsers: data.signups_breakup_verification_status[0].count,
      verifiedUsersPercentage: data.signups_breakup_verification_status[1].percentage,
      unVerifiedUsersPercentage: data.signups_breakup_verification_status[0].percentage,
      newbie: data.users_yoga_experience_breakup[0].count,
      intermediate: data.users_yoga_experience_breakup[1].count,
      advance: data.users_yoga_experience_breakup[2].count
    });

    this.setUsersWithYogaTypesArmPie(yogaTypesBreakup);
    this.setUsersWithYogaGoalsArmPie(usersYogaGoalsBreakup);
    this.setUsersWithYogaExpSourceArmPie(usersYogaExp);
    this.setOverAllUsersWithYogaExpSourceArmPie(usersOverAllYogaExp);
    this.setOverAllUsersVerifiedWithYogaExpSourceArmPie(usersOverAllVerifiedYogaExp);
    this.setActiveUsersSourceArmPie(data.users_yoga_experience_breakup);
    this.setOverAllUsersNotVerifiedWithYogaExpSourceDoughnut(usersOverAllNotVerifiedYogaExp);
  };

  setUsersWithYogaTypesArmPie = (data) => {
    // Create chart instance

    const chart = am4core.create('chartTypesdiv', am4charts.PieChart);
    const chartData = [];

    for (const [key, value] of Object.entries(data)) {
      const dataU = {
        country: key,
        litres: value
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setUsersWithYogaExpSourceDoughnut = (data) => {
    const source = [];
    const count = [];
    let yogaExpSourcedoughnut = {};

    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        this.setState({ activeSourcesWeb: data[i].count });
      } else if (i === 1) {
        this.setState({ activeSourcesMobile: data[i].count });
      } else if (i === 2) {
        this.setState({ activeSourcesFb: data[i].count });
      }
      source.push(`${data[i].source} [ ${data[i].count} ] `);
      count.push(data[i].count);
    }

    yogaExpSourcedoughnut = {
      labels: source,
      datasets: [
        {
          data: count,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }
      ]
    };
    this.setState({ yogaExpSourcedoughnut });
  };

  setUsersWithYogaExpSourceArmPie = (data) => {
    const chart = am4core.create('chartYogaExpSourcediv', am4charts.PieChart);
    const chartData = [];

    for (let i = 0; i < data.length; i++) {
      const dataU = {
        country: data[i].source,
        litres: data[i].count
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';

    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setActiveUsersSourceArmPie = (data) => {
    const chart = am4core.create('chartActiveUsersSourcediv', am4charts.PieChart);
    const chartData = [];

    for (let i = 0; i < data.length; i++) {
      const dataU = {
        country: data[i].yoga_level,
        litres: data[i].count
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';

    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setOverAllUsersWithYogaExpSourceArmPie = (data) => {
    const chart = am4core.create('chartAllOverUsersWithYogaExpdiv', am4charts.PieChart);
    const chartData = [];

    for (let i = 0; i < data.length; i++) {
      const dataU = {
        country: data[i].source,
        litres: data[i].count
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';

    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setOverAllUsersVerifiedWithYogaExpSourceArmPie = (data) => {
    const chart = am4core.create('chartOverAllUsersVerifiedWithYogaExpdiv', am4charts.PieChart);
    const chartData = [];

    for (let i = 0; i < data.length; i++) {
      const dataU = {
        country: data[i].source,
        litres: data[i].count
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setOverAllUsersNotVerifiedWithYogaExpSourceDoughnut = (data) => {
    const chart = am4core.create('chartOverAllUsersNotVerifiedWithYogaExpdiv', am4charts.PieChart);
    const chartData = [];

    for (let i = 0; i < data.length; i++) {
      const dataU = {
        country: data[i].source,
        litres: data[i].count
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  setUsersWithYogaTypesPie = (data) => {
    const usersYogaTypes = [];
    const labels = [];

    for (const [key, value] of Object.entries(data)) {
      labels.push(`${key} [ ${value} ] `);
      usersYogaTypes.push(value);
    }

    let yogaTypePie = data;

    yogaTypePie = {
      labels,
      position: 'outside',
      datasets: [
        {
          data: usersYogaTypes,
          backgroundColor: [...this.state.yogaTypesColor],
          hoverBackgroundColor: [...this.state.yogaTypesColor]
        }
      ]
    };
    this.setState({ yogaTypePie });
  };

  setUsersWithYogaGoalsPie = (data) => {
    const usersYogaTypes = [];
    const labels = [];

    for (const [key, value] of Object.entries(data)) {
      labels.push(`${key} [ ${value} ] `);
      usersYogaTypes.push(value);
    }

    let yogaGoalsPie = data;
    yogaGoalsPie = {
      labels,
      datasets: [
        {
          data: usersYogaTypes,
          backgroundColor: [...this.state.yogaTypesColor],
          hoverBackgroundColor: [...this.state.yogaTypesColor]
        }
      ]
    };
    this.setState({ yogaGoalsPie });

    let optionss = {};

    const dataTypeArr = [];

    for (const [key, value] of Object.entries(data)) {
      const dataType = { y: Number, label: '' };

      dataType.label = key;
      dataType.y = value;

      dataTypeArr.push(dataType);
    }

    const newData = dataTypeArr;

    optionss = {
      animationEnabled: true,
      exportEnabled: false,
      theme: 'light', // "light1", "dark1", "dark2"
      // title: {
      //   text: "Trip Expenses"
      // },
      data: [
        {
          type: 'pie',
          indexLabel: '{label}: {y}%',
          startAngle: -90,
          dataPoints: newData
        }
      ]
    };

    this.setState({ optionss });
  };

  setUsersWithYogaGoalsArmPie = (data) => {
    // Create chart instance

    const chart = am4core.create('chartGoalsdiv', am4charts.PieChart);
    const chartData = [];

    for (const [key, value] of Object.entries(data)) {
      const dataU = {
        country: key,
        litres: value
      };

      chartData.push(dataU);
    }

    chart.data = chartData;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'litres';
    pieSeries.dataFields.category = 'country';
    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  };

  render() {
    if (this.props.dashboard_status === 'success') {
      return (
        <div className="common-page-container">
          <div className="dashboard-users">
            <h1>Student Profiles</h1>
            {/* <div className="dashboard-users-container">
							<div className="dashboard-users-box">
								<h1>{this.state.total_users_signup}</h1>
								<p>ALL EMAILS</p>
							</div>
							<div className="dashboard-users-box">
								<h1>{this.state.verifiedUsers}</h1>
								<p>SIGNED UP</p>
							</div>
							<div className="dashboard-users-box">
								<h1>{this.state.unVerifiedUsers}</h1>
								<p>DIDN'T SIGN UP</p>
							</div>
							<div className="dashboard-users-box">
								<h1>{this.state.users_given_yoga_experience}</h1>
								<p>COMPLETED THEIR PROFILE</p>
							</div>
						</div> */}

            <div className="dashboard-users-container">
              <div className="dashboard-users-box">
                <h1>{this.state.total_users_signup}</h1>
                <p>Total Users</p>
              </div>
              <div className="dashboard-users-box">
                <h1>{this.state.total_members_signup}</h1>
                <p>Members</p>
              </div>
            </div>
            <div className="dashboard-users">
              <h1>Sources</h1>
              <div className="dashboard-chart">
                <p>OVER ALL SOURCE</p>
                <div id="chartAllOverUsersWithYogaExpdiv" />
              </div>
              <div className="dashboard-sub-charts">
                <div className="dashboard-sub-chart">
                  <div className="dashboard-chart">
                    <p>SIGNED UP</p>
                    <div id="chartOverAllUsersVerifiedWithYogaExpdiv" />
                  </div>
                </div>
                <div className="dashboard-sub-chart">
                  <div className="dashboard-chart">
                    <p>DIDN'T SIGN UP</p>
                    <div id="chartOverAllUsersNotVerifiedWithYogaExpdiv" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-users">
            <h1>Users Active on App</h1>
            <div className="dashboard-sub-charts">
              <div className="dashboard-sub-chart">
                <div className="dashboard-chart">
                  <p>ACTIVE USERS</p>
                  <div id="chartActiveUsersSourcediv" />
                </div>
              </div>
              <div className="dashboard-sub-chart">
                <div className="dashboard-chart">
                  <p>SOURCES</p>
                  <div id="chartYogaExpSourcediv" />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-users">
            <h1>Yoga Types</h1>
            <div className="dashboard-chart">
              <p>Users with yoga types</p>
              <div id="chartTypesdiv" style={{ width: '100%', height: '600px' }} />
            </div>
            <div className="dashboard-chart" style={{ marginTop: '30px' }}>
              <p>Users with yoga goals</p>
              <div id="chartGoalsdiv" style={{ width: '100%', height: '300px' }} />
            </div>
          </div>
        </div>
      );
    }
    return <CustomLoading />;
  }
}

const mapStateToProps = (state) => ({
  dashboard_data: state.home.dashboard_data,
  dashboard_status: state.home.dashboard_status,
  cms_token: state.home.cms_token
});

const mapDispatchToProps = (dispatch) => ({
  get_dashboard_data: () => dispatch(actions.get_dashboard_data())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
