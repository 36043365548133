import { createSelector } from 'reselect';

export const past_group_sessions_data = (state) => state.sessions.past_group_sessions_data;
export const past_group_sess_curr_page = (state) => state.sessions.past_group_sess_curr_page;

export const future_group_sessions_data = (state) => state.sessions.future_group_sessions_data;
export const future_group_sess_curr_page = (state) => state.sessions.future_group_sess_curr_page;

export const get_future_sessions_data = (state) => state.sessions.future_sessions_data;
export const future_sess_curr_page = (state) => state.sessions.future_sess_curr_page;
export const get_future_sessions = (state) => state.sessions.future_sessions;

export const get_past_sessions_data = (state) => state.sessions.past_sessions_data;
export const past_sess_curr_page = (state) => state.sessions.past_sess_curr_page;
export const get_past_sessions = (state) => state.sessions.past_sessions;

export const get_future_friends_sessions_data = (state) =>
  state.sessions.future_friends_sessions_data;
export const future_friends_sess_curr_page = (state) =>
  state.sessions.future_friends_sess_curr_page;
export const get_future_friends_sessions = (state) => state.sessions.future_friends_sessions;

export const get_past_friends_sessions_data = (state) => state.sessions.past_friends_sessions_data;
export const past_friends_sess_curr_page = (state) => state.sessions.past_friends_sess_curr_page;
export const get_past_friends_sessions = (state) => state.sessions.past_friends_sessions;

export const past_group_data = createSelector(past_group_sessions_data, (data) => data);

export const past_group_page = createSelector(past_group_sess_curr_page, (data) => data);

export const future_group_data = createSelector(future_group_sessions_data, (data) => data);

export const future_group_page = createSelector(future_group_sess_curr_page, (data) => data);

export const future_sessions = createSelector(
  get_future_sessions,
  (future_sessions) => future_sessions
);

export const future_sess_page = createSelector(future_sess_curr_page, (data) => data);

export const future_sessions_data = createSelector(get_future_sessions_data, (data) => data);

export const past_sessions = createSelector(get_past_sessions, (past_sessions) => past_sessions);

export const past_sess_page = createSelector(past_sess_curr_page, (data) => data);

export const past_sessions_data = createSelector(get_past_sessions_data, (data) => data);

export const future_friends_sessions = createSelector(
  get_future_friends_sessions,
  (past_sessions) => past_sessions
);

export const future_friends_sess_page = createSelector(
  future_friends_sess_curr_page,
  (data) => data
);

export const future_friends_sessions_data = createSelector(
  get_future_friends_sessions_data,
  (data) => data
);

export const past_friends_sessions = createSelector(
  get_past_friends_sessions,
  (past_sessions) => past_sessions
);

export const past_friends_sess_page = createSelector(past_friends_sess_curr_page, (data) => data);

export const past_friends_sessions_data = createSelector(
  get_past_friends_sessions_data,
  (data) => data
);
