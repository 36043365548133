import React, { Component } from 'react';
import { connect } from 'react-redux';
import { custom_data_configs } from '../../../../constants';
import * as actions from '../../../../redux/action_creators';
import { api_with_method } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import CommonHeader from '../../../util_components/common_header';
import CustomInput from '../../../util_components/custom_input';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleSelect from '../../../util_components/custom_single_select';

class SplitTestChoiceV2 extends Component {
  state = {
    loading: true,
    choice_details: {},
    modal_open: false,
    choice_name: '',
    choice_tag: '',
    test_next_page_url: '',
    optimize_test_variant: '',
    distribution_pattern: '',
    description: '',
    weight: '',
    image_url: '',
    config_data: [],
    choice_uuid: '',
    config_uuid: '',
    data_key: '',
    data_type: '',
    data_value: '',
    edit_config_modal: false,
    old_data_value: '',
    old_data_value_is_true: false,
    config_value_uuid: '',
    status: ''
  };

  componentDidMount() {
    const urlString = window.location.href;
    const { uuid } = this.props.match.params;

    if (uuid) {
      this.setState({ uuid }, () => this.load_data());
    } else {
      this.props.history.push('/split_test_v2');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { uuid } = this.state;

      const url = get_api_url(`/v2/split_tests/care/choice_details/${uuid}`, null, true);
      const payload = { choice_uuid: uuid };
      api_with_method('get', url, payload, true)
        .then((res) => {
          const choice_details = res.data.data;
          this.setState({
            choice_details,
            loading: false,
            config_data: choice_details.split_choice_config_data,
            choice_uuid: choice_details.uuid,
            status: choice_details.status
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  open_edit_split_test = () => {
    this.setState({ modal_open: true });
  };

  close_modal = () => {
    this.setState({ modal_open: false, edit_config_modal: false });
  };

  open_config_modal = () => {
    this.setState({ edit_config_modal: true });
  };

  set_values = () => {
    const { choice_details } = this.state;
    this.setState({
      choice_name: choice_details.choice_name,
      choice_tag: choice_details.choice_tag,
      test_next_page_url: choice_details.test_next_page_url,
      optimize_test_variant: choice_details.optimize_test_variant,
      distribution_pattern: choice_details.distribution_pattern,
      description: choice_details.description,
      modal_open: true,
      weight: choice_details.weight,
      image_url: choice_details.image_url
    });
  };

  go_back = () => this.props.history.goBack();

  onCommonChange = (e) => {
    const key = e.target.name;
    let { value } = e.target;
    if (key === 'weight') {
      value = Math.min(value, 100);
    }
    this.setState({ [key]: value, modal_error: false });
  };

  save_edit = () => {
    const {
      choice_name,
      description,
      choice_tag,
      weight,
      test_next_page_url,
      distribution_pattern,
      image_url,
      optimize_test_variant,
      uuid
    } = this.state;

    if (!!choice_name && !!weight) {
      const payload = {
        choice_name,
        choice_tag,
        description,
        weight,
        test_next_page_url,
        distribution_pattern,
        image_url,
        optimize_test_variant,
        uuid
      };

      const url = get_api_url('/v2/split_tests/care/edit_split_choice', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('put', url, payload, true)
          .then((res) => {
            this.props.set_notification_variable(true, 'success', 'Split choice edited');
            this.close_modal();
            this.load_data();
            this.setState({ loading: false });
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e?.response?.data?.reason;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  render_edit_split = () => {
    const {
      choice_tag,
      choice_name,
      description,
      weight,
      test_next_page_url,
      distribution_pattern,
      image_url,
      optimize_test_variant,
      modal_error
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    return (
      <>
        <div className="text-center m-4 w-full">Edit split test choice</div>
        <div className="flex flex-wrap justify-between px-2 w-full">
          <CustomInput
            label="*Enter tag"
            value={choice_tag}
            onChange={this.onCommonChange}
            disabled
            name="choice_tag"
          />
          <CustomInput
            label="*Enter name"
            value={choice_name}
            onChange={this.onCommonChange}
            name="choice_name"
          />
          <CustomInput
            label="*Enter weight"
            value={weight}
            onChange={this.onCommonChange}
            name="weight"
            type="number"
          />
          <CustomInput
            label="Next page url"
            value={test_next_page_url}
            onChange={this.onCommonChange}
            name="test_next_page_url"
          />
          <CustomInput
            label="Distribution pattern"
            value={distribution_pattern}
            onChange={this.onCommonChange}
            name="distribution_pattern"
          />
          <CustomInput
            label="Image Url"
            value={image_url}
            onChange={this.onCommonChange}
            name="image_url"
          />
          <CustomInput
            label="Optimize test variant"
            value={optimize_test_variant}
            onChange={this.onCommonChange}
            name="optimize_test_variant"
          />
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
          <button onClick={this.save_edit}>Save</button>
          <p style={err_sty}>* fields are compulsory</p>
        </div>
      </>
    );
  };

  set_config_value = (data_key, data_type, data_value, config_uuid, config_value_uuid) => {
    if (data_value === undefined) {
      this.setState({ old_data_value_is_true: true });
    }
    this.setState({
      data_key,
      data_type,
      data_value,
      edit_config_modal: true,
      config_uuid,
      config_value_uuid
    });
  };

  submit_add_data_config = () => {
    const { data_value, choice_uuid, config_uuid } = this.state;
    if (data_value) {
      const payload = {
        data_value,
        split_test_choice_uuid: choice_uuid,
        split_test_data_config_uuid: config_uuid
      };
      const url = get_api_url('/v2/split_tests/care/add_config_values', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('POST', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Config Added');
            this.close_modal();
            this.load_data();
            this.setState({ loading: false, old_data_value_is_true: false });
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    }
  };

  submit_edit_data_config = () => {
    const { data_value, config_value_uuid } = this.state;
    if (data_value) {
      const payload = { data_value, uuid: config_value_uuid };
      const url = get_api_url('/v2/split_tests/care/edit_config_values', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('PUT', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Config data value updated');
            this.close_modal();
            this.load_data();
            this.setState({ loading: false });
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    }
  };

  render_edit_data_config = () => {
    const { data_key, data_type, data_value, old_data_value_is_true } = this.state;
    const type = data_type === 'NUMBER' ? 'number' : 'text';
    return (
      <div className="w-full items-center flex flex-col">
        <div className="font-inter font-semibold text-2xl mb-2">
          {old_data_value_is_true === true ? 'Add custom data config' : 'Edit custom data config'}
        </div>
        <div>Key: {data_key}</div>
        <div>Type: {data_type}</div>
        <CustomInput
          label="Data Value"
          onChange={this.onCommonChange}
          type={type}
          value={data_value}
          name="data_value"
        />
        {old_data_value_is_true === true ? (
          <button style={{ width: '200px' }} onClick={this.submit_add_data_config}>
            Save
          </button>
        ) : (
          <button style={{ width: '200px' }} onClick={this.submit_edit_data_config}>
            Update
          </button>
        )}
      </div>
    );
  };

  render_data_config = () => {
    const { config_data, status } = this.state;
    const choice_detail = this.state.choice_details;
    return config_data.length > 0 ? (
      <table className="relative w-full border">
        <thead>
          <tr>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Key</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Type</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Value</th>
            {status === 'LIVE' ? (
              ''
            ) : (
              <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Details</th>
            )}
          </tr>
        </thead>
        <tbody>
          {config_data.map((item, index) => (
            <tr key={index}>
              <td>{item.data_key}</td>
              <td>{item.data_type}</td>
              <td>{item.data_value ? item.data_value : '--'}</td>
              {choice_detail.is_active === 0 ? null : item.config_key_enabled ===
                0 ? null : status === 'LIVE' ? (
                ''
              ) : (
                <td>
                  {item.data_value ? (
                    <button
                      onClick={() =>
                        this.set_config_value(
                          item.data_key,
                          item.data_type,
                          item.data_value,
                          item.split_test_data_config_uuid,
                          item.uuid
                        )
                      }
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        this.set_config_value(
                          item.data_key,
                          item.data_type,
                          item.data_value,
                          item.split_test_data_config_uuid
                        )
                      }
                    >
                      Add
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      ''
    );
  };

  render_choice_table = () => {
    const choice_detail = this.state.choice_details;
    return (
      <table>
        <tbody>
          <tr>
            <td className="text-left">Name:</td>
            <td className="text-left">{choice_detail.choice_name}</td>
            <td className="text-left">Tag:</td>
            <td className="text-left">{choice_detail.choice_tag}</td>
          </tr>
          <tr>
            <td className="text-left">Weight:</td>
            <td className="text-left">{choice_detail.weight}</td>
            <td className="text-left">Next Page:</td>
            <td className="text-left">
              {choice_detail.test_next_page_url === '' ? '--' : choice_detail.test_next_page_url}
            </td>
          </tr>
          <tr>
            <td className="text-left">UUID:</td>
            <td className="text-left">{choice_detail.uuid}</td>
            <td className="text-left">Status:</td>
            <td className="text-left">{choice_detail.status}</td>
          </tr>
          <tr>
            <td className="text-left">Optimize test variants:</td>
            <td className="text-left">
              {choice_detail.optimize_test_variant === ''
                ? '--'
                : choice_detail.optimize_test_variant}
            </td>
            <td className="text-left">Choice Description:</td>
            <td className="text-left">
              {choice_detail.description === '' ? '--' : choice_detail.description}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    const { modal_open, choice_details, loading, edit_config_modal } = this.state;
    return (
      <>
        <CommonHeader
          loading={loading}
          show_back
          title={`Split Test ${this.state.choice_details.choice_name} Details`}
        >
          <div className="w-full font-inter flex items-center font-semibold text-2xl" />
          <div className="mb-2">{this.render_choice_table()}</div>
          <div className="mb-4">
            {choice_details.is_active === 0 ? null : choice_details.status === 'LIVE' ? null : (
              <button onClick={this.set_values}>Edit details</button>
            )}
          </div>
          <div className="mb-2">{this.render_data_config()}</div>
        </CommonHeader>
        <CustomModal show={edit_config_modal} close={this.close_modal}>
          {this.render_edit_data_config()}
        </CustomModal>
        <CustomModal show={modal_open} close={this.close_modal}>
          {this.render_edit_split()}
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitTestChoiceV2);
