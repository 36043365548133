import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import CreateEditClassPage from './create_edit_class_page';
import CustomModal from '../../util_components/custom_modal';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import { check_user_is_from_back_pain, isViewOnly } from '../../../util_functions';
import Button from '../../util_components/button';

class GroupClassPages extends Component {
  state = {
    initial_loading: true,
    loading: true,
    create_open: false,
    pages: [],
    inactive_pages: false
  };

  componentDidMount() {
    const payload = {
      is_exclusive_for_backpain_client: check_user_is_from_back_pain()
    };
    this.props.get_class_types(payload);
    if (this.props.class_types_status === 'success' && this.props.config_status === 'success') {
      this.setState({ initial_loading: false });
    }
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    this.load_data();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.initial_loading &&
      this.props.class_types_status === 'success' &&
      this.props.config_status === 'success' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.setState({ initial_loading: false });
    }
    if (prevProps.location.pathname.toString() !== this.props.location.pathname.toString()) {
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.props.get_class_types(payload);
      this.load_data();
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_class_pages');
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      post_api(url, payload, true).then((result) =>
        this.setState({ pages: [...result.data.pages], loading: false })
      );
    });

  create_class_page = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('create_class_page_new');
      post_api(url, payload, true)
        .then(() => {
          this.toggle_create();
          this.load_data();
          this.props.set_notification_variable(true, 'success', 'Page created');
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  create_page = () => {
    const { create_open } = this.state;
    if (create_open) {
      return <CreateEditClassPage submit_change={this.create_class_page} />;
    }
    return null;
  };

  toggle_tests = () => {
    this.setState((prevState) => ({
      inactive_pages: !prevState.inactive_pages
    }));
  };

  toggle_create = () => this.setState({ create_open: !this.state.create_open });

  open_details = (slug) => {
    const pathname = `/group_class_page/${slug}`;
    this.props.history.push(pathname);
  };

  render_button = () => {
    const { inactive_pages } = this.state;
    return (
      <Button onClick={this.toggle_tests}>{inactive_pages ? 'Active' : 'Inactive'} Pages</Button>
    );
  };

  render_pages = () => {
    const { pages, inactive_pages } = this.state;
    const filter_pages = inactive_pages
      ? pages.filter((t) => t.is_page_active === 0)
      : pages.filter((t) => t.is_page_active === 1);

    if (pages.length > 0) {
      return (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">ID</div>
            <div className="ct-col">Name</div>
            <div className="ct-col ct-hf">Regular teachers</div>
            <div className="ct-col ct-qf  ct-sm-font">Active</div>
            <div className="ct-col ct-qf  ct-sm-font">
              Visible
              <div>(Trial Users)</div>
            </div>
            <div className="ct-col ct-qf  ct-sm-font">
              Visible
              <div>(Members)</div>
            </div>
            <div className="ct-col ct-qf  ct-sm-font">
              Visible
              <div>(Non Members)</div>
            </div>
            <div className="ct-col ct-hf">Details</div>
          </div>
          {filter_pages.map((page) => (
            <div className="ct-row" key={page.uuid}>
              <div className="ct-col ct-sm-font ct-bw">
                {page.slug}{' '}
                {page?.is_event ? (
                  <p className="m-0 w-full flex items-center justify-center">
                    <span
                      className="bg-mytPrimary px-2.5 py-1 w-fit text-white font-bold leading-3 rounded-lg flex justify-center items-center"
                      style={{ fontSize: '10px' }}
                    >
                      EVENT
                    </span>
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className="ct-col ct-sm-font ct-bw">{page.name}</div>
              <div className="ct-col ct-hf">{page.regular_teachers_count}</div>
              <div className="ct-col ct-qf  ct-sm-font">
                {page.is_page_active === 1 ? (
                  <div className="led-green" />
                ) : (
                  <div className="led-grey" />
                )}
              </div>
              <div className="ct-col ct-qf  ct-sm-font">
                {page.visible_to_trial_users === 1 ? (
                  <div className="led-green" />
                ) : (
                  <div className="led-grey" />
                )}
              </div>
              <div className="ct-col ct-qf  ct-sm-font">
                {page.visible_to_members === 1 ? (
                  <div className="led-green" />
                ) : (
                  <div className="led-grey" />
                )}
              </div>
              <div className="ct-col ct-qf  ct-sm-font">
                {page.visible_to_non_members === 1 ? (
                  <div className="led-green" />
                ) : (
                  <div className="led-grey" />
                )}
              </div>
              <div className="ct-col ct-hf">
                <button onClick={() => this.open_details(page.slug)}>Details</button>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  render() {
    const { loading, create_open, initial_loading, inactive_pages } = this.state;
    const title = inactive_pages ? 'Inactive Class Pages' : 'Active Class Pages';
    return (
      <CommonHeader loading={loading || initial_loading} title={title}>
        <CustomModal show={create_open} close={this.toggle_create}>
          {this.create_page()}
        </CustomModal>
        {!isViewOnly(this.props.access_type) ? (
          <button onClick={this.toggle_create}>Create Page</button>
        ) : null}
        {this.render_button()}
        {this.render_pages()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  teachers_list_status: state.teachers.teachers_list_status,
  teachers_list: state.teachers.teachers_list,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_class_types: (payload) => dispatch(actions.get_class_types(payload)),
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupClassPages);
