import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomModal from '../../util_components/custom_modal';
import CommonHeader from '../../util_components/common_header';
import CustomInput from '../../util_components/custom_input';
import CustomCheckbox from '../../util_components/custom_checkbox';
import CustomSingleSelect from '../../util_components/custom_single_select';
import HighlightSearch from '../../util_components/highlight_search_text';
import Button from '../../util_components/button';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

import './other.css';

class GroupClasses extends Component {
  state = {
    loading: true,
    open_create: false,
    open_edit: false,
    open_details: false,
    name: '',
    description: '',
    class_uuid: '',
    levels: [],
    keywords: [],
    goals: [],
    all_goals: [],
    grc: null,
    search_value: '',
    slug: '',
    is_exclusive_for_backpain_client: 0
  };

  componentDidMount() {
    if (this.props.config_status === 'success') {
      this.set_goals();
    }
    if (this.props.class_types_status === 'none') {
      this.props.get_class_types();
    }
    if (this.props.class_types_status === 'success') {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
      this.set_goals();
    }
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.setState({ loading: false });
    }
  }

  set_goals = () => {
    this.setState({ all_goals: [...this.props.config_values.yoga_goals] });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value, create_err: false });
  };

  onLevelClick = (level) => {
    const levels = [...this.state.levels];
    const index = levels.indexOf(level);
    if (index === -1) levels.push(level);
    else levels.splice(index, 1);
    this.setState({ levels: [...levels] });
  };

  onGoalClick = (goal) => {
    const goals = [...this.state.goals];
    const index = goals.indexOf(goal);
    if (index === -1) goals.push(goal);
    else goals.splice(index, 1);
    this.setState({ goals: [...goals] });
  };

  onKeyClick = (word) => {
    const keywords = [...this.state.keywords];
    const index = keywords.indexOf(word);
    if (index === -1) keywords.push(word);
    else keywords.splice(index, 1);
    this.setState({ keywords: [...keywords] });
  };

  add_new_type = (class_type) => {
    const class_types = [...this.props.class_types, class_type].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.props.set_home_variable('class_types', class_types);
  };

  update_class_type = (payload) => {
    const class_types = [...this.props.class_types];
    const index = class_types.findIndex((c) => c.uuid === payload.class_type_uuid);
    const new_class_type = {
      ...class_types[index],
      name: payload.name,
      description: payload.description,
      goals: payload.goals,
      health_keywords: payload.health_keywords,
      slug: payload.slug
    };
    const new_types = [
      ...class_types.slice(0, index),
      new_class_type,
      ...class_types.slice(index + 1)
    ];
    this.props.set_home_variable('class_types', new_types);
  };

  create_class = () =>
    this.setState({ loading: true }, () => {
      const {
        name,
        description,
        goals,
        keywords,
        open_edit,
        class_uuid,
        slug,
        audience_type,
        is_exclusive_for_backpain_client
      } = this.state;
      const payload = {
        name,
        description,
        goals,
        health_keywords: keywords,
        slug,
        audience_type,
        is_exclusive_for_backpain_client
      };
      let url = get_api_url('create_class_type');
      let success_msg = 'Class type added';
      if (open_edit) {
        url = get_api_url('update_class_type');
        payload.class_type_uuid = class_uuid;
        success_msg = 'Class type updated';
      }
      post_api(url, payload, true)
        .then((res) => {
          this.props.set_notification_variable(true, 'success', success_msg);
          this.close_create();
          this.setState({ loading: false });
          if (open_edit) {
            this.update_class_type(payload);
          } else {
            this.add_new_type(res.data.class_type);
          }
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_edit = (grc) => {
    const goals = grc.goals ? grc.goals : [];
    const keywords = grc.health_keywords ? grc.health_keywords : [];
    this.setState({
      open_details: false,
      goals,
      keywords,
      name: grc.name,
      description: grc.description,
      open_edit: true,
      class_uuid: grc.uuid,
      slug: grc.slug,
      audience_type: grc.audience_type
    });
  };

  open_details = (grc) => this.setState({ grc, open_details: true });

  render_class_types = () => {
    const { search_value } = this.state;
    const searched_classes = this.props.class_types.filter(
      (g) =>
        g.name.toLowerCase().indexOf(search_value.toLowerCase()) !== -1 ||
        g.slug.toLowerCase().indexOf(search_value.toLowerCase()) !== -1
    );
    const file_name = `Class_types_${moment().format('YYYY-MM-DD')}`;
    if (searched_classes.length > 0) {
      return (
        <div className="cus-table">
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={this.props.class_types} name="Transactions">
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Description" value="description" />
            </ExcelSheet>
          </ExcelFile>
          <div className="ct-row ct-h">
            <div className="ct-col ct-la ct-pl-10">ID</div>
            <div className="ct-col ct-la">Name</div>
            <div className="ct-col ct-la">Goals</div>
            {/* <div className="ct-col ct-la">keywords</div> */}
            <div className="ct-col ct-hf">Details</div>
            <div className="ct-col ct-hf">Edit</div>
          </div>
          {searched_classes.map((grc) => (
            <div className="ct-row" key={grc.uuid}>
              <div className="ct-col ct-sm-font ct-bw ct-la ct-pl-10">
                <HighlightSearch full_text={grc.slug} search_text={search_value} />
              </div>
              <div className="ct-col ct-sm-font ct-la ct-bw">
                <HighlightSearch full_text={grc.name} search_text={search_value} />
              </div>
              <div className="ct-col ct-sm-font ct-la">
                <ul>
                  {grc.goals.map((goal, index) => (
                    <li key={index}>{goal}</li>
                  ))}
                </ul>
              </div>
              {/* <div className="ct-col ct-sm-font ct-la">
                                    <ul>
                                        {grc.health_keywords.map((word, index) => <li key={index}>{word}</li>)}
                                    </ul>
                                </div> */}
              <div className="ct-col ct-hf">
                <button onClick={() => this.open_details(grc)}>Details</button>
              </div>
              <div className="ct-col ct-hf">
                <button onClick={() => this.open_edit(grc)}>Edit</button>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  render_grc_details = () => {
    const { grc, open_details } = this.state;
    if (open_details) {
      return (
        <div className="create-group-class-name">
          <div className="create-group-class-name-edit">Uuid:</div>
          <div>{grc.uuid}</div>
          <div className="create-group-class-name-edit">Name:</div>
          <div>{grc.name}</div>
          <div className="create-group-class-name-edit">Description:</div>
          <div>{grc.description}</div>
          {grc.goals.length > 0 ? (
            <>
              <div className="create-group-class-name-edit">Goals:</div>
              <ul>
                {grc.goals.map((goal, index) => (
                  <li key={index}>{goal}</li>
                ))}
              </ul>
            </>
          ) : null}
          {grc.health_keywords.length > 0 ? (
            <>
              <div className="create-group-class-name-edit">Keywords:</div>
              <div>{grc.health_keywords.join(' , ')}</div>
            </>
          ) : null}
          <button onClick={() => this.open_edit(grc)} style={{ marginTop: '20px' }}>
            Edit details
          </button>
        </div>
      );
    }
    return null;
  };

  render_create_keyword = () => {
    const {
      name,
      description,
      goals,
      all_goals,
      keywords,
      open_edit,
      slug,
      audience_type,
      is_exclusive_for_backpain_client
    } = this.state;
    const title = open_edit ? 'Edit group class' : 'Create group class';
    const label_style = {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      marginLeft: '5px',
      marginRight: '10px'
    };
    return (
      <div className="create-group-class-name">
        <h4>{title}</h4>
        <CustomInput
          label="Enter Id*"
          onChange={this.onCommonChange}
          name="slug"
          value={slug}
          style={{ width: '100%' }}
          label_style={{ color: '#aaa', fontSize: '12px', fontWeight: '600' }}
          input_style={{ margin: '0', marginBottom: '10px', fontSize: '14px', height: 'auto' }}
        />
        <CustomInput
          label="Enter Name*"
          onChange={this.onCommonChange}
          name="name"
          value={name}
          style={{ width: '100%' }}
          label_style={{ color: '#aaa', fontSize: '12px', fontWeight: '600' }}
          input_style={{ margin: '0', marginTop: '5px', fontSize: '14px', height: 'auto' }}
        />
        <CustomSingleSelect
          label="Audience Type"
          options={[
            { label: 'General', value: 0 },
            { label: 'Kids', value: 1 },
            { label: 'Seniors', value: 2 },
            { label: 'Women', value: 3 },
            { label: 'Beginners Bootcamp', value: 4 }
          ]}
          name="audience_type"
          onChange={this.onCommonChange}
          value={audience_type}
          style={{ width: '250px' }}
        />
        <div className="create-group-class-name-edit">Enter description:</div>
        <textarea
          style={{ width: '100%', height: '200px' }}
          value={description}
          name="description"
          onChange={this.onCommonChange}
        />
        <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
          Select Goals:
        </div>
        <div className="group-class-goals">
          {all_goals.map((goal) => {
            const selected = goals.indexOf(goal) !== -1;
            const custom_style = { marginRight: '10px', flexShrink: '0' };
            return (
              <CustomCheckbox
                key={goal}
                label={goal}
                checked={selected}
                onClick={() => this.onGoalClick(goal)}
                custom_style={custom_style}
                label_style={label_style}
              />
            );
          })}
        </div>
        <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
          Select Health keywords:
        </div>
        <div className="group-class-goals" style={{ marginBottom: '10px' }}>
          {this.props.health_keywords.map((word) => {
            const selected = keywords.indexOf(word) !== -1;
            const custom_style = { marginRight: '10px', flexShrink: '0' };
            return (
              <CustomCheckbox
                key={word}
                label={word}
                checked={selected}
                onClick={() => this.onKeyClick(word)}
                custom_style={custom_style}
                label_style={label_style}
              />
            );
          })}
        </div>
        <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
          Is exclusive for backpain client :
        </div>
        <div className="group-class-goals">
          <CustomCheckbox
            label="Is exclusive for backpain client"
            checked={is_exclusive_for_backpain_client}
            onClick={() => this.toggle_check_box('is_exclusive_for_backpain_client')}
          />
        </div>
        <div className="create-class-type-header">
          <Button disabled={!name || !description || !slug} onClick={this.create_class}>
            Submit
          </Button>
          <button>Add Health keyword</button>
        </div>
      </div>
    );
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  close_create = () =>
    this.setState({
      open_create: false,
      open_edit: false,
      name: '',
      description: '',
      levels: [],
      goals: [],
      keywords: [],
      class_uuid: '',
      open_details: false,
      slug: ''
    });

  toggle_create = () => this.setState({ open_create: !this.state.open_create });

  open_health_keywords = () => this.props.history.push('/health_keywords');

  render_type_header = () => {
    const { search_value } = this.state;
    return (
      <div className="create-class-type-header">
        <button onClick={this.toggle_create}>Create class type</button>
        <input
          value={search_value}
          onChange={this.onCommonChange}
          name="search_value"
          placeholder="Search by name or id"
        />
        <button onClick={this.open_health_keywords}> Health Keywords</button>
      </div>
    );
  };

  render() {
    const { loading, open_create, open_edit, open_details } = this.state;
    return (
      <CommonHeader loading={loading} title="Group Class Types">
        {this.render_type_header()}
        <CustomModal show={open_create || open_edit} close={this.close_create}>
          {this.render_create_keyword()}
        </CustomModal>
        <CustomModal show={open_details} close={this.close_create}>
          {this.render_grc_details()}
        </CustomModal>
        {this.render_class_types()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  set_home_variable: (key, payload) => dispatch(actions.set_home_variable(key, payload)),
  get_class_types: () => dispatch(actions.get_class_types())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupClasses);
