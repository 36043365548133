import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import CommonHeader from '../../util_components/common_header';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import CreateEditAnnouncement from './create_edit_announcement';
import CustomSingleTable from '../../util_components/custom_single_table';
import { time_day_format, short_time_day_format } from '../../../constants';
import { announcement_version_format } from '../../../utils/formats';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class AnnouncementDetails extends Component {
  state = {
    loading: false,
    details: {},
    minor_versions: [],
    announcement_uuid: '',
    create_announcement_open: false,
    publish_open: false,
    content_open: false,
    teachers_list_open: false,
    teachers_list: [],
    content: null,
    publish_ann_uuid: '',
    is_approve: false,
    schedule_open: false,
    confirm_schedule: false,
    edit_details: {},
    schedule_date: ''
  };

  componentDidMount() {
    try {
      const { uuid } = this.props.match.params;
      if (uuid) {
        this.setState({ announcement_uuid: uuid }, () => this.load_data());
      }
    } catch (e) {
      this.go_to_path('/announcements');
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const payload = { announcement_uuid: this.state.announcement_uuid };
      const url = get_api_url('get_announcement_details');
      post_api(url, payload, true)
        .then((res) =>
          this.setState({
            details: { ...res.data.details },
            minor_versions: [...res.data.minor_versions],
            loading: false
          })
        )
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  submit_announcement = (payload) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('update_announcement');
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ create_announcement_open: false });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  publish = () =>
    this.setState({ loading: true }, () => {
      const { is_approve, publish_ann_uuid } = this.state;
      const payload = { announcement_uuid: publish_ann_uuid };
      let url = get_api_url('publish_announcement');
      if (is_approve) {
        url = get_api_url('approve_announcement');
      }
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ publish_open: false, is_approve: false });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  open_edit = (details) => this.setState({ create_announcement_open: true, edit_details: details });

  close_edit = () => this.setState({ create_announcement_open: false });

  close_publish = () =>
    this.setState({
      publish_open: false,
      publish_ann_uuid: '',
      schedule_open: false,
      confirm_schedule: false,
      schedule_date: ''
    });

  open_publish = (publish_ann_uuid) => this.setState({ publish_ann_uuid, publish_open: true });

  open_approve = (publish_ann_uuid) =>
    this.setState({ is_approve: true }, () => this.open_publish(publish_ann_uuid));

  go_to_path = (path) => this.props.history.push(path);

  render_publish = () => {
    const { is_approve } = this.state;
    return (
      <div>
        <h4>Are you sure you want to {is_approve ? 'approve' : 'publish'}?</h4>
        <button onClick={this.publish}>Confirm</button>
      </div>
    );
  };

  open_schedule = (publish_ann_uuid) => this.setState({ publish_ann_uuid, schedule_open: true });

  render_publish_button = (ver) => {
    if (ver.is_scheduled === 1) {
      return '--';
    }
    if (ver.is_published === 0) {
      if (ver.temp_version > 0) {
        return (
          <button className="ct-small-btn" onClick={() => this.open_approve(ver.uuid)}>
            Approve
          </button>
        );
      }
      return (
        <div>
          <button className="ct-small-btn" onClick={() => this.open_publish(ver.uuid)}>
            Publish
          </button>
          <button className="ct-small-btn" onClick={() => this.open_schedule(ver.uuid)}>
            Schedule
          </button>
        </div>
      );
    }
    return '--';
  };

  render_single_version = (ver) => {
    const is_admin =
      this.props.config_values.user_access === 'SUPER_USER' ||
      this.props.config_values.user_access === 'CARE_ANNOUNCEMENT';
    return (
      <div className="ct-row" key={ver.uuid}>
        <div className="ct-col ct-qf">{`${ver.version}.${ver.minor_version}.${ver.temp_version}`}</div>
        <div className="ct-col ct-sm-font">{ver.name}</div>
        <div className="ct-col ct-qf ct-sm-font">{ver.created_by}</div>
        <div className="ct-col ct-qf ct-sm-font">
          <div>{moment(ver.created_date).tz(this.props.iana_timezone).format(time_day_format)}</div>
        </div>
        <div className="ct-col ct-hf ct-sm-font">
          {ver.is_published === 1 ? <div className="led-green" /> : <div className="led-grey" />}
          {ver.is_scheduled === 1 ? (
            <div>
              <div>Scheduled at </div>
              <div>
                {moment(ver.scheduled_date)
                  .tz(this.props.iana_timezone)
                  .format(short_time_day_format)}
              </div>
            </div>
          ) : null}
        </div>
        <div className="ct-col ct-qf">
          <button className="ct-small-btn" onClick={() => this.open_content(ver.content)}>
            View
          </button>
        </div>
        <div className="ct-col ct-hf">
          <button className="ct-small-btn" onClick={() => this.open_edit(ver)}>
            Edit
          </button>
        </div>
        {is_admin ? <div className="ct-col ct-hf">{this.render_publish_button(ver)}</div> : null}
        <div className="ct-col ct-qf">
          <button
            className="ct-small-btn"
            onClick={() => this.go_to_path(`/announcements/minor/${ver.uuid}`)}
          >
            Details
          </button>
        </div>
      </div>
    );
  };

  render_versions = () => {
    const { minor_versions } = this.state;
    const is_admin =
      this.props.config_values.user_access === 'SUPER_USER' ||
      this.props.config_values.user_access === 'CARE_ANNOUNCEMENT';
    if (minor_versions.length > 0) {
      return (
        <div style={{ marginTop: '20px' }}>
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col ct-qf">Version</div>
              <div className="ct-col">Name</div>
              <div className="ct-col ct-qf">By</div>
              <div className="ct-col ct-qf ct-sm-font">Created</div>
              <div className="ct-col ct-hf ct-sm-font">Published</div>
              <div className="ct-col ct-qf">Content</div>
              <div className="ct-col ct-hf">Edit</div>
              {is_admin ? <div className="ct-col ct-hf" /> : null}
              <div className="ct-col ct-qf">Details</div>
            </div>
            {minor_versions.map(this.render_single_version)}
          </div>
        </div>
      );
    }
    return null;
  };

  open_content = (content) => this.setState({ content, content_open: true });

  close_content = () => this.setState({ content: null, content_open: false });

  render_content = () => {
    const { content } = this.state;
    return (
      <div style={{ marginTop: '20px' }} className="ann-details ql-snow ql-editor">
        content:
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  };

  set_start_date = (key, date) => this.setState({ [key]: date });

  schedule_announcement = () =>
    this.setState({ loading: true }, () => {
      const { publish_ann_uuid, schedule_date } = this.state;
      const sel_start_time = moment(schedule_date).format('YYYY-MM-DD HH:mm:ss');
      const fin_start_date = moment.tz(sel_start_time, this.props.iana_timezone).valueOf();
      const payload = { announcement_uuid: publish_ann_uuid, scheduled_date: fin_start_date };
      const url = get_api_url('schedule_announcement');
      post_api(url, payload, true)
        .then((res) => {
          this.close_publish();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_schedule = () => {
    const { schedule_date, confirm_schedule } = this.state;
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    if (confirm_schedule) {
      return (
        <div>
          <h4>
            Are you sure you want to schedule the announcement at{' '}
            {`${moment(schedule_date).format(time_day_format)} ${curr_timezone}`}
          </h4>
          <button onClick={this.schedule_announcement}>Confirm</button>
          <button onClick={() => this.setState({ confirm_schedule: false })}>Cancel</button>
        </div>
      );
    }
    return (
      <div style={{ height: '400px' }}>
        <h4>Schedule announcement ({curr_timezone})</h4>
        <div>
          <DatePicker
            selected={schedule_date}
            onChange={(date) => this.set_start_date('schedule_date', date)}
            minDate={new Date()}
            showTimeSelect
            dateFormat="yyyy-MM-dd  h:mm aa"
            // popperPlacement="left-end"
          />
        </div>
        <Button disabled={!schedule_date} onClick={() => this.setState({ confirm_schedule: true })}>
          Submit
        </Button>
      </div>
    );
  };

  render() {
    const {
      loading,
      details,
      create_announcement_open,
      publish_open,
      content_open,
      announcement_uuid,
      edit_details,
      schedule_open
    } = this.state;
    const is_admin =
      this.props.config_values.user_access === 'SUPER_USER' ||
      this.props.config_values.user_access === 'CARE_ANNOUNCEMENT';
    return (
      <CommonHeader loading={loading} title="Announcement Version Details" show_back>
        <CustomModal show={create_announcement_open} close={this.close_edit}>
          <CreateEditAnnouncement
            submit_announcement={this.submit_announcement}
            is_edit="1"
            details={edit_details}
          />
        </CustomModal>
        <CustomModal show={publish_open} close={this.close_publish}>
          {this.render_publish()}
        </CustomModal>
        <CustomModal show={schedule_open} close={this.close_publish}>
          {this.render_schedule()}
        </CustomModal>
        <CustomModal show={content_open} close={this.close_content}>
          {this.render_content()}
        </CustomModal>
        <CustomSingleTable data={details} data_format={announcement_version_format} />
        {details.is_published === 0 && is_admin ? (
          <button onClick={() => this.open_publish(announcement_uuid)}>Publish</button>
        ) : null}
        <button onClick={() => this.open_edit(details)}>Edit Details</button>
        <button onClick={() => this.open_content(details.content)}>View Content</button>
        {this.render_versions()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementDetails);
