export const capitalizeFirstLetter = (string) =>
  string.charAt(0).replace(/[_-]/g, ' ').toUpperCase() + string.slice(1).replace(/[_-]/g, ' ');

export const removeSeperators = (string) => string.replace(/[_-]/g, ' ');

export const captialLetters = (string) => string.replace(/[_-]/g, ' ').toUpperCase();

export const convertToSentenceCase = (str) => {
  const newStr = str?.replaceAll('_', ' ');
  const words = newStr?.toLowerCase()?.split(',');
  const sentence = words?.map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1));

  return sentence?.join(', ');
};
