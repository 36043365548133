import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import { image_error_replace, session_type } from '../../../../util_functions';
import { day_format, time_format } from '../../../../constants';
import Paginator from '../../../util_components/paginator';
import ScrollContainer from '../../../util_components/scroll_container';
import * as actions from '../../../../redux/action_creators';
import * as selectors from '../../../../redux/selectors';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import './future_sessions.css';

class FutureSessions extends Component {
  state = { loading: true, sessions: [] };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_all_sessions');
      const is_backpain = this.props.location.pathname === '/sessions/backpain' ? 1 : 0;
      const payload = { timezone: new Date().getTimezoneOffset(), is_backpain };
      post_api(url, payload, true)
        .then((resp) => {
          this.setState({ sessions: [...resp.data.sessions], loading: false });
        })
        .catch((e) => {
          console.log(e);
        });
    });

  componentDidUpdate(prevProps) {
    if (
      prevProps.future_sessions_status === 'loading' &&
      this.props.future_sessions_status === 'success'
    ) {
      this.setState({ sessions: [...this.props.future_sessions] }, () => {
        this.setState({ loading: false });
      });
    }
  }

  go_to_sessions = (id, type) => {
    let search = `?id=${id}`;
    let pathname = '/sessiondetails';
    if (type === 'GROUP_SESSION' || type === 'GROUP_SESSION_STUDENT') {
      search = `?session_uuid=${id}`;
      pathname = 'groupsessiondetails';
    }
    this.props.history.push({ pathname, search });
  };

  render_single_session = (session, index) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    const student_url = `/studentprofile?id=${session.student_uuid}`;
    const cancelled_status = [
      'CANCELLED',
      'CANCELLED_BY_TEACHER',
      'CANCELLED_BY_STUDENT',
      'NO_SHOW_CANCELLATION_1HOUR',
      'TEACHER_NO_SHOW',
      'STUDENT_NO_SHOW',
      'STUDENT_AND_TEACHER_NO_SHOW',
      'JOINED_BUT_CANCELLED',
      'NO_SHOW_WITH_RECORDING'
    ];
    const status_style =
      cancelled_status.indexOf(session.session_status) === -1
        ? { color: '#016601' }
        : { color: '#ED4D4D' };
    const is_backpain = this.props.location.pathname === '/sessions/backpain' ? 1 : 0;
    return (
      <div className="ct-row" key={index + session.session_uuid}>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_avatar_medium} alt="i" onError={image_error_replace} />
              {session.teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-hf">
          {session.is_live === 1 ? <div className="led-green" /> : <div className="led-grey" />}
        </div>
        {is_backpain == 1 ? (
          <div className="ct-col ct-hf ct-sm-font">
            {session.type === 'BACK_PAIN_CONSULT' ? 'Consult' : 'Therapy'}
          </div>
        ) : null}
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-sm-font">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={session.student_avatar_medium} alt="i" onError={image_error_replace} />
              {session.student_name}
            </div>
          </a>
        </div>
        {is_backpain == 0 ? (
          <div className="ct-col ct-si ct-hf">
            {session.is_recurring === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic_repeat@2x.png" alt="ic_rep" />
            ) : null}
          </div>
        ) : null}
        <div className="ct-col ct-vsm-font ct-hf ct-bw" style={status_style}>
          {session.session_status}
          {session.session_status === 'CANCELLED' &&
          !!session.can_diff_hrs &&
          session.can_diff_hrs < 12 ? (
            <span style={{ display: 'block' }}>(Less than 12 Hrs)</span>
          ) : null}
        </div>
        <div className="ct-col">
          <button onClick={() => this.go_to_sessions(session.session_uuid, session.type)}>
            Details
          </button>
        </div>
      </div>
    );
  };

  changePage = (page) => this.props.get_future_sessions(page);

  render_function = () => {
    const is_backpain = this.props.location.pathname === '/sessions/backpain' ? 1 : 0;
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        {this.props.zoom_signature_status === 'loading' ||
        this.props.future_sessions_status === 'loading' ? (
          <CustomLoading />
        ) : null}
        <div className="common-page-header">
          <div className="common-page-title">{`Future Sessions (${this.state.sessions.length})`}</div>
        </div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la">
              <span className="ct-ml25">Teacher</span>
            </div>
            <div className="ct-col ct-hf">Live</div>
            {is_backpain == 1 ? <div className="ct-col ct-hf ct-sm-font">Type</div> : null}
            <div className="ct-col ct-sm-font">Timings</div>
            <div className="ct-col ct-la">
              <span className="ct-ml25">Student</span>
            </div>
            {is_backpain == 0 ? <div className="ct-col ct-hf ct-sm-font">Recurring</div> : null}
            <div className="ct-col ct-hf ct-sm-font">status</div>
            <div className="ct-col">Session Details</div>
          </div>
          {this.state.sessions.map(this.render_single_session)}
        </div>
        <Paginator
          curr_page={this.props.future_sess_curr_page}
          page_limit={this.props.future_sess_page_limit}
          pageChange={this.changePage}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Future Sessions</title>
        </Helmet>
        <ScrollContainer />
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  future_sessions: selectors.future_sessions(state),
  future_sessions_status: selectors.future_sessions_status(state),
  zoom_signature_status: state.loading.zoom_signature_status,
  iana_timezone: state.home.iana_timezone,
  future_sess_curr_page: state.sessions.future_sess_curr_page,
  future_sess_page_limit: state.sessions.future_sess_page_limit
});

const mapDispatchToProps = (dispatch) => ({
  get_future_sessions: (status) => {
    dispatch(actions.get_future_sessions(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FutureSessions);
