import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { image_error_replace } from '../../../util_functions';
import { time_format, day_format_year } from '../../../constants';
import CommonHeader from '../../util_components/common_header';
import CustomInput from '../../util_components/custom_input';
import CustomCheckBox from '../../util_components/custom_checkbox';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import CustomSingleSelect from '../../util_components/custom_single_select';
import { api_with_method, post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

const session_duration_list = [
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '60',
    label: '60 min'
  },
  {
    value: '90',
    label: '90 min'
  }
];

class GroupDetails extends Component {
  state = {
    loading: false,
    repeat_group_uuid: '',
    repeat_group_details: {},
    add_participant_open: false,
    remove_participant_open: false,
    remove_participant_uuid: '',
    remove_participant_confirm: false,
    cancel_repeat_session_open: false,
    cancel_repeat_session_id: 0,
    cancel_repeat_session_reason: '',
    add_session_open: false,
    is_single_session: false,
    add_teacher_open: false,
    teacher_uuid: '',
    slots_loaded: false,
    slots_loading: false,
    edit_details_open: false,
    session_duration: '60',
    teacher_selected: '',
    slot_selected: '',
    slots_list: [],
    student_search_text: '',
    add_student_uuid: '',
    host_student: {},
    participants: [],
    repeat_sessions: [],
    single_sessions: [],
    teachers: [],
    teacher_uuids: [],
    all_teachers: [],
    discount: 0,
    is_price_split: 1,
    non_repeat_group: 0,
    sessions_per_week: 0,
    group_name: '',
    participant_price_60: 0
  };

  componentDidMount() {
    try {
      const { uuid } = this.props.match.params;
      if (uuid) {
        this.setState({ repeat_group_uuid: uuid }, () => this.load_data());
      }
      if (this.props.teachers_list_status === 'success') {
        this.set_teachers_list();
      }
      if (this.props.teachers_list_status === 'none') {
        this.props.get_teachers_list();
      }
    } catch (e) {
      this.go_to_path('/groups');
    }
  }

  go_to_path = (path) => this.props.history.push(path);

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter(
      (t) => t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY'
    );
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    const grp_sess_teach_filters = [...teachers_list];
    grp_sess_teach_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ all_teachers: [...teachers_list], loading: false, grp_sess_teach_filters });
  };

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('ywf_repeat_group');
      const { repeat_group_uuid } = this.state;
      api_with_method('post', url, { repeat_group_uuid }, true)
        .then((res) => {
          const all_participants = [...res.data.repeat_group_details.participants];
          const host_student = all_participants.find((p) => !!p.student_uuid && p.is_host === 1);
          const participants = all_participants.filter((p) => !!p.student_uuid);
          const teachers = all_participants.filter((p) => !!p.teacher_uuid && p.is_active === 1);
          const teacher_uuids = teachers.map((t) => t.teacher_uuid);
          this.setState({
            loading: false,
            repeat_group_details: res.data.repeat_group_details,
            host_student,
            participants,
            teachers,
            teacher_uuids,
            repeat_sessions: [...res.data.repeat_group_details.repeat_sessions],
            single_sessions: [...res.data.repeat_group_details.single_sessions]
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_details = () => {
    const { teacher_uuids, participants } = this.state;
    const details = this.state.repeat_group_details;
    return (
      <div>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Name:</td>
              <td>{details.name}</td>
              <td className="teacher-table-detail">Uuid:</td>
              <td>{details.uuid}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Is price split :</td>
              <td>{details.is_price_split}</td>
              <td className="teacher-table-detail">Participant Price($) :</td>
              <td>{details.participant_price_60}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Sessions per week :</td>
              <td>{details.sessions_per_week}</td>
              <td className="teacher-table-detail">Group share url:</td>
              <td>{details.group_share_url}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Non Repeat Group :</td>
              <td>{details.non_repeat_group}</td>
              <td className="teacher-table-detail" />
              <td />
            </tr>
          </tbody>
        </table>
        <div>
          <button onClick={this.open_edit_details}>Edit Details</button>
          <button onClick={() => this.open_modal('add_participant_open')}>Add Participant</button>
          {this.props.teachers_list_status === 'success' ? (
            <button onClick={() => this.open_modal('add_teacher_open')}>Add Teacher</button>
          ) : null}
          <Button
            disabled={teacher_uuids.length === 0}
            onClick={() => this.open_modal('add_session_open')}
          >
            Schedule Repeat Session
          </Button>
          <Button disabled={teacher_uuids.length === 0} onClick={this.open_add_single_session}>
            Schedule Single Session
          </Button>
          <Button
            disabled={participants.length <= 1}
            onClick={() => this.open_modal('remove_participant_open')}
          >
            Remove participant
          </Button>
        </div>
      </div>
    );
  };

  open_add_single_session = () =>
    this.setState({ add_session_open: true, is_single_session: true });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  on_search_click = () => {
    const { student_search_text } = this.state;
    if (student_search_text.trim().length < 3) {
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.props.search_students({
        search_param: student_search_text
      });
    }
  };

  remove_participant = () =>
    this.setState({ loading: true }, () => {
      const { remove_participant_uuid, repeat_group_details, teacher_uuid } = this.state;
      const payload = {
        ywf_repeat_group_id: repeat_group_details.id,
        student_uuid: remove_participant_uuid
      };
      const url = get_api_url('ywf_repeat_group_participants');
      api_with_method('delete', url, payload, true)
        .then((res) => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  add_particpant = (is_student) =>
    this.setState({ loading: true }, () => {
      const { add_student_uuid, repeat_group_details, teacher_uuid } = this.state;
      const payload = {
        ywf_repeat_group_id: repeat_group_details.id
      };
      if (is_student) {
        payload.student_uuid = add_student_uuid;
      } else {
        payload.teacher_uuid = teacher_uuid;
      }
      const url = get_api_url('ywf_repeat_group_participants');
      api_with_method('put', url, payload, true)
        .then((res) => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_add_participant = () => {
    const { student_search_text, add_student_uuid, repeat_group_details } = this.state;
    let participants = [];
    if (!!repeat_group_details.participants && repeat_group_details.participants.length > 0) {
      participants = repeat_group_details.participants;
    }
    const student_select_values = [];
    this.props.search_students_list.map((st) => {
      const { student_uuid } = st;
      if (student_uuid) {
        const index = participants.findIndex((p) => p.student_uuid === student_uuid);
        if (index === -1 || (index !== -1 && participants[index].status === 'CANCELLED')) {
          const fname = st.first_name ? st.first_name : '';
          const mname = st.middle_name ? st.middle_name : '';
          const lname = st.last_name ? st.last_name : '';
          student_select_values.push({
            label: `${fname} ${mname} ${lname} (${st.email})`,
            value: student_uuid
          });
        }
      }
      return null;
    });
    return (
      <div>
        <h3>Add a participant to the group</h3>
        <div className="display-flex-between-center">
          <CustomInput
            label="Enter student name or email"
            onChange={this.onCommonChange}
            name="student_search_text"
            value={student_search_text}
            style={{ width: '300px' }}
            onKeyPress={this.on_key_press}
          />
          <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
            Search student
          </Button>
        </div>
        {!!student_search_text && this.props.student_search_status === 'success' ? (
          <div>
            <CustomSingleSelect
              label="Select student"
              options={student_select_values}
              onChange={this.onCommonChange}
              name="add_student_uuid"
              value={add_student_uuid}
              style={{ width: '250px' }}
            />
            <Button disabled={!add_student_uuid} onClick={() => this.add_particpant(true)}>
              Add participant
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  render_teachers = () => {
    const { teachers } = this.state;
    return (
      <div>
        <h4>Teachers</h4>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la ct-pl-10">Teacher</div>
            <div className="ct-col ct-hf">Active</div>
          </div>
          {teachers.map((t, index) => {
            const teacher_url = `/teacherprofile?id=${t.teacher_uuid}`;
            return (
              <div className="ct-row" key={index}>
                <div className="ct-col ct-sm-font">
                  <a className="link-no-dec" href={teacher_url}>
                    <div className="profile-img-name">
                      <img src={t.teacher_photo} alt="i" onError={image_error_replace} />
                      {t.teacher_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col ct-hf ct-si">
                  {t.is_active === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render_participants = () => {
    const { participants } = this.state;
    return (
      <div>
        <h4>Participants</h4>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la ct-pl-10">Student</div>
            <div className="ct-col ct-hf">Host</div>
            {/* <div className="ct-col ct-hf">Active</div> */}
          </div>
          {participants.map((p, index) => {
            const student_url = `/studentprofile?id=${p.student_uuid}`;
            return (
              <div className="ct-row" key={index}>
                <div className="ct-col ct-sm-font">
                  <a className="link-no-dec" href={student_url}>
                    <div className="profile-img-name">
                      <img src={p.student_photo} alt="i" onError={image_error_replace} />
                      {p.student_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col ct-hf ct-si">
                  {p.is_host === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                {/* <div className="ct-col ct-hf ct-si">
                                    {p.is_active === 1 ? <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" /> : <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />}
                                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render_remove_participant = () => {
    const { participants, remove_participant_uuid, remove_participant_confirm } = this.state;
    const participants_dis = participants
      .filter((p) => p.is_host === 0)
      .map((p) => ({ label: p.student_name, value: p.student_uuid }));
    // console.log(participants_dis)
    if (remove_participant_confirm) {
      const selected_par = participants.find((p) => p.student_uuid === remove_participant_uuid);
      return (
        <div>
          <h4>Are you sure you want to remove participant</h4>
          <p>Name: {selected_par.student_name}</p>
          <button onClick={() => this.setState({ remove_participant_confirm: false })}>
            Cancel
          </button>
          <button onClick={this.remove_participant}>Confirm</button>
        </div>
      );
    }
    return (
      <div>
        <h2>Remove Participant</h2>
        <CustomSingleSelect
          label="Select participant"
          options={participants_dis}
          name="remove_participant_uuid"
          onChange={this.onCommonChange}
          value={remove_participant_uuid}
          style={{ width: '250px' }}
        />
        <Button
          disabled={!remove_participant_uuid}
          onClick={() => this.open_modal('remove_participant_confirm')}
        >
          Remove
        </Button>
      </div>
    );
  };

  render_add_teacher = () => {
    const { all_teachers, teacher_uuids, teacher_uuid } = this.state;
    const final_teachers = all_teachers.filter((t) => teacher_uuids.indexOf(t.value) === -1);
    return (
      <div>
        <h2>Add teacher</h2>
        <CustomSingleSelect
          label="Select a teacher"
          options={final_teachers}
          name="teacher_uuid"
          onChange={this.onCommonChange}
          value={teacher_uuid}
          style={{ width: '250px' }}
        />
        <Button disabled={!teacher_uuid} onClick={() => this.add_particpant(false)}>
          Add
        </Button>
      </div>
    );
  };

  get_slots = () =>
    this.setState({ slots_loading: true, slots_loaded: false }, () => {
      const { teacher_selected, host_student } = this.state;
      const url = get_api_url('teacher_slots_v1');
      const data = {
        student_uuid: host_student.student_uuid,
        teacher_uuid: this.state.teacher_selected,
        timezone: 330
      };
      post_api(url, data, true)
        .then((response) => {
          const available_slots = [...response.data.available_slots];
          const all_slots = [];
          for (const day_slot of available_slots) {
            const keys = Object.keys(day_slot.slots);
            if (keys.length > 0) {
              for (const key of keys) {
                all_slots.push(...day_slot.slots[key]);
              }
            }
          }
          this.setState({
            slots_list: all_slots,
            slots_loaded: true,
            slots_loading: false,
            slot_selected: ''
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ slots_loading: false });
        });
    });

  on_teacher_change = (e) => {
    this.setState({ teacher_selected: e.target.value }, () => {
      this.get_slots();
    });
  };

  on_slot_change = (e) => {
    this.setState({ slot_selected: e.target.value });
  };

  schedule_session = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('ywf_repeat_group_session');
      const {
        host_student,
        teacher_selected,
        is_single_session,
        slot_selected,
        session_duration,
        repeat_group_details
      } = this.state;
      const payload = {
        student_uuid: host_student.student_uuid,
        teacher_uuid: teacher_selected,
        start_time: parseInt(slot_selected, 10),
        duration: parseInt(session_duration, 10),
        ywf_repeat_group_id: parseInt(repeat_group_details.id, 10),
        is_single_session: is_single_session ? 1 : 0
      };
      api_with_method('put', url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_slots = () => {
    const { session_duration, slots_list, slot_selected, is_single_session } = this.state;
    const slots_array = [];
    const final_slots = slots_list.filter((f) => {
      const duration = session_duration;
      if (duration === '30' && is_single_session) {
        return f.slot_30 === 1;
      }
      if (duration === '30') {
        return f.slot_30 === 1 && f.is_repeat_30 === 1;
      }
      if (duration === '60' && is_single_session) {
        return f.slot_60 === 1;
      }
      if (duration === '60') {
        return f.slot_60 === 1 && f.is_repeat === 1;
      }
      if (duration === '90' && is_single_session) {
        return f.slot_90 === 1;
      }
      if (duration === '90') {
        return f.slot_90 === 1 && f.is_repeat_90 === 1;
      }
    });
    final_slots.map((slot) =>
      slots_array.push({ value: slot.epoch_time, label: slot.local_session_time })
    );
    if (final_slots.length > 0) {
      return (
        <div>
          <CustomSingleSelect
            label="Select a slot"
            options={slots_array}
            onChange={this.on_slot_change}
            value={slot_selected}
            style={{ width: '300px' }}
          />
          <Button disabled={!slot_selected} onClick={this.schedule_session}>
            Schedule
          </Button>
        </div>
      );
    }
    return (
      <div className="tr-no-uuid" style={{ margin: '5px 0' }}>
        No Slots available for this teacher
      </div>
    );
  };

  change_duration = (e) => {
    this.setState({ session_duration: e.target.value, slot_selected: '' });
  };

  render_add_session = () => {
    const { teachers, teacher_selected, slots_loaded, session_duration, is_single_session } =
      this.state;
    const teachers_list = teachers.map((teacher) => ({
      value: teacher.teacher_uuid,
      label: teacher.teacher_name
    }));
    return (
      <div>
        <h2>{`Schedule ${is_single_session ? 'Single' : 'Repeat'} Session`}</h2>
        {/* <p>{`All slots are in timezone ${moment().tz(this.props.iana_timezone).format('z')}`}</p> */}
        <CustomSingleSelect
          label="Select a teacher"
          options={teachers_list}
          onChange={this.on_teacher_change}
          value={teacher_selected}
          style={{ width: '300px' }}
        />
        {slots_loaded ? (
          <>
            <CustomSingleSelect
              label="Select Duration"
              options={session_duration_list}
              onChange={this.change_duration}
              value={session_duration}
              style={{ width: '300px' }}
            />
            {this.render_slots()}
          </>
        ) : null}
      </div>
    );
  };

  render_single_sessions = () => {
    const { single_sessions, teachers } = this.state;
    return (
      <div>
        <h4>Single Sessions</h4>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la ct-pl-10">Teacher</div>
            <div className="ct-col ct-hf">
              <div>Start Time</div>
              <div className="ct-sm-font">(Dur)</div>
            </div>
            <div className="ct-col ct-hf">Status</div>
            <div className="ct-col ct-hf">Details</div>
          </div>
          {single_sessions.map((session) => {
            const teacher = teachers.find((t) => t.teacher_uuid === session.teacher_uuid);
            const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
            return (
              <div className="ct-row" key={session.uuid}>
                <div className="ct-col ct-pl-10">
                  <a className="link-no-dec" href={teacher_url}>
                    <div className="profile-img-name">
                      <img src={teacher.teacher_photo} onError={image_error_replace} alt="x" />
                      {teacher.teacher_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col ct-hf">
                  <div>
                    {moment(session.repeat_time)
                      .tz(this.props.iana_timezone)
                      .format(day_format_year)}
                  </div>
                  <div>
                    {moment(session.repeat_time).tz(this.props.iana_timezone).format(time_format)}
                  </div>
                  <div>{`(${session.duration} mins)`}</div>
                </div>
                <div className="ct-col ct-hf">{session.session_status}</div>
                <div className="ct-col ct-hf">
                  {session.session_uuid ? (
                    <a
                      className="link-btn"
                      href={`/groupsessiondetails?session_uuid=${session.session_uuid}`}
                    >
                      Details
                    </a>
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render_repeat_sessions = () => {
    const { repeat_sessions, teachers } = this.state;
    return (
      <div>
        <h4>Repeat Sessions</h4>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la ct-pl-10">Teacher</div>
            <div className="ct-col">
              <div>Start Time</div>
              <div className="ct-sm-font">(Dur)</div>
            </div>
            <div className="ct-col ct-hf">Is Active</div>
            <div className="ct-col ct-hf" />
          </div>
          {repeat_sessions.map((session) => {
            const teacher = teachers.find((t) => t.teacher_uuid === session.teacher_uuid);
            const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
            return (
              <div className="ct-row" key={session.uuid}>
                <div className="ct-col">
                  <a className="link-no-dec" href={teacher_url}>
                    <div className="profile-img-name">
                      <img src={teacher.teacher_photo} onError={image_error_replace} alt="x" />
                      {teacher.teacher_name}
                    </div>
                  </a>
                </div>
                <div className="ct-col">
                  <div>
                    Every{' '}
                    {moment(session.repeat_time)
                      .tz(this.props.iana_timezone)
                      .format('dddd, hh:mm A z')}
                  </div>
                  <div className="ct-sm-font">{`(${session.duration} mins)`}</div>
                </div>
                <div className="ct-col ct-hf ct-si">
                  {session.is_active === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-hf">
                  {session.is_active === 1 ? (
                    <button
                      onClick={() =>
                        this.setState({
                          cancel_repeat_session_open: true,
                          cancel_repeat_session_id: session.id
                        })
                      }
                    >
                      Cancel
                    </button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  cancel_repeat_session = () =>
    this.setState({ loading: true }, () => {
      const { cancel_repeat_session_id, repeat_group_details, cancel_repeat_session_reason } =
        this.state;
      const payload = {
        ywf_repeat_group_session_id: cancel_repeat_session_id,
        ywf_repeat_group_id: repeat_group_details.id,
        reason_for_cancel: cancel_repeat_session_reason
      };
      const url = get_api_url('ywf_repeat_group_session');
      api_with_method('delete', url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_cancel_repeat_session = () => {
    const { cancel_repeat_session_reason } = this.state;
    return (
      <div>
        <h4>Are you sure you want to cancel the repeat session?</h4>
        <CustomInput
          label="Enter reason"
          onChange={this.onCommonChange}
          name="cancel_repeat_session_reason"
          value={cancel_repeat_session_reason}
          style={{ width: '300px' }}
          onKeyPress={this.on_key_press}
        />
        <Button onClick={this.cancel_repeat_session} disabled={!cancel_repeat_session_reason}>
          Confirm
        </Button>
        <button onClick={this.close_modal}>Cancel</button>
      </div>
    );
  };

  open_edit_details = () => {
    const { repeat_group_details } = this.state;
    this.setState({
      edit_details_open: true,
      discount: repeat_group_details.discount,
      cancel_repeat_session_id: 0,
      is_price_split: repeat_group_details.is_price_split,
      sessions_per_week: repeat_group_details.sessions_per_week,
      participant_price_60: repeat_group_details.participant_price_60,
      group_name: repeat_group_details.name,
      non_repeat_group: repeat_group_details.non_repeat_group
    });
  };

  open_modal = (modal) => this.setState({ [modal]: true });

  close_modal = () =>
    this.setState(
      {
        add_participant_open: false,
        student_search_text: '',
        cancel_repeat_session_open: false,
        slots_loaded: false,
        session_duration: '60',
        remove_participant_uuid: '',
        slots_loading: false,
        remove_participant_open: false,
        remove_participant_confirm: false,
        teacher_selected: '',
        slots_list: [],
        is_single_session: false,
        slot_selected: '',
        edit_details_open: false,
        cancel_repeat_session_reason: '',
        add_student_uuid: '',
        add_teacher_open: false,
        teacher_uuid: '',
        add_session_open: false
      },
      () => this.props.set_loading_variable('student_search_status', 'none')
    );

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  toggle_split = () => this.setState({ is_price_split: this.state.is_price_split === 1 ? 0 : 1 });

  toggle_non_repeat = () =>
    this.setState({ non_repeat_group: this.state.non_repeat_group === 1 ? 0 : 1 });

  submit_edit_details = () =>
    this.setState({ loading: true }, () => {
      const {
        is_price_split,
        group_name,
        sessions_per_week,
        discount,
        participant_price_60,
        repeat_group_details,
        non_repeat_group
      } = this.state;
      const payload = {
        repeat_group_uuid: repeat_group_details.uuid,
        is_active: 1,
        is_price_split,
        non_repeat_group,
        discount,
        sessions_per_week,
        name: group_name,
        participant_price_60: parseInt(participant_price_60)
      };
      const url = get_api_url('ywf_repeat_group');
      api_with_method('patch', url, payload, true)
        .then(() => {
          this.close_modal();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_edit_details = () => {
    const {
      is_price_split,
      group_name,
      sessions_per_week,
      participant_price_60,
      non_repeat_group
    } = this.state;
    return (
      <div>
        <h2>Edit Group Details</h2>
        <CustomInput
          label="Enter group name*"
          onChange={this.onCommonChange}
          name="group_name"
          value={group_name}
          style={{ width: '300px' }}
        />
        <CustomInput
          label="Participant Price ($)"
          onChange={this.on_number_change}
          name="participant_price_60"
          value={participant_price_60}
          type="number"
          style={{ width: '150px' }}
        />
        <CustomInput
          label="Sessions per Week"
          onChange={this.on_number_change}
          name="sessions_per_week"
          value={sessions_per_week}
          type="number"
          style={{ width: '150px' }}
        />
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Price split"
            checked={is_price_split}
            onClick={this.toggle_split}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Non Repeat Group"
            checked={non_repeat_group}
            onClick={this.toggle_non_repeat}
          />
        </div>
        <button onClick={this.submit_edit_details}>Submit</button>
      </div>
    );
  };

  render() {
    const {
      loading,
      add_participant_open,
      add_teacher_open,
      edit_details_open,
      remove_participant_open,
      cancel_repeat_session_open,
      single_sessions,
      repeat_sessions,
      add_session_open,
      slots_loading,
      teachers
    } = this.state;
    const show_loading = this.props.student_search_status === 'loading' || loading || slots_loading;
    return (
      <CommonHeader loading={show_loading} title="Group Details" show_back>
        {this.render_details()}
        {this.render_participants()}
        {teachers.length > 0 ? this.render_teachers() : null}
        {repeat_sessions.length > 0 ? this.render_repeat_sessions() : null}
        {single_sessions.length > 0 ? this.render_single_sessions() : null}
        <CustomModal show={add_participant_open} close={this.close_modal}>
          {this.render_add_participant()}
        </CustomModal>
        <CustomModal show={remove_participant_open} close={this.close_modal}>
          {this.render_remove_participant()}
        </CustomModal>
        <CustomModal show={add_teacher_open} close={this.close_modal}>
          {this.render_add_teacher()}
        </CustomModal>
        <CustomModal show={add_session_open} close={this.close_modal}>
          {this.render_add_session()}
        </CustomModal>
        <CustomModal show={edit_details_open} close={this.close_modal}>
          {this.render_edit_details()}
        </CustomModal>
        <CustomModal show={cancel_repeat_session_open} close={this.close_modal}>
          {this.render_cancel_repeat_session()}
        </CustomModal>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  search_students_list: state.students.search_students_list,
  student_search_status: state.loading.student_search_status,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  search_students: (payload) => {
    dispatch(actions.search_students(payload));
  },
  set_loading_variable: (key, payload) => {
    dispatch(actions.set_loading_variable(key, payload));
  },
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
