export const class_page_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Id',
    key: 'slug'
  },
  {
    title: 'No. Teachers',
    key: 'regular_teachers_count'
  },
  {
    title: 'Class time',
    key: 'class_time'
  },
  {
    title: 'Goals',
    key: 'goals',
    type: 'list_ul',
    full_row: true
  },
  {
    title: 'List Thumbnail',
    key: 'list_thumbnail',
    full_row: true
  },
  {
    title: 'Banner Image',
    key: 'banner_image',
    full_row: true
  },
  {
    title: 'Video Url',
    key: 'video_url',
    full_row: true
  },
  {
    title: 'Video thumbanil url',
    key: 'video_thumbnail_url',
    full_row: true
  },
  {
    title: 'Visible to Members',
    key: 'visible_to_members',
    type: 'yes_no'
  },
  {
    title: 'Visible to Non Members',
    key: 'visible_to_non_members',
    type: 'yes_no'
  },
  {
    title: 'Visible to Trial users',
    key: 'visible_to_trial_users',
    type: 'yes_no'
  },
  {
    title: 'Hide from Newbie Trial Users',
    key: 'hide_from_newbie_trial',
    type: 'yes_no'
  },
  {
    title: 'Hide from Beginner Trial Users',
    key: 'hide_from_beginner_trial',
    type: 'yes_no'
  },
  {
    title: 'Target Audience',
    key: 'yoga_level'
  },
  {
    title: 'Intensity',
    key: 'intensity'
  },
  {
    title: 'Stretching',
    key: 'stretching'
  },
  {
    title: 'Relaxation',
    key: 'relaxation'
  },
  {
    title: 'Easy Filters',
    key: 'easy_filters_list'
  },
  {
    title: 'Categories',
    key: 'categories'
  }
];

export const announcement_version_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Version',
    key: 'version'
  },
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Published',
    key: 'is_published',
    type: 'yes_no'
  },
  {
    title: 'Published date',
    key: 'published_date',
    type: 'time_day_format'
  },
  {
    title: 'Created By',
    key: 'created_by'
  },
  {
    title: 'Created date',
    key: 'created_date',
    type: 'time_day_format'
  }
];

export const announcement_minor_version_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Version',
    key: 'full_version'
  },
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Published',
    key: 'is_published',
    type: 'yes_no'
  },
  {
    title: 'Published date',
    key: 'published_date',
    type: 'time_day_format'
  },
  {
    title: 'Scheduled',
    key: 'is_scheduled',
    type: 'yes_no'
  },
  {
    title: 'Scheduled date',
    key: 'scheduled_date',
    type: 'time_day_format'
  },
  {
    title: 'Created By',
    key: 'created_by'
  },
  {
    title: 'Created date',
    key: 'created_date',
    type: 'time_day_format'
  }
];

export const teacher_profile_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Teacher Name',
    key: 'full_name'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Email Verified',
    key: 'email_verified',
    type: 'yes_no'
  },
  {
    title: 'Phone number Verified',
    key: 'verified_phone_number',
    type: 'yes_no'
  },
  {
    title: 'Onboarding Status',
    key: 'status'
  },
  {
    title: 'Vishesh Teacher',
    key: 'vishesh_teacher',
    type: 'yes_no'
  },
  {
    title: 'Test User',
    key: 'test_user',
    type: 'yes_no'
  },
  {
    title: 'Resume Link',
    key: 'resume_document_url',
    type: 'link'
  },
  {
    title: 'Intro Video Link',
    key: 'video_url',
    type: 'link'
  },
  {
    title: 'Phone',
    key: 'phone_personal'
  },
  {
    title: 'Gender',
    key: 'gender'
  },
  {
    title: 'Marital Status',
    key: 'marital_status'
  },
  {
    title: 'D.O.B',
    key: 'date_of_birth'
  },
  {
    title: 'Iana Timezone',
    key: 'iana_timezone'
  },
  {
    title: 'Timezone Offset from GMT',
    key: 'teacher_utc_offset'
  },
  {
    title: 'Teacher current time',
    key: 'teacher_current_time'
  },
  {
    title: 'Address',
    key: 'full_address',
    full_row: true
  },
  {
    title: 'Zipcode',
    key: 'zipcode'
  },
  {
    title: 'Teaching Experience',
    key: 'years_of_yoga_teaching_experience',
    suffix: 'Years'
  },
  {
    title: 'Practise Experience',
    key: 'years_of_yoga_practise',
    suffix: 'Years'
  },
  {
    title: 'Training Experience',
    key: 'years_of_training_experience',
    suffix: 'Years'
  },
  {
    title: 'Slug',
    key: 'slug'
  },
  {
    title: 'Opt In 1on1 Sessions',
    key: 'opt_in_1on1_sessions',
    type: 'yes_no'
  },
  {
    title: 'Opt In Group Classes',
    key: 'opt_in_group_classes',
    type: 'yes_no'
  },
  {
    title: 'Opt In YWF Sessions',
    key: 'opt_in_yoga_with_friends_sessions',
    type: 'yes_no'
  },
  {
    title: 'Opt In Consult Sessions',
    key: 'is_yoga_consult_teacher',
    type: 'yes_no'
  },
  {
    title: 'Opt In Community Sessions',
    key: 'opt_in_community_classes',
    type: 'yes_no'
  },
  {
    title: 'Opt In Concierge',
    key: 'opt_in_concierge',
    type: 'yes_no'
  },
  {
    title: 'Therapeautic Teacher',
    key: 'therapeautic',
    type: 'yes_no'
  },
  {
    title: 'Concierge Per Day Limit',
    key: 'concierge_per_day_limit'
  },
  {
    title: 'Concierge Signature Image',
    key: 'concierge_signature_image'
  },
  {
    title: 'Consult URL',
    key: 'con_url',
    type: 'link'
  },
  {
    title: 'Reco for Members',
    key: 'reco_for_members'
  },
  {
    title: 'Reco for Trial',
    key: 'reco_for_trial'
  },
  {
    title: 'Back Pain Consultant',
    key: 'is_consultant_therapist',
    type: 'yes_no'
  },
  {
    title: 'Back Pain Coach',
    key: 'is_back_pain_therapist',
    type: 'yes_no'
  },
  {
    title: 'Consultant therapist in preferred group',
    key: 'is_consultant_therapist_in_preferred_group',
    type: 'yes_no',
    full_row: true
  },
  {
    title: 'Lead time for backpain consult session',
    key: 'lead_time_backpain_consult'
  },
  {
    title: 'Lead time for booking',
    key: 'lead_time'
  }
];

export const student_profile_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Student Name',
    key: 'name'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Receipt Name',
    key: 'receipt_name'
  },
  {
    title: 'Email Verified',
    key: 'email_verified',
    type: 'yes_no'
  },
  {
    title: 'User Banned',
    key: 'is_banned',
    type: 'yes_no'
  },
  {
    title: 'Phone',
    key: 'phone_no_full'
  },
  {
    title: 'Number of sessions ',
    key: 'number_of_sessions_finished'
  },
  {
    title: 'Waiting Queue',
    key: 'waiting_queue'
  },
  {
    title: 'Waiting days',
    key: 'waiting_days'
  },
  {
    title: 'Yoga Level',
    key: 'yoga_level'
  },
  {
    title: 'Health History',
    key: 'medical_history_details'
  },
  {
    title: 'Session Price',
    key: 'session_price'
  },
  {
    title: 'Credits Available',
    key: 'credits_available'
  },
  {
    title: 'Credits Expiry',
    key: 'credits_expiry'
  },
  {
    title: 'Sessions Remaining',
    key: 'sessions_remaining'
  },
  {
    title: 'Teacher Evaluator',
    key: 'teacher_evaluator',
    type: 'yes_no'
  },
  {
    title: 'Test User',
    key: 'test_user',
    type: 'yes_no'
  },
  {
    title: 'IANA Timezone',
    key: 'iana_timezone'
  },
  {
    title: 'Timezone Offset from GMT',
    key: 'student_utc_offset'
  },
  {
    title: 'Student current time',
    key: 'student_current_time'
  },
  {
    title: 'Created date',
    key: 'created_date',
    type: 'day_format_full_year'
  },
  {
    title: 'Trial end date',
    key: 'trial_end_date',
    type: 'day_format_full_year'
  },
  {
    title: 'Subscribed',
    key: 'is_student_subscribed',
    type: 'yes_no'
  },
  {
    title: 'Allow booking outside US',
    key: 'allow_booking_outside_us',
    type: 'yes_no'
  },
  {
    title: 'Access to vishesh teacher',
    key: 'allow_vishesh_teacher',
    type: 'yes_no'
  },
  {
    title: 'Referral url',
    key: 'latest_referral_url',
    type: 'link'
  },
  {
    title: 'Email Login',
    key: 'from_email',
    type: 'yes_no'
  },
  {
    title: 'FB Login',
    key: 'from_fb',
    type: 'yes_no'
  },
  {
    title: 'Google Login',
    key: 'from_google',
    type: 'yes_no'
  },
  {
    title: 'Experience',
    key: 'years_of_experience'
  },
  {
    title: 'Marketing messages (AC)',
    key: 'active_campaign_url',
    type: 'link'
  },
  {
    title: 'UTM Source',
    key: 'utm_source'
  },
  {
    title: 'UTM Medium',
    key: 'utm_medium'
  },
  {
    title: 'UTM Campaign',
    key: 'utm_campaign'
  },
  {
    title: 'UTM Content',
    key: 'utm_content'
  },
  {
    title: 'Free Trail',
    key: 'is_free_trial',
    type: 'yes_no'
  },
  {
    title: 'Replays Private',
    key: 'replays_private_to_student',
    type: 'yes_no'
  },
  ,
  {
    title: 'Device',
    key: 'device'
  },
  ,
  {
    title: 'Tried Booking Session',
    key: 'has_tried_session_booking',
    type: 'yes_no'
  },
  {
    title: 'Funnel url',
    key: 'funnel_url'
  },
  {
    title: 'Allow teacher replacement',
    key: 'allow_teacher_replacement',
    type: 'yes_no'
  },
  {
    title: 'Gender',
    key: 'gender'
  },
  {
    title: 'Age',
    key: 'age'
  },
  {
    title: 'Class Preference',
    key: 'class_preference'
  },
  {
    title: 'Goals',
    key: 'yoga_goals',
    type: 'list',
    list_delim: ',',
    full_row: true
  },
  {
    title: 'Primary goal',
    key: 'primary_goal',
    full_row: true
  },
  {
    title: 'Yoga Types',
    key: 'yoga_types_practiced',
    type: 'list',
    list_delim: ',',
    full_row: true
  },
  {
    title: 'Medical records of student',
    key: 'health_history_list',
    type: 'list_of_links',
    full_row: true
  },
  {
    title: 'Student Preferred Time',
    key: 'preferred_practice_timebands',
    type: 'string_array',
    list_delim: ',',
    full_row: true
  },
  {
    title: 'Date Of Birth',
    key: 'dob'
  }
];

export const student_profile_format_backpain = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Student Name',
    key: 'name'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Receipt Name',
    key: 'receipt_name'
  },
  {
    title: 'Email Verified',
    key: 'email_verified',
    type: 'yes_no'
  },
  {
    title: 'User Banned',
    key: 'is_banned',
    type: 'yes_no'
  },
  {
    title: 'Phone',
    key: 'phone_no_full'
  },
  {
    title: 'Number of sessions ',
    key: 'number_of_sessions_finished'
  },
  {
    title: 'Waiting Queue',
    key: 'waiting_queue'
  },
  {
    title: 'Waiting days',
    key: 'waiting_days'
  },
  {
    title: 'Yoga Level',
    key: 'yoga_level'
  },
  {
    title: 'Session Price',
    key: 'session_price'
  },
  {
    title: 'Credits Available',
    key: 'credits_available'
  },
  {
    title: 'Credits Expiry',
    key: 'credits_expiry'
  },
  {
    title: 'Sessions Remaining',
    key: 'sessions_remaining'
  },
  {
    title: 'Teacher Evaluator',
    key: 'teacher_evaluator',
    type: 'yes_no'
  },
  {
    title: 'Test User',
    key: 'test_user',
    type: 'yes_no'
  },
  {
    title: 'IANA Timezone',
    key: 'iana_timezone'
  },
  {
    title: 'Timezone Offset from GMT',
    key: 'student_utc_offset'
  },
  {
    title: 'Student current time',
    key: 'student_current_time'
  },
  {
    title: 'Created date',
    key: 'created_date',
    type: 'day_format_full_year'
  },
  {
    title: 'Trial end date',
    key: 'trial_end_date',
    type: 'day_format_full_year'
  },
  {
    title: 'Subscribed',
    key: 'is_student_subscribed',
    type: 'yes_no'
  },
  {
    title: 'Allow booking outside US',
    key: 'allow_booking_outside_us',
    type: 'yes_no'
  },
  {
    title: 'Access to vishesh teacher',
    key: 'allow_vishesh_teacher',
    type: 'yes_no'
  },
  {
    title: 'Referral url',
    key: 'latest_referral_url',
    type: 'link'
  },
  {
    title: 'Email Login',
    key: 'from_email',
    type: 'yes_no'
  },
  {
    title: 'FB Login',
    key: 'from_fb',
    type: 'yes_no'
  },
  {
    title: 'Google Login',
    key: 'from_google',
    type: 'yes_no'
  },
  {
    title: 'Experience',
    key: 'years_of_experience'
  },
  {
    title: 'Marketing messages (AC)',
    key: 'active_campaign_url',
    type: 'link'
  },
  {
    title: 'UTM Source',
    key: 'utm_source'
  },
  {
    title: 'UTM Medium',
    key: 'utm_medium'
  },
  {
    title: 'UTM Campaign',
    key: 'utm_campaign'
  },
  {
    title: 'UTM Content',
    key: 'utm_content'
  },
  {
    title: 'Free Trail',
    key: 'is_free_trial',
    type: 'yes_no'
  },
  {
    title: 'Replays Private',
    key: 'replays_private_to_student',
    type: 'yes_no'
  },
  ,
  {
    title: 'Device',
    key: 'device'
  },
  ,
  {
    title: 'Tried Booking Session',
    key: 'has_tried_session_booking',
    type: 'yes_no'
  },
  {
    title: 'Funnel url',
    key: 'funnel_url'
  },
  {
    title: 'Allow teacher replacement',
    key: 'allow_teacher_replacement',
    type: 'yes_no'
  },
  {
    title: 'Gender',
    key: 'gender'
  },
  {
    title: 'Age',
    key: 'age'
  },
  {
    title: 'Class Preference',
    key: 'class_preference'
  },
  {
    title: 'Height',
    key: 'client_height'
  },
  {
    title: 'Weight',
    key: 'weight'
  },
  {
    title: 'Pain Area',
    key: 'back_pain_location',
    type: 'list',
    full_row: true
  },
  {
    title: 'Back Pain Duration',
    key: 'back_pain_duration',
    full_row: true
  },
  {
    title: 'Back Pain Trigger',
    key: 'back_pain_trigger',
    full_row: true
  },
  {
    title: 'Back Pain Doctor Diagnosis',
    key: 'back_pain_doctor_diagnosis',
    list_delim: ',',
    full_row: true
  },
  {
    title: 'Back Pain Doctor Other Diagnosis',
    key: 'back_pain_doctor_other_diagnosis',
    full_row: true
  },
  {
    title: 'Back Pain Intensity',
    key: 'back_pain_intensity',
    full_row: true
  },
  {
    title: 'Medical records of student',
    key: 'health_history_list',
    type: 'list_of_links',
    full_row: true
  },
  {
    title: 'Health History',
    key: 'medical_history_details',
    full_row: true
  },

  {
    title: 'Student Preferred Time',
    key: 'preferred_practice_timebands',
    type: 'string_array',
    list_delim: ',',
    full_row: true
  },
  {
    title: 'Date Of Birth',
    key: 'dob'
  }
];

export const timeline_data_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Id',
    key: 'id'
  },
  {
    title: 'Event Name',
    key: 'event_name',
    full_row: true
  },
  {
    title: 'Message',
    key: 'message',
    full_row: true
  },
  {
    title: 'Meta data',
    key: 'metadata',
    full_row: true,
    type: 'metadata'
  },
  {
    title: 'Created date',
    key: 'created_date',
    type: 'time_day_format'
  },
  {
    title: 'Modified date',
    key: 'modified_date',
    type: 'time_day_format'
  },
  {
    title: 'Message type',
    key: 'msg_type'
  },
  {
    title: 'Request Id',
    key: 'request_id'
  },
  {
    title: 'Request time',
    key: 'request_time',
    type: 'time_day_format'
  },
  {
    title: 'Service',
    key: 'service'
  },
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Student name',
    key: 'student_name'
  },
  {
    title: 'Student uuid',
    key: 'student_uuid',
    full_row: true
  },
  {
    title: 'Source user',
    key: 'source_user',
    full_row: true
  },
  {
    title: 'Source user type',
    key: 'source_user_type'
  },
  {
    title: 'Teacher name',
    key: 'teacher_name'
  },
  {
    title: 'Teacher uuid',
    key: 'teacher_uuid',
    full_row: true
  },
  {
    title: 'User details',
    key: 'user_details',
    full_row: true
  }
];

export const mail_meta_data = [
  {
    title: 'Session time',
    key: 'session_time'
  },
  {
    title: 'Session date',
    key: 'session_date'
  },
  {
    title: 'To email',
    key: 'to_email',
    full_row: true
  },
  {
    title: 'Contact message from teacher',
    key: 'contact_message_from_teacher',
    full_row: true
  },
  {
    title: 'Email template name',
    key: 'email_template_name',
    full_row: true
  },
  {
    title: 'Session student name',
    key: 'session_student_name'
  },
  {
    title: 'Session teacher name',
    key: 'session_teacher_name'
  },
  {
    title: 'Subject',
    key: 'subject',
    full_row: true
  },
  {
    title: 'Body',
    key: 'body',
    full_row: true,
    type: 'html_data'
  }
];

export const coupon_offer_format = [
  {
    title: 'Uuid',
    key: 'uuid',
    full_row: true
  },
  {
    title: 'Name',
    key: 'offer_name'
  },
  {
    title: 'Description',
    key: 'description'
  },
  {
    title: 'Type',
    key: 'type'
  },
  {
    title: 'Type ID',
    key: 'type_id'
  },
  {
    title: 'Discount Type',
    key: 'value_type'
  },
  {
    title: 'Discount',
    key: 'value'
  },
  {
    title: 'Currency',
    key: 'currency'
  },
  {
    title: 'Usage',
    key: 'coupon_used_by'
  },
  {
    title: 'Mapping Present',
    key: 'offer_type_mapped',
    type: 'yes_no'
  },
  {
    title: 'Teacher Offer',
    key: 'teacher_associated_offer',
    type: 'yes_no'
  }
];
