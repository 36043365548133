import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import moment from 'moment';
import CustomLoading from '../../../util_components/custom_loading';
import * as actions from '../../../../redux/action_creators';
import * as selectors from '../../../../redux/selectors';
import { groupBy } from '../../../../util_functions';
import { iana_values, time_half_values } from '../../../../constants';

class SessionsReport extends Component {
  state = { all_sessions: [], loading: true };

  componentDidMount() {
    if (this.props.past_sessions_status === 'none') this.props.get_future_sessions();
    if (this.props.future_sessions_status === 'none') this.props.get_past_sessions();
    if (
      this.props.future_sessions_status === 'success' &&
      this.props.past_sessions_status === 'success'
    )
      this.load_data_for_graphs();
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.past_sessions_status === 'loading' &&
        this.props.past_sessions_status === 'success') ||
      (prevProps.future_sessions_status === 'loading' &&
        this.props.future_sessions_status === 'success')
    ) {
      if (
        this.props.future_sessions_status === 'success' &&
        this.props.past_sessions_status === 'success'
      ) {
        this.load_data_for_graphs();
      }
    }
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.compSessWiTime();
      this.schSessWiTime();
    }
  }

  get_time_zone = () => {
    const time_zone = this.props.iana_timezone;
    const index = iana_values.findIndex((x) => x.value === time_zone);
    return iana_values[index].label;
  };

  load_data_for_graphs = () => {
    this.setState(
      {
        future_sessions: [...this.props.future_sessions],
        past_sessions: [...this.props.past_sessions]
      },
      () =>
        this.setState({ loading: false }, () => {
          this.compSessWiTeach();
          this.schSessWiTeach();
          this.compSessWiTime();
          this.schSessWiTime();
        })
    );
  };

  compSessWiTeach = () => {
    const past_sessions = [...this.state.past_sessions];
    const filtered_data = past_sessions.filter(
      (session) => session.student_joined === 1 && session.teacher_joined === 1
    );
    const processed_data = groupBy('teacher_uuid', filtered_data);
    const final_data = Object.keys(processed_data).map((key) => ({
      x: processed_data[key][0].teacher_name,
      y: processed_data[key].length
    }));
    final_data.sort((a, b) => a.y - b.y);
    const chart = am4core.create('compSessWiTeach', am4charts.PieChart);
    chart.data = final_data;
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'y';
    pieSeries.dataFields.category = 'x';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.text = '{x}';
    pieSeries.slices.template.tooltipText = '{x}: {y}';
  };

  schSessWiTeach = () => {
    const future_sessions = [...this.state.future_sessions];
    const processed_data = groupBy('teacher_uuid', future_sessions);
    const final_data = Object.keys(processed_data).map((key) => ({
      x: processed_data[key][0].teacher_name,
      y: processed_data[key].length
    }));
    final_data.sort((a, b) => a.y - b.y);
    const chart = am4core.create('schSessWiTeach', am4charts.PieChart);
    chart.data = final_data;
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'y';
    pieSeries.dataFields.category = 'x';
    chart.innerRadius = am4core.percent(40);

    pieSeries.slices.template.stroke = am4core.color('#c4c4c4');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.text = '{x}';
    pieSeries.slices.template.tooltipText = '{x}: {y}';
  };

  compSessWiTime = () => {
    const past_sessions = [...this.state.past_sessions];
    let values = { ...time_half_values };
    past_sessions.forEach((session) => {
      const hour_str = moment(session.epoch_start_time)
        .tz(this.props.iana_timezone)
        .format('HH:mm')
        .toString();
      const count = values[hour_str] ? values[hour_str] : 0;
      values = { ...values, [hour_str]: count + 1 };
    });
    const final_data = Object.keys(values).map((key) => ({
      x: key,
      y: values[key]
    }));
    const chart = am4core.create('compSessWiTime', am4charts.XYChart);
    chart.data = final_data;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'x';
    categoryAxis.title.text = 'Time';
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'No. of sessions';
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'y';
    series.dataFields.categoryX = 'x';
    series.columns.template.tooltipText = 'No. of sessions: {y} at {x}';
  };

  schSessWiTime = () => {
    const future_sessions = [...this.state.future_sessions];
    let values = { ...time_half_values };
    future_sessions.forEach((session) => {
      const hour_str = moment(session.epoch_start_time)
        .tz(this.props.iana_timezone)
        .format('HH:mm')
        .toString();
      const count = values[hour_str] ? values[hour_str] : 0;
      values = { ...values, [hour_str]: count + 1 };
    });
    const final_data = Object.keys(values).map((key) => ({
      x: key,
      y: values[key]
    }));
    const chart = am4core.create('schSessWiTime', am4charts.XYChart);
    chart.data = final_data;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'x';
    categoryAxis.title.text = 'Time';
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'No. of sessions';
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'y';
    series.dataFields.categoryX = 'x';
    series.columns.template.tooltipText = 'No. of sessions: {y} at {x}';
  };

  render() {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="common-page-title">All Sessions</div>
        </div>
        <div className="custom-charts-cont">
          <div className="custom-chart-small">
            <p>Completed Sessions By Teacher</p>
            <div className="custom-chart-pie" id="compSessWiTeach" />
          </div>
          <div className="custom-chart-small">
            <p>Scheduled Sessions By Teacher</p>
            <div className="custom-chart-pie" id="schSessWiTeach" />
          </div>
        </div>

        <div className="custom-charts-full">
          <div>
            <p>Completed Sessions By Time in {this.get_time_zone()}</p>
            <div className="custom-chart-pie" id="compSessWiTime" />
          </div>
        </div>
        <div className="custom-charts-full">
          <div>
            <p>Scheduled Sessions By Time in {this.get_time_zone()}</p>
            <div className="custom-chart-pie" id="schSessWiTime" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  past_sessions: selectors.past_sessions(state),
  past_sessions_status: selectors.past_sessions_status(state),
  future_sessions: selectors.future_sessions(state),
  future_sessions_status: selectors.future_sessions_status(state),
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  get_past_sessions: (status) => {
    dispatch(actions.get_past_sessions(status));
  },
  get_future_sessions: (status) => {
    dispatch(actions.get_future_sessions(status));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionsReport);
