import React from 'react';
import PropTypes from 'prop-types';

import './custom_input.css';

function CustomInput(props) {
  return (
    <div className="custom-input" style={props.style}>
      {props.show_label ? (
        <div className="custom-input-label" style={props.label_style}>
          {props.label}:
        </div>
      ) : null}
      <input
        value={props.value}
        type={props.type}
        name={props.name}
        // placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        style={props.input_style}
        disabled={props.disabled}
      />
    </div>
  );
}

CustomInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  // placeholder: PropTypes.string,
  style: PropTypes.object,
  label_style: PropTypes.object,
  input_style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  show_label: PropTypes.bool,
  disabled: PropTypes.bool
};

CustomInput.defaultProps = {
  type: 'text',
  label: 'Enter Label',
  name: 'custom_input',
  style: {
    width: '200px'
  },
  label_style: {},
  input_style: {},
  show_label: true,
  disabled: false
  // placeholder: 'Enter text'
};

export default CustomInput;
