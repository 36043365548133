import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { api_with_method } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import { short_time_day_format } from '../../../../constants';

function MembershipCreditHistory() {
  const [studentUUID, setStudentUUID] = useState('');
  const [studentName, setStudentName] = useState('');
  const [data, setData] = useState({});
  const [givenMembershipCreditsHistory, setgivenMembershipCreditsHistory] = useState([]);
  const [membershipCredits, setMembershipCredits] = useState('');
  const [usedMembershipCreditHistory, setUsedMembershipCreditHistory] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const urlsString = window.location.href;
    const url = new URL(urlsString);
    const uuid = url.searchParams.get('id');
    const name = url.searchParams.get('name');
    setStudentName(name);
    setStudentUUID(uuid);

    if (studentUUID !== '') {
      loadMembershipCreditHistory();
    }
  }, []);

  useEffect(() => {
    if (studentUUID !== '') {
      loadMembershipCreditHistory();
    }
  }, [studentUUID]);

  const { iana_timezone } = useSelector((state) => ({
    iana_timezone: state.home.iana_timezone
  }));

  const history = useHistory();

  const loadMembershipCreditHistory = async () => {
    const url = get_api_url(
      `/v2/payments/cms/membership_credits_history?student_uuid=${studentUUID}`,
      null,
      true
    );

    try {
      const response = await api_with_method('get', url, null, true);
      setgivenMembershipCreditsHistory(response?.data?.data?.given_membership_credits_history);
      setMembershipCredits(response?.data?.data?.membership_credits);
      setUsedMembershipCreditHistory(response?.data?.data?.used_membership_credits_history);
      setMembershipCredits(response?.data?.data?.membership_credits);
    } catch (error) {
      setShowError(true);
      setErrorMessage(error?.response?.data?.reason);
    }
  };

  function MembershipCreditsHistoryTable(props) {
    const { data, header, isFromHistoryTable } = props;
    return (
      <>
        <h2 className="text-center">{header}</h2>
        <table className="relative w-full border">
          <thead>
            <tr>
              {!isFromHistoryTable ? (
                <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Given By</th>
              ) : null}
              <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">
                {isFromHistoryTable ? 'Credits Used' : '# Credits'}
              </th>
              <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Reason</th>
              <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item?.created_date}>
                {!isFromHistoryTable ? <td>{item?.credits_given_by}</td> : null}
                <td>{item?.credits_used_by}</td>
                <td>{item?.reason}</td>
                <td>
                  {moment(item?.created_date).tz(iana_timezone).format(short_time_day_format)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  function ErrorResponseMessage() {
    return <h2 className="text-mytRed text-center">{errorMessage}</h2>;
  }

  return (
    <div className="common-page-container">
      <div className="common-page-header">
        <div className="profile-go-back" onClick={() => history.goBack()}>
          <img
            src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
            alt="back"
          />
          <span>Back</span>
        </div>
        <div className="common-page-title">Membership Credit History Of {studentName}</div>
      </div>

      {showError ? (
        <ErrorResponseMessage />
      ) : (
        <>
          <div className="flex">
            <div className="font-bold">Total Credit :</div>
            <div className="font-bold">{membershipCredits}</div>
          </div>
          <MembershipCreditsHistoryTable
            data={givenMembershipCreditsHistory}
            header="Membership Credits History"
            isFromHistoryTable={false}
          />
          <hr className="mt-4" />
          <div className="mt-8">
            <MembershipCreditsHistoryTable
              data={usedMembershipCreditHistory}
              header="Used Membership Credits History"
              isFromHistoryTable
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MembershipCreditHistory;
