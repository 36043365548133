import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { image_error_replace } from '../../../util_functions';
import { day_format, time_format } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class PastConsults extends Component {
  state = {
    loading: false,
    sessions: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_consults');
      post_api(url, null, true)
        .then((res) => {
          this.setState({ loading: false, sessions: [...res.data.sessions] });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  go_to_path = (path) => this.props.history.push(path);

  render_single_session = (session) => {
    const student_url = `/studentprofile?id=${session.student_uuid}`;
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.uuid}>
        <div className="ct-col ct-la">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={session.student_photo} onError={image_error_replace} alt="x" />
              {session.student_name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-la">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
              {session.teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col">
          <div>{moment(session.start_time).tz(this.props.iana_timezone).format(day_format)}</div>
          <div>{moment(session.start_time).tz(this.props.iana_timezone).format(time_format)}</div>
        </div>
        <div className="ct-col">{session.status}</div>
        <div className="ct-col">
          <button onClick={() => this.go_to_path(`/consults/details/${session.uuid}`)}>
            Details
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { loading, sessions } = this.state;
    return (
      <CommonHeader loading={loading} title="Future Consults">
        <button onClick={() => this.go_to_path('/consults/future')}>Future Consults</button>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-la">Student</div>
            <div className="ct-col ct-la">Teacher</div>
            <div className="ct-col">Start Time</div>
            <div className="ct-col">Status</div>
            <div className="ct-col">Details</div>
          </div>
          {sessions.map(this.render_single_session)}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PastConsults);
