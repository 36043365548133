import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import './ctb.css';
import 'react-datepicker/dist/react-datepicker.css';

export default class CohortTimeBased extends Component {
  state = {
    loading: true,
    start_date: moment().subtract(56, 'd').toDate(),
    whole_data: []
  };

  load_data = () => {
    const url = get_api_url('get_cohort_data');
    const payload = { start_date: moment(this.state.start_date).format('YYYY-MM-DD') };
    post_api(url, payload, true)
      .then((result) => {
        const data = [...result.data.whole_data];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const in_data = element.data;
          let optin = Array(index).fill(null);
          optin.push(element.email_optin);
          optin = optin.concat(new Array(data.length - index - 1).fill(null));
          const fin_data = {
            week: new Array(index).fill(null),
            email_optin: [...optin],
            app_signup: new Array(index).fill(null),
            scheduled_first_session: new Array(index).fill(null),
            scheduled_session: new Array(index).fill(null),
            completed_first_session: new Array(index).fill(null),
            completed_session: new Array(index).fill(null),
            total_scheduled: new Array(index).fill(null),
            total_completed: new Array(index).fill(null)
          };
          for (let j = 0; j < in_data.length; j++) {
            const el = in_data[j];
            Object.keys(el).map((k) => fin_data[k].push(el[k]));
          }
          element.render_data = { ...fin_data };
        }
        this.setState({
          starting_week: result.data.starting_week,
          whole_data: data,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false, page_error: true }));
  };

  componentDidMount() {
    this.load_data();
  }

  handleDate = (date) => {
    this.setState({ start_date: date, loading: true }, () => this.load_data());
  };

  render_cohort_data = () => {
    if (this.state.whole_data.length !== 0) {
      return (
        <div>
          <table>
            <thead>
              <tr>
                <th>Week</th>
                <th />
                <th>Metric</th>
                {this.state.whole_data.map((data, index) => (
                  <th key={data.week}>
                    <div className="ctb-week-text2">
                      <div className="ctb-week-text">week {index + 1}</div>
                      {data.week}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {this.state.whole_data.map((data, index) => {
              const rowspan = Object.keys(data.data[0]).length;
              return (
                <tbody key={data.week}>
                  <tr>
                    <td rowSpan={rowspan} className="ctb-fir-col">
                      Week {index + 1}
                    </td>
                    <td rowSpan={rowspan - 2}>Unique users</td>
                    <td className="ctb-fir-col">Email-Optin</td>
                    {data.render_data.email_optin.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">App Signup</td>
                    {data.render_data.app_signup.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">Scheduled 1st session</td>
                    {data.render_data.scheduled_first_session.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">Scheduled any session</td>
                    {data.render_data.scheduled_session.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">Completed 1st session</td>
                    {data.render_data.completed_first_session.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">Completed any session</td>
                    {data.render_data.completed_session.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td rowSpan={2}>Total sessions</td>
                    <td className="ctb-fir-col">Scheduled</td>
                    {data.render_data.total_scheduled.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ctb-fir-col">Completed</td>
                    {data.render_data.total_completed.map((ite, inde) => (
                      <td key={ite + inde}>{ite}</td>
                    ))}
                  </tr>
                  <tr className="ctb-no-bor">
                    <td />
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Time Based Cohort</div>
        </div>
        <div className="teacher-availability-block ctb-date">
          <div style={{ marginRight: '20px' }}>Select Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => this.handleDate(date)}
            maxDate={new Date()}
          />
        </div>
        {this.render_cohort_data()}
      </div>
    );
  }
}
