import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { get_api_url } from '../../../utils/urls';
import { get_api, post_api, put_api, api_with_method } from '../../../redux/api_funcs';
import CustomModal from '../../util_components/custom_modal';
import CommonHeader from '../../util_components/common_header';
import CreateEditContent from './create_edit_content';
import CreateEditFaq from './create_edit_faq';
import CreateEditAssociation from './create_edit_association';
import Button from '../../util_components/button';
import * as actions from '../../../redux/action_creators';

class ContentTagsBySlug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug_name: null,
      tag_content: null,
      blog_content: null,
      faq_details: null,
      association_details: null,
      loading: false,
      blog_loaded: false,
      edit_tag: false,
      edit_faq: false,
      current_faq: null,
      edit_association: false,
      teacher_toggle: false,
      workshop_toggle: false,
      group_class_toggle: false,
      blog_toggle: false,
      remove_entity_open: false,
      entity_to_be_removed: null,
      remove_faq_open: false,
      faq_to_be_removed: null,
      blog_entry: null
    };
  }

  componentDidMount() {
    const urlParams = this.props.match.params;
    const slug_name = urlParams?.slug_name
      ? urlParams.slug_name.charAt(0).toLowerCase() + urlParams.slug_name.slice(1)
      : '';
    this.setState(
      {
        slug_name
      },
      () => {
        this.fetch_tag_content_by_slug();
      }
    );
  }

  fetch_tag_content_by_slug = () => {
    const { slug_name } = this.state;
    try {
      const path = `/v2/entity_tags/tags/content/${slug_name}`;
      const url = get_api_url(path, false, true);
      get_api(url, true)
        .then((res) => {
          this.setState({
            tag_content: res.data.data.tag_details,
            faq_details: res.data.data.faq_details,
            association_details: res.data.data.association_details,
            loading: true
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
      const blog_url = get_api_url('get_blog_posts', false, false);
      post_api(blog_url, null, true)
        .then((res) => {
          this.setState({
            blog_content: res.data,
            blog_loaded: true
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  edit_tag = (payload) =>
    this.setState({ loading: true }, () => {
      const path = `/v2/entity_tags/tags`;
      const url = get_api_url(path, false, true);
      put_api(url, payload, true)
        .then((res) => {
          this.close_edit_tag();
          this.fetch_tag_content_by_slug();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
        });
    });

  reorder_faq = () =>
    this.setState({ loading: true }, () => {
      const { faq_details } = this.state;
      const path = `/v2/entity_tags/tags/faq`;
      const url = get_api_url(path, false, true);
      const increment = 1;
      faq_details.forEach((faq, index) => {
        const payload = {
          uuid: faq.UUID,
          question: faq.Question,
          answer: faq.Answer,
          position: index + increment
        };
        put_api(url, payload, true)
          .then((res) => {
            this.close_edit_faq();
            this.fetch_tag_content_by_slug();
          })
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
          });
      });
    });

  edit_faq = (payload) =>
    this.setState({ loading: true }, () => {
      const { tag_content } = this.state;
      const path = `/v2/entity_tags/tags/faq`;
      const url = get_api_url(path, false, true);
      put_api(url, payload, true)
        .then((res) => {
          this.close_edit_faq();
          this.fetch_tag_content_by_slug();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
        });
    });

  add_faq = (faq) =>
    this.setState({ loading: true }, () => {
      const { tag_content } = this.state;
      const path = `/v2/entity_tags/tags/faq`;
      const url = get_api_url(path, false, true);
      const payload = {
        tag_id: tag_content.ID,
        question: faq.Question,
        answer: faq.Answer
      };
      post_api(url, payload, true)
        .then((res) => {
          this.close_edit_faq();
          this.fetch_tag_content_by_slug();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
        });
    });

  delete_faq = () =>
    this.setState({ loading: true }, () => {
      const current_faq = this.state.faq_to_be_removed;
      const payload = {
        uuid: current_faq.UUID
      };
      const path = `/v2/entity_tags/tags/faq`;
      const url = get_api_url(path, false, true);
      api_with_method('delete', url, payload, true)
        .then((res) => {
          this.close_edit_faq();
          this.fetch_tag_content_by_slug();
          this.toggle_remove_faq();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
        });
    });

  add_association = (payload) => {
    const { tag_content } = this.state;
    const entity_name = ['TEACHER', 'WORKSHOP', 'GROUP_CLASS_PAGE', 'BLOG'];
    const path = `/v2/entity_tags/associations/blogs`;
    const url = get_api_url(path, false, true);
    entity_name.forEach((entity) => {
      const entities = payload[`${entity}`];
      if (Array.isArray(entities) && entities.length) {
        entities.forEach((item) => {
          const payload = {
            updates: [
              {
                is_tagged: 1,
                tag_category_uuid: tag_content.TagCategoryUUID,
                tag_uuid: tag_content.UUID
              }
            ],
            association_type_id: item.value,
            association_type: entity
          };
          post_api(url, payload, true)
            .then((res) => {
              this.close_edit_association();
              this.fetch_tag_content_by_slug();
            })
            .catch((e) => {
              let err_message;
              try {
                err_message = e.response.data.message;
              } catch (err) {
                err_message = 'Some error occured. Please contact dev team.';
              }
            });
        });
      }
    });
  };

  remove_association = () => {
    const entity = this.state.entity_to_be_removed;
    const { tag_content } = this.state;
    const { entity_details } = entity;
    const path = `/v2/entity_tags/associations/blogs`;
    const url = get_api_url(path, false, true);
    const payload = {
      updates: [
        {
          is_tagged: 0,
          tag_category_uuid: tag_content.TagCategoryUUID,
          tag_uuid: tag_content.UUID
        }
      ],
      association_type_id: entity_details.association_type_id,
      association_type: entity_details.association_type
    };
    post_api(url, payload, true)
      .then((res) => {
        this.fetch_tag_content_by_slug();
        this.toggle_remove_entity();
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
      });
  };

  open_edit_tag = () => this.setState({ edit_tag: true });

  close_edit_tag = () => this.setState({ edit_tag: false });

  open_edit_faq = (faq) => this.setState({ edit_faq: true, current_faq: faq });

  close_edit_faq = () => this.setState({ edit_faq: false });

  open_edit_association = () => this.setState({ edit_association: true });

  close_edit_association = () => this.setState({ edit_association: false });

  render_tag_content = () => {
    const details = this.state.tag_content;
    const { slug_name } = this.state;
    const preview_url = `https://myyogateacher.com/yoga-online-classes/${slug_name}`;

    return (
      <>
        <table className="tag-content">
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Name: </td>
              <td>{details.Name}</td>
              <td className="teacher-table-detail">Tag uuid:</td>
              <td>{details.UUID}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Slug: </td>
              <td>{details.Slug}</td>
              <td className="teacher-table-detail">Label:</td>
              <td>{details.Label}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Tagline: </td>
              <td colSpan="3">{details.Tagline}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Meta Description: </td>
              <td colSpan="3">{details.MetaDescription}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Description:</td>
              <td colSpan="3" dangerouslySetInnerHTML={{ __html: details.Description }} />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">WhosFor:</td>
              <td colSpan="3" dangerouslySetInnerHTML={{ __html: details.WhosFor }} />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">WhosNotFor:</td>
              <td colSpan="3" dangerouslySetInnerHTML={{ __html: details.WhosNotFor }} />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">HowWillHelp:</td>
              <td colSpan="3" dangerouslySetInnerHTML={{ __html: details.HowWillHelp }} />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">HowWillNotHelp:</td>
              <td colSpan="3" dangerouslySetInnerHTML={{ __html: details.HowWillNotHelp }} />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Thumbnail:</td>
              <td colSpan="3">{details.Tagline}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Icon:</td>
              <td colSpan="3">{details.Icon}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Short Name:</td>
              <td colSpan="3">{details.ShortName}</td>
            </tr>
          </tbody>
        </table>
        <button onClick={this.open_edit_tag}>Edit</button>
        <a href={preview_url} target="_blank" rel="noreferrer">
          <button>Preview</button>
        </a>
      </>
    );
  };

  onFaqReorder = (result) => {
    if (!result.destination) return;
    const { faq_details } = this.state;
    const items = Array.from(faq_details);

    const [reorderedFaq] = items.splice(result.source.index, 1);
    const reorderedPosition = reorderedFaq.Position;
    items.splice(result.destination.index, 0, reorderedFaq);
    this.setState({
      faq_details: items
    });
  };

  render_faq_content = () => {
    const { faq_details } = this.state;
    const char_limit = 25;
    return (
      <div style={{ marginTop: '30px' }}>
        <div className="display-flex-between">Tag FAQ:</div>
        <div className="display-flex-between">
          <div>S.No</div>
          <div>Question</div>
          <div>Answer</div>
          <div />
          <div />
        </div>
        <div>
          <DragDropContext onDragEnd={this.onFaqReorder}>
            <Droppable droppableId="position">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {faq_details.map((faq, index) => {
                    const question_length = faq.Question.length;
                    const answer_length = faq.Answer.length;
                    let extra_char = '';
                    if (question_length > char_limit) {
                      extra_char = '...';
                    }
                    if (answer_length > char_limit) {
                      extra_char = '...';
                    }
                    return (
                      <Draggable key={faq.UUID} draggableId={faq.UUID} index={index}>
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div className="display-flex-between">
                              <div>{faq.Position}</div>
                              <div>{faq.Question.slice(0, 25) + extra_char}</div>
                              <div>{faq.Answer.slice(0, 25) + extra_char}</div>
                              <div>
                                <button onClick={() => this.open_edit_faq(faq)}>Edit</button>
                              </div>
                              <div>
                                <button onClick={() => this.toggle_remove_faq(faq)}>Remove</button>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        {/* <div className="cus-table" style={{ marginBottom: "20px" }}>
                    <div className="ct-row ct-h">
                        <div className="ct-col ct-hf">Question</div>
                        <div className="ct-col ct-hf">Answer</div>
                        <div className="ct-col ct-hf"></div>
                    </div>
                    </div> */}
      </div>
    );
  };

  render_single_faq_details = (faq) => (
    <div className="ct-row" key={faq.uuid}>
      <div className="ct-col ct-hf">{faq.Question}</div>
      <div className="ct-col ct-hf">{faq.Answer}</div>
      <button onClick={() => this.open_edit_faq(faq)}>Edit</button>
      <button onClick={() => this.toggle_remove_faq(faq)}>Remove</button>
    </div>
  );

  toggle_remove_entity = (entity, blog_entry) =>
    this.setState({
      remove_entity_open: !this.state.remove_entity_open,
      entity_to_be_removed: entity,
      blog_entry
    });

  toggle_remove_faq = (faq) =>
    this.setState({ remove_faq_open: !this.state.remove_faq_open, faq_to_be_removed: faq });

  render_association_content = (label_name) => {
    const { association_details } = this.state;
    const entity_details = association_details[`${label_name}`];
    const blog_object = this.state.blog_content;
    const blogs_list = blog_object ? Object.values(blog_object) : null;
    const associated_blogs = [];
    const entity_name = {
      teachers: 'TEACHER',
      workshop: 'WORKSHOP',
      group_class_page: 'GROUP_CLASS_PAGE',
      blogs: 'BLOG'
    };

    if (
      label_name == 'BLOG' &&
      Array.isArray(entity_details) &&
      entity_details.length &&
      !!blog_object
    ) {
      const blog_uuid = [];
      entity_details.forEach((key) => {
        const uuid = key.entity_details.association_type_id;
        blog_uuid.push(uuid);
      });
      blogs_list.forEach((record) => {
        if (blog_uuid.includes(record.id)) {
          associated_blogs.push(record);
        }
      });
    }
    return (
      <div>
        <div className="details-session-logs-header" onClick={() => this.toggle_entity(label_name)}>
          {label_name}
          <img
            className={this.state[`${label_name}_toggle`] ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>
        {this.state[`${label_name}_toggle`] && !!entity_details ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col align:left ">Name</div>
              <div className="ct-col" />
            </div>
            {entity_details.map((record) => {
              let redirect_url;
              let blog_entry = null;
              const record_to_be_removed = record;
              if (label_name == entity_name.teachers) {
                redirect_url = `/teacherprofile?id=${record.teacher_details.UUID}`;
              } else if (label_name == entity_name.workshop) {
                redirect_url = `/workshop/${record.entity_details.association_type_id}`;
              } else if (label_name == entity_name.group_class_page) {
                redirect_url = `/group_class_page/${record.group_class_page_details.Slug}`;
              } else if (label_name == entity_name.blogs) {
                blog_entry = associated_blogs.find(
                  (blog) => blog.id === record.entity_details.association_type_id
                );
                if (blog_entry) {
                  redirect_url = `/tag_associations/blogs/${blog_entry.id}?title=${blog_entry.title}&slug=${blog_entry.slug}`;
                } else {
                  return;
                }
              }
              return (
                <div className="ct-row">
                  <div className="ct-col align:left">
                    <a
                      className="cursor-pointer"
                      href={redirect_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label_name == entity_name.blogs
                        ? blog_entry.title
                        : record.entity_details.name}
                    </a>
                  </div>
                  <div className="ct-col">
                    <button
                      onClick={() => this.toggle_remove_entity(record_to_be_removed, blog_entry)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  toggle_entity = (entity_label) =>
    this.setState({ [`${entity_label}_toggle`]: !this.state[`${entity_label}_toggle`] });

  // render_single_association_details = (entity) => {

  //     let id = 0
  //     let entity_details = entity["entity_details"]
  //     let tag_details = entity["tag_details"]
  //     let entity_label = entity_details.association_type

  //     return(
  //         <div>
  //             <div className="details-session-logs-header" onClick={() => this.toggle_entity(entity_label)}>
  // 				{entity_label}
  // 				<img
  // 					className={this.state[`${entity_label}_toggle`] ? 'reverse-expand' : 'normal-expand'}
  // 					src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
  // 					alt="exp"
  // 				/>
  // 			</div>
  //             <div className="cus-table">
  // 					<div className="ct-row ct-h">
  // 						<div className="ct-col ct-qf">ID</div>
  // 						<div className="ct-col">Name</div>
  // 						<div className="ct-col">Details</div>
  // 					</div>
  // 					<div className="ct-row" key={id}>
  // 								<div className="ct-col ct-sm-font ct-qf">{id+1}</div>
  // 								<div className="ct-col ct-sm-font">
  // 									<div>{entity_details.name}</div>
  // 								</div>
  // 								<div className="ct-col ct-sm-font">
  // 									<div>
  //                                     <button onClick={() => this.remove_association()}>
  //                                         Remove
  //                                     </button>
  //                                     </div>
  // 								</div>
  // 							</div>
  // 				</div>
  //         </div>
  //     )
  // }

  render_subset_of_associations = (association_subset) => (
    <div>
      <div className="ct-row">
        <div className="ct-col ct-hf">{association_subset.AssociationType}</div>
        <div className="ct-col ct-hf">{association_subset.AssociationTypeId}</div>
        <div className="ct-col ct-hf">
          <button onClick={() => this.remove_association(association_subset)}>Remove</button>
        </div>
      </div>
    </div>
  );

  render_remove_entity = () => {
    const { entity_to_be_removed } = this.state;
    const { blog_entry } = this.state;

    return (
      <div>
        <h4>
          Are you sure you want to remove entity:
          <strong>
            "
            {!!blog_entry && !!blog_entry.title
              ? blog_entry.title
              : !!entity_to_be_removed && !!entity_to_be_removed.entity_details
                ? entity_to_be_removed.entity_details.name
                : null}
            "
          </strong>
        </h4>
        <div className="display-flex-between">
          <Button onClick={this.remove_association}>Yes</Button>
          <Button onClick={this.toggle_remove_entity}>No</Button>
        </div>
      </div>
    );
  };

  render_remove_faq = () => {
    const { faq_to_be_removed } = this.state;
    return (
      <div>
        <h4>
          Are you sure you want to remove entity:
          <strong>"{faq_to_be_removed ? faq_to_be_removed.Question : null}"</strong>
        </h4>
        <div className="display-flex-between">
          <Button onClick={this.delete_faq}>Yes</Button>
          <Button onClick={this.toggle_remove_faq}>No</Button>
        </div>
      </div>
    );
  };

  render() {
    const {
      tag_content,
      current_faq,
      association_details,
      blog_content,
      remove_entity_open,
      remove_faq_open,
      loading
    } = this.state;
    const entity_name = {
      teachers: 'TEACHER',
      workshop: 'WORKSHOP',
      group_class_page: 'GROUP_CLASS_PAGE',
      blogs: 'BLOG'
    };
    return (
      <CommonHeader show_back loading={false} title="Tag Details">
        <CustomModal show={remove_entity_open} close={this.toggle_remove_entity}>
          {this.render_remove_entity()}
        </CustomModal>
        <CustomModal show={remove_faq_open} close={this.toggle_remove_faq}>
          {this.render_remove_faq()}
        </CustomModal>
        <CustomModal show={this.state.edit_tag} close={this.close_edit_tag}>
          <CreateEditContent tag_details={tag_content} submit_tag={this.edit_tag} />
        </CustomModal>
        <CustomModal show={this.state.edit_faq} close={this.close_edit_faq}>
          <CreateEditFaq
            faq_details={current_faq}
            submit_faq={this.edit_faq}
            add_faq={this.add_faq}
          />
        </CustomModal>
        {this.state.blog_loaded ? (
          <CustomModal show={this.state.edit_association} close={this.close_edit_association}>
            <CreateEditAssociation
              blog_data={blog_content}
              association_details={association_details}
              submit_association={this.add_association}
              close_editor={this.close_edit_association}
            />
          </CustomModal>
        ) : null}
        <div>{this.state.loading ? this.render_tag_content() : null}</div>
        <br />
        <div>
          {this.state.loading ? this.render_faq_content() : null}
          <div>
            <button onClick={this.reorder_faq}>Save Order</button>
            <button onClick={this.open_edit_faq}>Add FAQ</button>
          </div>
        </div>
        <br />
        <br />
        <div>
          <button onClick={() => this.open_edit_association()}>Add Any</button>
        </div>
        <div>
          {this.state.loading ? this.render_association_content(entity_name.teachers) : null}
        </div>
        <div>
          {this.state.loading ? this.render_association_content(entity_name.workshop) : null}
        </div>
        <div>
          {this.state.loading
            ? this.render_association_content(entity_name.group_class_page)
            : null}
        </div>
        <div>{this.state.loading ? this.render_association_content(entity_name.blogs) : null}</div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentTagsBySlug);
