import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import { time_day_format } from '../../../constants';
import { capitalizeFirstLetter } from '../../../utils/formatString';

class TimelineData extends Component {
  is_json_string(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render_second_td = (format) => {
    const value = this.props.data[format?.key];
    const is_json_str = !!value && typeof value === 'object';
    if (format?.type === 'time_day_format') {
      return (
        <div className="text-sm break-all w-4/6 p-2 border-b-2 border-gray-100">
          {value ? moment(value).tz(this.props.iana_timezone).format(time_day_format) : '--'}
        </div>
      );
    }
    if (format?.type === 'html_data') {
      return <div className="">{HTMLReactParser(value)}</div>;
    }
    if (format?.type === 'metadata') {
      return (
        <div className="text-sm break-all w-4/6 p-2 border-b-2 border-gray-100">
          <button
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            onClick={() => this.props.onViewClick(value)}
          >
            View
          </button>
        </div>
      );
    }
    if (is_json_str === true) {
      return this.iterate_obj(value);
    }
    return (
      <div className="text-sm break-all w-4/6 p-2 border-b-2 border-gray-100">{value || '--'}</div>
    );
  };

  iterate_obj = (parsed_value) => {
    if (Object.keys(parsed_value).length !== 0 && !parsed_value) {
      return (
        <div className="text-sm break-all w-full p-2 border-b-2 border-gray-100">
          {Object.keys(parsed_value).map((item, index) => (
            <>
              {typeof parsed_value[item] === 'string' || typeof parsed_value[item] === 'number' ? (
                <div className="w-full flex" key={index}>
                  <div
                    className={`text-sm w-4/6 p-2 ${
                      Object.keys(parsed_value).length !== index + 1
                        ? 'border-b-2 border-gray-100'
                        : null
                    }`}
                  >
                    {capitalizeFirstLetter(item)} :
                  </div>
                  <div
                    className={`text-sm break-all text-center w-full p-2 ${
                      Object.keys(parsed_value).length !== index + 1
                        ? 'border-b-2 border-gray-100'
                        : null
                    }`}
                  >
                    {parsed_value?.[item] ? parsed_value[item] : '--'}
                  </div>
                </div>
              ) : (
                <>{this.iterate_obj(parsed_value[item])}</>
              )}
            </>
          ))}
        </div>
      );
    }
    return <div className="text-sm break-all w-4/6 p-2 border-b-2 border-gray-100">--</div>;
  };

  render_single_row = (format, index) => (
    <div className="w-full flex" key={index + format?.title}>
      <div className="text-sm w-2/6 p-2 border-b-2 border-gray-100">{format?.title}</div>
      {this.render_second_td(format)}
    </div>
  );

  render() {
    return (
      <>
        <p className="p-1 text-xl text-center font-bold">
          {this.props.open_view_modal ? 'Student mail data' : 'Student event data'}
        </p>
        <div className="mt-1 text-sm w-full flex flex-wrap border-t-2 border-gray-100 border-l-2 border-r-2">
          {this.props.data_format.map(this.render_single_row)}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(mapStateToProps)(TimelineData);
