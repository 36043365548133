import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../../util_components/common_header';
import { get_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import { short_time_day_format } from '../../../../constants';
import { CheckMarkIcon, CrossIcon } from '../../../../utils/svg';

function TeacherNotificationPreferencesHistory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { iana_timezone = 'America/Los_Angeles' } = useSelector((state) => ({
    iana_timezone: state?.home?.iana_timezone
  }));

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');
    if (id) {
      getNotificationPreferencesHistory(id);
    }
  }, []);

  const getNotificationPreferencesHistory = async (id) => {
    setLoading(true);
    const path = `/v2/cms_user/teachers/${id}/preference_history`;
    const url = get_api_url(path, false, true);

    try {
      const response = await get_api(url, true);
      setData(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data: ', error);
    }
  };

  const formatTimeZone = (time) => moment(time)?.tz(iana_timezone)?.format(short_time_day_format);

  return (
    <CommonHeader title="Notification Preferences" show_back loading={loading}>
      <div className="cus-table" style={{ marginBottom: '20px' }}>
        <div className="ct-row ct-h">
          <div className="ct-col ct-sm-font">
            <span className="ct-ml25">Type</span>
          </div>
          <div className="ct-col ct-sm-font ct-hf">Choice</div>
          <div className="ct-col ct-sm-font">Updated on</div>
          <div className="ct-col ct-sm-font">Updated by</div>
          <div className="ct-col ct-sm-font ct-hf">Role</div>
        </div>
        {data.map((item, index) => (
          <div className="ct-row" key={index}>
            <div className="flex flex-col flex-1">
              {item?.notification_types?.map((type, index) => (
                <p className="flex items-center justify-center">{Object.keys(type)}</p>
              ))}
            </div>
            <div className="ct-col ct-sm-font ct-hf">
              <div className="flex flex-col items-center">
                {item?.notification_types?.map((type) =>
                  Object.values(type)[0] ? (
                    <CheckMarkIcon style={{ width: '20px', height: '20px', margin: '14px 0px' }} />
                  ) : (
                    <CrossIcon style={{ width: '20px', height: '20px', margin: '14px 0px' }} />
                  )
                )}
              </div>
            </div>
            <div className="ct-col ct-sm-font">
              <div>{formatTimeZone(item?.created_date)}</div>
            </div>
            <div className="ct-col ct-sm-font">{item?.cms_user_name}</div>
            <div className="ct-col ct-sm-font ct-hf">{item?.cms_user_role}</div>
          </div>
        ))}
      </div>
    </CommonHeader>
  );
}

export default TeacherNotificationPreferencesHistory;
