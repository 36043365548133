import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomLoading from '../../../util_components/custom_loading';
import * as actions from '../../../../redux/action_creators';
import CustomCheckBox from '../../../util_components/custom_checkbox';
import HighlightSearch from '../../../util_components/highlight_search_text';
import Button from '../../../util_components/button';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';

import { image_error_replace } from '../../../../util_functions';

import './teachers_list.css';

class TeachersList extends Component {
  state = {
    teacherslistArr: [],
    allTeachersList: [],
    show_teachers_details: false,
    show_teacher_profile_uuid: '',
    invite_email: '',
    invite_name: '',
    invite_loading: false,
    optin_conc: 0
  };

  componentDidMount() {
    if (this.props.teachers_list.length === 0) {
      this.props.get_teachers_list();
    }
  }

  toggle_coneirge = () => this.setState({ optin_conc: this.state.optin_conc === 1 ? 0 : 1 });

  on_key_press = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.invite_teacher();
    }
  };

  invite_teacher = () => {
    this.setState({ invite_loading: true });
    const url = get_api_url('invite_new_teacher');
    const data = {
      email: this.state.invite_email.trim(),
      name: this.state.invite_name.trim()
    };
    post_api(url, data, true)
      .then(() =>
        this.setState({ invite_email: '', invite_loading: false, invite_name: '' }, () => {
          this.props.set_notification_variable(true, 'success', `Invitation Sent to ${data.email}`);
        })
      )
      .catch((error) => {
        this.setState({ invite_loading: false }, () => {
          let err_msg = 'Some error occured';
          try {
            err_msg = error.response.data.message;
          } catch {}
          this.props.set_notification_variable(true, 'error', err_msg);
        });
      });
  };

  handle_teacher_invite = (e) => this.setState({ [e.target.name]: e.target.value });

  handle_update = (e) => this.props.filter_teachers(e.target.name, e.target.value);

  close_teacher_profile = () => {
    this.setState({ show_teachers_details: false });
  };

  open_teacher_profile = (e) => {
    const url = '/teacherprofile';
    const search = `?id=${e.currentTarget.dataset.id}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  open_area_map = () => this.props.history.push('/teacherareamap');

  render_status_dropdown = () => (
    <select
      name="onboarding_status"
      onChange={this.handle_update}
      value={this.props.onboarding_status}
    >
      <option value="ALL">ALL</option>
      <option value="APPROVED">APPROVED</option>
      <option value="UNDER_EVALUATION">UNDER EVALUATION</option>
      <option value="UNDER_EVALUATION_ON_HOLD">UNDER EVALUATION ON HOLD</option>
      <option value="INTERESTED_TO_JOIN">INTRESTED TO JOIN</option>
      <option value="REJECTED">REJECTED</option>
      <option value="TEMPORARILY_UNAVAILABLE">TEMPORARILY UNAVAILABLE</option>
      <option value="UNDER_SCRUTINY">UNDER SCRUTINY</option>
      <option value="VIDEO_CONSULT">VIDEO CONSULT</option>
      <option value="UNDER_PROBATION">UNDER PROBATION</option>
      <option value="RESIGNED">RESIGNED</option>
      <option value="SUSPENDED">SUSPENDED</option>
    </select>
  );

  getTeacherData() {
    const teacherDataArr = [];

    this.props.filtered_teachers.forEach((item) => {
      const csvData = {
        first_name: item?.first_name,
        last_name: item?.last_name,
        location: item?.location,
        city: item?.city,
        zipcode: item?.zipcode,
        state: item?.state,
        phone_personal: item?.phone_personal,
        goals: item?.goals?.toString(),
        yoga_types_teaching: JSON.stringify(item.yoga_types_teaching),
        email: item?.email,
        yoga_experience: item?.years_of_yoga_teaching_experience
      };
      teacherDataArr.push('csvData', csvData);
    });
    return teacherDataArr;
  }

  render_function = () => {
    const { invite_name, invite_email, optin_conc } = this.state;
    const file_name = `Teachers_${moment().format('YYYY-MM-DD')}`;
    const final_teachers = this.props.filtered_teachers.filter((t) =>
      optin_conc === 1 ? t.opt_in_concierge === optin_conc : 1
    );
    if (this.props.teachers_list_status === 'success') {
      return (
        <div className="common-page-container">
          <h2 style={{ textAlign: 'center' }}>Invite Teacher: </h2>
          <div className="teachers-invite">
            <div>
              <input
                placeholder="Name"
                name="invite_name"
                value={invite_name}
                onChange={this.handle_teacher_invite}
                onKeyPress={this.on_key_press}
              />
              <input
                placeholder="Email"
                name="invite_email"
                value={invite_email}
                onChange={this.handle_teacher_invite}
                onKeyPress={this.on_key_press}
              />
              <Button
                disabled={this.state.invite_loading || !invite_name || !invite_email}
                onClick={this.invite_teacher}
              >
                Invite
              </Button>
            </div>
          </div>
          {this.props.filtered_teachers.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="teachers-count">
                Teachers Count: {this.props.filtered_teachers.length}
              </div>
              <CustomCheckBox
                label="Conceirge"
                checked={optin_conc === 1}
                onClick={this.toggle_coneirge}
              />
              <div style={{ textAlign: 'right' }}>
                <ExcelFile
                  element={<button className="data-download-btn">Download Data</button>}
                  filename={file_name}
                >
                  <ExcelSheet data={this.getTeacherData()} name="Teachers">
                    <ExcelColumn label="First Name" value="first_name" />
                    <ExcelColumn label="Last Name" value="last_name" />
                    <ExcelColumn label="Location / Street Address" value="location" />
                    <ExcelColumn label="City" value="city" />
                    <ExcelColumn label="Zip Code" value="zipcode" />
                    <ExcelColumn label="State" value="state" />
                    <ExcelColumn label="Phone" value="phone_personal" />
                    <ExcelColumn label="Goals" value="goals" />
                    <ExcelColumn label="Yoga Types" value="yoga_types_teaching" />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Yoga Experience" value="yoga_experience" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </div>
          ) : null}

          <table style={{ width: '100%' }}>
            <tbody>
              <tr className="teachers-details-row">
                <td>
                  <img
                    src="https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png"
                    alt="pic"
                  />
                </td>
                <td>
                  <input
                    value={this.props.search_text}
                    name="search_text"
                    onChange={this.handle_update}
                    placeholder="Search teacher name"
                  />
                </td>
                <td>UPDATES</td>
                <td>{this.render_status_dropdown()}</td>
                <td>
                  <input
                    style={{ width: '300px' }}
                    value={this.props.goal_search_text}
                    name="goal_search_text"
                    onChange={this.handle_update}
                    placeholder="Search by goal"
                  />
                </td>
              </tr>
              {final_teachers.map((teacher) => {
                const teacher_name = `${teacher.first_name} ${teacher.middle_name} ${teacher.last_name}`;
                return (
                  <tr
                    key={teacher.uuid}
                    style={{ cursor: 'pointer' }}
                    onClick={this.open_teacher_profile}
                    data-id={teacher.uuid}
                    className="tr-cursor-row"
                  >
                    <td>
                      <img src={teacher.profile_photo} alt="pic" onError={image_error_replace} />
                    </td>
                    <td className="list-name-email">
                      <div>
                        <HighlightSearch
                          full_text={teacher_name}
                          search_text={this.props.search_text}
                        />
                      </div>
                      <div className="list-email">
                        <HighlightSearch
                          full_text={teacher.email}
                          search_text={this.props.search_text}
                        />
                      </div>
                    </td>
                    <td>
                      {teacher.new_profile_updates === 0 ? (
                        '-'
                      ) : (
                        <span style={{ color: 'red', fontSize: '12px' }}>YES</span>
                      )}
                    </td>
                    <td className="low-text">{teacher.onboarding_status}</td>
                    <td className="low-text">
                      <ul style={{ maxWidth: '250px' }}>
                        {teacher.goals
                          ? teacher.goals.map((goal, index) => (
                              <li key={index + goal}>
                                <HighlightSearch
                                  full_text={goal}
                                  search_text={this.props.goal_search_text}
                                />
                              </li>
                            ))
                          : null}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return <CustomLoading />;
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Teachers</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  filtered_teachers: state.teachers.filtered_teachers,
  search_text: state.teachers.search_text,
  goal_search_text: state.teachers.goal_search_text,
  onboarding_status: state.teachers.onboarding_status
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  filter_teachers: (key, payload) => {
    dispatch(actions.filter_teachers(key, payload));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TeachersList);
