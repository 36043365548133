import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import { get_api_url } from '../../../../utils/urls';
import Button from '../../../util_components/button';
import { post_api } from '../../../../redux/api_funcs';

class StudentLogs extends Component {
  state = {
    start_date: moment().subtract(1, 'days').toDate(),
    loading: true,
    url_error: false,
    email_data: [],
    show_email: false,
    email_body: ''
  };

  iframeRef = React.createRef();

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    if (uuid) {
      this.setState({ student_uuid: uuid }, () => this.load_data());
    } else {
      this.setState({ loading: false, url_error: true });
    }
  }

  load_data = () => {
    // TODO: blocked to fix ses-notification
    // this.setState({loading: true}, () => {
    //     const { start_date, student_uuid } = this.state;
    //     // const date_str = moment(start_date).format('YYYY-MM-DD');
    //     // const start_time = date_str + ' 00:00:00';
    //     // const end_time = date_str + ' 23:59:59';
    //     // const cus_start_date = moment.tz(start_time, this.props.iana_timezone).utc().format('YYYY-MM-DD HH:mm:ss')
    //     // const cus_end_date = moment.tz(end_time, this.props.iana_timezone).utc().format('YYYY-MM-DD HH:mm:ss')
    //     const payload = {
    //         student_uuid,
    //         // start_time: cus_start_date,
    //         // end_time: cus_end_date,
    //     }
    //     const url = get_api_url('get_email_logs_student', true);
    //     post_api(url, payload, true)
    //         .then(res => {
    //             this.setState({ email_data: [...res.data.email_data], loading: false})
    //         })
    //         .catch(e => console.log(e))
    // })
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  render_filters = () => {
    const btn_style = { marginTop: '25px' };
    const { start_date, student_uuid } = this.state;
    return (
      <div>
        <hr />
        <h4>Date range:</h4>
        <div className="teacher-availability-filters">
          <div className="teacher-availability-block">
            <div>Start Date:</div>
            <DatePicker
              selected={start_date}
              dateFormat="yyyy-MM-dd"
              onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
            />
          </div>
          <div className="teacher-availability-block">
            <Button custom_style={btn_style} disabled={!student_uuid} onClick={this.load_data}>
              Submit
            </Button>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  render_data = () => {
    const { email_data } = this.state;
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col">Type</div>
          <div className="ct-col">Delivered</div>
          <div className="ct-col">Opened</div>
          <div className="ct-col">Primary</div>
          <div className="ct-col">Updated</div>
          <div className="ct-col">Error</div>
          {/* <div className="ct-col">View</div> */}
        </div>
        {email_data.map((email) => (
          <div className="ct-row" key={email.uuid}>
            <div className="ct-col ct-sm-font ct-bw">{email.email_type}</div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.is_delivered === 1
                ? moment(email.delivery_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.is_opened === 1
                ? moment(email.last_opened_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.primary_action_count > 0
                ? moment(email.primary_action_last_opened_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font">
              {moment(email.modified_date)
                .tz(this.props.iana_timezone)
                .format('YYYY-MM-DD hh:mm A z')}
            </div>
            <div className="ct-col">{email.is_error === 1 ? 'True' : '--'}</div>
            {/* {!!email.email_body ? <button onClick={() => this.open_show_email(email.email_body)}>View</button> : '--'} */}
          </div>
        ))}
      </div>
    );
  };

  render_show_email = () => {
    const { email_body } = this.state;
    return <div dangerouslySetInnerHTML={{ __html: email_body }} />;
  };

  open_show_email = (email_body) => {
    this.setState({ show_email: true, email_body }, () => {
      const iframe = this.iframeRef.current;
      console.log(iframe);
      const document = iframe.contentDocument;
      document.body.innerHTML = email_body;
    });
  };

  close_show_email = () => this.setState({ show_email: false, email_body: '' });

  go_back = () => this.props.history.goBack();

  render() {
    const { loading, student_uuid, url_error, show_email } = this.state;
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Student email logs</div>
        </div>
        {/* {this.render_filters()} */}
        {loading ? <CustomLoading /> : null}
        {/* <CustomModal show={show_email} close={this.close_show_email} inner_style={{minWidth: '600px', minHeight: '600px'}}>
                    <iframe ref={this.iframeRef} style={{ width: '100%', height: '100%', minHeight: '600px'}}/>
                </CustomModal> */}
        {!url_error ? (
          this.render_data()
        ) : (
          <h4 style={{ textAlign: 'center' }}>Wrong link. Try again</h4>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(StudentLogs);
