import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import CustomCheckBox from '../../util_components/custom_checkbox';
import UploadImage from '../../util_components/upload_image';
import { audience_types } from '../../../constants';
import CustomSingleSelect from '../../util_components/custom_single_select';
import * as actions from '../../../redux/action_creators';

const choice_active = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 }
];

const unlisted_active = [
  { label: 'Visible to everyone', value: 0 },
  { label: 'Hide from the list', value: 1 }
];

class CreateEditWorkshop extends Component {
  state = {
    loading: false,
    teachers: [],
    teacher_selected: '',
    workshop_name: '',
    workshop_description: '',
    seats_limit: '',
    video_url: '',
    video_thumbnail_url: '',
    banner_image: '',
    teacher_intro: '',
    start_date: '',
    end_date: '',
    frequency_text: '',
    total_hours: '',
    discount_for_members: '',
    referral_benefit: '',
    list_thumbnail: '',
    price: '',
    original_price: '',
    is_active: '',
    is_unlisted: '',
    number_of_instalments: 0,
    instalment_price: 0,
    workshop_uuid: '',
    co_teachers: [],
    co_teacher_uuids: [],
    visible_to_members: 1,
    visible_to_non_members: 1,
    visible_to_trial_users: 1,
    video_title: '',
    video_description: '',
    cc_required_for_bootcamps: 0,
    discounted_price_for_bootcamps: 0,
    trial_user_original_price: 0,
    trial_user_discounted_price: 0,
    member_original_price: 0,
    member_discounted_price: 0,
    non_member_original_price: 0,
    non_member_discounted_price: 0,
    show_course_schedule: 1,
    show_about_teacher: 1,
    visible_to_newbie: 1,
    visible_to_beginner: 1,
    visible_to_intermediate: 1,
    visible_to_advanced: 1,
    audience_type: 0
  };

  // visible_to_newbie, visible_to_beginner, visible_to_intermediate, visible_to_advanced
  componentDidMount() {
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
    if (this.props.is_edit) {
      this.set_details();
    }
  }

  set_details = () => {
    const details = this.props.workshop_details;
    const for_start_date = moment
      .tz(details.start_date, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const start_date = moment(for_start_date).toDate();
    const for_end_date = moment
      .tz(details.end_date, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(for_end_date).toDate();
    this.setState(
      {
        workshop_uuid: details.uuid,
        teacher_selected: details.teacher.uuid,
        workshop_name: details.name,
        workshop_description: details.description,
        seats_limit: details.seats_limit,
        video_url: details.video_url,
        video_thumbnail_url: details.video_thumbnail_url ? details.video_thumbnail_url : '',
        banner_image: details.banner_image,
        teacher_intro: details.teacher_intro,
        start_date,
        end_date,
        frequency_text: details.frequency_text,
        total_hours: details.total_hours,
        discount_for_members: details.discount_for_members,
        referral_benefit: details.referral_benefit,
        list_thumbnail: details.list_thumbnail,
        original_price: details.original_price,
        price: details.price,
        is_active: details.is_active,
        is_unlisted: details.is_unlisted,
        instalment_price: details.instalment_price,
        number_of_instalments: details.number_of_instalments,
        co_teachers: [],
        co_teacher_uuids: details.co_teachers ? details.co_teachers : [],
        audience_type: details.audience_type,
        visible_to_members: details.visible_to_members,
        visible_to_non_members: details.visible_to_non_members,
        visible_to_trial_users: details.visible_to_trial_users,
        video_description: details.video_description || '',
        video_title: details.video_title,
        cc_required_for_bootcamps: details.cc_required_for_bootcamps,
        discounted_price_for_bootcamps: details.discounted_price_for_bootcamps,
        trial_user_original_price: details.trial_user_original_price,
        trial_user_discounted_price: details.trial_user_discounted_price,
        member_original_price: details.member_original_price,
        member_discounted_price: details.member_discounted_price,
        non_member_original_price: details.non_member_original_price,
        non_member_discounted_price: details.non_member_discounted_price,
        show_course_schedule: details.show_course_schedule,
        show_about_teacher: details.show_about_teacher,
        visible_to_newbie: details.visible_to_newbie,
        visible_to_beginner: details.visible_to_beginner,
        visible_to_intermediate: details.visible_to_intermediate,
        visible_to_advanced: details.visible_to_advanced
      },
      () => this.set_teachers_list()
    );
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
  }

  set_teachers_list = () => {
    const { co_teacher_uuids } = this.state;
    const all_teachers = this.props.teachers_list;
    const teachers_list = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    teachers_list.sort((a, b) => a.label.localeCompare(b.label));
    const co_teachers = teachers_list.filter((t) => co_teacher_uuids.indexOf(t.value) !== -1);
    this.setState({ teachers: [...teachers_list], loading: false, co_teachers });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCommonChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  set_start_date = (key, date) => {
    this.setState({ [key]: date });
  };

  submit_workshop = () => {
    const {
      workshop_name,
      teacher_selected,
      start_date,
      end_date,
      video_thumbnail_url,
      is_active,
      is_unlisted,
      workshop_description,
      seats_limit,
      video_url,
      banner_image,
      list_thumbnail,
      workshop_uuid,
      teacher_intro,
      frequency_text,
      total_hours,
      discount_for_members,
      referral_benefit,
      price,
      original_price,
      number_of_instalments,
      instalment_price,
      co_teachers,
      video_title,
      video_description,
      audience_type,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      cc_required_for_bootcamps,
      discounted_price_for_bootcamps,
      show_about_teacher,
      show_course_schedule,
      trial_user_original_price,
      trial_user_discounted_price,
      member_original_price,
      member_discounted_price,
      non_member_original_price,
      non_member_discounted_price,
      visible_to_newbie,
      visible_to_beginner,
      visible_to_intermediate,
      visible_to_advanced
    } = this.state;
    const payload = {
      name: workshop_name,
      description: workshop_description,
      video_thumbnail_url,
      video_url,
      banner_image,
      teacher_intro,
      frequency_text,
      list_thumbnail,
      package_uuid: 'f4707ea0-45af-460e-ba2a-72305e4eb03a',
      teacher_uuid: teacher_selected,
      number_of_instalments,
      instalment_price,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      cc_required_for_bootcamps,
      discounted_price_for_bootcamps,
      target_audience: parseInt(audience_type),
      video_title,
      video_description,
      show_about_teacher,
      show_course_schedule,
      trial_user_original_price,
      trial_user_discounted_price,
      member_original_price,
      member_discounted_price,
      non_member_original_price,
      non_member_discounted_price,
      visible_to_newbie,
      visible_to_beginner,
      visible_to_intermediate,
      visible_to_advanced
    };
    const sel_start_time = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
    const fin_start_date = moment.tz(sel_start_time, this.props.iana_timezone).valueOf();
    const sel_end_time = moment(end_date).format('YYYY-MM-DD HH:mm:ss');
    const fin_end_date = moment.tz(sel_end_time, this.props.iana_timezone).valueOf();
    payload.start_date = fin_start_date;
    payload.end_date = fin_end_date;
    if (co_teachers.length > 0) {
      payload.co_teachers = co_teachers.map((t) => t.value);
    }
    if (seats_limit) {
      payload.seats_limit = seats_limit;
    }
    if (total_hours) {
      payload.total_hours = total_hours;
    }
    if (discount_for_members) {
      payload.discount_for_members = discount_for_members;
    }
    if (!!price || price === 0) {
      payload.price = price;
    }
    if (original_price) {
      payload.original_price = original_price;
    }
    if (referral_benefit) {
      payload.referral_benefit = referral_benefit;
    }
    if (this.props.is_edit) {
      payload.is_active = is_active;
      payload.is_unlisted = is_unlisted;
      payload.workshop_uuid = workshop_uuid;
    }
    this.props.submit_workshop(payload);
  };

  on_teacher_click = (e) => {
    const { teachers, co_teachers } = this.state;
    const co_index = co_teachers.findIndex((t) => t.value === e.target.value);
    if (co_index === -1) {
      const teacher_index = teachers.findIndex((t) => t.value === e.target.value);
      const final_co_teachers = [...co_teachers, teachers[teacher_index]];
      this.setState({ co_teachers: final_co_teachers });
    }
  };

  remove_teacher = (teac) => {
    const { co_teachers } = this.state;
    const teacher_index = co_teachers.findIndex((t) => t.value === teac.value);
    const final_teachers = [
      ...co_teachers.slice(0, teacher_index),
      ...co_teachers.slice(teacher_index + 1)
    ];
    this.setState({ co_teachers: final_teachers });
  };

  render_co_teachers = () => {
    const { co_teachers } = this.state;
    if (co_teachers.length > 0) {
      return (
        <div className="">
          <div className="cre-class-teachers-title">Teachers selected:</div>
          <div className="cre-class-teachers">
            {co_teachers.map((teac) => (
              <div className="cre-class-teac" key={teac.value}>
                {teac.label}
                <img
                  src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                  onClick={() => this.remove_teacher(teac)}
                  alt="x"
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  change_input = (field_name, value) => {
    this.setState({ [field_name]: value });
  };

  start_loading = () => this.setState({ loading: true });

  stop_loading = () => this.setState({ loading: false });

  render() {
    const {
      teachers,
      workshop_name,
      teacher_selected,
      start_date,
      end_date,
      video_thumbnail_url,
      workshop_description,
      seats_limit,
      video_url,
      banner_image,
      list_thumbnail,
      is_active,
      is_unlisted,
      teacher_intro,
      frequency_text,
      total_hours,
      discount_for_members,
      referral_benefit,
      price,
      original_price,
      number_of_instalments,
      instalment_price,
      audience_type,
      video_title,
      video_description,
      cc_required_for_bootcamps,
      discounted_price_for_bootcamps,
      show_about_teacher,
      show_course_schedule,
      trial_user_original_price,
      trial_user_discounted_price,
      member_original_price,
      member_discounted_price,
      non_member_original_price,
      non_member_discounted_price,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      visible_to_newbie,
      visible_to_beginner,
      visible_to_intermediate,
      visible_to_advanced
    } = this.state;
    return (
      <div>
        {this.state.loading ? <CustomLoading /> : null}
        <h2>{`${this.props.is_edit ? `Edit` : `Create`}`} workshop</h2>
        <div className="display-flex-between">
          {this.render_custom_input('Enter workshop name *', 'workshop_name', workshop_name, {
            width: '250px'
          })}
          <CustomSingleSelect
            label="Select a teacher *"
            options={teachers}
            name="teacher_selected"
            onChange={this.onCommonChange}
            value={teacher_selected}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Audience Type"
            options={audience_types}
            name="audience_type"
            onChange={this.onCommonChange}
            value={audience_type}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <div>
            <div>Select start date *</div>
            <DatePicker
              selected={start_date}
              onChange={(date) => this.set_start_date('start_date', date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="yyyy-MM-dd  h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
          <div>
            <div>Select end date *</div>
            <DatePicker
              selected={end_date}
              onChange={(date) => this.set_start_date('end_date', date)}
              minDate={start_date}
              showTimeSelect
              popperPlacement="left"
              dateFormat="yyyy-MM-dd  h:mm aa"
              // popperPlacement="left-end"
            />
          </div>
        </div>
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <div>Enter description:</div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={workshop_description}
              name="workshop_description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <div>Enter teacher intro:</div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={teacher_intro}
              name="teacher_intro"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
        </div>
        <CustomSingleSelect
          label="Add co-teachers"
          options={teachers}
          name="teacher_selected"
          onChange={this.on_teacher_click}
          value=""
          style={{ width: '250px' }}
        />
        {this.render_co_teachers()}
        <div className="display-flex-between">
          {this.render_custom_input('Video title', 'video_title', video_title, { width: '100%' })}
        </div>
        {/* <div className="display-flex-between">
                    <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
                        <div>Enter Video Description:</div>
                        <textarea style={{ width: '100%', height: '200px' }}
                            value={video_description} name="video_description"
                            onChange={this.onCommonChange} maxLength="2000" />
                    </div>
                </div> */}
        <div className="display-flex-between">
          {this.render_custom_input('Video url', 'video_url', video_url, { width: '100%' })}
        </div>
        <div className="display-flex-between">
          <UploadImage
            buttonTitle={
              video_thumbnail_url ? 'Edit Video Thumbnail Url' : 'Add Video Thumbnail Url'
            }
            imageUrl={video_thumbnail_url}
            uuid={this.state.workshop_uuid}
            set_notification_variable={this.props.set_notification_variable}
            close_create_workshop={this.props.close_create_workshop}
            get_all_workshops={this.props.get_all_workshops}
            folder_name="workshops"
            image_type="video_thumbnail_url"
            change_input={this.change_input}
            start_loading={this.start_loading}
            stop_loading={this.stop_loading}
          />
        </div>
        <div className="display-flex-between">
          <UploadImage
            buttonTitle={banner_image ? 'Edit Banner Image' : 'Add Banner Image'}
            imageUrl={banner_image}
            uuid={this.state.workshop_uuid}
            set_notification_variable={this.props.set_notification_variable}
            close_create_workshop={this.props.close_create_workshop}
            get_all_workshops={this.props.get_all_workshops}
            folder_name="workshops"
            image_type="banner_image"
            change_input={this.change_input}
            start_loading={this.start_loading}
            stop_loading={this.stop_loading}
          />
        </div>
        <div className="display-flex-between">
          <UploadImage
            buttonTitle={list_thumbnail ? 'Edit List Thumbnail Image' : 'Add List Thumbnail Image'}
            imageUrl={list_thumbnail}
            uuid={this.state.workshop_uuid}
            set_notification_variable={this.props.set_notification_variable}
            close_create_workshop={this.props.close_create_workshop}
            get_all_workshops={this.props.get_all_workshops}
            folder_name="workshops"
            image_type="list_thumbnail"
            change_input={this.change_input}
            start_loading={this.start_loading}
            stop_loading={this.stop_loading}
          />
        </div>
        <div className="display-flex-between">
          {this.render_custom_input(
            'Seats limit',
            'seats_limit',
            seats_limit,
            { width: '150px' },
            'number'
          )}
          {this.render_custom_input(
            'Total hours',
            'total_hours',
            total_hours,
            { width: '150px' },
            'number'
          )}
          {this.render_custom_input(
            'Referral benefit($)',
            'referral_benefit',
            referral_benefit,
            { width: '150px' },
            'number'
          )}
        </div>
        <div className="display-flex-between">
          {/* {this.render_custom_input('Discounted Bootcamp Price ($)', 'discounted_price_for_bootcamps', discounted_price_for_bootcamps, { width: '150px' }, 'number')} */}
        </div>
        <div className="display-flex-between">
          {this.render_custom_input(
            'No of instalments ($)',
            'number_of_instalments',
            number_of_instalments,
            { width: '150px' },
            'number'
          )}
          {this.render_custom_input(
            'Instalment price ($)',
            'instalment_price',
            instalment_price,
            { width: '150px' },
            'number'
          )}
        </div>
        <div className="display-flex-between">
          {this.render_custom_input('Frequency text', 'frequency_text', frequency_text, {
            width: '100%'
          })}
        </div>
        {this.props.is_edit ? (
          <div className="display-flex-between">
            <CustomSingleSelect
              label="Is workshop Live"
              options={choice_active}
              onChange={this.onCommonChange}
              value={is_active}
              style={{ width: '260px' }}
              name="is_active"
            />
          </div>
        ) : null}
        <hr />
        <div className="display-flex-between">
          <CustomCheckBox
            label="Visible to Newbie"
            checked={visible_to_newbie}
            onClick={() => this.toggle_check_box('visible_to_newbie')}
          />
          <CustomCheckBox
            label="Visible to Beginner"
            checked={visible_to_beginner}
            onClick={() => this.toggle_check_box('visible_to_beginner')}
          />
        </div>
        <div className="display-flex-between">
          <CustomCheckBox
            label="Visible to Intermediate"
            checked={visible_to_intermediate}
            onClick={() => this.toggle_check_box('visible_to_intermediate')}
          />
          <CustomCheckBox
            label="Visible to Advanced"
            checked={visible_to_advanced}
            onClick={() => this.toggle_check_box('visible_to_advanced')}
          />
        </div>
        <hr />
        {audience_type != 4 ? (
          <div className="display-flex-between">
            {this.render_custom_input('Price($)', 'price', price, { width: '150px' }, 'number')}
            {this.render_custom_input(
              'Original price($)',
              'original_price',
              original_price,
              { width: '150px' },
              'number'
            )}
            {this.render_custom_input(
              'Member discount($)',
              'discount_for_members',
              discount_for_members,
              { width: '150px' },
              'number'
            )}
          </div>
        ) : null}
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Members"
            checked={visible_to_members}
            onClick={() => this.toggle_check_box('visible_to_members')}
          />
        </div>
        {visible_to_members === 1 && audience_type == 4 ? (
          <div className="display-flex-between">
            {this.render_custom_input(
              'Member crossed price ($)',
              'member_original_price',
              member_original_price,
              { width: '45%' },
              'number'
            )}
            {this.render_custom_input(
              'Member discounted price ($)',
              'member_discounted_price',
              member_discounted_price,
              { width: '45%' },
              'number'
            )}
          </div>
        ) : null}
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Non Members"
            checked={visible_to_non_members}
            onClick={() => this.toggle_check_box('visible_to_non_members')}
          />
        </div>
        {visible_to_non_members && audience_type == 4 ? (
          <div className="display-flex-between">
            {this.render_custom_input(
              'NonMember crossed price($)',
              'non_member_original_price',
              non_member_original_price,
              { width: '45%' },
              'number'
            )}
            {this.render_custom_input(
              'NonMember discounted price($)',
              'non_member_discounted_price',
              non_member_discounted_price,
              { width: '45%' },
              'number'
            )}
          </div>
        ) : null}
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Trial users"
            checked={visible_to_trial_users}
            onClick={() => this.toggle_check_box('visible_to_trial_users')}
          />
        </div>
        {visible_to_trial_users && audience_type == 4 ? (
          <div className="display-flex-between">
            {this.render_custom_input(
              'Trial user crossed price($)',
              'trial_user_original_price',
              trial_user_original_price,
              { width: '45%' },
              'number'
            )}
            {this.render_custom_input(
              'Trial user discounted price($)',
              'trial_user_discounted_price',
              trial_user_discounted_price,
              { width: '45%' },
              'number'
            )}
          </div>
        ) : null}
        <hr />
        <div className="display-flex-between" style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Is credit card required?"
            checked={cc_required_for_bootcamps}
            onClick={() => this.toggle_check_box('cc_required_for_bootcamps')}
          />
          <CustomCheckBox
            label="Show Course Schedule"
            checked={show_course_schedule}
            onClick={() => this.toggle_check_box('show_course_schedule')}
          />
          <CustomCheckBox
            label="Show about teacher"
            checked={show_about_teacher}
            onClick={() => this.toggle_check_box('show_about_teacher')}
          />
        </div>
        <Button
          onClick={this.submit_workshop}
          disabled={!workshop_name || !teacher_selected || !start_date || !end_date}
        >
          Submit
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_all_workshops: () => {
    dispatch(actions.get_all_workshops());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditWorkshop);
