import React, { Component } from 'react';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import { image_error_replace } from '../../../../util_functions';
import { get_api_url } from '../../../../utils/urls';

export default class LiveUsers extends Component {
  state = {
    loading: true,
    modified_students: [],
    search_value: '',
    yoga_level: 'ALL',
    credits: 'DESCENDING'
  };

  componentDidMount() {
    this.load_students();
  }

  load_students = () => {
    const url = get_api_url('get_all_students_out_of_queue');
    post_api(url, null, true)
      .then((response) =>
        this.setState({ students: response.data.students, loading: false }, () =>
          this.modify_data()
        )
      )
      .catch((e) => console.log('in live users error', e));
  };

  modify_data = () => {
    const students = [...this.state.students];
    const { search_value } = this.state;
    const search_list = students.filter(
      (student) =>
        student.first_name.toLowerCase().indexOf(search_value) !== -1 ||
        student.last_name.toLowerCase().indexOf(search_value) !== -1 ||
        student.middle_name.toLowerCase().indexOf(search_value) !== -1 ||
        student.email.toLowerCase().indexOf(search_value) !== -1
    );
    search_list.sort((a, b) => (a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 1));
    const sort_item = this.state.credits;
    const sorted_students = search_list.sort((a, b) => {
      if (sort_item === 'ASCENDING') {
        return a.credits - b.credits;
      }
      return b.credits - a.credits;
    });
    let level_filtered = [];
    const level = this.state.yoga_level;
    if (level === 'ALL') {
      level_filtered = [...sorted_students];
    } else {
      level_filtered = sorted_students.filter((student) => level === student.yoga_level);
    }
    this.setState({ modified_students: [...level_filtered] });
  };

  handle_modify_change = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => this.modify_data());

  render_level_dropdown = () => (
    <select name="yoga_level" onChange={this.handle_modify_change} value={this.state.yoga_level}>
      <option value="ALL">ALL</option>
      <option value="NEWBIE">NEWBIE</option>
      <option value="INTERMEDIATE">INTERMEDIATE</option>
      <option value="ADVANCED">ADVANCED</option>
    </select>
  );

  render_credits_dropdown = () => (
    <select name="credits" onChange={this.handle_modify_change} value={this.state.credits}>
      <option value="ASCENDING">ASCENDING</option>
      <option value="DESCENDING">DESCENDING</option>
    </select>
  );

  open_student_profile = (e) => {
    const url = '/studentprofile';
    const search = `?id=${e.currentTarget.dataset.id}`;
    this.props.history.push({
      pathname: url,
      search
    });
  };

  render() {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const students = this.state.modified_students;
    return (
      <div className="common-page-container">
        <h3>Live Users</h3>
        <p>Total Records - {students.length}</p>
        <table>
          <thead>
            <tr>
              <th>
                <img src="" alt="i" onError={image_error_replace} />
              </th>
              <th className="list-name-email">STUDENT</th>
              <th>YOGA LEVEL</th>
              <th>CREDITS AVAILABLE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="list-filter" />
              <td className="list-filter-input">
                <input
                  placeholder="Enter name or email to search"
                  name="search_value"
                  value={this.state.search_value}
                  onChange={this.handle_modify_change}
                />
              </td>
              <td className="list-filter">{this.render_level_dropdown()}</td>
              <td className="list-filter">{this.render_credits_dropdown()}</td>
            </tr>
            {students.map((student) => (
              <tr
                key={student.uuid}
                className="tr-cursor-row"
                onClick={this.open_student_profile}
                data-id={student.uuid}
              >
                <td>
                  <img src={student.profile_photo} alt="i" onError={image_error_replace} />
                </td>
                <td className="list-name-email">
                  <div>
                    {student.first_name} {student.middle_name} {student.last_name}
                  </div>
                  <div className="list-email">{student.email}</div>
                </td>
                <td>{student.yoga_level}</td>
                <td>{student.credits}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
