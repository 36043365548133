import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import { image_error_replace, combine_name } from '../../../../util_functions';
import Button from '../../../util_components/button';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

class GrpSessByDate extends Component {
  state = {
    loading: true,
    load_error: false,
    date: '',
    sessions: []
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const date = url.searchParams.get('date');
    if (date) {
      this.setState({ date }, () => this.load_data());
    } else {
      this.setState({ load_error: true });
    }
  }

  load_data = () => {
    const { date } = this.state;
    const url = get_api_url('group_sessions_data_by_date');
    const payload = {
      start_date: date,
      iana_timezone: this.props.iana_timezone
    };
    post_api(url, payload, true)
      .then((response) => this.setState({ loading: false, sessions: [...response.data.result] }))
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false });
        this.props.set_notification_variable(
          true,
          'error',
          'Some error occured. Please contact dev team'
        );
      });
  };

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    const teacher_name = combine_name(session.first_name, session.middle_name, session.last_name);
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col ct-la">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.profile_photo} alt="i" onError={image_error_replace} />
              {teacher_name}
            </div>
          </a>
        </div>
        <div className="ct-col">
          {moment(session.start_time).tz(this.props.iana_timezone).format('MMM DD hh:mm A z')}
        </div>
        <div className="ct-col ct-hf">{session.joined_participants}</div>
        <div className="ct-col ct-hf">{session.no_show_participants}</div>
        <div className="ct-col ct-hf">{session.cancelled_participants}</div>
        <div className="ct-col ct-hf">{session.total_participants}</div>
        <div className="ct-col ct-hf">{session.avg_rating}</div>
        <div className="ct-col ct-hf">
          <Button onClick={() => this.open_session_viewer(session.session_uuid)}>Details</Button>
        </div>
      </div>
    );
  };

  render_data = () => {
    const { sessions } = this.state;
    return (
      <div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col">Teacher</div>
            <div className="ct-col">Start time</div>
            <div className="ct-col-span-con" style={{ flex: 2 }}>
              <div className="ct-col-span">Participants</div>
              <div className="ct-col-span" style={{ display: 'flex' }}>
                <div className="ct-col">Joined</div>
                <div className="ct-col">No show</div>
                <div className="ct-col">Cancelled</div>
                <div className="ct-col">Registered</div>
              </div>
            </div>
            <div className="ct-col ct-hf">Rating</div>
            <div className="ct-col ct-hf">Details</div>
          </div>
          {sessions.map(this.render_single_session)}
        </div>
      </div>
    );
  };

  render_func = () => {
    const { loading, load_error } = this.state;
    if (load_error) {
      return <div>Wrong url</div>;
    }
    return this.render_data();
  };

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Group Sessions By Date</div>
        </div>
        {this.render_func()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GrpSessByDate);
