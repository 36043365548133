import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import ScrollContainer from '../../../util_components/scroll_container';
import { get_api_url } from '../../../../utils/urls';
import './user_rev.css';

class Fiancials extends Component {
  state = {
    loading: true,
    data: [],
    data_keys: []
  };

  load_data = () => {
    const url = get_api_url('get_users_revenue_analysis_data');
    post_api(url, null, true).then((result) => {
      const met_data = result.data.metrics;
      const key_names = {};
      const data_keys = met_data.map((m) => {
        key_names[m.key] = m.value;
        return m.key;
      });
      const { data } = result.data;
      // data.reverse()
      this.setState({
        data,
        data_keys,
        met_data,
        key_names,
        loading: false
      });
    });
  };

  componentDidMount() {
    this.load_data();
  }

  render_data_mobile = () => {
    const { data, data_keys, key_names } = this.state;
    return (
      <div>
        {data.map((item, index) => {
          let column_class = 'common-mobile-column';
          column_class +=
            index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
          return (
            <div className={column_class} key={item.month_name}>
              {data_keys.map((key) => {
                if (key.indexOf('line_break') !== -1) {
                  return <br key={key} />;
                }
                return (
                  <div className="common-mobile-details" key={key}>
                    <div className="common-mobile-single-detail common-mobile-key">
                      {key === 'month_name' ? 'Month' : key_names[key]}
                    </div>
                    <div className="common-mobile-single-detail">{item[key]}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  render_data = () => {
    const { data, data_keys, key_names } = this.state;
    return (
      <div className="k-m-cont">
        <div className="k-m-title">
          <table className="user-rev-td-fir">
            <tbody>
              {data_keys.map((key, index) => {
                const first_row = index === 0 ? 'user-rev-row-fir ' : '';
                const tr_class = key.indexOf('line_break') !== -1 ? 'user-rev-no-bor' : '';
                return (
                  <tr className={`user-rev-row ${tr_class}`} key={key + index}>
                    <td className={`user-rev-td user-rev-td-fir ${first_row}`}>{key_names[key]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="k-m-value">
          {data.map((item, index) => (
            <table key={item.month_name + index} className="user-rev-table">
              <tbody>
                {data_keys.map((key, index1) => {
                  let new_break = false;
                  try {
                    if (item[key].indexOf('\n') !== -1) {
                      new_break = true;
                    }
                  } catch {}
                  const first_row = index1 === 0 ? 'user-rev-row-fir ' : '';
                  const tr_class = key.indexOf('line_break') !== -1 ? 'user-rev-no-bor' : '';
                  if (new_break) {
                    return (
                      <tr className={`user-rev-row ${tr_class}`} key={index + key}>
                        <td className={`sess-week-rev-td ${first_row}`}>
                          {item[key].split('\n')[0]}
                          <br />
                          {item[key].split('\n')[1]}
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <tr className={`user-rev-row ${tr_class}`} key={index + key}>
                      <td className={`user-rev-td ${first_row}`}>{item[key]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="common-page-container">
        <ScrollContainer />
        {this.state.loading ? <CustomLoading /> : null}
        {this.state.loading ? null : (
          <ReactToPrint
            trigger={() => (
              <div className="print-link">
                <a href="#">Print</a>
              </div>
            )}
            content={() => this.componentRef}
          />
        )}
        <div ref={(el) => (this.componentRef = el)}>
          <div className="common-page-header">
            <div className="common-page-title">Key Metrics</div>
          </div>
          {this.state.data.length > 0
            ? this.props.application_width > 700
              ? this.render_data()
              : this.render_data_mobile()
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  application_width: state.home.application_width
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Fiancials);
