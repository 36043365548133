import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CustomLoading from '../../util_components/custom_loading';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import {
  time_format,
  day_format_year,
  date_drop_for_report,
  engineering_student_timeline_msg_types,
  other_student_timeline_msg_types
} from '../../../constants';
import CustomModal from '../../util_components/custom_modal';
import CustomTable from '../../util_components/custom_table';
import CustomSingleSelect from '../../util_components/custom_single_select';
import Button from '../../util_components/button';
import { timeline_data_format, mail_meta_data } from '../../../utils/formats';
import CustomCheckbox from '../../util_components/custom_checkbox';
import { get_api_url } from '../../../utils/urls';
import CustomDatePicker from '../../util_components/custom_date_picker';
import { get_date_filter_data_timezone_utc } from '../../../util_functions';

class BolierPlate extends Component {
  state = {
    loading: false,
    user_events: [],
    open_event_modal: false,
    modal_event_data: {},
    msg_type_list:
      this.props?.access_type.toUpperCase() === 'ENGINEERING' ||
      this.props?.access_type.toUpperCase() === 'SUPER_USER'
        ? ['API_REQUEST', 'API_RESPONSE', 'EMAIL_SENT', 'SMS_SENT', 'SMS_RECEIVED', 'UI_EVENTS']
        : ['EMAIL_SENT', 'SMS_SENT', 'SMS_RECEIVED'],
    has_next: false,
    has_prev: false,
    open_view_modal: false,
    meta_data_details: {},
    msg_type: '',
    event_name: '',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    page_no: 0,
    is_page_change: false
  };

  onViewClick = (value) => {
    this.setState({
      open_event_modal: false,
      open_view_modal: true,
      meta_data_details: value
    });
  };

  view_modal = (data) => {
    this.setState({
      open_event_modal: false,
      open_view_modal: !this.state.open_view_modal,
      meta_data_details: data
    });
  };

  show_view_modal_data = () => (
    <CustomTable
      data={this.state.meta_data_details}
      data_format={mail_meta_data}
      open_view_modal={this.state.open_view_modal}
    />
  );

  show_ui_events_data = (value, index) => (
    <div className="w-full flex" key={index + value}>
      <div className="text-sm w-2/6 p-2 border-b-2 border-gray-100">{value}</div>
      <div className="text-sm break-all w-4/6 p-2 border-b-2 border-gray-100">
        {this.state.meta_data_details[value]}
      </div>
    </div>
  );

  go_to_path = (path) => this.props.history.push(path);

  componentDidMount() {
    try {
      const { uuid, user_type } = this.props.match.params;
      const { date_filter_type, start_date, end_date } = this.state;
      const date_data = get_date_filter_data_timezone_utc(date_filter_type, start_date, end_date);
      if (!!uuid && !!user_type) {
        const payload = {};
        if (user_type.toLowerCase() === 'student') {
          payload.student_uuid = uuid;
          payload.msg_types = this.state.msg_type_list;
          payload.from_date = date_data.utc_start_date;
          payload.to_date = date_data.utc_end_date;
        } else if (user_type.toLowerCase() === 'teacher') {
          // payload.teacher_uuid = uuid;
          payload.teacher_uuid = uuid;
          payload.msg_types = this.state.msg_type_list;
          payload.from_date = date_data.utc_start_date;
          payload.to_date = date_data.utc_end_date;
        }
        this.setState({ uuid, user_type }, () => this.load_details(payload));
      } else this.go_to_path('/');
    } catch (e) {
      this.go_to_path('/');
    }
  }

  load_details = (payload) =>
    this.setState({ loading: true }, () => {
      const { date_filter_type, start_date, end_date } = this.state;
      const date_data = get_date_filter_data_timezone_utc(date_filter_type, start_date, end_date);

      payload.from_date = date_data.utc_start_date;
      payload.to_date = date_data.utc_end_date;
      const url = get_api_url('/v2/user_timeline/get_events', false, true);

      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            loading: false,
            user_events: res.data.records,
            has_next: res.data.has_next,
            has_prev: res.data.has_prev
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message || e.response.data.reason;
            if (!err_message) err_message = 'Some error occured. Please contact dev team.';
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_event = (event) => (
    <div className="ct-row" key={event.uuid}>
      <div className="ct-col ct-sm-font">{event.event_name}</div>
      <div className="ct-col ct-sm-font">{event.message}</div>
      <div className="ct-col ct-sm-font">{event.msg_type}</div>
      <div className="ct-col ct-sm-font">
        {moment(event.created_date).tz(this.props.iana_timezone).format(day_format_year)}{' '}
        {moment(event.created_date).tz(this.props.iana_timezone).format(time_format)}
      </div>
      <div className="ct-col">
        <button
          onClick={() => {
            this.event_modal(event);
          }}
        >
          Details
        </button>
      </div>
    </div>
  );

  event_modal = (data) => {
    this.setState({
      open_event_modal: !this.state.open_event_modal,
      modal_event_data: data,
      msg_type: data.msg_type,
      event_name: data.event_name
    });
  };

  show_event_modal_data = () => (
    <CustomTable
      data={this.state.modal_event_data}
      data_format={timeline_data_format}
      onViewClick={this.onViewClick}
    />
  );

  onMsgTypeClick = (val) => {
    const keywords = [...this.state.msg_type_list];
    const index = keywords.indexOf(val);
    if (index === -1) keywords.push(val);
    else keywords.splice(index, 1);
    const { uuid, user_type } = this.state;
    const payload = {};
    if (user_type === 'student') {
      payload.student_uuid = uuid;
      payload.msg_types = keywords;
      payload.page = this.state.is_page_change ? this.state.page_no : 0;
    } else if (user_type === 'teacher') {
      payload.teacher_uuid = uuid;
      payload.msg_types = keywords;
      payload.page = this.state.is_page_change ? this.state.page_no : 0;
    }
    this.setState({ msg_type_list: [...keywords] }, () => this.load_details(payload));
  };

  handleChangePage = (page_type) => {
    const { uuid, user_type, msg_type_list, page_no } = this.state;
    const page = page_type === 'prev' ? page_no - 1 : page_no + 1;

    this.setState(
      {
        page_no: page,
        is_page_change: true
      },
      () => {
        const payload = {};
        if (user_type === 'student') {
          payload.student_uuid = uuid;
          payload.msg_types = msg_type_list;
          payload.page = page;
        } else if (user_type === 'teacher') {
          payload.teacher_uuid = uuid;
          payload.msg_types = msg_type_list;
          payload.page = page;
        }
        this.load_details(payload);
      }
    );
  };

  onSubmitCustomDate = () => {
    const { uuid, user_type, msg_type_list, page_no, is_page_change } = this.state;

    const payload = {};
    if (user_type === 'student') {
      payload.student_uuid = uuid;
      payload.msg_types = msg_type_list;
      payload.page = is_page_change ? page_no : 0;
    } else if (user_type === 'teacher') {
      payload.teacher_uuid = uuid;
      payload.msg_types = msg_type_list;
      payload.page = is_page_change ? page_no : 0;
    }
    this.load_details(payload);
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => {
      const { uuid, user_type, msg_type_list, page_no, is_page_change } = this.state;
      const payload = {};

      if (this.state.date_filter_type !== 'CUSTOM') {
        if (user_type === 'student') {
          payload.student_uuid = uuid;
          payload.msg_types = msg_type_list;
          payload.page = is_page_change ? page_no : 0;
        } else if (user_type === 'teacher') {
          payload.teacher_uuid = uuid;
          payload.msg_types = msg_type_list;
          payload.page = is_page_change ? page_no : 0;
        }
        this.load_details(payload);
      }
    });

  handle_date_change = (name, val) => this.setState({ [name]: val });

  render_data = () => {
    const { date_filter_type, start_date, end_date, page_no, has_prev, has_next } = this.state;
    const custom_style = { marginRight: '10px', flexShrink: '0' };
    const label_style = {
      fontSize: '14px',
      fontFamily: 'Montserrat',
      marginLeft: '5px',
      marginRight: '10px'
    };
    const msg_types_options =
      this.props?.access_type.toUpperCase() === 'ENGINEERING' ||
      this.props?.access_type.toUpperCase() === 'SUPER_USER'
        ? engineering_student_timeline_msg_types
        : other_student_timeline_msg_types;
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <>
        <div className="flex flex-wrap justify-center">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <CustomSingleSelect
              label="Select a date filter"
              options={date_drop_for_report}
              onChange={this.change_date_filter}
              value={date_filter_type}
              style={{ width: '200px' }}
            />
            {this.state.date_filter_type === 'CUSTOM' ? (
              <>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="From Date"
                    value={start_date}
                    handleChange={(val) => this.handle_date_change('start_date', val)}
                    maxDate={end_date}
                  />
                </div>
                <div className="daily-report-item">
                  <CustomDatePicker
                    label="To Date"
                    value={end_date}
                    handleChange={(val) => this.handle_date_change('end_date', val)}
                    minDate={start_date}
                    maxDate={moment().format('YYYY-MM-DD')}
                  />
                  <button onClick={this.onSubmitCustomDate}>Submit</button>
                </div>
              </>
            ) : null}
          </div>
          {msg_types_options.map((msg_type) => {
            const selected = this.state.msg_type_list.indexOf(msg_type.value) !== -1;
            return (
              <CustomCheckbox
                key={msg_type.value}
                label={msg_type.label}
                checked={selected}
                onClick={() => this.onMsgTypeClick(msg_type.value)}
                custom_style={custom_style}
                label_style={label_style}
              />
            );
          })}
        </div>
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-sm">Event name</div>
            <div className="ct-col ct-sm">Message</div>
            <div className="ct-col ct-sm">Message type</div>
            <div className="ct-col ct-sm">Created at</div>
            <div className="ct-col ct-sm">Details</div>
          </div>
          {this.state.user_events.map(this.render_single_event)}
        </div>
        <div className="container flex justify-center mx-auto mt-2">
          <ul className="flex">
            <li>
              <Button disabled={!has_prev} onClick={() => this.handleChangePage('prev')}>
                Prev
              </Button>
            </li>
            <li>
              <Button type="secondary">{page_no + 1}</Button>
            </li>
            <li>
              <Button disabled={!has_next} onClick={() => this.handleChangePage('next')}>
                Next
              </Button>
            </li>
          </ul>
        </div>
      </>
    );
  };

  render() {
    const { loading, user_type, user_events, open_event_modal, open_view_modal } = this.state;
    const user_name =
      user_type === 'Student' ? user_events?.student_name : user_events?.teacher_name;
    const title = `Event Timeline ${user_name ? `for ${user_type} ${user_name}` : ''}`;
    return (
      <CommonHeader loading={loading} title={title} show_back>
        {this.render_data()}
        {open_event_modal && (
          <CustomModal show={open_event_modal} close={this.event_modal}>
            {this.show_event_modal_data()}
          </CustomModal>
        )}
        {open_view_modal && (
          <CustomModal show={open_view_modal} close={this.view_modal}>
            {this.state.msg_type.toUpperCase() !== 'EMAIL_SENT'
              ? Object.keys(this.state?.meta_data_details).length !== 0 && (
                  <>
                    <p className="p-1 text-xl text-center font-bold">{this.state.event_name}</p>
                    <div className="mt-1 text-sm w-full flex flex-wrap border-t-2 border-gray-100 border-l-2 border-r-2">
                      {Object.keys(this.state.meta_data_details).map(this.show_ui_events_data)}
                    </div>
                  </>
                )
              : this.show_view_modal_data()}
          </CustomModal>
        )}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BolierPlate);
