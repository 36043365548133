import * as t from '../action_types';

export const set_workshops_variable = (key, payload) => ({
  type: t.set_workshops_variable,
  payload: {
    key,
    payload
  }
});

export const get_all_workshops = () => ({
  type: t.get_all_workshops
});
