import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import Button from '../../../util_components/button';
import { get_api_url } from '../../../../utils/urls';

class GroupSessionsReport extends Component {
  state = {
    start_date: moment().subtract(7, 'd').toDate(),
    end_date: moment().toDate(),
    loading: true,
    session_data: []
  };

  componentDidMount() {
    this.load_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.load_data();
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { start_date, end_date } = this.state;
      const url = get_api_url('group_sessions_data');
      const payload = {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).add(1, 'days').format('YYYY-MM-DD'),
        iana_timezone: this.props.iana_timezone
      };
      post_api(url, payload, true)
        .then((response) => {
          this.setState({ loading: false, session_data: [...response.data.result] });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  handle_date_chage = (date, component) => {
    this.setState({ [component]: date });
  };

  on_details_click = (date) => {
    const search = `?date=${date}`;
    this.props.history.push({
      pathname: '/grpsessionreportdate',
      search
    });
  };

  render_data = () => {
    const { session_data } = this.state;
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col">Date</div>
          <div className="ct-col">Total sessions</div>
          <div className="ct-col">Scheduled participants</div>
          <div className="ct-col">Participants joined</div>
          <div className="ct-col">No shows</div>
          <div className="ct-col">Cancelled</div>
          <div className="ct-col">Details</div>
        </div>
        {session_data.map((sess, index) => (
          <div className="ct-row" key={sess.session_uuid}>
            <div className="ct-col">{sess.date}</div>
            <div className="ct-col">{sess.sessions_count}</div>
            <div className="ct-col">{sess.total_participants}</div>
            <div className="ct-col">{sess.joined_participants}</div>
            <div className="ct-col">{sess.no_show_participants}</div>
            <div className="ct-col">{sess.cancelled_participants}</div>
            <div className="ct-col">
              <Button onClick={() => this.on_details_click(sess.date)}>Details</Button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render_filters = () => (
    <div>
      <hr />
      <h4>Date range:</h4>
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.load_data}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Group Sessions Report</div>
        </div>
        {this.render_filters()}
        {this.render_data()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(GroupSessionsReport);
