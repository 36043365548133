import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { invalidations } from '../../../constants/index';
import { get_api_url } from '../../../utils/urls';

class Coupons extends Component {
  state = {
    loading: false
  };

  invalidate_path = (inv) =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('path_invalidate');
      const payload = { invalidate_path: inv.value };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ loading: false });
          this.props.set_notification_variable(true, 'success', `${inv.label} path invalidated`);
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_invalidation = (inv) => (
    <div className="ct-row" key={inv.value}>
      <div className="ct-col">{inv.label}</div>
      <div className="ct-col">
        <button onClick={() => this.invalidate_path(inv)}>Invalidate</button>
      </div>
    </div>
  );

  render_invalidations = () => (
    <div className="cus-table" style={{ marginBottom: '20px' }}>
      <div className="ct-row ct-h">
        <div className="ct-col">Page</div>
        <div className="ct-col">Invalidate</div>
      </div>
      {invalidations.map(this.render_single_invalidation)}
    </div>
  );

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Invalidate">
        {this.render_invalidations()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
