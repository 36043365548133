import { all, put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* getApprovedTeachers(action) {
  try {
    yield put(actions.set_teachers_variable('approved_teachers_status', 'loading'));
    const url = get_api_url('get_teachers_list_approved');
    const payload = {
      status: action.payload
    };
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_teachers_variable('approved_teachers_list', response.data.teachers));
    yield put(actions.set_teachers_variable('approved_teachers_status', 'success'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* getTeachersList() {
  try {
    yield put(actions.set_teachers_variable('teachers_list_status', 'loading'));
    const url = get_api_url('get_teachers_list');
    const response = yield call(post_api, url, null, true);
    const teachers = [...response.data.teachers];
    teachers.sort((a, b) => a.first_name.localeCompare(b.first_name));
    yield put(actions.filter_teachers('teachers_list', teachers));
    yield put(actions.set_teachers_variable('teachers_list_status', 'success'));
  } catch (error) {
    if (error.response?.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* approveTeacherDetails(action) {
  yield;
}

export function* getTeacherProfileDetails(action) {
  try {
    yield put(actions.set_teachers_variable('teacher_profile_status', 'loading'));
    const url = get_api_url('get_teacher_details');
    const payload = {
      teacher_uuid: action.payload
    };
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_teachers_variable('teacher_profile_details', response.data.teacher));
    yield put(actions.set_teachers_variable('teacher_profile_status', 'success'));
  } catch (error) {
    yield put(actions.set_teachers_variable('teacher_profile_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* updateTeacherFiles(action) {
  try {
    yield put(actions.set_teachers_variable('teacher_profile_status', 'loading'));
    let url;
    if (action.payload.key === 'photo') {
      url = get_api_url('update_teacher_photo');
    } else if (action.payload.key === 'resume') {
      url = get_api_url('upload_resume');
    } else if (action.payload.key === 'video') {
      url = get_api_url('update_video');
    }
    const data = new FormData();
    data.append(action.payload.key, action.payload.payload);
    data.append('teacher_uuid', action.payload.uuid);
    yield call(post_api, url, data, true, 'formdata');
    yield put(actions.get_teacher_profile_details(action.payload.uuid));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}
export default function* chatSaga() {
  yield all([
    takeLatest(t.get_approved_teachers, getApprovedTeachers),
    takeEvery(t.approve_teacher_details, approveTeacherDetails),
    takeLatest(t.get_teacher_profile_details, getTeacherProfileDetails),
    takeLatest(t.update_teacher_files, updateTeacherFiles),
    takeLatest(t.get_teachers_list, getTeachersList)
  ]);
}
