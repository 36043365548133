import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
// import * as actions from '../../../../redux/action_creators';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';

class TeacherTransactions extends Component {
  state = { loading: true, single_trans_open: true, subscription_open: true };

  go_back = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    const user_name = url.searchParams.get('name');
    this.setState({ uuid, user_name }, () => {
      this.load_details();
    });
  }

  load_details = () => {
    const url = get_api_url('list_all_transactions_teacher');
    const payload = {
      teacher_uuid: this.state.uuid,
      recurring: 0
    };
    post_api(url, payload, true).then((response) => {
      this.setState({ single_transactions: [...response.data.transaction] }, () => {
        const new_payload = {
          teacher_uuid: this.state.uuid,
          recurring: 1
        };
        post_api(url, new_payload, true).then((response) => {
          this.setState({
            subscription_transactions: [...response.data.transaction],
            loading: false
          });
        });
      });
    });
  };

  toggle_single_trans = () => this.setState({ single_trans_open: !this.state.single_trans_open });

  render_single_transactions = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_single_trans}>
        Single Transactions: {this.state.single_transactions.length}
        {this.state.single_transactions.length !== 0 ? (
          <img
            className={this.state.single_trans_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        ) : null}
      </div>
      {this.state.single_transactions.length !== 0 && this.state.single_trans_open ? (
        <table>
          <tbody>
            <tr>
              <td>Invoice Id</td>
              <td>Price</td>
              <td>Transaction Time</td>
              <td>Student Session Time</td>
              <td>Student Name</td>
            </tr>
            {this.state.single_transactions.map((transaction) => {
              const student_url = `/studentprofile?id=${transaction.student_uuid}`;
              return (
                <tr key={transaction.invoice_id}>
                  <td>{transaction.invoice_id}</td>
                  <td className="low-text">{transaction.purchase_price}</td>
                  <td className="low-text">{transaction.transaction_date}</td>
                  <td className="low-text">{transaction.session_student_time}</td>
                  <td>
                    <a className="link-no-dec" href={student_url}>
                      {transaction.student_name}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </div>
  );

  toggle_subscription_open = () =>
    this.setState({ subscription_open: !this.state.subscription_open });

  render_subscriptions = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_subscription_open}>
        Subscription Transactions: {this.state.subscription_transactions.length}
        {this.state.subscription_transactions.length !== 0 ? (
          <img
            className={this.state.subscription_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        ) : null}
      </div>
      {this.state.subscription_transactions.length !== 0 && this.state.subscription_open ? (
        <table>
          <tbody>
            <tr>
              <td>Invoice Id</td>
              <td>Package Name</td>
              <td>Purchase Date</td>
              <td>Price</td>
              <td>Next Billing</td>
            </tr>
            {this.state.subscription_transactions.map((transaction, index) => (
              <tr key={transaction.invoice_id + index}>
                <td>{transaction.invoice_id}</td>
                <td className="low-text">{transaction.package_name}</td>
                <td className="low-text">
                  {moment(transaction.purchase_date)
                    .tz('America/Los_Angeles')
                    .format('YYYY-MM-DD hh:mm z')}
                </td>
                <td className="low-text">{transaction.purchase_price}</td>
                <td className="low-text">
                  {moment(transaction.next_billing_date)
                    .tz('America/Los_Angeles')
                    .format('YYYY-MM-DD hh:mm z')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Teacher Transactions of {this.state.user_name}</div>
        </div>
        {this.render_single_transactions()}
        {this.render_subscriptions()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Teacher Transactions</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(TeacherTransactions);
