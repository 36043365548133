import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import {
  image_error_replace,
  check_null_text,
  check_user_is_from_back_pain
} from '../../../util_functions';
import { iana_values, day_format, time_format } from '../../../constants';
import CustomSingleSelect from '../../util_components/custom_single_select';
import Paginator from '../../util_components/paginator';
import CustomDatePicker from '../../util_components/custom_date_picker';
import * as actions from '../../../redux/action_creators';
import { post_api } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';

class PastGroupSessions extends Component {
  state = {
    group_sessions: [],
    filtered_group_sessions: [],
    group_sessions_loading: true,
    filter_name: 'all',
    filter_time: '',
    filter_teacher: 'all',
    open_download: false,
    data_loading: false,
    download_data: [],
    download_start: '',
    download_end: ''
  };

  componentDidMount() {
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
    if (this.props.past_group_sessions_status === 'none') {
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.props.get_past_group_sessions(payload);
    }
    if (this.props.past_group_sessions_status === 'success') {
      this.set_group_sessions();
    }
    if (this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (this.props.class_types_status === 'none') {
      this.props.get_class_types();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    if (
      prevProps.past_group_sessions_status === 'loading' &&
      this.props.past_group_sessions_status === 'success'
    ) {
      this.set_group_sessions();
    }
    if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
      this.set_session_names();
    }
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.set_session_names();
    }

    if (prevProps.past_group_sessions !== this.props.past_group_sessions) {
      this.set_group_sessions();
    }
    if (prevProps.location.pathname.toString() !== this.props.location.pathname.toString()) {
      this.props.set_sessions_variable('past_group_sess_curr_page', 0);
      const payload = {
        is_exclusive_for_backpain_client: check_user_is_from_back_pain()
      };
      this.setState({ filter_teacher: 'all', filter_name: 'all', filter_time: '' }, () => {
        this.props.get_past_group_sessions(payload);
      });
    }
  }

  set_session_names = () => {
    const group_session_values = this.props.class_types;
    const group_session_names = [];
    for (const sess of group_session_values) {
      group_session_names.push({ value: sess.uuid, label: sess.name });
    }
    const grp_sess_name_filters = [...group_session_names];
    grp_sess_name_filters.sort((a, b) => a.label.localeCompare(b.label));
    grp_sess_name_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ grp_sess_name_filters });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter(
      (t) => t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY'
    );
    const grp_sess_teach_filters = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    grp_sess_teach_filters.sort((a, b) => a.label.localeCompare(b.label));
    grp_sess_teach_filters.splice(0, 0, { value: 'all', label: 'All' });
    this.setState({ loading: false, grp_sess_teach_filters });
  };

  set_group_sessions = () =>
    this.setState(
      { group_sessions: [...this.props.past_group_sessions], group_sessions_loading: false },
      () => {
        this.filter_sessions();
      }
    );

  filter_sessions = () => {
    let { filter_name, filter_time, filter_teacher, group_sessions } = this.state;
    if (filter_teacher === 'all') {
      filter_teacher = '';
    }
    if (filter_name === 'all') {
      filter_name = '';
    }
    const tea_fil_sess = group_sessions.filter(
      (sess) => sess.teacher_uuid.indexOf(filter_teacher) !== -1
    );
    // let name_fil_sess = tea_fil_sess.filter(sess => sess.group_session_name.toLowerCase().indexOf(filter_name.toLowerCase()) !== -1)
    const name_fil_sess = tea_fil_sess.filter(
      (sess) => sess.class_type_uuid.indexOf(filter_name) !== -1
    );
    let date_fil_sess = [];
    if (filter_time) {
      const sel_date = moment(filter_time).format('YYYY-MM-DD');
      date_fil_sess = name_fil_sess.filter(
        (sess) =>
          moment(sess.epoch_start_time).tz(this.props.iana_timezone).format('YYYY-MM-DD') ===
          sel_date
      );
    } else {
      date_fil_sess = name_fil_sess;
    }
    this.setState({ group_sessions_loading: false, filtered_group_sessions: [...date_fil_sess] });
  };

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.session_uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher_photo} onError={image_error_replace} alt="x" />
              {session.teacher_name.split(' ')[0]}
            </div>
          </a>
        </div>
        <div className="ct-col ct-sm-font">
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(day_format)}
          </div>
          <div>
            {moment(session.epoch_start_time).tz(this.props.iana_timezone).format(time_format)}
          </div>
          <div>{`(${session.duration} mins)`}</div>
        </div>
        <div className="ct-col ct-sm-font">{session.group_session_name}</div>
        {/* <div className="ct-col ct-qf ct-sm-font">{session.participants}</div>
                <div className="ct-col ct-qf ct-sm-font">{session.joined_participants}</div>
                <div className="ct-col ct-qf ct-sm-font">{check_null_text(session.members_participants)}</div>
                <div className="ct-col ct-qf ct-sm-font">{check_null_text(session.non_members_participants_after_first_10_days)}</div>
                <div className="ct-col ct-qf ct-sm-font">{check_null_text(session.non_members_participants_within_first_10_days)}</div> */}
        <div className="ct-col ct-3rdf  ct-sm-font">{session.participants}</div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.members_participants)}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {check_null_text(session.non_member_participants)}
        </div>

        <div className="ct-col ct-3rdf  ct-sm-font">
          {session.session_status === 'FINISHED' ? session.joined_members : '--'}
        </div>
        <div className="ct-col ct-3rdf  ct-sm-font">
          {session.session_status === 'FINISHED' ? session.joined_non_members : '--'}
        </div>

        <div className="ct-col ct-hf ct-sm-font ct-bw">
          {check_null_text(session.video_recording_status)}
        </div>
        <div className="ct-col">
          <Button onClick={() => this.open_session_viewer(session.session_uuid)}>Details</Button>
        </div>
      </div>
    );
  };

  render_group_sessions = (sessions) => (
    <div className="cus-table" style={{ marginBottom: '20px' }}>
      <div className="ct-row ct-h">
        <div className="ct-col">Teacher</div>
        <div className="ct-col">
          <div>Time</div>
          <div className="ct-sm-font">(Dur)</div>
        </div>
        <div className="ct-col">Name</div>
        {/* <div className="ct-col ct-5qf">
                        <div>Participants</div>
                        <div style={{ display: 'flex' }} className="ct-sm-font">
                            <div className="ct-col ct-sm-font">R.</div>
                            <div className="ct-col ct-sm-font">J.</div>
                            <div className="ct-col ct-sm-font">M.</div>
                            <div className="ct-col ct-sm-font">NM.</div>
                            <div className="ct-col ct-sm-font">{`NM.<10`}</div>
                        </div>
                    </div> */}
        <div className="ct-col ct-3rdf ct-sm-font">
          Total <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          Members <span className="ct-vsm-font">Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          NM <span className="ct-vsm-font"> Booked</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          Mem <span className="ct-vsm-font">Joined</span>
        </div>
        <div className="ct-col ct-3rdf ct-sm-font">
          NM <span className="ct-vsm-font">Joined</span>
        </div>
        <div className="ct-col ct-hf">Rec.</div>
        <div className="ct-col">Det.</div>
      </div>
      {sessions.map(this.render_single_session)}
    </div>
  );

  changePage = (page) => {
    const payload = {
      is_exclusive_for_backpain_client: check_user_is_from_back_pain(),
      page
    };
    this.props.get_past_group_sessions(payload);
  };

  render_sessions = () => {
    const sessions = this.state.filtered_group_sessions;
    const private_sessions = sessions.filter((sess) => sess.is_private_group_session === 1);
    const public_sessions = sessions.filter((sess) => sess.is_private_group_session === 0);
    return (
      <div>
        {private_sessions.length !== 0 ? (
          <>
            {`Private Sessions (${private_sessions.length}):`}
            {this.render_group_sessions(private_sessions)}
          </>
        ) : null}
        {public_sessions.length !== 0 ? (
          <>
            {`Public Sessions (${public_sessions.length}):`}
            {this.render_group_sessions(public_sessions)}
          </>
        ) : null}
        <Paginator
          curr_page={this.props.past_group_sess_curr_page}
          page_limit={this.props.past_group_sess_page_limit}
          pageChange={this.changePage}
        />
      </div>
    );
  };

  go_scheduled = () => this.props.history.push('/groupsessions');

  onFilterChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value }, () => this.filter_sessions());
  };

  filterDateChange = (date) => {
    this.setState({ filter_time: date }, () => this.filter_sessions());
  };

  render_filters = () => {
    const {
      filter_name,
      filter_time,
      filter_teacher,
      grp_sess_name_filters,
      grp_sess_teach_filters,
      filtered_group_sessions
    } = this.state;
    const download_sessions = filtered_group_sessions.map((s) => {
      const new_s = {
        ...s,
        start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('MMM DD hh:mm A z')
      };
      return new_s;
    });
    const file_name = `Past_group_sessions_${moment().format('YYYY-MM-DD')}`;
    return (
      <div>
        Filters:
        <div style={{ margin: '10px' }} className="display-flex-between">
          <CustomSingleSelect
            label="Filter by teacher"
            options={grp_sess_teach_filters}
            name="filter_teacher"
            onChange={this.onFilterChange}
            value={filter_teacher}
            style={{ width: '250px' }}
          />
          <div>
            <div className="custom-single-select-label">Filter by date:</div>
            <DatePicker
              selected={filter_time}
              onChange={(date) => this.filterDateChange(date)}
              isClearable
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <CustomSingleSelect
            label="Filter by name"
            options={grp_sess_name_filters}
            onChange={this.onFilterChange}
            name="filter_name"
            value={filter_name}
            style={{ width: '250px' }}
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={download_sessions} name="Sessions">
              <ExcelColumn label="Teacher" value="teacher_name" />
              <ExcelColumn label="Start Time" value="start_time" />
              <ExcelColumn label="Status" value="session_status" />
              <ExcelColumn label="Name" value="group_session_name" />
              <ExcelColumn label="Target Audience" value="group_session_target_audience" />
              <ExcelColumn label="Duration" value="duration" />
              <ExcelColumn label="Total Participants" value="participants" />
              <ExcelColumn label="Members" value="members_participants" />
              <ExcelColumn
                label="Non Members"
                value="non_members_participants_after_first_10_days"
              />
              <ExcelColumn
                label="Non Members (Trial)"
                value="non_members_participants_within_first_10_days"
              />
              <ExcelColumn label="Non Members (Total)" value="non_member_participants" />
              <ExcelColumn label="Non Members (Limit)" value="max_non_member_participants" />
              <ExcelColumn label="Limit" value="group_session_limit" />
              <ExcelColumn label="Session UUID" value="session_uuid" />
            </ExcelSheet>
          </ExcelFile>
        </div>
      </div>
    );
  };

  open_download = () => this.setState({ open_download: true });

  close_download = () => this.setState({ open_download: false });

  generate_data = () => {
    const { download_start, download_end } = this.state;
    // let sel_start_time = moment(download_start).format('YYYY-MM-DD HH:mm:ss')
    const sel_start_time = `${moment(download_start).format('YYYY-MM-DD')} 00:00:00`;
    const epoch_start_time = moment
      .tz(sel_start_time, this.props.iana_timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    // const sel_end_time = moment(download_end).format('YYYY-MM-DD HH:mm:ss')
    const sel_end_time = `${moment(download_end).format('YYYY-MM-DD')} 23:59:59`;
    const epoch_end_time = moment
      .tz(sel_end_time, this.props.iana_timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const payload = { start_time: epoch_start_time, end_time: epoch_end_time };
    const url = get_api_url('download_past_group_data');
    this.setState({ data_loading: true }, () => {
      post_api(url, payload, true)
        .then((response) => {
          this.props.set_notification_variable(true, 'success', 'Data generated');
          this.setState({ download_data: [...response.data.sessions], data_loading: false });
        })
        .catch((e) => {
          console.log(e);
          this.props.set_notification_variable(
            true,
            'error',
            'Some error occured. Please contact care team.'
          );
          this.setState({ data_loading: false });
        });
    });
  };

  set_date = (key, value) => {
    this.setState({ [key]: value });
  };

  render_download_modal = () => {
    const { download_data, download_start, download_end } = this.state;
    const iana_index = iana_values.findIndex((a) => a.value === this.props.iana_timezone);
    const iana_value = iana_values[iana_index].label;
    const start_date = moment(download_start).format('MMMDD');
    const end_date = moment(download_end).format('MMMDD');
    const file_name = `Group_sessions_${start_date}_${end_date}_${iana_value}`;
    const final_download_data = download_data.map((s) => {
      const new_s = {
        ...s,
        formatted_start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('MMM DD hh:mm A z'),
        start_time: moment(s.epoch_start_time)
          .tz(this.props.iana_timezone)
          .format('M/D/YYYY hh:mm A z')
      };
      return new_s;
    });
    const btn_style = { marginTop: '25px' };
    return (
      <div>
        <h4>Download group session data</h4>
        <p>Select start date and end date to generate data in {iana_value}</p>
        <div style={{ display: 'flex' }}>
          <CustomDatePicker
            label="Start Date"
            value={download_start}
            handleChange={(val) => this.set_date('download_start', val)}
          />
          <CustomDatePicker
            label="End Date"
            value={download_end}
            handleChange={(val) => this.set_date('download_end', val)}
            minDate={download_start}
          />
          <div>
            <Button
              onClick={this.generate_data}
              disabled={!download_start || !download_end}
              custom_style={btn_style}
            >
              Generate Data
            </Button>
          </div>
        </div>
        {download_data.length > 0 ? (
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={final_download_data} name="Sessions">
              <ExcelColumn label="Teacher" value="teacher_name" />
              <ExcelColumn label="Start Time" value="start_time" />
              <ExcelColumn label="Formatted Start Time" value="formatted_start_time" />
              <ExcelColumn label="Status" value="session_status" />
              <ExcelColumn label="Name" value="group_session_name" />
              <ExcelColumn label="Target Audience" value="group_session_target_audience" />
              <ExcelColumn label="Duration" value="duration" />
              <ExcelColumn label="Total Participants" value="participants" />
              <ExcelColumn label="Members" value="members_participants" />
              <ExcelColumn label="Non Members" value="non_member_participants" />
              <ExcelColumn label="Joined Members" value="joined_members" />
              <ExcelColumn label="Joined Non Members" value="joined_non_members" />
              <ExcelColumn label="Session UUID" value="session_uuid" />
            </ExcelSheet>
          </ExcelFile>
        ) : null}
        <div style={{ height: '300px' }} />
      </div>
    );
  };

  render() {
    return (
      <div className="common-page-container">
        {this.state.group_sessions_loading ||
        this.props.past_group_sessions_status === 'loading' ||
        this.state.data_loading ? (
          <CustomLoading />
        ) : null}
        <div className="common-page-header">
          <div className="common-page-title">
            {check_user_is_from_back_pain() === 1
              ? 'Past Back Pain Group Sessions'
              : 'Past Group Sessions'}
          </div>
        </div>
        <CustomModal show={this.state.open_download} close={this.close_download}>
          {this.render_download_modal()}
        </CustomModal>
        {this.props.access_type !== 'VIDEO_EDITOR' ? (
          <>
            <Button onClick={this.go_scheduled}>Group Sessions</Button>
            <Button onClick={this.open_download}>Download custom data</Button>
          </>
        ) : null}
        <hr />
        {this.state.group_sessions_loading ? null : this.render_filters()}
        {this.render_sessions()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  iana_timezone: state.home.iana_timezone,
  past_group_sessions_status: state.loading.past_group_sessions_status,
  past_group_sessions: state.sessions.past_group_sessions,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  past_group_sess_page_limit: state.sessions.past_group_sess_page_limit,
  past_group_sess_curr_page: state.sessions.past_group_sess_curr_page,
  past_group_sessions_data: state.sessions.past_group_sessions_data,
  class_types: state.home.class_types,
  class_types_status: state.loading.class_types_status,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_past_group_sessions: (payload) => {
    dispatch(actions.get_past_group_sessions(payload));
  },
  get_class_types: () => dispatch(actions.get_class_types()),
  set_sessions_variable: (key, payload) => {
    dispatch(actions.set_sessions_variable(key, payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PastGroupSessions);
