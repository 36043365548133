import * as t from '../action_types';
import { getCookie } from '../../util_functions';

const initialState = {
  cms_token: typeof getCookie('cms_token_3') !== 'undefined' ? getCookie('cms_token_3') : '',
  login_status: 'none',
  login_error_message: '',
  dashboard_status: 'none',
  home_component_index: 0,
  past_sessions: [],
  replacement_sessions: [],
  dashboard_data: '',
  iana_timezone: 'America/Los_Angeles',
  access_pages: [],
  application_width: window.innerWidth,
  access_type: 'NO_ACCESS',
  cms_teacher_uuid: '',
  config_values: {},
  class_types: [],
  health_keywords: [],
  health_keywords_data: [],
  cms_user_name: ''
};

const set_home_variable = (state, payload) => ({
  ...state,
  [payload.key]: payload.payload
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_home_variable:
      return set_home_variable(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
