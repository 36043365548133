import React, { useEffect, useState } from 'react';
import CommonHeader from '../../util_components/common_header';
import { get_api, api_with_method } from '../../../redux/api_funcs';
import { get_api_url } from '../../../utils/urls';
import AllowedParticipants from './components/AllowedParticipants';
import SearchStudent from './components/SearchStudent';
import SearchTeacher from './components/SearchTeacher';

function LiveKitUsers() {
  const [allowedParticipantsData, setAllowedParticipantsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentUUID, setStudentUUID] = useState('');
  const [teacherUUID, setTeacherUUID] = useState('');
  const [errorMSG, setErrorMSG] = useState(false);

  useEffect(() => {
    getAllowedParticipantsData();
  }, []);

  const getAllowedParticipantsData = async () => {
    try {
      const path = `/v2/meetings/cms/participants`;
      const url = get_api_url(path, false, true);
      const res = await get_api(url, true);
      setAllowedParticipantsData(res?.data?.data?.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleSearchStudentUpdate = (newStudentUUID) => {
    setStudentUUID(newStudentUUID);
  };
  const handleSearchTeacherUpdate = (newTeacherUUID) => {
    setTeacherUUID(newTeacherUUID);
  };

  const handleAddParticipants = () => {
    const path = `/v2/meetings/cms/participants`;
    const url = get_api_url(path, false, true);
    const payload = {
      student_uuid: studentUUID,
      teacher_uuid: teacherUUID
    };
    if (studentUUID !== '' && teacherUUID !== '') {
      api_with_method('post', url, payload, true, 'none')
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setErrorMSG(true);
    }
  };

  const handleErrorMessage = () => {
    setErrorMSG(false);
  };

  function AllowedParticipantsTable() {
    const headers = ['Teacher name', 'Student name', 'Action'];
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          {headers.map((header, index) => (
            <div className="ct-col ct-sm-font" key={index}>
              {header}
            </div>
          ))}
        </div>
        <AllowedParticipants allowedParticipantsList={allowedParticipantsData} />
      </div>
    );
  }

  return (
    <div>
      <CommonHeader loading={loading} title="Livekit Users">
        <div className="w-full flex items-center">
          <div className="flex items-end justify-between gap-14 flex-wrap">
            <div>
              <div className="flex items-center gap-7 justify-between flex-wrap">
                <SearchTeacher
                  handleTeacherErrorMSG={handleErrorMessage}
                  onTeacherUpdate={handleSearchTeacherUpdate}
                />
                <SearchStudent
                  handleStudentErrorMSG={handleErrorMessage}
                  onStudentUpdate={handleSearchStudentUpdate}
                />
              </div>
            </div>
            <button className="h-46px" style={{ margin: '0' }} onClick={handleAddParticipants}>
              Add
            </button>
          </div>
        </div>
        <div className="my-5">LiveKit Users : {allowedParticipantsData?.length}</div>
        <div>
          {errorMSG ? (
            <div className="text-mytRed mt-2">
              Please select teacher and student to add participants
            </div>
          ) : null}
        </div>
        <div className="mt-5">
          <AllowedParticipantsTable />
        </div>
      </CommonHeader>
    </div>
  );
}

export default LiveKitUsers;
