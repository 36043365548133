import * as t from '../action_types';

export const set_students_variable = (key, payload) => ({
  type: t.set_students_variable,
  payload: {
    key,
    payload
  }
});

export const filter_students = (key, payload) => ({
  type: t.filter_students,
  payload: {
    key,
    payload
  }
});

export const get_all_students = () => ({
  type: t.get_all_students,
  payload: null
});

export const cud_membership_pause = (payload) => ({
  type: t.cud_membership_pause,
  payload
});

export const cud_membership = (payload) => ({
  type: t.cud_membership,
  payload
});

export const search_students = (payload) => ({
  type: t.search_students,
  payload
});
