import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import CustomLoading from '../../util_components/custom_loading';
import CustomInput from '../../util_components/custom_input';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomCheckbox from '../../util_components/custom_checkbox';
import Button from '../../util_components/button';
import UploadImage from '../../util_components/upload_image';
import { get_api, post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';
import 'react-quill/dist/quill.snow.css';
import CustomModal from '../../util_components/custom_modal';
import ImageCropComponent from './ImageCrop';

const choice_active = [
  { label: 'True', value: 1 },
  { label: 'False', value: 0 }
];

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

const gc_class_intensity = [
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' }
];

class CreateEditClassPage extends Component {
  state = {
    loading: false,
    name: '',
    slug: '',
    page_uuid: '',
    description: '',
    is_event: 0,
    goals: [],
    class_type_uuid: '',
    teachers: [],
    regular_teachers_count: 0,
    class_time: null,
    list_thumbnail: '',
    banner_image: '',
    og_image: '',
    video_url: '',
    video_thumbnail_url: '',
    class_types: [],
    all_goals: [],
    all_teachers: [],
    visible_to_members: 1,
    visible_to_non_members: 1,
    visible_to_trial_users: 1,
    hide_from_newbie_trial: 0,
    hide_from_beginner_trial: 0,
    is_exclusive_for_backpain_client: 0,
    easy_filters: [],
    all_easy_filters: [],
    faq_values: '',
    description_unauth: '',
    whos_for: '',
    whos_not_for: '',
    how_will_help: '',
    how_will_not_help: '',
    intensity: '',
    relaxation: '',
    stretching: '',
    yoga_level: [],
    group_class_page_yoga_levels: [],
    all_group_class_page_categories: [],
    categories: [],
    show_image_crop_modal: false
  };

  componentDidMount() {
    const all_goals = [...this.props.config_values.yoga_goals];
    const all_easy_filters = [...this.props.config_values.easy_filters];
    const group_class_page_yoga_levels = [...this.props.config_values.group_class_page_yoga_levels];

    const teachers = this.props.teachers_list
      .filter((t) => t.onboarding_status === 'APPROVED')
      .map((t) => ({ value: t.uuid, label: `${t.first_name} ${t.last_name}` }));
    teachers.sort((a, b) => a.label.localeCompare(b.label));
    const class_types = this.props.class_types.map((c) => ({
      label: c.name,
      value: c.uuid
    }));
    if (this.props.edit_class) {
      const details = this.props.page_details;
      const selected_easy_filters = this.props.selected_easy_filters.map((item) => item.slug);
      const teachers_uuids = details.teachers ? details.teachers : [];
      const final_teachers = teachers.filter((t) => teachers_uuids.indexOf(t.value) === -1);
      const final_all_teachers = teachers.filter((t) => teachers_uuids.indexOf(t.value) !== -1);
      const selected_yoga_level = details.yoga_level;
      const yoga_level = group_class_page_yoga_levels.find(
        (level) => level.value === selected_yoga_level
      );

      this.setState({
        class_type_uuid: details.class_type_uuid,
        name: details.name,
        slug: details.slug,
        page_uuid: details.uuid,
        description: details.description,
        description_unauth: details?.description_unauth || '',
        goals: details.goals ? details.goals : [],
        teachers: final_all_teachers,
        all_teachers: final_teachers,
        regular_teachers_count: parseInt(details.regular_teachers_count),
        class_time: details.class_time,
        list_thumbnail: details.list_thumbnail,
        banner_image: details.banner_image,
        og_image: details?.og_image,
        video_url: details.video_url,
        video_thumbnail_url: details.video_thumbnail_url,
        class_types,
        is_event: details?.is_event,
        all_goals,
        visible_to_members: details.visible_to_members,
        visible_to_non_members: details.visible_to_non_members,
        visible_to_trial_users: details.visible_to_trial_users,
        hide_from_newbie_trial: details.hide_from_newbie_trial,
        hide_from_beginner_trial: details.hide_from_beginner_trial,
        all_easy_filters,
        easy_filters: selected_easy_filters,
        whos_for: details.whos_for,
        whos_not_for: details.whos_not_for,
        how_will_help: details.how_will_help,
        how_will_not_help: details.how_will_not_help,
        stretching: details.stretching,
        intensity: details.intensity,
        relaxation: details.relaxation,
        yoga_level: yoga_level?.label || '',
        categories: details.categories || [],
        group_class_page_yoga_levels
      });
    } else {
      this.setState({
        all_teachers: teachers,
        class_types,
        all_goals,
        group_class_page_yoga_levels
      });
    }
  }

  load_easy_filters = () => {
    this.setState({ loading: true }, () => {
      const payload = { class_page_uuid: this.state.class_type_uuid };
      const url = get_api_url('get_all_easy_filters');
      get_api(url, true)
        .then((res) => {
          const temp_easy_filters = res.data;
          const array_of_easy_filter = Object.keys(temp_easy_filters).map(
            (key) => temp_easy_filters[key]
          );
          const easy_filters = array_of_easy_filter.map((item) => item.name);
          this.setState({
            all_easy_filters: easy_filters,
            loading: false
          });
        })
        .catch((e) => {
          console.log('e: ', e);
          this.setState({ loading: false });
        });
    });
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    if (key === 'class_type_uuid') {
      const class_type = this.props.class_types.find((c) => c.uuid === value);
      this.setState({
        [key]: value,
        name: class_type.name,
        description: class_type.description,
        goals: class_type.goals,
        is_exclusive_for_backpain_client: class_type.is_exclusive_for_backpain_client
      });
    } else this.setState({ [key]: value });
  };

  on_teacher_click = (e) => {
    const { all_teachers, teachers } = this.state;
    const teacher_index = all_teachers.findIndex((t) => t.value === e.target.value);
    const final_all_teachers = [
      ...all_teachers.slice(0, teacher_index),
      ...all_teachers.slice(teacher_index + 1)
    ];
    const final_teachers = [...teachers, all_teachers[teacher_index]];
    this.setState({
      all_teachers: final_all_teachers,
      teachers: final_teachers
    });
  };

  remove_teacher = (teac) => {
    const { all_teachers, teachers } = this.state;
    const teacher_index = teachers.findIndex((t) => t.value === teac.value);
    const final_teachers = [
      ...teachers.slice(0, teacher_index),
      ...teachers.slice(teacher_index + 1)
    ];
    const final_all_teachers = [...all_teachers, teac].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    this.setState({
      all_teachers: final_all_teachers,
      teachers: final_teachers
    });
  };

  onCheckClick = (value, key) => {
    const val_arr = [...this.state[key]];
    const index = val_arr.indexOf(value);
    if (index === -1) val_arr.push(value);
    else val_arr.splice(index, 1);
    this.setState({ [key]: [...val_arr] });
  };

  onEasyFilterCheckClick = (value, key) => {
    const val_arr = [...this.state[key]];
    const index = val_arr.indexOf(value);
    if (index === -1) {
      val_arr.push(value);
    } else {
      val_arr.splice(index, 1);
    }
    this.setState({ [key]: [...val_arr] });
  };

  onCategoriesClick = (value, key) => {
    const val_arr = [...this.state[key]];
    const index = val_arr.indexOf(value);
    if (index === -1) {
      val_arr.push(value);
    } else {
      val_arr.splice(index, 1);
    }
    this.setState({ [key]: [...val_arr] });
  };

  render_check_boxes = (title, all_values, curr_values, state_text) => (
    <>
      <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
        {title}
      </div>
      <div className="group-class-goals">
        {all_values.map((value) => {
          const selected = curr_values.indexOf(value) !== -1;
          const custom_style = { marginRight: '10px', flexShrink: '0' };
          return (
            <CustomCheckbox
              key={value}
              label={value}
              checked={selected}
              onClick={() => this.onCheckClick(value, state_text)}
              custom_style={custom_style}
            />
          );
        })}
      </div>
    </>
  );

  render_check_boxes_for_easy_filter = (title, all_values, curr_values, state_text) => (
    <>
      <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
        {title}
      </div>
      <div className="group-class-goals">
        {this.props?.config_values?.easy_filters?.map(({ name: value, slug }) => {
          const selected = curr_values.indexOf(slug) !== -1;
          const custom_style = { marginRight: '10px', flexShrink: '0' };
          return (
            <CustomCheckbox
              key={value}
              label={value}
              checked={selected}
              onClick={() => this.onEasyFilterCheckClick(slug, state_text)}
              custom_style={custom_style}
            />
          );
        })}
      </div>
    </>
  );

  render_check_boxes_for_categories = (title, all_value, curr_vlaues, state_text) => (
    <>
      <div style={{ marginTop: '10px' }} className="create-group-class-name-edit">
        {title}
      </div>
      <div className="group-class-goals">
        {this.props?.config_values?.group_class_page_categories?.map(({ label, value }) => {
          const selected = curr_vlaues.indexOf(value) !== -1;
          const custom_style = { marginRight: '10px', flexShrink: '0' };
          return (
            <CustomCheckbox
              key={label}
              label={label}
              checked={selected}
              onClick={() => this.onCategoriesClick(value, state_text)}
              custom_style={custom_style}
            />
          );
        })}
      </div>
    </>
  );

  render_custom_input = (label, name, value, style, label_style, input_style) => (
    <CustomInput
      label={label}
      onChange={this.onCommonChange}
      name={name}
      value={value}
      style={style}
      label_style={label_style}
      input_style={input_style}
    />
  );

  render_video_thumbnail_url = (label, name, value, style, label_style, input_style) => (
    <CustomInput
      label={label}
      onChange={this.onCommonChange}
      name={name}
      value={value}
      style={style}
      label_style={label_style}
      input_style={input_style}
    />
  );

  render_selected_teachers = () => {
    const { teachers } = this.state;
    if (teachers.length > 0) {
      return (
        <div className="">
          <div className="cre-class-teachers-title">Teachers selected:</div>
          <div className="cre-class-teachers">
            {teachers.map((teac) => (
              <div className="cre-class-teac" key={teac.value}>
                {teac.label}
                <img
                  src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                  onClick={() => this.remove_teacher(teac)}
                  alt="x"
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  start_loading = () => this.setState({ loading: true });

  stop_loading = () => this.setState({ loading: false });

  submit_page_details = () => {
    const {
      name,
      slug,
      description,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      class_type_uuid,
      is_event,
      goals,
      teachers,
      list_thumbnail,
      banner_image,
      og_image,
      video_url,
      video_thumbnail_url,
      page_uuid,
      hide_from_newbie_trial,
      hide_from_beginner_trial,
      is_exclusive_for_backpain_client,
      faq_values,
      easy_filters,
      description_unauth,
      whos_for,
      whos_not_for,
      how_will_help,
      how_will_not_help,
      intensity,
      relaxation,
      stretching,
      categories,
      yoga_level
    } = this.state;
    const final_teachers = teachers.map((t) => t.value);
    const payload = {
      slug,
      name,
      description,
      regular_teachers_count: final_teachers.length,
      class_type_uuid,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      is_event,
      goals,
      list_thumbnail,
      banner_image,
      og_image,
      video_url,
      video_thumbnail_url,
      teachers: final_teachers,
      hide_from_newbie_trial,
      hide_from_beginner_trial,
      is_exclusive_for_backpain_client,
      easy_filters,
      description_unauth,
      whos_for,
      whos_not_for,
      how_will_help,
      how_will_not_help,
      intensity,
      relaxation,
      stretching,
      categories,
      yoga_level
    };
    if (this.props.edit_class) {
      payload.page_uuid = page_uuid;
      // payload.is_active = is_active;
    }
    this.props.submit_change(payload);
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  change_input = (field_name, value) => {
    this.setState({ [field_name]: value });
  };

  handleFaqChange = (whos_for) => {
    this.setState({ whos_for });
  };

  handleFaqChangeWhosNotFor = (whos_not_for) => {
    this.setState({ whos_not_for });
  };

  handleFaqChangeHowWillHelp = (how_will_help) => {
    this.setState({ how_will_help });
  };

  handleFaqChangeHowWillNotHelp = (how_will_not_help) => {
    this.setState({ how_will_not_help });
  };

  clear_faqs_whos_for = () => {
    this.setState({ whos_for: '' }, () => {
      this.setState({ whos_for: this.state.whos_for.replace('<p><br></p>', '') });
    });
  };

  clear_faqs_whos_not_for = () => {
    this.setState({ whos_not_for: '' }, () => {
      this.setState({ whos_not_for: this.state.whos_not_for.replace('<p><br></p>', '') });
    });
  };

  clear_faqs_how_will_help = () => {
    this.setState({ how_will_help: '' }, () => {
      this.setState({ how_will_help: this.state.how_will_help.replace('<p><br></p>', '') });
    });
  };

  clear_faqs_how_will_not_help = () => {
    this.setState({ how_will_not_help: '' }, () => {
      this.setState({ how_will_not_help: this.state.how_will_not_help.replace('<p><br></p>', '') });
    });
  };

  render_faq_editor = () => {
    const { whos_for, whos_not_for, how_will_help, how_will_not_help } = this.state;
    return (
      <div>
        <div className="flex flex-col mt-4">
          <div>
            <div className="text-sm text-mytLightGray">Who it’s for</div>
            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              theme="snow"
              value={whos_for}
              onChange={this.handleFaqChange}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['clean']
                  ]
                }
              }}
              formats={formats}
            />
          </div>
          <div>
            <Button onClick={() => this.clear_faqs_whos_for()}>Clear</Button>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <div>
            <div className="text-sm text-mytLightGray">Who it’s not for</div>
            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              theme="snow"
              value={whos_not_for}
              onChange={this.handleFaqChangeWhosNotFor}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['clean']
                  ]
                }
              }}
              formats={formats}
            />
          </div>
          <div>
            <Button onClick={() => this.clear_faqs_whos_not_for()}>Clear</Button>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <div>
            <div className="text-sm text-mytLightGray">How it will help you</div>
            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              theme="snow"
              value={how_will_help}
              onChange={this.handleFaqChangeHowWillHelp}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['clean']
                  ]
                }
              }}
              formats={formats}
            />
          </div>
          <div>
            <Button onClick={() => this.clear_faqs_how_will_help()}>Clear</Button>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <div>
            <div className="text-sm text-mytLightGray">How it will not help you</div>
            <ReactQuill
              ref={(el) => {
                this.quill = el;
              }}
              theme="snow"
              value={how_will_not_help}
              onChange={this.handleFaqChangeHowWillNotHelp}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['clean']
                  ]
                }
              }}
              formats={formats}
            />
          </div>
          <div>
            <Button onClick={() => this.clear_faqs_how_will_not_help(how_will_not_help)}>
              Clear
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render_class_intensity = () => {
    const { intensity, relaxation, stretching } = this.state;

    return (
      <div className="flex flex-col mt-4">
        <div>
          <CustomSingleSelect
            label="Intensity"
            options={gc_class_intensity}
            name="intensity"
            onChange={this.onCommonChange}
            value={intensity}
            style={{ width: '250px' }}
          />
        </div>
        <div>
          <CustomSingleSelect
            label="Stretching"
            options={gc_class_intensity}
            name="stretching"
            onChange={this.onCommonChange}
            value={stretching}
            style={{ width: '250px' }}
          />
        </div>
        <div>
          <CustomSingleSelect
            label="Relaxation"
            options={gc_class_intensity}
            name="relaxation"
            onChange={this.onCommonChange}
            value={relaxation}
            style={{ width: '250px' }}
          />
        </div>
      </div>
    );
  };

  render_image_crop = () => <ImageCropComponent />;

  toggel_crop_image_modal = () =>
    this.setState({ show_image_crop_modal: !this.state.show_image_crop_modal });

  render() {
    const {
      name,
      slug,
      description,
      class_types,
      regular_teachers_count,
      class_type_uuid,
      is_event,
      goals,
      all_goals,
      all_teachers,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users,
      list_thumbnail,
      banner_image,
      og_image,
      video_url,
      video_thumbnail_url,
      loading,
      hide_from_newbie_trial,
      hide_from_beginner_trial,
      all_easy_filters,
      easy_filters,
      description_unauth,
      yoga_level,
      categories,
      all_group_class_page_categories,
      group_class_page_yoga_levels,
      show_image_crop_modal
    } = this.state;
    const label_style = { color: '#aaa', fontSize: '12px', fontWeight: '600' };
    const input_style = {
      margin: '0',
      marginTop: '5px',
      marginBottom: '10px',
      fontSize: '14px',
      height: 'auto'
    };
    return (
      <div className="create-group-class-name">
        {loading ? <CustomLoading /> : null}
        <h4>Create Class Page</h4>
        <CustomSingleSelect
          label="Select a class type *"
          options={class_types}
          name="class_type_uuid"
          onChange={this.onCommonChange}
          value={class_type_uuid}
          style={{ width: '250px' }}
          labelStyle={label_style}
        />
        {class_type_uuid ? (
          <div>
            <div className="display-flex-between">
              {this.render_custom_input(
                'Enter Id *',
                'slug',
                slug,
                { width: '250px' },
                label_style,
                input_style
              )}
              {this.render_custom_input(
                'Enter name *',
                'name',
                name,
                { width: '250px' },
                label_style,
                input_style
              )}
            </div>
            <div className="create-group-class-name-edit">
              Enter short description about the class*:
            </div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
            />
            <div className="create-group-class-name-edit">
              Enter additional info for logged out state:
            </div>
            <textarea
              style={{ width: '100%', height: '200px' }}
              value={description_unauth}
              name="description_unauth"
              onChange={this.onCommonChange}
            />
            {this.render_faq_editor()}
            {this.render_class_intensity()}
            <div className="display-flex-between">
              <CustomSingleSelect
                label="Select target audience"
                options={group_class_page_yoga_levels}
                onChange={this.onCommonChange}
                name="yoga_level"
                value={yoga_level}
                style={{ width: '250px' }}
              />
            </div>
            <div className="display-flex-between">
              <CustomSingleSelect
                label="Add teachers"
                options={all_teachers}
                name="teacher_selected"
                onChange={this.on_teacher_click}
                value=""
                style={{ width: '250px' }}
                labelStyle={label_style}
              />
            </div>
            {this.render_selected_teachers()}

            {this.render_check_boxes('Select Goals:', all_goals, goals, 'goals')}
            {this.render_check_boxes_for_easy_filter(
              'Select Easy Filters:',
              all_easy_filters,
              easy_filters,
              'easy_filters'
            )}
            {this.render_check_boxes_for_categories(
              'Select Categories:',
              all_group_class_page_categories,
              categories,
              'categories'
            )}

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="Visible to Members"
                checked={visible_to_members}
                onClick={() => this.toggle_check_box('visible_to_members')}
              />
            </div>

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="Visible to Non Members"
                checked={visible_to_non_members}
                onClick={() => this.toggle_check_box('visible_to_non_members')}
              />
            </div>

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="Visible to Trial Users"
                checked={visible_to_trial_users}
                onClick={() => this.toggle_check_box('visible_to_trial_users')}
              />
            </div>

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="Hide from newbie trial Users"
                checked={hide_from_newbie_trial}
                onClick={() => this.toggle_check_box('hide_from_newbie_trial')}
              />
            </div>

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="Hide from beginner trial Users"
                checked={hide_from_beginner_trial}
                onClick={() => this.toggle_check_box('hide_from_beginner_trial')}
              />
            </div>

            <div style={{ margin: '10px 0' }}>
              <CustomCheckbox
                label="It is an event"
                checked={is_event}
                onClick={() => this.toggle_check_box('is_event')}
              />
            </div>

            <div className="display-flex-between">
              <UploadImage
                buttonTitle={
                  list_thumbnail ? 'Edit List Thumbnail Image' : 'Add List Thumbnail Image'
                }
                imageUrl={list_thumbnail}
                uuid={this.state.page_uuid}
                set_notification_variable={this.props.set_notification_variable}
                close={this.props.close}
                get_all_workshops={this.props.get_all_workshops}
                folder_name="group-classes"
                image_type="list_thumbnail"
                change_input={this.change_input}
                start_loading={this.start_loading}
                stop_loading={this.stop_loading}
              />
            </div>
            <div className="display-flex-between">
              <UploadImage
                buttonTitle={banner_image ? 'Edit Banner Image' : 'Add Banner Image'}
                imageUrl={banner_image}
                uuid={this.state.page_uuid}
                set_notification_variable={this.props.set_notification_variable}
                close={this.props.close}
                get_all_workshops={this.props.get_all_workshops}
                folder_name="group-classes"
                change_input={this.change_input}
                image_type="banner_image"
                start_loading={this.start_loading}
                stop_loading={this.stop_loading}
              />
            </div>
            <div className="display-flex-between">
              <UploadImage
                buttonTitle={og_image ? 'Edit OG Image' : 'Add OG Image'}
                imageUrl={og_image}
                uuid={this.state.page_uuid}
                set_notification_variable={this.props.set_notification_variable}
                close={this.props.close}
                get_all_workshops={this.props.get_all_workshops}
                folder_name="group-classes"
                change_input={this.change_input}
                image_type="og_image"
                start_loading={this.start_loading}
                stop_loading={this.stop_loading}
              />
            </div>
            {this.render_custom_input(
              'Video Url',
              'video_url',
              video_url,
              { width: '100%', marginTop: '5px' },
              label_style,
              input_style
            )}
            {this.render_video_thumbnail_url(
              'Video Thumbnail Url',
              'video_thumbnail_url',
              video_thumbnail_url,
              { width: '100%', marginTop: '5px' },
              label_style,
              input_style
            )}
            <Button disabled={!name || !slug || !description} onClick={this.submit_page_details}>
              Submit
            </Button>
          </div>
        ) : null}
        {/* <CustomModal isFullWidth={true} show={show_image_crop_modal}  close={this.toggel_crop_image_modal}>
              {this.render_image_crop()}
        </CustomModal> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  config_status: state.loading.config_status,
  config_values: state.home.config_values,
  teachers_list: state.teachers.teachers_list
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditClassPage);
