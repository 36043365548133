import React from 'react';
import { Helmet } from 'react-helmet';
import CustomLoading from '../util_components/custom_loading';
import butter from './butter-client';

export default class extends React.Component {
  state = {
    loading: true,
    fields: {}
  };

  async componentDidMount() {
    try {
      const resp = await butter.page.retrieve('*', 'home-page');
      this.setState({ fields: resp.data.data.fields, loading: false });
    } catch (e) {
      console.log(e);
      this.props.history.push('/');
    }
  }

  render_seo = () => {
    const { fields } = this.state;
    const { seo } = fields;
    return (
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.meta_description} />
        {/* <meta name='og:image' content={post.featured_image} /> */}
      </Helmet>
    );
  };

  render_title = () => {
    const { fields } = this.state;
    const { title } = fields;
    return <h4>{title.title}</h4>;
  };

  open_post = (slug) => this.props.history.push(`/blog/${slug}`);

  render_posts = () => {
    const { fields } = this.state;
    const all_posts = fields.posts;
    return (
      <div className="post-list">
        {all_posts.post.map((post) => (
          <div className="post-single" onClick={() => this.open_post(post.slug)} key={post.slug}>
            <h4>{post.title}</h4>
            {post.display_image ? <img src={post.display_image} /> : null}
          </div>
        ))}
      </div>
    );
  };

  render_content = () => {
    const { fields } = this.state;
    console.log(fields);
    return (
      <div>
        {fields.seo ? this.render_seo() : null}
        {fields.title ? this.render_title() : null}
        {fields.posts ? this.render_posts() : null}
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <CustomLoading />;
    }
    return this.render_content();
  }
}
