import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import { time_day_format } from '../../../../constants';
import { image_error_replace } from '../../../../util_functions';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import * as actions from '../../../../redux/action_creators';

class CancelledSessions extends Component {
  state = {
    loading: false,
    cancelled_sessons: [],
    sort_val: true
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_cancelled_sessions');
      post_api(url, null, true).then((res) => {
        this.setState({ loading: false, cancelled_sessons: [...res.data.sessions] });
      });
    });
  };

  go_to_sessions = (id) => {
    const search = `?id=${id}`;
    this.props.history.push({
      pathname: '/sessiondetails',
      search
    });
  };

  render_single_session = (session) => {
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    return (
      <div className="ct-row" key={session.uuid}>
        <div className="ct-col">
          <a className="link-no-dec" href={teacher_url}>
            <div className="profile-img-name">
              <img src={session.teacher.profile_photo} onError={image_error_replace} alt="x" />
              {session.teacher.first_name} {session.teacher.last_name}
            </div>
          </a>
        </div>
        <div className="ct-col">
          {moment(session.start_time).tz(this.props.iana_timezone).format(time_day_format)}
        </div>
        <div className="ct-col">{session.reason_for_cancel_reschedule}</div>
        <div className="ct-col">
          <button onClick={() => this.go_to_sessions(session.uuid)}>Details</button>
        </div>
      </div>
    );
  };

  sort_teachers = () => {
    const sessions = [...this.state.cancelled_sessons];
    sessions.sort((a, b) => {
      if (this.state.sort_val) {
        return a.teacher.first_name.localeCompare(b.teacher.first_name);
      }
      return b.teacher.first_name.localeCompare(a.teacher.first_name);
    });
    this.setState({ cancelled_sessons: [...sessions], sort_val: !this.state.sort_val });
  };

  render_sessions = () => {
    const sessions = this.state.cancelled_sessons;
    if (sessions.length === 0) {
      return <div>No cancelled sessions available in next 12 hrs</div>;
    }
    const teachers = sessions.map((s) => s.teacher_uuid);
    const uniq_teachers = [...new Set(teachers)];
    const val = `Sessions: ${sessions.length},   Teachers: ${uniq_teachers.length} `;
    return (
      <div>
        <h4>{val}</h4>
        <div className="cus-table">
          <div className="ct-row">
            <div className="ct-col" style={{ cursor: 'pointer' }} onClick={this.sort_teachers}>
              Teacher
            </div>
            <div className="ct-col">Session start time</div>
            <div className="ct-col">Reason</div>
            <div className="ct-col">Session Details</div>
          </div>
          {sessions.map(this.render_single_session)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading || this.state.group_sessions_loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Cancelled Sessions in next 12 hrs</div>
        </div>
        {!this.state.loading ? this.render_sessions() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelledSessions);
