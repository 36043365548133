import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { time_format, day_format_year } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class Notifications extends Component {
  state = {
    loading: true,
    notifications: []
  };

  componentDidMount() {
    try {
      const { uuid } = this.props.match.params;
      if (uuid) {
        this.setState({ student_uuid: uuid }, this.load_data());
      }
    } catch (e) {
      this.go_to_path('/users');
    }
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { student_uuid } = this.state;
      const url = get_api_url('get_student_notifications');
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({
            loading: false,
            notifications: [...res.data.notifications]
          });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  go_to_path = (path) => this.props.history.push(path);

  render() {
    const { loading, notifications } = this.state;
    return (
      <CommonHeader loading={loading} show_back title="Student Notifications">
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-hf">Title</div>
            <div className="ct-col">Message</div>
            <div className="ct-col ct-qf">Read</div>
            <div className="ct-col ct-qf">Target</div>
            <div className="ct-col ct-hf">Created</div>
          </div>
          {notifications.map((n) => (
            <div className="ct-row" key={n.uuid}>
              <div className="ct-col ct-sm-font ct-hf">{n.title}</div>
              <div className="ct-col ct-sm-font">{n.message}</div>
              <div className="ct-col ct-si ct-qf">
                {n.read_status === 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>
              <div className="ct-col ct-sm-font ct-qf">{n.target_platform}</div>
              <div className="ct-col ct-sm-font ct-hf">
                <div>
                  {moment(n.created_date).tz(this.props.iana_timezone).format(day_format_year)}
                </div>
                <div>{moment(n.created_date).tz(this.props.iana_timezone).format(time_format)}</div>
              </div>
            </div>
          ))}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
