import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomModal from '../../util_components/custom_modal';
import UploadImage from '../../util_components/upload_image';
import CustomInput from '../../util_components/custom_input';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomSingleSelect from '../../util_components/custom_single_select';
import { image_error_replace } from '../../../util_functions';
import { time_day_format, choice_active } from '../../../constants';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import 'react-quill/dist/quill.snow.css';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

class CreateEditAnnouncement extends Component {
  state = {
    is_edit: false,
    type_uuid: '',
    announcement_uuid: '',
    version_name: '',
    acknowledgement_required: 0,
    content: null,
    image_open: false,
    image_url: '',
    is_new_version: true,
    open_confirm: false,
    is_scheduled: 0,
    scheduled_date: ''
  };

  componentDidMount() {
    try {
      if (this.props.is_edit == '1') {
        const { details } = this.props;
        let scheduled_date = '';
        if (details.scheduled_date) {
          const for_start_date = moment
            .tz(details.scheduled_date, this.props.iana_timezone)
            .format('YYYY-MM-DD HH:mm:ss');
          scheduled_date = moment(for_start_date).toDate();
        }
        this.setState({
          is_edit: true,
          announcement_uuid: details.uuid,
          version_name: details.name,
          acknowledgement_required: details.acknowledgement_required,
          type_uuid: details.type_uuid,
          content: details.content,
          is_scheduled: details.is_scheduled,
          scheduled_date
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  newImageHandler = () => {
    this.toggle_image();
  };

  save_image = () => {
    this.quillEditor = this.quill.getEditor();
    console.log('this.quillEditor : ', this.quillEditor);
    const { image_url } = this.state;
    const range = this.quillEditor.getSelection();
    console.log('range: ', range);
    let index = 0;
    try {
      index = range.index;
    } catch (e) {}
    // this.quill.focus()
    this.quill.getEditor().insertEmbed(index, 'image', image_url);
    // this.quill.setEditorSelection(index + 1);
    // this.quill.getEditor().deleteText(index, 1);
    this.toggle_image();
  };

  change_input = (field_name, value) => {
    this.setState({ [field_name]: value });
  };

  render_image_url = () => {
    const { image_url } = this.state;
    return (
      <div>
        {/* <CustomInput
                    label="Enter image url"
                    onChange={this.onCommonChange}
                    name="image_url"
                    value={image_url}
                    style={{ width: '500px' }}
                />
                <button onClick={this.save_image}>Save</button> */}
        <UploadImage
          buttonTitle="Select Image"
          imageUrl={image_url}
          uuid=""
          close_create_workshop={() => console.log('close')}
          get_all_workshops={() => console.log('close')}
          folder_name="workshops"
          image_type="image_url"
          change_input={this.change_input}
          start_loading={this.start_loading}
          stop_loading={this.stop_loading}
        />
        {image_url ? <button onClick={this.save_image}>Save</button> : null}
      </div>
    );
  };

  setValue = (content) => this.setState({ content });

  toggle_image = () => this.setState({ image_open: !this.state.image_open });

  on_number_change = (e) => this.setState({ [e.target.name]: parseInt(e.target.value) });

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCommonChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  close_ann = () => this.setState({ open_confirm: false, is_new_version: false });

  submit_ann = () => this.submit_announcement();

  submit_announcement = () => {
    const {
      type_uuid,
      version_name,
      acknowledgement_required,
      content,
      announcement_uuid,
      is_edit,
      scheduled_date
    } = this.state;
    const payload = {
      type_uuid,
      name: version_name,
      acknowledgement_required,
      content
    };
    if (is_edit) {
      payload.announcement_uuid = announcement_uuid;
      payload.is_new_version = 1;
    } else {
      try {
        if (this.props.config_values.user_name) {
          payload.created_by = this.props.config_values.user_name;
        }
      } catch (e) {}
    }
    this.props.submit_announcement(payload);
  };

  set_start_date = (key, date) => this.setState({ [key]: date });

  render() {
    const {
      type_uuid,
      version_name,
      acknowledgement_required,
      content,
      image_open,
      open_confirm,
      is_edit,
      is_scheduled,
      scheduled_date
    } = this.state;
    const { announcement_types } = this.props.config_values;
    if (open_confirm) {
      return (
        <div>
          <h4>Do you want to create a new minor version or edit in same version?</h4>
          <div>
            <button onClick={this.close_ann}>Back</button>
            <button onClick={() => this.submit_announcement(1)}>New Version</button>
            <button onClick={() => this.submit_announcement(0)}>Same Version</button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <CustomModal show={image_open} close={this.toggle_image}>
          {this.render_image_url()}
        </CustomModal>
        <h2>{is_edit ? 'Edit' : 'Create'} Announcement</h2>
        <div className="display-flex-between">
          {is_edit ? null : (
            <CustomSingleSelect
              label="Select announcement type"
              options={announcement_types}
              name="type_uuid"
              onChange={this.onCommonChange}
              value={type_uuid}
              style={{ width: '250px' }}
            />
          )}
          {this.render_custom_input('Enter name *', 'version_name', version_name, {
            width: '250px'
          })}
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Is Acknowledgement required"
            options={choice_active}
            onChange={this.onCommonChange}
            value={acknowledgement_required}
            style={{ width: '260px' }}
            name="acknowledgement_required"
          />
          {/* is_scheduled, scheduled_date */}
          {/* {is_scheduled === 1 ? 
                        <div>
                            <h4>Scheduled Date</h4>
                            <DatePicker
                                selected={scheduled_date}
                                onChange={date => this.set_start_date('scheduled_date',date)}
                                minDate={new Date()}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd  h:mm aa"
                                popperPlacement="left-center"
                            />
                        </div>
                    : null} */}
        </div>
        <div>
          <ReactQuill
            ref={(el) => {
              this.quill = el;
            }}
            theme="snow"
            value={content}
            onChange={this.setValue}
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                  ['link', 'image', 'video'],
                  ['clean']
                ],
                handlers: {
                  image: this.newImageHandler
                }
              }
            }}
            formats={formats}
          />
        </div>
        <Button disabled={!version_name || !type_uuid || !version_name} onClick={this.submit_ann}>
          Submit
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  config_values: state.home.config_values
});

const mapDispatchToProps = (dispatch) => ({
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditAnnouncement);
