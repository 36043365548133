import * as t from '../action_types';

export const set_sessions_variable = (key, payload) => ({
  type: t.set_sessions_variable,
  payload: {
    key,
    payload
  }
});

export const get_past_group_sessions = (payload) => ({
  type: t.get_past_group_sessions,
  payload
});

export const get_future_group_sessions = (payload) => ({
  type: t.get_future_group_sessions,
  payload
});

export const get_future_friends_sessions = (payload) => ({
  type: t.get_future_friends_sessions,
  payload
});

export const get_past_friends_sessions = (payload) => ({
  type: t.get_past_friends_sessions,
  payload
});

export const update_future_group_sessions = (uuid, key, value) => ({
  type: t.update_future_group_sessions,
  payload: { uuid, key, value }
});

export const get_proposal_info = (payload) => ({
  type: t.get_proposal_info,
  payload
});

export const promote_proposal = (payload) => ({
  type: t.promote_proposal,
  payload
});

export const reject_proposal = (payload) => ({
  type: t.reject_proposal,
  payload
});

export const accept_proposal = (payload) => ({
  type: t.accept_proposal,
  payload
});

export const proposal_teacher_request = (payload) => ({
  type: t.proposal_teacher_request,
  payload
});

export const promote_form_info = (payload) => ({
  type: t.promote_form_info,
  payload
});

export const community_proposal_accept = (payload) => ({
  type: t.community_proposal_accept,
  payload
});

export const community_cancel_proposal = (payload) => ({
  type: t.community_cancel_proposal,
  payload
});
export const community_update_proposal_class_status = (payload) => ({
  type: t.community_update_proposal_class_status,
  payload
});

export const modify_proposal_data = (payload) => ({
  type: t.modify_proposal_data,
  payload
});
