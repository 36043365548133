import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CommonHeader from '../../util_components/common_header';
import CustomLoading from '../../util_components/custom_loading';
import { post_api } from '../../../redux/api_funcs';
import CustomModal from '../../util_components/custom_modal';
import CustomCheckBox from '../../util_components/custom_checkbox';
import HighlightSearch from '../../util_components/highlight_search_text';
import CustomSingleSelect from '../../util_components/custom_single_select';
import {
  long_session_duration_list,
  session_duration_list,
  group_session_private_values,
  target_audience_names,
  group_types,
  group_session_status,
  email_audience_types,
  boolean_values,
  day_format,
  time_format,
  session_status_values
} from '../../../constants';
import CustomInput from '../../util_components/custom_input';
import CustomPlayer from '../../util_components/custom_video_react';
import {
  getCookie,
  image_error_replace,
  is_production,
  isViewOnly,
  trackUserDevice
} from '../../../util_functions';
import * as actions from '../../../redux/action_creators';
import Button from '../../util_components/button';
import { get_api_url } from '../../../utils/urls';
import CustomRadioButton from '../../util_components/custom_radio_button';
import { capitalizeFirstLetter } from '../../../utils/formatString';

const cancel_options = ['cancelled_by_student', 'cancelled_by_teacher'];

class GroupSessionDetails extends Component {
  state = {
    page_error: false,
    loading: true,
    edit_details: false,
    edit_loading: false,
    edit_err: false,
    rencode_modal_open: false,
    change_host_open: false,
    change_host_uuid: '',
    session_title: '',
    session_other_title: '',
    session_desc: '',
    session_duration: '60',
    max_non_member_participants: 0,
    is_private_group_session: '',
    allow_booking_by_everyone: 0,
    featured_in_recommended: 0,
    change_teacher_open: false,
    change_session_time: false,
    teachers_loading: true,
    teacher_selected: '',
    change_reason: '',
    teachers: [],
    change_teacher_loading: false,
    change_time_loading: false,
    change_teacher_error: false,
    start_date: null,
    cancel_open: false,
    cancel_reason: '',
    cancel_error: false,
    cancel_loading: false,
    shift_open: false,
    shift_sessions_loaded: false,
    shift_sessions: [],
    participants: [],
    invitees: [],
    replace_session_uuid: '',
    replace_session_reason: '',
    replace_session_loading: false,
    add_student_open: false,
    student_search_text: '',
    add_student_uuid: '',
    add_student_loading: false,
    student_session_logs: [],
    zoom_logs: [],
    cancelled_open: false,
    joined_open: false,
    active_open: false,
    invitees_open: false,
    zoom_logs_open: false,
    replacement_logs: [],
    replacement_open: false,
    email_data: [],
    email_open: false,
    participants_emails: {},
    par_list_open: false,
    open_change_status: false,
    status: '',
    edit_workshop_open: false,
    workshop_session_name: '',
    workshop_session_description: '',
    start_time: '',
    duration: '',
    show_raw_data: false,
    raw_data_search: '',
    open_send_email: false,
    send_email_type: '',
    send_email_confirm: false,
    user_data_open: false,
    user_data_type: '',
    session_details: {},
    hide_replay_open: false,
    bootcamp_workshop_names: [],
    bootcamp_workshop_uuid: '',
    visible_to_members: 1,
    visible_to_non_members: 1,
    visible_to_trial_users: 1,
    open_ignore: false,
    ignore_student_rating: 0,
    ignore_student_rating_reason: '',
    zoom_recording_logs_open: false,
    zoom_recording_details: [],
    penalty_open: false,
    waive_penalty: false,
    waive_penalty_reason: '',
    show_session_detail: false,
    modal_error: false,
    cancelled_by_student: false,
    cancelled_by_teacher: false,
    teacher_penalty_reason: '',
    teacher_penalty: 0,
    is_repeat_session: 0,
    view: 'speaker'
  };

  componentDidMount() {
    this.set_url();
    if (this.props.teachers_list_status === 'none') {
      this.props.get_teachers_list();
    }
    if (this.props.teachers_list_status === 'success') {
      this.set_teachers_list();
    }
    if (this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (this.props.class_types_status === 'none') {
      const payload = {
        is_exclusive_for_backpain_client:
          this.props?.location?.state?.is_exclusive_for_backpain_client
      };
      this.props.get_class_types(payload);
    }
    if (this.props.worshops_status === 'none') this.props.get_all_workshops();
    if (this.props.worshops_status === 'success') this.set_bootcamp_workshops();
  }

  set_url = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('session_uuid');
    const student_uuid = url.searchParams.get('student_uuid');
    const student_name = url.searchParams.get('student_name');
    if (uuid) {
      this.setState({ session_uuid: uuid, student_uuid, student_name }, () => this.load_data());
    } else {
      this.setState({ page_error: true });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.teachers_list_status === 'loading' &&
      this.props.teachers_list_status === 'success'
    ) {
      this.set_teachers_list();
    }
    if (prevProps.class_types_status === 'loading' && this.props.class_types_status === 'success') {
      this.set_session_names();
    }
    if (prevProps.location.search !== this.props.location.search) {
      this.set_url();
    }
    if (prevProps.worshops_status === 'loading' && this.props.worshops_status === 'success') {
      this.set_bootcamp_workshops();
    }
  }

  set_bootcamp_workshops = () => {
    const { workshops } = this.props;
    const bootcamp_workshops = workshops.filter((w) => w.audience_type == 4);
    const bootcamp_workshop_names = bootcamp_workshops.map((w) => ({
      value: w.uuid,
      label: `${w.name}(${w.start_date})`
    }));
    bootcamp_workshop_names.push({ value: '', label: 'NONE' });
    this.setState({ bootcamp_workshop_names });
  };

  set_session_names = () => {
    const group_session_values = this.props.class_types;
    const group_session_names = [];
    const group_session_descriptions = [];
    for (const sess of group_session_values) {
      group_session_names.push({ value: sess.uuid, label: sess.name });
      group_session_descriptions.push({ value: sess.uuid, description: sess.description });
    }
    group_session_names.sort((a, b) => a.label.localeCompare(b.label));
    this.setState({ group_session_names, group_session_descriptions });
  };

  set_teachers_list = () => {
    const all_teachers = this.props.teachers_list.filter((t) => t.onboarding_status === 'APPROVED');
    this.setState({ teachers: all_teachers, teachers_loading: false });
  };

  load_data = () => {
    this.setState({ loading: true, student_session_logs: [] }, () => {
      const url = get_api_url('single_group_session_details');
      const payload = {
        session_uuid: this.state.session_uuid
      };
      this.load_emails();
      this.load_logs();
      this.load_zoom_recording_logs();
      post_api(url, payload, true)
        .then((res) => {
          const { session_details } = res.data;

          this.setState({
            session_details,
            participants: res.data.participants,
            participants_emails: res.data.participants_emails,
            zoom_logs: res.data.zoom_logs,
            invitees: res.data.invitees,
            teacher_penalty_reason: session_details.waive_penalty_reason,
            teacher_penalty: session_details.waive_penalty,
            is_repeat_session: session_details.is_repeat_session,
            loading: false
          });
          const new_payload = {
            session_uuid: session_details.session_uuid,
            start_time: session_details.epoch_start_time,
            group_session_name: session_details.group_session_name
          };
          const new_url = get_api_url('get_group_replacement_sessions');
          post_api(new_url, new_payload, true)
            .then((response) => {
              this.setState({
                shift_sessions: response.data.sessions,
                shift_sessions_loaded: true
              });
            })
            .catch((e) => {
              this.setState({ loading: false });
              console.log(e);
            });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ page_error: true, loading: false });
        });
    });
  };

  render_zoom_recording_logs = () => (
    <div>
      <div className="details-session-logs-header" onClick={this.toggle_zoom_recording_logs}>
        <img
          className={this.state.zoom_recording_logs_open ? 'reverse-expand' : 'normal-expand'}
          src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
          alt="exp"
        />
        <div className="details-session-logs-header-flex">
          <div className="details-session-logs-header-val">
            Zoom Recording Playback Logs: {this.state.zoom_recording_details.length}
          </div>
        </div>
      </div>
      {this.state.zoom_recording_logs_open ? (
        <div className="cus-table">
          <div className="ct-row ct-h">
            <div className="ct-col ct-qf">ID</div>
            <div className="ct-col">Playback Start Time</div>
            <div className="ct-col">Playback End Time</div>
            <div className="ct-col">Playback Duration (in min)</div>
          </div>
          {this.state.zoom_recording_details.map((record, id) => (
            <div className="ct-row" key={id}>
              <div className="ct-col ct-sm-font ct-qf">{id + 1}</div>
              <div className="ct-col ct-sm-font">
                <div>
                  {moment(record.recording_start_time)
                    .tz(this.props.iana_timezone)
                    .format(day_format)}
                </div>
                <div>
                  {moment(record.recording_start_time)
                    .tz(this.props.iana_timezone)
                    .format(time_format)}
                </div>
              </div>
              <div className="ct-col ct-sm-font">
                <div>
                  {moment(record.recording_end_time)
                    .tz(this.props.iana_timezone)
                    .format(day_format)}
                </div>
                <div>
                  {moment(record.recording_end_time)
                    .tz(this.props.iana_timezone)
                    .format(time_format)}
                </div>
              </div>
              <div className="ct-col ct-sm-font">{record.recording_playtime}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );

  session_status_updates = () => {
    const status_data = this.state.session_details.session_status_obj;
    const status = status_data?.status;
    const sub_status = status_data?.sub_status;
    return (
      <table>
        Status:
        <tbody>
          <tr>
            <td>Status</td>
            <td>Sub Status</td>
          </tr>
          <tr>
            <td>{status}</td>
            <td>
              {sub_status ? (
                <ol>
                  {sub_status.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
              ) : (
                '--'
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  load_emails = () => {
    // TODO: blocked to fix ses-notification
    // const { session_uuid } = this.state;
    // const payload = { session_uuid };
    // const url = get_api_url('get_email_logs_session', true)
    // post_api(url, payload, true)
    // .then(res => {
    //     this.setState({ email_data: [...res.data.email_data] })
    //     })
    //     .catch(e => {
    //         let err_message;
    //         try {
    //             err_message = e.response.data.message
    //         } catch (err) {
    //             err_message = 'Some error occured. Please contact dev team.'
    //         }
    //         this.props.set_notification_variable(true, 'error', err_message);
    //     })
  };

  load_logs = () => {
    const { session_uuid } = this.state;
    const payload = { session_uuid };
    const url = get_api_url('get_request_replacement_logs');
    post_api(url, payload, true)
      .then((res) => {
        this.setState({ replacement_logs: [...res.data.session_logs] });
      })
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        this.props.set_notification_variable(true, 'error', err_message);
      });
  };

  load_zoom_recording_logs = () => {
    const zoom_url = get_api_url('/v2/zoom_services/get_recording_logs', false, true);
    const payload = { session_uuid: this.state.session_uuid };
    post_api(zoom_url, payload, true)
      .then((response) => {
        this.setState({
          zoom_recording_details: [...response.data.state_records_for_recording_instance]
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  get_student_logs = () => {
    const { student_uuid, session_uuid } = this.state;
    const payload = { student_uuid, session_uuid };
    const url = get_api_url('get_student_group_session_logs');
    post_api(url, payload, true)
      .then((result) => this.setState({ student_session_logs: [...result.data.session_logs] }))
      .catch((e) => {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Some error occured. Please contact dev team.';
        }
        this.props.set_notification_variable(true, 'error', err_message);
      });
  };

  render_li_rating = (rating_reason) => {
    if (rating_reason === '') {
      return null;
    }
    if (rating_reason !== '') {
      return JSON.parse(rating_reason)?.map((i) => (
        <ul className="list-disc" style={{ width: '250px' }}>
          <li
            className="w-40"
            style={{
              border: '1px solid black',
              width: '250px',
              listStyle: 'none',
              padding: '20px',
              textAlign: 'left'
            }}
          >
            <b>{i?.parent_reason}</b>
            {i.child_reasons && i.child_reasons.length > 0 ? (
              <ul style={{ listStyle: 'circle' }} className="list-circle">
                {i?.child_reasons.map((i) => (
                  <li style={{ padding: 0, width: '230px' }} className="w-40">
                    {i}
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        </ul>
      ));
    }
  };

  render_single_participant = (per) => {
    const student_url = `/studentprofile?id=${per.student_uuid}`;
    return (
      <div className="ct-row" key={per.student_uuid}>
        <div className="ct-col ct-la">
          <a className="link-no-dec" href={student_url}>
            <div className="profile-img-name">
              <img src={per.profile_photo} onError={image_error_replace} alt="x" />
              {per.name}
            </div>
          </a>
        </div>
        <div className="ct-col ct-si">
          {per.participants_type === 'MEMBER' ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        {per.status !== 'FINISHED' ? <div className="ct-col">{per.status}</div> : null}
        <div className="ct-col">{per.yoga_level}</div>
        {per.status === 'FINISHED' ? (
          <>
            <div className="ct-col">{per.rating}</div>
            <div className="ct-col">{this.render_li_rating(per.rating_reason)}</div>
          </>
        ) : null}
      </div>
    );
  };

  open_edit = () => {
    const details = this.state.session_details;
    this.setState({
      edit_details: true,
      session_title: details.class_type_uuid,
      session_desc: details.group_session_description,
      session_duration: details.duration.toString(),
      is_private_group_session: details.is_private_group_session.toString(),
      target_audience: details.target_audience,
      is_premium_group_session: details.is_premium_group_session,
      max_non_member_participants: details.max_non_member_participants,
      allow_booking_by_everyone: details.allow_booking_by_everyone,
      featured_in_recommended: details.featured_in_recommended,
      bootcamp_workshop_uuid: details.bootcamp_workshop_uuid,
      visible_to_members: details.visible_to_members,
      visible_to_non_members: details.visible_to_non_members,
      visible_to_trial_users: details.visible_to_trial_users
    });
  };

  close_edit = () =>
    this.setState({ edit_details: false, change_session_time: false, change_teacher_open: false });

  toggle_open = (e) => {
    const label = e.currentTarget.dataset.div_label;
    const key = `${label}_open`;
    this.setState({ [key]: !this.state[key] });
  };

  toggle_zoom_recording_logs = () =>
    this.setState({ zoom_recording_logs_open: !this.state.zoom_recording_logs_open });

  render_participants = () => {
    const { joined_open, participants, active_open, cancelled_open, session_details } = this.state;
    const cancelled_participants = participants.filter(
      (p) => p.status === 'CANCELLED' || p.status === 'NO_SHOW_CANCELLATION_1HOUR'
    );
    const cancelled_members = cancelled_participants.filter(
      (c) => c.participants_type === 'MEMBER'
    );
    const cancelled_members_count = cancelled_members.length;
    const cancelled_non_members_count = cancelled_participants.length - cancelled_members_count;
    const joined_participants = participants.filter(
      (p) => p.status === 'FINISHED' || p.status === 'JOINED_BUT_CANCELLED'
    );
    const joined_members = joined_participants.filter((c) => c.participants_type === 'MEMBER');
    const joined_members_count = joined_members.length;
    const joined_non_members_count = joined_participants.length - joined_members_count;
    const active_participants = participants.filter(
      (p) =>
        p.status !== 'CANCELLED' &&
        p.status !== 'FINISHED' &&
        p.status !== 'NO_SHOW_CANCELLATION_1HOUR' &&
        p.status !== 'JOINED_BUT_CANCELLED'
    );
    const active_members = active_participants.filter((c) => c.participants_type === 'MEMBER');
    const active_members_count = active_members.length;
    const active_non_members_count = active_participants.length - active_members_count;
    const no_show_text =
      session_details.session_status === 'ACTIVE' || session_details.session_status === 'SCHEDULED'
        ? 'Booked'
        : 'No Shows';
    return (
      <>
        {joined_participants.length > 0 ? (
          <>
            <div
              className="details-session-logs-header"
              onClick={this.toggle_open}
              data-div_label="joined"
            >
              <img
                className={joined_open ? 'reverse-expand' : 'normal-expand'}
                src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
                alt="exp"
              />
              <div className="details-session-logs-header-flex">
                <div className="details-session-logs-header-val">
                  Joined: {joined_participants.length}
                </div>
                <div className="details-session-logs-header-mem">
                  Members: {joined_members_count}
                </div>
                <div className="details-session-logs-header-mem">
                  Non Members: {joined_non_members_count}
                </div>
              </div>
            </div>
            {joined_open ? (
              <div className="cus-table">
                <div className="ct-row ct-h">
                  <div className="ct-col">User</div>
                  <div className="ct-col">Member</div>
                  <div className="ct-col">Yoga level</div>
                  <div className="ct-col">Rating</div>
                  <div className="ct-col">Review</div>
                </div>
                {joined_participants.map(this.render_single_participant)}
              </div>
            ) : null}
          </>
        ) : null}
        {this.state.session_details?.session_status_obj && this.session_status_updates()}
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="active"
        >
          <img
            className={active_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
          <div className="details-session-logs-header-flex">
            <div className="details-session-logs-header-val">{`${no_show_text}: ${active_participants.length}`}</div>
            <div className="details-session-logs-header-mem">Members: {active_members_count}</div>
            <div className="details-session-logs-header-mem">
              Non Members: {active_non_members_count}
            </div>
          </div>
        </div>
        {active_open && active_participants.length > 0 ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">User</div>
              <div className="ct-col">Member</div>
              <div className="ct-col">Status</div>
              <div className="ct-col">Yoga level</div>
            </div>
            {active_participants.map(this.render_single_participant)}
          </div>
        ) : null}
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="cancelled"
        >
          <img
            className={cancelled_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
          <div className="details-session-logs-header-flex">
            <div className="details-session-logs-header-val">
              Cancellations: {cancelled_participants.length}
            </div>
            <div className="details-session-logs-header-mem">
              Members: {cancelled_members_count}
            </div>
            <div className="details-session-logs-header-mem">
              Non Members: {cancelled_non_members_count}
            </div>
          </div>
        </div>
        {cancelled_open && cancelled_participants.length > 0 ? (
          <div className="cus-table" style={{ marginTop: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col">User</div>
              <div className="ct-col">Member</div>
              <div className="ct-col">Status</div>
              <div className="ct-col">Yoga level</div>
            </div>
            {cancelled_participants.map(this.render_single_participant)}
          </div>
        ) : null}
        {this.state.zoom_recording_details.length > 0 ? this.render_zoom_recording_logs() : null}
      </>
    );
  };

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    const { group_session_descriptions } = this.state;
    this.setState(
      { [key]: value, edit_err: false, change_teacher_error: false, cancel_error: false },
      () => {
        if (key === 'session_title') {
          const index = group_session_descriptions.findIndex((gs) => value === gs.value);
          if (index !== -1) {
            this.setState({ session_desc: group_session_descriptions[index].description });
          } else {
            this.setState({ session_desc: '' });
          }
        }
      }
    );
  };

  edit_session = () => {
    const {
      session_title,
      session_desc,
      session_duration,
      session_other_title,
      is_private_group_session,
      target_audience,
      is_premium_group_session,
      max_non_member_participants,
      allow_booking_by_everyone,
      featured_in_recommended,
      bootcamp_workshop_uuid,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users
    } = this.state;
    if (
      !!session_title &&
      !!session_desc &&
      target_audience &&
      (session_title !== 'other' || !!session_other_title) &&
      max_non_member_participants > 0
    ) {
      const class_type = this.props.class_types.find((c) => c.uuid === session_title);
      const url = get_api_url('edit_group_session');
      const paylaod = {
        session_uuid: this.state.session_details.session_uuid,
        duration: session_duration,
        description: session_desc,
        is_private_group_session: parseInt(is_private_group_session),
        target_audience,
        is_premium_group_session: parseInt(is_premium_group_session),
        max_non_member_participants,
        class_type_uuid: class_type.uuid,
        name: class_type.name,
        allow_booking_by_everyone,
        featured_in_recommended,
        bootcamp_workshop_uuid,
        visible_to_members,
        visible_to_non_members,
        visible_to_trial_users,
        is_exclusive_for_backpain_client:
          this.props?.location?.state?.is_exclusive_for_backpain_client
      };
      this.setState({ edit_loading: true }, () => {
        post_api(url, paylaod, true)
          .then(() => {
            this.props.set_notification_variable(
              true,
              'success',
              'Session details edited successfully'
            );
            this.setState({
              edit_loading: false,
              edit_details: false,
              session_title: '',
              session_other_title: '',
              session_desc: '',
              target_audience: ''
            });
            this.load_data();
          })
          .catch((e) => {
            this.setState({ edit_loading: false });
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
          });
      });
    } else {
      this.setState({ edit_err: true });
    }
  };

  on_num_change = (e) => {
    let { value } = e.target;
    value = value || 0;
    this.setState({ max_non_member_participants: parseInt(value) });
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  render_edit_details = () => {
    const err_sty = this.state.edit_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const {
      group_session_names,
      bootcamp_workshop_names,
      bootcamp_workshop_uuid,
      visible_to_members,
      visible_to_non_members,
      visible_to_trial_users
    } = this.state;
    let show_allow_booking = false;
    try {
      show_allow_booking =
        this.state.session_details.group_session_name.toLowerCase().indexOf('webinar') !== -1 ||
        this.state.session_details.group_session_name.toLowerCase().indexOf('yoga talks') !== -1;
    } catch (e) {}
    return (
      <div>
        <h2>Edit group session</h2>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select session duration"
            options={session_duration_list}
            onChange={this.onCommonChange}
            name="session_duration"
            value={this.state.session_duration}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session private of public"
            options={group_session_private_values}
            onChange={this.onCommonChange}
            name="is_private_group_session"
            value={this.state.is_private_group_session}
            style={{ width: '250px' }}
          />
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select target audience"
            options={target_audience_names}
            onChange={this.onCommonChange}
            name="target_audience"
            value={this.state.target_audience}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session title"
            options={group_session_names}
            onChange={this.onCommonChange}
            name="session_title"
            value={this.state.session_title}
            style={{ width: '250px' }}
          />
        </div>
        {this.state.session_title === 'other' ? (
          <CustomInput
            label="Enter session title"
            onChange={this.onCommonChange}
            name="session_other_title"
            value={this.state.session_other_title}
            style={{ width: '300px' }}
          />
        ) : null}
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Select session type"
            options={group_types}
            onChange={this.onCommonChange}
            name="is_premium_group_session"
            value={this.state.is_premium_group_session}
            style={{ width: '250px' }}
          />
          {show_allow_booking ? (
            <CustomSingleSelect
              label="Allow booking by everyone"
              options={boolean_values}
              onChange={this.onCommonChange}
              name="allow_booking_by_everyone"
              value={this.state.allow_booking_by_everyone}
              style={{ width: '250px' }}
            />
          ) : null}
        </div>
        <div className="display-flex-between">
          <CustomSingleSelect
            label="Feature in recommended"
            options={boolean_values}
            onChange={this.onCommonChange}
            name="featured_in_recommended"
            value={this.state.featured_in_recommended}
            style={{ width: '250px' }}
          />
          <CustomInput
            label="Enter Max non member participants"
            onChange={this.on_num_change}
            name="session_other_title"
            value={this.state.max_non_member_participants}
            style={{ width: '250px' }}
          />
        </div>
        <CustomSingleSelect
          label="Select Bootcamp Workshop"
          options={bootcamp_workshop_names}
          onChange={this.onCommonChange}
          name="bootcamp_workshop_uuid"
          value={bootcamp_workshop_uuid}
          style={{ width: '250px' }}
        />
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Members"
            checked={visible_to_members}
            onClick={() => this.toggle_check_box('visible_to_members')}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Non Members"
            checked={visible_to_non_members}
            onClick={() => this.toggle_check_box('visible_to_non_members')}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Visible to Trial users"
            checked={visible_to_trial_users}
            onClick={() => this.toggle_check_box('visible_to_trial_users')}
          />
        </div>
        <div>Enter session description:</div>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={this.state.session_desc}
          name="session_desc"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <p style={{ margin: '0', fontSize: '12px' }}>
          you have {2000 - this.state.session_desc.length} characters left
        </p>
        <p style={err_sty}>All fields are compulsory</p>
        <Button onClick={this.edit_session} disabled={this.state.edit_loading}>
          Submit
        </Button>
      </div>
    );
  };

  edit_recording = () => {
    const { session_details, session_uuid } = this.state;
    const replay_url = encodeURIComponent(session_details.session_replay);
    this.props.history.push({
      pathname: '/croprecording',
      state: {
        session_uuid,
        replay_url
      }
    });
  };

  handleViewChange = (view) => {
    this.setState({ view });
  };

  toggle_reencode = () => this.setState({ rencode_modal_open: !this.state.rencode_modal_open });

  render_replays = () => {
    const { view } = this.state;
    const details = this.state.session_details;
    const recording_status = details.video_recording_status;
    const hasSpeakerView = !!details.session_replay;
    const hasGalleryView = !!details.gallery_view;
    let isReplayUrlName = false;
    if (moment(details.epoch_start_time).isBefore(moment('2024-07-31', 'YYYY-MM-DD'))) {
      isReplayUrlName = true;
    }

    if (!hasSpeakerView && hasGalleryView && view === 'speaker') {
      this.setState({ view: 'gallery' });
    }
    if (hasSpeakerView || hasGalleryView) {
      return (
        <>
          <div className="flex items-center gap-10 mb-10">
            {hasSpeakerView && (
              <div
                onClick={() => this.handleViewChange('speaker')}
                className={` cursor-pointer font-bold text-xl ${view === 'speaker' ? 'border-b-2 border-black underline-offset-4' : ''} `}
              >
                {isReplayUrlName ? 'Replays:' : 'Speaker view'}
              </div>
            )}
            {hasGalleryView && (
              <div
                onClick={() => this.handleViewChange('gallery')}
                className={` cursor-pointer font-bold text-xl ${view === 'gallery' ? 'border-b-2 border-black underline-offset-4' : ''}`}
              >
                Gallery view
              </div>
            )}
          </div>
          {view === 'speaker' && hasSpeakerView && (
            <>
              <CustomPlayer video_url={details.session_replay} />
              {details.session_type === 'GROUP_SESSION' ||
              details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
              details.session_type === 'WORKSHOP_SESSION' ? (
                <>
                  <Button onClick={this.edit_recording}>Edit Recording</Button>
                  <Button onClick={this.toggle_reencode}>Reencode video</Button>
                  {recording_status ? (
                    <span
                      style={{ marginLeft: '5px' }}
                    >{`(Video editing status : ${recording_status} )`}</span>
                  ) : null}
                </>
              ) : null}
            </>
          )}
          {view === 'gallery' && hasGalleryView && (
            <>
              <CustomPlayer video_url={details.gallery_view} />
              {details.session_type === 'GROUP_SESSION' ||
              details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
              details.session_type === 'WORKSHOP_SESSION' ? (
                <>
                  <Button onClick={this.edit_recording}>Edit Recording</Button>
                  <Button onClick={this.toggle_reencode}>Reencode video</Button>
                  {recording_status ? (
                    <span
                      style={{ marginLeft: '5px' }}
                    >{`(Video editing status : ${recording_status} )`}</span>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </>
      );
    }
    return null;
  };

  handle_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submit_penalty = () => {
    this.setState({ charge_loading: true }, () => {
      const { waive_penalty_reason, waive_penalty } = this.state;
      const url = get_api_url('update_session_penalty');
      const payload = {
        session_uuid: this.state.session_uuid,
        waive_penalty: waive_penalty ? 1 : 0,
        waive_penalty_reason: waive_penalty_reason || ''
      };
      post_api(url, payload, true)
        .then(() => {
          this.close_penalty();
          this.load_data();
          this.setState({ charge_loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ charge_loading: false });
        });
    });
  };

  render_penalty_popup = () => {
    const details = this.state.session_details;
    return (
      <div>
        <h4>Change Popup Details:</h4>
        <div style={{ marginBottom: '20px' }}>
          <CustomCheckBox
            label="Waive Penalty"
            checked={this.state.waive_penalty}
            onClick={() => this.setState({ waive_penalty: !this.state.waive_penalty })}
          />
        </div>
        <div style={{ margin: '10px 0' }}>
          <textarea
            value={this.state.waive_penalty_reason}
            name="waive_penalty_reason"
            onChange={this.handle_change}
            style={{ width: '300px', height: '100px' }}
            maxLength="200"
            placeholder="Enter waive penalty reason (max 200 char)"
          />
        </div>
        <button onClick={this.submit_penalty}>Submit</button>
      </div>
    );
  };

  close_penalty = () =>
    this.setState({ penalty_open: false, waive_penalty: false, waive_penalty_reason: '' });

  open_penalty = () => {
    const details = this.state.session_details;
    this.setState({
      penalty_open: true,
      waive_penalty: details.waive_penalty,
      waive_penalty_reason: details.waive_penalty_reason
    });
  };

  render_penalty = () => {
    const details = this.state.session_details;
    return (
      <>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Waive Penalty :</td>
              <td>{details.waive_penalty === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Waive Penalty Reason:</td>
              <td>{details.waive_penalty_reason}</td>
            </tr>
          </tbody>
        </table>
        {!isViewOnly(this.props.access_type) ? (
          <button onClick={this.open_penalty}>Change Penalty</button>
        ) : null}
      </>
    );
  };

  render_session_details = () => {
    const details = this.state.session_details;
    const teacher_url = `/teacherprofile?id=${details.teacher_uuid}`;
    let teacher_link = '';
    let care_link = '';
    if (details?.session_status === 'ACTIVE' || details?.session_status === 'SCHEDULED') {
      let zoom_link = details.join_url;
      if (zoom_link?.indexOf('?') === -1) {
        zoom_link += '?';
      } else {
        zoom_link += '&';
      }
      const teacher_name = btoa(details.teacher_name);
      teacher_link = `${zoom_link}name=${teacher_name}`;
      care_link = `${zoom_link}name=Myt-Care`;
      if (details?.teacher_zak_token) {
        teacher_link = `${teacher_link}&zak=${details.teacher_zak_token}`;
        care_link = `${care_link}&zak=${details.teacher_zak_token}`;
      }
    }

    const isProduction = window.location.hostname === 'hridaya.myyogateacher.com';
    const host = isProduction
      ? 'https://teacher.myyogateacher.com'
      : 'http://teacher-testenv1.myyogateacher.com';

    const livekit_dashboard = isProduction
      ? 'https://cloud.livekit.io/projects/p_2nxn09d2ot6/sessions'
      : 'https://cloud.livekit.io/projects/p_1q9m81ky6p4/sessions';

    const livekit_care_link = `${host}/sessions/livekit?session_uuid=${details.session_uuid}&token=${getCookie('cms_token_3')}`;

    const showJoinInfo =
      (details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') &&
      (details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
        details.session_type === 'GROUP_SESSION' ||
        details.session_type === 'WORKSHOP_SESSION');

    let showTeacherLinkAndJoinUrl = false;
    if (
      ['YOGA_WITH_FRIENDS_SESSION', 'GROUP_SESSION', 'WORKSHOP_SESSION'].includes(
        details.session_type
      )
    ) {
      const ONE_MINUTE_IN_MILLISECONDS = 60000;
      const THIRTY_MINUTES_IN_MILLISECONDS = 30 * ONE_MINUTE_IN_MILLISECONDS;
      const durationInMs = details.duration * ONE_MINUTE_IN_MILLISECONDS;
      if (Date.now() <= details.epoch_start_time + durationInMs + THIRTY_MINUTES_IN_MILLISECONDS) {
        showTeacherLinkAndJoinUrl = true;
      }
    }

    return (
      <>
        <table>
          <tbody>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Teacher Name:</td>
              <td>
                <a className="link-no-dec" href={teacher_url}>
                  {details.teacher_name}
                </a>
              </td>
              <td className="teacher-table-detail">Session uuid:</td>
              <td>{details.session_uuid}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session status:</td>
              <td>{details.session_status}</td>
              <td className="teacher-table-detail">Start time:</td>
              <td>
                {moment(details.epoch_start_time)
                  .tz(this.props.iana_timezone)
                  .format('YYYY-MM-DD ddd hh:mm A z')}
              </td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session Duration:</td>
              <td>{details.duration}</td>
              <td className="teacher-table-detail">Session Name:</td>
              <td>{details.group_session_name}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session Privacy:</td>
              <td>{details.is_private_group_session === 1 ? 'Private' : 'Public'}</td>
              <td className="teacher-table-detail">Target audience:</td>
              <td>{details.target_audience ? details.target_audience : '--'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session booked by teacher:</td>
              <td>{details.scheduled_by_teacher === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Premium Session?:</td>
              <td>{details.is_premium_group_session === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Members booked:</td>
              <td>{details.members_participants}</td>
              <td className="teacher-table-detail">Non members total booked:</td>
              <td>{details.non_member_participants}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Non members booked</td>
              <td>{details.non_members_participants_after_first_10_days}</td>
              <td className="teacher-table-detail">Trials booked</td>
              <td>{details.non_members_participants_within_first_10_days}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Non members Limit:</td>
              <td>{details.max_non_member_participants}</td>
              <td className="teacher-table-detail">Total booked:</td>
              <td>{details.group_session_participants}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Session Name:</td>
              <td>
                {details.session_type === 'WORKSHOP_SESSION' ||
                details.session_type === 'WORKSHOP_SESSION_STUDENT'
                  ? details.workshop_session_name
                  : details.group_session_name}
              </td>
              <td className="teacher-table-detail">Allow booking for everyone?:</td>
              <td>{details.allow_booking_by_everyone === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Featured in recommended?:</td>
              <td>{details.featured_in_recommended === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Hide replay from student?:</td>
              <td>{details.hide_replay_from_student === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Visible to Members:</td>
              <td>{details.visible_to_members === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Visible to Non Members:</td>
              <td>{details.visible_to_non_members === 1 ? 'True' : 'False'}</td>
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Visible to Trial users:</td>
              <td>{details.visible_to_trial_users === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail" />
              <td />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Cancelled by:</td>
              <td>
                {details.updated_by_user_name} (
                {capitalizeFirstLetter(details?.updated_by_user_type)})
              </td>
              <td className="teacher-table-detail" />
              <td />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Cancellation Date:</td>
              <td>{moment(details.cancellation_time).format('YYYY-MM-DD')}</td>
              <td className="teacher-table-detail">Cancellation Time:</td>
              <td>{moment(details.cancellation_time).format('HH:mm:ss')}</td>
              <td />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Reason:</td>
              <td>{details.reason_for_cancel_reschedule}</td>
              <td className="teacher-table-detail" />
              <td />
            </tr>
            <tr className="table-row-left-align">
              <td className="teacher-table-detail">Cancelled By teacher:</td>
              <td>{details.raw_data.cancelled_by_teacher === 1 ? 'True' : 'False'}</td>
              <td className="teacher-table-detail">Cancelled By student:</td>
              <td>{details.raw_data.cancelled_by_student === 1 ? 'True' : 'False'}</td>
            </tr>
            {details.ignore_student_rating === 1 ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Ignore rating:</td>
                <td>{details.visible_to_trial_users === 1 ? 'True' : 'False'}</td>
                <td className="teacher-table-detail">Ignore rating reason:</td>
                <td>{details.ignore_student_rating_reason}</td>
              </tr>
            ) : null}
            {details.bootcamp_workshop_uuid ? (
              <tr className="table-row-left-align">
                <td className="teacher-table-detail">Bootcamp Workshop:</td>
                <td colSpan="3">
                  <a
                    href={`/workshop/${details.bootcamp_workshop_uuid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {details.bootcamp_workshop_name}
                  </a>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        {details.call_provider === 'livekit' && (
          <div style={{ marginTop: '20px' }}>
            <p>This is a livekit session. For video session logs visit, livekit dashboard</p>
          </div>
        )}

        <div style={{ marginTop: '20px' }}>
          {showJoinInfo && details.call_provider === 'livekit' && (
            <a
              className="btn-link"
              href={livekit_care_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join as care
            </a>
          )}

          {showTeacherLinkAndJoinUrl && details.call_provider !== 'livekit' && (
            <>
              <a className="btn-link" href={teacher_link} target="_blank" rel="noopener noreferrer">
                Teacher Link
              </a>
              {/* <a className="btn-link" href={care_link} target="_blank" rel="noopener noreferrer" >Join as care</a> */}
              <a
                className="btn-link"
                href={details.join_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join URL
              </a>
            </>
          )}

          {details.call_provider === 'livekit' && (
            <a
              className="btn-link"
              href={livekit_dashboard}
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveKit Dashboard
            </a>
          )}
          {details.call_provider !== 'livekit' && (
            <a
              className="btn-link"
              href="https://zoom.us/account/metrics/livemeetings"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zoom Dashboard
            </a>
          )}
        </div>

        <div style={{ margin: '20px 0' }}>
          {details.session_type === 'WORKSHOP_SESSION' ||
          details.session_type === 'WORKSHOP_SESSION'
            ? 'Workshop Session Description:'
            : 'Session Description:'}
          <div style={{ margin: '5px 0', fontSize: '14px', letterSpacing: '0.3px' }}>
            {details.session_type === 'WORKSHOP_SESSION'
              ? details.workshop_session_description
              : details.group_session_description}
          </div>
          {this.render_penalty()}
        </div>
      </>
    );
  };

  go_back = () => {
    this.props.history.goBack();
  };

  open_change_teacher = () => {
    const details = this.state.session_details;
    this.setState({
      change_teacher_open: true,
      teacher_selected: details.teacher_uuid,
      change_reason: '',
      change_teacher_loading: false
    });
  };

  open_change_time = () => {
    const curr_date = moment
      .tz(this.state.session_details.epoch_start_time, this.props.iana_timezone)
      .format('YYYY-MM-DD HH:mm:ss');
    const curr_time = moment(curr_date).toDate();
    this.setState({ change_session_time: true, start_date: curr_time });
  };

  open_shift = () => this.setState({ shift_open: true });

  close_shift = () => this.setState({ shift_open: false });

  toggle_par_list = () => this.setState({ par_list_open: !this.state.par_list_open });

  open_change_status = () =>
    this.setState({ open_change_status: true, status: this.state.session_details.session_status });

  close_change_status = () => this.setState({ open_change_status: false, status: '' });

  close_workshop_edit = () => {
    this.setState({
      edit_workshop_open: false,
      workshop_session_name: '',
      workshop_session_description: '',
      start_time: '',
      duration: ''
    });
  };

  set_create_time = (date) => this.setState({ start_time: date });

  edit_workshop_session_details = () => {
    const { session_details } = this.state;
    const start_time = moment(
      moment(session_details.start_time)
        .tz(this.props.iana_timezone)
        .format('YYYY-MM-DD hh:mm:ss A')
    ).toDate();
    this.setState({
      start_time,
      edit_workshop_open: true,
      workshop_session_name: session_details.workshop_session_name,
      workshop_session_description: session_details.workshop_session_description,
      duration: session_details.duration
    });
  };

  edit_workshop_session = () => {
    const {
      workshop_session_name,
      workshop_session_description,
      duration,
      session_uuid,
      start_time
    } = this.state;
    if (!!workshop_session_name && !!workshop_session_name) {
      const sel_time = moment(start_time).format('YYYY-MM-DD HH:mm:ss');
      const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
      const payload = {
        session_uuid,
        workshop_session_name,
        workshop_session_description,
        duration,
        start_time: epoch_time
      };
      const url = get_api_url('update_workshop_session');
      this.setState({ loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Workshop session updated');
            this.close_workshop_edit();
            this.load_data();
          })
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ create_err: true });
    }
  };

  render_edit_workshop = () => {
    const {
      create_err,
      workshop_session_name,
      workshop_session_description,
      duration,
      start_time
    } = this.state;
    const err_sty = create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const curr_timezone = moment().tz(this.props.iana_timezone).format('z');
    return (
      <div>
        <h2>Edit Workshop Session</h2>
        <div className="display-flex-between">
          <CustomInput
            label="Enter session name *"
            onChange={this.onCommonChange}
            name="workshop_session_name"
            value={workshop_session_name}
            style={{ width: '250px' }}
          />
          <CustomSingleSelect
            label="Select session duration"
            options={long_session_duration_list}
            onChange={this.onCommonChange}
            name="duration"
            value={duration}
            style={{ width: '250px' }}
          />
        </div>
        <div>
          <div>{`Select time (${curr_timezone})`}</div>
          <DatePicker
            selected={start_time}
            onChange={(date) => this.set_create_time(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </div>
        <div>Enter session description:</div>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={workshop_session_description}
          name="workshop_session_description"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <p style={err_sty}>All fields are compulsory</p>
        <button onClick={this.edit_workshop_session}>Submit</button>
      </div>
    );
  };

  render_buttons = () => {
    const details = this.state.session_details;
    const is_workshop = details.session_type === 'WORKSHOP_SESSION';
    const is_group =
      details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
      details.session_type === 'GROUP_SESSION';
    const is_active = details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED';
    const isFinishedSession = details.session_status === 'FINISHED';
    return (
      <>
        {!isViewOnly(this.props.access_type) ? (
          <>
            {details.session_type === 'GROUP_SESSION' &&
            !this.state.teachers_loading &&
            (is_active || isFinishedSession) ? (
              <Button onClick={this.open_change_teacher}>Change Teacher</Button>
            ) : null}
            {is_group && is_active ? (
              <>
                <Button onClick={this.open_change_time}>Change Session Time</Button>
                {this.state.shift_sessions_loaded ? (
                  <Button onClick={this.open_shift}>Shift participants and cancel session</Button>
                ) : null}
              </>
            ) : null}
            {is_group ? <Button onClick={this.open_add_student}>Add participant</Button> : null}
            {is_workshop ? (
              <>
                {!this.state.teachers_loading &&
                (details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') ? (
                  <Button onClick={this.open_change_teacher}>Change Teacher</Button>
                ) : null}
                <Button onClick={this.edit_workshop_session_details}>Edit details</Button>
                {/* <Button onClick={this.change_workshop_teacher}>Change teacher</Button> */}
              </>
            ) : null}
            {details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
            details.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT' ? (
              details.hide_replay_from_student === 1 ? (
                <button onClick={this.toggle_hide_replay}>Show Replay to student</button>
              ) : (
                <button onClick={this.toggle_hide_replay}>Hide Replay to student</button>
              )
            ) : null}
            {details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ? (
              <button onClick={this.toggle_change_host}>Change Host</button>
            ) : null}
          </>
        ) : null}
        {is_group ? (
          <Button
            disabled={this.state.participants_emails.length === 0}
            onClick={this.toggle_par_list}
          >
            List participant emails
          </Button>
        ) : null}
        {is_group || is_workshop ? <SessionQA session_uuid={details.session_uuid} /> : null}
      </>
    );
  };

  render_change_host = () => {
    const { participants, session_details, change_host_uuid } = this.state;
    const scheduled_participants = participants.filter(
      (p) => p.status === 'ACCEPTED' && session_details.student_uuid !== p.student_uuid
    );
    const scheduled_participants_list = scheduled_participants.map((p) => ({
      value: p.student_uuid,
      label: p.name
    }));
    return (
      <div>
        <h2>Change host</h2>
        <CustomSingleSelect
          label="Select student"
          options={scheduled_participants_list}
          onChange={this.onCommonChange}
          name="change_host_uuid"
          value={change_host_uuid}
          style={{ width: '250px' }}
        />
        <Button disabled={!change_host_uuid} onClick={this.change_host}>
          Confirm
        </Button>
      </div>
    );
  };

  change_host = () =>
    this.setState({ loading: true }, () => {
      const { session_details, change_host_uuid } = this.state;
      const url = get_api_url('change_host_ywf');
      const payload = {
        session_uuid: session_details.session_uuid,
        host_uuid: change_host_uuid
      };
      post_api(url, payload, true)
        .then(() => {
          this.toggle_change_host();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  toggle_change_host = () =>
    this.setState({ change_host_open: !this.state.change_host_open, change_host_uuid: '' });

  open_parent_session = () => {
    const details = this.state.session_details;
    let search = `?session_uuid=${details.group_session_uuid}`;
    if (details.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT') {
      search = `?session_uuid=${details.yoga_with_friends_session_uuid}`;
    }
    this.props.history.push({
      pathname: '/groupsessiondetails',
      search
    });
  };

  render_invitees = () => {
    const { invitees, invitees_open } = this.state;
    return (
      <div>
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="invitees"
        >
          Invitees: {invitees.length}
          <img
            className={invitees_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>
        {invitees_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col ct-la">
                <span className="ct-ml25">Student</span>
              </div>
              <div className="ct-col ct-qf">Host</div>
              <div className="ct-col">Status</div>
              <div className="ct-col ct-hf">rating</div>
              <div className="ct-col ct-hf">Reason</div>
              <div className="ct-col ct-hf">Member</div>
              <div className="ct-col ct-hf">User Type</div>
            </div>
            {invitees.map((inv) => {
              const student_url = `/studentprofile?id=${inv.student_uuid}`;
              return (
                <div className="ct-row" key={inv.student_uuid}>
                  <div className="ct-col">
                    <a className="link-no-dec" href={student_url}>
                      <div className="profile-img-name">
                        <img src={inv.profile_photo} onError={image_error_replace} alt="x" />
                        {inv.name}
                      </div>
                    </a>
                  </div>
                  <div className="ct-col ct-si ct-qf">
                    {inv.is_host === 1 ? (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                        alt="av"
                      />
                    ) : (
                      <img
                        src="https://images.myyogateacher.com/icons/ic-delete-48.png"
                        alt="nav"
                      />
                    )}
                  </div>
                  <div className="ct-col ct-sm-font">{inv.status}</div>
                  <div className="ct-col ct-hf">{inv.student_rating}</div>
                  <div className="ct-col ct-hf">{inv.student_rating_reason}</div>
                  <div className="ct-col ct-hf">{inv.is_member}</div>
                  <div className="ct-col ct-hf">{inv.participants_type}</div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  render_email_logs = () => {
    const { email_open, email_data } = this.state;
    return (
      <>
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="email"
        >
          Email logs: {email_data.length}
          <img
            className={email_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>
        {email_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Type</div>
              <div className="ct-col">To</div>
              <div className="ct-col ct-hf">Delivered</div>
              <div className="ct-col ct-hf">Opened</div>
              <div className="ct-col ct-hf">Primary action</div>
            </div>
            {email_data.map((email) => (
              <div className="ct-row" key={email.id}>
                <div className="ct-col ct-bw ct-sm-font">{email.email_type}</div>
                <div className="ct-col ct-bw ct-sm-font">{email.to_address}</div>
                <div className="ct-col ct-xsi ct-hf">
                  {email.is_delivered ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
                <div className="ct-col ct-xsi ct-hf">
                  {email.is_opened ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : null}
                </div>
                <div className="ct-col ct-xsi ct-hf">
                  {email.primary_action_count > 0 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  };

  render_replacement_logs = () => {
    const { replacement_open, replacement_logs } = this.state;
    return (
      <>
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="replacement"
        >
          Request replacement logs: {replacement_logs.length}
          <img
            className={replacement_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>
        {replacement_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Action</div>
              <div className="ct-col">By</div>
              <div className="ct-col">Date</div>
            </div>
            {replacement_logs.map((log, index) => {
              const original_link = `/teacherprofile?id=${log.original_teacher_uuid}`;
              const new_link = `/teacherprofile?id=${log.new_teacher_uuid}`;
              return (
                <div className="ct-row" key={index + log.uuid}>
                  <div className="ct-col">
                    {log.action === 'WAITING' ? 'Replacement Created' : log.action}
                  </div>
                  <div className="ct-col">
                    {log.action === 'Replacement Created' ? (
                      <a className="link-no-dec" href={original_link}>
                        {log.original_teacher_name}
                      </a>
                    ) : (
                      <a className="link-no-dec" href={new_link}>
                        {log.new_teacher_name}
                      </a>
                    )}
                  </div>
                  <div className="ct-col">
                    {log.action === 'Replacement Created'
                      ? moment(log.waiting_start_time)
                          .tz(this.props.iana_timezone)
                          .format('MMM DD hh:mm A z')
                      : moment(log.modified_date)
                          .tz(this.props.iana_timezone)
                          .format('MMM DD hh:mm A z')}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };

  render_zoom_logs = () => {
    const { zoom_logs, zoom_logs_open } = this.state;
    return (
      <>
        <div
          className="details-session-logs-header"
          onClick={this.toggle_open}
          data-div_label="zoom_logs"
        >
          Zoom logs : {zoom_logs.length}
          <img
            className={zoom_logs_open ? 'reverse-expand' : 'normal-expand'}
            src="https://images.myyogateacher.com/icons/ic_expand_arrow_white.png"
            alt="exp"
          />
        </div>
        {zoom_logs_open ? (
          <div className="cus-table">
            <div className="ct-row ct-h">
              <div className="ct-col">Event</div>
              <div className="ct-col">Start Time</div>
              <div className="ct-col">Name</div>
              <div className="ct-col ct-hf">Join Time</div>
              <div className="ct-col ct-hf">Leave Time</div>
            </div>
            {zoom_logs.map((log) => {
              const log_body = JSON.parse(log.body);
              return (
                <div className="ct-row" key={log.id}>
                  <div className="ct-col ct-sm-font">{log_body.event}</div>
                  <div className="ct-col  ct-sm-font">
                    {moment(log.created_date)
                      .tz(this.props.iana_timezone)
                      .format('YYYY-MM-DD hh:mm A z')}
                    {/* {moment(log_body.payload.object.start_time)
                                    .tz(this.props.iana_timezone)
                                    .format('YYYY-MM-DD hh:mm z')} */}
                  </div>
                  <div className="ct-col  ct-sm-font">
                    {log_body.payload.object.participant
                      ? log_body.payload.object.participant.user_name
                      : '-'}
                  </div>
                  <div className="ct-col ct-hf  ct-sm-font">
                    {!!log_body.payload.object.participant &&
                    !!log_body.payload.object.participant.join_time
                      ? moment(log_body.payload.object.participant.join_time)
                          .tz(this.props.iana_timezone)
                          .format('hh:mm z') || '-'
                      : '-'}
                  </div>
                  <div className="ct-col ct-hf ct-sm-font">
                    {!!log_body.payload.object.participant &&
                    log_body.payload.object.participant.leave_time
                      ? moment(log_body.payload.object.participant.leave_time)
                          .tz(this.props.iana_timezone)
                          .format('hh:mm z') || '-'
                      : '-'}
                  </div>
                  _
                </div>
              );
            })}
          </div>
        ) : (
          false
        )}
      </>
    );
  };

  change_teacher = () => {
    const { session_details, teacher_selected, change_reason } = this.state;
    const url = get_api_url('change_teacher_for_group_session');
    const payload = {
      session_uuid: session_details.session_uuid,
      teacher_uuid: teacher_selected,
      reason: change_reason
    };
    if (session_details.session_type === 'WORKSHOP_SESSION') {
      payload.is_workshop = 1;
    }
    if (change_reason) {
      this.setState({ change_teacher_loading: true }, () => {
        post_api(url, payload, true)
          .then(() => {
            this.setState({
              change_teacher_open: false,
              teacher_selected: '',
              change_reason: '',
              change_teacher_loading: false
            });
            this.load_data();
            this.props.set_notification_variable(true, 'success', 'Teacher successfully changed');
          })
          .catch((e) => {
            this.setState({ change_teacher_loading: false });
            let err_message;
            try {
              err_message = e.response.data.message || e.response.data.reason.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
          });
      });
    } else {
      this.setState({ change_teacher_error: true });
    }
  };

  render_change_teacher = () => {
    const teachers_list = this.state.teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    const details = this.state.session_details;
    if (details.allow_teacher_replacement === 0) {
      return (
        <div>
          The student has opted not to replace the teacher. Please cancel the session or ask student
          to update the preference
        </div>
      );
    }
    return (
      <div>
        <h2>Change teacher</h2>
        <CustomSingleSelect
          label="Select a teacher"
          options={teachers_list}
          name="teacher_selected"
          onChange={this.onCommonChange}
          value={this.state.teacher_selected}
          style={{ width: '300px' }}
        />
        <CustomInput
          label="Enter reason"
          onChange={this.onCommonChange}
          name="change_reason"
          value={this.state.change_reason}
          style={{ width: '300px' }}
        />
        {this.state.change_teacher_error ? (
          <p style={{ color: '#ed4d4d' }}>*Please enter reason</p>
        ) : null}
        <Button onClick={this.change_teacher} disabled={this.state.change_teacher_loading}>
          Change
        </Button>
      </div>
    );
  };

  set_start_date = (date) => this.setState({ start_date: date });

  change_time = () => {
    const { session_details, start_date } = this.state;
    const sel_time = moment(start_date).format('YYYY-MM-DD HH:mm:ss');
    const epoch_time = moment.tz(sel_time, this.props.iana_timezone).valueOf();
    this.setState({ change_time_loading: true }, () => {
      let url = get_api_url('update_group_session_time');
      if (session_details.session_type === 'YOGA_WITH_FRIENDS_SESSION') {
        url = get_api_url('update_friends_session_time');
      }
      const payload = {
        session_uuid: session_details.session_uuid,
        start_time: epoch_time
      };
      post_api(url, payload, true)
        .then(() => {
          this.setState({ change_time_loading: false, change_session_time: false });
          this.load_data();
          this.props.set_notification_variable(
            true,
            'success',
            'Session time updated successfully'
          );
        })
        .catch((e) => {
          this.setState({ change_session_time: false });
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
        });
    });
  };

  render_change_time = () => (
    <div>
      <h3>Change session time</h3>
      <div>
        <DatePicker
          selected={this.state.start_date}
          onChange={(date) => this.set_start_date(date)}
          minDate={new Date()}
          maxDate={moment(new Date()).add(90, 'days').toDate()}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
      <Button onClick={this.change_time} disabled={this.state.change_time_loading}>
        Change Time
      </Button>
    </div>
  );

  show_cancel_pop = () => {
    this.setState({ cancel_open: true });
  };

  close_cancel = () => {
    this.setState({
      cancel_open: false,
      cancel_reason: '',
      cancel_error: false,
      open_send_email: false
    });
  };

  cancel_group_session = () => {
    const { cancel_reason, session_details } = this.state;
    if (cancel_reason) {
      this.setState({ cancel_loading: true }, () => {
        const url = get_api_url('cancel_group_session');
        const payload = {
          session_uuid: session_details.session_uuid,
          reason: cancel_reason
        };
        if (session_details.session_type === 'WORKSHOP_SESSION') {
          payload.is_workshop = 1;
        }
        post_api(url, payload, true)
          .then(() =>
            this.setState({ cancel_error: false, cancel_reason: false, cancel_open: false }, () => {
              this.props.set_notification_variable(
                true,
                'success',
                'Session details edited successfully'
              );
              this.load_data();
            })
          )
          .catch((e) => {
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
          });
      });
    } else {
      this.setState({ cancel_error: true });
    }
  };

  handle_cancel_reason = (e) => {
    const val = e.target.value;
    if (val.length <= 200) {
      this.setState({ cancel_reason: val });
    }
  };

  render_cancel = () => {
    const { cancel_error, cancel_reason } = this.state;
    const error_style = cancel_error ? { color: '#ed4d4d' } : { opacity: 0 };
    return (
      <div>
        <div>
          <div>Enter Reason:</div>
          <textarea
            name="cancel_reason"
            value={cancel_reason}
            onChange={this.handle_cancel_reason}
            style={{ width: '300px', height: '100px' }}
          />
          <div style={{ fontSize: '10px' }}>* {200 - cancel_reason.length} characters left</div>
        </div>
        <p style={error_style}>Please enter reason</p>
        <Button onClick={this.cancel_group_session} disabled={this.state.cancel_loading}>
          Confirm
        </Button>
      </div>
    );
  };

  shift_session = () => {
    this.setState({ replace_session_loading: true }, () => {
      const { replace_session_uuid, replace_session_reason, session_details } = this.state;
      const payload = {
        original_session_uuid: session_details.session_uuid,
        replace_session_uuid,
        reason: replace_session_reason
      };
      const url = get_api_url('merge_group_session');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Session merged successfully');
          this.setState({
            replace_session_uuid: '',
            replace_session_reason: '',
            replace_session_loading: false,
            shift_open: false
          });
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ replace_session_loading: false });
        });
    });
  };

  handle_reason = (e) => {
    const val = e.target.value;
    if (val.length <= 200) {
      this.setState({ replace_session_reason: val });
    }
  };

  render_shift = () => {
    let select_values = [];
    const { shift_sessions, replace_session_uuid, replace_session_reason } = this.state;
    select_values = shift_sessions.map((sess) => ({
      label: `${sess.target_audience} ${sess.group_session_name} by ${sess.teacher_name} at ${moment(
        sess.epoch_start_time
      )
        .tz(this.props.iana_timezone)
        .format('YYYY-MM-DD hh:mm A z')}`,
      value: sess.session_uuid
    }));
    if (select_values.length === 0) {
      return (
        <div>
          <h3>No sessions available to shift participants</h3>
        </div>
      );
    }
    return (
      <div>
        <h3>Shift participants and cancel session</h3>
        <CustomSingleSelect
          label="Select session to merge with"
          options={select_values}
          onChange={this.onCommonChange}
          name="replace_session_uuid"
          value={replace_session_uuid}
          style={{ width: '400px' }}
        />
        <div>
          <div>Enter Reason:</div>
          <textarea
            name="replace_session_reason"
            value={replace_session_reason}
            onChange={this.handle_reason}
            style={{ width: '300px', height: '100px' }}
          />
          <div style={{ fontSize: '10px' }}>
            * {200 - replace_session_reason.length} characters left
          </div>
        </div>
        <Button
          onClick={this.shift_session}
          disabled={!replace_session_uuid || !replace_session_reason}
        >
          Submit
        </Button>
      </div>
    );
  };

  open_add_student = () => this.setState({ add_student_open: true });

  close_add_student = () =>
    this.setState({ add_student_open: false, student_search_text: '', add_student_uuid: '' }, () =>
      this.props.set_loading_variable('student_search_status', 'none')
    );

  on_search_click = () => {
    const { student_search_text } = this.state;
    if (student_search_text.trim().length < 3) {
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.props.search_students({
        search_param: student_search_text
      });
    }
  };

  add_particpant = () => {
    const { session_details, add_student_uuid } = this.state;
    let url = get_api_url('add_participant_group_session');
    if (session_details.session_type === 'YOGA_WITH_FRIENDS_SESSION') {
      url = get_api_url('add_participant_private_group_session');
    }
    const payload = {
      student_uuid: add_student_uuid,
      teacher_uuid: session_details.teacher_uuid,
      group_session_uuid: session_details.session_uuid
    };
    this.setState({ add_student_loading: true }, () => {
      post_api(url, payload, true)
        .then((res) => {
          const session_uuid = res?.data?.session[0]?.session_uuid;
          trackUserDevice(session_uuid, 'SCHEDULE');
          this.props.set_notification_variable(true, 'success', 'Participant added');
          this.setState(
            {
              add_student_open: false,
              student_search_text: '',
              add_student_uuid: '',
              add_student_loading: false
            },
            () => {
              this.props.set_loading_variable('student_search_status', 'none');
              this.load_data();
            }
          );
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ add_student_loading: false });
        });
    });
  };

  on_key_press = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.on_search_click();
    }
  };

  render_add_student = () => {
    const { student_search_text, add_student_uuid, participants } = this.state;
    const student_select_values = [];
    this.props.search_students_list.map((st) => {
      const { student_uuid } = st;
      if (student_uuid) {
        const index = participants.findIndex((p) => p.student_uuid === student_uuid);
        if (index === -1 || (index !== -1 && participants[index].status === 'CANCELLED')) {
          const fname = st.sq_fname ? st.sq_fname : '';
          const mname = st.sq_mname ? st.sq_mname : '';
          const lname = st.sq_lname ? st.sq_lname : '';
          student_select_values.push({
            label: `${fname} ${mname} ${lname} (${st.email})`,
            value: student_uuid
          });
        }
      }
      return null;
    });
    return (
      <div>
        <h3>Add a participant to the session</h3>
        <div className="display-flex-between-center">
          <CustomInput
            label="Enter student name or email"
            onChange={this.onCommonChange}
            name="student_search_text"
            value={student_search_text}
            style={{ width: '300px' }}
            onKeyPress={this.on_key_press}
          />
          <Button disabled={student_search_text.length < 3} onClick={this.on_search_click}>
            Search student
          </Button>
        </div>
        {!!student_search_text && this.props.student_search_status === 'success' ? (
          <div>
            <CustomSingleSelect
              label="Select student"
              options={student_select_values}
              onChange={this.onCommonChange}
              name="add_student_uuid"
              value={add_student_uuid}
              style={{ width: '250px' }}
            />
            <Button disabled={!add_student_uuid} onClick={this.add_particpant}>
              Book Session
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  rencode_video = () => {
    const { session_details, add_student_uuid } = this.state;
    this.setState({ loading: true }, () => {
      const url = get_api_url('reencode_video_again');
      const payload = { session_uuid: session_details.session_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Video reencoded successfully');
          this.toggle_reencode();
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_rencode_confirm = () => (
    <div>
      <h4>Are you sure you want to rencode</h4>
      <Button onClick={this.rencode_video}>Confirm</Button>
      <Button onClick={this.toggle_reencode}>Cancel</Button>
    </div>
  );

  submit_change_status = () =>
    this.setState({ loading: true }, () => {
      const { session_details, status } = this.state;
      let url = get_api_url('update_group_class_status');
      if (session_details.session_type === 'YOGA_WITH_FRIENDS_SESSION') {
        url = get_api_url('update_friends_session_status');
      }
      const payload = { session_uuid: session_details.session_uuid, session_status: status };
      console.log(session_details);
      post_api(url, payload, true)
        .then(() => {
          this.close_change_status();
          this.props.set_notification_variable(true, 'success', 'Session updated successfully');
          this.load_data();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_change_status = () => (
    <div>
      <h4>Change session status</h4>
      <CustomSingleSelect
        label="Select session status"
        options={group_session_status}
        onChange={this.onCommonChange}
        name="status"
        value={this.state.status}
        style={{ width: '250px' }}
      />
      <Button onClick={this.submit_change_status}>Submit</Button>
    </div>
  );

  render_par_list = () => {
    const par_list = this.state.participants_emails;
    const par_list_keys = Object.keys(par_list);
    return (
      <div>
        <h4>Participants emails by status</h4>
        {par_list_keys.map((list_key) => (
          <div key={list_key}>
            <h5>Status: {list_key}</h5>
            <div style={{ marginTop: '5px', marginBottom: '10px' }}>
              {par_list[list_key].map((email) => (
                <div key={email}>{`${email},`}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  open_class_page = () => {
    const details = this.state.session_details;
    const pathname = `/group_class_page/${details.page_slug}`;
    this.props.history.push({
      pathname,
      state: {
        is_repeat_session: this.state.is_repeat_session
      }
    });
  };

  send_email = () => {
    const { participants, send_email_type } = this.state;
    const a_index = email_audience_types.findIndex((a) => a.value === this.state.send_email_type);
    const audience_label = email_audience_types[a_index].label;
    let email_list = [];
    let email_data = [];
    if (send_email_type === 'all') {
      email_data = participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    } else if (send_email_type === 'joined') {
      const joined_participants = participants.filter(
        (p) => p.status === 'FINISHED' || p.status === 'JOINED_BUT_CANCELLED'
      );
      email_data = joined_participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    } else if (send_email_type === 'didnt_join') {
      const not_joined_participants = participants.filter(
        (p) => p.status !== 'FINISHED' && p.status !== 'JOINED_BUT_CANCELLED'
      );
      email_data = not_joined_participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    } else if (send_email_type === 'cancelled') {
      const cancelled_participants = participants.filter(
        (p) => p.status === 'CANCELLED' || p.status === 'NO_SHOW_CANCELLATION_1HOUR'
      );
      email_data = cancelled_participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    } else if (send_email_type === 'members') {
      const members_participants = participants.filter((p) => p.participants_type === 'MEMBER');
      email_data = members_participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    } else if (send_email_type === 'non_members') {
      const non_members_participants = participants.filter((p) => p.participants_type !== 'MEMBER');
      email_data = non_members_participants.map((p) => ({ email: p.email, name: p.name }));
      email_list = email_data.map((p) => p.email);
    }
    this.props.history.push({
      pathname: '/createemail',
      state: {
        email_list,
        email_data
      }
    });
  };

  render_user_data = () => {
    const { participants, user_data_type, session_details } = this.state;
    let email_data = [];
    if (user_data_type === 'all') {
      email_data = participants.map((p) => ({ email: p.email, name: p.name }));
    } else if (user_data_type === 'joined') {
      const joined_participants = participants.filter(
        (p) => p.status === 'FINISHED' || p.status === 'JOINED_BUT_CANCELLED'
      );
      email_data = joined_participants.map((p) => ({ email: p.email, name: p.name }));
    } else if (user_data_type === 'didnt_join') {
      const not_joined_participants = participants.filter(
        (p) => p.status !== 'FINISHED' && p.status !== 'JOINED_BUT_CANCELLED'
      );
      email_data = not_joined_participants.map((p) => ({ email: p.email, name: p.name }));
    } else if (user_data_type === 'cancelled') {
      const cancelled_participants = participants.filter(
        (p) => p.status === 'CANCELLED' || p.status === 'NO_SHOW_CANCELLATION_1HOUR'
      );
      email_data = cancelled_participants.map((p) => ({ email: p.email, name: p.name }));
    } else if (user_data_type === 'members') {
      const members_participants = participants.filter((p) => p.participants_type === 'MEMBER');
      email_data = members_participants.map((p) => ({ email: p.email, name: p.name }));
    } else if (user_data_type === 'non_members') {
      const non_members_participants = participants.filter((p) => p.participants_type !== 'MEMBER');
      email_data = non_members_participants.map((p) => ({ email: p.email, name: p.name }));
    }
    const file_name = `${session_details.page_slug}_${moment(session_details.start_time).format('YYYY-MM-DD')}_${user_data_type}`;
    return (
      <div>
        <h4>Select set of users to Download data</h4>
        <CustomSingleSelect
          label="Select audience type"
          options={email_audience_types}
          onChange={this.onCommonChange}
          name="user_data_type"
          value={this.state.user_data_type}
          style={{ width: '250px' }}
        />
        {this.state.user_data_type ? (
          <ExcelFile
            element={<button className="data-download-btn">Download Data</button>}
            filename={file_name}
          >
            <ExcelSheet data={email_data} name="Sessions">
              <ExcelColumn label="Name" value="name" />
              <ExcelColumn label="Email" value="email" />
            </ExcelSheet>
          </ExcelFile>
        ) : null}
      </div>
    );
  };

  render_send_email = () => {
    if (this.state.send_email_confirm) {
      const a_index = email_audience_types.findIndex((a) => a.value === this.state.send_email_type);
      return (
        <div>
          <h4>Are you sure you want to send to below audience</h4>
          <div style={{ marginBottom: '20px' }}>{email_audience_types[a_index].label}</div>
          <Button onClick={() => this.setState({ send_email_confirm: false })}>Back</Button>
          <Button onClick={this.send_email}>Confirm</Button>
        </div>
      );
    }
    return (
      <div>
        <h4>Select set of users to send email</h4>
        <CustomSingleSelect
          label="Select audience type"
          options={email_audience_types}
          onChange={this.onCommonChange}
          name="send_email_type"
          value={this.state.send_email_type}
          style={{ width: '250px' }}
        />
        <Button
          disabled={!this.state.send_email_type}
          onClick={() => this.setState({ send_email_confirm: true })}
        >
          Next
        </Button>
      </div>
    );
  };

  toggle_email = () =>
    this.setState({
      open_send_email: !this.state.open_send_email,
      send_email_type: '',
      send_email_confirm: false
    });

  render_function = () => {
    if (this.state.page_error) {
      return <span>Oops! something happened. Check your url or try again</span>;
    }
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const details = this.state.session_details;
    return (
      <>
        {this.state.change_teacher_loading || this.state.replace_session_loading ? (
          <CustomLoading />
        ) : null}
        {this.render_session_details()}
        {this.render_replays()}
        <button onClick={this.toggle_raw_data}>Raw Data</button>
        <button onClick={this.toggle_user_data}>User Data</button>
        {details.session_type === 'GROUP_SESSION_STUDENT' ||
        details.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT' ? (
          <Button
            onClick={this.open_parent_session}
            disabled={!details.group_session_uuid && !details.yoga_with_friends_session_uuid}
          >
            Parent Session Details
          </Button>
        ) : null}
        {this.props.access_type !== 'VIDEO_EDITOR' && !isViewOnly(this.props.access_type) ? (
          <>
            {details.session_type === 'GROUP_SESSION' ? (
              <Button onClick={this.open_edit}>Edit</Button>
            ) : null}
            {this.render_buttons()}
            {(details.session_status === 'ACTIVE' || details.session_status === 'SCHEDULED') &&
            details.session_type !== 'YOGA_WITH_FRIENDS_SESSION_STUDENT' ? (
              <Button onClick={this.show_cancel_pop}>Cancel</Button>
            ) : null}
            {details.page_slug ? <Button onClick={this.open_class_page}>Class Page</Button> : null}
            {details.session_type !== 'WORKSHOP_SESSION' &&
            details.session_type !== 'YOGA_WITH_FRIENDS_SESSION' &&
            details.session_type !== 'YOGA_WITH_FRIENDS_SESSION_STUDENT' &&
            details.session_status !== 'ACTIVE' &&
            details.session_status !== 'SCHEDULED' ? (
              <Button onClick={this.open_change_status}>Change session status</Button>
            ) : null}
            {!is_production() && details.session_type === 'YOGA_WITH_FRIENDS_SESSION' ? (
              <Button onClick={this.open_change_status}>Change session status</Button>
            ) : null}

            <button onClick={this.toggle_email}>Send Email</button>
            <button onClick={this.show_edit_session}>Edit Session Status</button>
            {details.session_type === 'GROUP_SESSION_STUDENT' &&
            details.raw_data.ignore_student_rating === 0 ? (
              <button
                onClick={() =>
                  this.setState({
                    open_ignore: true,
                    ignore_student_rating: details.ignore_student_rating,
                    ignore_student_rating_reason: details.ignore_student_rating_reason
                  })
                }
              >
                Ignore Rating
              </button>
            ) : null}
          </>
        ) : null}
        {this.state.replacement_logs.length > 0 ? this.render_replacement_logs() : null}
        {this.state.participants.length > 0 ? this.render_participants() : null}
        {this.state.zoom_logs.length > 0 ? this.render_zoom_logs() : null}
        {this.state.email_data.length > 0 ? this.render_email_logs() : null}
        {this.state.invitees.length > 0 ? this.render_invitees() : null}
      </>
    );
  };

  toggle_raw_data = () => this.setState({ show_raw_data: !this.state.show_raw_data });

  render_raw_data = () => {
    const { session_details, show_raw_data, raw_data_search } = this.state;
    if (show_raw_data) {
      const { raw_data } = session_details;
      const object_keys = Object.keys(raw_data).sort();
      const filtered_keys = object_keys.filter(
        (k) => k.toLowerCase().indexOf(raw_data_search.toLowerCase()) !== -1
      );
      return (
        <div style={{ height: '80vh' }}>
          <CustomInput
            label="Search key value"
            style={{ width: '500px' }}
            value={raw_data_search}
            name="raw_data_search"
            onChange={this.handle_modal_change}
          />
          <div className="cus-table" style={{ marginBottom: '20px' }}>
            <div className="ct-row ct-h">
              <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
              <div className="ct-col ct-la ct-pl-10">Value</div>
            </div>
            {filtered_keys.map((key_val, index) => {
              const val = raw_data[key_val];
              if (typeof val !== 'object') {
                return (
                  <div className="ct-row" key={index + key_val}>
                    <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                      <HighlightSearch full_text={key_val} search_text={raw_data_search} />
                    </div>
                    <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">{val}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  confirm_hide_replay = () =>
    this.setState({ loading: true }, () => {
      const { session_details } = this.state;
      const payload = {
        hide_replay_from_student: session_details.hide_replay_from_student === 1 ? 0 : 1,
        session_uuid: session_details.session_uuid,
        student_uuid: this.state.student_uuid
      };
      const url = get_api_url('update_session_hide_replay_from_student');
      post_api(url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Value updated');
          this.load_data();
          this.toggle_hide_replay();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_hide_replay = () => {
    const { session_details } = this.state;
    const hide_text = session_details.hide_replay_from_student === 1 ? 'show' : 'hide';
    return (
      <div>
        <h4>{`Are you sure you want to ${hide_text} replay from student`}</h4>
        <button onClick={this.confirm_hide_replay}>Confirm</button>
        <button onClick={this.toggle_hide_replay}>Cancel</button>
      </div>
    );
  };

  toggle_hide_replay = () => this.setState({ hide_replay_open: !this.state.hide_replay_open });

  toggle_user_data = () => this.setState({ user_data_open: !this.state.user_data_open });

  submit_ignore = () =>
    this.setState({ loading: true }, () => {
      const { session_uuid, ignore_student_rating_reason, session_details } = this.state;
      const payload = { session_uuid, ignore_student_rating_reason };
      const url = get_api_url('session_ignore_rating');
      post_api(url, payload, true)
        .then((res) => {
          const new_session_details = { ...session_details };
          new_session_details.ignore_student_rating_reason = ignore_student_rating_reason;
          new_session_details.ignore_student_rating = 1;
          this.setState({ loading: false, session_details: new_session_details });
          this.close_ignore();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_ignore_rating = () => {
    const { ignore_student_rating_reason } = this.state;
    return (
      <div>
        <h4>Ignore Rating</h4>
        <textarea
          style={{ width: '500px', height: '200px' }}
          value={ignore_student_rating_reason}
          name="ignore_student_rating_reason"
          onChange={this.onCommonChange}
          maxLength="2000"
        />
        <Button onClick={this.submit_ignore} disabled={!ignore_student_rating_reason}>
          Submit
        </Button>
      </div>
    );
  };

  close_session_detail = () => {
    this.setState({
      show_session_detail: false
    });
  };

  show_edit_session = () => {
    this.setState({ show_session_detail: true });
  };

  handle_edit_modal_change = (e) => {
    if (e.target.name === 'status' && e.target.value !== 'CANCELLED') {
      this.setState({
        cancelled_by_student: false,
        cancelled_by_teacher: false
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      modal_error: false
    });
  };

  toggle_cancel = (option) => {
    let { status } = this.state;
    if (option === 'cancelled_by_student') {
      if (!this.state.cancelled_by_student) {
        status = 'CANCELLED';
      }
      this.setState({
        cancelled_by_student: !this.state.cancelled_by_student,
        cancelled_by_teacher: this.state.cancelled_by_student,
        status
      });
    } else if (option === 'cancelled_by_teacher') {
      if (!this.state.cancelled_by_teacher) {
        status = 'CANCELLED';
      }
      this.setState({
        cancelled_by_teacher: !this.state.cancelled_by_teacher,
        cancelled_by_student: this.state.cancelled_by_teacher,
        status
      });
    }
  };

  save_modal = () => {
    const {
      status,
      teacher_penalty,
      teacher_penalty_reason,
      session_uuid,
      cancelled_by_teacher,
      cancelled_by_student
    } = this.state;
    const p = parseInt(teacher_penalty, 10);
    if (p < 0) {
      this.setState({ modal_error: true });
    } else {
      this.setState({ loading: true, show_modal: false }, () => {
        const url = get_api_url('update_session');
        const payload = {
          session_uuid,
          teacher_penalty: p,
          status,
          teacher_penalty_reason,
          student_uuid: this.state.session_details.student_uuid
        };
        if (status === 'CANCELLED') {
          payload.cancelled_by_admin = 1;
        }
        if (cancelled_by_student) {
          payload.cancelled_by_student = 1;
          payload.cancelled_by_admin = 1;
        }
        if (cancelled_by_teacher) {
          payload.cancelled_by_teacher = 1;
          payload.cancelled_by_admin = 1;
        }
        post_api(url, payload, true)
          .then(() => {
            this.load_data();
            this.close_session_detail();
          })
          .catch(() => this.setState({ loading: false, page_error: true }));
      });
    }
  };

  render_edit_group_class_session = () => {
    if (this.state.loading) {
      return null;
    }
    return (
      <>
        <h5>Edit Session Details</h5>
        <div style={{ margin: '10px 0' }}>
          <CustomSingleSelect
            label="Change status"
            options={session_status_values}
            onChange={(e) => this.handle_edit_modal_change(e)}
            value={this.state.status || ''}
            style={{ width: '300px' }}
            name="status"
          />
        </div>
        {this.state.status === 'CANCELLED'
          ? cancel_options.map((option, index) => (
              <td key={index}>
                <CustomRadioButton
                  label={
                    option === 'cancelled_by_student'
                      ? 'Cancelled by Student'
                      : 'Cancelled by Teacher'
                  }
                  onRadioClick={() => this.toggle_cancel(option)}
                  selected={this.state[`${option}`]}
                />
              </td>
            ))
          : null}
        <CustomInput
          label="Enter Penalty"
          style={{ width: '300px' }}
          value={this.state.teacher_penalty}
          name="teacher_penalty"
          onChange={(e) => this.handle_edit_modal_change(e)}
          type="number"
        />
        <div style={{ margin: '10px 0' }}>
          <textarea
            value={this.state.teacher_penalty_reason}
            name="teacher_penalty_reason"
            onChange={(e) => this.handle_edit_modal_change(e)}
            style={{ width: '300px', height: '100px' }}
            maxLength="200"
            placeholder="Enter penalty reason (max 200 char)"
          />
        </div>
        {this.state.modal_error ? (
          <p style={{ color: 'red' }}>* Teacher penaly should be greater or equal to 0</p>
        ) : null}
        <Button onClick={this.save_modal}>Save</Button>
      </>
    );
  };

  close_ignore = () =>
    this.setState({
      open_ignore: false,
      ignore_student_rating: 0,
      ignore_student_rating_reason: ''
    });

  render() {
    const show_loading =
      this.props.student_search_status === 'loading' || this.state.add_student_loading;
    const {
      session_details,
      edit_workshop_open,
      open_send_email,
      hide_replay_open,
      change_host_open,
      open_ignore
    } = this.state;
    const session_type = session_details ? session_details.session_type : '';
    let title = '';
    switch (session_type) {
      case 'YOGA_WITH_FRIENDS_SESSION':
        title = 'Friends group class details';
        break;
      case 'YOGA_WITH_FRIENDS_SESSION_STUDENT':
        title = 'Friends private group class details';
        break;
      case 'GROUP_SESSION_STUDENT':
        title = 'Student group class details';
        break;
      case 'WORKSHOP_SESSION':
        title = 'Workshop session details';
        break;
      default:
        title = 'Group class details';
        break;
    }
    return (
      <CommonHeader loading={show_loading} title={title} show_back>
        <CustomModal show={change_host_open} close={this.toggle_change_host}>
          {this.render_change_host()}
        </CustomModal>
        <CustomModal show={this.state.open_change_status} close={this.close_change_status}>
          {this.render_change_status()}
        </CustomModal>
        <CustomModal show={this.state.user_data_open} close={this.toggle_user_data}>
          {this.render_user_data()}
        </CustomModal>
        <CustomModal show={this.state.edit_details} close={this.close_edit}>
          {this.render_edit_details()}
        </CustomModal>
        <CustomModal show={this.state.change_teacher_open} close={this.close_edit}>
          {this.render_change_teacher()}
        </CustomModal>
        <CustomModal
          show={this.state.change_session_time}
          close={this.close_edit}
          inner_style={{ minHeight: '50%' }}
        >
          {this.render_change_time()}
        </CustomModal>
        <CustomModal show={this.state.cancel_open} close={this.close_cancel}>
          {this.render_cancel()}
        </CustomModal>
        <CustomModal show={this.state.shift_open} close={this.close_shift}>
          {this.render_shift()}
        </CustomModal>
        <CustomModal show={this.state.add_student_open} close={this.close_add_student}>
          {this.render_add_student()}
        </CustomModal>
        <CustomModal show={this.state.rencode_modal_open} close={this.toggle_reencode}>
          {this.render_rencode_confirm()}
        </CustomModal>
        <CustomModal show={this.state.par_list_open} close={this.toggle_par_list}>
          {this.render_par_list()}
        </CustomModal>
        <CustomModal show={edit_workshop_open} close={this.close_workshop_edit}>
          {this.render_edit_workshop()}
        </CustomModal>
        <CustomModal show={this.state.show_raw_data} close={this.toggle_raw_data}>
          {this.render_raw_data()}
        </CustomModal>
        <CustomModal show={open_send_email} close={this.toggle_email}>
          {this.render_send_email()}
        </CustomModal>
        <CustomModal show={hide_replay_open} close={this.toggle_hide_replay}>
          {this.render_hide_replay()}
        </CustomModal>
        <CustomModal show={open_ignore} close={this.close_ignore}>
          {this.render_ignore_rating()}
        </CustomModal>
        <CustomModal show={this.state.penalty_open} close={this.close_penalty}>
          {this.render_penalty_popup()}
        </CustomModal>
        <CustomModal show={this.state.show_session_detail} close={this.close_session_detail}>
          {this.render_edit_group_class_session()}
        </CustomModal>
        {this.render_function()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  teachers_list: state.teachers.teachers_list,
  teachers_list_status: state.teachers.teachers_list_status,
  search_students_list: state.students.search_students_list,
  student_search_status: state.loading.student_search_status,
  class_types: state.home.class_types,
  health_keywords: state.home.health_keywords,
  class_types_status: state.loading.class_types_status,
  access_type: state.home.access_type,
  worshops_status: state.loading.worshops_status,
  workshops: state.workshops.workshops
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  get_teachers_list: (status) => {
    dispatch(actions.get_teachers_list(status));
  },
  search_students: (payload) => {
    dispatch(actions.search_students(payload));
  },
  set_loading_variable: (key, payload) => {
    dispatch(actions.set_loading_variable(key, payload));
  },
  get_class_types: (payload) => dispatch(actions.get_class_types(payload)),
  get_all_workshops: () => dispatch(actions.get_all_workshops())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSessionDetails);
