import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class TeacherMailLogs extends Component {
  state = {
    start_date: moment().subtract(1, 'days').toDate(),
    loading: true,
    url_error: false,
    email_data: []
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    if (uuid) {
      this.setState({ teacher_uuid: uuid }, () => this.load_data());
    } else {
      this.setState({ loading: false, url_error: true });
    }
  }

  load_data = () => {
    // TODO: blocked to fix ses-notification
    // this.setState({ loading: true }, () => {
    //     const { start_date, teacher_uuid } = this.state;
    //     const payload = {
    //         teacher_uuid,
    //     }
    //     const url = get_api_url('get_email_logs_teacher', true);
    //     post_api(url, payload, true)
    //         .then(res => {
    //             this.setState({ email_data: [...res.data.email_data], loading: false })
    //         })
    //         .catch(e => console.log(e))
    // })
  };

  render_data = () => {
    const { email_data } = this.state;
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col">Type</div>
          <div className="ct-col">Delivered</div>
          <div className="ct-col">Opened</div>
          <div className="ct-col">Primary</div>
          <div className="ct-col">Updated</div>
          <div className="ct-col">Error</div>
        </div>
        {email_data.map((email) => (
          <div className="ct-row" key={email.uuid}>
            <div className="ct-col ct-sm-font ct-bw">{email.email_type}</div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.is_delivered === 1
                ? moment(email.delivery_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.is_opened === 1
                ? moment(email.last_opened_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font ct-bw">
              {email.primary_action_count > 0
                ? moment(email.primary_action_last_opened_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm A z')
                : '--'}
            </div>
            <div className="ct-col ct-sm-font">
              {moment(email.modified_date)
                .tz(this.props.iana_timezone)
                .format('YYYY-MM-DD hh:mm A z')}
            </div>
            <div className="ct-col">{email.is_error === 1 ? 'True' : '--'}</div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, url_error } = this.state;
    return (
      <CommonHeader loading={loading} show_back title="Teacher Email Logs">
        {!url_error ? (
          this.render_data()
        ) : (
          <h4 style={{ textAlign: 'center' }}>Wrong link. Try again</h4>
        )}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherMailLogs);
