import { GraphQLClient, gql } from 'graphql-request';

const url = 'https://api-us-east-1.graphcms.com/v2/ckfpk1rt219bu01yufzm01oxg/master';
const graphQLClient = new GraphQLClient(url, {
  headers: {
    authorization:
      'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MDM2NTU1OTYsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NrZnBrMXJ0MjE5YnUwMXl1ZnptMDFveGcvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiODkyOGZkNTItN2M2NC00MWJkLThlZjUtNWUwYjQyYThlNjNjIiwianRpIjoiY2tncGo0eGR5Zm42NjAxd2ZjNGo4M3FoayJ9.G-lANigjqHejNId0SVBUSEFKKEd5T6K21o0WMNKkFi9KAdcdrMTcVxnn-mktmCWUKRC1PYZiW0psL3-NHMBDaq_2ZR323VwtnYpp95P-tQiduBZYc-KPc8qpTR4eYl3JlGc8UOL_WzCksZFHI0Kj0gMopPDr-6RKNCaiDXj2tUvanAS3A1LFE7biTU843s1XEdDUc8mZCM1JWrmVCc8Spr6xgcZPEzgq6t9y-808D9vxiQsxOssY3sjslOCbbS20ZMJBRrNXn4CblvGcy6vNysiqVSZmTDW3MqeWl9LAwGe0a9eWYu1ySjhHkan_eRAhh-YKrPKuSXZimDjqO5qvbysmzfHGkhK8indas6p2wIl3B86jZp7pdlEeDMRFVXG-yXb_yT-3WdPfLWZz6nzSk5xoyrHHNzH3kvaCKVHQQlhDLlkbFhfBN0y-x7ngPFbbh71d_HFwczJq5FFGwS5Xq0x_faOniCqPSSYht2o7ogMzrSQMUdx_1Vb6fYfZMJvPQC6l0eoADVrJ3CdzpOYPWYBwPJzwzKPUn7tnEJHkjS528FnBzhxFzm6lz8xtdy20LszVhmIJAAEBRDBh2PD0sRsexL8Fbt1Ca_kQgzo04O4UNTkiK2VtaYi6EwBWr4K1uwP-NseEfGHSsX-FZfZCtjtzX7d5IdXI5gexl0mxYZE'
  }
});

export const graphBlogPostApi = async function (slug) {
  try {
    const query = gql`{
							post(where: {slug: "${slug}"}) {
                                title, date, excerpt,tags,content {html}, authorName, subTitle,coverUrl
							}
                        }`;
    const resp = await graphQLClient.request(query);
    return resp.post;
  } catch (e) {
    throw e;
  }
};

export const searchBlogPosts = async function (search_text) {
  try {
    const query = gql`{
            posts(where: { _search: "${search_text}"}) {
              id
              slug
              title
            }
          }`;
    console.log('query ', query);
    const resp = await graphQLClient.request(query);
    return resp.posts;
  } catch (e) {
    throw e;
  }
};

export default graphBlogPostApi;
