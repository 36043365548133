import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import Button from '../../../util_components/button';
import { get_api_url } from '../../../../utils/urls';

class EmailDeliveryReportDay extends Component {
  state = {
    start_date: moment().toDate(),
    end_date: moment().toDate(),
    loading: true,
    email_data: [],
    show_per: false
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const date = url.searchParams.get('date');
    if (date) {
      this.setState({ start_date: moment(date).toDate(), end_date: moment(date).toDate() });
    } else {
      this.setState({ start_date: moment().toDate(), end_date: moment().toDate() });
    }
    this.load_data();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.iana_timezone !== this.props.iana_timezone) {
      this.load_data();
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { start_date, end_date } = this.state;
      const url = get_api_url('email_report_data_single_day');
      const payload = {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        iana_timezone: this.props.iana_timezone
      };
      post_api(url, payload, true)
        .then((response) => {
          this.setState({
            loading: false,
            email_data: [...response.data.email_data],
            bounces: [...response.data.bounces],
            complaints: [...response.data.complaints]
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  toggle_per = () => this.setState({ show_per: !this.state.show_per });

  render_emails = () => {
    const { email_data, show_per } = this.state;
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Email Data:
          <Button onClick={this.toggle_per}>{show_per ? 'Show values' : 'Show rates'}</Button>
        </div>
        <div className="cus-table">
          <div className="ct-row ct-h ct-sm-font">
            <div className="ct-col ct-bw">Type</div>
            <div className="ct-col ct-hf">Total</div>
            <div className="ct-col ct-hf">Delivered</div>
            <div className="ct-col ct-hf">Errors</div>
            <div className="ct-col ct-hf">Bounced</div>
            <div className="ct-col ct-hf">Complaints</div>
            <div className="ct-col ct-hf">Opened</div>
            <div className="ct-col ct-hf">Clicked</div>
            <div className="ct-col ct-hf">Total Clicks</div>
          </div>
          {email_data.map((email, index) => (
            <div className="ct-row ct-sm-font" key={index + email.email_type}>
              <div className="ct-col ct-bw">{email.email_type}</div>
              <div className="ct-col ct-hf">{email.total_emails}</div>
              <div className="ct-col ct-hf">
                {!show_per ? email.delivered_emails : email.delivered_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.error_emails : email.error_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.bounce_emails : email.bounce_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.complaint_emails : email.complaint_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.opened_emails : email.opened_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.primary_emails : email.primary_perc}
              </div>
              <div className="ct-col ct-hf">
                {!show_per ? email.total_clicks : email.clicks_perc}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  handle_date_chage = (date, component) => this.setState({ [component]: date });

  render_filters = () => (
    <div>
      <hr />
      <div className="teacher-availability-filters">
        <div className="teacher-availability-block">
          <div>Start Date:</div>
          <DatePicker
            selected={this.state.start_date}
            maxDate={this.state.end_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'start_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <div>End Date:</div>
          <DatePicker
            selected={this.state.end_date}
            minDate={this.state.start_date}
            dateFormat="yyyy-MM-dd"
            onChange={(date_selected) => this.handle_date_chage(date_selected, 'end_date')}
          />
        </div>
        <div className="teacher-availability-block">
          <button style={{ marginTop: '25px' }} onClick={this.load_data}>
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );

  render() {
    return (
      <div className="common-page-container">
        {this.state.loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="common-page-title">Email Delivery by Date</div>
        </div>
        {this.render_filters()}
        {this.render_emails()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(EmailDeliveryReportDay);
