import React, { useEffect, useState } from 'react';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomLoading from '../../util_components/custom_loading';
import './Session.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { get_api_url } from '../../../utils/urls';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { date_drop_for_report } from '../../../constants';
import { get_date_filter_data_timezone_utc } from '../../../util_functions';
import CustomDatePicker from '../../util_components/custom_date_picker';

function GptSessionNotes() {
  const [filter_teacher, setFilterTeacher] = useState('all');
  const [sessionSummaries, setSessionSummaries] = useState([]);
  const [grp_sess_teach_filters, setGrpSessTeachFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilterType, setDateFilterType] = useState('');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const history = useHistory();
  const dispatch = useDispatch();
  const teachers_list = useSelector((state) => state.teachers.teachers_list);
  const iana_timezone = useSelector((state) => state.home.iana_timezone);

  useEffect(() => {
    setTeacherList();
  }, [teachers_list]);

  useEffect(() => {
    if (!teachers_list || teachers_list.length === 0) {
      dispatch(actions.get_teachers_list());
    }
  }, [teachers_list]);

  useEffect(() => {
    if (dateFilterType !== 'CUSTOM') {
      fetchSessionSummaries();
    }
  }, [filter_teacher, dateFilterType, startDate, endDate]);

  const setTeacherList = () => {
    const all_teachers = teachers_list.filter(
      (t) => t.onboarding_status === 'APPROVED' || t.onboarding_status === 'UNDER_SCRUTINY'
    );
    const new_grp_sess_teach_filters = all_teachers.map((teacher) => ({
      value: teacher.uuid,
      label: `${teacher.first_name} ${teacher.last_name}`
    }));
    new_grp_sess_teach_filters.sort((a, b) => a.label.localeCompare(b.label));
    new_grp_sess_teach_filters.unshift({ value: 'all', label: 'All' });
    setGrpSessTeachFilters(new_grp_sess_teach_filters);
  };

  const fetchSessionSummaries = async () => {
    const url = get_api_url('get_all_session_summary');
    const date_data = get_date_filter_data_timezone_utc(dateFilterType, startDate, endDate);
    setSessionSummaries([]);
    setLoading(true);
    const payload = {
      teacher_uuid: filter_teacher !== 'all' ? filter_teacher : '',
      start_date: date_data.utc_start_date,
      end_date: date_data.utc_end_date,
      iana_timezone
    };
    try {
      const response = await post_api(url, payload, true);
      if (response.data.status === 'success') {
        setSessionSummaries(response?.data?.all_session_data || []);
      }
    } catch (error) {
      console.error('Error fetching session summaries:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterSessions = () => {
    const tea_fil_sess = sessionSummaries.filter(
      (sess) => sess.teacher_uuid.indexOf(filter_teacher) !== -1
    );

    let date_fil_sess = [];
    if (dateFilterType === 'CUSTOM') {
      date_fil_sess = tea_fil_sess.filter((sess) => {
        const sessionDate = moment(sess.epoch_start_time).tz(iana_timezone).format('YYYY-MM-DD');
        return moment(sessionDate).isBetween(startDate, endDate, 'day', '[]');
      });
    } else {
      date_fil_sess = tea_fil_sess;
    }
    setSessionSummaries(date_fil_sess);
    setLoading(false);
  };

  const handleTeacherChange = (e) => {
    const { value } = e.target;
    setFilterTeacher(value);
    filterSessions(value);
  };

  const handleDetailsClick = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    history.push({
      pathname: '/gpt_session_notes/session_summary',
      search
    });
  };

  const changeDateFilter = (e) => {
    const newDateFilterType = e.target.value;
    setDateFilterType(newDateFilterType);
  };

  const handle_date_change = (type, value) => {
    if (type === 'start_date') {
      setStartDate(value);
    } else if (type === 'end_date') {
      setEndDate(value);
    }
  };

  const handleSubmit = () => {
    fetchSessionSummaries();
  };

  function SelectHeader() {
    return (
      <>
        <p className="mb-2.5 mt-0">Select a Teacher or Date</p>
        <div className="flex items-center gap-5">
          <CustomSingleSelect
            labelClass="hidden"
            label=""
            options={grp_sess_teach_filters}
            name="filter_teacher"
            onChange={handleTeacherChange}
            value={filter_teacher}
            style={{ width: '250px' }}
          />

          <div style={{ marginRight: '10px' }}>
            <CustomSingleSelect
              label="set date filter"
              labelClass="hidden"
              options={date_drop_for_report}
              onChange={changeDateFilter}
              value={dateFilterType}
              style={{ width: '200px' }}
            />
          </div>
          <button
            onClick={() => {
              setFilterTeacher('all');
              setDateFilterType('');
              setStartDate(moment().format('YYYY-MM-DD'));
              setEndDate(moment().format('YYYY-MM-DD'));
            }}
          >
            Clear Filter
          </button>
        </div>
        <div className="flex items-center">
          {dateFilterType === 'CUSTOM' ? (
            <>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="Start Date"
                  value={startDate}
                  handleChange={(val) => handle_date_change('start_date', val)}
                  maxDate={endDate}
                />
              </div>
              <div className="daily-report-item">
                <CustomDatePicker
                  label="End Date"
                  value={endDate}
                  handleChange={(val) => handle_date_change('end_date', val)}
                  minDate={startDate}
                  maxDate={moment().format('YYYY-MM-DD')}
                />
              </div>
              <button className="mt-[25px]" onClick={handleSubmit}>
                Submit
              </button>
            </>
          ) : null}
        </div>
      </>
    );
  }
  return (
    <>
      {loading && <CustomLoading />}
      <div className="common-page-container h-full">
        <div className="font-bold text-black text-[30px] mb-5">GPT Session Notes: Extracted</div>
        <SelectHeader />
        <table className="session-table">
          <thead>
            {loading ? null : (
              <tr>
                <th>No.</th>
                <th>Student Name</th>
                <th>Teacher Name</th>
                <th>Issued</th>
                <th>Status</th>
                <th />
              </tr>
            )}
            {sessionSummaries.length > 0 ? (
              sessionSummaries.map((session, index) => (
                <tr key={session.session_uuid}>
                  <td>{index + 1}</td>
                  <td>{session?.student_name}</td>
                  <td>{session?.teacher_name}</td>
                  <td>
                    {moment(session?.summary_created_date)?.format('MMM DD, YYYY [at] hh:mm') || ''}
                  </td>
                  <td>{session?.summary_status}</td>
                  <td>
                    <button onClick={() => handleDetailsClick(session?.session_uuid)}>
                      Details
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div className=" w-full p-5 font-bold text-2xl">
                {loading ? null : 'No Data Found'}
              </div>
            )}
          </thead>
        </table>
      </div>
    </>
  );
}

export default GptSessionNotes;
