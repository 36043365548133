import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import CustomCheckBox from '../../../util_components/custom_checkbox';
import { post_api } from '../../../../redux/api_funcs';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';
import './pref.css';

class Preferences extends Component {
  state = {
    loading: true,
    preferences: {}
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    const name = url.searchParams.get('name');
    if (uuid) {
      this.setState({ student_uuid: uuid, name }, () => this.load_data());
    } else {
      this.props.history.push('/users');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { student_uuid } = this.state;
      const url = get_api_url('student_notification_preferences');
      const payload = { student_uuid };
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ preferences: res.data.preferences, loading: false });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  toggle_preference = (key) => {
    let preferences = { ...this.state.preferences };
    const value = preferences[key];
    const new_value = value === 1 ? 0 : 1;
    preferences = { ...preferences, [key]: new_value };
    this.setState({ preferences });
  };

  save_preferences = () => {
    const { student_uuid, preferences } = this.state;
    const payload = { student_uuid, ...preferences };
    const url = get_api_url('update_student_notification_preferences');
    this.setState({ loading: true }, () => {
      post_api(url, payload, true)
        .then((res) => {
          this.setState({ preferences: { ...res.data.preferences }, loading: false });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });
  };

  render_preferences = () => {
    const { preferences } = this.state;
    return (
      <>
        <div className="pref-row pref-row-top">
          <div className="pref-title" />
          <div className="pref-title-other">Email</div>
          <div className="pref-title-other">Push Notifications</div>
          <div className="pref-title-other">SMS</div>
        </div>
        <div className="pref-row-title">Class Scheduling</div>
        <div className="pref-row pref-row-top">
          <div className="pref-title">Booking confirmation</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.booking_confirmation_email === 1}
              onClick={() => this.toggle_preference('booking_confirmation_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.booking_confirmation_push === 1}
              onClick={() => this.toggle_preference('booking_confirmation_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.booking_confirmation_sms === 1}
              onClick={() => this.toggle_preference('booking_confirmation_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Cancellation</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.cancellation_email === 1}
              onClick={() => this.toggle_preference('cancellation_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.cancellation_push === 1}
              onClick={() => this.toggle_preference('cancellation_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.cancellation_sms === 1}
              onClick={() => this.toggle_preference('cancellation_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Teacher replacement</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.teacher_replacement_email === 1}
              onClick={() => this.toggle_preference('teacher_replacement_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.teacher_replacement_push === 1}
              onClick={() => this.toggle_preference('teacher_replacement_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.teacher_replacement_sms === 1}
              onClick={() => this.toggle_preference('teacher_replacement_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Time change</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.time_change_email === 1}
              onClick={() => this.toggle_preference('time_change_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.time_change_push === 1}
              onClick={() => this.toggle_preference('time_change_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.time_change_sms === 1}
              onClick={() => this.toggle_preference('time_change_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Daily upcoming classes</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.group_class_blast_email === 1}
              onClick={() => this.toggle_preference('group_class_blast_email')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row-title">Class Reminders</div>
        <div className="pref-row pref-row-top">
          <div className="pref-title">30 mins before class</div>
          <div className="pref-title-other" />
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_10_min_push === 1}
              onClick={() => this.toggle_preference('reminder_10_min_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_10_min_sms === 1}
              onClick={() => this.toggle_preference('reminder_10_min_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">1 hour before class</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_hour_email === 1}
              onClick={() => this.toggle_preference('reminder_1_hour_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_hour_push === 1}
              onClick={() => this.toggle_preference('reminder_1_hour_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_hour_sms === 1}
              onClick={() => this.toggle_preference('reminder_1_hour_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">24 hours before class</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_day_email === 1}
              onClick={() => this.toggle_preference('reminder_1_day_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_day_push === 1}
              onClick={() => this.toggle_preference('reminder_1_day_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.reminder_1_day_sms === 1}
              onClick={() => this.toggle_preference('reminder_1_day_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row-title">After Class</div>
        <div className="pref-row pref-row-top">
          <div className="pref-title">Feedback</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.feedback_email === 1}
              onClick={() => this.toggle_preference('feedback_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.feedback_push === 1}
              onClick={() => this.toggle_preference('feedback_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.feedback_sms === 1}
              onClick={() => this.toggle_preference('feedback_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Recording Availability</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.recording_email === 1}
              onClick={() => this.toggle_preference('recording_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.recording_push === 1}
              onClick={() => this.toggle_preference('recording_push')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.recording_sms === 1}
              onClick={() => this.toggle_preference('recording_sms')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row-title">Account & Marketing</div>
        <div className="pref-row pref-row-top">
          <div className="pref-title">24 hours before payment</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.payment_reminder_1_day_email === 1}
              onClick={() => this.toggle_preference('payment_reminder_1_day_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.payment_reminder_1_day_push === 1}
              onClick={() => this.toggle_preference('payment_reminder_1_day_push')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Payment receipt</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.payment_receipt_email === 1}
              onClick={() => this.toggle_preference('payment_receipt_email')}
              show_label={false}
            />
          </div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.payment_receipt_push === 1}
              onClick={() => this.toggle_preference('payment_receipt_push')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row">
          <div className="pref-title">Marketing emails</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.marketing_emails === 1}
              onClick={() => this.toggle_preference('marketing_emails')}
              show_label={false}
            />
          </div>
        </div>
        <div className="pref-row-title">SMS Opt IN</div>
        <div className="pref-row pref-row-top">
          <div className="pref-title">SMS Opt In</div>
          <div className="pref-title-other">
            <CustomCheckBox
              checked={preferences.opt_in_sms === 1}
              onClick={() => this.toggle_preference('opt_in_sms')}
              show_label={false}
            />
          </div>
        </div>
        <>
          <div className="pref-row-title">Blocked Email</div>
          <div className="pref-row pref-row-top">
            <div className="pref-title">Blocked Email</div>
            <div className="pref-title-other">
              <CustomCheckBox
                checked={preferences.flagged_email === 1}
                onClick={() => this.toggle_preference('flagged_email')}
                show_label={false}
              />
            </div>
          </div>
        </>
        <button style={{ marginTop: '20px' }} onClick={this.save_preferences}>
          Save
        </button>
      </>
    );
  };

  render() {
    const { loading, name } = this.state;
    return (
      <div className="common-page-container">
        {loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="profile-go-back" onClick={() => this.props.history.goBack()}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">
            Notification Preferences
            {name ? <div>{`(${name})`}</div> : null}
          </div>
        </div>
        {this.render_preferences()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
