import { all, put, takeLatest, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { get_api, post_api, api_with_method, put_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* getCategoryData() {
  try {
    yield put(actions.set_content_variable('get_content_category_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/tags', false, true);

    const response = yield call(get_api, url, true);
    const tags = response.data.data;
    yield put(actions.set_content_variable('get_content_category_response', tags));
    yield put(actions.set_content_variable('get_content_category_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('get_content_category_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* getCategoryList() {
  try {
    yield put(actions.set_content_variable('get_category_list_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/categories', false, true);

    const response = yield call(get_api, url, true);
    const tags = [{ label: 'ALL', value: 'all' }, ...response.data.data];
    yield put(actions.set_content_variable('get_category_list_response', tags));
    yield put(actions.set_content_variable('get_category_list_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('get_category_list_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* addNewTag(action) {
  try {
    yield put(actions.set_content_variable('add_new_tag_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/tags', false, true);
    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.set_content_variable('add_new_tag_status', 'success'));
  } catch (error) {
    yield put(
      actions.set_notification_variable(
        true,
        'error',
        error.response.data.reason ? error.response.data.reason : error.response.data.message
      )
    );
    yield put(actions.set_content_variable('add_new_tag_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* deleteTag(action) {
  try {
    yield put(actions.set_content_variable('delete_tag_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/tags', false, true);
    const response = yield call(api_with_method, 'delete', url, action.payload, true);
    console.log('response: ', response);
    yield put(actions.set_content_variable('delete_tag_status', 'success'));
  } catch (error) {
    console.log('error: ', error);
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('delete_tag_status', 'fail'));
  }
}

export function* updateTag(action) {
  try {
    yield put(actions.set_content_variable('update_tag_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/tags', false, true);
    const response = yield call(put_api, url, action.payload, true);
    console.log('response: ', response);
    yield put(actions.set_content_variable('update_tag_status', 'success'));
  } catch (error) {
    console.log('error: ', error);
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('update_tag_status', 'fail'));
  }
}

export function* getAllBlogs(action) {
  try {
    yield put(actions.set_content_variable('get_all_blogs_status', 'loading'));
    const url = get_api_url('get_blog_posts', false, false);

    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.set_content_variable('get_all_blogs_response', response.data));
    yield put(actions.set_content_variable('get_all_blogs_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('get_all_blogs_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* getAllAssociatedTags(action) {
  try {
    yield put(actions.set_content_variable('get_all_associated_tags_status', 'loading'));
    const url = get_api_url(action.payload.url, false, true);
    const response = yield call(get_api, url, action.payload, true);

    yield put(actions.set_content_variable('get_all_associated_tags', response.data.data));
    yield put(actions.set_content_variable('get_all_associated_tags_status', 'success'));
  } catch (error) {
    console.log('error: ', error);
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('get_all_associated_tags_status', 'fail'));
  }
}

export function* updateTagAssociations(action) {
  try {
    yield put(actions.set_content_variable('update_tag_associations_status', 'loading'));
    const url = get_api_url(action.payload.url, false, true);
    const response = yield call(post_api, url, action.payload.payload, true);
    console.log('response: ', response);
    yield put(actions.set_content_variable('update_tag_associations_status', 'success'));
  } catch (error) {
    console.log('error: ', error);
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('update_tag_associations_status', 'fail'));
  }
}

export function* addNewCategory(action) {
  try {
    yield put(actions.set_content_variable('add_new_category_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/categories', false, true);
    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.set_content_variable('add_new_category_status', 'success'));
  } catch (error) {
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('add_new_category_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* updateCategory(action) {
  try {
    yield put(actions.set_content_variable('update_category_status', 'loading'));
    const url = get_api_url('/v2/entity_tags/categories', false, true);
    const response = yield call(put_api, url, action.payload, true);
    console.log('response: ', response);
    yield put(actions.set_content_variable('update_category_status', 'success'));
  } catch (error) {
    console.log('error: ', error);
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_notification_variable(true, 'error', error.response.data.message));
    yield put(actions.set_content_variable('update_category_status', 'fail'));
  }
}

export default function* contentSaga() {
  yield all([
    takeLatest(t.get_category_data, getCategoryData),
    takeLatest(t.get_category_list, getCategoryList),
    takeLatest(t.add_new_tag, addNewTag),
    takeLatest(t.delete_tag, deleteTag),
    takeLatest(t.update_tag, updateTag),
    takeLatest(t.get_all_blogs, getAllBlogs),
    takeLatest(t.get_all_associated_tags, getAllAssociatedTags),
    takeLatest(t.update_tag_associations, updateTagAssociations),
    takeLatest(t.add_new_category, addNewCategory),
    takeLatest(t.update_category, updateCategory)
  ]);
}
