import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../../util_components/custom_loading';
import { image_error_replace, combine_name, check_null_text } from '../../../../util_functions';
import ScrollContainer from '../../../util_components/scroll_container';
import HighlightSearch from '../../../util_components/highlight_search_text';
import * as actions from '../../../../redux/action_creators';
import './list.css';

class StudentsList extends PureComponent {
  state = {
    students: [],
    modified_students: [],
    search_text: ''
  };

  componentDidMount() {
    if (this.props.filtered_students.length === 0) {
      // this.props.get_all_students();
    }
  }

  handle_search_input = (e) => {
    this.props.set_students_variable('search_students_list', []);
    this.props.set_loading_variable('student_search_status', 'none');
    this.props.set_students_variable('search_value', e.target.value);
    // this.setState({ search_text: e.target.value })
  };

  handle_search_text = (e) => this.props.filter_students(e.target.name, e.target.value);

  render_level_dropdown = () => (
    <select name="yoga_level" onChange={this.handle_search_text} value={this.props.yoga_level}>
      <option value="ALL">ALL</option>
      <option value="NEWBIE">NEWBIE</option>
      <option value="INTERMEDIATE">INTERMEDIATE</option>
      <option value="ADVANCED">ADVANCED</option>
    </select>
  );

  render_credits_dropdown = () => (
    <select name="credits" onChange={this.handle_search_text} value={this.props.credits}>
      <option value="ALL">ALL</option>
      <option value="0">0</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="75">75</option>
      <option value="100">100</option>
      <option value="125">125</option>
      <option value=">125">More than 125</option>
    </select>
  );

  render_type_dropdown = () => (
    <select name="user_type" onChange={this.handle_search_text} value={this.props.user_type}>
      <option value="ALL">ALL</option>
      <option value="REAL_USER">REAL USERS</option>
      <option value="TEST_USER">TEST USER</option>
      <option value="TEACHER_EVALUATOR">TEACHER EVALUATOR</option>
    </select>
  );

  open_student_profile = (e, client_domain) => {
    const url = '/studentprofile';
    const uuid = e.currentTarget.dataset.id;
    if (uuid) {
      const search = `?id=${uuid}`;
      this.props.history.push({
        pathname: url,
        search,
        state: { client_domain }
      });
    }
  };

  on_search_click = () => {
    const { search_value } = this.props;
    if (search_value.trim().length < 3) {
      this.props.set_notification_variable(
        true,
        'error',
        'Please type atlease 3 letters to search'
      );
    } else {
      this.props.search_students({
        search_param: search_value
      });
    }
  };

  input_handle_key = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      this.on_search_click();
    }
  };

  render_students = () => {
    const students = [...this.props.search_students_list];
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col ct-qf">
            <img src="" alt="i" onError={image_error_replace} />
          </div>
          <div className="ct-col">Student (slug)</div>
          <div className="ct-col">EMAIL VERIFIED</div>
          {/* <div className="ct-col">USER TYPE</div> */}
          <div className="ct-col">PHONE</div>
          <div className="ct-col">YOGA LEVEL</div>
          <div className="ct-col ct-hf">CREDITS</div>
        </div>
        {students.map((student, index) => {
          const profile_photo = student.profile_photo ? student.profile_photo : '';
          const tr_class = student.student_uuid
            ? 'ct-row ct-cur tr-cursor-row'
            : 'ct-row tr-no-uuid';
          const sq_name = combine_name(student.sq_fname, student.sq_mname, student.sq_lname);
          const name = combine_name(student.first_name, student.middle_name, student.last_name);
          const st_slug = student.slug ? student.slug : '';
          const client_domain = student.client_domain
            ? student.client_domain === 'lifestyle'
              ? ''
              : 'Lifetree Health'
            : '';
          return (
            <div
              className={tr_class}
              key={student.student_uuid ? student.student_uuid : index}
              onClick={(e) => this.open_student_profile(e, student.client_domain)}
              data-id={student.student_uuid}
            >
              <div className="ct-col ct-qf">
                <img src={profile_photo} alt="i" onError={image_error_replace} />
              </div>
              <div className="ct-col ct-bw">
                <div style={{ fontSize: '17px' }}>
                  <HighlightSearch
                    full_text={name || sq_name}
                    search_text={this.props.search_value}
                  />
                  {st_slug ? (
                    <span className="sl-span">
                      (<HighlightSearch full_text={st_slug} search_text={this.props.search_value} />
                      )
                    </span>
                  ) : null}
                </div>
                {sq_name.trim().length > 0 &&
                sq_name.trim() !== 'User' &&
                !!name &&
                sq_name !== name ? (
                  <div style={{ fontSize: '14px', opacity: '0.7' }}>
                    <HighlightSearch full_text={sq_name} search_text={this.props.search_value} />
                  </div>
                ) : null}
                <div className="list-email ct-sm-font">
                  <HighlightSearch
                    full_text={student.email ? student.email : student.sq_email}
                    search_text={this.props.search_value}
                  />
                </div>
                {client_domain === '' ? null : (
                  <div className="bg-mytPurple px-2.5 py-1 w-fit text-white text-xs font-bold leading-3 rounded-lg mx-auto flex justify-center items-center">
                    {client_domain}
                  </div>
                )}
              </div>
              <div className="ct-col ct-si">
                {student.email_verified === 1 ? (
                  <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
                ) : (
                  <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                )}
              </div>
              {/* <div className="ct-col ct-ho">
								{student.test_user === 1 ? 'TEST USER' : null}
								{student.test_user === 1 && student.teacher_evaluator === 1 ? <br /> : null}
								{student.teacher_evaluator === 1 ? 'TEACHER EVALUATOR' : null}
							</div> */}
              <div className="ct-col ct-ho">
                <HighlightSearch
                  full_text={check_null_text(student.phone_personal)}
                  search_text={this.props.search_value}
                />
              </div>
              <div className="ct-col ct-ho">{student.yoga_level}</div>
              <div className="ct-col ct-ho ct-hf">{student.credits}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render_students_mobile = () => {
    const students = [...this.props.search_students_list];
    return (
      <div className="common-mobile-list">
        {students.map((student, index) => {
          const profile_photo = student.profile_photo ? student.profile_photo : '';
          const sq_name = combine_name(student.sq_fname, student.sq_mname, student.sq_lname);
          const name = combine_name(student.first_name, student.middle_name, student.last_name);
          let column_class = 'common-mobile-column';
          column_class += student.student_uuid ? ' ' : ' common-mobile-column-dis';
          column_class +=
            index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
          return (
            <div
              className={column_class}
              onClick={this.open_student_profile}
              data-id={student.student_uuid}
              key={student.student_uuid ? student.student_uuid : index}
            >
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">
                  STUDENT
                  <br />
                  <span style={{ fontSize: '14px', opacity: '0.7' }}>(Name given on LP)</span>
                </div>
                <div className="common-mobile-single-detail">
                  <div style={{ fontSize: '17px' }}>{name || sq_name}</div>
                  {sq_name.trim().length > 0 &&
                  sq_name.trim() !== 'User' &&
                  !!name &&
                  sq_name !== name ? (
                    <div style={{ fontSize: '14px', opacity: '0.7' }}>({sq_name})</div>
                  ) : null}
                  <div className="list-email">
                    {student.email ? student.email : student.sq_email}
                  </div>
                  <img src={profile_photo} onError={image_error_replace} alt="x" />
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Email VERIFIED</div>
                <div className="common-mobile-single-detail small-image-td">
                  {student.email_verified === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">USER TYPE</div>
                <div className="common-mobile-single-detail common-low-text">
                  {student.test_user === 1 ? 'TEST USER' : null}
                  {student.test_user === 1 && student.teacher_evaluator === 1 ? <br /> : null}
                  {student.teacher_evaluator === 1 ? 'TEACHER EVALUATOR' : null}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">YOGA LEVEL</div>
                <div className="common-mobile-single-detail common-low-text">
                  {student.yoga_level}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">
                  CREDITS AVAILABLE
                </div>
                <div className="common-mobile-single-detail common-low-text">{student.credits}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render_student_container = () =>
    this.props.application_width > 700 ? this.render_students() : this.render_students_mobile();

  render_display_text = () => {
    const search_value = this.props.search_value.trim();
    let display_text = '';
    if (search_value.length === 0) {
      display_text = 'Enter name, email or phone number to search';
    } else if (search_value.length > 0 && search_value.length < 3) {
      display_text = 'Please type atleast 3 letters to search';
    } else if (search_value.length >= 3) {
      display_text = 'Press Enter to search';
    }
    if (search_value.length >= 3 && this.props.student_search_status === 'success') {
      return this.render_student_container();
    }
    return <div className="sl-display-cont">{display_text}</div>;
  };

  render() {
    const search_value = this.props.search_value.trim();
    return (
      <div className="common-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Users</title>
        </Helmet>
        <ScrollContainer />
        {this.props.student_search_status === 'loading' ? <CustomLoading /> : null}
        <h3>All Users</h3>
        <div className="sl-top-content">
          <div className="sl-top-inp">
            <input
              className="sl-input"
              placeholder="Enter name, email or phone number to search"
              name="search_value"
              value={this.props.search_value}
              onChange={this.handle_search_input}
              onKeyDown={this.input_handle_key}
            />
            <button onClick={this.on_search_click}>Search</button>
          </div>
          <div className="sl-top-leg">
            {search_value.length >= 3 && this.props.student_search_status === 'success' ? (
              <>
                <div className="sl-legend" />
                <span>Users not signed up</span>
              </>
            ) : null}
          </div>
        </div>
        {this.render_display_text()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  get_all_students_status: state.loading.get_all_students_status,
  filtered_students: state.students.filtered_students,
  search_value: state.students.search_value,
  yoga_level: state.students.yoga_level,
  credits: state.students.credits,
  user_type: state.students.user_type,
  search_students_list: state.students.search_students_list,
  student_search_status: state.loading.student_search_status,
  application_width: state.home.application_width
});

const mapDispatchToProps = (dispatch) => ({
  get_all_students: () => {
    dispatch(actions.get_all_students());
  },
  set_students_variable: (key, payload) => {
    dispatch(actions.set_students_variable(key, payload));
  },
  filter_students: (key, payload) => {
    dispatch(actions.filter_students(key, payload));
  },
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  },
  search_students: (payload) => {
    dispatch(actions.search_students(payload));
  },
  set_loading_variable: (key, payload) => {
    dispatch(actions.set_loading_variable(key, payload));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
