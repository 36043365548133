import * as t from '../action_types';

const initialState = {
  past_group_sess_curr_page: 0,
  past_group_sess_page_limit: 1,
  past_group_sessions: [],
  past_group_sessions_data: [],
  future_group_sess_curr_page: 0,
  future_group_sess_page_limit: 1,
  future_group_sessions: [],
  future_group_sessions_data: [],
  future_sessions: [],
  future_sessions_data: [],
  future_sess_curr_page: 0,
  future_sess_page_limit: 1,
  past_sessions: [],
  past_sessions_data: [],
  past_sess_curr_page: 0,
  past_sess_page_limit: 1,
  past_sess_teacher_uuid: '',
  future_friends_sessions: [],
  future_friends_sessions_data: [],
  future_friends_sess_curr_page: 0,
  future_friends_sess_page_limit: 1,
  past_friends_sessions: [],
  past_friends_sessions_data: [],
  past_friends_sess_curr_page: 0,
  past_friends_sess_page_limit: 1
};

const set_sessions_variable = (state, payload) => ({
  ...state,
  [payload.key]: payload.payload
});

const update_future_group_sessions = (state, payload) => {
  const fu_sess = [...state.future_group_sessions];
  const sess_index = fu_sess.findIndex((s) => s.session_uuid === payload.uuid);
  const curr_sess = { ...fu_sess[sess_index], is_premium_group_session: 1 };
  const updated_sessions = [
    ...fu_sess.slice(0, sess_index),
    curr_sess,
    ...fu_sess.slice(sess_index + 1)
  ];
  return {
    ...state,
    future_group_sessions: updated_sessions
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_sessions_variable:
      return set_sessions_variable(state, action.payload);
    case t.update_future_group_sessions:
      return update_future_group_sessions(state, action.payload);
    case t.reset:
      return initialState;
    default:
      return state;
  }
}
