import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class Testing extends Component {
  state = {
    selectedFile: null,
    loading: false
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] }, () =>
      console.log(this.state.selectedFile.name)
    );
  };

  submit = () =>
    this.setState({ loading: true }, () => {
      const data = new FormData();
      data.append('attachment', this.state.selectedFile);
      data.append('filename', this.state.selectedFile.name);
      data.append('session_uuid', '97e240eb-f891-460b-9cd7-b610b758f5c7');
      const url = get_api_url('upload_consult_attachment');
      post_api(url, data, true, 'formdata')
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Testing">
        <input type="file" onChange={this.onFileChange} />
        <button onClick={this.submit}>Submit</button>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Testing);
