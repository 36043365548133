import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/action_creators';
import './content.css';
import CustomLoading from '../../../../util_components/custom_loading';
import CustomModal from '../../../../util_components/custom_modal';

class RenderAllTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      all_tags: [],
      selected_tags: [],
      association_type: '',
      association_id: '',
      edit_tags: false,
      associated_tags: []
    };
  }

  componentDidMount() {
    this.set_tag_details();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_all_associated_tags_status === 'loading' &&
      this.props.get_all_associated_tags_status === 'success'
    ) {
      const all_tags = this.props.get_all_associated_tags;
      const associated_tags = [];
      if (!!all_tags && all_tags.length) {
        for (const tag_category of all_tags) {
          for (const tag of tag_category.tags) {
            if (tag.is_tagged == 1) {
              associated_tags.push(tag);
            }
          }
        }
      }
      this.setState({
        loading: false,
        all_tags,
        associated_tags
      });
    }
    if (
      prevProps.update_tag_associations_status === 'loading' &&
      this.props.update_tag_associations_status === 'success'
    ) {
      this.setState({
        loading: false,
        edit_tags: false
      });
      this.set_tag_details();
    }
  }

  set_tag_details = () => {
    const { association_type } = this.props;
    const { association_id } = this.props;
    const { entity_name } = this.props;
    this.setState({
      association_type,
      association_id,
      entity_name
    });
    if (association_id) {
      const uuid = association_id;
      this.fetch_association_details(uuid);
    }
  };

  fetch_association_details = (uuid) => {
    if (uuid) {
      const url = `/v2/entity_tags/associations/blogs/${uuid}`;
      this.props.getAllAssociatedTags({ url });
    }
  };

  updateSelectedTags(selected_tags_list, item) {
    const selected_tag_index = selected_tags_list.findIndex(
      (_item) => _item.tag_uuid === item.tag_uuid
    );
    if (selected_tag_index > -1) {
      return (selected_tags_list[selected_tag_index] = item);
    }
    return selected_tags_list.push(item);
  }

  onTagSelect = (tag_data, category) => {
    const tag_obj = tag_data;
    tag_obj.is_tagged = tag_data.is_tagged === 1 ? 0 : 1;
    const tagIndex = category.tags.findIndex((t) => t.uuid === tag_data.uuid);
    category.tags[tagIndex] = tag_obj;
    const { all_tags } = this.state;
    const filtered_category_index = all_tags.findIndex((obj) => obj.uuid === category.uuid);
    all_tags[filtered_category_index] = category;

    this.updateSelectedTags(this.state.selected_tags, {
      is_tagged: tag_obj.is_tagged,
      tag_uuid: tag_obj.uuid,
      tag_category_uuid: category.uuid
    });

    this.setState({
      all_tags
    });
  };

  onSave = () => {
    const payload = {
      updates: this.state.selected_tags,
      association_type_id: this.state.association_id,
      association_type: this.state.association_type
    };
    this.setState({
      loading: true
    });

    this.props.updateTagAssociations({ url: `/v2/entity_tags/associations/blogs`, payload });
  };

  render_all_tags = () => {
    const cont_style = 'content-width-full';
    return (
      <div className={`content-border category-template-div ${cont_style}`}>
        <div className="inner-div">
          {!!this.state.all_tags && this.state.all_tags.length !== 0
            ? this.state.all_tags.map((data) => (
                <div key={data?.uuid} className="content-div">
                  <h3 className="text-lg font-bold">{data?.name}</h3>
                  <div className="inner-div content-border content-width-full">
                    {data?.tags && data?.tags.length !== 0 ? (
                      data.tags.map((tag) => (
                        <div className="md-chips" key={tag?.uuid}>
                          <input
                            type="checkbox"
                            name={tag}
                            checked={tag?.is_tagged}
                            value={tag?.uuid}
                            id={tag?.uuid}
                            style={{ display: 'none' }}
                            onClick={() => this.onTagSelect(tag, data)}
                          />
                          <label
                            htmlFor={tag?.uuid}
                            id={tag?.uuid}
                            className="md-chip md-chip-clickable md-chip-hover"
                          >
                            <span> {tag?.name} </span>
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>No tags present</p>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="create-tag-name-button mr-4" onClick={this.onSave}>
          <button>Save</button>
        </div>
      </div>
    );
  };

  render_association_content = () => {
    const { associated_tags } = this.state;

    const cont_style = 'content-width-full';

    return (
      <div className={`content-border category-template-div ${cont_style}`}>
        <div className="inner-div">
          {!!associated_tags && associated_tags.length !== 0 ? (
            associated_tags.map((tag, index) => (
              <div className="md-chips" key={index}>
                <label htmlFor={tag?.uuid} id={index} className="md-chip">
                  <span> {tag?.name} </span>
                </label>
              </div>
            ))
          ) : (
            <p>
              <strong>No tags present</strong>
            </p>
          )}
        </div>
      </div>
    );
  };

  open_edit_tags = () => this.setState({ edit_tags: true });

  close_edit_tags = () => this.setState({ edit_tags: false });

  render() {
    const { association_type, loading, entity_name, edit_tags } = this.state;
    return (
      <div>
        Tags for <strong>{entity_name}</strong>
        <br />
        {loading ? (
          <CustomLoading />
        ) : (
          <div>
            <button onClick={() => this.open_edit_tags()}>Add/Edit Tags</button>
            <div style={{ display: 'flex' }}>{this.render_association_content()}</div>
            <CustomModal show={edit_tags} close={this.close_edit_tags}>
              {this.render_all_tags()}
            </CustomModal>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  get_all_associated_tags: state.content.get_all_associated_tags,
  get_all_associated_tags_status: state.content.get_all_associated_tags_status,
  update_tag_associations_status: state.content.update_tag_associations_status
});

const mapDispatchToProps = (dispatch) => ({
  getAllAssociatedTags: (payload) => dispatch(actions.get_all_associated_tags(payload)),
  updateTagAssociations: (payload) => dispatch(actions.update_tag_associations(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderAllTags);
