import React from 'react';

function SecurityCheckPopup(props) {
  return (
    <div className="relative inset-0 z-50">
      <div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center">
        <div className="z-50 bg-white rounded-lg p-10 2xl:w-2/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative">
          <div
            className="flex justify-end cursor-pointer absolute top-1 right-1"
            onClick={() => {
              props.close();
            }}
          >
            <div>
              <svg
                width="40"
                height="40"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="34" height="34" rx="17" fill="none" />
                <path
                  d="M22 12L12 22"
                  stroke="#BFBFBF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 12L22 22"
                  stroke="#BFBFBF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="mb-0">
            <div className="flex justify-center flex-col items-center">
              <div className="mb-5">
                <svg
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="22" fill="#FF2121" />
                  <path
                    d="M27.5 16.5L16.5 27.5"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 16.5L27.5 27.5"
                    stroke="white"
                    strokeWidth="2.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="text-19px font-inter font-semibold text-mytBlackMedium text-center leading-snug">
                This is in violation of our user agreement, you cannot post this content
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityCheckPopup;
