import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../../../redux/action_creators';
import { api_with_method } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import CustomInput from '../../../util_components/custom_input';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import {
  distribution_types,
  split_test_categories,
  new_custom_data_config,
  day_format_year
} from '../../../../constants';
import CommonHeader from '../../../util_components/common_header';
import ConfirmationPopup from '../../../util_components/confirmation_popup';
import ErrorPopup from '../../../util_components/error_popup';

class SplitTestV2 extends Component {
  state = {
    loading: false,
    test_details: {},
    modal_open: false,
    choice_tag: '',
    choice_name: '',
    description: '',
    weight: 0.0,
    test_next_page_url: '',
    distribution_pattern: '',
    image_url: '',
    optimize_test_variant: '',
    modal_error: false,
    edit_modal: false,
    category: '',
    end_date: '',
    fifth_success_metric_name: '',
    first_success_metric_name: '',
    fourth_success_metric_name: '',
    name: '',
    optimize_test_id: '',
    second_success_metric_name: '',
    start_date: '',
    tag: '',
    test_starting_page: '',
    third_success_metric_name: '',
    data_config_modal: false,
    edit_data_config_modal: false,
    data_key: '',
    data_type: '',
    choice_list: [],
    draft_choice_list: [],
    draft_available: false,
    published_available: false,
    draft_details: {},
    published_details: {},
    draft_uuid: '',
    draft_choices: [],
    published_choices: [],
    draft_category: '',
    split_test_uuid: '',
    split_uuid: '',
    release_conformation_popup: false,
    draft_config: {},
    published_config: {},
    draft_config_uuid: '',
    custom_date: [],
    custom_data_key: '',
    custom_data_value: '',
    choice_edit_index: -1,
    choice_edit_value: '',
    choice_edit_key: '',
    custom_data_value: '',
    custom_published_data: {},
    custom_draft_data: {},
    split_test_tag: '',
    show_table: false,
    split_test_history_data: [],
    show_history_choices_details: false,
    split_test_history_choices: [],
    open_tag: '',
    test_key: '',
    category: 'GENERAL',
    show_delete_modal: false,
    delete_choice_uuid: '',
    show_delete_config_modal: false,
    delete_config_uuid: '',
    show_history_data: false,
    show_error_popup: false,
    error_message: ''
  };

  componentDidMount() {
    const { tag } = this.props.match.params;
    if (tag) {
      this.setState({ tag }, () => this.load_data());
    } else {
      this.props.history.push('/split_testing/split_test_choice_v2');
    }
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const { tag } = this.state;
      const url = get_api_url(`/v2/split_tests/care/split_details/${tag}`, null, true);
      api_with_method('get', url, null, true)
        .then((res) => {
          const test_details = res.data.data;
          let draft_available = false;
          let published_available = false;
          const draft_details = test_details.latest_draft_details;
          const published_details = test_details.published_version_details;
          if (draft_details) {
            draft_available = true;
            this.setState({
              draft_uuid: draft_details.uuid,
              draft_category: draft_details.category,
              split_test_uuid: draft_details.uuid,
              draft_config: draft_details.ConfigKey,
              custom_draft_data: draft_details.custom_data ? draft_details.custom_data : {}
            });
          }
          if (published_details) {
            published_available = true;
            this.setState({
              published_config: published_details.ConfigKey,
              custom_published_data: published_details.custom_data
                ? published_details.custom_data
                : {}
            });
          }

          this.setState({
            draft_available,
            published_available,
            test_details,
            draft_details,
            published_details
          });
          if (published_available) {
            this.load_published_choice_data();
          }
          if (draft_available) {
            this.load_draft_choice_data();
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  show_history = (tag) => {
    this.setState({ loading: true }, () => {
      const url = get_api_url(`/v2/split_tests/care/test_history/${tag}`, null, tag);
      api_with_method('get', url, null, true)
        .then((res) => {
          const split_history = res.data.data;
          split_history.forEach((el) => {
            el.isActive = false;
          });
          this.setState({
            loading: false,
            show_table: true,
            split_test_history_data: split_history
          });
        })
        .catch((e) => {
          console.log(e);
          let err_message;
          try {
            err_message = e?.response?.data?.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }

          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false, show_table: false });
        });
    });
  };

  show_hide_history = (tag) => {
    this.setState(
      {
        show_history_data: !this.state.show_history_data
      },
      () => {
        this.show_history(tag);
      }
    );
  };

  open_history_choice_details = (choices) => {
    const { split_test_history_data } = this.state;
    split_test_history_data.forEach((el) => {
      if (choices.uuid === el.uuid) {
        el.isActive = true;
      } else if (choices.uuid !== el.uuid) {
        el.isActive = false;
      }
    });
    this.setState({
      show_history_choices_details: true,
      split_test_history_choices: choices
    });
  };

  redner_split_choice_table = () => {
    const { split_test_history_choices } = this.state;

    return (
      <div className="px-8 pb-4 relative w-full">
        <table>
          <tr>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Choice Name</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Choice Tag</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Virsion</th>
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Weight</th>
          </tr>
          {split_test_history_choices?.split_choices?.map((item, index) => (
            <tr key={index}>
              <td>{item.choice_name}</td>
              <td>{item.choice_tag}</td>
              <td>{item.version}</td>
              <td>{item.weight}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  };

  render_history_table_row = (item, index) => {
    const { iana_timezone } = this.props;
    return (
      <>
        <tr key={index}>
          <td>{item.name}</td>
          <td>{item.category}</td>
          <td>{moment(item.created_date).tz(iana_timezone).format(day_format_year)}</td>
          <td>{moment(item.modified_date).tz(iana_timezone).format(day_format_year)}</td>
          <td>{item.version}</td>
          <td>{item.test_starting_page}</td>
          <td>
            {' '}
            {item.split_choices.length === 0 ? (
              <button disabled>Choices</button>
            ) : (
              <button onClick={() => this.open_history_choice_details(item)}>Choices</button>
            )}
          </td>
        </tr>
        <tr>
          <td colSpan="7">{item.isActive === true && <>{this.redner_split_choice_table()}</>}</td>
        </tr>
      </>
    );
  };

  render_history_table = () => {
    const { split_test_history_data } = this.state;

    return split_test_history_data.length > 0 ? (
      <table className="w-full">
        <thead>
          <th>Names</th>
          <th>Category</th>
          <th>Created Date</th>
          <th>Modified Date</th>
          <th>Version</th>
          <th>Start Page</th>
          <th>Choices</th>
        </thead>
        <tbody>
          {split_test_history_data.map((item, index) => this.render_history_table_row(item, index))}
        </tbody>
      </table>
    ) : (
      <p>No history available</p>
    );
  };

  load_published_choice_data = () => {
    this.setState({ loading: true }, () => {
      const { published_details } = this.state;
      let payload_uuid;
      if (!!published_details && !!published_details.uuid) {
        payload_uuid = published_details.uuid;
      }
      const payload = { split_test_uuid: payload_uuid };
      const url = get_api_url(`/v2/split_tests/care/choices_list`, null, true);

      api_with_method('POST', url, payload, true)
        .then((res) => {
          const test_details = res.data.data;
          this.setState({
            choice_list: test_details,
            loading: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  load_draft_choice_data = () => {
    this.setState({ loading: true }, () => {
      const { draft_details } = this.state;
      let payload_uuid;
      if (!!draft_details && !!draft_details.uuid) {
        payload_uuid = draft_details.uuid;
      }
      const payload = { split_test_uuid: payload_uuid };
      const url = get_api_url(`/v2/split_tests/care/choices_list`, null, true);

      api_with_method('POST', url, payload, true)
        .then((res) => {
          const test_details = res.data.data;
          this.setState({
            draft_choice_list: test_details,
            loading: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });
  };

  release_conformation = () => {
    this.setState({
      release_conformation_popup: true
    });
  };

  open_modal = () => {
    this.setState({
      modal_open: true
    });
  };

  edit_modal_open = () => {
    this.setState({
      edit_modal: true
    });
  };

  open_data_config_modal = () => {
    this.setState({
      data_config_modal: true
    });
  };

  open_edit_data_config_modal = () => {
    this.setState({
      edit_data_config_modal
    });
  };

  close_modal = () => {
    this.setState({
      modal_open: false,
      edit_modal: false,
      data_config_modal: false,
      release_conformation_popup: false,
      edit_data_config_modal: false,
      show_delete_modal: false,
      show_delete_config_modal: false,
      show_history_choices_details: false,
      description: '',
      choice_tag: '',
      choice_name: '',
      weight: '',
      test_next_page_url: '',
      distribution_pattern: '',
      image_url: '',
      optimize_test_variant: '',
      data_key: '',
      data_type: ''
    });
  };

  go_back = () => this.props.history.goBack();

  onCommonChange = (e) => {
    const key = e.target.name;
    let { value } = e.target;
    if (key === 'weight') {
      value = Math.min(value, 100);
    }
    this.setState({ [key]: value, modal_error: false });
  };

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  open_choice_details = (uuid, tag) => {
    this.props.history.push({
      pathname: `/split_test_v2/${tag}/${uuid}`
    });
  };

  render_single_row = (item, index, tag, status) => (
    <tr key={index}>
      <td>{item.choice_name}</td>
      <td>{item.choice_tag}</td>
      <td>{item.weight}</td>
      <td>{item.status}</td>
      <td>
        <button onClick={() => this.open_choice_details(item.uuid, tag)}>Details</button>
      </td>
      {status === 'DRAFT' ? (
        item.is_active === 1 ? (
          <td>
            <button onClick={() => this.delete_split_choice_by_uuid(item.uuid, 0)}>Disable</button>
          </td>
        ) : (
          <td>
            <button onClick={() => this.delete_split_choice_by_uuid(item.uuid, 1)}>Enable</button>
          </td>
        )
      ) : null}
    </tr>
  );

  render_choice_list = () => {
    const { choice_list, published_details } = this.state;
    return choice_list?.length > 0 ? (
      this.render_choice_list_new(choice_list, published_details?.tag, published_details?.status)
    ) : (
      <p className="text-center">No Published Choice Available</p>
    );
  };

  render_draft_choice_list = () => {
    const { draft_choice_list, draft_details } = this.state;
    return draft_choice_list?.length > 0 ? (
      this.render_choice_list_new(draft_choice_list, draft_details?.tag, draft_details?.status)
    ) : (
      <p className="text-center">No Draft Choice Available</p>
    );
  };

  render_choice_list_new = (choice_list, tag, status) => (
    <table className="relative w-full border">
      <thead>
        <tr>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Name</th>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Tag</th>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Weight</th>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Status</th>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Details</th>
          {status === 'DRAFT' ? (
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Action</th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {choice_list.map((item, index) => this.render_single_row(item, index, tag, status))}
      </tbody>
    </table>
  );

  submit_data_config = () => {
    const { data_key, data_type, draft_uuid } = this.state;
    if (data_key) {
      const payload = { data_key, data_type, split_test_uuid: draft_uuid };
      const url = get_api_url('/v2/split_tests/care/add_config_key', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('POST', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Config Added');
            this.load_data();
            this.close_modal();
            this.setState({ loading: false });
          })
          .catch((e) => {
            console.log(e);
            try {
              err_message = e.response.data.reason;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  render_data_config = () => {
    const { data_key, data_type } = this.state;
    return (
      <div className="w-full items-center flex flex-col">
        <div className="font-inter font-semibold text-2xl mb-2">Add custom data config</div>
        <CustomInput
          label="*Enter data key"
          value={data_key}
          onChange={this.onCommonChange}
          className="w-full"
          name="data_key"
        />
        <CustomSingleSelect
          label="Select type"
          options={new_custom_data_config}
          onChange={this.onCommonChange}
          value={data_type}
          name="data_type"
        />
        <button style={{ width: '200px' }} onClick={this.submit_data_config}>
          Save
        </button>
      </div>
    );
  };

  set_config_value = (uuid, data_key, data_type) => {
    const data_type_new = data_type === 'STRING' ? 'string' : 'number';
    this.setState({
      draft_config_uuid: uuid,
      data_type: data_type_new,
      data_key,
      edit_data_config_modal: true
    });
  };

  submit_edit_data_config = () => {
    const { draft_config_uuid, data_type } = this.state;
    if (data_type) {
      const new_data_type = data_type.toUpperCase();
      const payload = { uuid: draft_config_uuid, data_type: new_data_type };
      const url = get_api_url('/v2/split_tests/care/edit_config', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('put', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Config Added');
            this.close_modal();
            this.load_data();
            this.setState({ loading: false });
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    }
  };

  redner_edit_config = () => {
    const { data_key, data_type } = this.state;
    return (
      <div className="w-full items-center flex flex-col">
        <div className="font-inter font-semibold text-2xl mb-2">Edit custom data config</div>
        <CustomInput
          label="*Enter data key"
          value={data_key}
          onChange={this.onCommonChange}
          className="w-full"
          name="data_key"
          disabled
        />
        <CustomSingleSelect
          label="Select type"
          options={new_custom_data_config}
          onChange={this.onCommonChange}
          value={data_type}
          name="data_type"
          disabled
        />
        <button style={{ width: '200px' }} onClick={this.submit_edit_data_config}>
          Save
        </button>
      </div>
    );
  };

  submit_edit = () => {
    const {
      uuid,
      name,
      category,
      description,
      end_date,
      start_date,
      fifth_success_metric_name,
      first_success_metric_name,
      fourth_success_metric_name,
      optimize_test_id,
      second_success_metric_name,
      tag,
      test_starting_page,
      third_success_metric_name,
      custom_data
    } = this.state;
    if (!!name && !!start_date && !!end_date && !!first_success_metric_name && !!description) {
      const sel_start_date = `${start_date} 00:00:01`;
      const sel_end_date = `${end_date} 23:59:59`;
      const payload = {
        uuid,
        name,
        category,
        description,
        end_date: sel_end_date,
        start_date: sel_start_date,
        fifth_success_metric_name,
        first_success_metric_name,
        fourth_success_metric_name,
        optimize_test_id,
        second_success_metric_name,
        tag,
        test_starting_page,
        third_success_metric_name,
        custom_data
      };
      const url = get_api_url('/v2/split_tests/care/edit_split_test', null, true);

      this.setState({ loading: true }, () => {
        api_with_method('put', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Split test edited');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    } else {
      this.setState({ modal_error: true });
    }
  };

  add_key_value = () => {
    const { custom_data_key, custom_data_value } = this.state;
    const custom_data = { ...this.state.custom_data, [custom_data_key]: custom_data_value };
    this.setState({ custom_data, custom_data_key: '', custom_data_value: '' });
  };

  cancel_edit_choice = () => {
    this.setState({ custom_edit_value: '', choice_edit_index: -1, custom_edit_key: '' });
  };

  save_edit_choice = () => {
    const { custom_edit_value, custom_edit_key, custom_data } = this.state;
    const new_custom_data = { ...custom_data, [custom_edit_key]: custom_edit_value };
    this.setState({
      custom_edit_value: '',
      choice_edit_index: -1,
      custom_edit_key: '',
      custom_data: new_custom_data
    });
  };

  render_draft = () => {
    const {
      start_date,
      end_date,
      distribution_metric,
      tag,
      name,
      test_starting_page,
      optimize_test_id,
      first_success_metric_name,
      second_success_metric_name,
      third_success_metric_name,
      fourth_success_metric_name,
      fifth_success_metric_name,
      category,
      description,
      modal_error,
      custom_data,
      choice_edit_index,
      custom_edit_value,
      custom_data_key,
      custom_data_value
    } = this.state;
    const { iana_timezone } = this.props;
    const curr_timezone = moment().tz(iana_timezone).format('z');
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    const show_edit = choice_edit_index === -1;
    return (
      <>
        <div className="text-center m-4">Edit split test (in {curr_timezone})</div>
        <div className="flex flex-wrap justify-between px-2">
          <CustomDatePicker
            label="*Start Date"
            value={start_date}
            handleChange={(val) => this.handle_date_change('start_date', val)}
          />
          <CustomDatePicker
            label="*End Date"
            value={end_date}
            handleChange={(val) => this.handle_date_change('end_date', val)}
          />
          <CustomSingleSelect
            label="*Select Distribution"
            options={distribution_types}
            name="distribution_metric"
            onChange={this.onCommonChange}
            value={distribution_metric}
          />
          <CustomInput
            label="*Enter tag"
            onChange={this.onCommonChange}
            name="tag"
            value={tag}
            disabled
          />
          <CustomInput
            label="*Enter name"
            onChange={this.onCommonChange}
            name="name"
            value={name}
          />
          <CustomInput
            label="Start page"
            onChange={this.onCommonChange}
            name="test_starting_page"
            value={test_starting_page}
          />
          <CustomInput
            label="Optimize test id"
            onChange={this.onCommonChange}
            name="optimize_test_id"
            value={optimize_test_id}
          />
          <CustomInput
            label="*First success metric"
            onChange={this.onCommonChange}
            name="first_success_metric_name"
            value={first_success_metric_name}
          />
          <CustomInput
            label="Second success metric"
            onChange={this.onCommonChange}
            name="second_success_metric_name"
            value={second_success_metric_name}
          />
          <CustomInput
            label="Third success metric"
            onChange={this.onCommonChange}
            name="third_success_metric_name"
            value={third_success_metric_name}
          />
          <CustomInput
            label="Fourth success metric"
            onChange={this.onCommonChange}
            name="fourth_success_metric_name"
            value={fourth_success_metric_name}
          />
          <CustomInput
            label="Fifth success metric"
            onChange={this.onCommonChange}
            name="fifth_success_metric_name"
            value={fifth_success_metric_name}
          />
          <CustomSingleSelect
            label="Select Category"
            options={split_test_categories}
            name="category"
            onChange={this.onCommonChange}
            value={category}
          />
          <div className="mb-2">
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
          <button onClick={this.submit_edit}>Submit</button>
          <p style={err_sty}>* fields are compulsory</p>
        </div>
      </>
    );
  };

  set_values = () => {
    const { published_available, published_details, draft_details } = this.state;
    let filter_test_details;
    if (published_available) {
      filter_test_details = published_details;
    }
    if (draft_details) {
      filter_test_details = draft_details;
    }
    const test_details = filter_test_details;
    const start_date = moment(test_details.start_date).format('YYYY-MM-DD');
    const end_date = moment(test_details.end_date).format('YYYY-MM-DD');
    this.setState({
      uuid: test_details.uuid,
      description: test_details.description,
      end_date,
      start_date,
      fifth_success_metric_name: test_details.fifth_success_metric_name,
      first_success_metric_name: test_details.first_success_metric_name,
      fourth_success_metric_name: test_details.fourth_success_metric_name,
      name: test_details.name,
      optimize_test_id: test_details.optimize_test_id,
      second_success_metric_name: test_details.second_success_metric_name,
      tag: test_details.tag,
      test_starting_page: test_details.test_starting_page,
      third_success_metric_name: test_details.third_success_metric_name,
      category: test_details.category,
      custom_data: test_details.custom_data,
      edit_modal: true
    });
  };

  submit_choices = () => {
    const {
      split_test_uuid,
      draft_category,
      choice_tag,
      choice_name,
      description,
      weight,
      test_next_page_url,
      distribution_pattern,
      image_url,
      optimize_test_variant
    } = this.state;
    if (!!choice_name && !!choice_tag && !!weight && !!draft_category) {
      const payload = {
        choice_tag,
        choice_name,
        description,
        weight,
        category: draft_category,
        test_next_page_url,
        distribution_pattern,
        image_url,
        optimize_test_variant,
        split_test_uuid
      };
      const url = get_api_url('/v2/split_tests/care/add_split_choice', null, true);
      this.setState({ loading: true }, () => {
        api_with_method('POST', url, payload, true)
          .then(() => {
            this.props.set_notification_variable(true, 'success', 'Split test edited');
            this.close_modal();
            this.load_data();
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e?.response?.data?.reason;
            } catch (err) {
              err_message = 'Some error occured. Please contact care team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ loading: false });
          });
      });
    }
  };

  release_split_test = () => {
    const { split_test_uuid } = this.state;
    const payload = { split_uuid: split_test_uuid };
    const url = get_api_url('/v2/split_tests/care/release_split', null, true);
    this.setState({ loading: true }, () => {
      api_with_method('POST', url, payload, true)
        .then(() => {
          this.setState({
            draft_choice_list: [],
            draft_config: {}
          });
          this.props.set_notification_variable(true, 'success', 'Split Test is now live');
          this.load_data();
          this.close_modal();
          this.render_draft_choice_list();
          this.render_choice_list();
          this.render_draft_data_config();
        })
        .catch((e) => {
          console.log(e?.response?.data?.reason);
          let err_message;
          try {
            err_message = e?.response?.data?.reason;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.setState(
            {
              loading: false,
              error_message: err_message,
              show_error_popup: true,
              release_conformation_popup: false
            },
            () => {
              setTimeout(() => {
                this.setState({
                  show_error_popup: false
                });
              }, 5000);
              this.load_data();
            }
          );
        });
    });
  };

  delete_split_choice_by_uuid = (uuid, num) => {
    const choice_uuid = uuid;
    const payload = { choice_uuid: uuid, disable: num };
    const url = get_api_url(`/v2/split_tests/care/remove_choice/${choice_uuid}`, null, true);
    this.setState({ loading: true }, () => {
      api_with_method('POST', url, payload, true)
        .then(() => {
          this.load_data();
          this.close_modal();
          this.render_draft_choice_list();
          this.render_choice_list();
        })
        .catch((e) => {
          console.log(e?.message);
          let err_message;
          try {
            err_message = e?.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false, release_conformation_popup: false });
          this.load_data();
        });
    });
  };

  delete_config_by_uuid = (uuid, num) => {
    const config_uuid = uuid;
    const payload = { config_uuid: uuid, disable: num };
    const url = get_api_url(`/v2/split_tests/care/remove_config_key/${config_uuid}`, null, true);
    this.setState({ loading: true }, () => {
      api_with_method('POST', url, payload, true)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Config disabled');
          this.load_data();
          this.close_modal();
          this.render_draft_choice_list();
          this.render_choice_list();
        })
        .catch((e) => {
          console.log(e?.message);
          let err_message;
          try {
            err_message = e?.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact care team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false, release_conformation_popup: false });

          this.load_data();
        });
    });
  };

  render_error_popup = () => <ErrorPopup message={this.state?.error_message} />;

  close_error_popup = () => {
    this.setState({
      show_error_popup: false
    });
  };

  render_relese_popup = () => (
    <ConfirmationPopup
      title="Are you sure to release this Split Test"
      close_modal={this.close_modal}
      confirm={this.release_split_test}
    />
  );

  render_delete_popup = () => (
    <ConfirmationPopup
      title="Are you sure to delete this Split Test choice"
      close_modal={this.close_modal}
      confirm={this.delete_split_choice_by_uuid}
    />
  );

  render_delete_config_popup = () => (
    <ConfirmationPopup
      title="Are you sure to delete this Split data config"
      close_modal={this.close_modal}
      confirm={this.delete_config_by_uuid}
    />
  );

  delete_config_value = (uuid) => {
    this.setState({
      show_delete_config_modal: true,
      delete_config_uuid: uuid
    });
  };

  show_delete_modal = (uuid, num) => {
    this.setState({
      show_delete_modal: true,
      delete_choice_uuid: uuid
    });
  };

  render_create_choice = () => {
    const {
      choice_tag,
      choice_name,
      description,
      weight,
      test_next_page_url,
      distribution_pattern,
      image_url,
      optimize_test_variant,
      modal_error
    } = this.state;
    const err_sty = modal_error ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
    return (
      <>
        <div className="text-center m-4 w-full">Create split test choice</div>
        <div className="flex flex-wrap justify-between px-2 w-full">
          <CustomInput
            label="*Enter tag"
            value={choice_tag}
            onChange={this.onCommonChange}
            name="choice_tag"
          />
          <CustomInput
            label="*Enter name"
            value={choice_name}
            onChange={this.onCommonChange}
            name="choice_name"
          />
          <CustomInput
            label="*Enter weight"
            value={weight}
            onChange={this.onCommonChange}
            name="weight"
            type="number"
          />
          <CustomInput
            label="Next page url"
            value={test_next_page_url}
            onChange={this.onCommonChange}
            name="test_next_page_url"
          />
          <CustomInput
            label="Distribution pattern"
            value={distribution_pattern}
            onChange={this.onCommonChange}
            name="distribution_pattern"
          />
          <CustomInput
            label="Image Url"
            value={image_url}
            onChange={this.onCommonChange}
            name="image_url"
          />
          <CustomInput
            label="Optimize test variant"
            value={optimize_test_variant}
            onChange={this.onCommonChange}
            name="optimize_test_variant"
          />
          <div>
            <div>*Enter description:</div>
            <textarea
              style={{ width: '500px', height: '200px' }}
              value={description}
              name="description"
              onChange={this.onCommonChange}
              maxLength="2000"
            />
          </div>
          <button onClick={this.submit_choices}>Create</button>
          <p style={err_sty}>* fields are compulsory</p>
        </div>
      </>
    );
  };

  render_draft_table = () => {
    const details = this.state.test_details.latest_draft_details;
    if (details) {
      return this.render_table(details);
    }
    return <p className="text-center">No Draft data </p>;
  };

  redner_published_table = () => {
    const details = this.state.published_details;
    if (this.state.published_details) {
      return this.render_table(details);
    }
    return <p className="text-center">Published version not available </p>;
  };

  render_table = (details) => {
    const { iana_timezone } = this.props;
    return (
      <table className="text-left">
        <tbody>
          <tr>
            <td className="text-left">Names:</td>
            <td className="text-left">{details.name}</td>
            <td className="text-left">Tag:</td>
            <td className="text-left">{details.tag}</td>
          </tr>
          <tr>
            <td className="text-left ">Start date:</td>
            <td className="text-left">
              {moment(details.start_date).tz(iana_timezone).format(day_format_year)}
            </td>
            <td className="text-left ">End date:</td>
            <td className="text-left">
              {moment(details.end_date).tz(iana_timezone).format(day_format_year)}
            </td>
          </tr>
          <tr>
            <td className="text-left">UUID:</td>
            <td className="text-left">{details.uuid}</td>
            <td className="text-left ">Start Page:</td>
            <td className="text-left">{details.test_starting_page}</td>
          </tr>
          <tr>
            <td className="text-left">Distribution:</td>
            <td className="text-left">{details.distribution_metric}</td>
            <td className="text-left">Status:</td>
            <td className="text-left">{details.status}</td>
          </tr>
          <tr>
            <td className="text-left">First metric:</td>
            <td className="text-left">
              {details.first_success_metric_name === '' ? '--' : details.first_success_metric_name}
            </td>
            <td className="text-left">Second metric:</td>
            <td className="text-left">
              {details.second_success_metric_name === ''
                ? '--'
                : details.second_success_metric_name}
            </td>
          </tr>
          <tr>
            <td className="text-left">Third metric:</td>
            <td className="text-left">
              {details.third_success_metric_name === '' ? '--' : details.third_success_metric_name}
            </td>
            <td className="text-left">Fourth metric:</td>
            <td className="text-left">
              {details.fourth_success_metric_name === ''
                ? '--'
                : details.fourth_success_metric_name}
            </td>
          </tr>
          <tr>
            <td className="text-left">Fifth :</td>
            <td className="text-left">
              {details.fifth_success_metric_name === '' ? '--' : details.fifth_success_metric_name}
            </td>
            <td className="text-left">Optimize test id:</td>
            <td className="text-left">
              {details.optimize_test_id === '' ? '--' : details.optimize_test_id}
            </td>
          </tr>

          <tr>
            <td className="text-left">Category:</td>
            <td className="text-left">{details.category}</td>
            <td className="text-left">Version:</td>
            <td className="text-left">{details.version}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  render_draft_data_config = () => {
    const { draft_config, draft_details } = this.state;
    if (Object.keys(draft_config).length > 0) {
      return this.show_data_config(draft_config, draft_details?.status);
    }
    return <p>No data found</p>;
  };

  render_published_data_config = () => {
    const { published_config, published_details } = this.state;
    if (Object.keys(published_config)?.length > 0) {
      return this.show_data_config(published_config, published_details?.status);
    }
    return <p>No data found</p>;
  };

  show_data_config = (config, status) => (
    <table className="relative w-full border">
      <thead>
        <tr>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Name</th>
          <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Tag</th>
          {status === 'LIVE' ? (
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Status</th>
          ) : null}
          {status === 'LIVE' ? null : (
            <th className="sticky top-0 px-6 py-3 text-white bg-mytBlue">Action</th>
          )}
        </tr>
      </thead>
      <tbody>
        {config.map((item, index) => (
          <tr key={index}>
            <td>{item.data_key}</td>
            <td>{item.data_type}</td>
            {status === 'LIVE' ? <td>{item.is_active === 1 ? 'Enable' : 'Disable'}</td> : null}
            {status === 'LIVE' ? null : item.is_active === 1 ? (
              <td>
                <button onClick={() => this.delete_config_by_uuid(item.uuid, 0)}>Disable</button>
              </td>
            ) : (
              <td>
                <button onClick={() => this.delete_config_by_uuid(item.uuid, 1)}>Enable</button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  render() {
    const {
      modal_open,
      show_history_data,
      show_error_popup,
      show_delete_config_modal,
      tag,
      loading,
      show_table,
      edit_modal,
      data_config_modal,
      draft_available,
      release_conformation_popup,
      draft_config,
      published_config,
      edit_data_config_modal,
      show_history_choices_details,
      show_delete_modal
    } = this.state;
    return (
      <>
        <CommonHeader loading={loading} show_back title="Split Test Details">
          <div className="font-inter font-bold text-lg text-center mb-2">
            Published version details
          </div>
          <div className="mb-2">{this.redner_published_table()}</div>
          <div className="mb-2">
            {draft_available ? '' : <button onClick={this.set_values}>Edit details</button>}
          </div>
          <div className="font-inter font-bold text-lg text-center mb-2 mt-5">
            Published data config
          </div>
          <div className="mb-2">{this.render_published_data_config()}</div>
        </CommonHeader>
        <CommonHeader loading={loading} show_back={false} title="">
          <div className="font-inter font-bold text-lg text-center mb-2">Published choice list</div>
          {this.render_choice_list()}
        </CommonHeader>
        <hr style={{ borderTop: '5px solid #000' }} />

        <CommonHeader loading={loading} show_back={false} title="">
          <div className="font-inter font-bold text-lg text-center mb-2">Draft version details</div>
          <div className="mb-2">{this.render_draft_table()}</div>
          <div className="mb-2">
            <div className="mt-2 flex">
              {draft_available ? (
                <>
                  <button onClick={this.set_values}>Edit details</button>
                  <button onClick={this.open_modal}>Create Choice</button>
                  <button onClick={this.open_data_config_modal}>Add data config</button>
                  <button onClick={this.release_conformation}>Release</button>
                </>
              ) : null}
            </div>
          </div>
          <div className="font-inter font-bold text-lg text-center mb-2 mt-5">
            Draft data config
          </div>
          <div className="mb-2">{this.render_draft_data_config()}</div>
        </CommonHeader>

        <CommonHeader loading={loading} show_back={false} title="">
          <div className="font-inter font-bold text-lg text-center mb-2">Draft choice list</div>
          {this.render_draft_choice_list()}
        </CommonHeader>
        <hr style={{ borderTop: '5px solid #000' }} />
        <CommonHeader loading={loading} show_back={false} title="History">
          <div className="mb-2">
            <button onClick={() => this.show_hide_history(tag)}>
              {!this.state.show_history_data ? 'Show History' : 'Hide History'}{' '}
            </button>
          </div>
          <div>{show_table && show_history_data ? this.render_history_table() : null}</div>
        </CommonHeader>

        <>
          <CustomModal show={modal_open} close={this.close_modal}>
            {this.render_create_choice()}
          </CustomModal>

          <CustomModal show={edit_modal} close={this.close_modal}>
            {this.render_draft()}
          </CustomModal>

          <CustomModal show={data_config_modal} close={this.close_modal}>
            {this.render_data_config()}
          </CustomModal>

          <CustomModal show={release_conformation_popup} close={this.close_modal}>
            {this.render_relese_popup()}
          </CustomModal>

          <CustomModal show={edit_data_config_modal} close={this.close_modal}>
            {this.redner_edit_config()}
          </CustomModal>

          <CustomModal show={show_delete_modal} close={this.close_modal}>
            {this.render_delete_popup()}
          </CustomModal>

          <CustomModal show={show_delete_config_modal} close={this.close_modal}>
            {this.render_delete_config_popup()}
          </CustomModal>

          <CustomModal show={show_error_popup} close={this.close_error_popup}>
            {this.render_error_popup()}
          </CustomModal>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitTestV2);
