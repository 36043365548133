import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import ScrollContainer from '../../../util_components/scroll_container';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import { get_date_filter_data_timezone_utc } from '../../../../util_functions';
import { date_drop_for_report } from '../../../../constants';
import { get_api_url } from '../../../../utils/urls';

class Referrals extends Component {
  state = {
    loading: true,
    date_filter_type: 'THIS WEEK',
    start_date: '',
    end_date: ''
  };

  componentDidMount() {
    const start_date = moment().format('YYYY-MM-DD');
    const end_time = moment().format('HH:mm');
    const end_date = moment().format('YYYY-MM-DD');
    this.setState({ start_date, end_date, end_time }, () => {
      this.load_data();
    });
  }

  load_data = () => {
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_referral_data');
      const { date_filter_type, start_date, end_date } = this.state;
      const date_data = get_date_filter_data_timezone_utc(date_filter_type, start_date, end_date);
      const payload = {
        start_date: date_data.utc_start_date,
        end_date: date_data.utc_end_date
      };
      post_api(url, payload, true)
        .then((result) => this.setState({ referrals: result.data.referral_data, loading: false }))
        .catch(() => this.setState({ loading: false, page_error: true }));
    });
  };

  render_referral_mobile = () => {
    const { referrals } = this.state;
    return (
      <div>
        {referrals.map((refer, index) => {
          const student_url = `/studentprofile?id=${refer.referrer.uuid}`;
          const referal_url = `/studentprofile?id=${refer.referal.uuid}`;
          let column_class = 'common-mobile-column';
          column_class +=
            index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
          return (
            <div className={column_class} key={index + refer.referrer.uuid}>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Referrer</div>
                <div className="common-mobile-single-detail">
                  <a className="link-no-dec" href={student_url}>
                    {refer.referrer.name}
                  </a>
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Date</div>
                <div className="common-mobile-single-detail">
                  {moment(refer.referal.referal_time)
                    .tz(this.props.iana_timezone)
                    .format('YYYY-MM-DD hh:mm z')}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Referral type</div>
                <div className="common-mobile-single-detail">
                  {refer.is_group === 1 ? 'Group' : '1-on-1'}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">
                  Referal registered
                </div>
                <div className="common-mobile-single-detail small-image-td">
                  {refer.referal.registered ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic-checkmark-48.png"
                      alt="av"
                    />
                  ) : (
                    <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
                  )}
                </div>
              </div>
              <div className="common-mobile-details">
                <div className="common-mobile-single-detail common-mobile-key">Referral</div>
                <div className="common-mobile-single-detail">
                  {refer.referal.registered ? (
                    <a className="link-no-dec" href={referal_url}>
                      {refer.referal.name}
                    </a>
                  ) : (
                    refer.referal.name
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render_single_referral = (refer, index) => {
    const student_url = `/studentprofile?id=${refer.referrer.uuid}`;
    const referal_url = `/studentprofile?id=${refer.referal.uuid}`;
    return (
      <div className="ct-row" key={index}>
        <div className="ct-col">
          <a className="link-no-dec" href={student_url}>
            {refer.referrer.name}
          </a>
        </div>
        <div className="ct-col">
          {moment(refer.referal.referal_time)
            .tz(this.props.iana_timezone)
            .format('YYYY-MM-DD hh:mm z')}
        </div>
        <div className="ct-col">{refer.is_group === 1 ? 'Group' : '1-on-1'}</div>
        <div className="ct-col ct-si ct-hf">
          {refer.referal.registered ? (
            <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
          ) : (
            <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
          )}
        </div>
        <div className="ct-col">
          {refer.referal.registered ? (
            <a className="link-no-dec" href={referal_url}>
              {refer.referal.name}
            </a>
          ) : (
            refer.referal.name
          )}
        </div>
      </div>
    );
  };

  render_referrals = () => {
    const { referrals } = this.state;
    return (
      <div className="cus-table" style={{ marginBottom: '20px' }}>
        <div className="ct-row ct-h">
          <div className="ct-col">Referrer</div>
          <div className="ct-col">Date</div>
          <div className="ct-col">Referral type</div>
          <div className="ct-col ct-hf">Referal registered</div>
          <div className="ct-col">Referral</div>
        </div>
        {referrals.map(this.render_single_referral)}
      </div>
    );
  };

  change_date_filter = (e) =>
    this.setState({ date_filter_type: e.target.value }, () => this.load_data());

  handle_date_change = (name, val) => {
    this.setState({
      [name]: val,
      first_load: false
    });
  };

  render_date_selection = () => {
    const { date_filter_type, start_date, end_date } = this.state;
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
      >
        <div style={{ marginRight: '10px' }}>
          <CustomSingleSelect
            label="Select a date filter"
            options={date_drop_for_report}
            onChange={this.change_date_filter}
            value={date_filter_type}
            style={{ width: '200px' }}
          />
        </div>
        {this.state.date_filter_type === 'CUSTOM' ? (
          <>
            <div className="daily-report-item">
              <CustomDatePicker
                label="Start Date"
                value={start_date}
                handleChange={(val) => this.handle_date_change('start_date', val)}
                maxDate={end_date}
              />
            </div>
            <div className="daily-report-item">
              <CustomDatePicker
                label="End Date"
                value={end_date}
                handleChange={(val) => this.handle_date_change('end_date', val)}
                minDate={start_date}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <button onClick={this.submit_click}>Submit</button>
          </>
        ) : null}
      </div>
    );
  };

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="common-page-title">Referrals</div>
        </div>
        {this.render_date_selection()}
        <div className="common-page-title">Referral Count - {this.state.referrals.length}</div>
        {this.props.application_width > 700
          ? this.render_referrals()
          : this.render_referral_mobile()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Referrals</title>
        </Helmet>
        <ScrollContainer />
        {this.render_function()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone,
  application_width: state.home.application_width
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Referrals);
