import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import CustomPlayer from '../../../util_components/custom_video_react';
import { image_error_replace } from '../../../../util_functions';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import 'video-react/dist/video-react.css';

export default class SessionViewer extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const session_uuid = url.searchParams.get('session_uuid');
    this.load_details(session_uuid);
  }

  load_details = (session_uuid) => {
    const url = get_api_url('session_details_for_cms');
    const payload = { session_uuid };
    post_api(url, payload, true)
      .then((result) =>
        this.setState({ session_details: result.data }, () => {
          this.setState({ loading: false });
        })
      )
      .catch(() => this.setState({ load_error: true, loading: false }));
  };

  go_back = () => {
    this.props.history.goBack();
  };

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const session = this.state.session_details;
    if (this.state.load_error) {
      return (
        <div className="common-page-container">
          <div className="cmp-go-back">
            <img
              src="https://images.myyogateacher.com/icons/ic_next@2x.png"
              alt="b"
              onClick={this.go_back}
            />
          </div>
          Some error
        </div>
      );
    }
    const teacher_url = `/teacherprofile?id=${session.teacher_uuid}`;
    const student_url = `/studentprofile?id=${session.student_uuid}`;
    return (
      <div className="common-page-container">
        <div className="cmp-go-back">
          <img
            src="https://images.myyogateacher.com/icons/ic_next@2x.png"
            alt="b"
            onClick={this.go_back}
          />
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <img src="" alt="i" onError={image_error_replace} />
              </td>
              <td>TEACHER</td>
              <td>TIMING</td>
              <td>STUDENT</td>
              <td>
                <img src="" alt="i" onError={image_error_replace} />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a className="link-no-dec" href={teacher_url}>
                  <img src={session.teacher_avatar_medium} alt="i" onError={image_error_replace} />
                </a>
              </td>

              <td>
                <a className="link-no-dec" href={teacher_url}>
                  {session.teacher_name}
                </a>
              </td>
              {/* <td>{session.start_date + ' ' + session.start_time}</td> */}
              <td>{moment(session.epoch_start_time).format('dddd MMMM Do YYYY')}</td>
              <td>
                <a className="link-no-dec" href={student_url}>
                  {session.student_name}
                </a>
              </td>
              <td>
                <a className="link-no-dec" href={student_url}>
                  <img src={session.student_avatar_medium} alt="i" onError={image_error_replace} />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <p />
        <CustomPlayer video_url={session.session_replay} />
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Session Viewer</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}
