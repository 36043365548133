import { all, put, takeLatest, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api, get_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* getReplacementSessions(action) {
  try {
    yield put(actions.set_loading_variable('replacement_sessions_status', 'loading'));
    const url = get_api_url('/v2/teacher_sessions/care/replacement_requests', false, true);

    const payload = { timezone: new Date().getTimezoneOffset() };
    const response = yield call(get_api, url, true);

    yield put(
      actions.set_home_variable('replacement_sessions', [...response.data.replacement_requests])
    );
    yield put(actions.set_loading_variable('replacement_sessions_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('replacement_sessions_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* getReplacementSessionTeachers(action) {
  try {
    yield put(actions.set_loading_variable('replacement_session_teachers_status', 'loading'));
    const url = get_api_url(
      `/v2/teacher_sessions/care/replacement_requests/${action.payload.replacement_request_id}`,
      false,
      true
    );

    const payload = { timezone: new Date().getTimezoneOffset() };
    const response = yield call(get_api, url, true);

    yield put(
      actions.set_home_variable('replacement_session_teachers', [
        ...response.data.replacement_requests
      ])
    );
    yield put(actions.set_loading_variable('replacement_session_teachers_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('replacement_session_teachers_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* getEmailTemplates() {
  try {
    yield put(actions.set_loading_variable('get_templates_status', 'loading'));
    const url = get_api_url('get_all_automated_emails');
    const response = yield call(post_api, url, null, true);
    yield put(actions.set_reports_variable('report_templates', [...response.data.result]));
    yield put(actions.set_loading_variable('get_templates_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_templates_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    console.log('chat errpr is ', error);
  }
}

export function* availAllInOne(action) {
  try {
    yield put(actions.set_loading_variable('avail_all_status', 'loading'));
    const url = get_api_url('availability_all_in_one');
    const { payload } = action;
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_reports_variable('avail_all', [...response.data.result]));
    yield put(actions.set_loading_variable('avail_all_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('avail_all_status', 'error'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export default function* chatSaga() {
  yield all([
    takeLatest(t.get_email_templates, getEmailTemplates),
    takeLatest(t.get_replacement_sessions, getReplacementSessions),
    takeLatest(t.get_replacement_session_teachers, getReplacementSessionTeachers),
    takeLatest(t.avail_all_in_one, availAllInOne)
  ]);
}
