import { combineReducers } from 'redux';

import teachers from './teachers_reducer';
import home from './home_reducer';
import loading from './loading_reducer';
import students from './students_reducer';
import workshops from './workshops_reducer';
import reports from './reports_reducer';
import notif from './notification_reducer';
import sessions from './sessions_reducer';
import content from './content_reducer';

const appReducer = combineReducers({
  teachers,
  home,
  loading,
  students,
  reports,
  notif,
  sessions,
  workshops,
  content
});

export default appReducer;
