import React, { Component } from 'react';
import CustomLoading from '../../util_components/custom_loading';
import Button from '../../util_components/button';
import CustomInput from '../../util_components/custom_input';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import CustomCheckBox from '../../util_components/custom_checkbox';
import UploadImage from '../../util_components/upload_image';
import * as actions from '../../../redux/action_creators';
import { connect } from 'react-redux';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

class CreateEditContent extends Component {
  state = {
    loading: false,
    short_name: '',
    icon: ''
  };

  componentDidMount() {
    this.set_tag_details();
  }

  set_tag_details = () => {
    const { tag_details } = this.props;

    this.setState({
      tag_uuid: tag_details.UUID,
      tag_name: tag_details.Name,
      tag_description: tag_details.Description,
      tag_label: tag_details.Label,
      tag_slug: tag_details.Slug,
      tag_whos_for: tag_details.WhosFor,
      tag_whos_not_for: tag_details.WhosNotFor,
      tag_how_will_help: tag_details.HowWillHelp,
      tag_how_will_not_help: tag_details.HowWillNotHelp,
      tag_is_shown_in_page: tag_details.IsShownInPage,
      tag_thumbnail: tag_details.Thumbnail,
      tag_tagline: tag_details.Tagline,
      tag_meta_description: tag_details.MetaDescription,
      icon: tag_details.Icon,
      short_name: tag_details.ShortName
    });
  };

  onCommonChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onCustomChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  toggle_check_box = (key) => this.setState({ [key]: this.state[key] === 1 ? 0 : 1 });

  submit_tag = () => {
    const {
      tag_uuid,
      tag_name,
      tag_description,
      tag_label,
      tag_slug,
      tag_whos_for,
      tag_whos_not_for,
      tag_how_will_help,
      tag_how_will_not_help,
      tag_is_shown_in_page,
      tag_thumbnail,
      tag_tagline,
      tag_meta_description,
      icon,
      short_name
    } = this.state;

    const payload = {
      UUID: tag_uuid,
      Name: tag_name,
      label: tag_label,
      description: tag_description,
      slug: tag_slug,
      whos_for: tag_whos_for,
      whos_not_for: tag_whos_not_for,
      how_will_help: tag_how_will_help,
      how_will_not_help: tag_how_will_not_help,
      is_shown_in_page: tag_is_shown_in_page,
      thumbnail: tag_thumbnail,
      tagline: tag_tagline,
      meta_description: tag_meta_description,
      icon,
      short_name
    };
    this.props.submit_tag(payload);
  };

  render_custom_input = (label, name, value, style, type = 'text') => (
    <CustomInput
      label={label}
      onChange={type === 'number' ? this.on_number_change : this.onCustomChange}
      name={name}
      type={type}
      value={value}
      style={style}
    />
  );

  render_react_quill = (label, key, tag_content) => {
    const tag_key = `tag_${key}`;
    return (
      <div>
        Enter {label}:
        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          theme="snow"
          value={tag_content || ''}
          onChange={(tag_content) => this.onCommonChange(tag_key, tag_content)}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote', 'color'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean']
              ],
              handlers: {
                image: this.newImageHandler
              }
            }
          }}
          formats={formats}
        />
      </div>
    );
  };

  change_input = (field_name, value) => {
    this.setState({ [field_name]: value });
  };

  start_loading = () => this.setState({ loading: true });

  stop_loading = () => this.setState({ loading: false });

  render() {
    const {
      tag_uuid,
      tag_name,
      tag_description,
      tag_label,
      tag_slug,
      tag_whos_for,
      tag_whos_not_for,
      tag_how_will_help,
      tag_how_will_not_help,
      tag_is_shown_in_page,
      tag_thumbnail,
      tag_tagline,
      tag_meta_description,
      short_name,
      icon
    } = this.state;
    return (
      <div>
        {this.state.loading ? <CustomLoading /> : null}
        <div className="display-flex-between">
          {this.render_custom_input('Enter Tag name *', 'tag_name', tag_name, { width: '550px' })}
        </div>
        <div className="display-flex-between">
          {/* {this.render_custom_input('Enter Tag slug *', 'tag_slug', tag_slug, { width: '250px' })} */}
          {this.render_custom_input('Enter Tag label *', 'tag_label', tag_label, {
            width: '250px'
          })}
        </div>
        <div className="display-flex-between">
          {this.render_custom_input('Enter Short Name', 'short_name', short_name, {
            width: '550px'
          })}
        </div>
        <div className="display-flex-between">
          {this.render_custom_input('Enter Icon Url', 'icon', icon, { width: '550px' })}
        </div>
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <div>Enter Tagline</div>
            <textarea
              style={{ width: '100%', height: '100px' }}
              value={tag_tagline}
              name="tag_tagline"
              onChange={this.onCustomChange}
              maxLength="2000"
            />
          </div>
        </div>
        <div className="display-flex-between">
          <div className="custom-input-label" style={{ width: '100%', marginBottom: '10px' }}>
            <div>Enter Meta Description</div>
            <textarea
              style={{ width: '100%', height: '100px' }}
              value={tag_meta_description}
              name="tag_meta_description"
              onChange={this.onCustomChange}
              maxLength="2000"
            />
          </div>
        </div>
        <div style={{ margin: '10px 0' }}>
          <CustomCheckBox
            label="Is Shown in Page"
            checked={tag_is_shown_in_page}
            onClick={() => this.toggle_check_box('tag_is_shown_in_page')}
          />
        </div>
        <br />
        {this.render_react_quill('Description', 'description', tag_description)}
        <br />
        {this.render_react_quill('Whos For', 'whos_for', tag_whos_for)}
        <br />
        {this.render_react_quill('Whos Not For', 'whos_not_for', tag_whos_not_for)}
        <br />
        {this.render_react_quill('How Will Help', 'how_will_help', tag_how_will_help)}
        <br />
        {this.render_react_quill('How Will Not Help', 'how_will_not_help', tag_how_will_not_help)}
        <br />
        {this.render_custom_input('Enter Thumbnail Image', 'tag_thumbnail', tag_thumbnail, {
          width: '500px'
        })}
        <br />
        <div className="display-flex-between">
          <UploadImage
            buttonTitle={tag_thumbnail ? 'Edit Thumbnail Url' : 'Add Thumbnail Url'}
            imageUrl={tag_thumbnail}
            uuid={tag_uuid}
            set_notification_variable={this.props.set_notification_variable}
            folder_name="tags"
            image_type="tag_thumbnail"
            change_input={this.change_input}
            start_loading={this.start_loading}
            stop_loading={this.stop_loading}
          />
        </div>
        <br />
        <Button onClick={this.submit_tag}>Submit</Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditContent);
