export const basic_header = `
<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />
  <link href="https://fonts.googleapis.com/css?family=PT+Sans+Narrow&display=swap" rel="stylesheet" />
  <title>MyYogaTeacher</title>
  <style type="text/css">
    .purple-btn {
      background-color: #4b3ddd;
    }

    .purple-btn:hover {
      background-color: #2d20b7 !important;
    }

    .aws-confirm {
      width: 1px;
      height: 1px;
    }

    .footer a:hover {
      opacity: 1 !important;
    }

    @media only screen and (max-width: 768px) {
      body {
        padding: 0px !important;
      }
    }

    @media only screen and (max-width: 600px) {
      .outer-table {
        width: 100% !important;
      }

      .middle-table {
        width: 75% !important;
      }

      .outer-table-row {
        padding: 25px 25px 15px !important;
      }

      .outer-table-row p {
        margin-top: 0px !important;
      }

      .text-table-td {
        padding: 0px 40px !important;
      }

      .contact-us {
        padding-left: 20px !important;
      }

      .contact-us a {
        font-size: 12px !important;
      }

      .purple-btn {
        font-size: 13px !important;
        width: 165px !important;
      }
    }
  </style>
</head>

<body>
  <table class="outer-table" id="outer-table"
    style="font-size:14px;line-height:24px;border-collapse: collapse;width: 600px;margin: auto; margin-top:50px;background-color: #161743;border-collapse: collapse;">
    <tbody>
      <tr>
        <td class="outer-table-row" style="padding: 18px 65px 5px;">
          <a href="myyogateacher.com" target="_blank" rel="noopener noreferrer"><img style="width: 120px;"
              src="https://images.myyogateacher.com/web_images/email-logo.png" alt="Logo" /></a>
        </td>
      </tr>
      <tr>
        <td>
          <table class="middle-table" valign="bottom"
            style="background: #FFFFFF; text-align:center; font-family: Helvetica, Arial, sans-serif;line-height: 24px;
            font-size: 14px;width: 500px;margin: auto;padding-top: 70px;border-bottom: 5px solid rgba(154, 111, 58, 0.2); border-collapse: collapse;">
            <tr>
              <td align="left" style="padding: 0 35px;padding-top: 30px;line-height: 24px;font-size: 14px;">
`;

export const basic_body = `
Hey [USER-NAME],
<br />



<br /><br />
Best,
<br />
MyYogaTeacher care team
`;

export const basic_footer = `
    </td>
            </tr>
            <tr>
              <td valign="bottom">
                <table class="footer"
                  style="border-collapse: collapse;width: 100%;white-space: nowrap;min-height: 136px;">
                  <tbody>
                    <tr>
                      <td class="contact-us" valign="middle" align="left" style="font-size: 12px; padding-left: 30px">
                        <a href="mailto:contact@myyogateacher.com" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">CONTACT US</a>
                        <span class="contact-break"
                          style="padding: 0 2.3% 0 2.5%; color:#6881A9;opacity: 0.3;font-weight: 300;">|</span>
                        <a href="mailto:care@myyogateacher.com" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">SUPPORT</a>
                        <span class="contact-break"
                          style="padding: 0 2.3% 0 2.5%;color:#6881A9;opacity: 0.3;font-weight: 300;">|</span>
                        <a href="https://blog.myyogateacher.com/" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">BLOG</a>
                        <hr style="border: 0.5px solid #e8e8e8;border-style: solid; margin-bottom: 15px;" />
                        <table style="width:100%">
                          <tr>
                            <td valign="middle" align="left" style="font-size: 12px;">
                              <a href="https://www.youtube.com/channel/UCPPV-GxgyyXFj1Kgi940LVA" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_youtube.png" alt="youtube"
                                  width="18px" />
                              </a>
                              <a href="https://www.facebook.com/myyogateacher.inc" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_Facebook.png" alt="facebook"
                                  width="15px" style="padding: 0px 8px;" />
                              </a>
                              <a href="https://www.instagram.com/myyogateacher/" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_instagram.png" alt="instagram"
                                  width="14px" />
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td align="right" valign="bottom">
                        <img style=" vertical-align: bottom;"
                          src="https://images.myyogateacher.com/email-mandala-r.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="outer-table-row" style="padding: 5px 65px 25px;">
          <p
            style="font-size: 14px; color: #fff;font-family: Helvetica, Arial, sans-serif;opacity: 0.4;font-weight: 400;">
            &copy; 2018 MyYogaTeacher, Inc.
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</body>
<footer>
  <img class="aws-confirm" width="1" height="1" src=[AWS-CONFIRMATION-LINK] />
</footer>

</html>
`;

export const payment_body = `
<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />
  <link href="https://fonts.googleapis.com/css?family=PT+Sans+Narrow&display=swap" rel="stylesheet" />
  <title>MyYogaTeacher</title>
  <style type="text/css">
    .purple-btn {
      background-color: #4b3ddd;
    }

    .aws-confirm {
      width: 1px;
      height: 1px;
    }

    .purple-btn:hover {
      background-color: #2d20b7 !important;
    }

    .footer a:hover {
      opacity: 1 !important;
    }

    @media only screen and (max-width: 768px) {
      body {
        padding: 0px !important;
      }
    }

    @media only screen and (max-width: 600px) {
      .outer-table {
        width: 100% !important;
      }

      .middle-table {
        width: 75% !important;
      }

      .outer-table-row {
        padding: 25px 25px 15px !important;
      }

      .outer-table-row p {
        margin-top: 0px !important;
      }

      .text-table-td {
        padding: 0px 40px !important;
      }

      .contact-us {
        padding-left: 20px !important;
      }

      .contact-us a {
        font-size: 12px !important;
      }

      .purple-btn {
        font-size: 13px !important;
        width: 165px !important;
      }
    }

    .teacher-table td {
      text-align: center;
      vertical-align: middle;
    }
  </style>
</head>

<body>
  <table class="outer-table" id="outer-table"
    style="font-size:14px;line-height:24px;border-collapse: collapse;width: 600px;margin: auto; margin-top:50px;background-color: #161743;border-collapse: collapse;">
    <tbody>
      <tr>
        <td class="outer-table-row" style="padding: 18px 65px 5px;">
          <a href="myyogateacher.com" target="_blank"><img style="width: 120px;"
              src="https://images.myyogateacher.com/web_images/email-logo.png" alt="Logo" /></a>
        </td>
      </tr>
      <tr>
        <td>
          <table class="middle-table" valign="bottom"
            style="background: #FFFFFF; text-align:center; font-family: Helvetica, Arial, sans-serif;line-height: 24px;
            font-size: 14px;width: 500px;margin: auto;padding-top: 70px;border-bottom: 5px solid rgba(154, 111, 58, 0.2); border-collapse: collapse;">
            <tr>
              <td align="left" style="padding: 50px 35px 80px 35px;line-height: 24px;font-size: 14px;">
                Hey [USER-NAME],
                <br /><br />
                [EMAIL-BODY]
                <br /><br />
                [TEACHER-CONTENT]
                <br />
                [NO-SHOW-CONTENT]
                <br />
                [CANCELLATION-BLOCK-WITHIN-ONE-HOUR-CONTENT]
                <br />
                [REPLACEMENT-WITHIN-ONE-HOUR-CONTENT]
                <br />
                [LATE-JOIN-CONTENT]
                <br />
                [LATE-AUDIO-VIDEO-TURN-ON-CONTENT]
                <br />
                [EARLY-EXIT-PENALTY-CONTENT]
                <br />
                [CANCELLATION-CONTENT]
                <br />
                [REPLACEMENT-CONTENT]
                <br />
                [RESCHEDULES-CONTENT]
                <br />
                [CONVERSION-CONTENT]
                <br />
                [DAY-WISE-CONTENT]
                <br />
                <br />
                Best,
                <br />
                MyYogaTeacher care team
              </td>
            </tr>

            <tr></tr>

            <tr>
              <td valign="bottom">
                <table class="footer"
                  style="border-collapse: collapse;width: 100%;white-space: nowrap;min-height: 136px;">
                  <tbody>
                    <tr>
                      <td class="contact-us" valign="middle" align="left" style="font-size: 12px; padding-left: 30px">
                        <a href="mailto:care@myyogateacher.com" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">CONTACT US</a>
                        <span class="contact-break"
                          style="padding: 0 2.3% 0 2.5%; color:#6881A9;opacity: 0.3;font-weight: 300;">|</span>
                        <a href="mailto:care@myyogateacher.com" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">SUPPORT</a>
                        <span class="contact-break"
                          style="padding: 0 2.3% 0 2.5%;color:#6881A9;opacity: 0.3;font-weight: 300;">|</span>
                        <a href="https://blog.myyogateacher.com/" target="_blank" style="cursor: pointer;text-decoration:none;font-size: 12px;
        color: #4B3DDD;opacity: 0.8">BLOG</a>
                        <hr style="border: 0.5px solid #e8e8e8;border-style: solid; margin-bottom: 15px;" />
                        <table style="width:100%">
                          <tr>
                            <td valign="middle" align="left" style="font-size: 12px;">
                              <a href="https://www.youtube.com/channel/UCPPV-GxgyyXFj1Kgi940LVA" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_youtube.png" alt="youtube"
                                  width="18px" />
                              </a>
                              <a href="https://www.facebook.com/myyogateacher.inc" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_Facebook.png" alt="facebook"
                                  width="15px" style="padding: 0px 8px;" />
                              </a>
                              <!-- <a href="#" style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                    src="https://images.myyogateacher.com/email_template/ic_Twitter.png"
                                    alt="twitter" width="16.7" height="13.7" style="padding-right: 2.4%;" />
                            </a> -->
                              <a href="https://www.instagram.com/myyogateacher/" target="_blank"
                                style="cursor: pointer;text-decoration: none">
                                <img class="social-icon"
                                  src="https://images.myyogateacher.com/email_template/ic_instagram.png" alt="instagram"
                                  width="14px" />
                              </a>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td align="right" valign="bottom">
                        <img style=" vertical-align: bottom;"
                          src="https://images.myyogateacher.com/email-mandala-r.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td class="outer-table-row" style="padding: 5px 65px 25px;">
          <p
            style="font-size: 14px; color: #fff;font-family: Helvetica, Arial, sans-serif;opacity: 0.4;font-weight: 400;">
            &copy; 2024 MyYogaTeacher, Inc.
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</body>
<footer>
  <img class="aws-confirm" width="1" height="1" src=[AWS-CONFIRMATION-LINK] />
</footer>

</html>
`;

export const montly_payment_block = `
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Group Session Name</th>
    <th>Session Status</th>
    <th>Member Type</th>
    <th>Duration</th>
    <th>Total Sessions</th>
    <th>Total Joinees</th>
    <th>Currency</th>
    <th>Teacher Rate</th>
    <th>Amount</th>
</tr>
    [TEACHER-BLOCK]
</table>
`;

export const late_join_block = ` 
<p>Late Joins Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
  <th>Session Type</th>
  <th>Duration</th>
  <th>Teacher Rate</th>
  <th>Class Date (IST)</th>
  <th>Class Time (IST)</th>
  <th>Student/Class Name</th>
  <th>1st Student Join Time</th>
  <th>Teacher Join Time</th>
  <th>penalty</th>
</tr>
    [LATE-JOIN-BLOCK]
</table>`;

export const no_show_block = ` 
<p>No Show Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Duration</th>
    <th>Class Date (IST)</th>
    <th>Class Time (IST)</th>
    <th>Student/Class Name</th>
    <th>Teacher Rate</th>
    <th>penalty</th>
</tr>
    [NO-SHOW-BLOCK]
</table>`;

export const cancellation_block = ` 
<p>Cancellation within 24hrs Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Duration</th>
    <th>Class Date</th>
    <th>Class Time</th>
    <th>Class Name</th>
    <th>Teacher Rate</th>
    <th>Cancellation Time</th>
    <th>Session booking time</th>
    <th>penalty</th>
</tr>
    [CANCELLATION-BLOCK]
</table>`;

export const replacement_block = ` 
<p>Replacement within 24hrs Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Duration</th>
    <th>Class Date</th>
    <th>Class Time</th>
    <th>Class Name</th>
    <th>Teacher Rate</th>
    <th>Replacement Request Date Time</th>
    <th>Replacement Session Status</th>
    <th>penalty</th>
</tr>
    [REPLACEMENT-BLOCK]
</table>`;

export const day_wise_block = ` 
<p>Free Pass Day : Day Wise Breakdown</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Day of Class Date (IST)</th>
    <th>Free Pass Day Applicable</th>
    <th>Penalty Amount (Late Cancel & Replace)</th>
</tr>
    [DAY-WISE-BLOCK]
</table>`;

export const day_wise_summary_block = `
<br /><br />
<p>Free Pass Day : Summary</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr><td>Free Pass Days Opening Balance ( as of [DAY-WISE-MONTH] 1,  [DAY-WISE-YEAR])</td><td>[OPENING-BALANCE]</td></tr>
<tr><td>No. of Free Pass Days Availed for [DAY-WISE-MONTH], [DAY-WISE-YEAR]</td><td>[PASS-AVAILED]</td></tr>
<tr><td>Total Penalty for Late Cancellation / Replacement</td><td>[TOTAL-PENALTY]</td></tr>
<tr><td>Penalty Waived Off using Free Pass Days</td><td>[PENALTY-WAIVED]</td></tr>
<tr><td>Effective Penalty for Late Cancellation / Replacement</td><td>[FINAL-PENALTY]</td></tr>
<tr><td>Free Pass Days Closing Balance ( as of [END-OF-MONTH])</td><td>[CLOSING-BALANCE]</td></tr>
</table>
`;

export const conversion_block = ` 
<p>Trial to Membership Conversion Bonus:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Student Name</th>
    <th>Student's Lateset Session</th>
    <th>Total Sessions before membership</th>
    <th>Bonus</th>
</tr>
    [CONVERSION-BLOCK]
</table>`;

export const early_exit_penalty_block = `
<p>Early Exit penalty:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
  <th>Session Type</th>
  <th>Duration</th>
  <th>Teacher Rate</th>
  <th>Class Date (IST)</th>
  <th> Class Time (IST)</th>
  <th>Student/Class Name</th>
  <th>Teacher Leaving Time</th>
  <th>penalty</th>
</tr>
   [EARLY-EXIT-PENALTY-BLOCK]
</table>
`;

export const late_audio_video_turn_on = `
<p>Late Audio/Video Turn On:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
  <th>Session Type</th>
  <th>Duration</th>
  <th>Teacher Rate</th>
  <th>Class Date (IST)</th>
  <th>Class Time (IST)</th>
  <th>Student/Class Name</th>
  <th>1st Student Join Time</th>
  <th>Teacher Join Time</th>
  <th>Penalty Reason</th>
  <th>penalty</th>
</tr>
    [LATE-AUDIO-VIDEO-TURN-ON-BLOCK]
</table>
`;

export const cancellation_block_within_one_hour = ` 
<p>Cancellation within 1hr Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Duration</th>
    <th>Class Date</th>
    <th>Class Time</th>
    <th>Class Name</th>
    <th>Teacher Rate</th>
    <th>Cancellation Time</th>
    <th>Session booking time</th>
    <th>penalty</th>
</tr>
    [CANCELLATION-BLOCK-WITHIN-ONE-HOUR-BLOCK]
</table>`;

export const replacement_block_within_one_hour = ` 
<p>Replacement within 1hr Details:</p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
    <th>Session Type</th>
    <th>Duration</th>
    <th>Class Date</th>
    <th>Class Time</th>
    <th>Class Name</th>
    <th>Teacher Rate</th>
    <th>Replacement Request Date Time</th>
    <th>Replacement Session Status</th>
    <th>penalty</th>
</tr>
    [REPLACEMENT-BLOCK-WITHIN-ONE-HOUR]
</table>`;

export const reschedules_block = `
<p>Cancellation/Replacement of Rescheduled Sessions: </p>
<table cellpadding="5" cellspacing="0" class="teacher-table" border="1">
<tr>
  <th>Session Type</th>
  <th>Initiated By</th>
  <th>Session Status</th>
  <th>Reschedule Time</th>
  <th>Session Start Time (IST)</th>
  <th>Reschedule  Within</th>
  <th>Student Name</th>
  <th>Original Session Time</th>
  <th>New Session Time</th>
  <th>Teacher Rate</th>
  <th>Penalty</th>
</tr>
 [RESCHEDULES-BLOCK]
</table>

`;
