import React from 'react';
import PropTypes from 'prop-types';

function ConfirmationPopup(props) {
  return (
    <div className="p-4 flex flex-col items-center">
      <div className="flex font-inter font-semibold text-center">{props.title}</div>
      <div className="flex flex-row justify-end">
        <button className="btn-white-icon" onClick={props.close_modal}>
          No
        </button>
        <button onClick={props.confirm}>Yes</button>
      </div>
    </div>
  );
}

ConfirmationPopup.propTypes = {
  title: PropTypes.string,
  close_modal: PropTypes.func,
  confirm: PropTypes.func
};

export default ConfirmationPopup;
