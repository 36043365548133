import { createSelector } from 'reselect';

export const get_past_sessions_status = (state) => state.loading.past_sessions_status;
export const get_future_sessions_status = (state) => state.loading.future_sessions_status;

export const past_sessions_status = createSelector(
  get_past_sessions_status,
  (past_sessions_status) => past_sessions_status
);

export const future_sessions_status = createSelector(
  get_future_sessions_status,
  (future_sessions_status) => future_sessions_status
);
