import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';
import CommonHeader from '../../util_components/common_header';
import { is_production } from '../../../util_functions';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class DownloadData extends Component {
  state = {
    loading: false,
    student_segment_data: []
  };

  load_user_segments = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('generate_student_segment_data');
      post_api(url, null, true)
        .then((res) => {
          this.setState({
            student_segment_data: [...res.data.student_segment_data],
            loading: false
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_segment_excel = () => {
    const { student_segment_data } = this.state;
    const file_name = `User segment data - ${!is_production() ? 'staging - ' : ''} ${moment().format('YYYY-MM-DD')}`;
    const first_data = student_segment_data.find((s) => s.uuid == 1);
    const second_data = student_segment_data.find((s) => s.uuid == 2);
    const third_data = student_segment_data.find((s) => s.uuid == 3);
    const fourth_data = student_segment_data.find((s) => s.uuid == 4);
    return (
      <ExcelFile element={<button>Download</button>} filename={file_name}>
        <ExcelSheet data={first_data.data} name="0-7 Days">
          <ExcelColumn label="Student Id" value="id" />
          <ExcelColumn label="First Name" value="first_name" />
          <ExcelColumn label="Email" value="email" />
        </ExcelSheet>
        <ExcelSheet data={second_data.data} name="8-14 Days">
          <ExcelColumn label="Student Id" value="id" />
          <ExcelColumn label="First Name" value="first_name" />
          <ExcelColumn label="Email" value="email" />
        </ExcelSheet>
        <ExcelSheet data={third_data.data} name="15-28 Days">
          <ExcelColumn label="Student Id" value="id" />
          <ExcelColumn label="First Name" value="first_name" />
          <ExcelColumn label="Email" value="email" />
        </ExcelSheet>
        <ExcelSheet data={fourth_data.data} name="6+any joined">
          <ExcelColumn label="Student Id" value="id" />
          <ExcelColumn label="First Name" value="first_name" />
          <ExcelColumn label="Email" value="email" />
          <ExcelColumn label="Count" value="cnt" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  render_download_user_segment = () => {
    const { student_segment_data } = this.state;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row">
            <div className="ct-col">User Segment Data</div>
            <div className="ct-col">
              <button onClick={this.load_user_segments}>Generate Data</button>
            </div>
            <div className="ct-col">
              {student_segment_data.length > 0 ? this.render_segment_excel() : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <CommonHeader loading={loading} title="Download Data">
        {this.render_download_user_segment()}
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadData);
