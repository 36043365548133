import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import * as actions from '../../../../redux/action_creators';
import { get_api_url } from '../../../../utils/urls';

class CreditHistory extends Component {
  state = {
    loading: true,
    confirm_open: false,
    confirm_uuid: ''
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    this.setState({ student_uuid: uuid }, () => {
      this.load_details();
    });
  }

  load_details = () => {
    const url = get_api_url('get_credits_history');
    const payload = { student_uuid: this.state.student_uuid };
    post_api(url, payload, true).then((result) =>
      this.setState({ transactions: result.data, loading: false })
    );
  };

  confirm_change = (uuid) => this.setState({ confirm_uuid: uuid, confirm_change: true });

  close_confirm = () => this.setState({ confirm_uuid: '', confirm_change: false });

  change_credit = () =>
    this.setState({ loading: true }, () => {
      const { student_uuid, confirm_uuid } = this.state;
      const payload = { student_uuid, student_credits_history_id: confirm_uuid };
      const url = get_api_url('change_referral_dicount_to_1on1_session');
      post_api(url, payload, true)
        .then(() => {
          this.close_confirm();
          this.props.set_notification_variable(true, 'success', 'Credit Updated');
          this.load_details();
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_data_table = (transactions) => (
    <div className="cus-table">
      <div className="ct-row ct-h">
        <div className="ct-col ct-sm-font">By</div>
        <div className="ct-col ct-qf ct-sm-font">Issued</div>
        <div className="ct-col ct-sm-font">Reason</div>
        <div className="ct-col ct-qf ct-sm-font">After</div>
        <div className="ct-col ct-sm-font">Affiliate</div>
        <div className="ct-col ct-sm-font">Referral</div>
        <div className="ct-col ct-sm-font">Date</div>
        <div className="ct-col ct-sm-font">Change to 1on1</div>
      </div>
      {transactions.map((trans) => (
        <div className="ct-row" key={trans.id}>
          <div className="ct-col ct-sm-font ct-bw">{trans.credits_issued_by}</div>
          <div className="ct-col ct-qf">{trans.credits_issued}</div>
          <div className="ct-col ct-vsm-font">{trans.reason}</div>
          <div className="ct-col ct-qf">{trans.credits_after}</div>
          <div className="ct-col ct-si">
            {trans.is_affiliate === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </div>
          <div className="ct-col ct-si">
            {trans.is_referral === 1 ? (
              <img src="https://images.myyogateacher.com/icons/ic-checkmark-48.png" alt="av" />
            ) : (
              <img src="https://images.myyogateacher.com/icons/ic-delete-48.png" alt="nav" />
            )}
          </div>
          <div className="ct-col ct-sm-font">
            {moment(trans.created_date).tz(this.props.iana_timezone).format('MMM DD hh:mm A z')}
          </div>
          <div className="ct-col">
            {trans.reason === 'STUDENT-REFERRAL-GROUP-CLASS' ? (
              <a className="btn-link" onClick={() => this.confirm_change(trans.id)}>
                Change
              </a>
            ) : (
              '--'
            )}
          </div>
        </div>
      ))}
    </div>
  );

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const other_credits = this.state.transactions.filter((t) => t.reason !== 'ROLLOVER-CREDITS');
    const rollovers = this.state.transactions.filter((t) => t.reason === 'ROLLOVER-CREDITS');
    return (
      <div className="common-page-container">
        <div className="common-page-header">
          <div className="profile-go-back" onClick={() => this.props.history.goBack()}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Credit History</div>
        </div>
        {this.render_data_table(other_credits)}
        {rollovers.length > 0 ? (
          <>
            <h4>Rollovers:</h4>
            {this.render_data_table(rollovers)}
          </>
        ) : null}
      </div>
    );
  };

  render_confirm = () => (
    <div>
      <h4>Are you sure you want to change the credit to 1 on 1?</h4>
      <button onClick={this.change_credit}>Submit</button>
    </div>
  );

  render() {
    const { confirm_change } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Credit History</title>
        </Helmet>
        {this.render_function()}
        <CustomModal show={confirm_change} close={this.close_confirm}>
          {this.render_confirm()}
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditHistory);
