import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import { post_api } from '../../../../redux/api_funcs';
import ScrollContainer from '../../../util_components/scroll_container';
import '../key_metrics/user_rev.css';
import { get_api_url } from '../../../../utils/urls';

class SessionsWeekly extends Component {
  state = {
    loading: true,
    data: [],
    data_keys: []
  };

  load_data = () => {
    const url = get_api_url('get_weekly_sessions_analysis_data');
    post_api(url, null, true).then((result) => {
      const met_data = result.data.metrics;
      const key_names = {};
      const data_keys = met_data.map((m) => {
        key_names[m.key] = m.value;
        return m.key;
      });
      const { data } = result.data;
      this.setState({
        data,
        data_keys,
        met_data,
        key_names,
        loading: false
      });
    });
  };

  componentDidMount() {
    this.load_data();
  }

  render_data_mobile = () => {
    const { data, data_keys, key_names } = this.state;
    return (
      <div>
        {data.map((item, index) => {
          let column_class = 'common-mobile-column';
          column_class +=
            index % 2 === 0 ? ' common-mobile-column-even' : ' common-mobile-column-odd';
          return (
            <div className={column_class} key={item.week_name}>
              {data_keys.map((key) => {
                if (key.indexOf('line_break') !== -1) {
                  return <br key={key} />;
                }
                return (
                  <div className="common-mobile-details" key={key}>
                    <div className="common-mobile-single-detail common-mobile-key">
                      {key === 'week_name' ? 'Week' : key_names[key]}
                    </div>
                    <div className="common-mobile-single-detail">{item[key]}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  render_data = () => {
    const { data, data_keys, key_names } = this.state;
    return (
      <div style={{ display: 'flex', marginRight: '10px', width: '99%', overflow: 'auto' }}>
        <table className="user-rev-table user-rev-td-fir">
          <tbody>
            {data_keys.map((key, index) => {
              const tr_class = key.indexOf('line_break') !== -1 ? 'user-rev-no-bor' : '';
              const first_row = index === 0 ? 'user-rev-row-fir ' : '';
              return (
                <tr className={`user-rev-row ${tr_class}`} key={key + index}>
                  <td className={`user-rev-td user-rev-td-fir ${first_row}`}>{key_names[key]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.map((item, index) => (
          <table key={item.week_name + index} className="user-rev-table">
            <tbody>
              {data_keys.map((key1, index1) => {
                const first_row = index1 === 0 ? 'user-rev-row-fir ' : '';
                let new_break = false;
                try {
                  if (item[key1].indexOf('\n') !== -1) {
                    new_break = true;
                  }
                } catch {}
                const tr_class = key1.indexOf('line_break') !== -1 ? 'user-rev-no-bor' : '';
                if (new_break) {
                  return (
                    <tr className={`user-rev-row ${tr_class}`} key={index + key1}>
                      <td className={`sess-week-rev-td ${first_row}`}>
                        {item[key1].split('\n')[0]}
                        <br />
                        {item[key1].split('\n')[1]}
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr className={`user-rev-row ${tr_class}`} key={index + key1}>
                    <td className={`sess-week-rev-td ${first_row}`}>{item[key1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="common-page-container">
        <ScrollContainer />
        {this.state.loading ? <CustomLoading /> : null}
        <div ref={(el) => (this.componentRef = el)}>
          <div className="common-page-header">
            <div className="common-page-title">Weekly Report</div>
          </div>
          {this.state.data.length > 0
            ? this.props.application_width > 700
              ? this.render_data()
              : this.render_data_mobile()
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  application_width: state.home.application_width
});

// const mapDispatchToProps = dispatch => {
// 	return {
// 		log_out: () => dispatch(actions.log_out()),
// 	};
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(SessionsWeekly);
