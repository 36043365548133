import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomLoading from '../../../util_components/custom_loading';
import { basic_header, basic_footer, basic_body } from '../../../../constants/email_template';
import CustomInput from '../../../util_components/custom_input';
import { post_api } from '../../../../redux/api_funcs';
import Button from '../../../util_components/button';
import * as actions from '../../../../redux/action_creators';
import './create.css';
import { get_api_url } from '../../../../utils/urls';

class CreateEmail extends Component {
  state = {
    show_back: false,
    email_template: basic_body,
    to_address: '',
    subject: '',
    input_error: false,
    error_text: '',
    emails_list: [],
    email_loading: false,
    email_data: [],
    load_email: false,
    from_transaction: false,
    amount: 0,
    reason: ''
  };

  componentDidMount() {
    try {
      const prop_state = this.props.location.state;
      const { email } = prop_state;
      const { name } = prop_state;
      const emails_list = [];
      let email_template = basic_body;
      const { email_list } = prop_state;
      const email_data = prop_state.email_data || [];
      if (!!email && !!name) {
        emails_list.push(email);
        email_template = email_template.toString().replace('[USER-NAME]', name);
        this.setState({ emails_list, email_template });
      }
      if (!!email_list && email_list.length > 0) {
        this.setState({ emails_list: email_list, email_template, email_data });
      }
      if (prop_state.from_transaction) {
        this.setState({
          load_email: prop_state.load_email,
          from_transaction: prop_state.from_transaction,
          amount: prop_state.amount,
          reason: prop_state.reason
        });
      }
    } catch (e) {}
  }

  createMarkup = () => {
    const template = this.state.email_template;
    const final_template = `${basic_header}${basic_footer}`;
    return { __html: final_template };
  };

  on_change = (e) => this.setState({ email_template: e.target.value, input_error: false });

  render_fn = () => {
    const { email_template } = this.state;
    const final_template = `${basic_header}${email_template}${basic_footer}`;
    return (
      <div className="cr-email-container">
        <div className="cr-email-text">
          <textarea value={email_template} onChange={this.on_change} />
        </div>
        <div className="cr-email-view">
          <iframe srcDoc={final_template} />
        </div>
      </div>
    );
  };

  onCommonChange = (e) => this.setState({ [e.target.name]: e.target.value, input_error: false });

  add_email = () => {
    const { to_address, emails_list } = this.state;
    const to_address_list = to_address.split(',');
    this.setState({ emails_list: [...emails_list, ...to_address_list], to_address: '' });
  };

  delete_email = (index) => {
    const { emails_list } = this.state;
    this.setState({
      emails_list: [...emails_list.slice(0, index), ...emails_list.slice(index + 1)]
    });
  };

  render_single_email = (email, index) => {
    const { from_transaction, load_email } = this.state;
    return (
      <div className="cr-email-single" key={index + email}>
        {email}
        {!(load_email && from_transaction) ? (
          <p onClick={() => this.delete_email(index)}>X</p>
        ) : null}
      </div>
    );
  };

  render_header = () => {
    const { to_address, subject, emails_list, from_transaction, load_email } = this.state;
    return (
      <div>
        {!(load_email && from_transaction) ? (
          <div className="cr-email-toaddr">
            <CustomInput
              label="Enter email"
              style={{ width: '500px' }}
              value={to_address}
              name="to_address"
              onChange={this.onCommonChange}
            />
            <Button onClick={this.add_email} disabled={!to_address}>
              Add
            </Button>
          </div>
        ) : null}
        <div className="cr-email-emails-list">{emails_list.map(this.render_single_email)}</div>
        <CustomInput
          label="Enter subject"
          style={{ width: '500px' }}
          value={subject}
          name="subject"
          onChange={this.onCommonChange}
        />
      </div>
    );
  };

  send_mail = () => {
    const { emails_list, subject, email_template, email_data } = this.state;
    if (emails_list.length <= 0) {
      this.setState({ input_error: true, error_text: 'Please enter to addresses' });
    } else if (!subject) {
      this.setState({ input_error: true, error_text: 'Please enter subject' });
    } else {
      this.setState({ email_loading: true }, () => {
        const url = get_api_url('send_email_cms');
        const payload = {
          to_address: emails_list,
          subject,
          email_body: `${basic_header}${email_template}${basic_footer}`,
          email_type: 'other',
          email_data
        };
        post_api(url, payload, true)
          .then((response) => {
            this.props.set_notification_variable(true, 'success', 'Email sent successfully');
            this.setState({
              emails_list: [],
              subject: '',
              email_template: basic_body,
              email_loading: false,
              from_transaction: false,
              load_email: false
            });
          })
          .catch((e) => {
            console.log(e);
            let err_message;
            try {
              err_message = e.response.data.message;
            } catch (err) {
              err_message = 'Some error occured. Please contact dev team.';
            }
            this.props.set_notification_variable(true, 'error', err_message);
            this.setState({ email_loading: false });
          });
      });
    }
  };

  go_back = () => this.props.history.goBack();

  render_back = () => {
    if (this.state.show_back) {
      return (
        <div className="profile-go-back" onClick={this.go_back}>
          <img
            src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
            alt="back"
          />
          <span>Back</span>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      input_error,
      error_text,
      email_loading,
      emails_list,
      subject,
      from_transaction,
      amount
    } = this.state;
    const btn_disabled = emails_list.length === 0 || !subject;
    return (
      <div className="common-page-container">
        {email_loading ? <CustomLoading /> : null}
        <div className="common-page-header">
          {this.render_back()}
          <div className="common-page-title">
            Create Email
            {from_transaction ? <div>{`(for the charge $${amount})`}</div> : null}
          </div>
        </div>
        {this.render_header()}
        {this.render_fn()}
        <Button className="cr-email-send" onClick={this.send_mail} disabled={btn_disabled}>
          Send Email
        </Button>
        {input_error ? <p className="cr-email-error">{error_text}</p> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmail);
