import React, { useEffect, useReducer, useState } from 'react';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import { get_api, post_api } from '../../../redux/api_funcs';
import { StarIcon } from '../../../utils/svg';

export default function CreateSessionFeedback() {
  const [loading, setLoading] = useState(true);
  const [questionData, setQuestionData] = useState([]);
  const [feedbackHash, setFeedbackHash] = useState({});
  const [sessionUUID, setSessionUUID] = useState('');
  const [optionSelectedHash, setOptionSelectedHash] = useState({});
  const [mandatoryFieldError, setMandatoryFieldError] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const optionsObject = (optionsArray) => {
    const acc = {};
    optionsArray.map((op) => {
      acc[op] = true;
    });
    return acc;
  };
  const loadData = () => {
    setLoading(true);
    const currentUrl = new URL(window.location.href);
    const uuid = currentUrl.searchParams.get('session_uuid');
    setSessionUUID(uuid);
    const apiUrl = `/v2/cms_user/sessions/feedbacks/${uuid}/edit/QA_SCORE_CARD`;
    const url = get_api_url(apiUrl, null, true);
    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        const tempFeedbackHash = {};
        const tempFeedbackOption = {};
        if (data) {
          setQuestionData(data);
          data.forEach((fb) => {
            fb?.questions.forEach((element) => {
              tempFeedbackHash[element.question_id] = element;
              if (element.question_type === 'MULTIPLE_SELECT') {
                tempFeedbackOption[element.question_id] = optionsObject(element.answer.split(', '));
              }
            });
          });
        }
        setOptionSelectedHash(tempFeedbackOption);
        setFeedbackHash(tempFeedbackHash);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const setMandatoryFieldFalse = (id, answer = '') => {
    if (mandatoryFieldError[id] && answer !== '') {
      setMandatoryFieldError((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const keys = Object.keys(feedbackHash);
    let isReturn = false;
    keys.forEach((key) => {
      if (feedbackHash[key]?.is_mandatory && !feedbackHash[key]?.answer) {
        isReturn = true;
        setMandatoryFieldError((prev) => ({ ...prev, [key]: true }));
      }
    });
    if (isReturn) {
      return;
    }
    const values = Object.values(feedbackHash);
    setLoading(true);
    const apiUrl = `/v2/cms_user/sessions/feedbacks`;
    const url = get_api_url(apiUrl, null, true);
    const payload = {
      session_uuid: sessionUUID,
      feedback: values
    };
    post_api(url, payload, true)
      .then(() => {
        setLoading(false);
        window.location.replace(`/session_score_card?session_uuid=${sessionUUID}`);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleMultiSelect = (e, question) => {
    let answer = [];
    if (feedbackHash[question.question_id]?.answer) {
      answer = feedbackHash[question.question_id]?.answer?.split(', ');
    }

    // Check if optionSelectedHash[question.id] is defined, if not, initialize it
    if (!optionSelectedHash[question.question_id]) {
      optionSelectedHash[question.question_id] = {};
    }

    if (e.target.checked) {
      answer.push(e.target.value);
      optionSelectedHash[question.question_id][e.target.value] = true;
    } else {
      optionSelectedHash[question.question_id][e.target.value] = false;
      const index = answer.indexOf(e.target.value);
      answer.splice(index, 1);
    }
    setMandatoryFieldFalse(question.question_id, answer);
    feedbackHash[question.question_id] = {
      question_id: question.question_id,
      question_uuid: question.question_uuid,
      question: question.question,
      question_type: question.question_type,
      options: question.options,
      answer: answer.join(', '),
      category: question.category,
      is_mandatory: question.is_mandatory
    };
    setFeedbackHash(feedbackHash);
    forceUpdate();
  };
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleRatingChange = (e, question, value) => {
    e.preventDefault();
    setMandatoryFieldFalse(question.question_id, value);
    feedbackHash[question.question_id] = {
      question_id: question.question_id,
      question_uuid: question.question_uuid,
      question: question.question,
      question_type: question.question_type,
      options: question.options,
      answer: value,
      category: question.category,
      is_mandatory: question.is_mandatory
    };
    setFeedbackHash(feedbackHash);
    forceUpdate();
  };

  const handleChange = (e, question) => {
    setMandatoryFieldFalse(question.question_id, e.target.value);
    feedbackHash[question.question_id] = {
      question_id: question.question_id,
      question_uuid: question.question_uuid,
      question: question.question,
      question_type: question.question_type,
      options: question.options,
      answer: e.target.value,
      category: question.category,
      is_mandatory: question.is_mandatory
    };
    setFeedbackHash(feedbackHash);
    forceUpdate();
  };

  const renderErrorMessage = () => (
    <div className="text-red-500">
      <span>Please fill the field</span>
    </div>
  );

  const renderTextPreview = (question) => (
    <>
      <input
        type="text"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        placeholder="Enter your answer"
        onChange={(e) => handleChange(e, question)}
        value={feedbackHash[question.question_id]?.answer}
      />
      {mandatoryFieldError[question.question_id] ? renderErrorMessage() : null}
    </>
  );

  const renderPreviewYesOrNo = (question) => (
    <>
      <div className="flex space-x-4">
        <div>
          <input
            type="radio"
            id="option1"
            value="Yes"
            className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
            onChange={(e) => handleChange(e, question)}
            checked={feedbackHash[question.question_id]?.answer === 'Yes'}
          />
          <label htmlFor="option1" className="ml-2 text-gray-700">
            Yes
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="option2"
            value="No"
            className="form-radio text-primary-600 focus:ring-primary-600 h-4 w-4"
            onChange={(e) => handleChange(e, question)}
            checked={feedbackHash[question.question_id]?.answer === 'No'}
          />
          <label htmlFor="option2" className="ml-2 text-gray-700">
            No
          </label>
        </div>
      </div>
      {mandatoryFieldError[question.question_id] ? renderErrorMessage() : null}
    </>
  );

  const renderPreviewSingleSelect = (question) => (
    <>
      <select
        id="large"
        className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(e) => handleChange(e, question)}
        value={feedbackHash[question.question_id]?.answer}
      >
        <option>Select Option</option>
        {question?.options.map((op, index) => (
          <option key={index} value={op}>
            {op}
          </option>
        ))}
      </select>
      {mandatoryFieldError[question.question_id] ? renderErrorMessage() : null}
    </>
  );

  const renderPreviewMultipleSelect = (question) => (
    <>
      <div className="flex space-x-1">
        {question?.options?.map((op) => (
          <div className="space-x-1">
            <div className="flex items-center me-4 ">
              <input
                id="inline-checkbox"
                type="checkbox"
                value={op}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onClick={(e) => handleMultiSelect(e, question)}
                checked={optionSelectedHash[question.question_id]?.[op] === true}
              />
              <label
                htmlFor="inline-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {op}
              </label>
            </div>
          </div>
        ))}
      </div>
      {mandatoryFieldError[question.question_id] ? renderErrorMessage() : null}
    </>
  );

  const renderPreviewRatingScale = (question) => (
    <>
      <div className="flex space-x-1">
        {[...Array(5)].map((_, index) => {
          const starId = `star${index + 1}`;

          return (
            <div className="flex items-center space-x-2" key={starId}>
              <div className="hidden">
                <input
                  type="radio"
                  id={starId}
                  name="rating"
                  value={index + 1}
                  className="hidden"
                />
              </div>
              <label htmlFor={starId} className="cursor-pointer">
                <div onClick={(e) => handleRatingChange(e, question, index + 1)}>
                  <StarIcon rating={feedbackHash[question.question_id]?.answer} value={index} />
                </div>
              </label>
            </div>
          );
        })}
      </div>
      {mandatoryFieldError[question.question_id] ? renderErrorMessage() : null}
    </>
  );

  const renderQuestion = (question) => {
    const { question_type = '' } = question;
    return (
      <>
        <p className="text-base text-gray-500 sm:text-lg dark:text-gray-400">
          {question.question} {question.is_mandatory ? <span> *</span> : null}
        </p>
        <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse" />
        {question_type === 'TEXT' ? renderTextPreview(question) : null}
        {question_type === 'YES_OR_NO' ? renderPreviewYesOrNo(question) : null}
        {question_type === 'SINGLE_SELECT' ? renderPreviewSingleSelect(question) : null}
        {question_type === 'MULTIPLE_SELECT' ? renderPreviewMultipleSelect(question) : null}
        {question_type === 'RATING_SCALE' ? renderPreviewRatingScale(question) : null}
        <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4" />
      </>
    );
  };

  const renderCategory = (datum) => (
    <div className="my-10 w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <h5 className="text-center mb-2 text-3xl font-bold text-gray-900 dark:text-white">
        {datum.category}
      </h5>
      {datum?.questions.map(renderQuestion)}
    </div>
  );

  const renderForm = () => (
    <>
      {questionData.map(renderCategory)}
      <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
        <button
          className="block text-white bg-blue-700 hover:bg-transparent hover:text-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
  const renderFeedbackQuestions = () => {
    const title = 'QA Score Card';
    return (
      <CommonHeader loading={loading} title={title} meta_title="Feedback Questions">
        {!loading ? renderForm() : null}
      </CommonHeader>
    );
  };
  return renderFeedbackQuestions();
}
