import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonHeader from '../../util_components/common_header';
import { image_error_replace, check_null_text } from '../../../util_functions';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import { get_api_url } from '../../../utils/urls';

class BlockedDevices extends Component {
  state = {
    loading: true,
    devices: []
  };

  componentDidMount() {
    this.load_data();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = get_api_url('get_blocked_devices');
      post_api(url, null, true)
        .then((res) => this.setState({ devices: [...res.data.devices], loading: false }))
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  render_single_device = (device) => {
    let user_url = '';
    let user_name = '';
    let user_photo = '';
    if (device.student_uuid) {
      user_url = `/studentprofile?id=${device.student_uuid}`;
      user_name = device.student_name;
      user_photo = device.student_photo;
    }
    if (device.teacher_uuid) {
      user_url = `/teacherprofile?id=${device.teacher_uuid}`;
      user_name = device.teacher_name;
      user_photo = device.teacher_photo;
    }
    return (
      <div className="ct-row">
        <div className="ct-col ct-la  ct-sm-font ct-bw">{device.unique_device_id}</div>
        <div className="ct-col ct-la ct-hf ct-sm-font">{check_null_text(device.user_type)}</div>
        <div className="ct-col ct-hf ct-la">
          {user_url ? (
            <a className="link-no-dec" href={user_url}>
              <div className="profile-img-name">
                <img src={user_photo} onError={image_error_replace} alt="x" />
                {user_name}
              </div>
            </a>
          ) : (
            '--'
          )}
        </div>
        <div className="ct-col ct-qf ct-vsm-font">{check_null_text(device.app_platform)}</div>
        <div className="ct-col ct-qf ct-vsm-font">{check_null_text(device.device_type)}</div>
      </div>
    );
  };

  render() {
    const { loading, devices } = this.state;
    return (
      <CommonHeader loading={loading} title="Blocked Devices">
        <div className="cus-table" style={{ marginBottom: '20px' }}>
          <div className="ct-row ct-h">
            <div className="ct-col ct-la">Device ID</div>
            <div className="ct-col ct-hf ct-la">User Type</div>
            <div className="ct-col ct-hf ct-la">User</div>
            <div className="ct-col ct-qf">Platform</div>
            <div className="ct-col ct-qf">Device</div>
          </div>
          {devices.map(this.render_single_device)}
        </div>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockedDevices);
